import { useCallback, useMemo } from 'react'

import { gql, /* NetworkStatus,  */ useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import { getGroupConversionDatapoints } from 'util/datapointCalculationFunctions'
import { useQueryData } from 'services/data'
import { useCurrentCustomer } from 'services/store'

import { getDefaultTimeRange } from 'components/Dashboard/components/TimePicker/functions'

import useErrorHandler from '../util/useErrorHandler'
import { useLocaleHook } from './user'

export const POLL_INTERVAL_MS = 15 * 1000

export const UUID_BY_INTERNALID_QUERY = gql`
  query datapointIdByInternalId($companyId: Float!, $internalId: String!) {
    datapointIdByInternalId(companyId: $companyId, internalId: $internalId)
  }
`

export const DATAPOINTS_QUERY = gql`
  query datapointsQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      name
      first
      last
      min
      max
      average
      kind
      unit
      rawUnit
      startTime
      endTime
      schedule {
        id
        schedule
      }
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
      history
      tags
      compareHistory
    }
  }
`

export const DATAPOINTS_VALUES_QUERY = gql`
  query datapointsValuesQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      name
      first
      last
      unit
      rawUnit
      startTime
      endTime
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
      values
      tags
    }
  }
`

export const DATAPOINTS_GRID_QUERY = gql`
  query datapointsQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      name
      first
      last
      min
      max
      average
      unit
      namedReferences
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
      history
      compareHistory
      tags
    }
  }
`

export const DATAPOINTS_OPTIONS_QUERY = gql`
  query datapointsOptionsQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      name
      unit
      namedReferences
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
      tags
    }
  }
`

export const DATAPOINTS_CALENDAR_QUERY = gql`
  query datapointsCalendar($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      schedule {
        schedule
      }
    }
  }
`

export const LAST_DATAPOINT_QUERY = gql`
  query datapoint($datapoint: DatapointInput!) {
    datapoint(datapoint: $datapoint) {
      id
      name
      lastHistoryRecord {
        time
        value
      }
      unit
    }
  }
`
export const DATAPOINT_DETAIL_QUERY = gql`
  query datapoint($datapoint: DatapointInput!) {
    datapoint(datapoint: $datapoint) {
      id
      name
      namedReferences
    }
  }
`

export const LAST_DATAPOINTS_QUERY = gql`
  query datapoints($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      last
      name
      unit
      namedReferences
      preset {
        translations {
          translation
          language {
            code
          }
        }
      }
    }
  }
`

export const DATAPOINT_DETAILS_QUERY = gql`
  query datapoints($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      name
      first
      last
      unit
      rawUnit
      namedReferences
      tags
      createdAt
      updatedAt
    }
  }
`

export const DATAPOINT_UNIT_QUERY = gql`
  query DatapointUnit($datapoint: DatapointInput!) {
    datapoint(datapoint: $datapoint) {
      id
      name
      unit
      rawUnit
    }
  }
`

export const DATAPOINT_DETAILS_FP_QUERY = gql`
  query datapointsFixedPermissions($datapoints: [DatapointInput!]!, $companyId: Float) {
    datapointsFixedPermissions(datapoints: $datapoints, companyId: $companyId) {
      id
      name
      first
      last
      unit
      namedReferences
      tags
      createdAt
      updatedAt
    }
  }
`

export const CUSTOMER_DATAPOINTS_QUERY = gql`
  query customerDatapoints($id: String!, $companyId: Float) {
    customerDatapoints(id: $id, companyId: $companyId) {
      id
      name
      unit
      namedReferences
    }
  }
`

export const DATAPOINT_TAGS_QUERY = gql`
  query datapoints($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      tags
    }
  }
`

export const LAST_VALUE_QUERY = gql`
  query datapoint($datapoint: DatapointInput!) {
    datapoint(datapoint: $datapoint) {
      id
      last
      unit
    }
  }
`

export const LAST_VALUES_QUERY = gql`
  query lastValuesQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      last
      unit
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
    }
  }
`

export const DATAPOINTS_FIRST_LAST_VALUES_QUERY = gql`
  query datapointsFirstLastQuery($datapoints: [DatapointInput!]!) {
    datapoints(datapoints: $datapoints) {
      id
      last
      first
      min
      max
      average
      unit
      unitObject {
        difference
      }
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
        }
      }
    }
  }
`

export const COUNT_LAST_VALUE_QUERY = gql`
  query datapoint($database: String!, $unit: String!, $tags: [FilterTag!]!, $values: [Float!]!) {
    countDatapointLastValues(database: $database, unit: $unit, tags: $tags, values: $values) {
      count
    }
  }
`

export const MATRIX_WIDGET_DATAPOINTS_BY_IDS_AND_PRESET_IDS = gql`
  query entitiesByIds($ids: [String!]!, $presetIds: [Float!]!) {
    entitiesByIds(ids: $ids) {
      id
      datapoints(presetIds: $presetIds) {
        id
        preset {
          id
        }
      }
    }
  }
`

export const DATAPOINTS_SPREAD_BY_QUERY = gql`
  query datapointSpreadBy($datapoints: [DatapointInput!]!) {
    datapointsSpreadBy(datapoints: $datapoints) {
      time
      min
      max
      average
    }
  }
`

export const GATES_OPEN_TIME_QUERY = gql`
  query gatesOpenTime($datapoints: [DatapointInput!]!) {
    gatesOpenTime(datapoints: $datapoints) {
      time
      min
      max
      average
    }
  }
`

export const DATAPOINT_SPREAD_VALUES_BY_QUERY = gql`
  query datapointSpreadValue($datapoint: DatapointInput!) {
    datapoint(datapoint: $datapoint) {
      counterHistory
    }
  }
`

const FRAGMENT_DATAPOINT_ENTITY_FIELDS = gql`
  fragment DatapointEntityFields on DatapointEntity {
    id
    name
    presetName

    equipment {
      name
    }
    room {
      name
    }
    floor {
      name
    }
    building {
      name
    }
    site {
      name
    }
    unit {
      name
    }
    allTags
  }
`

const DATAPOINT_ENTITIES_QUERY = gql`
  ${FRAGMENT_DATAPOINT_ENTITY_FIELDS}
  query datapointEntites($companyId: ID!, $languageCode: String!) {
    datapointEntities(companyId: $companyId, languageCode: $languageCode) {
      items {
        ...DatapointEntityFields
      }
    }
  }
`

const DATAPOINT_ENTITY_QUERY = gql`
  ${FRAGMENT_DATAPOINT_ENTITY_FIELDS}
  query datapointEntity($id: ID!, $language: String!) {
    datapointEntity(id: $id, language: $language) {
      ...DatapointEntityFields
    }
  }
`

const DATAPOINT_ENTITY_DETAIL_QUERY = gql`
  query entity($id: ID!, $language: String!, $companyId: ID) {
    datapointEntity(id: $id, language: $language) {
      name
      site {
        name
      }
      building {
        name
      }
      floor {
        name
      }
      room {
        name
      }
      datapointRules {
        id
        iid
        name
        isActive
        created
        flowId
        fields {
          name
          value
          required
          description
        }
      }
    }
    entity(id: $id, companyId: $companyId) {
      id
      createdAt
      updatedAt
      equipment {
        id
        name
        preset {
          name(language: $language)
        }
      }
      unit {
        id
        name
      }
      tags: features(typeName: ["tag", "field"]) {
        id
        name
        value
        enabled
        required
        type {
          id
          name
        }
      }
      markers: features(typeName: ["marker"]) {
        id
        name
        enabled
        required
        type {
          id
          name
        }
      }
    }
  }
`

const NON_ASSET_DATAPOINT_ENTITY_DETAIL_QUERY = gql`
  query entity($id: ID!, $language: String!, $companyId: ID!) {
    nonAssetDatapointEntity(id: $id, language: $language) {
      name
      parentEntityTypeName
      parentEntity {
        id
        name
      }
      datapointRules {
        id
        iid
        name
        isActive
        created
        fields {
          name
          value
          required
          description
        }
      }
    }
    entity(id: $id, companyId: $companyId) {
      id
      createdAt
      updatedAt
      unit {
        id
        name
      }
      tags: features(typeName: ["tag", "field"]) {
        id
        name
        value
        enabled
        required
        type {
          id
          name
        }
      }
      markers: features(typeName: ["marker"]) {
        id
        name
        enabled
        required
        type {
          id
          name
        }
      }
    }
  }
`

export const VIRTUAL_DATAPOINT_FLOW_QUERY = gql`
  query virtualDatapointFlow($companyId: Float!, $flowId: Float!) {
    virtualDatapointFlow(companyId: $companyId, flowId: $flowId) {
      id
      flowLogic
      flowData
      details
      flowTrigger {
        id
        details
        isActive
      }
      entityDetails {
        id
        name
        type
        tags
        fields
        unit {
          difference
        }
      }
    }
  }
`

export const FLOW_TRIGGERS_QUERY = gql`
  query flowTriggers($companyId: Float!, $flowId: Float!) {
    flowTriggers(companyId: $companyId, flowId: $flowId) {
      id
      name
      description
      details
      datapointDetails {
        id
        name
      }
    }
  }
`

export const UPDATE_FLOW_TRIGGERS = gql`
  mutation updateFlowTriggers(
    $companyId: Float!
    $flowId: Float!
    $flowTriggerStatusInput: [FlowTriggerStatusInput!]!
  ) {
    updateFlowTriggers(
      data: {
        flowId: $flowId
        companyId: $companyId
        flowTriggerStatusInput: $flowTriggerStatusInput
      }
    ) {
      id
    }
  }
`

export const INSERT_VIRTUAL_DATAPOINT_FLOW = gql`
  mutation insertVirtualDatapointFlow(
    $companyId: Float!
    $flowName: String
    $virtualDatapointId: String!
    $flowData: String!
    $flowLogic: String!
  ) {
    insertVirtualDatapointFlow(
      data: {
        companyId: $companyId
        flowName: $flowName
        virtualDatapointId: $virtualDatapointId
        flowData: $flowData
        flowLogic: $flowLogic
      }
    ) {
      id
    }
  }
`

export const UPDATE_VIRTUAL_DATAPOINT_FLOW = gql`
  mutation updateVirtualDatapointFlow(
    $flowId: Float!
    $virtualDatapointId: String!
    $flowData: String!
    $flowLogic: String!
  ) {
    updateVirtualDatapointFlow(
      data: {
        flowId: $flowId
        virtualDatapointId: $virtualDatapointId
        flowData: $flowData
        flowLogic: $flowLogic
      }
    ) {
      id
    }
  }
`

export const DATAPOINTS_OVERDUE_QUERY = gql`
  query datapointsOverdueQuery($datapoints: [DatapointInput!]!, $companyId: Float) {
    datapointsOverdue(datapoints: $datapoints, companyId: $companyId) {
      id
      name
      unit
      unitObject {
        difference
      }
      last
      first
      overdueTime
      expectedDateTime
      conversionFactor {
        multiplyFactor
        operation
        endUnit {
          name
          formatted
        }
        reverse {
          multiplyFactor
          operation
        }
      }
      values
    }
  }
`

export const useCalendarQuery = (entityId) => {
  const skip = !entityId
  const [errorHandler] = useErrorHandler()
  const { data, error, loading, refetch } = useQuery(DATAPOINTS_CALENDAR_QUERY, {
    variables: {
      datapoints: [
        {
          id: entityId,
        },
      ],
    },
    skip: !entityId,
  })
  if (skip) {
    return null
  }
  if (error) {
    errorHandler(error)
  }
  return { data: data?.datapoints?.[0]?.schedule?.schedule, loading, error, refetch }
}

export const useDatapointsQuery = (
  { datapoints = [], skip = false, options = {} },
  query = DATAPOINTS_QUERY
) => {
  const skipQuery = !datapoints.length || skip
  const [errorHandler] = useErrorHandler()
  const { data, error, loading, refetch } = useQuery(query, {
    ...options,
    skip: skipQuery,
    fetchPolicy: 'no-cache',
    variables: {
      datapoints,
    },
  })
  if (error) {
    errorHandler(error)
  }

  const cleanData = useMemo(() => {
    const firstPoint = data?.datapoints?.[0]?.history?.[0]
    const lastPoint = data?.datapoints?.[0]?.history?.[data.datapoints[0].history.length - 1]
    const granularity = datapoints?.[0]?.granularity
    const granularityUnit = granularity?.substring(granularity.length - 1)
    const granularityValue = granularity?.slice(-1)
    // const lastGranularityValue = dayjs().subtract(granularityValue, granularityUnit).valueOf()
    const lastHourValue = dayjs().subtract(1, 'h').valueOf()
    const trimEnd = lastPoint?.time > lastHourValue
    const trimStart =
      granularityUnit !== 'm' &&
      !(granularityUnit === 'h' && granularityValue === '1') &&
      firstPoint?.time < datapoints[0]?.startTime

    if (trimEnd || trimStart) {
      return data.datapoints.map(({ history, ...datapoint }) => {
        const endTrimmedData = history.slice(
          trimStart ? 1 : 0,
          trimEnd && lastPoint?.value === 0 ? history.length - 1 : history.length
        )
        return {
          ...datapoint,
          history: endTrimmedData,
        }
      })
    }
    return data?.datapoints
  }, [data, datapoints])

  return { data: cleanData, error, loading, refetch }
}

export const useDatapointsGridQuery = ({ datapoints = [], skip = false, options = {} }, query) => {
  const skipQuery = !datapoints.length || skip
  const [errorHandler] = useErrorHandler()
  const { data, error, loading, refetch } = useQuery(query || DATAPOINTS_GRID_QUERY, {
    ...options,
    skip: skipQuery,
    fetchPolicy: 'no-cache',
    variables: {
      datapoints,
    },
  })
  if (error) {
    errorHandler(error)
  }
  return { data, error, loading, refetch }
}

export const useLastDatapointQuery = (id, poll = true) => {
  const skip = !id
  const [errorHandler] = useErrorHandler()
  const { data, error, loading, refetch } = useQuery(LAST_DATAPOINT_QUERY, {
    variables: {
      datapoint: { id },
    },
    skip,
    fetchPolicy: 'no-cache',
  })
  if (error) {
    errorHandler(error)
  }
  return { data, error, loading, refetch }
}

export const useLastDatapointsQuery = (ids = [], poll = true) => {
  const skip = !ids
  const [errorHandler] = useErrorHandler()
  const { data, error, loading, refetch } = useQuery(LAST_DATAPOINTS_QUERY, {
    variables: {
      datapoints: ids.map(({ id }) => ({ id })),
    },
    skip,
    fetchPolicy: 'no-cache',
  })
  if (skip) {
    return null
  }
  if (error) {
    errorHandler(error)
  }
  return { data: data?.datapoints, error, loading, refetch }
}

export const useCustomerDatapointsQuery = () => {
  const customer = useCurrentCustomer()
  const [errorHandler] = useErrorHandler()
  const id = customer?.entity?.id
  const companyId = customer?.id
  const skip = !id || !customer
  const { data, error, loading, refetch, networkStatus } = useQuery(CUSTOMER_DATAPOINTS_QUERY, {
    variables: { id, companyId },
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  if (skip) {
    return { loading: false, datapoints: [], refetch: (e) => e }
  }
  if (loading || networkStatus === 4) {
    return { loading: true }
  }
  if (error) {
    errorHandler(error)
    return null
  }

  return { datapoints: data.customerDatapoints, refetch }
}

export const useDatapointDetails = (id, skip) => {
  const customer = useCurrentCustomer()
  const companyId = customer?.id
  const skipQuery = !!skip || !id
  const [errorHandler] = useErrorHandler()
  const { data, error, loading } = useQuery(DATAPOINT_DETAILS_FP_QUERY, {
    variables: {
      datapoints: [
        {
          id,
        },
      ],
      companyId,
    },
    skip: skipQuery,
    fetchPolicy: 'network-only',
  })
  if (skipQuery || loading) {
    return null
  }
  if (error) {
    errorHandler(error)
  }
  return data?.datapointsFixedPermissions[0]
}

export const useDatapointUnit = (id, skip) => {
  const skipQuery = !!skip || !id
  const [errorHandler] = useErrorHandler()
  const { data, error, loading } = useQuery(DATAPOINT_UNIT_QUERY, {
    variables: {
      datapoint: {
        id,
      },
    },
    skip: skipQuery,
    fetchPolicy: 'network-only',
  })
  if (skipQuery || loading) {
    return null
  }
  if (error) {
    errorHandler(error)
  }
  return data?.datapoint
}

export const useMatrixWidgetDatapointsByIds = (ids, presetIds) => {
  return useQuery(MATRIX_WIDGET_DATAPOINTS_BY_IDS_AND_PRESET_IDS, {
    variables: { ids, presetIds },
  })
}

const getDatapointsForTimeRange = (
  datapoints,
  timeRange,
  comparisonPeriod = { amount: 1, unit: 'month' }
) => {
  const currentTimeRange = getDefaultTimeRange(timeRange)
  const previousTimeRange = getDefaultTimeRange(comparisonPeriod)

  const datapointsCurrentPeriod = datapoints.map(({ id }) => ({
    id,
    difference: false,
    granularity: '',
    ...currentTimeRange,
  }))
  const datapointsPrevPeriod = comparisonPeriod
    ? datapoints.map(({ id }) => ({
        id,
        difference: false,
        granularity: '',
        ...previousTimeRange,
      }))
    : []

  return datapointsPrevPeriod.concat(datapointsCurrentPeriod)
}

export const useDatapointsForComparedTimeRange = (
  newSocketValue,
  timeRange,
  datapoints,
  comparisonPeriod,
  difference
) => {
  const datapointsVariable = useMemo(
    () =>
      timeRange
        ? getDatapointsForTimeRange(datapoints, timeRange, comparisonPeriod, difference)
        : datapoints.map(({ id }) => ({ id })),
    // eslint-disable-next-line
    [newSocketValue, timeRange, datapoints, comparisonPeriod, difference]
  )

  const { data, error, loading, refetch } = useQuery(DATAPOINTS_FIRST_LAST_VALUES_QUERY, {
    variables: {
      datapoints: datapointsVariable,
    },
    skip: !datapointsVariable.length,
  })

  return { data, error, loading, refetch }
}

export const useDatapointsForTimeRange = ({
  datapoints,
  datapointsGroups,
  timeRange,
  groupConversionUnit,
  widgetType,
  skip,
}) => {
  const isLastValueQuery = !timeRange || timeRange?.preset === 'none'
  const timeRangeValues = useMemo(() => {
    return getDefaultTimeRange(timeRange)
  }, [timeRange])

  const datapointsPeriod = useMemo(() => {
    if (!datapoints || skip) {
      return []
    }
    const groupConversionDatapoints = getGroupConversionDatapoints(datapointsGroups)
    return datapoints.map(({ id, conversionUnit, difference }) => ({
      ...timeRangeValues,
      id,
      returnUnitId: groupConversionDatapoints[id] || conversionUnit || groupConversionUnit,
      ...(!isLastValueQuery && {
        difference,
        granularity: '',
      }),
    }))
  }, [datapoints, datapointsGroups, isLastValueQuery, timeRangeValues, groupConversionUnit, skip])

  const { data, error, loading, refetch } = useQueryData(
    'datapoints',
    datapointsPeriod,
    [timeRange, datapoints],
    skip || !datapointsPeriod.length,
    widgetType
  )

  /* const { data, error, refetch, networkStatus } = useQuery(
    isLastValueQuery ? LAST_VALUES_QUERY : DATAPOINTS_FIRST_LAST_VALUES_QUERY,
    {
      variables: {
        datapoints: datapointsPeriod,
      },
      skip: skip || !datapointsPeriod.length,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  ) */

  return {
    data: data?.data?.datapoints,
    error,
    loading /* : networkStatus === NetworkStatus.loading */,
    refetch,
  }
}

export const useDatapointsSpreadByQuery = (datapoints, options = {}) => {
  return useQuery(DATAPOINTS_SPREAD_BY_QUERY, {
    variables: {
      datapoints,
    },
    ...options,
  })
}

export const useGatesOpenTimeQuery = (datapoints, options = {}) => {
  return useQuery(GATES_OPEN_TIME_QUERY, {
    variables: {
      datapoints,
    },
    ...options,
  })
}

export const useDatapointSpreadByValueQuery = (datapoint, options = {}) => {
  return useQuery(DATAPOINT_SPREAD_VALUES_BY_QUERY, {
    variables: {
      datapoint,
    },
    ...options,
  })
}

export const useDatapointEntities = (options) => {
  const customer = useCurrentCustomer()

  const locale = useLocaleHook()

  const { data, ...rest } = useQuery(DATAPOINT_ENTITIES_QUERY, {
    variables: {
      companyId: customer?.id,
      languageCode: locale,
    },
    skip: !customer?.id,
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  return { data: data?.datapointEntities?.items, customer, ...rest }
}

export const QUERY_NON_ASSET_DATAPOINTS = gql`
  query nonAssetDatapointEntities($companyId: ID!, $languageCode: String!, $entityTypeIds: [ID!]!) {
    nonAssetDatapointEntities(
      companyId: $companyId
      languageCode: $languageCode
      entityTypeIds: $entityTypeIds
    ) {
      items {
        id
        name
        parentEntity {
          name
        }
        unit {
          name
        }
        site
        department
        parentEntityTypeName
      }
    }
  }
`

export const useNonAssetDatapointEntities = (entityTypeIds) => {
  const customer = useCurrentCustomer()

  const locale = useLocaleHook()

  const { data, ...rest } = useQuery(QUERY_NON_ASSET_DATAPOINTS, {
    variables: { companyId: customer?.id, languageCode: locale, entityTypeIds },
    skip: !customer?.id,
    notifyOnNetworkStatusChange: true,
  })

  return { data: data?.nonAssetDatapointEntities?.items, ...rest }
}

export const useDatapointEntity = (options) => {
  const locale = useLocaleHook()

  const { data, ...rest } = useQuery(DATAPOINT_ENTITY_QUERY, {
    variables: {
      language: locale,
    },
    ...options,
  })

  return { data: data?.datapointEntity, ...rest }
}

export const useDatapointDetail = () => {
  const locale = useLocaleHook()

  const [getDatapointDetail, { data, ...rest }] = useLazyQuery(DATAPOINT_DETAIL_QUERY, {
    variables: {
      language: locale,
    },
  })

  return [getDatapointDetail, { data: data?.datapoint, ...rest }]
}

export const useDatapointEntityDetail = (id) => {
  const customer = useCurrentCustomer()
  const locale = useLocaleHook()

  const { data, ...rest } = useQuery(DATAPOINT_ENTITY_DETAIL_QUERY, {
    variables: { id, companyId: customer?.id, language: locale },
    skip: !id || !customer?.id,
    fetchPolicy: 'network-only',
  })

  // combine data from 2 queries
  const entityData = useMemo(() => {
    return data && { ...data?.entity, ...data?.datapointEntity }
  }, [data])

  return { data: entityData, ...rest }
}

export const useNonAssetDatapointEntityDetail = (id) => {
  const customer = useCurrentCustomer()
  const locale = useLocaleHook()

  const { data, ...rest } = useQuery(NON_ASSET_DATAPOINT_ENTITY_DETAIL_QUERY, {
    variables: { id, companyId: customer?.id, language: locale },
    skip: !id,
    fetchPolicy: 'network-only',
  })

  // combine data from 2 queries
  const entityData = useMemo(() => {
    return data && { ...data?.entity, ...data?.nonAssetDatapointEntity }
  }, [data])

  return { data: entityData, ...rest }
}

export const MUTATION_INSERT_DATAPOINT_VALUES = gql`
  mutation insertDatapointValues($data: [DatapointValueInput!]!) {
    insertDatapointValues(data: $data) {
      id
    }
  }
`

export const MUTATION_IMPORT_NON_ASSET_ENTITY_DATA = gql`
  mutation importNonAssetEntityData($companyId: Float!, $data: [DataImportInput!]!) {
    importNonAssetEntityData(companyId: $companyId, data: $data)
  }
`

export const useInsertDatapointValues = () => {
  const [execute, response] = useMutation(MUTATION_INSERT_DATAPOINT_VALUES)

  const save = useCallback(
    (data) => {
      return execute({ variables: { data }, refetchQueries: 'GhgDataInputDatapoints' })
    },
    [execute]
  )

  return [save, response]
}

export const useImportNonAssetEntityData = () => {
  const customer = useCurrentCustomer()

  const companyId = customer?.id
  const [execute, response] = useMutation(MUTATION_IMPORT_NON_ASSET_ENTITY_DATA)

  const save = useCallback(
    (data) => {
      execute({ variables: { data, companyId } })
    },
    [execute, companyId]
  )

  return [save, response]
}

export const useDatapointOptions = (datapoints, skip) => {
  const { data } = useQuery(DATAPOINTS_OPTIONS_QUERY, {
    variables: {
      datapoints: datapoints?.map(({ id }) => ({ id })),
    },
    skip: skip || !datapoints,
  })
  return { data: data?.datapoints }
}

const QUERY_DATAPOINT_FIELDS_BY_ENTITY = gql`
  query datapointFieldsByEntity(
    $companyId: ID
    $entityId: ID!
    $filterTags: [String!]
    $filterMarkers: [String!]
  ) {
    entity(companyId: $companyId, id: $entityId) {
      id
      datapoints(filterTags: $filterTags, filterMarkers: $filterMarkers) {
        id
        fields
        lastHistoryRecord {
          value
        }
      }
    }
  }
`

export const useDatapointFieldsByEntity = (entityId) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_DATAPOINT_FIELDS_BY_ENTITY, {
    variables: {
      companyId: customer?.id,
      entityId,
      filterTags: [`equipRef=${entityId}`],
      filterMarkers: ['refrigerant'],
    },
    skip: !customer?.id,
  })

  return { data: data?.entity?.datapoints?.[0], ...rest }
}

export const useVirtualDatapointFlow = (flowId) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(VIRTUAL_DATAPOINT_FLOW_QUERY, {
    variables: {
      companyId: customer?.id,
      flowId,
    },
    skip: !customer?.id || !flowId,
    fetchPolicy: 'network-only',
  })

  return { data: data?.virtualDatapointFlow, ...rest }
}

export const useInsertVirtualDatapointFlow = () => {
  const customer = useCurrentCustomer()

  return useMutation(INSERT_VIRTUAL_DATAPOINT_FLOW, {
    variables: {
      companyId: customer?.id,
    },
  })
}

export const useUpdateVirtualDatapointFlow = () => {
  return useMutation(UPDATE_VIRTUAL_DATAPOINT_FLOW)
}

export const useUpdateFlowTriggers = () => {
  const customer = useCurrentCustomer()
  return useMutation(UPDATE_FLOW_TRIGGERS, { variables: { companyId: customer?.id } })
}

export const useFlowTriggers = (flowId) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(FLOW_TRIGGERS_QUERY, {
    variables: {
      companyId: customer?.id,
      flowId,
    },
    skip: !customer?.id || !flowId,
    fetchPolicy: 'network-only',
  })

  return { data: data?.flowTriggers, ...rest }
}

export const useDatapointsOverdue = (datapoints, skip) => {
  const customer = useCurrentCustomer()
  return useQuery(DATAPOINTS_OVERDUE_QUERY, {
    variables: {
      datapoints: datapoints?.map(({ id, conversionUnit, limit }) => ({
        id,
        returnUnitId: conversionUnit,
        limit,
      })),
      companyId: customer?.id,
      fetchPolicy: 'network-only',
    },
    skip: skip || !datapoints,
  })
}

export const useDatapointsValues = ({ datapoints }, skip) => {
  const customer = useCurrentCustomer()
  const { data, ...rest } = useQuery(DATAPOINTS_VALUES_QUERY, {
    variables: {
      datapoints: datapoints,
      companyId: customer?.id,
    },
    skip: skip || !datapoints?.length,
  })
  return { data: data?.datapoints, ...rest }
}
