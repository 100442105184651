import {
  barChartFieldConvertors,
  configFieldConvertor,
} from '../../../Dashboard/utils/common/helpers'
import { config as graphCard } from './../../LineChart/GraphCard/config'

export const config = {
  ...graphCard,
  editProps: {
    name: 'widget.barChart',
    category: 'widget.charts',
    keywords: ['chart', 'trend', 'bar'],
    img: '/resources/images/widgets/barchart.png',
    info: {
      'nl-NL':
        '<h2>Staafdiagram</h2>\n' +
        '<p>De Bar Chart-widget is een manier om een reeks categorische gegevens samen te vatten. Het staafdiagram geeft gegevens weer met behulp van een aantal staven, die elk een bepaalde categorie vertegenwoordigen.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Widgets titel</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '<li><strong>Stapel</strong> Geef de waarden weer als gestapelde staven of niet</li>\n' +
        '<li><strong>Type</strong> Geef de waarden weer op een horizontale (balk) of verticale (kolom) manier</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groepen (LINK)</strong> Een bestaande groep datapunten toevoegen of selecteren</li>\n' +
        '<li><strong>Datapunten (LINK)</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Bijschriften</strong> Wijs een tekst, kleur en pictogram toe aan een waarde</li>\n' +
        '</ul>\n' +
        '<h3>Groepsconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Geselecteerde groep</strong> In deze sectie worden alle bestaande groepen weergegeven en kunt u er nieuwe datapunten voor selecteren</li>\n' +
        '<li><strong>Niet-gegroepeerde gegevenspunten</strong> In deze sectie worden alle geselecteerde, maar niet-gegroepeerde gegevenspunten weergegeven. Ze kunnen aan de geselecteerde groep worden toegevoegd door erop te klikken.</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor de groep</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor de groep</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor de groep</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode voor de groep</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor het datapunt</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor het datapunt</li>\n' +
        '<li><strong>Gebruik stappen</strong> Geef de trend stapsgewijs weer</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Graphique à barres</h2>\n' +
        "<p>Le widget Bar Chart est un moyen de résumer un ensemble de données catégorielles. Le graphique à barres affiche les données à l'aide d'un certain nombre de barres, chacune représentant une catégorie particulière.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        '<li><strong>Empiler</strong> Afficher les valeurs sous forme de barres empilées ou non</li>\n' +
        '<li><strong>Type</strong> Afficher les valeurs de manière horizontale (Bar) ou verticale (Columnn)</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groupes (LINK)</strong> Ajouter ou sélectionner un groupe existant de points de données</li>\n' +
        '<li><strong>Points de données (LINK)</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Légendes</strong> Attribuez un texte, une couleur et une icône à une valeur</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du groupe</h3>\n' +
        '<ul>\n' +
        '<li><strong>Groupe sélectionné</strong> Cette section affiche tous les groupes existants et permet de sélectionner de nouveaux points de données pour celui-ci</li>\n' +
        '<li><strong>Points de données non groupés</strong> Cette section affiche tous les points de données sélectionnés, mais non groupés. Ils peuvent être ajoutés au groupe sélectionné en cliquant dessus.</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le groupe</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le groupe</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le groupe</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul pour le groupe</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Modifier l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Étiquette</strong> Saisissez une étiquette pour le point de données</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le point de données</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le point de données</li>\n' +
        '<li><strong>Utiliser les étapes</strong> Afficher la tendance par étapes</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Modifier l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul> ',
      'en-US':
        '<h2>Bar Chart</h2>\n' +
        '<p>The Bar Chart widget is a way of summarizing a set of categorical data. The bar chart displays data using a number of bars, each representing a particular category.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '<li><strong>Stack</strong> Display the values as stacked bars or not</li>\n' +
        '<li><strong>Type</strong> Display the values in a horizontal (Bar) or vertical (Columnn) way</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groups (LINK)</strong> Add or select an existing group of datapoints</li>\n' +
        '<li><strong>Datapoints (LINK)</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Captions</strong> Assign a text, color and icon to a value</li>\n' +
        '</ul>\n' +
        '<h3>Group Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Selected Group</strong> This section displays all existing groups and allows to select new datapoints for it</li>\n' +
        '<li><strong>Ungrouped Datapoints</strong> This section displays all selected, but ungrouped datapoints. They can be added to the selected group by clicking on them.</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the group</li>\n' +
        '<li><strong>Color</strong> Select a color for the group</li>\n' +
        '<li><strong>Style</strong> Select a line style for the group</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method for the group</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Color</strong> Select a color for the datapoint</li>\n' +
        '<li><strong>Style</strong> Select a line style for the datapoint</li>\n' +
        '<li><strong>Use Steps</strong> Display the trend in a step wise manner</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
    },
    default: {
      ...graphCard.editProps.default,
      type: 'BarChart',
      title: 'widget.barChart',
    },
    unUsedDatapointProperties: ['rangeId', 'offsets'],
    preview: {
      icon: 'fas fa-chart-area',
      chart: {
        series: configFieldConvertor(graphCard.editProps.preview.chart.series, [
          {
            selector: () => true,
            resolver: (serie) => ({ ...serie, type: 'column' }),
          },
        ]),
      },
    },
    fields: configFieldConvertor(graphCard.editProps.fields, barChartFieldConvertors),
  },
}

export default config
