import React, { useCallback, useEffect } from 'react'

import { injectIntl } from 'react-intl'

import { setCustomer, useAppState, useCurrentCustomer } from 'services/store'

import Icon from 'components/Global/Icon'

import { useMessages } from '../../../Layout/Notifications'
import { Item } from '../../components/Results'
import { CustomerTitle } from './styled'

export const CustomerResultItem = ({ data, close, selected, clickRef, intl, ...props }) => {
  const messages = useMessages()
  const customer = useCurrentCustomer()

  const { appStateDispatch } = useAppState()

  const isCurrent = customer?.id === data.id

  const changeCostumer = useCallback((payload) => appStateDispatch(setCustomer(payload)), [
    appStateDispatch,
  ])

  const handleClick = useCallback(() => {
    changeCostumer(data)
    messages.success(`${intl.formatMessage({ id: 'switchedToCustomer' }, { customer: data.name })}`)
    close()
  }, [close, changeCostumer, data, messages, intl])

  useEffect(() => {
    if (clickRef) {
      clickRef.current = handleClick
    }
  }, [clickRef, handleClick])

  return (
    <Item
      onClick={isCurrent ? undefined : handleClick}
      icon="far building"
      selected={selected}
      {...props}
    >
      <CustomerTitle>
        {data.name}
        {isCurrent && <Icon icon="fa circle-check" />}
      </CustomerTitle>
    </Item>
  )
}

export default injectIntl(CustomerResultItem)
