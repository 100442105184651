import styled from 'styled-components'

import ValuePicker from 'components/Form/components/ValuePicker'

import Selector from '../Selector/styled'

const DatapointValueTypePickerStyled = styled(ValuePicker)`
  .Input {
    width: 100%;
  }
  ${Selector} {
    margin-bottom: 1rem;
  }
`
export default DatapointValueTypePickerStyled
