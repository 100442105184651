import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

const ColorPickerFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    > .Icon {
      width: 3rem;
      height: 3rem;

      img {
        width: 100%;
      }
    }
    .Tooltip {
      display: flex;
      justify-content: flex-end;
      width: auto;
    }
  }
`
export default ColorPickerFieldStyled
