import styled from 'styled-components'

import { iconActionButton } from './styled.iconActionButton'
import { iconListButton } from './styled.iconListButton'
import { Navigation } from './styled.Navigation'
import { openCloseButton } from './styled.openCloseButton'
import { smallButton } from './styled.smallButton'

const variants = {
  iconActionButton,
  iconListButton,
  smallButton,
  openCloseButton,
  Navigation,
}

const OptionButtonStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ variant }) => variants[variant]}
`
export default OptionButtonStyled
