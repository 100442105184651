import { darken, lighten, transparentize } from 'polished'
import styled from 'styled-components'

import Icon from 'components/Global/Icon/styled'
import Loader from 'components/Global/Loader'

const WidgetErrorStyled = styled(Loader)`
  background: ${({ theme, type = 'error' }) =>
    transparentize(
      0.3,
      theme.darkMode ? darken(0.5, theme.color[type]) : lighten(0.38, theme.color[type])
    )};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 100;
  line-height: 1.3;
  color: ${({ theme, type }) => theme.color[type]};
  padding: 0 5%;
  border: 1px solid ${({ theme, type }) => theme.color[type]};
  border-radius: 0.5rem;
  backdrop-filter: blur(8px);

  ${Icon} {
    width: 5% !important;
    padding: 0 !important;
    height: auto;
    max-height: 40%;
    display: flex;
    align-items: center;
    color: ${({ theme, type }) => theme.color[type]};

    svg {
      min-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }

  label {
    overflow: hidden;
    margin-top: 1.5%;
  }
`
export default WidgetErrorStyled
