/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import get from 'lodash/get'
import { FormattedMessage, injectIntl } from 'react-intl'

import { ICON_STRING_MAPPING } from 'util/constants'

import { AntPopconfirm } from 'components/Global/AntDesign/AntPopconfirm'
import Icon from 'components/Global/Icon'
import Loader from 'components/Global/Loader'
import IconCellDropdown from 'components/Layout/List/components/IconCell/IconCellDropdown'

import IconCellStyled from './styled'

const IconCell = ({
  data,
  nameField = 'name',
  iconField,
  intl,
  onDelete,
  onDeleteCancel,
  onUnsubscribe,
  onUnsubscribeCancel,
  onDuplicate,
  onDeepDuplicate,
  onCreate,
  onCopy,
  onCut,
  onPaste,
  pasteName,
  children,
  extraItems,
  loading,
  bold = true,
}) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const name = get(data, nameField)
  const iconName = get(data, iconField) || iconField
  const icon = iconField && (ICON_STRING_MAPPING[iconName] || iconName)

  return (
    <IconCellStyled bold={bold}>
      {icon && <Icon className="IconCell__icon" icon={icon} />}
      {name && <span className="IconCell__name">{name}</span>}
      {children}
      {onDelete && (
        <AntPopconfirm
          title={`${intl.formatMessage({ id: 'deleteConfirmationMessage' })}${name}?`}
          onConfirm={(e) => {
            e.stopPropagation()
            setDeleteConfirm(false)
            return onDelete(e, data)
          }}
          onCancel={(e) => {
            e.stopPropagation()
            if (onDeleteCancel) onDeleteCancel(data)
            setDeleteConfirm(false)
          }}
          open={deleteConfirm}
        >
          <span
            ref={(ref) => {
              if (!ref) return
              ref.onclick = (e) => {
                e.stopPropagation()
                setDeleteConfirm((state) => !state)
              }
            }}
          >
            <Icon icon="fas fa-trash" className="IconCell__action IconCell__duplicate" />
          </span>
        </AntPopconfirm>
      )}
      {onCreate && (
        <span
          ref={(ref) => {
            if (!ref) return
            ref.onclick = (e) => {
              e.stopPropagation()
              onCreate(e, data)
            }
          }}
        >
          <Icon icon="fas fa-plus" className="IconCell__action IconCell__add" />
        </span>
      )}
      {onUnsubscribe && (
        <AntPopconfirm
          title={`${intl.formatMessage({ id: 'unsubscribeConfirmationMessage' })}${name}?`}
          onConfirm={(e) => {
            setDeleteConfirm(false)
            return onUnsubscribe(e, data)
          }}
          onCancel={() => {
            if (onUnsubscribeCancel) onUnsubscribeCancel(data)
            setDeleteConfirm(false)
          }}
          visible={deleteConfirm}
        >
          <span
            ref={(ref) => {
              if (!ref) return
              ref.onclick = (e) => {
                e.stopPropagation()
                setDeleteConfirm((state) => !state)
              }
            }}
          >
            <Icon icon="fas fa-file-xmark" className="IconCell__action IconCell__duplicate" />
          </span>
        </AntPopconfirm>
      )}
      {(onDuplicate || onDeepDuplicate || onCopy || onCut || onPaste || extraItems) && (
        <IconCellDropdown
          valueElement={
            <Icon icon="fas fa-ellipsis-vertical" className="IconCell__action IconCell__add" />
          }
          variant="iconCellMore"
          className="iconCellMore"
        >
          {({ toggleOpen }) => {
            return (
              <>
                {onDuplicate && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onDuplicate(e, data)
                    }}
                  >
                    <Icon icon="fas fa-clone" className="IconCell__action IconCell__duplicate" />
                    <FormattedMessage id="duplicate" />
                  </span>
                )}
                {onDeepDuplicate && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onDeepDuplicate(e, data)
                    }}
                  >
                    <Icon
                      icon="fas fa-list-tree"
                      className="IconCell__action IconCell__duplicate"
                    />
                    <FormattedMessage id="deepDuplicate" />
                  </span>
                )}
                {onCopy && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onCopy(e, data)
                    }}
                  >
                    <Icon icon="fas fa-copy" className="IconCell__action IconCell__copy" />
                    <FormattedMessage id="copy" />
                  </span>
                )}
                {onCut && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onCut(e, data)
                    }}
                  >
                    <Icon icon="fas fa-scissors" className="IconCell__action IconCell__cut" />
                    <FormattedMessage id="cut" />
                  </span>
                )}
                {onPaste && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onPaste(e, data)
                    }}
                  >
                    <Icon icon="fas fa-paste" className="IconCell__action IconCell__paste" />
                    <FormattedMessage id="paste" /> <em>{pasteName}</em>
                  </span>
                )}
                {extraItems?.map(({ icon, name, onClick }) => (
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleOpen(false)
                      onClick(e, data)
                    }}
                  >
                    <Icon icon={icon} className="IconCell__action IconCell__paste" />
                    {name}
                  </span>
                ))}
              </>
            )
          }}
        </IconCellDropdown>
      )}
      <Loader loading={loading} spinner={{ type: 'lineScale', size: 10 }} />
    </IconCellStyled>
  )
}

IconCell.propTypes = {}

IconCell.defaultProps = {}

export default injectIntl(IconCell)
