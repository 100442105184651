import styled from 'styled-components'

const SliderStyled = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .Slider__nav {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    display: flex;
    align-items: center;
    .Icon {
      position: absolute;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
  }
`
export default SliderStyled
