import { css } from 'styled-components'

export const clearIcon = css`
  transition: background 0.2s ease;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: ${({ theme }) => theme.color.softText};
  color: ${({ theme }) => theme.color.backgroundFront};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  &:hover {
    background: ${({ theme }) => theme.color.error};
  }
`
