import React, { useMemo } from 'react'

import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  calculatePlotBands,
  getComparatorCalculatedValue,
  getDataForEmissionFactor,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useLocaleNumber } from 'util/numbers'
import round from 'util/round'
import { useGhgData } from 'services/entities'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { useComparatorValues } from 'components/Global/FormField/Comparator'
import { getDatapointErrors } from 'components/Widgets/functions'
import { chartOptions, getSerie } from 'components/Widgets/Gauge/ClassicGauge/chartOptions'
import { SolidGauge } from 'components/Widgets/Gauge/ClassicGauge/styled'

import { getGranularity } from '../../../../Dashboard/utils/common/helpers'

const GhgRelativeGauge = ({
  dataProps = {},
  decimals = 2,
  datapoints,
  comparators,
  comparatorsMethod,
  method = 'sum',
  emissionFactor = 'total',
  timeRange,
  comparisonPeriod,
  conversionUnit,
  intl,
  prop,
  showDelta = false,
  showComparator = false,
  customLogic,
  deviation = 50,
  defaultCalculationMethod,
  comparatorsCalculationMethod,
  preview = { gauge: { series: [] } },
  colors = [
    [0, '#44bc66'],
    [0.33, '#fed33a'],
    [0.66, '#f88c1d'],
    [1, '#f14e3e'],
  ],
  newSocketValue,
  unit,
  returnUnitId,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps

  const { data, error, refetch, loading } = useGhgData({
    datapoints,
    emissionFactor,
    timeRange,
    returnUnitId,
    granularity: getGranularity({
      defaultStartTime: timeRange || { preset: 'past7Days' },
      type: 'ghg',
    }),
    defaultTimeRange: { preset: 'past7Days' },
    options: {
      poll: true,
    },
    calculationMethod: defaultCalculationMethod,
    widgetType: 'ghgRelativeGauge',
  })

  const { data: comparatorsData, loading: comparatorsLoading } = useComparatorValues({
    comparator: comparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor,
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: comparatorsCalculationMethod,
    widgetType: 'ghgRelativeGaugeComparator',
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data

  const { series, datapointChartProps, errors } = useMemo(() => {
    if (!validData) {
      return isPreviewMode
        ? { series: preview.gauge.series, datapointChartProps: {}, errors: [] }
        : { datapointChartProps: {}, errors: [] }
    }
    const errors = getDatapointErrors(validData)
    const value = getDatapointsCalculatedValue(
      getDataForEmissionFactor(validData, emissionFactor),
      { method, customLogic, decimals }
    )
    const comparator = getComparatorCalculatedValue(
      getDataForEmissionFactor(comparatorsData, emissionFactor),
      {
        method: comparatorsMethod,
        customLogic,
        decimals,
      }
    )
    const displayUnit = validData[0]?.returnUnit?.name || 'kgCO2e'
    const minimum = round(comparator - (Math.abs(comparator) / 100) * deviation, decimals)
    const maximum = round(comparator + (Math.abs(comparator) / 100) * deviation, decimals)
    const deviationPercentage = round(((value - comparator) / Math.abs(comparator)) * 100, decimals)
    const percentageLabel =
      deviationPercentage < 0 ? ` (${deviationPercentage}%)` : ` (+${deviationPercentage}%)`

    const series = [
      getSerie(
        {
          value: value,
          unit: displayUnit + percentageLabel,
          theme,
        },
        false,
        localizeNumber
      ),
    ]

    if (showComparator) {
      series.push(
        getSerie(
          {
            value: comparator,
            unit: `${displayUnit} - ${intl.formatMessage({ id: 'widget.reference' })}`,
            theme,
          },
          true,
          localizeNumber
        )
      )
    }

    const datapointChartProps = {
      yAxis: [
        {
          min: minimum,
          max: maximum,
          tickPositions: [minimum, maximum],
          labels: {
            formatter: function () {
              return this.isFirst
                ? `${localizeNumber(this.value)} ${displayUnit} (-${deviation}%)`
                : `${localizeNumber(this.value)} ${displayUnit} (+${deviation}%)`
            },
          },
          plotBands: calculatePlotBands(colors, minimum, maximum).map(({ from, to, color }) => ({
            from,
            to,
            color,
            outerRadius: '100%',
            innerRadius: '78%',
          })),
        },
      ],
    }
    return { series, datapointChartProps, errors }
  }, [
    colors,
    customLogic,
    decimals,
    deviation,
    isPreviewMode,
    method,
    preview.gauge.series,
    showComparator,
    validData,
    comparatorsData,
    emissionFactor,
    intl,
    theme,
    localizeNumber,
    comparatorsMethod,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })
  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <SolidGauge
      {...props}
      className="RelativeGauge"
      options={options}
      loading={{
        loading: loading || comparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(GhgRelativeGauge)
