import React from 'react'

import get from 'lodash/get'
import { any, bool, func, object, string } from 'prop-types'

import Component from '../../Component/styled'
import MenuItem from '../../MenuItem'
import DropdownArrow from '../DropdownArrow/styled'
import DropdownValueStyled from './styled'

const component = {
  MenuItem,
}
const DropdownValue = ({
  showArrow,
  value,
  componentName,
  componentProps,
  styleProps,
  onChange,
}) => {
  const displayValue = componentProps.valueKey ? get({ value }, componentProps.valueKey) : value
  return (
    <DropdownValueStyled
      {...styleProps}
      className="DropdownValue"
      showArrow={showArrow}
      onClick={(e) => onChange && onChange(e, value)}
    >
      <Component
        {...componentProps}
        as={component[componentName]}
        value={displayValue}
        styleProps={styleProps}
      />
      {showArrow && <DropdownArrow className="DropdownArrow" />}
    </DropdownValueStyled>
  )
}

DropdownValue.propTypes = {
  showArrow: bool,
  value: any,
  componentName: string,
  componentProps: object,
  styleProps: object,
  onChange: func,
}

DropdownValue.defaultProps = {
  componentProps: {},
}

export default DropdownValue
