import React from 'react'

import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import { Field } from 'components/Form'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'

const UnitSelectField = ({
  children,
  name,
  defaultValue,
  value = true,
  checked,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  label,
  ...props
}) => {
  return (
    <Field {...props} label={label}>
      {({ control, errors, isEditMode, loading }) => {
        const error = get(errors, name)

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              if (loading) {
                return <Skeleton width="200px" />
              }

              if (isEditMode) {
                return (
                  <>
                    <ValuePicker
                      value={formFieldValue?.id?.toString()}
                      query={{ name: 'UNITS_QUERY', dataKey: 'units' }}
                      selectionKeys={{
                        value: 'id',
                        label: 'name',
                      }}
                      getItemValue={({ id, name }) => ({ id, name })}
                      onChange={(value, option) => {
                        const val = { id: option?.id, name: option?.name }
                        fromFieldOnChange(val)
                        if (typeof onChange === 'function') {
                          onChange(val)
                        }
                      }}
                      search
                      error={error}
                      disabled={disabled}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              return <span>{formFieldValue?.name}</span>
            }}
          />
        )
      }}
    </Field>
  )
}

export default UnitSelectField
