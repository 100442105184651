import React from 'react'

import { injectIntl } from 'react-intl'

import Field from 'components/Form/components/Field'
import TextField from 'components/Form/fields/TextField'

const RuleValueField = ({ name, disabled, onChange = () => {}, ...fieldProps }) => {
  return (
    <Field {...fieldProps}>
      {({ watch }) => {
        const label = watch(`${name}.name`)
        const description = watch(`${name}.description`)
        const required = watch(`${name}.required`)

        return (
          <TextField
            name={`${name}.value`}
            label={description !== '' ? `${label} (${description})` : label}
            disabled={disabled}
            validation={{ required: required && !disabled }}
            className="TextField"
          />
        )
      }}
    </Field>
  )
}

export default injectIntl(RuleValueField)
