import React from 'react'

import { isEmpty } from 'lodash'
import { Controller } from 'react-hook-form'

import CsvMapper from 'components/Form/components/CsvMapper/CsvMapper'
import ReceiverContent from 'components/Form/components/DndMapper/components/ReceiverContent'
import { ReceiverStyled } from 'components/Form/components/DndMapper/components/styled'
import DndMapperStyled from 'components/Form/components/DndMapper/styled'
import { ContentFlex, ContentLabel } from 'components/Layout/Content'

import CsvMappingFieldStyled from './styled'

const CsvMappingField = ({ name, defaultValue, disabled, onChange, ...props }) => {
  const config = isEmpty(props.currentConfig) ? {} : JSON.parse(props.currentConfig)
  return (
    <CsvMappingFieldStyled {...props}>
      {({ control, isEditMode, watch }) => {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange, value: controllerValue }) => {
              if (isEditMode) {
                return (
                  <CsvMapper
                    name={name}
                    valuesToMatchTo={props.valuesToMatchTo}
                    onChangeConfig={onChange}
                    showMapping={isEditMode}
                  />
                )
              }

              return config && !isEmpty(config) ? (
                <DndMapperStyled>
                  <ContentFlex
                    border="auto"
                    col="1/1"
                    borderRadius="auto"
                    className="DndMapper__values"
                  >
                    <ContentLabel>Mapped Fields</ContentLabel>
                    <ReceiverStyled>
                      <ReceiverContent label="id" value={config.id ?? config.internalId} />
                    </ReceiverStyled>
                    <ReceiverStyled>
                      <ReceiverContent label="timestamp" value={config.timestamp} />
                    </ReceiverStyled>
                    {config.tags.map((item) =>
                      Object.entries(item).map(([key, value]) => (
                        <ReceiverStyled>
                          <ReceiverContent label={key} value={value} />
                        </ReceiverStyled>
                      ))
                    )}
                    {config.datapoints.map(
                      (item) => (
                        // Object.entries(item).map(([key, value]) => (
                        <ReceiverStyled>
                          <ReceiverContent label={item.name} value={item.value} />
                        </ReceiverStyled>
                      )
                      // ))
                    )}
                  </ContentFlex>
                </DndMapperStyled>
              ) : null
            }}
          />
        )
      }}
    </CsvMappingFieldStyled>
  )
}

export default CsvMappingField
