import React, { useCallback, useRef } from 'react'

import Results from '../Results'
import SearchBar from '../SearchBar'
import { Container } from './styled'

export const Search = ({
  onClose,
  selectedIndex,
  setSelectedIndex,
  searchText,
  setSearchText,
  data,
  loading,
  RowComponent,
  placeholder,
  selectMode,
  setSelectMode,
}) => {
  const clickRef = useRef()

  const handleSearch = useCallback(
    (text) => {
      setSearchText(text)
      setSelectedIndex(0)
    },
    [setSearchText, setSelectedIndex]
  )

  const handleClose = useCallback(() => {
    setSearchText('')
    setSelectedIndex(0)
    onClose()
  }, [setSearchText, setSelectedIndex, onClose])

  const handleSelectDown = useCallback(() => {
    setSelectMode(true)
    setSelectedIndex((prev) => {
      const newIndex = prev + 1
      return newIndex >= data?.length ? 0 : newIndex
    })
  }, [setSelectedIndex, data, setSelectMode])

  const handleSelectUp = useCallback(() => {
    setSelectMode(true)
    setSelectedIndex((prev) => {
      const newIndex = prev - 1
      return newIndex < 0 ? data?.length - 1 : newIndex
    })
  }, [setSelectedIndex, data, setSelectMode])

  const handleSelect = useCallback(() => {
    if (typeof clickRef.current === 'function') {
      clickRef.current()
    }
  }, [])

  const handleMouseEnterResult = useCallback(
    ({ index }) => {
      if (!selectMode) {
        setSelectedIndex(index)
      }
    },
    [setSelectedIndex, selectMode]
  )

  return (
    <Container
      onClick={handleClose}
      onMouseMove={() => {
        if (selectMode) {
          setSelectMode(false)
        }
      }}
    >
      <SearchBar
        onChange={handleSearch}
        onKeyDown={handleSelectDown}
        onKeyUp={handleSelectUp}
        onSelect={handleSelect}
        onClose={handleClose}
        placeholder={placeholder}
      >
        {searchText && !loading && (
          <Results
            results={data}
            close={handleClose}
            selected={selectedIndex}
            selectMode={selectMode}
            clickRef={clickRef}
            RowComponent={RowComponent}
            onMouseEnter={handleMouseEnterResult}
          />
        )}
      </SearchBar>
    </Container>
  )
}

export default Search
