import React from 'react'

import { ContentCardStyled } from './styled'

const ContentCard = ({ children, col, className, onClick }) => {
  return (
    <ContentCardStyled
      className={`ContentCard ${className}`}
      col={col}
      onClick={(e) => onClick && onClick(e)}
    >
      <div>{children}</div>
    </ContentCardStyled>
  )
}

export default ContentCard
