import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import Component from 'components/Global/Component/styled'
import Empty from 'components/Global/Empty'
import CaptionTooltip from 'components/Global/FormField/CaptionTooltip'
import { validateField } from 'components/Global/FormField/utils/functions'

import Icon from '../Icon'
import FormFieldElement from './FormFieldElement'
import FormFieldStyled, { FormFieldLabel } from './styled'

const FormField = ({
  value,
  defaultValue,
  validation,
  instance,
  label,
  caption,
  dataKey,
  path,
  subPath,
  icon,
  disabled,
  width,
  selectOptions,
  variant,
  children,
  componentName = 'Text',
  componentProps,
  col,
  className,
  hideValue,
  rootUpdate,
  onChange,
  onPressEnter,
  onOpenChange,
  intl,
  dataCy,
}) => {
  const [fieldValidation, setFieldValidation] = useState(null)

  const onValueUpdate = (value, fieldDataKey, meta) => {
    if (validation) {
      const { isValid, message } = validateField(value, validation, intl)
      if (!isValid) {
        return setFieldValidation({ type: 'error', message })
      }
      setFieldValidation(null)
    }

    onChange({
      value,
      dataKey: fieldDataKey || dataKey,
      meta: { ...meta, rootUpdate: rootUpdate || meta?.rootUpdate },
      path,
    })
  }

  return (
    <Component as={caption ? CaptionTooltip : Empty}>
      <FormFieldStyled
        className={`FormField ${componentName} ${col || ''} ${className || ''}`}
        value={value}
        disabled={disabled}
        labelStyle={(label && label.style) || {}}
        width={width}
        validation={fieldValidation?.type}
        componentName={componentName}
        variant={variant}
      >
        {label && (
          <FormFieldLabel className="FormFieldLabel" as={label.tagName}>
            {label.formatted ? <FormattedMessage id={label.formatted} /> : label.text}
          </FormFieldLabel>
        )}
        {fieldValidation?.message && (
          <span className="FormField__message">{fieldValidation.message}</span>
        )}
        {icon && <Icon icon={icon} />}
        {children ||
          (!hideValue && (
            <FormFieldElement
              disabled={disabled}
              value={value}
              instance={instance}
              label={label}
              dataKey={dataKey}
              path={path}
              subPath={subPath}
              defaultValue={defaultValue}
              selectOptions={selectOptions}
              componentName={componentName}
              componentProps={componentProps}
              variant={variant}
              onChange={onValueUpdate}
              onPressEnter={onPressEnter}
              onOpenChange={onOpenChange}
              dataCy={dataCy}
            />
          ))}
      </FormFieldStyled>
    </Component>
  )
}

FormField.propTypes = {
  componentName: PropTypes.string,
  componentProps: PropTypes.object,
}

FormField.defaultProps = {
  componentProps: {},
}

export default injectIntl(FormField)
