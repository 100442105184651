import React from 'react'

import { number, string } from 'prop-types'

import SpinnerStyled from './styled'

const Spinner = ({ type, size, color }) => {
  const elements = {
    ring: 4,
    grid: 9,
    ellipsis: 4,
    lineScale: 5,
  }[type]

  const renderChildren = () => {
    const children = []

    for (let i = 0; i < elements; i++) {
      children.push(<div key={`element-${i + 1}`} />)
    }
    return children
  }

  return (
    <SpinnerStyled className={`Spinner ${type}`} variant={type} size={size} color={color}>
      {renderChildren()}
    </SpinnerStyled>
  )
}

Spinner.propTypes = {
  type: string,
  size: number,
  color: string,
}

Spinner.defaultProps = {}

export default Spinner
