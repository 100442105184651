import React from 'react'

import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import TooltipContentPortal from 'components/Global/Tooltip/TooltipContent/TooltipContentPortal'

const DatapointDetails = ({ detail, hoveredElement, loading }) => {
  return hoveredElement ? (
    <TooltipContentPortal parentRef={hoveredElement} isOpen variant="Datapoints">
      <WidgetLoader spinner={{ type: 'lineScale', size: 8 }} loading={loading} />
      <span className="Datapoints__detail-title">{detail.name}</span>
      <span className="Datapoints__detail">{detail.namedReferences?.siteRef}</span>
      <span className="Datapoints__detail">{detail.namedReferences?.buildingRef}</span>
      <span className="Datapoints__detail">{detail.namedReferences?.floorRef}</span>
      <span className="Datapoints__detail">{detail.namedReferences?.roomRef}</span>
      <span className="Datapoints__detail">{detail.namedReferences?.vehicleRef}</span>
    </TooltipContentPortal>
  ) : null
}

export default DatapointDetails
