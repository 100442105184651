import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import axios from 'axios'
import get from 'lodash/get'

// import { useQueryData } from 'services/data'
import { DATAPOINT_DETAILS_QUERY } from '../../../../services/datapoints'
import {
  // useAssetDatapoints,
  useNonAssetDatapoints,
  useScope3Datapoints,
} from '../../../../services/entities'
import { coreConfig, useCurrentCustomer } from '../../../../services/store'
import EntityTree from '../../../Dashboard/components/EntityTree'
import NonAssetEntityTree from '../../../Dashboard/components/NonAssetEntityTree/NonAssetEntityTree'
import UpstreamDownstreamEntityTree from '../../../Dashboard/components/UpstreamDownstreamEntityTree/UpstreamDownstreamEntityTree'
import { useFilters } from '../../../Form/components/ValuePicker/hooks'
import { filterDatapointForTags, getDatapointListForQuery } from './functions'

const dataSources = [
  {
    id: 'assets',
    name: 'Assets',
    treeComponent: EntityTree,
  },
  {
    id: 'persona',
    name: 'Persona',
    disabled: true,
  },
  {
    id: 'vehicles',
    name: 'Vehicles',
    treeComponent: NonAssetEntityTree,
    typeIds: [12],
    tags: ['vehicle: 1'],
  },
  {
    id: 'upstreamDownstream',
    name: 'Upstream & Downstream Activities',
    treeComponent: UpstreamDownstreamEntityTree,
    typeIds: [12],
    tags: ['vehicle: 1'],
  },
]

export const useDatapointsFilter = ({
  datapointAssetsFilter,
  datapointNonAssetsFilter,
  tags,
  preview,
  groupDataKey,
  groupIndex,
  isDataEntryDashboard,
}) => {
  const [assetDatapoints, setAssetDataPoints] = useState([])
  const [loading, setLoading] = useState(false)
  const customer = useCurrentCustomer()
  const [getNonAssetDatapoints, { datapoints: nonAssetDatapoints }] = useNonAssetDatapoints()
  // const [getAssetDatapoints, { datapoints: assetDatapoints, loading }] = useAssetDatapoints()
  const [getScope3Datapoints, { datapoints: upstreamDownstreamDatapoints }] = useScope3Datapoints()

  const getApiAssetDatapoints = ({
    api = 'core',
    service = 'raw_entities_for_tags',
    payload,
    method,
    ...options
  }) => {
    const coreconfig = coreConfig()
    const url = process.env.REACT_APP_CORE_URL

    const config = {
      method: method ?? 'post',
      url: `${url}${service}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + coreconfig.token,
      },
      data: JSON.stringify(payload),
      ...(options || {}),
    }

    return axios(config)
  }

  const getAssetDatapoints = ({ api, service, payload: queryPayload, method }) => {
    setLoading(true)
    getApiAssetDatapoints({ api, service, payload: queryPayload, method })
      .then(function (response) {
        setAssetDataPoints(response.data?.entities || [])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [dataSource, setDataSource] = useState(dataSources[0])
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [datapoints, setDatapoints] = useState([])

  const { onSearch, searchValue, data: filteredDatapoints } = useFilters(
    datapoints,
    null,
    {},
    'name'
  )

  useEffect(() => {
    setSelectedEntity(null)
    setSelectedTags([])
  }, [customer])

  useEffect(() => {
    const datapoints = getDatapointListForQuery({
      dataSource: dataSource?.id,
      assetDatapoints: assetDatapoints || [],
      nonAssetDatapoints: nonAssetDatapoints || [],
      upstreamDownstreamDatapoints: upstreamDownstreamDatapoints || [],
      preview,
      groupDataKey,
      groupIndex,
      isDataEntryDashboard,
      customAssetsFilter: datapointAssetsFilter,
      customNonAssetsFilter: datapointNonAssetsFilter,
    })

    setDatapoints(
      dataSource?.id === 'assets' ? datapoints : filterDatapointForTags(datapoints, selectedTags)
    )
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetDatapoints,
    nonAssetDatapoints,
    upstreamDownstreamDatapoints,
    preview,
    datapointAssetsFilter,
    datapointNonAssetsFilter,
  ])

  const onSelectDataSource = (id, scope) => {
    setDataSource(scope)
    setDatapoints([])
    setSelectedTags([])
    setSelectedEntity(null)
  }

  const onSelectEntity = (value) => {
    switch (dataSource?.id) {
      case 'assets':
        getAssetDatapoints({
          payload: {
            getDatapoints: true,
            getNonDatapoints: false,
            // languageId: 1,
            data: {
              entityId: value.key,
              tags: [
                ...(tags ? Object.entries(tags).map(([key, value]) => `${key}: ${value}`) : []),
                ...selectedTags.map(({ key, value }) => `${key}: ${value}`),
              ],
            },
            doExtraPermissionCheck: true,
          },
        })
        break
      case 'upstreamDownstream':
        getScope3Datapoints({
          variables: {
            activityCategoryId: value.categoryId,
            ...(value.__typename === 'AviaryEntity' && { entityId: value.id }),
            ...(value.__typename === 'EntityType' && { entityTypeId: value.id }),
          },
        })
        break
      default:
        const selectedTagKey =
          !value.key &&
          {
            OrganisationForm: 'departmentRef',
          }[value.__typename]
        const selectedTag = selectedTagKey && `${selectedTagKey}: ${value.id}`
        getNonAssetDatapoints({
          variables: {
            languageId: 1,
            entityId: value.key || customer.entity.id,
            tags: [
              ...(tags ? Object.entries(tags).map(([key, value]) => `${key}: ${value}`) : []),
              ...(selectedTag ? [selectedTag] : []),
              ...dataSource.tags,
            ],
          },
        })
    }
    setSelectedEntity(value)
    setSelectedTags([])
  }

  const onSelectTags = (value) => {
    switch (dataSource?.id) {
      case 'assets':
        getAssetDatapoints({
          payload: {
            getDatapoints: true,
            getNonDatapoints: false,
            // languageId: 1,
            data: {
              entityId: selectedEntity.key,
              tags: [
                ...(tags ? Object.entries(tags).map(([key, value]) => `${key}: ${value}`) : []),
                ...value.map(({ key, value }) => `${key}: ${value}`),
              ],
            },
            doExtraPermissionCheck: true,
          },
        })
        break
      default:
        const unFilteredList = {
          assets: assetDatapoints,
          vehicles: nonAssetDatapoints,
          upstreamDownstream: upstreamDownstreamDatapoints,
        }[dataSource.id]

        setDatapoints(filterDatapointForTags(unFilteredList, value))
    }
    setSelectedTags(value)
  }

  return {
    dataSources,
    dataSource,
    datapoints,
    filteredDatapoints,
    selectedEntity,
    searchValue,
    onSelectEntity,
    onSelectTags,
    onSelectDataSource,
    onSearch,
    loading,
  }
}

export const useFixedUnit = ({
  preview,
  groupDataKey,
  groupUnit,
  fixedUnit,
  selectedDatapoints,
  groupDatapoints,
}) => {
  const [getDatapointDetails, { data }] = useLazyQuery(DATAPOINT_DETAILS_QUERY)
  const [datapointsUnit, setDatapointsUnit] = useState(null)
  useEffect(() => {
    let unit = groupDataKey ? groupUnit : data?.datapoints[0] ? data.datapoints[0].rawUnit : null
    if (typeof fixedUnit === 'string') {
      unit = get(preview, fixedUnit)
    }
    setDatapointsUnit(unit)
  }, [data, groupUnit, groupDataKey, fixedUnit, preview])

  useEffect(() => {
    if (typeof fixedUnit === 'string') {
      return
    }
    if (fixedUnit && selectedDatapoints.length) {
      const refDatapoint = groupDataKey
        ? selectedDatapoints.find(({ id }) => groupDatapoints?.includes(id))
        : selectedDatapoints[0]
      if (refDatapoint) {
        if (refDatapoint.unit) {
          return setDatapointsUnit(refDatapoint.unit)
        }
        return getDatapointDetails({
          variables: {
            datapoints: [
              {
                id: refDatapoint.id,
              },
            ],
          },
        })
      }
    }
    setDatapointsUnit(null)
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatapoints])

  return datapointsUnit
}
