import React, { useRef } from 'react'

import { get } from 'lodash'
import set from 'lodash/fp/set'
import { injectIntl } from 'react-intl'

import variables from '../../../../theme/variables'
import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { minMax } from '../../../Dashboard/Widget/config/selections'
import Table from '../../Table'
import FixedValueSelector from './../FixedValueSelector/index'
import FixedValuesStyled from './styled'

const FixedValues = ({
  onChange,
  dataKey,
  dataCy = 'FixedValues',
  includeType = true,
  value,
  intl,
}) => {
  const { action } = useDashboard()
  const editing = useRef(null)

  const onOpenBottomDrawer = (path) => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: true,
          width: 'full',
          component: FixedValueSelector,
          key: 'fixed-value-selector',
          componentProps: {
            intl,
            onSave: onBottomDrawerSave,
            subPath: path,
            dataKey,
          },
        },
      },
    })
  }

  const setProperties = ({ children, id, title, name, url, icon, color, key }, level = 0) => {
    return {
      title,
      name: level > 0 ? title : name,
      url: url || `/dashboard/${key}`,
      icon,
      color,
      id: id.toString(),
      ...(children && { children: children.map((child) => setProperties(child, level + 1)) }),
    }
  }

  const onBottomDrawerSave = (payload) => {
    const { raw } = payload || {}
    if (raw) {
      const currentItem = get({ root: value.links }, editing.current)
      const newItem = setProperties({
        ...currentItem,
        title: raw.title,
        name: raw.name,
        url: raw.url,
        icon: raw.icon,
        color: raw.color,
        children: raw.children?.length ? raw.children : currentItem.children,
      })

      onChange(set(`${editing.current}`, newItem, { root: value.links }).root, 'links')
    }
  }

  const handleChange = (value) => {
    onChange(value)
  }

  const onValueClick = (value, { path }) => {
    onOpenBottomDrawer(path.replace('root', dataKey))
  }

  return (
    <FixedValuesStyled className="DatapointGroups" data-cy={dataCy}>
      <Table
        value={value}
        columns={[
          {
            label: { formatted: 'widget.value' },
            dataKey: 'value',
            componentName: 'CaptionTooltip',
            componentProps: {
              onChange: onValueClick,
              caption: ({ value }) => {
                return {
                  text: value.label
                    ? `${value.label} | ${value.tagName} : ${value.tagValue}`
                    : `value: ${value}`,
                  label: value.tagName,
                }
              },
              children: ({ label, text }) => {
                return <span>{label || text}</span>
              },
              portal: false,
              variant: 'EditableTree',
            },
            width: 15,
          },
          {
            label: { formatted: 'widget.unit' },
            dataKey: 'unit',
            componentName: 'ValuePicker',
            componentProps: {
              query: {
                name: 'UNITS_QUERY',
                dataKey: 'units',
              },
              selectionKeys: {
                value: 'formatted',
                label: 'formatted',
              },
            },
            width: 11,
          },
          ...(includeType
            ? [
                {
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'ValuePicker',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: minMax,
                    search: false,
                  },
                  width: 14,
                },
              ]
            : []),
          {
            label: { formatted: 'widget.color' },
            dataKey: 'color',
            componentName: 'ColorPicker',
            width: 10,
          },
        ]}
        onChange={handleChange}
        defaultRowValue={{
          unit: null,
          value: 0,
          type: 'value',
          color: variables.color.color.aviary.error,
        }}
      />
    </FixedValuesStyled>
  )
}

export default injectIntl(FixedValues)
