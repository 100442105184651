export default {
  etl1: 'Batch {name} is gestart',
  etl0: 'Batch {name} is afgewerkt en duurde {duration}sec',
  report1: 'Rapport {name} wordt gegenereerd',
  report0: 'Rapport {name} is beschikbaar en duurde {duration}sec',
  notificationsCount: 'Je hebt {amount} meldingen',
  notificationsCountSingle: 'Je hebt 1 melding',
  operationInputLimit:
    'De bewerking "{operation}" kan niet meer dan {max} ingang(en) bevatten. De overige ingangen worden verwijderd.',
  itemsUpdated: 'items bijgewerkt.',
  fieldNotAvailableForThisConfiguration:
    'Veld "{field}" is niet beschikbaar voor deze configuratie',
}
