import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import ColumnsStyled from './styled'

const Columns = ({
  dataKey = 'datapoints',
  subPath,
  selectedIndex,
  buttons = true,
  onChange,
  onClick,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const columns = get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []

  const handleColumnClick = (e, { id, name, index }) => {
    e.stopPropagation()
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: index,
          dataKey: dataKey,
        },
      })
      onClick(e, { id, name, index })
    }
  }

  const onRemoveColumn = (e, { index: colIndex }) => {
    e.stopPropagation()
    const newColumns = columns.filter((group, index) => index !== colIndex)
    onChange(newColumns, dataKey)
  }

  const onAddColumn = () => {
    const newColumns = columns.concat({
      headerName: intl.formatMessage({ id: 'widget.column' }),
      width: 100,
    })
    onChange(newColumns, dataKey)
    const newColumnIndex = newColumns.length - 1
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: newColumnIndex,
        dataKey: dataKey,
      },
    })
    onClick(null, { ...newColumns[newColumnIndex], index: newColumnIndex })
  }

  return (
    <ColumnsStyled className="DatapointGroups">
      {columns.map((column, index) => (
        <Button
          label={
            column.headerName
              ? column.headerName.startsWith('intl')
                ? intl.formatMessage({ id: column.headerName.replace('intl.', '') })
                : column.headerName
              : `Column ${index + 1}`
          }
          variant="tag"
          onIconClick={(e) => onRemoveColumn(e, { ...column, index })}
          icon={onRemoveColumn && 'fas fa-xmark'}
          isActive={selectedIndex === index}
          onClick={(e) => handleColumnClick(e, { ...column, index })}
        />
      ))}
      {buttons && (
        <div className="Datapoints__buttons">
          <Button
            className="SmallButton"
            label={intl.formatMessage({
              id: 'widget.addColumn',
            })}
            onClick={onAddColumn}
            variant="smallButton"
          />
        </div>
      )}
    </ColumnsStyled>
  )
}

export default injectIntl(Columns)
