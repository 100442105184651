import React from 'react'

import BaseLayoutStyled from './styled'

const BaseLayout = ({ children }) => (
  <BaseLayoutStyled className="BaseLayout">{children}</BaseLayoutStyled>
)

BaseLayout.propTypes = {}

BaseLayout.defaultProps = {}

export default BaseLayout
