import { InputNumber } from 'antd'
import styled from 'styled-components'

export const AntInputNumber = styled(InputNumber)`
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.color.main};
    border-right-width: 1px !important;
  }
`
