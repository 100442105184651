import styled from 'styled-components'

export const OrganisationReportingDateRangeStyled = styled.div`
  justify-content: flex-end;

  display: flex;
  .RangePicker {
    margin: 0 0 0 0.5rem;
  }
`
