import { css } from 'styled-components'

import Icon from '../Icon/styled'

export const roundedButton = css`
  transition: box-shadow 0.3s ease;
  background: ${({ theme }) => theme.color.backgroundFront};
  border-radius: 4rem;
  box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadowElevation5(theme.darkMode)};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.color.highlight};
      box-shadow: ${({ theme }) => theme.shadowElevation5(theme.darkMode)};
      color: ${({ theme }) => theme.color.highlightText};

      ${Icon} {
        color: ${({ theme }) => theme.color.highlightText};
      }
    `}
  .Button__label {
  }
`
