import React, { useMemo, useRef } from 'react'

import { useTheme } from 'styled-components'

import { useLocaleNumber } from 'util/numbers'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'
import InlineErrors from 'components/Dashboard/components/Chart/InlineErrors'
import Button from 'components/Global/Button'
import Icon from 'components/Global/Icon'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import { WidgetError } from 'components/Widgets/WidgetError'

import {
  LastValueWidgetArrow,
  LastValueWidgetCaption,
  LastValueWidgetContainer,
  LastValueWidgetStyled,
} from './styled'

const getCleanKpi = (value, variant) => {
  if (value || value === 0) {
    return value
  }
  if (variant === 'LastValueWidget') {
    return '-'
  }
  return undefined
}

const getDescription = (cleanKpi, unit, caption) => {
  let kpi = ''
  let title = ''
  if (cleanKpi !== undefined) {
    kpi = `<span class="value">${cleanKpi} <span class="unit">${unit || ''}</span></span>`
  }
  if (caption?.title) {
    title = `<span>${caption.title}</span>`
  }
  return `${kpi}${title}`
}

const LastValueWidget = ({
  caption,
  value,
  prevValue,
  writeDatapoint = {},
  title,
  icon,
  line,
  unit,
  color,
  centeredText,
  fontColor,
  arrowUpColor,
  arrowDownColor,
  url,
  target,
  size,
  loading,
  styleProps = {},
  showUnit = false,
  hideValue = false,
  showDescription = false,
  onClick,
  onConfirmationRequest,
  confirmation,
  showDiffArrow = false,
  diffPercentage = false,
  error,
  inlineErrors = [],
}) => {
  const localizeNumber = useLocaleNumber()
  const container = useRef()
  const theme = useTheme()

  const hasCaptionComponent = title || icon || !hideValue

  const cleanKpi = localizeNumber(getCleanKpi(value, styleProps.variant))
  const description = useMemo(() => {
    if (!showDescription || (!cleanKpi && !caption?.title)) {
      return ''
    }
    return getDescription(cleanKpi, unit, caption)
  }, [cleanKpi, caption, unit, showDescription])

  const diffValue = localizeNumber(Math.round(((value - prevValue) / value) * 100))
  const width = container.current?.clientWidth
  const height = container.current?.clientHeight
  const iconSize = width > 200 || height > 200 ? 'large' : 'small'

  return (
    <LastValueWidgetStyled
      {...styleProps}
      className={`LastValueWidget ${size}`}
      img={icon && icon.includes('.')}
      color={color}
      fontColor={fontColor}
      line={line}
      clickable={!!(url || writeDatapoint.id)}
      toggleButton={!!writeDatapoint.id}
      size={size}
      ref={container}
      width={width}
      height={height}
      toggleState={value}
      hasIcon={!!icon}
      caption={caption}
      hideValue={hideValue}
    >
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        id={writeDatapoint.id}
        onConfirmationRequest={onConfirmationRequest}
        parentRef={container.current}
      >
        {
          <Icon
            icon={caption?.icon || icon}
            color={[theme.color.error, theme.color.success][confirmation?.value || 0]}
          />
        }
      </ActionConfirmationWithAdjustableValue>
      <LastValueWidgetContainer
        className="LastValueWidgetContainer"
        variant={styleProps.variant}
        as={url ? Button : 'div'}
        url={!writeDatapoint.id && url}
        target={target}
        onClick={onClick}
        img={icon && icon.includes('.')}
        hasLabel={!!title}
        hasKpi={!hideValue && cleanKpi !== undefined}
        hasIcon={!!icon}
        caption={caption}
        hideValue={hideValue}
        line={line}
        centeredText={centeredText}
      >
        {hasCaptionComponent && (
          <div className="LastValueWidget__caption">
            <div className="LastValueWidget__title-container">
              {title && <p className="Title">{title}</p>}
              {icon && <Icon icon={icon} size={iconSize} />}
            </div>
            {!hideValue && cleanKpi !== undefined && (
              <div className="LastValueWidget__kpi-container">
                <p className="kpi">
                  <span>{cleanKpi}</span>
                  {showUnit && <label>{unit}</label>}
                </p>
                {(diffPercentage || showDiffArrow) && prevValue !== undefined && (
                  <LastValueWidgetArrow
                    className="LastValueWidgetArrow"
                    diffValue={diffValue}
                    arrowUpColor={arrowUpColor}
                    arrowDownColor={arrowDownColor}
                    fontColor={fontColor}
                    showDiffArrow={showDiffArrow}
                  >
                    {diffPercentage && (
                      <>
                        {diffValue}
                        <span>%</span>
                      </>
                    )}
                  </LastValueWidgetArrow>
                )}
              </div>
            )}
          </div>
        )}
        {caption && (
          <LastValueWidgetCaption
            className="LastValueWidgetCaption"
            title={caption.title}
            icon={caption.icon}
            type={caption.type}
            hideValue={caption.hideValue}
            color={caption.color || color}
            variant={styleProps.variant}
            description={description}
            iconSize={iconSize}
          />
        )}
      </LastValueWidgetContainer>
      <WidgetLoader {...loading} spinner={false} />
      <WidgetError error={error} />
      {inlineErrors.length > 0 && <InlineErrors errors={inlineErrors} />}
    </LastValueWidgetStyled>
  )
}

export default LastValueWidget
