import { transparentize } from 'polished'
import { Scrollbar } from 'react-scrollbars-custom'
import styled, { css } from 'styled-components'

const ScrollbarStyled = styled(Scrollbar)`
  ${({ theme, color, scroll }) => {
    const trackColor = color || theme.color.strongText
    return css`
      .ScrollbarsCustom-Track {
        transition: opacity 0.2s ease;
        opacity: ${scroll ? 1 : 0};
        background: ${transparentize(0.9, trackColor)} !important;
        &:hover {
          opacity: 1;
        }
      }

      .ScrollbarsCustom-Thumb {
        background: ${transparentize(0.4, trackColor)} !important;
      }
    `
  }}
`
export default ScrollbarStyled
