import React, { useCallback } from 'react'

import { Radio } from 'antd'

const RadioGroup = ({ value, options, onChange, ...props }) => {
  const handleChange = useCallback(
    ({ target }) => {
      onChange(target.value)
    },
    [onChange]
  )

  return (
    <Radio.Group
      options={options}
      onChange={handleChange}
      value={value}
      optionType="button"
      buttonStyle="solid"
      {...props}
    />
  )
}

export default RadioGroup
