import React, { useRef, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { bool, object, string } from 'prop-types'

import TooltipContent from 'components/Global/Tooltip/TooltipContent'
import TooltipContentPortal from 'components/Global/Tooltip/TooltipContent/TooltipContentPortal'

import Button from '../Button'
import Icon from '../Icon'

const Tooltip = ({
  children,
  button,
  variant,
  preventClickOutside,
  onToggleOpen,
  onChange,
  onSearch,
  clearIcon,
  input,
  search,
  searchValue,
  placeholder,
  valueElement,
  value,
  isOpen,
  id = 'tooltip',
  className,
  width = 300,
  height = 400,
  fixedHeight,
  effect,
  dropdown,
  disabled,
  updatePosition,
  dataCy,
}) => {
  const handleToggleOpen = (e, payload) => {
    e && e.stopPropagation()

    if (onToggleOpen) {
      return onToggleOpen(typeof payload === 'boolean' ? payload : !isOpen)
    }
    _setIsOpen((state) => {
      return typeof payload === 'boolean' ? payload : !state
    })
  }
  const [_isOpen, _setIsOpen] = useState(false)
  const buttonState = useRef()
  const ref = useRef()

  const onButtonClick = (e, payload) => {
    if (disabled) {
      return
    }
    if (button.onClick) {
      return button.onClick(e, payload)
    }
    handleToggleOpen(e, !buttonState.current)
    buttonState.current = !buttonState.current
  }

  const openState = onToggleOpen ? isOpen : _isOpen

  return (
    <div className={`Tooltip ${className} ${isOpen ? 'open' : 'closed'}`} ref={ref} id={id}>
      {button && (
        <Button
          {...button}
          variant={button.variant || variant}
          onClick={onButtonClick}
          isActive={openState}
          label={value ? button.label : placeholder || button.label}
          disabled={disabled}
          dataCy={button.dataCy || `${dataCy || 'Tooltip'}-trigger`}
        />
      )}
      {value && clearIcon && (
        <Icon
          icon="fas fa-xmark"
          variant="clearIcon"
          className="Tooltip__clear"
          onClick={(e) => onChange({}, e)}
          dataCy={`${dataCy || 'Tooltip'}-clear`}
        />
      )}
      {valueElement && (
        <span className="Tooltip__value-element" onClick={handleToggleOpen}>
          {valueElement}
        </span>
      )}
      {input && (
        <Input
          onChange={(e) => onSearch && onSearch(e)}
          onClick={handleToggleOpen}
          isActive={openState}
          value={searchValue || value}
          placeholder={placeholder}
          disabled={disabled}
          data-cy={`${dataCy || 'Tooltip'}-search`}
          prefix={search && <SearchOutlined />}
        />
      )}
      {dropdown ? (
        <TooltipContent
          isOpen={isOpen !== undefined || openState}
          variant={variant}
          effect={effect}
          dataCy={`${dataCy || 'Tooltip'}-content`}
        >
          {typeof children === 'function' ? children({ toggleOpen: _setIsOpen }) : children}
        </TooltipContent>
      ) : (
        openState && (
          <TooltipContentPortal
            isOpen
            parentRef={ref.current}
            variant={variant}
            effect={effect}
            width={width}
            height={height}
            fixedHeight={fixedHeight}
            value={value}
            onToggleOpen={handleToggleOpen}
            preventClickOutside={preventClickOutside}
            updatePosition={updatePosition}
            dataCy={`${dataCy || 'Tooltip'}-portal-content`}
          >
            {children}
          </TooltipContentPortal>
        )
      )}
    </div>
  )
}

Tooltip.propTypes = {
  button: object,
  variant: string,
  preventClickOutside: bool,
}

Tooltip.defaultProps = {}

export default Tooltip
