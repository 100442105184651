import React from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import Field from 'components/Form/components/Field'

import OrganisationReportingDateRange from '../../../../components/OrganisationReportDateRange'
import { OrganisationReportingDateRangeFieldStyled } from './styled'

const OrganisationReportingDateRangeField = ({
  name,
  value,
  defaultValue,
  onChange,
  organisationId,
  size,
  allowCustomRange,
  disabled,
  ...fieldProps
}) => {
  if (name) {
    return (
      <OrganisationReportingDateRangeFieldStyled {...fieldProps}>
        <Controller
          name={name}
          value={value}
          defaultValue={defaultValue}
          render={({ onChange: fieldOnChange, value: fieldValue }) => {
            return (
              <OrganisationReportingDateRange
                value={fieldValue}
                onChange={(range) => {
                  fieldOnChange(range)
                  if (onChange) {
                    onChange(range)
                  }
                }}
                {...{ organisationId, size, allowCustomRange, disabled }}
              />
            )
          }}
        />
      </OrganisationReportingDateRangeFieldStyled>
    )
  }

  return (
    <Field {...fieldProps}>
      <OrganisationReportingDateRange
        {...{ value, defaultValue, onChange, organisationId, size, allowCustomRange, disabled }}
      />
    </Field>
  )
}

OrganisationReportingDateRangeField.propTypes = {
  allowCustomRange: PropTypes.bool,
}

export default OrganisationReportingDateRangeField
