import React from 'react'

import { ReceiverContentStyled } from 'components/Form/components/DndMapper/components/styled'
import Icon from 'components/Global/Icon'

const ReceiverContent = ({ label, value, isMapped, isDraggedOver }) => {
  return (
    <ReceiverContentStyled isMapped={isMapped} isDraggedOver={isDraggedOver}>
      <span className="ReceiverContent__label">{label}</span>
      <Icon icon="fas fa-arrow-right" />
      <span className="ReceiverContent__label">{value}</span>
    </ReceiverContentStyled>
  )
}

export default ReceiverContent
