import React, { useCallback, useEffect } from 'react'

import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Item, Title } from '../../../components/Results'

const Menu = styled.div`
  font-size: 0.8em;
  color: ${({ theme }) => theme.color.softText};
`

export const MenuItemSearchResult = ({
  data: { title, url, menu },
  close,
  clickRef,
  intl,
  ...props
}) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push(url)
    close()
  }, [url, history, close])

  useEffect(() => {
    if (clickRef) {
      clickRef.current = handleClick
    }
  }, [clickRef, handleClick])

  return (
    <Item
      onClick={handleClick}
      icon="far compass"
      iconInfo={intl.formatMessage({ id: 'page' })}
      {...props}
    >
      <Title>{title}</Title>
      <Menu>{menu}</Menu>
    </Item>
  )
}

export default injectIntl(MenuItemSearchResult)
