import styled, { css } from 'styled-components'

export const OnOffControlStyled = styled.div`
  font-size: 1.5rem;
  transition: color 0.2s ease;
  padding: 0 0.5rem;

  .Icon {
    color: ${({ color }) => color};
  }

  ${({ readOnly }) =>
    !readOnly &&
    css`
      cursor: pointer;

      &:hover {
        .Icon {
          color: ${({ theme, color }) => color || theme.color.main};
        }
      }
    `}
`
