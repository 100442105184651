import React from 'react'

import { Form } from 'antd'
import { injectIntl } from 'react-intl'

import { AntSelect } from '../AntDesign'

const SelectField = ({
  label,
  name,
  intl,
  required,
  customRules = [],
  options,
  mode,
  hidden,
  showSearch,
  filterOption,
  ...props
}) => {
  const rules = [...customRules]
  if (required) {
    rules.push({
      required: true,
      message: intl.formatMessage({
        id: mode === 'multiple' ? 'form.select.required' : 'form.select.requiredOne',
      }),
    })
  }
  return (
    <Form.Item style={{ width: '100%' }} name={name} label={label} rules={rules} hidden={hidden}>
      <AntSelect
        {...props}
        mode={mode}
        showArrow
        options={options}
        showSearch={showSearch}
        filterOption={filterOption}
        optionFilterProp="label"
      />
    </Form.Item>
  )
}

export default injectIntl(SelectField)
