import { motion } from 'framer-motion'
import styled from 'styled-components'

export const AccordeonStyled = styled.div``

export const AccordeonHeader = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  padding-right: 1rem;

  &:hover,
  &.active {
  }
`

export const AccordeonContent = styled(motion.div)`
  //min-height: fit-content;
  overflow: hidden;
`
