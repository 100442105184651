import styled from 'styled-components'

const EditableTreeStyled = styled.div``

export const SortableTreeItemStyled = styled.div``

export const TreeItemWrapperStyled = styled.li`
  list-style: none;
  box-sizing: border-box;
  padding-left: ${({ indent }) => indent}px;
  margin-bottom: -1px;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;

    .TreeItem {
      --vertical-padding: 5px;

      padding-right: 24px;
      border-radius: 4px;
      box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0;
        height: 8px;
        border-color: #2389ff;
        background-color: #56a1f8;

        &:before {
          position: absolute;
          left: -8px;
          top: -4px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #2389ff;
          background-color: #ffffff;
        }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem > * {
      box-shadow: none;
      background-color: transparent;
    }
  }

  &:hover {
    .TreeItem {
      border: 1px solid ${({ theme }) => theme.color.softStroke};

      > .Icon {
        opacity: 1;
      }

      .FieldGroup {
        .FormFieldLabel {
          opacity: 1;
        }
      }
    }
  }

  .TreeItem {
    transition: border 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 2rem;
    padding: 0 0.5rem;

    .Action {
      width: 1.5rem;
    }

    > .Icon {
      transition: opacity 0.2s ease, color 0.2s ease;
      opacity: 0;
      margin: auto 0.3rem;
      color: ${({ theme }) => theme.color.softText};
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.color.action};
      }
    }

    .FieldGroup {
      margin-bottom: -1px;
      width: 100%;
      padding: 0 0.2rem;

      .FormField {
        flex: none;
        width: auto;
        min-width: 2.8rem;
        align-items: center;
        &.Input {
          flex: 1;
          align-items: flex-start;
        }
        .ColorPicker {
          padding: 0 0.5rem;
        }
        .Icon {
          margin: 0;
          cursor: pointer;
        }
      }

      .FormFieldLabel {
        margin: 0 0 -0.3rem;
        opacity: 0;
        font-size: 0.7rem;
      }
    }
  }

  .Text {
    flex-grow: 1;
    padding-left: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Count {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.main};
    font-size: 0.8rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainTextColor};
  }

  .disableInteraction {
    pointer-events: none;
  }

  .disableSelection,
  .clone {
    .Text,
    .Count {
      user-select: none;
      -webkit-user-select: none;
    }
  }

  .Collapse {
    svg {
      transition: transform 250ms ease;
    }

    &.collapsed svg {
      transform: rotate(-90deg);
    }
  }
`
export const ActionStyled = styled.span`
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }

  &:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: var(--fill, #788491);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px $focused-outline-color;
  }
`
export default EditableTreeStyled
