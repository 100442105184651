import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

export const MarkdownContainer = styled(ReactMarkdown)`
  font-size: 1.1em;

  ul {
    margin-bottom: 1em;
  }

  li {
    list-style: initial;
    margin-left: 1.5em;
  }
`
