import React from 'react'

import { Progress as AntProgress } from 'antd'
import { useTheme } from 'styled-components'

import round from 'util/round'

import ProgressStyled from './styled'

const getGradient = (percentage, theme) => {
  if (percentage < 35) return theme.color.success
  if (percentage < 90) return theme.color.warning
  return theme.color.error
}

const Progress = ({
  value,
  size,
  comparator = 100,
  strokeColor,
  unit,
  showValue,
  showPercentage = true,
  ...props
}) => {
  const theme = useTheme()
  const percentage = round((value / comparator) * 100, 1) || 0
  return (
    <ProgressStyled className="Progress" size={size} percentage={percentage}>
      <AntProgress
        {...props}
        percent={percentage}
        showInfo={!!showPercentage}
        strokeColor={
          strokeColor || {
            '0%': theme.color.success,
            ...(percentage > 50 && {
              '35%': theme.color.success,
            }),
            ...(percentage > 80 && {
              '60%': theme.color.warning,
            }),
            '100%': getGradient(percentage, theme),
          }
        }
      />
      {showValue && <span className="Progress__value">{value}</span>}
    </ProgressStyled>
  )
}

export default Progress
