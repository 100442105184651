import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import InformationLinesStyled from './styled'

const InformationLines = ({
  dataKey = 'lines',
  subPath,
  selectedIndex,
  buttons = true,
  onChange,
  onClick,
  intl,
}) => {
  const theme = useTheme()
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const lines = get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []

  const handleLineClick = (e, { id, name, index }) => {
    e.stopPropagation()
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: index,
          dataKey: dataKey,
        },
      })
      onClick(e, { id, name, index })
    }
  }

  const onRemoveLine = (e, { index: colIndex }) => {
    e.stopPropagation()
    const newInformationLines = lines.filter((group, index) => index !== colIndex)
    onChange(newInformationLines, dataKey)
  }

  const onAddLine = (type) => {
    const newInformationLines = lines.concat({
      title: intl.formatMessage({ id: 'widget.line' }),
      type: type === 'detail' ? 'Detail' : 'Status',
      color: theme.color.backgroundFront,
      fontColor: theme.color.main,
    })
    onChange(newInformationLines, dataKey)
    const newLineIndex = newInformationLines.length - 1
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: newLineIndex,
        dataKey: dataKey,
      },
    })
    onClick(null, { ...newInformationLines[newLineIndex], index: newLineIndex })
  }

  const lineTypes = [
    {
      label: 'widget.addSingleProperty',
      onClick: () => onAddLine('property'),
    },
    {
      label: 'widget.addEntityDetails',
      onClick: () => onAddLine('detail'),
    },
  ]

  return (
    <InformationLinesStyled className="DatapointGroups">
      {lines.map((line, index) => (
        <Button
          label={
            line.title
              ? line.title.startsWith('intl')
                ? intl.formatMessage({ id: line.title.replace('intl.', '') })
                : line.title
              : line.name || `Line ${index + 1}`
          }
          variant="tag"
          onIconClick={(e) => onRemoveLine(e, { ...line, index })}
          icon={onRemoveLine && 'fas fa-xmark'}
          isActive={selectedIndex === index}
          onClick={(e) => handleLineClick(e, { ...line, index })}
        />
      ))}
      {buttons && (
        <div className="Datapoints__buttons">
          {lineTypes.map(({ name, label, onClick }) => (
            <Button
              key={label}
              className="SmallButton"
              label={intl.formatMessage({
                id: label,
              })}
              name={name}
              onClick={onClick}
              variant="smallButton"
            />
          ))}
        </div>
      )}
    </InformationLinesStyled>
  )
}

export default injectIntl(InformationLines)
