import React, { useCallback } from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { NavButtonStyled } from './styled'

const NavButton = ({
  url,
  onClick,
  type,
  iconComponent = <LeftOutlined />,
  tooltipText,
  intl,
  ...props
}) => {
  const history = useHistory()
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }

    if (url) {
      history.push(url)
    }
  }, [history, url, onClick])

  const title = tooltipText && intl.formatMessage({ id: tooltipText })

  return tooltipText ? (
    <Tooltip title={title}>
      <NavButtonStyled {...props} onClick={handleClick} icon={iconComponent} type={type} />
    </Tooltip>
  ) : (
    <NavButtonStyled {...props} onClick={handleClick} icon={iconComponent} type={type} />
  )
}

export default injectIntl(NavButton)
