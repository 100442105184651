import React, { useCallback, useEffect, useMemo, useState } from 'react'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import ValuePickerStyled from 'components/Form/components/ValuePicker/styled'
import { getLabel } from 'components/Global/FormField/utils/functions'

import FilterDropdown from './components/FilterDropdown'
import DefaulOptionValue from './components/OptionValue'
import { selectOption, useData, useDisplayValue, useFilters } from './hooks'

const filterDropdownRender = (filters) => {
  const key = filters?.reduce((key, filter) => `${key}-${filter.id}:${filter.value}`, '')

  return (menu) => <FilterDropdown key={key} filters={filters} menu={menu} />
}

const ValuePicker = ({
  value,
  id = '',
  options = [],
  query = {
    name: 'DEFAULT_QUERY',
    variables: {},
  },
  error,
  selectionKeys = { value: 'value', label: 'label' },
  placeholder,
  search = true,
  onChange,
  onOptionsLoaded,
  onOptionHover,
  intl,
  className,
  disabled,
  allowClear = false,
  dropdownRender,
  optionComponent,
  persistSearchValue = false,
  open,
  filters: filtersConfig,
  fullWidth = false,
  width,
  height = 360,
  fixedHeight = false,
  disableOnSingleItem = false,
  dropdown,
  valueElement,
  valueKeys,
  isMultiSelect,
  variant = 'ValuePicker',
  dataCy = 'ValuePicker',
}) => {
  const { data } = useData(query, options)
  const [items, setItems] = useState()
  const [openState, setOpenState] = useState()
  const rawData = useMemo(() => items || data || [], [items, data])

  const { filters, onSearch, searchValue, data: filteredOptions } = useFilters(
    rawData,
    filtersConfig,
    {},
    selectionKeys?.label,
    intl
  )

  const isDisabled = disabled || (disableOnSingleItem && rawData.length === 1)

  useEffect(() => {
    if (data && typeof onOptionsLoaded === 'function') {
      const newItems = onOptionsLoaded(data)
      if (newItems !== undefined && newItems.length) {
        setItems(newItems)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleOpenState = useCallback(
    (payload) => {
      setOpenState(payload)
    },
    [setOpenState]
  )

  const handleChange = useCallback(
    ({ value: item, ...rest }, e) => {
      e.stopPropagation()

      if (!item) {
        return onChange(null, null, filteredOptions)
      }

      const { newValue, option } = selectOption({
        item,
        value,
        selectionKeys,
        valueKeys,
        isMultiSelect,
        options: filteredOptions,
      })
      if (!persistSearchValue || searchValue === '') {
        onSearch({ target: { value: null } })
      }
      handleOpenState(false)
      onChange(newValue, option, filteredOptions)
    },
    [
      onChange,
      filteredOptions,
      selectionKeys,
      handleOpenState,
      persistSearchValue,
      searchValue,
      onSearch,
      isMultiSelect,
      valueKeys,
      value,
    ]
  )

  const dropdownRenderComp = useMemo(() => {
    return filters && !dropdownRender ? filterDropdownRender(filters) : dropdownRender
  }, [filters, dropdownRender])

  const OptionComp = optionComponent || DefaulOptionValue
  const displayValue = useDisplayValue(rawData, selectionKeys, value, intl)

  const renderedOptions = useMemo(() => {
    return filteredOptions.map((option) => {
      const optionValue = selectionKeys?.value ? get(option, selectionKeys.value) : option
      const selected = optionValue === value
      const disabled = option?.disabled || false
      const label = selectionKeys ? getLabel(option, selectionKeys.label, intl) : option
      const image = selectionKeys?.image ? (
        <img
          alt={getLabel(option, selectionKeys.label, intl)}
          style={{ height: '20px', width: '30px' }}
          src={`${selectionKeys.imageUrlPrefix}${option[selectionKeys.image].toLowerCase()}${
            selectionKeys.imageUrlSuffix
          }`}
        />
      ) : undefined

      const wrapperProps = {}
      if (!disabled) {
        wrapperProps.onClick = (e) => handleChange({ value: optionValue, option }, e)
      }
      if (onOptionHover) {
        wrapperProps.onMouseEnter = (e) => onOptionHover(e, option)
        wrapperProps.onMouseLeave = (e) => onOptionHover(e, null)
      }

      return (
        <div {...wrapperProps} id={option.id} data-cy={`${dataCy}-${option.id}`}>
          <OptionComp {...{ value: optionValue, label, image, option, selected, disabled }} />
        </div>
      )
    })
  }, [filteredOptions, value, handleChange, intl, selectionKeys, dataCy, onOptionHover])

  return (
    <ValuePickerStyled
      id={id}
      defaultValue={displayValue}
      value={open || openState ? '' : displayValue}
      isOpen={open ?? openState}
      placeholder={
        placeholder || (search && intl.formatMessage({ id: 'componentCaptions.searchToSelect' }))
      }
      onChange={handleChange}
      onSearch={search ? onSearch : () => null}
      searchValue={searchValue}
      onToggleOpen={handleOpenState}
      error={error}
      className={className}
      disabled={isDisabled}
      fullWidth={fullWidth}
      dropdownRender={dropdownRenderComp}
      clearIcon={allowClear}
      search={search}
      input
      variant={variant}
      width={width}
      height={height}
      dropdown={dropdown}
      fixedHeight={fixedHeight}
      valueElement={valueElement}
      dataCy={dataCy}
    >
      {dropdownRenderComp ? dropdownRenderComp(renderedOptions) : renderedOptions}
    </ValuePickerStyled>
  )
}

ValuePicker.propTypes = {
  fullWidth: PropTypes.bool,
  allowClear: PropTypes.bool,
}

export default injectIntl(ValuePicker)
