import styled from 'styled-components'

import Button from '../../Button/styled'
import Selector from './index'

const CheckboxSelector = styled(Selector)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Button} {
    transition: background 0.2s ease, border 0.2s ease, color 0.2s ease;
    border: 1px solid ${({ theme }) => theme.color.softStroke};
    border-radius: 1rem;
    padding: 0 0.8rem 0 0.4rem;
    margin: 0 0.5rem 0.5rem 0;
    color: ${({ theme }) => theme.color.softText};
    line-height: 1.5;
    &:before {
      transition: background 0.2s ease;
      content: '';
      width: 0.7rem;
      min-width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      margin-right: 0.5rem;
      background: ${({ theme }) => theme.color.softStroke};
    }
    &:hover,
    &.active {
      background: ${({ theme }) => theme.color.softerStroke};
      color: ${({ theme }) => theme.color.mainText};
      &:before {
        background: ${({ theme }) => theme.color.main};
      }
    }
  }
`
export default CheckboxSelector
