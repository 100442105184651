import React from 'react'

import ReactDOM from 'react-dom'
import { injectIntl } from 'react-intl'

import ActionConfirmation from 'components/Dashboard/components/ActionConfirmation'
import { usePortal } from 'components/Global/hooks'

const ActionConfirmationPortal = ({ parentRef, ...props }) => {
  const target = usePortal('main-panel')
  const location = parentRef && parentRef.getBoundingClientRect()
  return ReactDOM.createPortal(<ActionConfirmation {...props} location={location} />, target)
}

export default injectIntl(ActionConfirmationPortal)
