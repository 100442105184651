import React from 'react'

import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import { useLocaleNumber } from 'util/numbers'

import Field from 'components/Form/components/Field'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import NumberInput from 'components/Form/components/NumberInput'
import Skeleton from 'components/Form/components/Skeleton/styled'

const NumberField = ({
  value,
  defaultValue,
  name,
  label,
  validation,
  onBlur,
  viewRender,
  editRender,
  showRequired,
  disabled,
  onChange,
  rows,
  step,
  placeholder,
  formatter,
  parser,
  suffix,
  min,
  max,
  addonAfter,
  ...fieldProps
}) => {
  const localizeNumber = useLocaleNumber()

  return (
    <Field {...fieldProps} label={label} required={showRequired && validation?.required}>
      {({ errors, isEditMode, watch, loading }) => {
        const rawFieldValue = value === undefined && name !== undefined ? watch(name) : value
        const fieldValue =
          typeof rawFieldValue === 'string' && rawFieldValue !== ''
            ? parseFloat(rawFieldValue)
            : rawFieldValue
        const formattedValue =
          !fieldValue && fieldValue !== 0 ? fieldValue : localizeNumber(fieldValue)

        const error = get(errors, name)

        if (loading) {
          return <Skeleton width="200px" />
        }

        if (!name && isEditMode) {
          const elementProps = {
            onChange,
            onBlur,
            value,
            disabled,
            step,
            rows,
            placeholder,
            formatter,
            parser,
            min,
            max,
            suffix,
            addonAfter,
          }

          return <NumberInput {...elementProps} />
        }

        if (isEditMode && name) {
          const field = (
            <Controller
              defaultValue={defaultValue}
              name={name}
              rules={{ min, max, ...validation }}
              render={({ onChange: controllerOnChange, value: controllerValue }) => {
                const parsedCcontrollerValue =
                  typeof controllerValue === 'string' && controllerValue !== ''
                    ? parseFloat(controllerValue)
                    : controllerValue

                const handleOnChange = (value) => {
                  controllerOnChange(value)
                  onChange && onChange(value)
                }

                const elementProps = {
                  onChange: handleOnChange,
                  onBlur,
                  value: parsedCcontrollerValue,
                  disabled,
                  step,
                  rows,
                  placeholder,
                  formatter,
                  parser,
                  min,
                  max,
                  suffix,
                  error,
                  addonAfter,
                }

                return <NumberInput {...elementProps} />
              }}
            />
          )

          const message = <FieldMessage message={error} label={label} />

          return editRender ? editRender({ field, message }) : [field, message]
        }

        return viewRender ? (
          viewRender(fieldValue, formattedValue)
        ) : formattedValue || typeof formattedValue === 'number' ? (
          <span>
            {formattedValue}
            {suffix ? ` ${suffix}` : null}
          </span>
        ) : null
      }}
    </Field>
  )
}

export default NumberField
