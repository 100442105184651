import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { Chart } from 'components/Global/Tooltip/TooltipContent/Chart.styled'
import { ColorPicker } from 'components/Global/Tooltip/TooltipContent/ColorPicker.styled'
import { DashboardMenu } from 'components/Global/Tooltip/TooltipContent/DashboardMenu.styled'
import { Datapoints } from 'components/Global/Tooltip/TooltipContent/Datapoints.styled'
import { EditableTree } from 'components/Global/Tooltip/TooltipContent/EditableTree.styled'
import { IconCellDropdown } from 'components/Global/Tooltip/TooltipContent/IconCellDropdown.styled'
import { IconPicker } from 'components/Global/Tooltip/TooltipContent/IconPicker.styled'
import { Notifications } from 'components/Global/Tooltip/TooltipContent/Notifications.styled'
import { TimeSelection } from 'components/Global/Tooltip/TooltipContent/TimeSelection.styled'
import { ValuePicker } from 'components/Global/Tooltip/TooltipContent/ValuePicker.styled'

const variants = {
  ColorPicker,
  ValuePicker,
  HeaderValuePicker: ValuePicker,
  Table: ValuePicker,
  Datapoints,
  TimeSelection,
  IconPicker,
  IconCellDropdown,
  Chart,
  Notifications,
  EditableTree,
  DashboardMenu,
  Tags: css`
    ${ValuePicker}
    padding: .5rem;
  `,
}
const TooltipContentStyled = styled(motion.div)`
  transform-origin: ${({ position }) => position?.transformOrigin || 'top'} !important;
  position: fixed;
  width: ${({ width }) => width}px;
  min-height: ${({ minHeight = '70px' }) => minHeight};
  max-height: ${({ height }) => height}px;
  height: ${({ height, fixedHeight }) => fixedHeight && `${height}px`};
  top: ${({ position }) => position?.top};
  left: ${({ position }) => position?.left};
  right: ${({ position }) => position?.right};
  bottom: ${({ position }) => position?.bottom};
  z-index: 1200;
  ${({ variant }) => variants[variant]}
`
export default TooltipContentStyled
