import dayjs from 'dayjs'

import { getGranularityMomentUnit } from '../../components/TimePicker/functions'
import { getZones } from '../common/helpers'
import {
  createDatapointGroups,
  getGroupsCalculatedData,
  getHighChartSeries,
  mergeHistoryData,
} from './helpers'

export const getSeriesFromDatapoints = ({
  datapoints,
  datapointsGroups,
  datapointsData,
  targetData,
  targetDetails,
  yAxisUnits,
  startTime,
  chartStyle = 'area',
  graphOpacity,
  showInLegend,
  stacking,
  constants = [],
  intl,
}) => {
  const zones = getZones(constants)
  const { groups, remainingDatapoints } =
    datapointsGroups?.length && datapointsData?.length
      ? createDatapointGroups({
          datapoints,
          datapointsData,
          datapointsGroups,
          targetData,
          targetDetails,
          startTime,
          intl,
        })
      : {
          groups: [],
          remainingDatapoints: mergeHistoryData({
            datapointsData,
            datapoints,
            targetData,
            targetDetails,
            startTime,
            intl,
          }),
        }

  const groupedData = getGroupsCalculatedData(groups)

  const combinedData = [...groupedData, ...remainingDatapoints]

  return getHighChartSeries({
    datapointData: combinedData,
    constants: constants,
    zones,
    showInLegend,
    chartStyle,
    yAxisUnits,
    graphOpacity,
    stacking,
    data: datapointsData,
  })
}

export const getDashboardLinkPayload = ({
  dashboardLink,
  point,
  unit,
  defaultGranularity,
  datapoints,
  comparators,
  minComparators,
  maxComparators,
  linkDatapoints,
  title,
}) => {
  const granularity = {
    granularityValue:
      dashboardLink?.defaultGranularityValue || defaultGranularity?.granularityValue || 1,
    granularity: dashboardLink?.defaultGranularity || defaultGranularity?.granularity || 'h',
  }
  const startTime = dashboardLink.unit
    ? dayjs(point.time)
        .subtract(dashboardLink.amount || 1, dashboardLink.unit)
        .valueOf()
    : point.time
  const endTime = dashboardLink.durationUnit
    ? dayjs(startTime)
        .add(dashboardLink.duration || 1, dashboardLink.durationUnit)
        .valueOf()
    : dayjs(startTime)
        .add(granularity.granularityValue, getGranularityMomentUnit(granularity.granularity))
        .valueOf()
  return {
    pathname: dashboardLink.url,
    state: {
      datapoints,
      comparators,
      minComparators,
      maxComparators,
      linkDatapoints,
      pointTime: point.time,
      startTime,
      endTime,
      pointValue: point.value,
      pointColor: point.color,
      granularity,
      title,
      unit,
    },
  }
}
