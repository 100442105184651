import React, { useEffect, useState } from 'react'

import DndMapperStyled, { IconContainer } from 'components/Form/components/DndMapper/styled'
import Icon from 'components/Global/Icon'
import { ContentFlex, ContentLabel } from 'components/Layout/Content'

import AvailableContainer from './components/AvailableContainer'
import ReceiverContainer from './components/ReceiverContainer'

const DndMapper = ({ valuesToMatchTo, availableItems, onChangeConfig }) => {
  const [remainingItems, setRemainingItems] = useState(availableItems)
  const [configuredItems, setConfiguredItems] = useState({
    tags: [],
    datapoints: [],
    id: null,
    timestamp: null,
    internalId: null,
  })

  useEffect(() => {
    onChangeConfig(configuredItems)
  }, [configuredItems, onChangeConfig])

  const removeRemainingItem = (item, csvColumn, type) => {
    const currentItems = remainingItems.filter((availableItem) => availableItem !== item)
    setRemainingItems(currentItems)
    let currentConfig = { tags: [], datapoints: [], id: null, timestamp: null, internalId: null }
    configuredItems.tags.forEach((config) => {
      currentConfig.tags.push({ [Object.keys(config)[0]]: Object.values(config)[0] })
    })

    currentConfig.datapoints = configuredItems.datapoints

    currentConfig.id = configuredItems.id
    currentConfig.internalId = configuredItems.internalId
    currentConfig.timestamp = configuredItems.timestamp

    type === 'tag'
      ? currentConfig.tags.push({ [csvColumn]: item })
      : type === 'datapoint'
      ? currentConfig.datapoints.push({
          name: csvColumn,
          value: item,
          presetId: valuesToMatchTo.filter((dp) => dp.title === csvColumn)[0].presetId,
        }) // + preset
      : type === 'id'
      ? (currentConfig.id = item)
      : type === 'internalId'
      ? (currentConfig.internalId = item)
      : (currentConfig.timestamp = item)
    setConfiguredItems(currentConfig)
  }

  const addRemainingItem = (item, csvColumn) => {
    const currentItems = remainingItems
    currentItems.push(item)
    setRemainingItems(currentItems)
    let currentConfig = { tags: [], datapoints: [], id: null, timestamp: null, internalId: null }
    configuredItems.tags.forEach((config) => {
      if (Object.keys(config)[0] !== csvColumn) {
        currentConfig.tags.push({ [Object.keys(config)[0]]: Object.values(config)[0] })
      }
    })

    currentConfig.datapoints = configuredItems.datapoints.filter((dp) => dp.name !== csvColumn)
    currentConfig.id = configuredItems.id
    currentConfig.internalId = configuredItems.internalId
    currentConfig.timestamp = configuredItems.timestamp

    setConfiguredItems(currentConfig)
  }

  return (
    <DndMapperStyled>
      <ContentFlex col="5/1/10" padding={false}>
        <ContentFlex border="auto" borderRadius="auto" className="DndMapper__column">
          <ContentLabel>Available CSV columns</ContentLabel>
          <AvailableContainer availableItems={remainingItems} />
        </ContentFlex>
        <ContentFlex padding={false}>
          <IconContainer border="auto" borderRadius="auto">
            <Icon icon="fas fa-arrow-right" />
          </IconContainer>
        </ContentFlex>

        <ContentFlex border="auto" borderRadius="auto" className="DndMapper__column">
          <ContentLabel>Mandatory data</ContentLabel>
          <ReceiverContainer
            valuesToMatchTo={valuesToMatchTo}
            handleRemove={removeRemainingItem}
            handleAdd={addRemainingItem}
          />
        </ContentFlex>
      </ContentFlex>
    </DndMapperStyled>
  )
}

export default DndMapper
