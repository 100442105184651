import React from 'react'

import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'

import Button from '../../Button'
import { FormFieldLabel } from '../styled'
import DatapointGroup from './DatapointGroup'

const DatapointGroupList = ({
  label,
  selectedDatapointMap,
  dataKey,
  groups,
  datapoints,
  datapointGroups,
  intl,
  buttons,
  onDatapointClick,
  onDatapointHover,
  onRemoveDatapoint,
  onChange,
  action,
  onClick,
}) => {
  const onAddGroup = () => {
    const groups = datapointGroups.concat({
      name: intl.formatMessage({ id: 'widget.group' }),
      datapoints: [],
      id: uniqid(),
    })
    onChange(groups, `${dataKey}Groups`)
    const newGroupIndex = groups.length - 1
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: newGroupIndex,
        dataKey: `${dataKey}Groups`,
      },
    })
    onClick(null, { ...groups[newGroupIndex], type: 'groups', typeIndex: newGroupIndex })
  }
  const onRemoveGroup = (e, { groupIndex }) => {
    e.stopPropagation()
    const newGroupArray = datapointGroups.filter((group, index) => index !== groupIndex)
    onChange(
      datapoints.map(({ groupId, ...dp }) => ({
        ...dp,
        ...(groupId && groupId !== datapointGroups[groupIndex].id && { groupId }),
      })),
      dataKey
    )
    onChange(newGroupArray, `${dataKey}Groups`)
  }
  return (
    <>
      <FormFieldLabel as="h4">
        {intl.formatMessage({ id: label?.formatted || 'widget.groups' })}
      </FormFieldLabel>
      {groups.map((group, index) => (
        <DatapointGroup
          key={`group-${index}`}
          group={group}
          groupDataKey={`${dataKey}Groups`}
          dataKey={dataKey}
          selectedDatapointMap={selectedDatapointMap}
          groupIndex={index}
          onDatapointClick={onDatapointClick}
          onDatapointHover={onDatapointHover}
          onRemoveDatapoint={onRemoveDatapoint}
          onRemoveGroup={onRemoveGroup}
        />
      ))}
      {buttons && (
        <div className="Datapoints__buttons">
          <Button
            className="SmallButton"
            label={intl.formatMessage({
              id: 'widget.addGroup',
            })}
            onClick={onAddGroup}
            variant="smallButton"
          />
        </div>
      )}
    </>
  )
}

export default injectIntl(DatapointGroupList)
