import { transparentize } from 'polished'
import { css } from 'styled-components'

export const tag = css`
  transition: box-shadow 0.2s ease, transform 0.2s ease, opacity 0.2s ease, background 0.2s ease;
  flex-direction: row-reverse;
  margin: 0.2rem 0.5rem 0.3rem 0;
  max-width: 100%;
  width: fit-content;
  white-space: nowrap;
  padding: 0 0.5rem !important;
  color: ${({ theme }) => theme.color.mainText};
  background: ${({ theme }) => theme.color.backgroundFront};
  border-top: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-bottom: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-left: 4px solid ${({ color, theme }) => color || theme.color.softText};
  border-right: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadowElevation1(theme.darkMode)};
  font-weight: 100;
  > .Icon {
    display: flex;
    align-items: center;
    padding: 0 0 0 0.2rem;
    margin-right: -0.3rem;
    svg {
      width: 1.5rem;
    }
  }
  &:hover {
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    transform: scale(1.05);
    background: ${({ color, theme }) =>
      color ? transparentize(0.95, color) : theme.color.backgroundHighlight};
    color: ${({ color, theme }) => color || theme.color.softText};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ color, theme }) => color || theme.color.softText};
      color: rgba(255, 255, 255, 0.9) !important;
      &:hover {
        color: ${({ color, theme }) => color || theme.color.softText} !important;
      }
    `}
  .Button__label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    order: 1;
  }
`
