import React from 'react'

import { Image } from 'antd'
import { Controller } from 'react-hook-form'

import imageStyleUrl, { imageStyles } from 'util/imageStyleUrl'

import Field from 'components/Form/components/Field'
import Icon from 'components/Global/Icon'

import { ImageThumb, ImageThumbButtons } from './styled'

const ImageField = ({ value, name, width, ...fieldProps }) => {
  return (
    <Field {...fieldProps} name={name}>
      {({ control, watch, isEditMode }) => {
        const fieldValue = value || watch(name)
        return (
          <Controller
            control={control}
            name={`${name}.deleted`}
            render={({ onChange, value: controllerValue }) => {
              const handleToggleDelete = () => {
                onChange(!controllerValue)
              }
              if (isEditMode) {
                return (
                  <ImageThumb width={width} deleted={fieldValue.deleted}>
                    <Icon
                      className="ImageThumb__thumb"
                      width={150}
                      icon={fieldValue.file?.key}
                      onClick={handleToggleDelete}
                    />
                    <ImageThumbButtons>
                      <Icon icon={fieldValue.deleted ? 'lnr lnr-undo' : 'lnr lnr-trash'} />
                    </ImageThumbButtons>
                  </ImageThumb>
                )
              }
              return (
                <ImageThumb width={width}>
                  <Image
                    className="ImageThumb__thumb"
                    src={imageStyleUrl(fieldValue.file.key, imageStyles.large)}
                  />
                  <ImageThumbButtons>
                    <Icon icon="lnr lnr-eye" />
                  </ImageThumbButtons>
                </ImageThumb>
              )
            }}
          />
        )
      }}
    </Field>
  )
}

export default ImageField
