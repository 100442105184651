import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import ButtonList from '../ButtonList'
import Empty from '../Empty'
import { useOnClickOutside } from '../hooks'
import Icon from '../Icon'
import OptionButtonStyled from './styled'
import OptionButtonWrapperStyled from './styled.OptionButtonWrapper'

const OptionButton = ({
  name,
  action,
  icon,
  label,
  className,
  variant,
  buttons,
  url,
  dataCy,
  isActive,
  onClick,
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => setShowOptions(false))

  const onButtonClick = (e, payload) => {
    e.stopPropagation()
    if (buttons) {
      setShowOptions((state) => !state)
    }
    onClick(e, payload || { name, action })
  }

  return (
    <OptionButtonWrapperStyled
      className={`Button ButtonWrapper ${className}`}
      as={buttons ? 'div' : Empty}
      variant={variant}
    >
      <OptionButtonStyled
        as={url ? 'a' : 'span'}
        className={`Button ${className}`}
        href={url}
        variant={variant}
        isActive={isActive}
        onClick={onClick && onButtonClick}
        data-cy={dataCy}
      >
        {icon && <Icon icon={icon} onClick={onClick && onButtonClick} />}
        {label && <span className="Button__label">{label}</span>}
      </OptionButtonStyled>
      {buttons && (
        <ButtonList ref={ref} buttons={buttons} onButtonClick={onButtonClick} show={showOptions} />
      )}
    </OptionButtonWrapperStyled>
  )
}

OptionButton.propTypes = {
  name: PropTypes.string,
  action: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
  isActive: PropTypes.bool,
}

OptionButton.defaultProps = {}

export default OptionButton
