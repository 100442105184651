import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import { ghgScope3EnabledCategories } from 'config'

const QUERY_ACTIVITY_CATEGORIES = gql`
  query ActivityCategories {
    activityCategories {
      id
      name
      order
    }
  }
`

export const useActivities = () => {
  const { data, ...rest } = useQuery(QUERY_ACTIVITY_CATEGORIES)

  const sortedData = useMemo(() => {
    const list = data?.activityCategories
      ? data?.activityCategories.map((item) => ({
          ...item,
          enabled: ghgScope3EnabledCategories.includes(parseInt(item.id)),
        }))
      : undefined
    return list ? sortBy(list, 'order') : undefined
  }, [data])

  return { data: sortedData, ...rest }
}
