import React, { useCallback } from 'react'

import { injectIntl } from 'react-intl'

import Button from 'components/Global/Button'
import FormField from 'components/Global/FormField'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import { useDashboard } from '../../DashboardProvider'
import { MicroWidgetEdgeConfiguratorStyled } from './styled'

const MicroWidgetEdgeConfigurator = ({ onButtonClick, intl }) => {
  const {
    state: { elementInEditMode = { preview: {}, selectedDatapointMap: null } },
    action,
  } = useDashboard()

  const selectedItem =
    elementInEditMode.selectedDatapointMap.datapoints > -1
      ? elementInEditMode.preview.datapoints[elementInEditMode.selectedDatapointMap.datapoints]
      : { edges: [] }
  const selectedEdge =
    selectedItem.edges && elementInEditMode.selectedDatapointMap.edges > -1
      ? selectedItem.edges[elementInEditMode.selectedDatapointMap.edges]
      : {}

  const handleDatapointClick = (e, { index }) => {
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: index,
        dataKey: 'edges',
      },
    })
  }

  const handleEdgeUpdate = (payload) => {
    action({
      type: 'ON_PREVIEW_UPDATE',
      payload: {
        value: payload.value,
        graph: {
          dataKey: payload.dataKey,
          subPath: `datapoints[${elementInEditMode.selectedDatapointMap.datapoints}].edges[${elementInEditMode.selectedDatapointMap.edges}]`,
        },
      },
    })
  }

  const onPrevClick = useCallback(() => {
    if (onButtonClick) {
      return onButtonClick({ name: 'navigation', value: 1 })
    }
  }, [onButtonClick])

  return (
    <MicroWidgetEdgeConfiguratorStyled className="MicroWidgetEdgeConfigurator">
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.connectedDatapoints' })}</FormFieldLabel>
      <div className="MicroWidgetEdgeConfigurator__datapoints">
        {selectedItem.edges &&
          selectedItem.edges.map(({ id, name, color, ...edge }, index) => (
            <Button
              key={id}
              label={name}
              variant="tag"
              isActive={index === elementInEditMode.selectedDatapointMap.edges}
              color={color}
              onClick={(e) => handleDatapointClick(e, { ...edge, id, name, color, index })}
            />
          ))}
      </div>
      <FormField
        value={selectedEdge?.hoverHighlight}
        dataKey="hoverHighlight"
        col="col2"
        label={{ formatted: 'widget.highlightOnHover' }}
        componentName="Switch"
        onChange={handleEdgeUpdate}
      />
      <FormField
        value={selectedEdge?.inheritColor}
        dataKey="inheritColor"
        col="col2"
        label={{ formatted: 'widget.inheritColor' }}
        componentName="Switch"
        onChange={handleEdgeUpdate}
      />
      <div className="DatapointConfigurator__buttons">
        <Button
          label={intl.formatMessage({ id: 'widget.back' })}
          variant="mainButton"
          onClick={onPrevClick}
        />
      </div>
    </MicroWidgetEdgeConfiguratorStyled>
  )
}

export default injectIntl(MicroWidgetEdgeConfigurator)
