import styled, { css } from 'styled-components'

import { CustomerSelector } from './styled.CustomerSelector'

const variants = {
  CustomerSelector,
}
const DropdownValueStyled = styled.div`
  ${({ showArrow }) =>
    showArrow &&
    css`
      display: flex;
      align-items: center;
    `}
  ${({ variant }) => variants[variant]}
`
export default DropdownValueStyled
