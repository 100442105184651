import { buttonSizes } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.navigation',
    category: 'widget.controls',
    keywords: ['navigation', 'link', 'url'],
    img: '/resources/images/widgets/navigation.png',
    info: {
      'fr-FR':
        '<h2>Navigation</h2>\n' +
        '<p>Le widget de navigation est couramment utilisé pour naviguer vers une URL personnalisée.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        '<li><strong>Afficher le séparateur</strong> Afficher ou non une ligne de séparation horizontale</li>\n' +
        "<li><strong>Couleur d'arrière-plan</strong> Sélectionnez la couleur d'arrière-plan du widget</li>\n" +
        '<li><strong>Couleur de la police</strong> Sélectionnez la couleur de la police du widget</li>\n' +
        '<li><strong>Taille</strong> Sélectionnez la taille du widget</li>\n' +
        "<li><strong>Lien</strong> Définir un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le widget</li>\n" +
        '</ul>',
      'en-US':
        '<h2>Navigation</h2>\n' +
        '<p>The Navigation widget is commonly used to navigate to a custom URL.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Display a horizontal divider line or not</li>\n' +
        '<li><strong>Background Color</strong> Select the background color for the widget</li>\n' +
        '<li><strong>Font Color</strong> Select the font color for the widget</li>\n' +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the widget</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Navigatie</h2>\n' +
        '<p>De navigatiewidget wordt vaak gebruikt om naar een aangepaste URL te navigeren.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Widgets titel</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Geef wel of geen horizontale scheidingslijn weer</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Selecteer de achtergrondkleur voor de widget</li>\n' +
        '<li><strong>Letterkleur</strong> Selecteer de letterkleur voor de widget</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de widget wordt geklikt</li>\n' +
        '</ul>',
    },
    default: {
      type: 'NavigationWidget',
      title: 'widget.navigation',
      icon: 'fas fa-info',
      hideValue: true,
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
    },
    preview: {
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.centeredText' },
        col: 'col2',
        dataKey: 'centeredText',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.general',
        componentName: 'NavigationTypeSelector',
        label: { formatted: 'widget.navigationType' },
        componentProps: {},
      },
    ],
  },
}

export default config
