import React from 'react'

import FormField from '../../../Global/FormField'

export const HighlightConfigurator = ({ value = {}, onChange }) => {
  return (
    <div className="HighlightConfigurator">
      <FormField
        value={value.color}
        dataKey="color"
        col="col2 first"
        label={{ formatted: 'widget.color' }}
        componentName="ColorPicker"
        onChange={onChange}
      />
      <FormField
        value={value.circle}
        dataKey="circle"
        col="col2"
        label={{ formatted: 'widget.circle' }}
        componentName="Switch"
        onChange={onChange}
      />
      <FormField
        value={value.opacity || 1}
        dataKey="opacity"
        label={{ formatted: 'widget.opacity' }}
        componentName="RangeSlider"
        componentProps={{ min: 0, max: 1, step: 0.1 }}
        onChange={onChange}
      />
      <FormField
        value={value.captions}
        dataKey="captions"
        label={{ formatted: 'widget.captions' }}
        componentName="CaptionEditor"
        componentProps={{ columns: ['value', 'color'] }}
        onChange={onChange}
      />
      <FormField
        value={value.edges}
        dataKey="edges"
        componentName="MicroWidgetEdges"
        componentProps={{
          section: {
            name: 'edges',
            componentName: 'MicroWidgetEdgeConfigurator',
          },
        }}
        onChange={onChange}
      />
    </div>
  )
}
