import React from 'react'

import { injectIntl } from 'react-intl'

import AgGridStatusCellStyled from './styled'

const AgGridStatusCell = ({ label, ...props }) => {
  return (
    <AgGridStatusCellStyled className="AgGridStatusCell" {...props}>
      <div className="AgGridStatusCell__container">
        <span />
        {label && <label>{label}</label>}
      </div>
    </AgGridStatusCellStyled>
  )
}

export default injectIntl(AgGridStatusCell)
