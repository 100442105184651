import React from 'react'

import { bool, func, number, object, oneOfType, string } from 'prop-types'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Icon from '../Icon'
import ButtonStyled from './styled'

const Button = ({
  name,
  action,
  icon,
  iconSize,
  label,
  color,
  className,
  variant,
  url,
  as,
  isActive,
  isPrimary,
  isThird,
  scrollOffset,
  disabled,
  children,
  onClick,
  onHover,
  onIconClick,
  stopPropagation = true,
  target,
  dataCy,
  theme,
  ...props
}) => {
  const isAnchorLink = url && url.startsWith('#')
  const _onClick = (e) => {
    stopPropagation && e.stopPropagation()
    !disabled && onClick && onClick(e, { name, action })
  }

  return (
    <ButtonStyled
      {...props}
      as={as || (isAnchorLink ? AnchorLink : url ? url.startsWith('http') && 'a' : 'span')}
      className={`Button ${className || ''} ${variant || ''} ${isActive ? 'active' : ''} ${
        isPrimary ? 'primary' : 'secondary'
      }`}
      to={url}
      href={url}
      offset={scrollOffset}
      variant={variant}
      isActive={isActive}
      isPrimary={isPrimary}
      isThird={isThird}
      disabled={disabled}
      color={color}
      onClick={_onClick}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseEnter={(e) => onHover && onHover(e, 'enter')}
      onMouseLeave={(e) => onHover && onHover(e, 'leave')}
      target={target}
      data-cy={dataCy}
      theme={theme}
    >
      {icon && <Icon icon={icon} onClick={onIconClick || _onClick} size={iconSize} />}
      {label && <span className="Button__label">{label}</span>}
      {children}
    </ButtonStyled>
  )
}

Button.propTypes = {
  icon: string,
  name: string,
  label: oneOfType([string, number]),
  action: object,
  className: string,
  variant: string,
  url: string,
  isActive: bool,
  disabled: bool,
  onClick: func,
}

export default Button
