import { transparentize } from 'polished'
import { css } from 'styled-components'

const speedMap = {
  slow: '0.8s',
  fast: '0.4s',
}

export const status = (status, color, blinking, blinkingSpeed) => css`
  width: .8rem;
  min-width: .8rem;
  min-height: .8rem;
  max-width: .8rem;
  max-height: .8rem;
  height: .8rem;
  transition: transform .2s ease;
  margin: auto;
  background: ${({ theme }) => color || theme.color[status]};
  box-shadow: 0 0 1px 2px ${({ theme }) =>
    transparentize(0.5, color || theme.color[status] || theme.shadowBaseColor)};
  border-radius: 100%;
  &:hover {
    transform: scale(1.2);
  }
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  animation: ${() =>
    blinking && `${speedMap[blinkingSpeed] || '0.8s'} ease 0s infinite normal none running pulse`};
}
`
