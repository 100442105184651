export default {
  etl1: 'Le lot {name} a commencé',
  etl0: 'Le lot {name} est terminé et a pris {duration}sec',
  report1: 'Le rapport {name} est en cours de génération',
  report0: 'Le rapport {name} est disponible et a pris {duration}sec',
  notificationsCount: 'Vous avez {amount} notifications',
  notificationsCountSingle: 'Vous avez 1 notification',
  operationInputLimit:
    'L\'opération "{operation}" ne peut pas contenir plus de {max} entrées. Les entrées restantes seront supprimées.',
  itemsUpdated: 'articles mis à jour.',
  fieldNotAvailableForThisConfiguration:
    'Le champ "{field}" n\'est pas disponible pour cette configuration',
}
