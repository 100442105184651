import { useState } from 'react'

import get from 'lodash/get'

export const useDetailPortal = (detailDataKey = 'id') => {
  const [detail, setDetail] = useState()
  return {
    detailId: detail && get(detail.data, detailDataKey),
    setDetail,
    createMode: detail?.createMode,
  }
}
