import React, { useCallback, useEffect, useState } from 'react'

import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

import DropdownArrow from '../Dropdown/DropdownArrow/styled'
import Icon from '../Icon'
import { Tree } from '../Tree'
import { TreeItemButton, TreeItemStyled, TreeItemWrapper } from './styled'

const TreeItem = ({
  treeItem,
  treeItemComponent,
  treeItemComponentProps,
  selectedKeys,
  level,
  variant,
  elementHeight,
  screenSize,
  isOpen,
  children,
  onButtonClick,
  onTreeOpenClick,
  openItems,
  openIcon,
  path,
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(openItems?.includes(path))
  useEffect(() => setDropdownIsOpen(openItems?.includes(path)), [openItems, path])

  const { children: treeItemChildren = [] } = treeItem
  const variants = {
    enter: {
      opacity: 0,
      height: 0,
    },
    active: {
      opacity: 1,
      height: elementHeight,
    },
    exit: {
      opacity: 0,
      height: 0,
    },
  }
  const onItemClick = useCallback(() => {
    onButtonClick && onButtonClick(treeItem)
  }, [onButtonClick, treeItem])

  const handleTreeOpenClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onTreeOpenClick && onTreeOpenClick(treeItem, path)
    setDropdownIsOpen((state) => !state)
  }

  const itemProps =
    typeof treeItemComponentProps === 'function'
      ? treeItemComponentProps(treeItem)
      : treeItemComponentProps

  return (
    <TreeItemStyled
      className={`TreeItem level${level}`}
      variant={variant}
      isOpen={level === 0 || isOpen}
      dropdownIsOpen={dropdownIsOpen}
      level={level}
      elementHeight={elementHeight}
    >
      <AnimatePresence>
        {isOpen && (
          <TreeItemWrapper
            className="TreeItemWrapper"
            variants={variants}
            initial="enter"
            animate="active"
            exit="exit"
          >
            <TreeItemButton {...itemProps} onClick={onItemClick} as={treeItemComponent}>
              {children}
            </TreeItemButton>
          </TreeItemWrapper>
        )}
      </AnimatePresence>
      {!!treeItemChildren.length && (
        <>
          {openIcon === 'arrow' ? (
            <DropdownArrow className="DropdownArrow" onClick={handleTreeOpenClick} />
          ) : (
            <Icon
              icon={isOpen ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'}
              onClick={handleTreeOpenClick}
            />
          )}
          <Tree
            value={treeItemChildren}
            selectedKeys={selectedKeys}
            level={level + 1}
            variant={variant || 'Tree'}
            isOpen={isOpen && dropdownIsOpen}
            screenSize={screenSize}
            elementHeight={elementHeight}
            onButtonClick={onButtonClick}
            onTreeOpenClick={onTreeOpenClick}
            path={path}
            openItems={openItems}
            openIcon={openIcon}
            treeItemComponent={treeItemComponent}
            treeItemComponentProps={treeItemComponentProps}
          />
        </>
      )}
    </TreeItemStyled>
  )
}

TreeItem.propTypes = {
  prop: PropTypes.arrayOf(PropTypes.object),
}

TreeItem.defaultProps = {}

export default TreeItem
