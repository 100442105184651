import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { createPortal } from 'react-dom'

import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates'
import { SortableTreeItem } from './SortableTreeItem'
import EditableTreeStyled from './styled'
import {
  addItem,
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from './utilities'

const initialItems = [
  {
    id: 'Home',
    children: [],
  },
  {
    id: 'Collections',
    children: [
      { id: 'Spring', children: [] },
      { id: 'Summer', children: [] },
      { id: 'Fall', children: [] },
      { id: 'Winter', children: [] },
    ],
  },
  {
    id: 'About Us',
    children: [],
  },
  {
    id: 'My Account',
    children: [
      { id: 'Addresses', children: [] },
      { id: 'Order History', children: [] },
    ],
  },
]

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    })
  },
}

export function EditableTree({
  collapsible,
  value = initialItems,
  indicator = false,
  indentationWidth = 50,
  treeItemComponent,
  treeItemComponentProps,
  enableCrud,
  preventRootDelete,
  onChange,
  defaultValue,
  idKey = 'id',
}) {
  const [items, setItems] = useState(() => value)
  const [activeId, setActiveId] = useState(null)
  const [overId, setOverId] = useState(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items, idKey)
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, [idKey]: id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      []
    )

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
      idKey
    )
  }, [activeId, items, idKey])
  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth, idKey)
      : null
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  })
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  )

  const sortedIds = useMemo(() => flattenedItems.map(({ [idKey]: id }) => id), [
    flattenedItems,
    idKey,
  ])
  const activeItem = activeId ? flattenedItems.find(({ [idKey]: id }) => id === activeId) : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    }
  }, [flattenedItems, offsetLeft])

  useEffect(() => {
    setItems(value)
  }, [value])

  const announcements = {
    onDragStart({ active }) {
      return `Picked up ${active[idKey]}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active[idKey], over?.[idKey])
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active[idKey], over?.[idKey])
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active[idKey], over?.[idKey])
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active[idKey]} was dropped in its original position.`
    },
  }

  return (
    <EditableTreeStyled className="EditableTree">
      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          {flattenedItems.map(
            ({ [idKey]: id, path, children, collapsed, depth, parentId, index, ...value }) => (
              <SortableTreeItem
                key={id}
                id={id}
                value={{ [idKey]: id, ...value }}
                depth={id === activeId && projected ? projected.depth : depth}
                path={path}
                indentationWidth={indentationWidth}
                indicator={indicator}
                treeItemComponent={treeItemComponent}
                treeItemComponentProps={treeItemComponentProps}
                collapsed={Boolean(collapsed && children.length)}
                onCollapse={
                  collapsible && children.length ? () => handleCollapse(id, path) : undefined
                }
                onRemove={enableCrud ? () => handleRemove(id, path) : undefined}
                onAdd={enableCrud ? () => handleAdd(id, path, defaultValue) : undefined}
              />
            )
          )}
          {createPortal(
            <DragOverlay
              dropAnimation={dropAnimationConfig}
              modifiers={indicator ? [adjustTranslate] : undefined}
            >
              {activeId && activeItem ? (
                <SortableTreeItem
                  id={activeId}
                  depth={activeItem.depth}
                  clone
                  childCount={getChildCount(items, activeId, idKey) + 1}
                  value={activeId.toString()}
                  treeItemComponent={treeItemComponent}
                  treeItemComponentProps={treeItemComponentProps}
                  indentationWidth={indentationWidth}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>
    </EditableTreeStyled>
  )

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ [idKey]: id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      })
    }

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }) {
    resetState()

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items, idKey)))
      const overIndex = clonedItems.findIndex(({ [idKey]: id }) => id === over[idKey])
      const activeIndex = clonedItems.findIndex(({ [idKey]: id }) => id === active[idKey])
      const activeTreeItem = clonedItems[activeIndex]
      const prevParent = clonedItems.find(({ [idKey]: id }) => id === activeTreeItem.parentId)
      const newParent = clonedItems.find(({ [idKey]: id }) => id === parentId)

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const { items: newItems } = buildTree({
        flattenedItems: sortedItems,
        idKey,
        prevParentId: prevParent?.id,
        newParentId: newParent?.id,
        movedItemId: activeTreeItem.id,
      })

      onChange && onChange(newItems)
      setItems(newItems)
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty('cursor', '')
  }

  function handleRemove(id, path) {
    if (preventRootDelete && items.length === 1 && path === 'root[0]') {
      return
    }
    setItems((currentItems) => {
      const { items } = removeItem({ items: currentItems, id, path, idKey })
      onChange && onChange(items)
      return items
    })
  }

  function handleAdd(id, path, defaultValue) {
    setItems((currentItems) => {
      const { items } = addItem({ items: currentItems, path, idKey, defaultValue })
      onChange && onChange(items)
      return items
    })
  }

  function handleCollapse(id, path) {
    setItems((items) =>
      setProperty({
        items,
        path,
        dataKey: 'collapsed',
        setter: (item) => {
          return !item.collapsed
        },
      })
    )
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          })
        }
      }

      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items, idKey)))
      const overIndex = clonedItems.findIndex(({ [idKey]: id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ [idKey]: id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved'
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested'

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId = previousSibling.parentId
            previousSibling = sortedItems.find(({ [idKey]: id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }
  }
}

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  }
}
