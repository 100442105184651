export const widgetTemplates = [
  {
    type: 'GraphCard',
  },
  {
    type: 'HistoricalComparisonGraph',
  },
  {
    type: 'TrendLine',
  },
  {
    type: 'ChartCard',
  },
  {
    type: 'Connectivity',
  },
  {
    type: 'ComparisonPieChart',
  },
  {
    type: 'BarChart',
  },
  {
    type: 'HistoricalBarChart',
  },
  {
    type: 'DatapointHeatMap',
  },
  {
    type: 'Gauge',
  },
  {
    type: 'Consumption',
  },
  {
    type: 'AbsoluteGauge',
  },
  {
    type: 'RelativeGauge',
  },
  {
    type: 'CircleGauge',
  },
  {
    type: 'EfficiencyGauge',
  },
  {
    type: 'Grid',
  },
  {
    type: 'YearOverview',
  },
  {
    type: 'NavigationWidget',
  },
  {
    type: 'Calendar',
  },
  {
    type: 'Image',
  },
  {
    type: 'Matrix',
  },
  {
    type: 'SiteMap',
  },
  {
    type: 'Empty',
  },
  {
    type: 'Clock',
  },
  {
    type: 'Sdg',
  },
  {
    type: 'Status',
  },
  {
    type: 'File',
  },
  {
    type: 'Information',
  },
  {
    type: 'XRange',
  },
  {
    type: 'MeterOverview',
  },
  {
    type: 'EnergyClassification',
  },
  /*{
    type: 'Severity'
  },
  {
    type: 'SiteMap'
  },
  {
    type: 'ComparedDatapointHeatMap'
  }*/
]
export const sdgWidgetTemplates = [
  {
    type: 'GhgLineChart',
  },
  {
    type: 'GhgHistoricalLineChart',
  },
  {
    type: 'GhgTrendLine',
  },
  {
    type: 'GhgBarChart',
  },
  {
    type: 'GhgHistoricalBarChart',
  },
  {
    type: 'GhgPieChart',
  },
  {
    type: 'GhgSparkline',
  },
  {
    type: 'GhgGauge',
  },
  {
    type: 'GhgAbsoluteGauge',
  },
  {
    type: 'GhgRelativeGauge',
  },
  {
    type: 'GhgStatus',
  },
]
export const sectionTemplates = [
  {
    type: 'Section',
    h: 100,
    styleProps: {
      height: 50,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '1 x 6 grid',
    },
    elements: [
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
      {
        type: 'Placeholder',
        h: 100,
        w: 2,
      },
    ],
  },
  {
    type: 'Section',
    h: 400,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '2 x 4 grid',
    },
    elements: [
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 3,
      },
    ],
  },
  {
    type: 'Section',
    h: 600,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '2 x 2 grid',
    },
    elements: [
      {
        type: 'Placeholder',
        h: 50,
        w: 6,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 6,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 6,
      },
      {
        type: 'Placeholder',
        h: 50,
        w: 6,
      },
    ],
  },
  {
    type: 'Section',
    h: 400,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '1 x 1 grid',
    },
    elements: [
      {
        type: 'Placeholder',
        h: 100,
        w: 12,
      },
    ],
  },
  {
    type: 'Section',
    h: 600,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '2 x 1 / 2 x 2 grid',
    },
    elements: [
      {
        type: 'Section',
        h: 100,
        w: 2,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
        ],
      },
      {
        type: 'Section',
        h: 100,
        w: 10,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
        ],
      },
    ],
  },
  {
    type: 'Section',
    h: 600,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '2 x 2 / 2 x 1 grid',
    },
    elements: [
      {
        type: 'Section',
        h: 100,
        w: 10,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
        ],
      },
      {
        type: 'Section',
        h: 100,
        w: 2,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'Section',
    h: 600,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: '4 x 1 / 2 x 2 / 2 x 1 grid',
    },
    elements: [
      {
        type: 'Section',
        h: 100,
        w: 2,
        elements: [
          {
            type: 'Placeholder',
            h: 25,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 25,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 25,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 25,
            w: 12,
          },
        ],
      },
      {
        type: 'Section',
        h: 100,
        w: 8,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 6,
          },
        ],
      },
      {
        type: 'Section',
        h: 100,
        w: 2,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'Section',
    h: 600,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: 'Custom grid',
    },
    elements: [
      {
        type: 'Section',
        h: 100,
        w: 10,
        elements: [
          {
            type: 'Section',
            h: 25,
            w: 12,
            elements: [
              {
                type: 'Placeholder',
                h: 100,
                w: 3,
              },
              {
                type: 'Placeholder',
                h: 100,
                w: 3,
              },
              {
                type: 'Placeholder',
                h: 100,
                w: 3,
              },
              {
                type: 'Placeholder',
                h: 100,
                w: 3,
              },
            ],
          },
          {
            type: 'Placeholder',
            h: 75,
            w: 6,
          },
          {
            type: 'Section',
            h: 75,
            w: 6,
            elements: [
              {
                type: 'Placeholder',
                h: 50,
                w: 6,
              },
              {
                type: 'Placeholder',
                h: 50,
                w: 6,
              },
              {
                type: 'Placeholder',
                h: 50,
                w: 6,
              },
              {
                type: 'Placeholder',
                h: 50,
                w: 6,
              },
            ],
          },
        ],
      },
      {
        type: 'Section',
        h: 100,
        w: 2,
        elements: [
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
          {
            type: 'Placeholder',
            h: 50,
            w: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'Section',
    h: 400,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: 'Data Entry',
    },
    elements: [
      {
        type: 'DataEntry',
        h: 100,
        w: 12,
      },
    ],
  },
  {
    type: 'Section',
    h: 400,
    styleProps: {
      height: 150,
      level: 0,
    },
    columns: {
      s: 2,
      m: 6,
      l: 12,
    },
    header: {
      icon: 'fas fa-info',
      title: 'Status Grid',
    },
    elements: [
      {
        type: 'DataStatusGrid',
        h: 100,
        w: 12,
      },
    ],
  },
]
