import { css } from 'styled-components'

export const squeals = css`
  padding: 0 0.5rem;
  font-size: 1.1rem;
  .Icon {
    color: ${({ theme }) => theme.color.success};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.4rem;
      path {
        fill: ${({ theme }) => theme.color.success};
      }
    }
  }
  &.issues {
    animation: 0.8s ease 0s infinite normal none running pulse;
    .Icon,
    .Button__label {
      color: ${({ theme }) => theme.color.error};
    }
    .Icon {
      svg {
        path {
          fill: ${({ theme }) => theme.color.error};
        }
      }
    }
  }
  .Button__label {
    margin: -1em 0 0 0.2em;
    font-size: 0.8em;
    line-height: 1;
    padding: 3px 0.2em 1px;
    border: 1px solid;
    border-radius: 1em;
  }
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
