import React, { useEffect, useRef, useState } from 'react'

import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'

import Icon from 'components/Global/Icon'
import TooltipContentPortal from 'components/Global/Tooltip/TooltipContent/TooltipContentPortal'

import TooltipContent from '../Tooltip/TooltipContent'

const CaptionTooltip = ({
  caption,
  width = 400,
  maxWidth,
  height,
  variant = 'Datapoints',
  onClick,
  portal = true,
  children,
  timeout = 0,
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  const timeoutRef = useRef()
  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])
  const onToggleOpen = (payload) => {
    timeoutRef.current = setTimeout(
      () => setIsOpen((state) => (payload === undefined ? !state : payload)),
      isOpen ? 0 : timeout
    )
  }
  const tooltipProps =
    theme.screenSize.screen.current === 's'
      ? {
          onTap: () => onToggleOpen(),
        }
      : {
          onClick,
          onHoverStart: () => onToggleOpen(true),
          onHoverEnd: () => onToggleOpen(false),
        }
  const content = caption && (
    <>
      {caption.icon && <Icon icon={caption.icon} />}
      <span>{caption.formatted ? <FormattedMessage id={caption.formatted} /> : caption.text}</span>
      <Icon icon="fas fa-xmark" className="close-button" onClick={() => onToggleOpen(false)} />
    </>
  )
  return (
    <motion.div {...tooltipProps} className="CaptionTooltip" ref={ref}>
      {caption &&
        (portal ? (
          <TooltipContentPortal
            parentRef={ref.current}
            variant={variant}
            width={width}
            height={height}
            maxWidth={maxWidth}
            isOpen={isOpen}
          >
            {content || null}
          </TooltipContentPortal>
        ) : (
          <TooltipContent isOpen={isOpen} variant={variant}>
            {content || null}
          </TooltipContent>
        ))}
      {typeof children === 'function' ? children(caption) : children}
    </motion.div>
  )
}

export default CaptionTooltip
