import React from 'react'

import { useMutation } from '@apollo/client'

import { ACTION_MUTATION } from 'services/actions'

import ActionConfirmationPortal from '../ActionConfirmationPortal'

export const ActionConfirmationWithAdjustableValue = ({
  onConfirmationRequest,
  confirmationFunction,
  ...props
}) => {
  const [publishAction] = useMutation(ACTION_MUTATION)

  const setAdjustableValue = async ({ id, value }) => {
    confirmationFunction
      ? await confirmationFunction({
          id,
          value,
        })
      : await publishAction({
          variables: {
            entityId: id,
            value: String(value),
          },
        })
  }

  return (
    <ActionConfirmationPortal
      {...props}
      confirmationFunction={setAdjustableValue}
      onConfirmationRequest={onConfirmationRequest}
    />
  )
}

export default ActionConfirmationWithAdjustableValue
