import React from 'react'

import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { FieldMessage as FieldMessageStyled } from './styled'

const FieldMessage = ({ children, className, message, label, name, intl }) => {
  const messageText = {
    required: `* ${label ? intl.formatMessage({ id: label }) : ''}${intl.formatMessage({
      id: 'isRequired',
    })}`,
    pattern: `* ${label ? intl.formatMessage({ id: label }) : ''}${intl.formatMessage({
      id: 'form.input.invalid',
    })}`,
  }[message?.type]

  return (
    <AnimatePresence>
      {message && (
        <FieldMessageStyled
          className={`Field ${className}`}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 20 }}
          exit={{ opacity: 0, height: 0 }}
        >
          {messageText ?? message?.message}
        </FieldMessageStyled>
      )}
    </AnimatePresence>
  )
}

FieldMessage.propTypes = {
  message: PropTypes.object,
  className: PropTypes.string,
}

FieldMessage.defaultProps = {}

export default injectIntl(FieldMessage)
