import React, { useState } from 'react'

import { CSVReader } from 'react-papaparse'

import DndMapper from '../DndMapper'

const CsvMapper = ({ onChangeConfig, valuesToMatchTo }) => {
  const config = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: false,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '	', '|', ';'],
  }
  const [editing, setEditing] = useState(false)
  const [csvHeaders, setCsvHeaders] = useState({})

  const handleOnDrop = (data) => {
    setCsvHeaders(data[0].data)
    setEditing(true)
  }

  return editing ? (
    <DndMapper
      valuesToMatchTo={valuesToMatchTo}
      availableItems={csvHeaders}
      onChangeConfig={onChangeConfig}
    />
  ) : (
    <CSVReader onDrop={handleOnDrop} config={config}>
      <span>Drop CSV file here or click to upload.</span>
    </CSVReader>
  )
}

export default CsvMapper
