import { motion } from 'framer-motion'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { FieldStyled, Label, Value } from 'components/Form/components/Field/styled'
import { TextFieldStyled } from 'components/Form/fields/TextField/styled'
import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'
import { FormFieldLabel } from 'components/Global/FormField/styled'
import LoaderStyled from 'components/Global/Loader/styled'
import { Accordeon } from 'components/LayoutComponents/Accordeon'
import { AccordeonContent, AccordeonHeader } from 'components/LayoutComponents/Accordeon/styled'

const getTotalNrOfColumns = (columns) => columns.reduce((sum, col) => sum + parseInt(col), 0)
const getColumnWidth = (nrOfColumns, col) => (parseInt(col) / nrOfColumns) * 100

export const ContentStyled = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: ${({ theme }) => theme.panelElevationLeft(theme.darkMode)};
  background: ${({ theme }) => theme.color.backgroundFront};
  z-index: 1;

  > * {
    height: 100%;
  }
`

export const IconContainer = styled.div`
  width: 55px;

  img {
    width: 100%;
  }
`

export const ContentDetailStyled = styled.div`
  .Content {
    height: calc(100vh - 4em);
    top: 4em;
  }
`

export const ContentHeaderStyled = styled.div`
  grid-column: 1 / span 12;
  display: flex;
  padding: 2rem 2rem 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.softerStroke};
  justify-content: space-between;
  align-items: flex-start;
  background: ${({ theme }) => theme.color.backgroundHighlight};
  min-height: 7.8rem;

  ${IconContainer} {
    margin-right: 1.5rem;
  }

  .ContentHeader__title-container {
    overflow: hidden;
    flex: 1;

    h1 {
      font-size: ${({ theme }) => theme.text.xl.size}rem;
      font-weight: ${({ theme }) => theme.text.xl.weight};
      letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
      line-height: 1;
      color: ${({ theme }) => theme.color.strongText};
    }

    ${FieldStyled} {
      &:first-child {
        padding: 0;

        ${Value} {
          font-size: ${({ theme }) => theme.text.xl.size}rem;
          font-weight: ${({ theme }) => theme.text.xl.weight};
          letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
          line-height: 1;
          color: ${({ theme }) => theme.color.strongText};

          input {
            padding: 0 0.5rem;
          }
        }
      }

      &:not(:first-child) {
        margin-bottom: 0;
        padding: 0 0.3rem;
      }

      ${Value} {
        width: 100%;
      }

      justify-content: flex-start;
    }

    ${Label} {
      flex: none;
    }
  }

  .ContentHeader__children-container {
    display: flex;
    align-items: center;
    margin: 0 0 -0.3rem;

    ${FieldStyled} {
      width: auto;
    }

    ${TextFieldStyled} {
      margin: 0 0.5rem;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }

      ${Label} {
        margin-right: 0.5rem;
      }
    }

    ${Value} {
      color: ${({ theme }) => theme.color.mainText};
      line-height: 1.4;
      text-align: inherit;

      span {
        display: flex;
      }
    }
  }

  .ContentHeader__button-container {
    min-width: fit-content;

    .ant-btn {
      margin-left: 1rem;

      &:not(.ant-btn-primary) {
        &:not(:hover) {
          color: ${({ theme }) => theme.color.softText};
          background: transparent;
        }
      }
    }

    .ant-select-single:not(:hover) .ant-select-selector {
      background: transparent;
    }
  }
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.text.xl.size}rem;
  font-weight: ${({ theme }) => theme.text.xl.weight};
  letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
  line-height: 1;
  color: ${({ theme }) => theme.color.strongText};
`
export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.text.l.size}rem;
  font-weight: ${({ theme }) => theme.text.l.weight};
  letter-spacing: ${({ theme }) => theme.text.l.spacing}rem;
  line-height: 1;
  color: ${({ theme }) => theme.color.strongText};
  padding-left: 2px;
`

export const ScrollableElement = styled.div`
  flex: 1;
  height: ${({ height }) => height};
  overflow: auto;
  width: ${({ fullWidth }) => fullWidth && '100% !important'};
  min-width: ${({ fullWidth }) => fullWidth && '100% !important'};
`

export const ContentLoaderStyled = styled(LoaderStyled)`
  position: absolute;
  flex-direction: column-reverse;

  .Icon {
    font-size: ${({ size }) =>
      ({
        s: '3rem',
        m: '4rem',
        l: '6rem',
        xl: '10rem',
      }[size])};
    height: ${({ size }) =>
      ({
        s: '6rem',
        m: '8rem',
        l: '12rem',
        xl: '20rem',
      }[size])};
    color: ${({ theme }) => theme.color.softerStroke};
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: ${({ logoImage }) => logoImage && 0.2};
  }

  span {
    color: ${({ theme }) => theme.color.mainText};
    margin: 0 0 2rem;
    font-size: 1.2rem;
  }
`

export const ContentScrollContainer = styled.div`
  flex: 1;
  height: calc(100vh - 4em);
  overflow: hidden;
  padding-top: ${({ topOffset }) => topOffset && '4em'};

  ${ContentHeaderStyled} {
    min-height: 7.6rem;
    align-items: flex-start;

    .ant-btn {
      margin-left: 1rem;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;
`

export const ContentFlexStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ padding = true }) => (padding ? '1rem' : 0)};
  width: ${({ width }) => width};
  ${({ col }) => {
    if (col) {
      const columns = col.split('/')
      const totalNrOfColumns = getTotalNrOfColumns(columns)
      return (
        col &&
        css`
          ${columns.map((col, index) => {
            const colWidth = getColumnWidth(totalNrOfColumns, columns[index])
            return css`
              > * {
                &:nth-child(${columns.length}n + ${index + 1}) {
                  flex: ${columns[index]} ${columns[index]} ${colWidth}%;
                  width: ${colWidth}%;
                  min-width: ${colWidth}%;
                  max-width: ${colWidth}%;
                }
              }
            `
          })}
        `
      )
    }
  }}
  border: ${({ border, theme }) => border === 'auto' && `1px solid ${theme.color.softStroke}`};
  border-top: ${({ border, theme }) => border === 'top' && `1px solid ${theme.color.softStroke}`};
  border-bottom: ${({ border, theme }) =>
    border === 'bottom' && `1px solid ${theme.color.softStroke}`};
  border-left: ${({ border, theme }) => border === 'left' && `1px solid ${theme.color.softStroke}`};
  border-right: ${({ border, theme }) =>
    border === 'right' && `1px solid ${theme.color.softStroke}`};
  border-radius: ${({ borderRadius }) => (borderRadius === 'auto' ? '.5rem' : borderRadius)};

  ${({ align }) => {
    switch (align) {
      case 'right':
        return css`
          justify-content: flex-end;
        `
      default:
    }
  }}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : undefined}
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;

      > * {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    `}
`

export const ContentCardStyled = styled.div`
  align-content: start;
  padding: 1rem;
  min-width: 0;

  > div {
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    overflow: hidden;

    > .bordered {
    }
  }

  .ContentFlex {
  }
`

export const ContentTitle = styled.div`
  font-size: ${({ theme }) => theme.text.l.size}rem;
  font-weight: ${({ theme }) => theme.text.l.weight};
  letter-spacing: ${({ theme }) => theme.text.l.spacing}rem;
  line-height: 1;
  color: ${({ theme }) => theme.color.strongText};
`

export const ContentLabel = styled(FormFieldLabel)``

export const ContentValue = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.color.strongText};
`

export const ContentAccordeon = styled(Accordeon)`
  ${AccordeonHeader} {
    transition: background 0.2s ease, box-shadow 0.2s ease;
    padding: 0.5rem 0 0.5rem 3rem;

    &:hover,
    &.active {
      background: ${({ theme }) => lighten(0.02, theme.color.softerStroke)};
    }

    &.active {
      /*box-shadow: ${({ theme }) => theme.shadowElevation2()};*/
      border-bottom: 1px solid ${({ theme }) => theme.color.main};

      ${DropdownArrow} {
        transform: rotate(0deg) translate(3px, -2px);
      }
    }

    &.inactive {
      ${DropdownArrow} {
        transform: rotate(-90deg) translate(-3px, 4px);
      }
    }

    ${DropdownArrow} {
      transition: transform 0.2s ease;
      position: absolute;
      left: 0.5rem;

      &:after {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }

  ${AccordeonContent} {
    border-bottom: 1px solid transparent;
    font-weight: 100;

    &.active {
      border-color: ${({ theme }) => theme.color.softStroke};
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 0.5rem 1rem 3rem;
    }
    .ContentAccordeon__date {
      margin-right: 0.5rem;
      font-weight: 600;
    }
  }
`

export const Field = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.text.m.size}rem;
  font-weight: ${({ theme }) => theme.text.m.weight}rem;
  letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  align-items: center;
`
export const FieldLabel = styled.label`
  color: ${({ theme }) => theme.color.mainText};
  white-space: nowrap;
  grid-row: ${({ row }) => row};
  font-weight: 100;
`

export const FieldValue = styled.div`
  line-height: 1.3;
  color: ${({ theme }) => theme.color.strongText};
  grid-row: ${({ row }) => row};
  justify-self: start;
  margin-top: 0.1rem;
  font-weight: 500;
  font-size: ${({ strong }) => (strong ? '1.2rem' : '1rem')};
  overflow: hidden;
  max-width: -webkit-fill-available;
  text-overflow: ellipsis;

  .EditableField__unit {
    padding-left: 0.3rem;
    font-size: 0.8rem;
  }
`

export const Detail = styled.div`
  min-width: 60vw;
`

export default ContentStyled
