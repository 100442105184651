import React from 'react'

import PropTypes from 'prop-types'

import { ContentFlexStyled } from './styled'

const ContentFlex = ({ children, col, className, border, onClick, align, fullWidth, ...props }) => {
  return (
    <ContentFlexStyled
      {...props}
      className={`ContentFlex ${className} ${border ? `border${border}` : ''}`}
      col={col}
      border={border}
      onClick={(e) => onClick && onClick(e)}
      align={align}
      fullWidth={fullWidth}
    >
      {children}
    </ContentFlexStyled>
  )
}

ContentFlex.propTypes = {
  fullWidth: PropTypes.bool,
}

export default ContentFlex
