import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { Label, Value } from 'components/Form/components/Field/styled'

import Field from '../../components/Field'

const SwitchFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    align-items: center;
    flex: none !important;
  }

  ${Label} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .ant-switch {
    ${({ color }) =>
      color &&
      css`
        &.ant-switch-checked {
          background-color: ${({ color }) => color};
        }

        background-color: ${({ color }) => transparentize(0.75, color)};
      `}

    text-transform: uppercase;
    &-inner {
      font-size: 0.7rem;
    }
  }

  .ant-switch-disabled {
    opacity: 0.7;
  }
`
export default SwitchFieldStyled
