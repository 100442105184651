import styled, { css } from 'styled-components'

import ValuePicker from 'components/Form/components/ValuePicker'

export const HeaderValuePickerStyled = styled(ValuePicker)`
  width: 200px;
  height: 39px;
  flex: 1;

  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 2rem !important;
    min-height: 100%;
    input {
      min-height: 100%;
      padding: 0;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .Icon {
    top: 1rem;
  }

  ${({ hide }) =>
    hide &&
    css`
      flex: 0;
      min-width: auto;
      overflow: hidden;
    `};
`
