import { useLocaleHook } from 'services/user'

export const useLocaleNumber = () => {
  const locale = useLocaleHook()
  return (number, decimals = 1) => {
    const input = Number(number)

    if (isNaN(input)) return number
    const separator = locale.includes('en-') ? '.' : ','
    const inputDecimals = input.toString().split('.')[1]?.length || 0
    const numberOfDecimals = inputDecimals > decimals ? decimals : inputDecimals
    const split = input
      .toLocaleString(locale, {
        maximumFractionDigits: numberOfDecimals,
        minimumFractionDigits: numberOfDecimals,
      })
      .split(separator)
    return `${split[0]}${split[1] && split[1] !== '000' ? separator + split[1] : ''}`
  }
}
