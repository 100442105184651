import React from 'react'

import dayjs from 'dayjs'
import { injectIntl } from 'react-intl'

import RangePickerStyled from './styled'

const RangePicker = ({ enabled, intl, presets = true, ...props }) => {
  return (
    <RangePickerStyled
      className="RangePicker"
      size="small"
      type={enabled && 'primary'}
      ranges={
        presets && {
          [intl.formatMessage({
            id: 'graphComponent.presetControl.thisDay',
          })]: [dayjs().startOf('day'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.thisWeek',
          })]: [dayjs().startOf('week'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.thisMonth',
          })]: [dayjs().startOf('month'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.pastDay',
          })]: [dayjs().subtract(1, 'days'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.past7Days',
          })]: [dayjs().subtract(7, 'days'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.past28Days',
          })]: [dayjs().subtract(28, 'days'), dayjs()],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.yesterday',
          })]: [dayjs().startOf('day').subtract(1, 'day'), dayjs().startOf('day')],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.dayBefore',
          })]: [
            dayjs().startOf('day').subtract(2, 'day'),
            dayjs().startOf('day').subtract(1, 'day'),
          ],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.thisDayLastWeek',
          })]: [
            dayjs().startOf('day').subtract(1, 'week'),
            dayjs().startOf('day').subtract(6, 'days'),
          ],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.previousWeek',
          })]: [dayjs().startOf('week').subtract(1, 'week'), dayjs().startOf('week')],
          [intl.formatMessage({
            id: 'graphComponent.presetControl.previousMonth',
          })]: [dayjs().startOf('month').subtract(1, 'month'), dayjs().startOf('month')],
        }
      }
      {...props}
    />
  )
}

export default injectIntl(RangePicker)
