import { gql, useReactiveVar } from '@apollo/client'
import { cloneDeep } from 'lodash'
import { InvariantError } from 'ts-invariant'

import { settingsVar } from './apollo'

export const SETTINGS_QUERY = gql`
  query settings {
    settings @client {
      id
      isMobileView
      isMobileMenuOpen
      isLightTheme
      isSettingsOpen
      isMenuTop
      isMenuCollapsed
      isBorderless
      isSquaredBorders
      isFixedWidth
      isMenuShadow
      isModalOpen
    }
  }
`
/**
 * @deprecated Use Apollo reactiveVars instead
 */
export const setSetting = (client, key, value) => {
  try {
    const settings = cloneDeep(settingsVar())
    settings[key] = value
    settingsVar(settings)
  } catch (e) {
    if (!(e instanceof InvariantError)) {
      throw e
    }
  }
}

export const useIsModalOpen = () => {
  const settings = useSettingsHook()

  return settings.isModalOpen
}

export const useSettingsHook = () => {
  return useReactiveVar(settingsVar) || {}
}
