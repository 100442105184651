import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const getBackgroundColor = ({ theme, color, caption }) => {
  if (caption?.color) {
    return caption.color
  }
  if (color) {
    return color
  }
  return theme.color.backgroundFront
}

export const getForegroundColor = ({ theme, color, fontColor, caption, toggleState, source }) => {
  if (source === 'Equipment') {
    const foregroundColor = getBackgroundColor({
      theme,
      color,
      caption,
      toggleState,
    })
    if (foregroundColor === theme.color.backgroundFront) {
      return theme.color.mainText
    }
    return foregroundColor
  }
  if (caption?.color) {
    return theme.color.backgroundFront
  }
  if (fontColor) {
    return fontColor
  }
  return theme.color.mainText
}

export const InformationStyled = styled.div`
  position: relative;
  transition: box-shadow 0.2s ease, color 0.2s ease, border 0.2s ease;
  padding: 0;
  box-shadow: ${({ theme, noTitle, img, label }) =>
    label || ((!noTitle || img) && theme.shadowElevation1(theme.darkMode))};
  border: ${({ noTitle, img }) => (!noTitle || img) && '1px solid transparent'};
  border-radius: 0.3rem;
  height: 100%;
  color: ${({ theme }) => theme.color.mainText};
  cursor: default;

  ${({ color, fontColor, caption, toggleState, theme, source }) => {
    const backgroundColor = getBackgroundColor({
      theme,
      color,
      caption,
      toggleState,
      source,
    })
    const foregroundColor = getForegroundColor({
      theme,
      color,
      fontColor,
      caption,
      toggleState,
      source,
    })
    return css`
      background: ${backgroundColor};
      color: ${foregroundColor};

      &:hover {
        border: 1px solid ${transparentize(0.5, backgroundColor || theme.color.main)};
      }

      border: 1px solid
        ${toggleState && toggleState > -1 ? transparentize(0.5, backgroundColor) : 'transparent'};

      .InformationContainer {
        &:after {
          background: ${foregroundColor};
        }

        > .Information__caption {
          .Information__title-container {
            border-bottom: ${({ line }) =>
              line && `1px solid ${transparentize(0.5, foregroundColor)}`};

            i.Icon {
              background: ${foregroundColor};
              color: ${backgroundColor};
            }
          }

          .Title {
            color: ${foregroundColor};
          }
        }
        .DetailContainer__fields.tags {
          background: ${transparentize(0.9, foregroundColor)};
        }
        .Button {
          color: ${foregroundColor};
        }
      }
    `
  }}
  ${({ size, width }) =>
    ({
      s: css`
        .Title {
          font-size: 1.2rem;
        }
      `,
      m: css`
        .Title {
          font-size: 2rem;
        }

        .Button__label {
          font-size: 2rem;
        }
      `,
      l: css`
        .Title {
          font-size: 3rem;
        }

        .Button__label {
          font-size: 3rem;
        }
      `,
      auto: css`
        .Title {
          font-size: ${width / 15}px;
        }
      `,
    }[size || 's'])}
  ${({ hasLabel }) =>
    hasLabel
      ? css``
      : css`
          flex: 1;

          .Icon {
            &.type-img {
              height: 100%;
              grid-column: span 2;
              padding: 0.7rem;
            }
          }
        `}

  ${({ theme }) =>
    theme.screenSize.screen.current !== 's' && theme.screenSize.screen.current !== 'm'
      ? css`
          flex-wrap: wrap;

          .Title {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              display: block;
            }
          }
        `
      : css`
          min-width: fit-content;
        `}
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  position: relative;

  .InformationContainer__content {
    flex: 1;
    overflow: hidden;
    width: 100%;
    > div {
      height: 100%;
      overflow: auto;
    }
  }

  > .Information__caption {
    .Information__title-container {
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0.5rem;

      .Title {
        opacity: 0.8;
      }
      .Icon.type-img {
        width: 2.2rem;
        min-width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.3rem;
        padding: 0;
      }
    }
  }

  /*.ant-image {
    margin: 0.5rem;
    border-radius: 0.3rem;
    flex: 1;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }*/

  .Icon {
    &.type-img {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  i.Icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0 !important;

    svg {
      width: 60%;
      height: 60%;
    }
  }

  > .Information__caption {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    .Information__title-container {
      display: flex;
      padding: 0.7rem;
      flex: 1;

      i.Icon {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.2rem;
        min-width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.3rem;
        justify-self: end;
      }
    }

    .Title {
      text-align: left;
      line-height: 1.2;
      font-weight: 400;
      margin-bottom: 0;

      span {
        height: 100%;
      }
    }
  }
`
