import React from 'react'

import { object, string } from 'prop-types'

import imageStyleUrl, { imageStyles } from 'util/imageStyleUrl'

import { HvacAuto } from '../../CustomIcons/HvacAuto'
import { HvacComfort } from '../../CustomIcons/HvacComfort'
import { HvacEco } from '../../CustomIcons/HvacEco'
import { HvacFrost } from '../../CustomIcons/HvacFrost'
import { HvacStandby } from '../../CustomIcons/HvacStandby'
import FAIcon from '../FontAwesomeIcon'
import IconStyled from './styled'

const isAbsoluteUrl = (url) => /^[a-z][a-z0-9+.-]*:/.test(url)

const icons = {
  HvacAuto,
  HvacComfort,
  HvacEco,
  HvacFrost,
  HvacStandby,
  squeal: () => (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
      <path d="M38.2,499.5c-0.3-0.3-0.6-0.7-1-0.9c-9.9-4.9-12.2-13.5-6-22.7c16.3-24.5,32.9-48.9,48.9-73.6  c29.8-46.2,57.7-93.5,86.5-140.3c42.2-68.7,84.1-137.5,126.3-206.2c29-47.2,90.3-57.6,131.5-22.4c17.7,15.2,27.6,34.8,30.6,57.9  c0.9,6.9,0.8,6.9,7.7,6.9c2.4,0,4.8-0.1,7.1,0c8.4,0.2,14.7,6.4,14.6,14.4c-0.1,7.8-6.2,13.9-14.4,14.1c-7.9,0.2-15.9,0.5-23.8-0.1  c-13.3-0.9-19.3,6.8-19.1,18.8c0.5,26.6,0.2,53.3,0.2,79.9c-0.1,85.2-52.3,156-133.7,181.3c-1,0.3-2,0.8-3.2,1.2  c6.9,13.8,13.7,27.4,20.5,40.9c1.8,3.5,2.9,7,2,11c-1.4,6-6.2,10.4-12.1,11.2c-6,0.8-11.8-2.2-14.8-8.2  c-6.8-13.4-13.5-26.9-20.2-40.3c-1.1-2.1-2.2-4.2-3.1-6.4c-0.9-2.2-2.2-3.2-4.7-2.9c-6,0.9-12,0.9-18,0.8c-2.9-0.1-3.2,0.8-1.9,3.2  c5.4,10.6,10.7,21.2,16,31.8c1.7,3.3,2.7,6.8,1.9,10.5c-1.3,6-6.1,10.5-12,11.4c-6,0.9-11.8-2.1-14.8-8.1  c-7.5-14.7-14.9-29.3-22-44.2c-1.8-3.8-3.9-4.9-7.9-4.8c-9.5,0.3-19,0.2-28.5,0c-3.1-0.1-4.4,0.9-5.3,3.9  c-7.3,22.4-15,44.7-22.2,67.2c-2.1,6.5-5.1,11.8-11.5,14.7C100.3,499.5,69.3,499.5,38.2,499.5z M384.6,67.7  c-8.8-0.1-16.2,7.2-16.3,15.9c-0.2,9,7.4,16.6,16.3,16.5c8.8-0.1,16.1-7.4,16.1-16.2C400.6,75.1,393.3,67.8,384.6,67.7z" />
    </svg>
  ),
}

const getIcon = ({ iconType, icon, style, size }) => {
  if (!icon) {
    return null
  }

  switch (iconType) {
    case 'fa':
      return <FAIcon icon={icon} />
    case 'ln':
      return <span className={`Icon ${icon}`} />
    case 'ic':
      return icons[icon.replace('icon.', '')]()
    default:
      return (
        <img
          src={
            isAbsoluteUrl(icon) || icon.startsWith('/')
              ? icon
              : imageStyleUrl(icon, imageStyles[size])
          }
          className="icon img"
          alt="icon"
          style={style}
          onError={(e) => {
            e.target.src = '/resources/images/default-image.gif'
          }}
        />
      )
  }
}

const Icon = ({
  icon,
  className,
  color,
  variant,
  onClick,
  style = null,
  size = 'small',
  dataCy,
  title,
  theme,
  ...props
}) => {
  const iconType = icon?.substring(0, 2)
  const img = icon?.includes('.')

  const onIconClick = (e) => {
    if (onClick) {
      e.stopPropagation()
      onClick(e)
    }
  }

  return (
    <IconStyled
      {...props}
      className={`Icon ${className} type-${img ? 'img' : iconType}`}
      iconType={img ? 'img' : iconType}
      as={img ? 'div' : 'i'}
      color={color}
      variant={variant}
      onClick={onIconClick}
      data-cy={dataCy}
      title={title}
      theme={theme}
      style={style}
    >
      {getIcon({ iconType, icon, style, size })}
    </IconStyled>
  )
}

Icon.propTypes = {
  icon: string,
  styleProps: object,
}

Icon.defaultProps = {}

export default Icon
