import React, { useCallback, useState } from 'react'

import { merge } from 'lodash'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import { calculationMethods } from 'components/Dashboard/Widget/config/selections'
import ValuePicker from 'components/Form/components/ValuePicker'
import { WidgetCaption } from 'components/Global/Caption/styled.WidgetCaption'
import GranularitySelector from 'components/Global/FormField/GranularitySelector'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import HeatmapIntervalSelector from '../../../Global/FormField/DefaultStartTimeSelector/HeatmapIntervalSelector'
import { StatusIcon } from '../../../Global/Icon'
import TimePicker from '../TimePicker'
import { getGranularityForTimeRange, parseDefaultTime } from './functions'
import { useDefaultTimeParams } from './hooks'
import { TimeSelectionStyled } from './styled'

const FALLBACK_PROPS = {
  // Because start time may never be null
  hideStartTime: false,
  hideEndTime: false,
  hideGranularity: true,
  hideRawData: true,
}

/**
 * Add time controls to a component.
 * Will pass the following properties down to the compoennt,
 * - configured props such as 'defaultStartTime' and 'hideGranularity'
 * - user configured props such as 'startTime' and 'granularity'
 * @param WrappedComponent
 * @param defaultProps, set default props for this component, can be overwritten by the options provided in the dashboard configuration
 */
export const withTimeSelection = (WrappedComponent, defaultProps = {}) => {
  const ComponentWithTimeSelection = (props) => {
    const {
      title,
      icon,
      color,
      defaultGranularity,
      defaultGranularityValue,
      defaultCalculationMethod,
      heatmapGranularityValue: defaultHeatmapGranularityValue,
      heatmapGranularityUnit: defaultHeatmapGranularityUnit,
      organisationId,
      heatMapIntervals,
      datapoints,
      type,
      locationData,
    } = merge({}, FALLBACK_PROPS, defaultProps, props)
    // const combinedProps = useMemo(() => merge({}, FALLBACK_PROPS, defaultProps, props), [FALLBACK_PROPS, defaultProps, props.defaultStartTime,])
    const { state } = useDashboard()
    const {
      time,
      calculationMethod,
      source,
      granularity,
      granularityValue,
      heatmapGranularityUnit,
      heatmapGranularityValue,
      onValueUpdate,
    } = useDefaultTimeParams({
      defaultStartTime: props.defaultStartTime || defaultProps.defaultStartTime,
      defaultGranularity: defaultGranularity || defaultProps.defaultGranularity,
      defaultGranularityValue: defaultGranularityValue || defaultProps.defaultGranularityValue,
      heatmapGranularityValue: defaultHeatmapGranularityValue,
      heatmapGranularityUnit: defaultHeatmapGranularityUnit,
      defaultCalculationMethod,
      organisationId,
      dataProps: props.dataProps,
    })

    const [freeze, setFreeze] = useState(false)

    const containsNonDifferenceDatapoint = datapoints?.some(({ difference }) => !difference)

    const { startTime, endTime, preset } = time

    const onChangeTime = (value) => {
      const newTimeRange = {
        startTime: parseDefaultTime(value, 'startTime'),
        endTime: parseDefaultTime(value, 'endTime'),
        preset: value.preset,
      }
      const { defaultGranularity, defaultGranularityValue } = getGranularityForTimeRange(
        newTimeRange,
        heatMapIntervals
      )
      onValueUpdate(newTimeRange, 'time')
      onValueUpdate(defaultGranularity, 'granularity')
      onValueUpdate(defaultGranularityValue, 'granularityValue')
      onValueUpdate(null, 'source')
      if (
        !(
          (defaultGranularity === 'h' && defaultGranularityValue === 1) ||
          defaultGranularity === 'm'
        )
      ) {
        onValueUpdate(calculationMethod === 'raw' ? 'mean' : calculationMethod, 'calculationMethod')
      }
    }

    const changeGranularityValue = (value, dataKey) => {
      if (dataKey === 'defaultGranularity') {
        return onValueUpdate(value, 'granularity')
      }
      onValueUpdate(value, 'granularityValue')
    }
    const onChangeGranularity = (granularity, granularityValue) => {
      onValueUpdate(granularityValue, 'granularityValue')
      onValueUpdate(granularity, 'granularity')
    }

    const onSelectionChange = useCallback(({ type, resetSelection }) => {
      if (type === 'selection') {
        setFreeze(!resetSelection)
      }
    }, [])

    const {
      defaultGranularityValue: granValue,
      defaultGranularity: granDefault,
    } = getGranularityForTimeRange(
      {
        preset,
        startTime,
        endTime,
      },
      type === 'DatapointHeatMap'
    )

    return (
      <TimeSelectionStyled className="TimeSelection">
        <div className="TimeSelection__header">
          {(title || icon) && (
            <WidgetCaption
              title={`${locationData?.title ? `${locationData.title} | ` : ''}${title}`}
              icon={icon}
              color={color}
            />
          )}
          {!endTime && (
            <StatusIcon
              className={freeze ? 'pause' : 'play'}
              hoveredIcon={freeze ? 'fas fa-play' : 'fas fa-pause'}
              icon={freeze ? 'fas fa-pause' : 'fas fa-play'}
              onClick={() => setFreeze((state) => !state)}
            />
          )}
          <TimePicker
            value={time}
            organisationId={organisationId}
            onChange={onChangeTime}
            onChangeGranularity={onChangeGranularity}
            source={source}
            isDetailDashboard={state.data?.isDetailDashboard}
          >
            {props.type === 'DatapointHeatMap' ? (
              <HeatmapIntervalSelector
                value={{
                  defaultGranularityValue: granularityValue,
                  defaultGranularity: granularity,
                  heatmapGranularityValue,
                  heatmapGranularityUnit,
                }}
                defaultStartTime={time}
                onGranularityChange={({ value, dataKey }) => changeGranularityValue(value, dataKey)}
                onBlur={(value, dataKey) =>
                  onValueUpdate((state) => ({ ...state, [dataKey]: value }), 'heatmapGranularity')
                }
              />
            ) : (
              <GranularitySelector
                value={{
                  defaultGranularityValue: granularityValue,
                  defaultGranularity: granularity,
                }}
                defaultStartTime={time}
                onChange={changeGranularityValue}
                disabled={calculationMethod === 'raw'}
              />
            )}
            {state.advanced && (
              <>
                <FormFieldLabel>
                  {props.intl.formatMessage({ id: 'widget.calculationMethod' })}
                </FormFieldLabel>
                <ValuePicker
                  options={[
                    ...calculationMethods.filter(({ value }) =>
                      containsNonDifferenceDatapoint
                        ? value !== 'amount' && value !== 'sum'
                        : value === 'mean'
                    ),
                    ...((granDefault === 'h' && granValue === 1) || granDefault === 'm'
                      ? [
                          {
                            value: 'raw',
                            label: { formatted: 'widget.rawData' },
                          },
                        ]
                      : []),
                  ]}
                  onChange={(value) => onValueUpdate(value, 'calculationMethod')}
                  search={false}
                  value={calculationMethod}
                />
              </>
            )}
          </TimePicker>
        </div>
        <WrappedComponent
          {...props}
          title={null}
          widgetTitle={title}
          icon={null}
          startTime={startTime}
          endTime={endTime}
          timePreset={preset}
          granularity={
            granularityValue != null && granularity != null
              ? `${granularityValue}${granularity}`
              : undefined
          }
          granularityValue={granularityValue}
          granularityUnit={granularity}
          calculationMethod={calculationMethod}
          heatmapGranularityValue={heatmapGranularityValue}
          heatmapGranularityUnit={heatmapGranularityUnit}
          onSelectionChange={onSelectionChange}
          freeze={freeze}
        />
      </TimeSelectionStyled>
    )
  }

  ComponentWithTimeSelection.displayName = 'ComponentWithTimeSelection'
  return ComponentWithTimeSelection
}
