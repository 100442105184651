export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.matrix',
    category: 'widget.others',
    keywords: ['matrix', 'container'],
    img: '/resources/images/widgets/matrix.png',
    info: {
      'nl-NL':
        '<p>De matrix widget toont de huidige datapuntwaarden voor een reeks uitrustingen van een bepaald type. Daarnaast kunnen subwidgets worden voorzien om de apparatuur verder te detailleren.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's titel</li>\n" +
        '<li><strong>Pictogram</strong> Pictogram van de widget</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Type uitrusting</strong> Selecteer het type uitrusting</li>\n' +
        '<li><strong>Uitrustingen voor geselecteerd type</strong> Selecteer de uitrustingen voor het geselecteerde type</li>\n' +
        '<li><strong>Matrix Detail</strong> Voeg subwidgets toe aan de hoofdwidget</li>\n' +
        '<li><strong>Uitrustingslabel</strong> Label voor de uitrusting</li>\n' +
        '<li><strong>Voorinstelling</strong> Preset van de uitrusting</li>\n' +
        '<li><strong>Toon Eenheid</strong> Toon de Eenheid of niet</li>\n' +
        '<li><strong>Verberg Waarde</strong> Toon de waarde of niet</li>\n' +
        '<li><strong>Show Description</strong> Toon de beschrijving of niet</li>\n' +
        '<li><strong>Bijschriften</strong> Label, Icoon en Type voor vaste waarden toevoegen</li>' +
        '</ul>\n',
      'fr-FR':
        '<h4>Matrix</h4>\n' +
        "<p>Le widget  matrix affiche les valeurs actuelles des points de données pour une série d'équipements d'un certain type. En outre, des sous-widgets peuvent être prévus pour détailler davantage l'équipement.</p>\n" +
        '<ul>\n' +
        '<li><strong>Étiquette </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> icône du widget</li>\n' +
        '<li><strong>Couleur </strong> sélectionnez une couleur globale pour le widget</li>\n' +
        "<li><strong>Type d'équipement </strong> Sélectionnez le type d'équipement</li>\n" +
        '<li><strong>Equipements pour le type sélectionné </strong> Sélectionnez les équipements pour le type sélectionné</li>\n' +
        '<li><strong>Détail de la matrice </strong> Ajouter des sous-widgets au widget principal</li>\n' +
        "<li><strong>Étiquette de l'équipement </strong> Label pour l'équipement</li>\n" +
        "<li><strong>Préréglage </strong> Préréglage de l'équipement</li>\n" +
        "<li><strong>Show Unit </strong> Afficher l'unité ou non</li>\n" +
        '<li><strong>Cacher la valeur </strong> Afficher la valeur ou non</li>\n' +
        '<li><strong>Afficher la description </strong> Afficher la description ou non</li>\n' +
        '<li><strong>Légendes </strong> Ajouter un label, une icône et un type pour les valeurs fixes</li>' +
        '</ul>\n',
      'en-US':
        '<h4>Matrix</h4>\n' +
        '<p>The matrix widget displays current datapoint values for a series of equipments of a certain type. In addition to that, subwidgets can be foreseen to further detail the equipment.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Color</strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Equipment Type</strong> Select the equipment type</li>\n' +
        '<li><strong>Equipments for selected type</strong> Select the equipments for the selected type</li>\n' +
        '<li><strong>Matrix Detail</strong> Add subwidgets to the main widget</li>\n' +
        '<li><strong>Equipment Label</strong> Label for the equipment</li>\n' +
        '<li><strong>Preset</strong> Preset of the equipment</li>\n' +
        '<li><strong>Show Unit</strong> Display the Unit or not</li>\n' +
        '<li><strong>Hide Value</strong> Display the value or not</li>\n' +
        '<li><strong>Show Description</strong> Display the description or not</li>\n' +
        '<li><strong>Captions</strong> Add label, Icon and Type for fixed values</li>\n' +
        '</ul>',
    },
    default: {
      type: 'Matrix',
      title: 'widget.matrix',
      icon: 'fas fa-info',
      equipments: [],
      equipmentTemplate: {
        elements: [],
      },
    },
    preview: {
      icon: 'fas fa-th-large',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
        rootUpdate: true,
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
        rootUpdate: true,
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
        rootUpdate: true,
      },
      {
        category: 'widget.datapoints',
        componentName: 'EquipmentSelector',
        dataKey: 'equipments',
        componentProps: {
          section: {
            name: 'equipments',
            componentName: 'EquipmentConfigurator',
            componentProps: {
              dataKey: 'equipmentTemplate',
            },
          },
        },
      },
    ],
  },
}

export default config
