import styled, { css } from 'styled-components'

import Tooltip from 'components/Global/Tooltip'

const NotificationsStyled = styled.div``

export const NotificationTooltip = styled(Tooltip)`
  > span {
    position: relative;

    &:after {
      content: '';
      opacity: ${({ hasNotifications }) => (hasNotifications ? 1 : 0)};
      position: absolute;
      top: -4px;
      right: 4px;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      background: ${({ theme, lastNotificationType }) => theme.color[lastNotificationType]};
      border: 1px solid ${({ theme }) => theme.color.backgroundFront};
    }
  }

  i.Icon {
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: ${({ theme }) => theme.color.mainText};
    cursor: pointer;
  }
`

export const Notification = styled.div`
  display: flex;
  align-items: flex-start;

  &:hover {
    i.Notification__delete {
      opacity: 1;
      transform: scale(1);
    }
  }

  i.Icon {
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: ${({ theme }) => theme.color.white};
  }

  h4 {
    flex: 1;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.color.strongText};
  }

  .Button {
    margin-left: 0.5rem;
  }

  .NotificationProgress {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  i.Notification__delete {
    transition: transform 0.2s ease, opacity 0.2s ease;
    position: absolute;
    color: ${({ theme }) => theme.color.softText};
    right: 0.5rem;
    bottom: 0.5rem;
    opacity: 0;
    transform: scale(0);
    cursor: pointer;

    &:hover {
      ${({ theme }) => theme.color.action};
    }
  }

  ${({ variant }) =>
    variant === 'notification'
      ? css`
          flex-wrap: wrap;

          .Notification__label {
            display: block;
            width: 100%;
            text-align: left;
            padding-right: 2rem;

            + .Button {
              margin-left: auto;
            }
          }

          i.Notification__close {
            color: ${({ theme }) => theme.color.softText};
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            cursor: pointer;
          }

          .Button {
            margin-top: 0.5rem;
          }
        `
      : css`
          position: relative;
          padding: 1rem;
          border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};

          .Notification__content {
            flex: 1;
            align-items: center;
            min-height: 4rem;
          }
        `}
`

export const NotificationContainer = styled.div`
  background: ${({ theme }) => theme.color.backgroundFront};
  box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
  border-radius: 0.5rem;

  .Title {
    padding: 1rem;
    font-size: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  }

  .NotificationContainer__scroll {
    max-height: 400px;
    overflow: auto;
  }
`

export const NotificationProgressStyled = styled.div`
  position: relative;
  overflow: hidden;

  span {
    width: 100%;
    height: 2px;
    position: relative;
    background: ${({ theme }) => theme.color.main};
    display: block;
    animation: progressLinearMovement 2.5s infinite;
    animation-delay: 0;
  }

  @keyframes progressLinearMovement {
    0% {
      left: 0;
      width: 0;
    }
    50% {
      left: 100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 0;
    }
  }
`
export default NotificationsStyled
