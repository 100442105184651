import React from 'react'

import ValuePicker from 'components/Form/components/ValuePicker'
import { DatapointDetails } from 'components/Global/FormField/Datapoints'

import Button from '../../Button'
import { useEntitytHover } from './hooks'

const EntitiesPicker = ({ value = [], valueKeys = ['id', 'name'], type = 'site', onChange }) => {
  const { hoveredElement, detail, loading, onHover } = useEntitytHover()

  const handleChange = (payload) => {
    onChange(payload)
  }
  const onRemoveEntity = (e, { id }) => {
    onChange(value.filter((v) => v.id !== id))
  }

  return (
    <>
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} loading={loading} />
      <div>
        {value.map(({ id, name }) => (
          <div onMouseEnter={(e) => onHover(e, id)} onMouseLeave={(e) => onHover(e, null)}>
            <Button
              key={id}
              label={name}
              variant="tag"
              onIconClick={(e) => onRemoveEntity(e, { id, name })}
              icon={'fas fa-xmark'}
            />
          </div>
        ))}
      </div>
      <ValuePicker
        value={value}
        hideValue
        search
        query={{
          name: 'PERMITTED_ENTITIES',
          dataKey: 'allowedEntities',
          variables: {
            type,
          },
        }}
        isMultiSelect
        selectionKeys={{
          value: 'id',
          label: 'name',
        }}
        variant="Tags"
        valueKeys={valueKeys}
        onChange={handleChange}
        onHover={(event, entity) => onHover(event, entity?.id)}
        persistSearchValue
      />
    </>
  )
}

EntitiesPicker.propTypes = {}

EntitiesPicker.defaultProps = {
  options: [],
}

export default EntitiesPicker
