import { gql } from '@apollo/client'

export const UNITS_QUERY = gql`
  query units {
    units {
      id
      name
      formatted
      type
    }
  }
`
export const UNIT_QUERY = gql`
  query unit($id: Float, $name: String) {
    unit(id: $id, name: $name) {
      id
      name
      difference
      formatted
      type
    }
  }
`
