import { css } from 'styled-components'

export const icon = css`
  padding: 0 0.5rem;
  font-size: 1.1rem;
  &:hover {
    .Icon {
      color: ${({ theme }) => theme.color.action};
    }
  }
  .Icon {
    transition: color 0.2s ease;
    color: ${({ theme }) => theme.color.mainText};
  }
  .Button__label {
    margin: -1em 0 0 0.2em;
    font-size: 0.8em;
    line-height: 1;
    padding: 0 0.2em;
    border: 1px solid;
    border-radius: 1em;
  }
`
