import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { InformationContainer, InformationStyled } from '../Information/styled'

export const DetailStyled = styled(InformationStyled)`
  margin: 0.25rem 0.5rem;
  overflow: hidden;
  height: auto;
  ${({ layout, width }) => {
    const portrait = layout === 'portrait'
    return css`
      .DetailContainer__content-wrapper {
        width: 100%;
      }

      &.has-files {
        .DetailContainer__content {
          flex-direction: ${portrait ? 'column' : 'row'};

          .DetailContainer__content-wrapper {
            width: ${!portrait && '50%'};
            max-width: ${!portrait && '50%'};
            min-width: ${!portrait && '50%'};
          }

          .DetailContainer__images {
            height: ${portrait ? width / 2 : width / 4}px;
            padding: ${portrait ? '.8rem 0' : '.8rem 0 .8rem .8rem'};

            .ant-image {
              height: 100%;
            }
          }

          .DetailContainer__files {
            padding: ${portrait ? 0 : '0 0 .6rem .6rem'};
            margin: ${portrait && '0 -.2rem'};
          }
        }
      }
    `
  }}
`

export const DetailContainer = styled(InformationContainer)`
  display: block;
  overflow: hidden;
  width: 100%;

  ${({ fontColor, theme }) => {
    const overlay = transparentize(0.85, fontColor || theme.color.main)
    return css`
      .DetailContainer__images {
        .ant-image-img {
          background: ${overlay};
        }
      }

      .DetailContainer__fields:not(:empty) {
        &.tags {
          background: ${overlay};
        }
      }
    `
  }}
  .Information__title-container {
    justify-content: flex-start !important;
    margin-bottom: 0 !important;
    display: grid !important;
    grid-template: auto / auto 1fr;

    i.Icon {
      justify-content: center;
      width: 3rem !important;
      height: 3rem !important;
      margin-right: 0.5rem;
      border-radius: 0.3rem;
      justify-self: end;
      grid-row: 1 / span 2 !important;
      grid-column: 1 !important;
    }

    .Title {
      grid-column: 2;
    }

    .Title__value {
      grid-row: 2;
      grid-column: 2;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 0;
    }
  }

  .DetailContainer__content {
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex: 1;
    margin: 0 0.5rem 0.5rem;

    .DetailContainer__images {
      .Slider {
        border-radius: 0.3rem;
        height: 100%;

        &__nav {
          .Icon {
            transition: opacity 0.3s ease;
            opacity: 0.5;
            color: #ffffff;

            &:hover {
              opacity: 1;
            }
          }
        }

        .ant-image-img {
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .DetailContainer__files {
      display: flex;
      flex-wrap: wrap;

      .Button {
        padding: 0.5rem;
        margin: 0.2rem;
        border: 1px solid;
        border-radius: 0.3rem;
      }

      .Icon {
        width: 1rem;
        margin-right: 0.2rem;
      }
    }
  }

  .DetailContainer__fields:not(:empty) {
    padding: 0.7rem;
    flex: 1;

    &.tags {
      border-radius: 0.3rem;
    }

    &.markers {
      display: flex;
      flex-wrap: wrap;
      padding: 0.7rem 0;

      span {
        margin: 0 0.4rem 0.4rem 0;
      }
    }
  }
`

export const FieldStyled = styled.p`
  display: flex;
  align-items: center;

  label {
    flex: 1;
    font-weight: 600;
  }

  > span {
    display: block;
    max-width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Icon {
    width: 1rem;

    &.type-img {
      width: auto;
      padding: 0;

      img {
        height: 1.5rem;
        width: auto;
      }
    }
  }
`

export const TagStyled = styled.span`
  border: 1px solid;
  border-radius: 0.3rem;
  padding: 0 0.2rem;
`
