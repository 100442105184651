export default {
  301: 'Error on datapoint {datapointName}: Geen conversiefactor voor mobiele verbranding gevonden voor voertuigtype {vehicleTypeName} en brandstoftype {vehicleFuelTypeName}',
  302: 'Error on datapoint {datapointName}: Geen actieve conversiefactor voor mobiele verbranding gevonden voor voertuigtype {vehicleFuelTypeName} en brandstoftype {vehicleFuelTypeName} op datum {datetime}',
  303: 'Error on datapoint {datapointName}: Geen conversie gevonden voor {fromUnitName} naar {toUnitName}',
  304: 'Error on datapoint {datapointName}: Geen conversiefactor voor gekochte energie gevonden voor land {countryName} en energieType {energyTypeName}',
  305: 'Error on datapoint {datapointName}: Meter {entityId} heeft geen contract',
  306: 'Error on datapoint {datapointName}: Geen actief energiecontract gevonden voor meter {entityId} op datum {datetime}',
  307: 'Error on datapoint {datapointName}: Geen conversiefactor voor stationaire verbranding gevonden voor land {countryName} en brandstoftype {fuelTypeName}',
  308: 'Error on datapoint {datapointName}: Geen actieve conversiefactor gevonden voor brandstoftype {fuelTypeName} en land {countryName} op datum {dateTime}',
  309: 'Error on datapoint {datapointName}: Niet voor elk kWh-verbruik van voertuig {vehicleId} is een energiecontract voorzien',
  310: 'Error on datapoint {datapointName}: No active conversion factor found for energy type {energyTypeName} and country {countryName} on date {dateTime}',
  311: 'Error on datapoint {datapointName}: Geen "typeGas" waarde gevonden op datapunt {entityId}',
  312: 'Error on datapoint {datapointName}: Geen gasdefinitie gevonden voor typeGas id {gasId} op datapunt {entityId}',
  313: 'Error on datapoint {datapointName}: No conversionfactor found for entity {entityName} with vehicleType: {vehicleTypeName}, vehicle fueltype: {vehicleFuelTypeName} and transportLoadType: {transportLoadTypeName}',
  314: 'Error on datapoint {datapointName}: No conversionfactor found for entity {entityName} with travelMethod: {travelMethodName}, vehicle fueltype: {vehicleFuelTypeName}',
  315: 'Error on datapoint {datapointName}: Emission Absolute datapoint could not be found on entity {entityName}',
  316: 'Error on datapoint {datapointName}: Emission Biogenic Co2 datapoint could not be found on entity {entityName}',
  401: 'Error on datapoint {datapointName}: Geen organisatie voorzien',
  402: 'Error on datapoint {datapointName}: Startdatum {start} is later of gelijk aan einddatum {end}',
  403: 'Error on datapoint {datapointName}: Geen scope definitie gevonden voor scope {scopeId}',
  404: 'Error on datapoint {datapointName}: Entiteit met id {id} bestaat niet',
  405: 'Error on datapoint {datapointName}: Contract met id {e} bestaat niet maar werd gebruikt voor voertuig xxx',
  406: 'Error on datapoint {datapointName}: Emissionfactor {emissionfactor} is not available',
  407: 'Error on datapoint {datapointName}: Emissionfactors {emissionfactors} are not available',
  500: 'Error on datapoint {datapointName}: Interne fout',
}
