import styled from 'styled-components'

import { FieldStyled, Value } from 'components/Form/components/Field/styled'

import Field from '../../components/Field'

const NumberUnitSelectFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    ${FieldStyled} {
      padding: 0;
      .ant-input-number {
        border-radius: 2px 0 0 2px;
        border-right: none;
      }
      .ant-select-selector {
        border-radius: 0 2px 2px 0;
      }
    }
  }
`
export default NumberUnitSelectFieldStyled
