import React from 'react'

import { PropTypes } from 'prop-types'

import { Container } from './styled'

const variants = {
  closed: {
    height: 0,
    overflow: 'hidden',
  },
  open: {
    height: 'auto',
    overflow: 'hidden',
  },
}

const Expansion = ({ children, open = false, ...props }) => {
  return (
    <Container
      {...props}
      variants={variants}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      exit="closed"
    >
      {children}
    </Container>
  )
}

Expansion.propTypes = {
  open: PropTypes.bool,
}

Expansion.defaultProps = {}

export default Expansion
