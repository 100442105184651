import { useEffect, useRef, useState } from 'react'

export const useAccordeon = (defaultOpen, recalculateHeight, contextRecalculateHeight) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [height, setHeight] = useState(false)
  const wrapper = useRef()
  const timeout = useRef()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setHeight(isOpen ? wrapper.current?.clientHeight : 0)
    }, 300)
    return () => clearTimeout(timeout.current)
    // eslint-disable-next-line
  }, [isOpen, wrapper.current, recalculateHeight, contextRecalculateHeight])

  return {
    wrapper,
    height,
    isOpen,
    setIsOpen,
  }
}
