import React from 'react'

import PropTypes from 'prop-types'
import { useFieldArray } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'

import RuleValueField from './RuleValueField'
import { RuleValueListFieldStyled } from './styled'

const RuleValueListField = ({
  value,
  name,
  filter,
  parentIsEnabled,
  intl,
  allowEdit,
  ...fieldProps
}) => {
  const { fields } = useFieldArray({
    name,
  })

  return (
    <RuleValueListFieldStyled {...fieldProps}>
      {({ watch, loading }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }

        const filterFn = ({ item }) => {
          if (!filter) {
            return true
          }
          return filter(item, { watch })
        }

        const renderValue = value === undefined && name === undefined ? value : fields
        const list = Array.isArray(renderValue)
          ? renderValue?.map((item, index) => ({ item, index })).filter(filterFn)
          : []
        return (
          <>
            {!list || list.length === 0 ? (
              <span>
                {intl
                  ? intl.formatMessage({
                      id: 'componentCaptions.noFields',
                    })
                  : 'No fields'}
              </span>
            ) : (
              list?.map(({ item, index }) => (
                <RuleValueField
                  key={item.id}
                  name={`${name}[${index}]`}
                  defaultValue={item}
                  disabled={!parentIsEnabled}
                  readOnly={!allowEdit}
                />
              ))
            )}
          </>
        )
      }}
    </RuleValueListFieldStyled>
  )
}

RuleValueListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default RuleValueListField
