import React, { useEffect, useRef, useState } from 'react'

import TooltipContentPortal from 'components/Global/Tooltip/TooltipContent/TooltipContentPortal'
import { IconCellDropdownStyled } from 'components/Layout/List/components/IconCell/styled'

const IconCellDropdown = ({ valueElement, children, className, timeout = 5000 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const element = useRef()
  const timeoutRef = useRef()

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])
  const handleToggleOpen = () => {
    setIsOpen(false)
    clearTimeout(timeoutRef.current)
  }
  return (
    <IconCellDropdownStyled className="IconCellDropdown" variant="iconCellMore" ref={element}>
      <span
        className="IconCell__action"
        ref={(ref) => {
          if (!ref) return
          ref.onclick = (e) => {
            setIsOpen((state) => !state)
            timeoutRef.current = setTimeout(() => {
              setIsOpen(false)
            }, timeout)
            e.stopPropagation()
          }
        }}
      >
        {valueElement}
      </span>
      {isOpen && (
        <TooltipContentPortal
          isOpen
          parentRef={element.current}
          variant="IconCellDropdown"
          className={className}
          onToggleOpen={handleToggleOpen}
        >
          {typeof children === 'function' ? children({ toggleOpen: setIsOpen }) : children}
        </TooltipContentPortal>
      )}
    </IconCellDropdownStyled>
  )
}

export default IconCellDropdown
