import styled from 'styled-components'

import Selector from '../Selector/styled'

const LinkPickerStyled = styled.div`
  .Input {
    width: 100%;
  }
  ${Selector} {
    margin-bottom: 1rem;
  }
`
export default LinkPickerStyled
