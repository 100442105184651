import React, { useCallback } from 'react'

import { Popconfirm } from 'antd'
import { FormattedMessage } from 'react-intl'

import { AntButton } from '../../../Global/AntDesign'

const CancelButton = ({ children, onClick, text, confirm }) => {
  const handleClick = useCallback(() => {
    onClick()
  }, [onClick])

  const handleConfirm = useCallback(() => {
    handleClick()
  }, [handleClick])

  if (!confirm) {
    return (
      <AntButton id="cancelOrClose" onClick={handleClick} color="action">
        {children}
      </AntButton>
    )
  }

  return (
    <Popconfirm
      title={text}
      onConfirm={handleConfirm}
      okText={<FormattedMessage id="confirm" />}
      cancelText={<FormattedMessage id="cancel" />}
      placement="bottomRight"
    >
      <AntButton color="action">{children}</AntButton>
    </Popconfirm>
  )
}

export default CancelButton
