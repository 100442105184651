import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { useCompanyAccounts } from 'services/user'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import TagsStyled from 'components/Dashboard/components/Tags/styled'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'
import TagEditor from 'components/Form/fields/TagField/TagEditor'
import Button from 'components/Global/Button'

import { UserSelectFieldStyled } from './styled'

const UserSelectField = ({
  value,
  name,
  filter,
  dependencies,
  userType,
  onChange,
  uncontrolled,
  readOnly,
  disableList = [],
  defaultValue,
  ...fieldProps
}) => {
  const { data } = useCompanyAccounts(true)

  const options = useMemo(() => {
    let users = []
    let res = []

    if (!readOnly && data) {
      switch (userType) {
        case 'voice_mobile':
          users = data.filter((user) => user.mobile !== null)

          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.mobile})`,
            type: 'voice_mobile',
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.mobile})`),
          }))

          /* users = data.filter((user) => user.phone !== null)

          res = [
            ...res,
            ...users.map((user) => ({
              id: user.id,
              name: `${user.surname} ${user.firstname} (${user.phone})`,
              type: 'voice phone',
              color: generateTagColor(`${user.surname} ${user.firstname} (${user.phone})`),
            })),
          ] */
          break

        case 'voice_fixed':
          users = data.filter((user) => user.phone !== null)

          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.phone})`,
            type: 'voice_fixed',
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.phone})`),
          }))
          break

        case 'sms':
          users = data.filter((user) => user.mobile !== null)
          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.mobile})`,
            type: userType,
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.mobile})`),
          }))
          break

        default:
          // Default = 'mail'
          users = data.filter((user) => user.email !== null)
          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.email})`,
            type: userType,
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.email})`),
          }))
      }
      return res.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    }
    return []
  }, [data, userType, readOnly])

  return (
    <UserSelectFieldStyled {...fieldProps}>
      {({ control, watch, loading, getValues, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }
        const values = getValues()
        if (isEditMode) {
          return (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ onChange: fromFieldOnChange, value: controllerValue = [] }) => {
                const handleChange = (value) => {
                  const update = value.map((v) => v.id)

                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }
                const renderValue =
                  value === undefined && name === undefined ? value : controllerValue

                let list = []
                if (renderValue?.length && options?.length) {
                  for (let i = 0; i < renderValue.length; i++) {
                    let user
                    if (renderValue[i].id) {
                      user = options.find((u) => u.id === renderValue[i].id)
                    } else {
                      user = options.find((u) => u.id === renderValue[i])
                    }
                    if (user) {
                      list.push(user)
                    }
                  }
                }

                const handleRemoveItem = ({ id }) => {
                  const update = controllerValue.filter((item) => item !== id)
                  // const { [id]: removed, ...update } = controllerValue
                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }

                return (
                  <TagsStyled className="Tags">
                    <ValuePicker
                      value={list}
                      options={options}
                      search
                      input
                      variant="Tags"
                      selectionKeys={{ label: 'name', value: 'id' }}
                      isMultiSelect
                      onChange={handleChange}
                      width={380}
                      valueElement={
                        <div className="ValuePickerValue">
                          {list.map(({ id, name, value }) => (
                            <Button
                              key={id}
                              label={name}
                              variant="tag"
                              color={generateTagColor(name)}
                              icon="fas fa-xmark"
                              onIconClick={() => handleRemoveItem({ id, name })}
                              disabled={disableList.includes(name)}
                            >
                              {userType !== 'marker' ? null : (
                                <TagEditor
                                  name={`${name}.${name}`}
                                  tagName={name}
                                  value={value}
                                  values={values}
                                  isEditMode
                                  watch={watch}
                                  valueKey={false}
                                />
                              )}
                            </Button>
                          ))}
                        </div>
                      }
                    />
                  </TagsStyled>
                )
              }}
            />
          )
        }
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        let transformedValue = []
        if (fieldValue?.length && options?.length) {
          for (let i = 0; i < fieldValue.length; i++) {
            let user
            if (fieldValue[i].id) {
              user = options.find((u) => u.id === fieldValue[i].id)
            } else {
              user = options.find((u) => u.id === fieldValue[i])
            }
            if (user) {
              transformedValue.push(user)
            }
          }
        }
        return (
          <TagsStyled className="Tags">
            <div className="ValuePickerValue">
              {transformedValue &&
                transformedValue.map((user) => (
                  <Button
                    key={user.id}
                    label={user.name}
                    variant="tag"
                    color={generateTagColor(user.name)}
                    isActive
                  />
                ))}
            </div>
          </TagsStyled>
        )
      }}
    </UserSelectFieldStyled>
  )
}

UserSelectField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default UserSelectField
