import BarChart from 'components/Widgets/BarChart/BarChart/config'
import GhgBarChart from 'components/Widgets/BarChart/GhgBarChart/config'
import GhgHistoricalBarChart from 'components/Widgets/BarChart/GhgHistoricalBarChart/config'
import HistoricalBarChart from 'components/Widgets/BarChart/HistoricalBarChart/config'
import EnergyClassification from 'components/Widgets/BulletGraph/EnergyClassification/config'
import Calendar from 'components/Widgets/Calendar/Calendar/config'
import CircleGauge from 'components/Widgets/Gauge/CircleGauge/CircleGauge/config'
import EfficiencyGauge from 'components/Widgets/Gauge/CircleGauge/EfficiencyGauge/config'
import AbsoluteGauge from 'components/Widgets/Gauge/ClassicGauge/AbsoluteGauge/config'
import GhgAbsoluteGauge from 'components/Widgets/Gauge/ClassicGauge/GhgAbsoluteGauge/config'
import GhgRelativeGauge from 'components/Widgets/Gauge/ClassicGauge/GhgRelativeGauge/config'
import RelativeGauge from 'components/Widgets/Gauge/ClassicGauge/RelativeGauge/config'
import Consumption from 'components/Widgets/Gauge/SolidGauge/Consumption/config'
import Gauge from 'components/Widgets/Gauge/SolidGauge/Gauge/config'
import GhgGauge from 'components/Widgets/Gauge/SolidGauge/GhgGauge/config'
import AgEventLog from 'components/Widgets/Grid/AgEventLog/config'
import AgGridCard from 'components/Widgets/Grid/AgGridCard/config'
import GhgGrid from 'components/Widgets/Grid/GhgGrid/config'
import Grid from 'components/Widgets/Grid/Grid/config'
import YearOverview from 'components/Widgets/Grid/YearOverview/config'
import DatapointHeatMap from 'components/Widgets/HeatMap/DatapointHeatMap/config'
import GhgHistoricalLineChart from 'components/Widgets/LineChart/GhgHistoricalLineChart/config'
import GhgLineChart from 'components/Widgets/LineChart/GhgLineChart/config'
import GhgTrendLine from 'components/Widgets/LineChart/GhgTrendLine/config'
import GraphCard from 'components/Widgets/LineChart/GraphCard/config'
import HistoricalComparisonGraph from 'components/Widgets/LineChart/HistoricalComparisonGraph/config'
import TrendLine from 'components/Widgets/LineChart/TrendLine/config'
import SiteMap from 'components/Widgets/Map/SiteMap/config'
import Clock from 'components/Widgets/other/Clock/Clock/config'
import Connectivity from 'components/Widgets/other/Connectivity/Connectivity/config'
import ConsumptionExport from 'components/Widgets/other/ConsumptionExport/ConsumptionExport/config'
import DataEntry from 'components/Widgets/other/DataEntry/DataEntry/config'
import Empty from 'components/Widgets/other/Empty/config'
import File from 'components/Widgets/other/File/File/config'
import Image from 'components/Widgets/other/FloorMap/Image/config'
import Information from 'components/Widgets/other/Information/Information/config'
import GhgLastValueWidget from 'components/Widgets/other/LastValueWidget/GhgLastValueWidget/config'
import LastValueWidget from 'components/Widgets/other/LastValueWidget/LastValueWidget/config'
import Navigation from 'components/Widgets/other/LastValueWidget/Navigation/config'
import Matrix from 'components/Widgets/other/Matrix/Matrix/config'
import MeterOverview from 'components/Widgets/other/MeterOverview/MeterOverview/config'
import NavigationWidget from 'components/Widgets/other/NavigationWidget/NavigationWidget/config'
import Placeholder from 'components/Widgets/other/Placeholder/config'
import Sdg from 'components/Widgets/other/Sdg/Sdg/config'
import GhgStatus from 'components/Widgets/other/Status/GhgStatus/config'
import Status from 'components/Widgets/other/Status/Status/config'
import DataTable from 'components/Widgets/other/StatusGrid/DataTable/config'
import ComparisonPieChart from 'components/Widgets/PieChart/ClassicPieChart/ComparisonPieChart/config'
import GhgPieChart from 'components/Widgets/PieChart/ClassicPieChart/GhgPieChart/config'
import ChartCard from 'components/Widgets/Sparkline/ChartCard/config'
import GhgSparkline from 'components/Widgets/Sparkline/GhgSparkline/config'
import XRange from 'components/Widgets/XRange/XRange/config'

export const widgetConfig = ({ type = 'GraphCard' }) =>
  ({
    AgGridCard,
    Grid,
    GhgGrid,
    Calendar,
    ChartCard,
    GhgSparkline,
    CircleGauge,
    Connectivity,
    Consumption,
    DatapointHeatMap,
    EfficiencyGauge,
    Empty,
    GraphCard,
    GhgLineChart,
    TrendLine,
    GhgTrendLine,
    HistoricalComparisonGraph,
    GhgHistoricalLineChart,
    LastValueWidget,
    GhgLastValueWidget,
    Navigation,
    Placeholder,
    YearOverview,
    AgEventLog,
    Gauge,
    GhgGauge,
    AbsoluteGauge,
    GhgAbsoluteGauge,
    RelativeGauge,
    GhgRelativeGauge,
    Image,
    Matrix,
    SiteMap,
    Clock,
    Sdg,
    ComparisonPieChart,
    GhgPieChart,
    Status,
    File,
    Information,
    BarChart,
    GhgBarChart,
    HistoricalBarChart,
    GhgHistoricalBarChart,
    GhgStatus,
    ConsumptionExport,
    DataEntry,
    DataTable,
    MeterOverview,
    EnergyClassification,
    NavigationWidget,
    CountLastValueWidget: LastValueWidget,
    PolygonHighlight: {
      editProps: {},
    },
    PhaseToggle: {
      subscribeToSocket: true,
      editProps: {},
    },
    LightBulb: {
      subscribeToSocket: true,
      editProps: {},
    },
    DatapointLabel: {
      subscribeToSocket: true,
      editProps: {},
    },
    Label: {
      editProps: {},
    },
    NavigationLabel: {
      editProps: {},
    },
    Highlight: {
      subscribeToSocket: true,
      editProps: {},
    },
    XRange,
  }[type])
