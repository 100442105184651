import React, { useEffect, useState } from 'react'

import { MultiFieldContainer } from '../FormFieldElement/styled'
import NumberInput from './NumberInput'
import NumberRangeInputStyled from './styled'

const NumberRangeInput = ({ value = [], onChange, onBlur }) => {
  const [inputValue, setNumberInputValue] = useState(value || [0, 0])

  useEffect(() => {
    setNumberInputValue(value)
  }, [value])

  const getRangeValue = (v, index) => {
    return index === 0
      ? [parseFloat(v), parseFloat(v) > inputValue[1] ? parseFloat(v) : inputValue[1]]
      : [inputValue[0], parseFloat(v) < inputValue[0] ? inputValue[0] : parseFloat(v)]
  }

  const handleChange = (v, index) => {
    const newValue = getRangeValue(v, index)
    setNumberInputValue(newValue)
    onChange && onChange(newValue)
  }
  const handleBlur = (v, index) => {
    const newValue = getRangeValue(v, index)
    setNumberInputValue(newValue)
    onBlur && onBlur(newValue)
  }

  return (
    <NumberRangeInputStyled className="NumberRangeInput">
      <MultiFieldContainer>
        <NumberInput
          value={inputValue[0]}
          onBlur={(value) => handleBlur(value, 0)}
          onChange={(value) => handleChange(value, 0)}
        />
        <NumberInput
          value={inputValue[1]}
          onBlur={(value) => handleBlur(value, 1)}
          onChange={(value) => handleChange(value, 1)}
        />
      </MultiFieldContainer>
    </NumberRangeInputStyled>
  )
}

export default NumberRangeInput
