import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'
import slugify from 'slugify'

import { getLabel } from '../utils/functions'
import SelectorStyled, { SelectOption } from './styled'

const Selector = ({
  options,
  onSelect,
  onClick,
  onHover,
  onIconClick,
  isMultiSelect,
  selectionKeys = { value: 'value' },
  value,
  intl,
  className,
  variant,
  canReselect,
  renderFunction,
  dataCy = 'Selector',
}) => {
  const selectionKey = selectionKeys.value || selectionKeys.id
  const isSelected = (option) => {
    if (value && isMultiSelect) {
      return value.some(
        (v) => (get(v, selectionKey) ?? v) === (get(option, selectionKey) ?? option)
      )
    }
    return (get(value, selectionKey) ?? value) === (get(option, selectionKey) ?? option)
  }

  const countDuplicates = (option) => {
    const count = value.filter(({ id }) => id === option.id).length
    return count ? ` (${count})` : ''
  }

  return (
    <SelectorStyled className={`Selector ${className}`} variant={variant}>
      {options.map((option, index) => {
        const label = `${getLabel(option, selectionKeys.label, intl)}${
          canReselect ? countDuplicates(option) : ''
        }`
        return renderFunction ? (
          renderFunction({
            option,
            label,
            isSelected: isSelected(option),
          })
        ) : (
          <SelectOption
            key={`option-${index}`}
            label={label}
            color={option.color}
            icon={option.icon?.code || option.icon}
            disabled={option.disabled}
            onClick={(e) =>
              onClick ? onClick(e, { ...option, index }) : onSelect(e, { ...option, index })
            }
            onIconClick={(e) => (onIconClick ? onIconClick(e, option) : onSelect(e, option))}
            onHover={(e, action) => onHover && onHover(e, action === 'enter' ? option : null)}
            isActive={option.isActive || isSelected(option)}
            variant={variant}
            dataCy={`${dataCy}-${
              option.id || (label ? slugify(label) : option.icon?.code || option.icon)
            }`}
          />
        )
      })}
    </SelectorStyled>
  )
}

Selector.propTypes = {}

Selector.defaultProps = {}

export default injectIntl(Selector)
