import styled from 'styled-components'

import OptionPicker from 'components/Form/components/OptionPicker'

import Selector from '../Selector/styled'

const ColumnPickerStyled = styled(OptionPicker)`
  ${Selector} {
    margin-bottom: 1rem;
  }
`
export default ColumnPickerStyled
