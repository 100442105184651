import React, { useEffect, useMemo, useState } from 'react'

import { useTheme } from 'styled-components'

import { getDatapointsCalculatedValue } from 'util/datapointCalculationFunctions'
import { useComparatorsPayload, useDatapointsForTimeRangePayload } from 'util/hooks'
import { useQueryData } from 'services/data'

import { useActionConfirmation } from 'components/Dashboard/components/ActionConfirmation/hooks'
import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/other/LastValueWidget/Ui'

const LastValueWidget = ({
  datapoints = [],
  writeDatapoint = [],
  showUnit = false,
  method = 'sum',
  timeRange,
  comparisonPeriod,
  captions,
  intl,
  title,
  icon,
  type,
  preview = {},
  styleProps,
  dataProps = {},
  newSocketValue,
  customLogic,
  difference,
  showDelta = false,
  decimals = 2,
  unit,
  ...props
}) => {
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps
  const [valueState, setValueState] = useState(null)
  const [confirmation, onConfirmationRequest] = useActionConfirmation()

  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'lastValueMain',
    },
    deps: [datapointsPayload],
    skip: datapoints.length === 0,
  })

  const { comparatorDatapoints } = useComparatorsPayload({
    datapoints,
    comparators: 'main',
    timeRange: comparisonPeriod,
  })

  const { data: comparatorsData, loading: comparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: comparatorDatapoints,
      widgetType: 'lastValueComparator',
    },
    deps: [comparatorDatapoints],
    skip: comparatorDatapoints.length === 0,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data?.data?.datapoints

  useEffect(() => {
    if (confirmation.isConfirmed) {
      setValueState(confirmation.value)
    }
  }, [confirmation])

  useEffect(() => {
    if (validData?.length) {
      setValueState(
        datapoints.length
          ? getDatapointsCalculatedValue(validData, { method, customLogic, decimals })
          : null
      )
    }
  }, [customLogic, validData, method, difference, comparisonPeriod, datapoints.length, decimals])

  const handleToggle = async () => {
    const writeDatapointId = writeDatapoint[0]?.id
    if (!writeDatapointId) {
      return
    }
    const newToggleState = valueState ? 0 : 1
    onConfirmationRequest({ value: newToggleState })
  }

  const { value, previous, valueUnit, caption, errors } = useMemo(() => {
    const errors = getDatapointErrors(datapoints)
    if (!validData?.length) {
      return isPreviewMode
        ? { value: preview.value, valueUnit: preview.unit, caption: preview.caption, errors }
        : { errors }
    }

    const previous = comparatorsData?.data?.datapoints?.length
      ? getDatapointsCalculatedValue(comparatorsData.data.datapoints, {
          method,
          customLogic,
          decimals,
        })
      : null

    const caption =
      Object.keys(captions || {}).length > 0 &&
      (((valueState || valueState === 0) && {
        ...captions[valueState],
        color:
          captions[valueState] &&
          (captions[valueState].color ||
            theme.color[captions[valueState].type] ||
            (captions[valueState].type !== 'none' &&
              [theme.color.error, theme.color.success][valueState])),
      }) || { title: '-' })

    return {
      value: valueState,
      previous,
      valueUnit: unit || validData[0].unit,
      caption,
    }
  }, [
    valueState,
    preview,
    captions,
    decimals,
    isPreviewMode,
    theme.color,
    unit,
    validData,
    comparatorsData,
    customLogic,
    method,
    datapoints,
  ])

  return (
    <Ui
      {...props}
      caption={caption}
      value={value}
      prevValue={previous}
      writeDatapoint={writeDatapoint[0]}
      title={title}
      icon={icon}
      unit={valueUnit}
      showUnit={datapoints.length ? showUnit : true}
      styleProps={{ ...styleProps, variant: type }}
      onClick={handleToggle}
      confirmation={confirmation}
      onConfirmationRequest={onConfirmationRequest}
      loading={{
        loading: loading || comparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default LastValueWidget
