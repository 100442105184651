import React from 'react'

import { Form } from 'antd'
import { injectIntl } from 'react-intl'

import { AntInput } from '../AntDesign'

const InputField = ({
  label,
  name,
  disabled,
  intl,
  required,
  customRules = [],
  type = 'text',
  placeholder,
  step = 1,
  min = 0,
}) => {
  const rules = [...customRules]

  if (required) {
    rules.push({
      required: true,
      whitespace: true,
      message: `${label} ${intl.formatMessage({ id: 'form.input.required' })}`,
    })
  }

  return (
    <Form.Item style={{ width: '100%' }} name={name} label={label} rules={rules}>
      <AntInput type={type} disabled={disabled} placeholder={placeholder} step={step} min={min} />
    </Form.Item>
  )
}

export default injectIntl(InputField)
