import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { injectIntl } from 'react-intl'

import OrganisationReportingDateRange from 'components/Form/components/OrganisationReportDateRange'
import Button from 'components/Global/Button'
import FormField from 'components/Global/FormField'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import { TimePickerStyled, TooltipStyled } from './styled'

dayjs.extend(localizedFormat)

const TimePicker = ({
  screen,
  className,
  value,
  source,
  organisationId,
  isDetailDashboard,
  children,
  onChange,
  onChangeGranularity,
  intl,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const [presetTime, setPresetTime] = useState({ name: value?.preset })
  const { startTime, endTime } = value || {}

  useEffect(() => {
    dayjs.locale({ 'en-US': 'en-in', 'nl-NL': 'nl', 'fr-FR': 'fr' }[intl.locale || 'en-US'])
  }, [intl.locale])

  const displayValue = [startTime && dayjs(startTime), endTime && dayjs(endTime)]

  const formStyle = {
    label: {
      position: 'top',
    },
  }
  const onPresetChange = ({ value }) => {
    onChange({ preset: value.name })
    setPresetTime(value)
    setTooltipIsOpen(true)
  }

  const onDatePickerChange = ({ value }) => {
    if (value.range) {
      onChange({ startTime: value.value[0], endTime: value.value[1] })
      setTooltipIsOpen(true)
    }
  }

  const onReportingYearChange = (value) => {
    const newValue = { startTime: value?.[0], endTime: value?.[1] }
    onChange(newValue)
    onChangeGranularity('MS', 1)
  }

  return (
    <TimePickerStyled className={`TimePicker ${className}`}>
      {source && (
        <span className="TimePicker__source">{intl.formatMessage({ id: `layout.${source}` })}</span>
      )}
      <TooltipStyled
        button={{
          icon: 'fas calendar',
          label: `${startTime ? displayValue[0].format('lll') : 'Now'} - ${
            endTime ? displayValue[1].format('lll') : 'Now'
          }`,
        }}
        variant="TimeSelection"
        preventClickOutside
        onToggleOpen={() => setTooltipIsOpen((state) => !state)}
        isOpen={tooltipIsOpen}
        width={640}
      >
        <FormField
          componentName="DateRangeSelector"
          componentProps={{ isDetailDashboard }}
          label={{
            text: 'preset',
            style: formStyle.label,
          }}
          value={presetTime}
          placeholder="Now"
          onChange={onPresetChange}
        />
        <div className="TimeSelection__field-container">
          {organisationId ? (
            <>
              <FormFieldLabel>
                {intl.formatMessage({ id: 'graphComponent.presetControl.customPeriod' })}
              </FormFieldLabel>
              <OrganisationReportingDateRange
                organisationId={organisationId}
                allowCustomRange
                onChange={onReportingYearChange}
                enableDefaultValue={
                  !displayValue?.defaultStartTime || displayValue.defaultStartTime.preset === 'none'
                }
                presets={false}
              />
            </>
          ) : (
            <FormField
              componentName="DatePicker"
              componentProps={{
                allowClear: true,
                range: true,
                showToday: false,
                showTime: screen !== 's',
                format: 'YYYY-MM-DD HH:mm:ss',
                disabledDate: (current) => current.valueOf() > dayjs().valueOf(),
              }}
              value={displayValue}
              label={{
                formatted: 'graphComponent.endTimeControl.endTime',
                style: formStyle.label,
              }}
              placeholder="Now"
              startTime={startTime}
              onChange={onDatePickerChange}
            />
          )}

          {children}
        </div>
        <div className="TimeSelection__button-container">
          <Button
            variant="smallButton"
            label="Ok"
            isPrimary
            onClick={() => setTooltipIsOpen(false)}
          />
        </div>
      </TooltipStyled>
    </TimePickerStyled>
  )
}

TimePicker.defaultProps = {}

export default injectIntl(TimePicker)
