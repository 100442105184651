import styled from 'styled-components'

import ValuePickerStyled from 'components/Form/components/ValuePicker/styled'

const DatapointSelectorStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};

  .DatapointSelector__switch {
    padding: 1rem 1rem 0;
    margin-bottom: -1rem;
  }

  .DatapointSelector__search {
    position: relative;
    margin-bottom: 0.5rem;

    &:hover {
      > .Icon {
        opacity: 1;
        visibility: visible;
      }
    }

    > .Icon {
      transition: opacity 0.2s ease, visibility 0.2s ease;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 1.4rem;
      height: 1.4rem;
      background: ${({ theme }) => theme.color.softStroke};
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 10;
    }
  }

  .DatapointSelector__add {
    &:hover {
      background: ${({ theme }) => theme.color.success};
    }
  }

  .DatapointSelector__editor {
    display: flex;
    flex: 1;
    max-height: 50vh;
    overflow: hidden;

    > div {
      position: relative;
      padding: 1rem;
      flex: 1;
      max-width: 33.333333%;
      display: flex;
      flex-direction: column;

      > .Tags {
        overflow: auto;
      }

      .OptionPicker {
        padding: 0.5rem 0.5rem 1rem;
      }

      .SearchableTree {
        height: 100%;
        overflow: hidden;
      }

      > label {
        margin-top: 0.5rem;
      }
    }

    ${ValuePickerStyled} {
      margin-bottom: 0.5rem;
    }
  }

  .ValuePicker__button-container {
    display: none;
  }

  .DatapointSelector__buttons {
    display: flex;

    padding: 1rem;
    align-items: center;
    justify-content: center;
  }

  .EntityTree {
    flex: 1;
    overflow: hidden;

    > .Tree,
    .Customer {
      flex: 1;
      overflow: auto;
    }
  }

  .DatapointSelector__scroll-container {
    flex: 1;
    overflow: auto;
    .AugmentedEntity {
      width: auto;
    }
  }

  .DatapointSelector__drawers {
    position: relative;
    flex: 1;
    overflow: hidden;

    > div {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      left: 0;
    }

    .SubTitle {
      padding: 0.5rem 0 1rem;

      > span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        transform: rotate(-45deg);
        border-top: 2px solid ${({ theme }) => theme.color.mainText};
        border-left: 2px solid ${({ theme }) => theme.color.mainText};
        margin: 0 0.5rem 0.1rem;
        cursor: pointer;
      }
    }
  }

  .DatapointSelector__scroll {
    overflow: auto;
  }
`
export default DatapointSelectorStyled
