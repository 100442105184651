import React from 'react'

import BaseLayout from 'components/Layout/BaseLayout'
import UiProvider from 'components/Layout/UiProvider'

const NoLayout = ({ children }) => {
  return (
    <UiProvider>
      <BaseLayout>{children}</BaseLayout>
    </UiProvider>
  )
}

export default NoLayout
