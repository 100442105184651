import React from 'react'

import { FormattedMessage } from 'react-intl'

import { FormFieldLabel } from 'components/Global/FormField/styled'
import TimeOffsetStyled from 'components/Global/FormField/TimeOffset/styled'

import { presets } from '../../../Dashboard/DashboardLayout/presets'
import { dashStyles, graphTypes } from '../../../Dashboard/Widget/config/selections'
import RangeSelector from '../DefaultStartTimeSelector/RangeSelector'
import GranularitySelector from '../GranularitySelector'
import FormField from '../index'

const DashboardLink = ({
  value,
  dataKey,
  hideLabel,
  //subPath,
  onChange,
}) => {
  const handleOnChange = (updateValue, updateDataKey) => {
    return onChange(
      {
        ...value,
        [updateDataKey]: updateValue,
      },
      dataKey
    )
  }

  const handleDashboardLinkChange = ({ dataKey: updateDataKey, meta }) => {
    if (updateDataKey === 'url') {
      const { url, name } = meta
      return onChange({
        ...value,
        url,
        name,
      })
    }
    if (updateDataKey === 'id') {
      const { id, title } = meta
      return onChange({
        splitPage: true,
        amount: 1,
        defaultGranularity: 'h',
        defaultGranularityValue: 1,
        duration: 2,
        durationUnit: 'day',
        unit: 'day',
        ...value,
        url: id,
        name: title,
      })
    }
  }

  return (
    <TimeOffsetStyled className="TimeOffset">
      {!hideLabel && (
        <FormFieldLabel as="h4">
          <FormattedMessage id={'widget.linkedDashboard'} />
        </FormFieldLabel>
      )}
      <FormField
        componentName="ValuePicker"
        componentProps={{
          options: presets,
          selectionKeys: {
            label: 'title',
            value: 'id',
          },
          allowClear: true,
        }}
        dataKey="id"
        value={value?.url}
        onChange={handleDashboardLinkChange}
      />
      {/*<FormField
        componentName="LinkPicker"
        componentProps={{
          subPath,
        }}
        value={value}
        onChange={handleDashboardLinkChange}
      />*/}
      <FormFieldLabel as="h4">
        <FormattedMessage id={'widget.extraDatapoints'} />
      </FormFieldLabel>
      <FormField
        dataKey="linkDatapoints"
        onChange={({ value, dataKey }) => onChange(value, dataKey)}
        componentName="Datapoints"
        componentProps={{
          dataKey: 'linkDatapoints',
          linkMode: true,
          section: {
            name: 'linkDatapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: graphTypes,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.style' },
                  dataKey: 'dashStyle',
                  componentName: 'ValuePicker',
                  componentProps: {
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                    options: dashStyles,
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                    addRangeMethod: true,
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapoints',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
        }}
      />
      {/*<FormField
        label={{ formatted: 'widget.splitPage' }}
        componentName="Switch"
        dataKey="splitPage"
        onChange={({ value, dataKey }) => handleOnChange(value, dataKey)}
        value={value?.splitPage}
      />*/}
      <RangeSelector
        onBlur={handleOnChange}
        onChange={handleOnChange}
        dataKey="dashboardLink"
        value={{ dashboardLink: value }}
      />
      <FormFieldLabel>
        <FormattedMessage id={'widget.granularity'} />
      </FormFieldLabel>
      <GranularitySelector onChange={handleOnChange} value={value} defaultStartTime={value} />
    </TimeOffsetStyled>
  )
}

export default DashboardLink
