import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const MenuListStyled = styled(motion.div)`
  ${({ level }) =>
    level === 0
      ? css`
          margin: 0 1rem;

          &.dashboard-menu {
            padding-bottom: 5rem;
          }

          &.settings-menu {
            border-radius: 1rem;

            > .MenuListItem {
              > .Button {
                box-shadow: none !important;
                margin-bottom: 0.5rem;

                .Button__label {
                  font-size: 1.2rem;
                }
              }
            }
          }
        `
      : css`
          padding-left: 2rem;
        `}
  ${({ level }) =>
    level === 1 &&
    css`
      //box-shadow: 0px -10px 20px rgba(100, 100, 100, 0.1) inset;
    `}
`
export default MenuListStyled
