import React from 'react'

import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import { useCountryList } from 'services/companies'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'
import CountrySelectFieldStyled from 'components/Form/fields/CountrySelectField/styled'
import Icon from 'components/Global/Icon'

const CountrySelectField = ({
  children,
  name,
  defaultValue,
  value = true,
  checked,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  allowClear,
  label,
  fullWidth,
  addAllOption = false,
  showRequired,
  ...props
}) => {
  const filterCountryFunction = (input, option) => {
    return option.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const { data, loading: optionsLoading } = useCountryList(addAllOption)

  return (
    <CountrySelectFieldStyled
      {...props}
      label={label}
      required={showRequired && validation?.required}
    >
      {({ control, errors, isEditMode, loading }) => {
        const error = get(errors, name)
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              const currentCountry = data?.countries.filter(
                (country) =>
                  country.id ===
                  (formFieldValue?.id ? formFieldValue?.id.toString() : formFieldValue?.toString())
                /* typeof formFieldValue === 'number'
                    ? formFieldValue.toString()
                    : formFieldValue?.id
                    ? formFieldValue?.id.toString()
                    : formFieldValue.toString() */
              )

              if (loading || optionsLoading) {
                return <Skeleton width="200px" />
              }
              const selectedValue = currentCountry?.length ? currentCountry[0] : formFieldValue
              if (isEditMode) {
                return (
                  <>
                    <ValuePicker
                      id={selectedValue?.id}
                      value={selectedValue?.id}
                      valueElement={
                        selectedValue && (
                          <Icon
                            className="ValuePicker__icon-value"
                            icon={`/resources/images/countryflags/${selectedValue.alpha2?.toLowerCase()}.png`}
                          />
                        )
                      }
                      options={data?.countries}
                      selectionKeys={{
                        value: 'id',
                        label: 'name',
                        imageUrlPrefix: '/resources/images/countryflags/',
                        image: 'alpha2',
                        imageUrlSuffix: '.png',
                      }}
                      getItemValue={({ id, name }) => ({ id, name })}
                      onChange={(value, item) => {
                        const val = item ? { id: item.id, name: item.name } : undefined

                        fromFieldOnChange(val)
                        if (typeof onChange === 'function') {
                          onChange(val)
                        }
                      }}
                      filterOption={filterCountryFunction}
                      search
                      error={error}
                      allowClear={allowClear}
                      fullWidth={fullWidth}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              return (
                <span>
                  <Icon
                    className="ValuePicker__icon-value"
                    icon={`/resources/images/countryflags/${selectedValue?.alpha2?.toLowerCase()}.png`}
                  />{' '}
                  {selectedValue?.name}
                </span>
              )
            }}
          />
        )
      }}
    </CountrySelectFieldStyled>
  )
}

export default CountrySelectField
