import React, { forwardRef } from 'react'

import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { sortableHandle } from 'react-sortable-hoc'

import ButtonStyled from 'components/Global/Button/styled'
import Icon from 'components/Global/Icon'

import OptionButton from '../OptionButton'
import Button from './../Button'
import ButtonListStyled from './styled'

const DragHandle = sortableHandle(() => (
  <ButtonStyled data-cy="ButtonList-DragHandle">
    <Icon icon="lnr lnr-move" />
  </ButtonStyled>
))
const variants = {
  enter: {
    opacity: 0,
    visibility: 'hidden',
    scale: 0,
  },
  active: {
    opacity: 1,
    visibility: 'visible',
    scale: 1,
  },
  exit: {
    opacity: 0,
    visibility: 'hidden',
    scale: 0,
  },
}

const ButtonList = forwardRef(
  ({ buttons, show, styleProps, onButtonClick, className, intl }, ref) => {
    const handleButtonClick = (e, payload) => {
      e.stopPropagation()
      onButtonClick(e, payload)
    }
    return (
      <AnimatePresence>
        {show && (
          <ButtonListStyled
            {...styleProps}
            className={`ButtonList ${className}`}
            variants={variants}
            initial="enter"
            exit="exit"
            animate="active"
            ref={ref}
          >
            {buttons.map((button, index) =>
              button.buttons ? (
                <OptionButton {...button} key={`button-${index}`} onClick={handleButtonClick} />
              ) : button.sortableHandler ? (
                <DragHandle />
              ) : (
                <Button
                  {...button}
                  key={`button-${index}`}
                  label={button.label}
                  onClick={handleButtonClick}
                />
              )
            )}
          </ButtonListStyled>
        )}
      </AnimatePresence>
    )
  }
)

ButtonList.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  show: PropTypes.bool,
  styleProps: PropTypes.object,
  onButtonClick: PropTypes.func,
}

ButtonList.displayName = 'ButtonList'

ButtonList.defaultProps = {}

export default ButtonList
