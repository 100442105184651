import React from 'react'

import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import Icon from 'components/Global/Icon'
import Spinner from 'components/Global/Spinner'
import { ContentLoaderStyled } from 'components/Layout/Content/styled'

const ContentLoader = ({
  className,
  intl,
  children,
  hideIcon,
  hideSpinner,
  icon = 'fas fa-database',
  hideText,
  size = 'm',
}) => {
  const theme = useTheme()
  const spinnerSize = {
    s: 10,
    m: 30,
    l: 60,
    xl: 90,
  }[size]
  return (
    <ContentLoaderStyled
      className={`Loader ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      fullscreen
      size={size}
      logoImage={icon === 'logo'}
    >
      {children ?? (
        <>
          {!hideSpinner && <Spinner type="lineScale" size={spinnerSize} />}
          {!hideText && <span>{intl.formatMessage({ id: 'settings.loading' })}</span>}
          {!hideIcon && <Icon icon={icon === 'logo' ? theme.images.favicon : icon} />}
        </>
      )}
    </ContentLoaderStyled>
  )
}

export default injectIntl(ContentLoader)
