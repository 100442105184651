import React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Dropdown } from 'antd'

import {
  LANGUAGE_QUERY,
  ME_QUERY,
  UPDATE_ACCOUNT_MUTATION,
  useLocaleHook,
} from '../../../services/user'
import useErrorHandler from '../../../util/useErrorHandler'
import { DropdownItem, Option } from './styled'

const LanguageSelector = () => {
  const { data, error } = useQuery(ME_QUERY)
  const [updateAccount, { error: updateError }] = useMutation(UPDATE_ACCOUNT_MUTATION, {
    refetchQueries: [
      {
        query: ME_QUERY,
      },
      {
        query: LANGUAGE_QUERY,
      },
    ],
  })
  const [errorHandler] = useErrorHandler()
  const locale = useLocaleHook()
  if (error || updateError) {
    errorHandler(error || updateError)
    console.error(error)
  }

  if (!data) {
    return null
  }

  const changeLang = async ({ key }) => {
    await updateAccount({
      variables: {
        data: {
          id: Number(data.me.id),
          locale: key,
        },
      },
    })
  }

  const language = locale.substr(0, 2)

  const menu = [
    {
      key: 'en-US',
      onClick: () => changeLang({ key: 'en-US' }),
      label: (
        <Option>
          <span role="img" aria-label="English" className="mr-2">
            🇬🇧
          </span>
          English
        </Option>
      ),
    },
    {
      key: 'nl-NL',
      onClick: () => changeLang({ key: 'nl-NL' }),
      label: (
        <Option>
          <span role="img" aria-label="Dutch" className="mr-2">
            🇳🇱
          </span>
          Nederlands
        </Option>
      ),
    },
    {
      key: 'fr-FR',
      onClick: () => changeLang({ key: 'fr-FR' }),
      label: (
        <Option>
          <span role="img" aria-label="French" className="mr-2">
            🇫🇷
          </span>
          Français
        </Option>
      ),
    },
  ]

  return (
    <Dropdown menu={{ items: menu }} trigger={['click']}>
      <DropdownItem>
        <strong className="text-uppercase">{language}</strong>
      </DropdownItem>
    </Dropdown>
  )
}

export default LanguageSelector
