import React from 'react'

import { useAllowedCompanies } from 'services/customer'

import ValuePicker from 'components/Form/components/ValuePicker'

const CustomerPicker = ({ value, onChange }) => {
  let { data: allowedCompanies } = useAllowedCompanies()

  const handleValueUpdate = (value) => {
    onChange(value)
  }

  return (
    <ValuePicker
      options={allowedCompanies}
      value={value}
      selectionKeys={{
        value: 'entity.id',
        label: 'name',
      }}
      allowClear
      onChange={handleValueUpdate}
    />
  )
}

export default CustomerPicker
