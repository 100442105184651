import { lazy } from 'react'

const DatapointConfigurator = lazy(() =>
  import('components/SettingsComponents/DatapointConfigurator')
)
const EntityTypeManager = lazy(() => import('components/SettingsComponents/EntityTypeManager'))
const EditUserModal = lazy(() => import('components/Modals/Settings/EditUserModal'))
const EditCompanyModal = lazy(() => import('components/Modals/Settings/EditCompanyModal'))
const RoleModal = lazy(() => import('components/SettingsComponents/RoleModal'))
const PermissionsModal = lazy(() => import('components/SettingsComponents/PermissionsModal'))
const EditCustomerModal = lazy(() => import('components/Modals/Settings/EditCustomerModal'))
const NewCustomerModal = lazy(() => import('components/Modals/Settings/NewCustomerModal'))
const ModulesCustomerModal = lazy(() => import('components/Modals/Settings/ModulesCustomerModal'))

const TermsOfUse = lazy(() => import('components/Modals/TermsOfUse'))

const SupportModal = lazy(() => import('../components/Modals/Support/SupportModal'))
const ContactModal = lazy(() => import('../components/Modals/Support/ContactModal'))

const ConfidentialInformation = lazy(() => import('../components/Modals/confidentialinformation'))
const TermsAndConditions = lazy(() => import('../components/Modals/TermsAndConditions'))

export default [
  {
    path: '/settings/datapoints/configurator',
    component: DatapointConfigurator,
  },
  {
    path: '/settings/entity-type-manager',
    component: EntityTypeManager,
  },
  {
    path: '/settings/users/edit',
    component: EditUserModal,
  },
  {
    path: '/settings/company/edit',
    component: EditCompanyModal,
  },
  {
    path: '/settings/roles/new',
    component: RoleModal,
    exact: true,
  },
  {
    path: '/settings/roles/:roleId/permission/new',
    component: PermissionsModal,
    exact: true,
  },
  {
    path: '/user/login/cookies',
    component: TermsOfUse,
  },
  {
    path: '/user/login/support',
    component: SupportModal,
  },
  {
    path: '/user/login/contact',
    component: ContactModal,
  },
  {
    path: '/user/login/confidential-information',
    component: ConfidentialInformation,
  },
  {
    path: '/user/login/terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/settings/roles/:roleId/permission/:permissionId',
    component: PermissionsModal,
  },
  {
    path: '/settings/roles/:roleId',
    component: RoleModal,
  },
  {
    path: '/settings/customers/edit',
    component: EditCustomerModal,
  },
  {
    path: '/settings/customers/new',
    component: NewCustomerModal,
    exact: true,
  },
  {
    path: '/settings/customers/modules',
    component: ModulesCustomerModal,
    exact: true,
  },
]
