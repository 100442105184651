import React from 'react'

import RatioBar from 'components/Form/components/RatioBar/RatioBar'

import { RatioBarFieldStyled } from './styled'

const RatioBarField = ({
  value,
  randomColors,
  strokeColor,
  showValue,
  showPercentage,
  size = 'small',
  name,
  label,
  labelKey,
  validation,
  viewRender,
  editRender,
  disabled,
  rows,
  errorMessage,
  ...fieldProps
}) => {
  return (
    <RatioBarFieldStyled {...fieldProps} label={label} size={size}>
      {({ watch }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        return (
          <RatioBar
            size={size}
            segments={fieldValue}
            randomColors={randomColors}
            showValue={showValue}
            showPercentage={showPercentage}
            labelKey={labelKey}
            errorMessage={errorMessage}
          />
        )
      }}
    </RatioBarFieldStyled>
  )
}

export default RatioBarField
