import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  padding-top: 20vh;
  background-color: white;
  background-color: rgb(0 0 0 / 30%);
`
