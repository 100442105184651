import { useCallback, useMemo } from 'react'

import { useHasFixedPermissionAccess } from 'services/fixedpermissions'
import { useHasLicense } from 'services/security/license'
import { useHasModule } from 'services/security/modules'

const useSustainabilityMenu = (url) => {
  const [hasLicense, { loading: licenseLoading }] = useHasLicense()
  const [hasModule, { loading: moduleLoading }] = useHasModule()
  const [hasFixedPermission, { loading: fixedPermissionLoading }] = useHasFixedPermissionAccess()

  const allowed = hasLicense('medium') || hasLicense('enterprise')

  const hasSustFixedPerm = hasFixedPermission([
    'SUSTAINABILITY_VISITOR',
    'SUSTAINABILITY_CONTRIBUTOR',
    'SUSTAINABILITY_ADMINISTRATOR',
  ])

  const isModuleAllowed = useCallback(
    (key) => {
      return allowed && hasModule(key)
    },
    [allowed, hasModule]
  )

  const menuItems = useMemo(() => {
    const items = [
      {
        title: 'sustainability.menu.configuration',
        key: 'config-modules',
        selectable: false,
        url: '',
        disabled: true,
        children: [
          {
            title: 'sustainability.menu.config.research',
            key: 'config.research',
            url: `${url}/config/research`,
            disabled: true,
          },
          {
            title: 'sustainability.menu.config.configuration-assistant',
            key: 'config.configuration-assistant',
            url: `${url}/config/configuration-assistant`,
            disabled: true,
          },
          {
            title: 'sustainability.menu.config.data-survey',
            key: 'config.data-survey',
            url: `${url}/config/data-survey`,
            disabled: true,
          },
          {
            title: 'sustainability.menu.config.validation',
            key: 'config.validation',
            url: `${url}/config/validation`,
            disabled: true,
          },
        ],
      },
      {
        title: 'sustainability.menu.modules',
        key: 'sustainability-modules',
        selectable: false,
        url: `${url}`,
        disabled: !allowed,
        children: [
          {
            title: 'sustainability.menu.modules.csrd',
            key: 'modules.csrd',
            url: `${url}/module/csrd`,
            disabled: !isModuleAllowed('csrd') || !hasSustFixedPerm,
          },
          {
            title: 'sustainability.menu.modules.esg',
            key: 'modules.esg',
            url: `${url}/module/esg`,
            disabled: !isModuleAllowed('esg') || !hasSustFixedPerm,
          },
          {
            title: 'sustainability.menu.modules.ghg',
            key: 'modules.ghg',
            url: `${url}/module/greenhouse-gas-protocol`,
            disabled: !isModuleAllowed('ghg') || !hasSustFixedPerm,
          },
          {
            title: 'sustainability.menu.modules.gri',
            key: 'modules.gri',
            url: `${url}/module/gri`,
            disabled: !isModuleAllowed('gri') || !hasSustFixedPerm,
          },
          {
            title: 'sustainability.menu.modules.sbti',
            key: 'modules.sbti',
            url: `${url}/module/sbti`,
            disabled: !isModuleAllowed('sbti') || !hasSustFixedPerm,
          },
          {
            title: 'sustainability.menu.modules.sdg',
            key: 'modules.sdg',
            url: `${url}/module/sdg`,
            disabled: !isModuleAllowed('sdg') || !hasSustFixedPerm,
          },
        ],
      },
    ]

    return items
  }, [isModuleAllowed, allowed, url, hasSustFixedPerm])

  return [menuItems, { loading: licenseLoading || moduleLoading || fixedPermissionLoading }]
}

export default useSustainabilityMenu
