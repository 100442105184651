import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import HCMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  getComparatorValue,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useComparatorsPayload, useDatapointsForTimeRangePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks'
import { getDatapointErrors } from 'components/Widgets/functions'
import { CircleGaugeUi } from 'components/Widgets/Gauge/CircleGauge/styled'

import { chartOptions, getSerie } from '../chartOptions'

HCMore(Highcharts)
SolidGauge(Highcharts)

const EfficiencyGauge = ({
  datapoints = [],
  value,
  comparators,
  timeRange,
  comparisonPeriod,
  conversionUnit,
  decimals = 2,
  method,
  comparatorsMethod,
  showDelta = false,
  dataProps = {},
  preview = { gauge: { series: [] } },
  newSocketValue,
  customLogic,
  icon,
  color,
  trackColor,
  intl,
  className,
  defaultCalculationMethod,
  comparatorsCalculationMethod,
  ...props
}) => {
  const theme = useTheme()
  const localizeNumber = useLocaleNumber()
  const { isPreviewMode, isEditMode } = dataProps

  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'efficiencyGauge',
    },
    deps: [datapointsPayload],
    skip: !datapoints?.length,
  })

  const { values, comparatorDatapoints } = useComparatorsPayload({
    datapoints,
    comparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: comparatorsData, loading: comparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: comparatorDatapoints,
      widgetType: 'efficiencyGaugeComparator',
    },
    deps: [comparatorDatapoints],
    skip: comparatorDatapoints.length === 0,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data?.data?.datapoints

  const roundedMaximum = getComparatorValue({
    comparators,
    comparatorValues: values,
    comparatorsData,
    method: comparatorsMethod,
    decimals,
    calculationMethod: comparatorsCalculationMethod,
    defaultValue: 100,
  })

  const { series, datapointChartProps, errors } = useMemo(() => {
    const errors = getDatapointErrors(datapoints)
    if (!validData?.length) {
      return isPreviewMode
        ? { series: error ? [] : preview.gauge.series, datapointChartProps: {}, errors }
        : { datapointChartProps: {}, errors }
    }

    const value = getDatapointsCalculatedValue(validData, {
      method,
      customLogic,
      decimals,
      calculationMethod: defaultCalculationMethod,
    })

    const percentageValue = (value / roundedMaximum) * 100
    const percentage = 100 - percentageValue

    const serieColor = color || theme.color.main

    const series = [
      getSerie(
        {
          value,
          color: serieColor,
          showInvertedValues: true,
          showPercentage: true,
          percentage,
          icon: 'fas fa-leaf',
          unit: validData[0].conversionFactor?.endUnit.name || validData[0].unit,
          max: roundedMaximum,
          caption: intl.formatMessage({ id: 'widget.energySaving' }),
        },
        localizeNumber
      ),
    ]
    const datapointChartProps = {
      yAxis: {
        max: roundedMaximum,
      },
    }
    return { series, datapointChartProps, errors }
  }, [
    localizeNumber,
    validData,
    roundedMaximum,
    color,
    customLogic,
    decimals,
    preview.gauge.series,
    theme,
    isPreviewMode,
    method,
    datapoints,
    error,
    intl,
    defaultCalculationMethod,
  ])

  const globalChartOptions = chartOptions({
    series,
    color: color || theme.color.main,
    caption: intl.formatMessage({ id: 'widget.energySaving' }),
    trackColor,
  })

  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <CircleGaugeUi
      {...props}
      className={`${className} EfficiencyGauge`}
      icon={icon}
      options={options}
      loading={{
        loading: loading || comparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(EfficiencyGauge)
