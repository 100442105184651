import { motion } from 'framer-motion'
import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { FieldStyled, Label, Value } from 'components/Form/components/Field/styled'
import { FieldGroupTitle } from 'components/Form/components/FieldGroup/styled'
import TextAreaFieldStyled from 'components/Form/fields/TextAreaField/styled'
import IconStyled from 'components/Global/Icon/styled'

export const UploadAndRemarksFieldStyled = styled(Field)`
  display: block;
  margin: 0 -1rem;
`

export const UploadAndRemarksFieldHeader = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  padding-right: 1rem;

  ${FieldGroupTitle} {
    margin: 0;
    border-bottom: none;
  }

  &:hover,
  &.active {
    ${IconStyled} {
      color: ${({ theme }) => theme.color.main};
    }
  }

  ${IconStyled} {
    transition: color 0.2s ease;
    font-size: 1.2rem;
  }
`

export const UploadAndRemarksFieldContent = styled(motion.div)`
  min-height: fit-content;
  overflow: hidden;

  ${FieldStyled} {
    flex-direction: column;
  }

  ${Label} {
    font-size: ${({ large }) => large && '1.2rem'};
  }

  ${TextAreaFieldStyled} {
    ${Value} {
      min-height: 8rem;
      display: block;
    }
  }
`
