import React from 'react'

import { Tooltip } from 'antd'
import { injectIntl } from 'react-intl'

import Icon from 'components/Global/Icon'

export const Title = ({ icon, title, localisedLabels, tooltip, intl }) => {
  const formattedTitle = localisedLabels ? intl.formatMessage({ id: title }) : title
  const titleComp = <span className="Button__label">{formattedTitle}</span>

  return (
    <>
      {icon && <Icon icon={icon} />}
      {tooltip ? (
        <Tooltip title={formattedTitle} mouseEnterDelay={0.8}>
          {titleComp}
        </Tooltip>
      ) : (
        titleComp
      )}
    </>
  )
}

export default injectIntl(Title)
