import React from 'react'

import get from 'lodash/get'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import { calculationMethods } from 'components/Dashboard/Widget/config/selections'
import FormField from 'components/Global/FormField/index'
import ValueTypeSelectorStyled from 'components/Global/FormField/ValueTypeSelector/styled'

const ValueTypeSelector = ({
  value,
  dataKey,
  subPath,
  datapointsDataKey = 'datapoints',
  onChange,
}) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()
  const datapointArray = get(
    elementInEditMode.preview,
    `${subPath ? `${subPath}.` : ''}${datapointsDataKey}`
  )
  const difference = datapointArray?.[0]?.difference
  return difference && (!datapointArray || datapointArray?.length < 2) ? null : (
    <ValueTypeSelectorStyled>
      <FormField
        componentName="OptionPicker"
        componentProps={{
          selectionKeys: { label: 'label', value: 'value' },
          options:
            elementInEditMode.preview[datapointsDataKey]?.length > 1
              ? calculationMethods.filter(({ value }) => value !== 'range' && value !== 'amount')
              : [
                  { value: 'none', label: { formatted: 'widget.last' } },
                  { value: 'mean', label: { formatted: 'widget.mean' } },
                  { value: 'min', label: { formatted: 'widget.min' } },
                  { value: 'max', label: { formatted: 'widget.max' } },
                ],
        }}
        value={value}
        dataKey={dataKey}
        label={{ formatted: 'widget.calculationMethod' }}
        onChange={({ value }) => onChange({ value })}
      />
    </ValueTypeSelectorStyled>
  )
}

export default ValueTypeSelector
