import React, { useMemo } from 'react'

import { sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { useAllFixedFileRecords } from 'services/fixedFiles'
import { useCurrentCustomer } from 'services/store'

import SelectField from '../../SelectField'

const FixedFileSelectField = ({
  tableId,
  customerModus,
  valueField = 'id',
  labelField = 'label',
  ...props
}) => {
  const customer = useCurrentCustomer()

  const filterRecords = (records, filter) => {
    let res = records
    for (const [key, value] of Object.entries(filter)) {
      res = res.filter((item) => item.fields[key] === value)
    }
    return res
  }

  const { data } = useAllFixedFileRecords(tableId, customerModus, customer?.id, !customer)

  const filteredRecords =
    props.filter && data?.records ? filterRecords(data?.records, props.filter) : data?.records

  const options = useMemo(() => {
    const records = filteredRecords?.map(({ fields }) => ({
      value: fields[valueField],
      label: fields[labelField],
    }))

    return records ? sortBy(records, 'label') : records
  }, [valueField, labelField, filteredRecords])

  return <SelectField {...props} options={options} />
}

FixedFileSelectField.propTypes = {
  tableId: PropTypes.number.isRequired,
  customerModus: PropTypes.bool,
}

export default FixedFileSelectField
