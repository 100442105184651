import React from 'react'

import { AnimatePresence } from 'framer-motion'
import { FormattedMessage, injectIntl } from 'react-intl'

import ActionConfirmationValue from 'components/Dashboard/components/ActionConfirmation/ActionConfirmationValue'
import Button from 'components/Global/Button'

import ActionConfirmationStyled from './styled'

export const ActionConfirmation = ({
  value,
  id,
  unit,
  children,
  isActive,
  onConfirmationRequest,
  confirmationFunction,
  location,
  offset = true,
  message = 'widget.areYouSure',
  status,
  intl,
}) => {
  const handleCancel = () => {
    onConfirmationRequest({ value, isCanceled: true })
  }

  const handleConfirm = async () => {
    if (confirmationFunction) {
      await confirmationFunction({
        id,
        value,
      })
    }
    onConfirmationRequest({ value, isConfirmed: true })
  }

  return (
    <AnimatePresence>
      {isActive && (
        <ActionConfirmationStyled
          className="ActionConfirmation"
          location={location}
          offset={offset}
          initial={{
            opacity: 0,
            scale: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          exit={{
            opacity: 0,
            scale: 0,
          }}
          status={status}
        >
          <p>
            <FormattedMessage id={message} />
          </p>
          {children || <ActionConfirmationValue value={value} unit={unit} />}
          <div className="ActionConfirmation__container">
            <Button
              onClick={handleCancel}
              label={intl.formatMessage({ id: 'widget.cancel' })}
              variant="smallButton"
            />
            <Button onClick={handleConfirm} label="Ok" variant="smallButton" isPrimary />
          </div>
        </ActionConfirmationStyled>
      )}
    </AnimatePresence>
  )
}

export default injectIntl(ActionConfirmation)
