import { useMemo } from 'react'

import dayjs from 'dayjs'

import { DATETIME_FORMAT_DATE } from 'util/constants'

const getLabel = ({ isDraft, isCurrent, dayjsDate }) => {
  if (isDraft) {
    return 'Draft'
  }

  const formattedDate = dayjsDate.format(DATETIME_FORMAT_DATE)

  return `${
    formattedDate === '01/01/1970' || formattedDate === '01-01-1970' ? 'Initial' : formattedDate
  }${isCurrent ? ' - Current' : ''}`
}

export const useStateOptions = (options, { addCurrentOption = true } = {}) => {
  return useMemo(() => {
    if (!options) {
      return undefined
    }

    const stateOptions =
      options?.map((option) => {
        const dayjsDate = dayjs(option.date)

        return {
          ...option,
          label: getLabel({ ...option, dayjsDate }),
          dayjs: dayjsDate,
        }
      }) || []

    const allOptions = addCurrentOption
      ? [{ value: 'current', label: 'Current', dayjs: dayjs() }, ...stateOptions]
      : stateOptions

    return allOptions.sort((a, b) => {
      if (a.dayjs.isSame(b.startDate)) {
        return 0
      }

      return a.dayjs > b.dayjs ? 1 : -1
    })
  }, [options, addCurrentOption])
}
