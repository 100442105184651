import React, { useRef } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'

import DatapointTags from 'components/Dashboard/components/Tags/DatapointTags'
import OptionPicker from 'components/Form/components/OptionPicker/OptionPicker'
import Button from 'components/Global/Button'
import Component from 'components/Global/Component/styled'
import DatapointSelectorStyled from 'components/Global/FormField/DatapointSelector/styled'
import { cat3Config } from 'components/Global/FormField/GhgDataSelector/config'
import { FormFieldLabel } from 'components/Global/FormField/styled'
import Icon from 'components/Global/Icon'
import { SubTitle } from 'components/Layout/Content'

const GhgDataSelector = ({
  singleDatapoint,
  onSelectScope,
  selectors,
  onSelectDatapoints,
  onSelectEntity,
  onSelectTags,
  resetTags,
  onSearch,
  searchValue,
  datapoints,
  selectedEntity,
  filteredDatapoints,
  onDatapointHover,
  allDatapointsSelected,
  onDrawerSave,
  onDrawerCancel,
  selectedDatapoints,
  organisationId,
  treeComponent,
  subDrawer,
  onSubDrawerChange,
  canReselect = true,
  intl,
  height,
}) => {
  const openItems = useRef()
  const onSubDrawerSelectEntity = (payload) => {
    onSelectEntity(payload, { ...cat3Config[subDrawer.cat3.id], name: subDrawer.cat3.name })
  }

  const handleTreeOpenClick = (item, path, treeOpenItems) => {
    openItems.current = treeOpenItems
  }
  return (
    <DatapointSelectorStyled className="DatapointSelector" height={height}>
      <div className="DatapointSelector__editor">
        <div>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.entities' })}</FormFieldLabel>
          <div className="DatapointSelector__drawers">
            <AnimatePresence>
              {subDrawer ? (
                <motion.div
                  className="DatapointSelector__scroll"
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '100%' }}
                  key="main"
                  transition={{ type: 'spring', stiffness: 250, damping: 50 }}
                >
                  <SubTitle className="SubTitle">
                    <span onClick={() => onSubDrawerChange(null)} />
                    {subDrawer.cat3.name}
                  </SubTitle>
                  <Component
                    as={cat3Config[subDrawer.cat3.id]?.treeComponent}
                    onChange={onSubDrawerSelectEntity}
                    onSelectScope={onSelectScope}
                    selectedOrganisation={organisationId}
                    selectedEntity={selectedEntity}
                  />
                </motion.div>
              ) : (
                <motion.div
                  className="DatapointSelector__scroll"
                  initial={{ x: '-100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '-100%' }}
                  key="sub"
                  transition={{ type: 'spring', stiffness: 250, damping: 50 }}
                >
                  {selectors}
                  <Component
                    as={treeComponent}
                    onChange={onSelectEntity}
                    onSelectScope={onSelectScope}
                    selectedOrganisation={organisationId}
                    selectedEntity={selectedEntity}
                    onTreeOpenClick={handleTreeOpenClick}
                    openItems={openItems.current}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.tagsAndMarkers' })}</FormFieldLabel>
          <DatapointTags
            fromEntityTypes={['equip', 'point']}
            node={selectedEntity}
            onChange={(payload) => onSelectTags(payload, selectedEntity)}
            datapoints={filteredDatapoints}
            clearChecker={selectedEntity?.key}
            resetTags={resetTags}
          />
        </div>
        <div>
          <FormFieldLabel>
            {intl.formatMessage({ id: singleDatapoint ? 'widget.datapoint' : 'widget.data' })} (
            {datapoints?.length})
          </FormFieldLabel>
          <div className="DatapointSelector__search">
            <Input
              onChange={onSearch}
              value={searchValue}
              prefix={<SearchOutlined />}
              placeholder="Search to Select"
            />
            {!singleDatapoint && !allDatapointsSelected && (
              <Icon
                icon={'fas fa-check-double'}
                variant="clearIcon"
                className={'DatapointSelector__add'}
                onClick={() => onSelectDatapoints(filteredDatapoints)}
              />
            )}
          </div>
          <OptionPicker
            options={filteredDatapoints}
            value={singleDatapoint ? selectedDatapoints[0] : selectedDatapoints}
            selectionKeys={{ label: 'name', id: 'id' }}
            isMultiSelect={!singleDatapoint}
            returnAsArray={singleDatapoint}
            variant="Tags"
            onChange={onSelectDatapoints}
            onHover={onDatapointHover}
            canReselect={canReselect}
          />
        </div>
      </div>
      <div className="DatapointSelector__buttons">
        <Button
          label={intl.formatMessage({ id: 'widget.cancel' })}
          variant="mainButton"
          onClick={onDrawerCancel}
        />
        <Button
          label={intl.formatMessage({ id: 'widget.ok' })}
          variant="mainButton"
          isPrimary
          onClick={onDrawerSave}
        />
      </div>
    </DatapointSelectorStyled>
  )
}

export default GhgDataSelector
