import styled from 'styled-components'

const DatapointsStyled = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  .Datapoints__buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .smallButton {
    margin: 0.5rem 0.5rem 0 0;
  }
`
export default DatapointsStyled
