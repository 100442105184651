import React, { useCallback, useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { coreConfig } from '../../../../services/store'
import SearchField from '../SearchField/SearchField'
import LocationSearchStyled from './styled'

const LocationSearch = ({ onChange, value, className, intl }) => {
  const coreconfig = useMemo(() => coreConfig(), [])

  const handleOnSearch = useCallback(
    (data) => {
      const request = require('request')
      const options = {
        method: 'GET',
        url: `${coreconfig.url}openweather/geo?q=${data}`,
        //url: `https://api.openweathermap.org:443/geo/1.0/direct?q=${data}&appid=3c07d5d80ae7a91bdf95f0a34d43f116`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + coreconfig.token,
        },
      }

      request(options, function (error, response) {
        if (response.body !== '[]') {
          const resp = JSON.parse(response.body)

          if (resp.error) {
            return
          }
          const { lat, lon } = resp.geo[0]
          onChange(`${lat},${lon}`)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coreconfig]
  )

  const handleOnChange = useCallback(
    ({ value }) => {
      handleOnSearch(value)
    },
    [handleOnSearch]
  )
  return (
    <LocationSearchStyled className={`${className} LocationSearch`}>
      <p>{value}</p>
      <SearchField
        onSearch={handleOnChange}
        placeholder={intl.formatMessage({ id: 'widget.searchCity' })}
      />
    </LocationSearchStyled>
  )
}

export default injectIntl(LocationSearch)
