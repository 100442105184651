import styled, { css } from 'styled-components'

const IconCellStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.strongText};
  font-weight: ${({ bold }) => (bold ? 600 : 'inherit')};
  position: relative;

  a,
  span {
    display: flex;
    align-items: center;
  }

  .Icon {
    img {
      height: 50%;
      max-height: 1.5rem;
    }
  }

  .IconCell__icon {
    margin-right: 1rem;
    color: ${({ theme }) => theme.color.softText};
    font-size: 1.2rem;
    padding: 0 0.3rem;
  }

  .Icon.IconCell__action {
    transition: opacity 0.2s ease, padding 0.2s ease;
    color: ${({ theme }) => theme.color.softText};
    opacity: 0;

    &:hover {
      color: ${({ theme }) => theme.color.action};
    }
  }

  .IconCell__add {
    display: block;
  }

  .IconCell__name {
    margin-right: 1rem;
  }

  .Loader {
    position: inherit;
  }
`

export const IconCellDropdownStyled = styled.div`
  margin: auto;
  ${({ variant }) =>
    variant === 'iconCellMore' &&
    css`
      font-size: 1rem;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    `}
`

export default IconCellStyled
