export const getQueryPayload = (
  scope,
  hierarchy,
  { extraFilterTags = {}, extraNotFilterTags = {}, refrigerantType }
) => {
  switch (scope.id) {
    case 1:
    case 2:
      // Stationary combustion or Purchased energy
      if (hierarchy) {
        const refKey = ['siteRef', 'buildingRef', 'floorRef', 'roomRef'][hierarchy.length - 1]
        const meterKey = ['siteMeter', 'buildingMeter', 'floorMeter', 'subMeter'][
          hierarchy.length - 1
        ]

        return {
          meterInfo: {
            meterInfo: JSON.stringify({
              [refKey]: hierarchy[hierarchy.length - 1],
              [meterKey]: '1',
              ...extraFilterTags,
            }),
          },
        }
      }
      return
    case 4:
      if (hierarchy) {
        const refKey = ['siteRef', 'buildingRef', 'floorRef', 'roomRef'][hierarchy.length - 1]

        return {
          tagFilter: JSON.stringify({
            [refKey]: hierarchy[hierarchy.length - 1],
            ...extraFilterTags,
          }),
          extraNotFilterTags:
            Object.keys(extraNotFilterTags).length > 0
              ? JSON.stringify(extraNotFilterTags)
              : undefined,
          type: refrigerantType && (refrigerantType === 'inventory' ? 'Inventory' : 'Equipment'),
        }
      }
      return
    default:
  }
}
