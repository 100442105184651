import { darken, transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'

import AntStyling from './styled.ant'

const GlobalStyled = createGlobalStyle`
	html {
		font-size: 13px;
		@media (max-width: 767px) {
			font-size: 12px;
		}
	}

	body {
		font-family: ${({ theme }) => theme.font.primary};
		line-height: 1.7;
		@media (max-width: 767px) {
			font-size: 12px;
		}
	}

	* {
		margin: 0;
		box-sizing: border-box;
	}

	ul {
		padding: 0;
    list-style-type: none;
	}

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
  }

	h1, h2, h5 {
		color: ${({ theme }) => theme.color.strongText};
	}

	h1 {
		font-size: 2.5rem;
	}

	h3, .h3 {
		font-size: ${({ theme }) => theme.text.l.size}rem;
		font-weight: ${({ theme }) => theme.text.l.weight};
	}

	h4, .h4 {
		font-size: 1.5rem;
	}

  h6, .h6 {
		font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
	}

	a {
		text-decoration: none;
    color: ${({ theme }) => theme.color.softText};
	}

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track-piece {
		background: transparent; // ${({ theme }) => theme.color.backgroundFront};
	}

	::-webkit-scrollbar-track {
		background: transparent; // ${({ theme }) => darken(0.05, theme.color.backgroundFront)};
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		border: 1px solid ${({ theme }) => darken(0.05, theme.color.backgroundFront)};
		background: ${({ theme }) => transparentize(0.5, theme.color.softText)};

		&:hover {
			background: ${({ theme }) => theme.color.softText};
		}
	}

	::-webkit-scrollbar-thumb:window-inactive {
		background: ${({ theme }) => theme.color.softerStroke};
	}

  ${AntStyling}
`
export default GlobalStyled
