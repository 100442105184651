import { css } from 'styled-components'

export const smallButton = css`
  font-size: 0.9em;
  color: ${({ theme, isPrimary, disabled }) =>
    isPrimary && !disabled ? theme.color.white : theme.color.softText};
  background: ${({ theme, isPrimary, isActive, disabled }) =>
    (isPrimary || isActive) && !disabled ? theme.color.action : 'none'};
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? theme.color.softText : theme.color.action)};
  border-radius: 1rem;
  padding: 0 0.5rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  max-width: fit-content;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.color.softerStroke};

    span {
      color: ${({ theme, isActive, isPrimary, disabled }) =>
        !disabled && (isActive || isPrimary ? theme.color.action : theme.color.mainText)};
    }
    .Icon {
      color: ${({ theme, disabled }) => !disabled && theme.color.action};
    }
  }

  span {
    color: ${({ theme, isActive, isPrimary, disabled }) =>
      (isActive || isPrimary) && !disabled ? theme.color.white : theme.color.mainText};
  }
  .Icon {
    margin-right: 0.2rem;
  }
`
