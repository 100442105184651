import React from 'react'

import { injectIntl } from 'react-intl'

import Search, { useSearch } from '../../components/Search'
import { useSearchQuery } from '../../hooks'
import MenuResultItem from './MenuResultItem'

export const GotoSearch = ({ onClose, intl }) => {
  const { props, text } = useSearch({ onClose })
  const { data, ...params } = useSearchQuery(text)

  return (
    <Search
      {...props}
      {...params}
      data={data?.search}
      RowComponent={MenuResultItem}
      placeholder={intl.formatMessage({ id: 'search.goto.placeholder' })}
    />
  )
}

export default injectIntl(GotoSearch)
