import React, { useEffect, useRef, useState } from 'react'

import { useQueryData } from 'services/data'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'
import { useActionConfirmation } from 'components/Dashboard/components/ActionConfirmation/hooks'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

import PhaseToggleStyled, { PhaseToggleContainer } from './styled'

const PhaseToggle = ({
  id,
  writeDatapoint = {},
  iconOn,
  iconOff,
  colorOn,
  colorOff,
  readOnly = false,
  //newSocketValue,
  className,
  initialValue,
}) => {
  const element = useRef()
  const [value, setValue] = useState(0)
  const [confirmation, onConfirmationRequest] = useActionConfirmation()

  const { data: apiResponse } = useQueryData({
    service: 'datapoints',
    payload: { datapoints: [{ id }], widgetType: 'phaseToggle' },
    deps: [],
    skip: initialValue !== 'IN_EDIT',
  })

  const last =
    initialValue !== 'IN_EDIT'
      ? initialValue?.value?.value
      : apiResponse?.data?.datapoints?.length > 0
      ? apiResponse?.data?.datapoints[0].last?.value
      : null

  /*useEffect(() => {
    if (newSocketValue) {
      setValue(newSocketValue.value)
    }
  }, [newSocketValue])*/

  useEffect(() => {
    if (last) {
      setValue(last)
    }
  }, [last])

  useEffect(() => {
    if (confirmation.isConfirmed) {
      setValue(confirmation.value)
    }
  }, [confirmation])

  const onClick = async () => {
    if (readOnly) {
      return
    }
    const newToggleState = !isToggled()
    onConfirmationRequest({ value: newToggleState ? 1 : 0 })
  }

  const isToggled = () => {
    if (value !== undefined) {
      return value
    }
    return Number(last?.value) === 1
  }

  const toggled = isToggled()
  const hasIcon = iconOn || iconOff

  /* const _loading = error
    ? handleError(error, !data?.datapoint)
    : loading && { spinner: { type: 'lineScale', size: 10 } } */

  const _loading = !initialValue && { spinner: { type: 'lineScale', size: 8 } }

  return (
    <PhaseToggleContainer ref={element}>
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        id={writeDatapoint?.id || id}
        onConfirmationRequest={onConfirmationRequest}
        parentRef={element.current}
      >
        <PhaseToggleStyled
          className={`PhaseToggle ${className}`}
          disabled={readOnly}
          as={!hasIcon && 'div'}
          status={confirmation.value ? 'on' : 'off'}
          icon={confirmation.value ? iconOn || 'fas fa-lightbulb' : iconOff || 'far fa-lightbulb'}
          hasIcon={hasIcon}
          color={confirmation.value ? colorOn : colorOff}
        />
      </ActionConfirmationWithAdjustableValue>
      <PhaseToggleStyled
        className={`PhaseToggle ${className}`}
        onClick={onClick}
        disabled={readOnly}
        as={!hasIcon && 'div'}
        status={toggled ? 'on' : 'off'}
        icon={toggled ? iconOn || 'fas fa-lightbulb' : iconOff || 'far fa-lightbulb'}
        hasIcon={hasIcon}
        color={toggled ? colorOn : colorOff}
      />
      <WidgetLoader {..._loading} loading={!initialValue} />
    </PhaseToggleContainer>
  )
}

export default PhaseToggle
