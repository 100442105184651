import styled from 'styled-components'

export const CaptionStyled = styled.div`
  display: flex;
`

export const Description = styled.p``

export const Title = styled.h3`
  margin: 0;
`
export default CaptionStyled
