import React from 'react'

import { Tag } from 'antd'
import { get, isEmpty } from 'lodash'
import { Controller } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { useLanguages } from 'services/languages'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import TextInput from 'components/Form/components/TextInput'

import { Container, Label, Language, TranslationFieldStyled, Value } from './styled'

const TranslationField = ({
  value,
  name,
  label,
  placeholder,
  validation,
  onBlur,
  viewRender,
  editRender,
  showRequired,
  disabled,
  rows,
  onChange,
  ...fieldProps
}) => {
  const { data: languages, loading: languagesLoading } = useLanguages()

  return (
    <TranslationFieldStyled
      {...fieldProps}
      label={label}
      required={showRequired && validation?.required}
    >
      {({ errors, isEditMode, watch, loading }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        const error = get(errors, name)

        if (loading || languagesLoading) {
          return <Skeleton width="200px" />
        }

        if (isEditMode) {
          const field = name ? (
            <Controller
              name={name}
              rules={validation}
              render={({ onChange: controllerOnChange, value: controllerValue }) => {
                const handleOnChange = (key) => (val) => {
                  const newValue = { ...controllerValue, [key]: val }
                  const cleanNewValue = Object.entries(newValue).reduce((result, [code, value]) => {
                    if (value !== '') {
                      result[code] = value
                    }

                    return result
                  }, {})

                  controllerOnChange(isEmpty(cleanNewValue) ? undefined : cleanNewValue)
                  onChange && onChange(val)
                }

                return (
                  <Container>
                    {languages?.map(({ code, name }) => (
                      <Language>
                        <Label>
                          {name}
                          {code === 'en-US' && (
                            <Tag color="default">
                              <FormattedMessage id="default" />
                            </Tag>
                          )}
                        </Label>
                        <Value>
                          <TextInput
                            onChange={handleOnChange(code)}
                            value={fieldValue?.[code]}
                            placeholder={placeholder}
                            error={error}
                            onBlur={onBlur}
                            disabled={disabled}
                            rows={rows}
                          />
                        </Value>
                      </Language>
                    ))}
                  </Container>
                )
              }}
            />
          ) : (
            <TextInput
              onChange={onChange}
              value={value}
              error={error}
              onBlur={onBlur}
              disabled={disabled}
              rows={rows}
            />
          )

          const message = <FieldMessage message={error} label={label} name={name} />

          return editRender ? editRender({ field, message }) : [field, message]
        }

        return viewRender ? (
          viewRender(fieldValue)
        ) : (
          <Container>
            {languages?.map(({ code, name }) => (
              <Language>
                <Label>
                  {name}
                  {code === 'en-US' && (
                    <Tag color="default">
                      <FormattedMessage id="default" />
                    </Tag>
                  )}
                </Label>
                <Value>{fieldValue?.[code] || ''}</Value>
              </Language>
            ))}
          </Container>
        )
      }}
    </TranslationFieldStyled>
  )
}

export default TranslationField
