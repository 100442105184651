import _ from 'lodash'
import * as icons from 'theme/icons'

export const generateSvgFromIconName = (iconName) => {
  if (!iconName) {
    return ''
  }
  const iconBase = _.camelCase(iconName.split(' ')[1])
  if (!icons[iconBase]) {
    return ''
  }
  return `<svg aria-hidden="true" focusable="false" class="svg-inline--fa fa-w-18 Icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icons[iconBase].icon[0]} ${icons[iconBase].icon[1]}"><path fill="currentColor" d="${icons[iconBase].icon[4]}"></path></svg>`
}
