import React, { useMemo, useState } from 'react'

import treeifyHiearchyList from 'util/treeifyHiearchyList'
import { useEntityTreeDataForCompany } from 'services/entities'

import Cascader from 'components/Form/components/Cascader'
import DatapointSelectorStyled from 'components/Global/FormField/DatapointSelector/styled'

const disabledFunction = ({ entityTags }) => {
  return !entityTags?.area
}

const labelFunction = ({ name, entityTags }) => {
  return `${name}${entityTags?.area ? ` - ${entityTags.area}m²` : ''}`
}

const displayRender = (names) => {
  return names[names.length - 1]
}

const EntityCascader = ({ onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentSelection, setCurrentSelection] = useState()
  const { data } = useEntityTreeDataForCompany()
  const treeifiedEntities = useMemo(
    () =>
      data
        ? treeifyHiearchyList({
            list: data,
            idKey: 'id',
            depthType: 'equip',
            disabledFunction,
            labelFunction,
          })
        : [],
    [data]
  )

  const displayValue = useMemo(() => {
    const selected = data?.find(({ id }) => id === value?.id)
    return selected?.hierarchy
  }, [value, data])

  const onConfirm = () => {
    setIsOpen(false)
    const { value, label } = currentSelection
    onChange({ id: value, name: label })
  }
  const handleChange = (e, payload) => {
    setCurrentSelection(payload[payload.length - 1])
  }

  return (
    <DatapointSelectorStyled className="EntitySelector" onClick={() => setIsOpen(true)}>
      {data && (
        <Cascader
          options={treeifiedEntities}
          popupVisible={isOpen}
          showButtons
          changeOnSelect
          onConfirm={onConfirm}
          onChange={handleChange}
          prefix={false}
          displayRender={displayRender}
          defaultValue={displayValue}
        />
      )}
    </DatapointSelectorStyled>
  )
}

export default EntityCascader
