import { transparentize } from 'polished'
import styled from 'styled-components'

const TextInputStyled = styled.span`
  position: relative;
  display: inline-block;
  height: ${({ rows }) => (rows === undefined ? '2.4em' : `${rows * 24}px`)};

  input,
  textarea {
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-overflow: ellipsis;
    transition: border 0.2s ease;
    border-radius: 6px;
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.color.error : theme.color.softStroke}`};
    padding: 0.5rem;
    background: none;
    color: ${({ theme }) => theme.color.strongText};
    cursor: ${({ disabled }) => disabled && 'not-allowed'};

    &:hover {
      border-color: ${({ theme, error, disabled }) =>
        !disabled && (error ? theme.color.error : theme.color.main)};
    }
    &:focus {
      border-color: ${({ theme, error, disabled }) =>
        !disabled && (error ? theme.color.error : theme.color.main)};
      box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.9, theme.color.main)};
      outline: none;
    }
  }
  input {
    height: 100%;
  }
  textarea {
    height: auto;
  }
`
export default TextInputStyled
