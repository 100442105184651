import { css } from 'styled-components'

export const notification = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: ${({ color }) => color};
  color: #ffffff;
  margin-right: 1rem;
  svg {
    width: 50%;
  }
`
