import { useMemo } from 'react'

import { isArray, isNumber, isObject } from 'lodash'

import { useGhgData } from 'services/entities'

export const useComparatorValues = ({
  comparator,
  timeRange,
  datapoints,
  type,
  emissionFactor,
  defaultTimeRange,
  granularity,
  returnUnitId,
  calculationMethod,
  widgetType,
}) => {
  const comparatorDatapoints = useMemo(
    () => (isArray(comparator) ? comparator.filter(isObject) : []),
    [comparator]
  )

  const datapointsForQuery = useMemo(
    () =>
      comparatorDatapoints.length ? comparatorDatapoints : comparator === 'main' && datapoints,
    [datapoints, comparatorDatapoints, comparator]
  )

  const values = useMemo(
    () => (isArray(comparator) ? comparator.filter(isNumber).map((value) => value) : []),
    [comparator]
  )

  const { data: ghgData, loading, refetch, error } = useGhgData({
    datapoints: datapointsForQuery,
    emissionFactor,
    timeRange,
    defaultTimeRange,
    granularity,
    returnUnitId,
    skip: type !== 'ghg',
    calculationMethod,
    widgetType,
  })

  const data = [...values]
  if (datapointsForQuery?.length > 0) {
    let queryData = []

    if (ghgData?.length) {
      queryData = ghgData
    }
    data.push(...queryData)
  }

  return { data, loading, error, refetch }
}
