import styled from 'styled-components'

import { Value } from 'components/Form/components/Field/styled'

import Field from '../../components/Field'

const ListFieldStyled = styled(Field)`
  height: ${({ height }) => height};
  ${Value} {
    height: 100%;
  }
`
export default ListFieldStyled
