import React, { useState } from 'react'

import PropTypes from 'prop-types'

import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'

import MenuList from '../index'
import { MenuListItemButton, MenuListItemStyled } from './styled'
import Title from './Title'

const MenuListItem = ({
  menuItem,
  selectedKeys = [],
  level,
  variant,
  screenSize,
  isOpen,
  defaultOpen,
  localisedLabels,
  onItemClick,
  tooltip,
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(defaultOpen)
  const { icon, title, url, disabled, children } = menuItem
  const openStateClassname = children ? (dropdownIsOpen ? 'menu-open' : 'menu-close') : ''

  return (
    <MenuListItemStyled
      className={`MenuListItem ${openStateClassname}`}
      variant={variant}
      isOpen={level === 0 || isOpen}
      dropdownIsOpen={dropdownIsOpen}
      level={level}
    >
      <MenuListItemButton
        disabled={disabled}
        className={`Button ${dropdownIsOpen ? 'active' : ''}`}
        activeClassName="active"
        to={url}
        icon={icon}
      >
        <Title title={title} icon={icon} localisedLabels={localisedLabels} tooltip={tooltip} />
      </MenuListItemButton>

      {!!children?.length && (
        <>
          <DropdownArrow
            className="DropdownArrow"
            onClick={() => setDropdownIsOpen((state) => !state)}
          />
          <MenuList
            value={children}
            selectedKeys={selectedKeys}
            level={level + 1}
            variant={variant || 'MenuList'}
            show={isOpen && dropdownIsOpen}
            screenSize={screenSize}
            onItemClick={onItemClick}
            localisedLabels={localisedLabels}
            tooltip={true}
          />
        </>
      )}
    </MenuListItemStyled>
  )
}

MenuListItem.propTypes = {
  prop: PropTypes.arrayOf(PropTypes.object),
  defaultOpen: PropTypes.bool,
}

MenuListItem.defaultProps = {
  defaultOpen: false,
}

export default MenuListItem
