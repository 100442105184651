import { css } from 'styled-components'

export const ellipsis = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  div {
    width: ${({ size }) => Math.ceil(size / 4)}px;
    height: ${({ size }) => Math.ceil(size / 4)}px;
    border-radius: 50%;
    background: ${({ theme, color }) => theme.color[color]};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      position: absolute;
      animation: ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      animation: ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      animation: ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      animation: ellipsis3 0.6s infinite;
    }
  }
  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(133.33333%, 0);
    }
  }
`
