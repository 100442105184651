import React from 'react'

import { useNonAssetEntities } from 'services/entities'

import { OptionStyled } from 'components/Form/components/ValuePicker/styled'

import SelectField from '../../SelectField'

const CustomOption = ({ label, option, selected }) => {
  return (
    <OptionStyled selected={selected}>
      <span className="Option__primary">{label}</span>
      <span className="Option__secondary">{option?.department?.name}</span>
      <span className="Option__secondary">{option?.tags?.internalId}</span>
      <span className="Option__secondary">{option?.vehicleType?.description}</span>
    </OptionStyled>
  )
}

const NonAssetEntitiesSelectField = ({ entityTypes, nonAssetFilter, ...props }) => {
  const { data } = useNonAssetEntities(entityTypes, { filter: nonAssetFilter })

  return (
    <SelectField
      {...props}
      options={data}
      selectionKeys={{ value: 'id', label: 'name' }}
      optionComponent={CustomOption}
      width={500}
    />
  )
}

export default NonAssetEntitiesSelectField
