const getGroupPropsForDatapoint = (id, groups) => {
  if (!groups) {
    return null
  }
  const groupIndex = groups.findIndex(({ datapoints }) => datapoints.includes(id))
  return groupIndex === -1
    ? null
    : {
        color: groups[groupIndex].color,
        //disabled: groupIndex !== selectedGroupIndex,
      }
}

export const getDatapointListForQuery = ({
  dataSource,
  assetDatapoints,
  nonAssetDatapoints,
  upstreamDownstreamDatapoints,
  preview,
  groupDataKey,
  groupIndex,
  customAssetsFilter = () => true,
  customNonAssetsFilter = () => true,
}) => {
  if (dataSource === 'assets') {
    return assetDatapoints?.reduce((datapoints, p) => {
      if (customAssetsFilter(p)) {
        datapoints.push({
          id: p.id,
          name: p.name,
          unit: p.unit,
          difference: p.unitObject?.difference,
          tags: p.tags,
          ...getGroupPropsForDatapoint(p.id, preview?.[groupDataKey], groupIndex),
        })
      }
      return datapoints
    }, [])
  }
  if (dataSource === 'upstreamDownstream') {
    return upstreamDownstreamDatapoints?.reduce((datapoints, p) => {
      if (customNonAssetsFilter(p)) {
        datapoints.push({
          id: p.id,
          name: p.name,
          unit: p.unit?.name || null,
          difference: p.unitObject?.difference,
          tags: p.tags,
          ...getGroupPropsForDatapoint(p.id, preview?.[groupDataKey], groupIndex),
        })
      }
      return datapoints
    }, [])
  }

  return nonAssetDatapoints?.reduce((datapoints, p) => {
    if (customNonAssetsFilter(p)) {
      datapoints.push({
        id: p.id,
        name: p.name,
        unit: p.unit?.name || null,
        difference: p.unit?.difference,
        tags: p.tags,
        ...getGroupPropsForDatapoint(p.id, preview?.[groupDataKey], groupIndex),
      })
    }
    return datapoints
  }, [])
}

export const filterDatapointListByTags = () => {}

export const filterDatapointForTags = (datapoints, selectedTags) => {
  return datapoints.filter(
    ({ tags }) =>
      tags.virtual !== '1' && selectedTags.every(({ key, value }) => tags?.[key] === value)
  )
}
