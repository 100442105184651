import React, { useMemo } from 'react'

import { useEnergyTypes } from 'services/energy'

import { SelectField } from 'components/Form'

const EnergySelectField = ({ ...props }) => {
  const { data, loading } = useEnergyTypes()
  const selectData = useMemo(
    () => data?.map(({ id, energySource }) => ({ value: id, label: energySource })),
    [data]
  )

  return <SelectField {...props} loading={loading} options={selectData} />
}

EnergySelectField.propTypes = {}

EnergySelectField.defaultProps = {}

export default EnergySelectField
