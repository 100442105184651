import React from 'react'

import { FormattedMessage } from 'react-intl'

import { MenuHeaderStyled } from './styled'

const MenuHeader = ({ children, title }) => (
  <MenuHeaderStyled className="MenuHeader">
    <h2>
      <FormattedMessage id={title} />
    </h2>
    {children}
  </MenuHeaderStyled>
)

export default MenuHeader
