import styled from 'styled-components'

import { status as _status } from 'components/Global/Caption/styled.status'
import Icon from 'components/Global/Icon'

const PhaseToggleStyled = styled(Icon)`
  width: ${({ hasIcon }) => (hasIcon ? '100%' : '.8rem')};
  height: ${({ hasIcon }) => (hasIcon ? '100%' : '.8rem')};
  max-height: ${({ hasIcon }) => (hasIcon ? '100%' : '.8rem')};
  transition: transform 0.2s ease;
  margin: auto;
  color: ${({ color }) => color};
  display: block;
  &:hover {
    transform: scale(1.2);
  }
  ${({ hasIcon, status, blinking, blinkingSpeed, color }) =>
    !hasIcon && _status(status === 'on' ? 'success' : 'error', color, blinking, blinkingSpeed)}
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

export const PhaseToggleContainer = styled.div`
  position: relative;
  .ActionConfirmation {
    text-align: left;
  }
`
export default PhaseToggleStyled
