import { gql, useQuery } from '@apollo/client'

const QUERY_FEATURES_BY_NAME = gql`
  query filteredEntityFeatureTypes($typeName: String!, $filter: [String!]!) {
    filteredEntityFeatureTypes(typeName: $typeName, filter: $filter)
  }
`

const QUERY_FILTERED_FEATURES = gql`
  query filteredFeatures($tagObjectInput: [TagObjectInput!]!) {
    filteredFeatures(tagObjectInput: $tagObjectInput) {
      featureId
      featureName
      featureType
      featureFieldType
    }
  }
`

export const useFeaturesByName = (type, filter) => {
  const { data, ...rest } = useQuery(QUERY_FEATURES_BY_NAME, {
    variables: { typeName: type, filter },
    skip: !type || !filter?.length,
    fetchPolicy: 'network-only',
  })
  return { data: data?.filteredEntityFeatureTypes, ...rest }
}

export const useFilteredFeatures = (filter) => {
  const { data, ...rest } = useQuery(QUERY_FILTERED_FEATURES, {
    variables: { tagObjectInput: filter },
    skip: !filter?.length,
  })
  return { data: data?.filteredFeatures, ...rest }
}
