import React from 'react'

import { injectIntl } from 'react-intl'

import Progress from 'components/Form/components/Progress'

import AgGridProgressCellStyled from './styled'

const AgGridProgressCell = (props) => {
  return (
    <AgGridProgressCellStyled className="AgGridProgressCell">
      <Progress {...props} />
    </AgGridProgressCellStyled>
  )
}

export default injectIntl(AgGridProgressCell)
