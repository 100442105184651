import React, { useEffect, useRef } from 'react'

import { bool, func, object, string } from 'prop-types'

import Component from '../../Component/styled'
import SlideStyled from './styled'

const transitionEffect = (effect, reverse) =>
  ({
    fade: {
      enter: {
        opacity: 0,
        visibility: 'hidden',
      },
      active: {
        opacity: 1,
        visibility: 'visible',
      },
      exit: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
    slideHorizontal: {
      enter: {
        x: reverse ? '-100%' : '100%',
        transition: { duration: 0 },
      },
      active: {
        x: 0,
        transition: {
          type: 'spring',
          stiffness: 150,
          damping: 24,
          from: reverse ? '-100%' : '100%',
        },
      },
      exit: {
        x: reverse ? '100%' : '-100%',
        transition: { type: 'spring', stiffness: 150, damping: 24 },
      },
    },
  }[effect || 'fade'])

const getSlideState = (prevSlide, activeSlide, slideIndex) => {
  return activeSlide === slideIndex ? 'active' : prevSlide === slideIndex ? 'exit' : 'enter'
}
const Slide = ({
  styleProps,
  prevSlide,
  activeSlide,
  slideIndex,
  effect = 'slideHorizontal',
  reverse,
  children,
  component,
  componentProps,
  resetScrollTrigger,
}) => {
  const slideState = getSlideState(prevSlide, activeSlide, slideIndex)
  const slideRef = useRef()

  const resetScroll = () => {
    if (slideRef.current) {
      slideRef.current.scroll({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    resetScroll()
  }, [resetScrollTrigger])

  return (
    <SlideStyled
      {...styleProps}
      className={`Slide ${slideState}`}
      isActive={activeSlide === slideIndex}
      initial="enter"
      animate={slideState}
      variants={transitionEffect(effect, reverse)}
      ref={slideRef}
    >
      {component ? <Component {...componentProps} as={component} /> : children}
    </SlideStyled>
  )
}

Slide.propTypes = {
  componentName: string,
  componentProps: object,
  styleProps: object,
  isActive: bool,
  effect: string,
  onButtonClick: func,
  listDataKey: string,
}

export default Slide
