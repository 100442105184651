import { useMemo } from 'react'

import dayjs from 'dayjs'
import { isArray, isNumber, isObject } from 'lodash'

import { getOffsetsForGranularity } from 'util/datapointCalculationFunctions'

import {
  getDefaultTimeRange,
  useDefaultTimeRange,
} from 'components/Dashboard/components/TimePicker/functions'

import { getGranularity, getGroupProperties } from '../components/Dashboard/utils/common/helpers'

export const useHistoricalDatapointsPayload = ({
  datapoints,
  startTime,
  endTime,
  datapointsGroups,
  granularity,
  calculationMethod,
  preset,
}) => {
  const timeRange = useMemo(
    () => ({
      startTime: startTime
        ? startTime.startOf('second').valueOf()
        : dayjs().subtract(7, 'days').valueOf(),
      endTime: endTime ? endTime.startOf('second').valueOf() : dayjs().valueOf(),
      preset,
    }),
    [startTime, endTime, preset]
  )
  const groupProperties = getGroupProperties(datapointsGroups)
  const dataTimeRange = useDefaultTimeRange(timeRange, { preset: 'past7Days' }, false)
  const { presetObject } = dataTimeRange
  const dataGranularity = getGranularity({
    granularity,
    calculationMethod,
    defaultStartTime: presetObject && { unit: presetObject.unit },
  })

  return useMemo(() => {
    return {
      datapoints: datapoints.map(
        ({
          id,
          difference,
          conversionUnit,
          defaultStartTime,
          offsets,
          defaultCalculationMethod,
          groupId,
        }) => ({
          startTime: timeRange.startTime,
          endTime: timeRange.endTime,
          id,
          groupId,
          offsets: (groupProperties[id]?.offsets || offsets)?.map(
            ({ defaultCalculationMethod, ...offset }) => ({
              ...offset,
              calculationMethod: defaultCalculationMethod || calculationMethod,
            })
          ),
          returnUnitId: groupProperties[id]?.returnUnitId || conversionUnit,
          difference,
          defaultStartTime,
          calculationMethod:
            groupProperties[id]?.defaultCalculationMethod ||
            defaultCalculationMethod ||
            calculationMethod,
          granularity: dataGranularity,
          timeRange: presetObject,
        })
      ),
      timeRange,
    }
  }, [timeRange, groupProperties, datapoints, dataGranularity, presetObject, calculationMethod])
}

export const useDatapointsForTimeRangePayload = ({
  datapoints,
  timeRange,
  groupConversionUnit,
  locationData,
}) => {
  return useMemo(() => {
    if (!datapoints) {
      return []
    }
    const isLastValue = !timeRange || timeRange?.preset === 'none'
    const timeRangeValues =
      timeRange?.preset === 'linkedWidget' && locationData
        ? { startTime: locationData.startTime, endTime: locationData.endTime }
        : getDefaultTimeRange(timeRange)

    return datapoints.map(({ id, conversionUnit, difference, tag }) => ({
      ...timeRangeValues,
      id,
      returnUnitId: conversionUnit || groupConversionUnit,
      ...(!isLastValue && {
        difference,
        granularity: '',
      }),
      tag,
    }))
  }, [datapoints, timeRange, groupConversionUnit, locationData])
}

export const useComparatorsPayload = ({
  datapoints,
  comparators,
  timeRange,
  groupConversionUnit,
  locationData,
}) => {
  const comparatorDatapoints = useMemo(
    () => (isArray(comparators) ? comparators.filter(isObject) : []),
    [comparators]
  )
  const datapointsForQuery = comparators === 'main' ? datapoints : comparatorDatapoints
  const payloadDatapoints = useDatapointsForTimeRangePayload({
    datapoints: datapointsForQuery,
    timeRange,
    groupConversionUnit,
    locationData,
  })
  return useMemo(() => {
    const values = isArray(comparators) ? comparators.filter(isNumber).map((value) => value) : []
    return { values, comparatorDatapoints: payloadDatapoints }
  }, [comparators, payloadDatapoints])
}

export const useEnergyClassificationsPayload = ({
  datapoints,
  comparisonPeriod,
  energyClassification,
  area,
}) => {
  const comparisonStartTime = comparisonPeriod?.preset
    ? dayjs()
        .startOf(energyClassification?.interval)
        .subtract(
          comparisonPeriod.preset.startsWith('previous') ? 1 : 0,
          energyClassification?.interval
        )
        .valueOf()
    : comparisonPeriod?.startTime

  const comparisonEndTime = comparisonPeriod?.preset
    ? comparisonPeriod.preset.startsWith('previous')
      ? dayjs()
          .subtract(1, energyClassification?.interval)
          .endOf(energyClassification?.interval)
          .valueOf()
      : dayjs().valueOf()
    : comparisonPeriod?.endTime

  return useMemo(
    () => ({
      datapoints: datapoints.map(({ id }) => ({ id, areaEntityId: area?.id })),
      energyClassificationId: parseInt(energyClassification?.id),
      comparisonStartTime,
      comparisonEndTime,
      widgetType: 'energyClassification',
    }),
    [datapoints, energyClassification, area, comparisonStartTime, comparisonEndTime]
  )
}

const getReferenceTimeRange = (gridColumns) => {
  const referenceColumn = gridColumns.find(({ valueType }) => valueType === 'referenceValue')
  if (!referenceColumn?.defaultStartTime) {
    return { startTime: dayjs().subtract(1, 'day').valueOf(), endTime: dayjs().valueOf() }
  }
  return getDefaultTimeRange(referenceColumn.defaultStartTime)
}

const getOffsetsByColumn = (gridColumns, referenceTimeRange) => {
  return gridColumns.reduce(
    (offsets, { valueType, defaultStartTime, defaultCalculationMethod }) => {
      if (valueType === 'value') {
        const offset = defaultStartTime?.unit &&
          !defaultStartTime.duration && {
            unit: defaultStartTime.unit,
            value: defaultStartTime.amount,
          }
        const { startTime, endTime } = !offset
          ? getDefaultTimeRange(defaultStartTime, referenceTimeRange)
          : {}
        offsets.push({
          ...(offset ? offset : { startTime, endTime }),
          dashStyle: 'dotted',
          color: '#FFFFFF',
          calculationMethod: defaultCalculationMethod,
        })
      }
      return offsets
    },
    []
  )
}

export const useDatapointsGridPayload = (datapoints, gridColumns) => {
  return useMemo(() => {
    const referenceTimeRange = getReferenceTimeRange(gridColumns)
    return datapoints.map(
      ({ id, groupId, defaultCalculationMethod, multiplier, conversionUnit }) => ({
        id,
        ...referenceTimeRange,
        offsets: getOffsetsByColumn(gridColumns, referenceTimeRange),
        returnUnitId: conversionUnit,
        calculationMethod: defaultCalculationMethod,
        multiplier,
        granularity: '30m',
        groupId,
      })
    )
  }, [datapoints, gridColumns])
}

export const useDatapointsSparklinePayload = ({
  datapoints,
  defaultStartTime,
  defaultGranularity,
  defaultGranularityValue,
  conversionUnit,
  calculationMethod,
  locationData,
}) => {
  const startTimeDefault = useMemo(
    () => getDefaultTimeRange({ preset: 'past7Days' }, 'startTime').startTime,
    []
  )
  const { startTime, endTime } = useMemo(
    () =>
      defaultStartTime
        ? getDefaultTimeRange(defaultStartTime)
        : locationData
        ? { startTime: locationData.startTime, endTime: locationData.endTime }
        : { startTime: startTimeDefault },
    [defaultStartTime, startTimeDefault, locationData]
  )
  const granularityUnit =
    defaultGranularity === 'linkedData'
      ? locationData?.granularity?.granularity
      : defaultGranularity
  const granularityValue =
    defaultGranularity === 'linkedData'
      ? locationData?.granularity?.granularityValue
      : defaultGranularityValue

  const granularity = `${granularityValue}${granularityUnit}`

  return useMemo(() => {
    const datapointOffsets = granularityUnit === 'month'

    const offsets =
      datapointOffsets &&
      getOffsetsForGranularity({
        startTime: dayjs(startTime),
        endTime: endTime ? dayjs(endTime) : dayjs(),
        defaultGranularity: granularityUnit,
        defaultGranularityValue: granularityValue,
      })

    return datapoints.map(({ id }, index) => ({
      ...(offsets && { offsets }),
      id,
      returnUnitId: index === 0 ? conversionUnit : null,
      granularity: datapointOffsets ? '1d' : getGranularity({ granularity, defaultStartTime }),
      startTime: datapointOffsets
        ? endTime || dayjs().startOf(granularityUnit).valueOf()
        : startTime || undefined,
      endTime: !datapointOffsets ? endTime : undefined,
      calculationMethod,
    }))
  }, [
    datapoints,
    defaultStartTime,
    startTime,
    endTime,
    granularity,
    conversionUnit,
    calculationMethod,
    granularityUnit,
    granularityValue,
  ])
}
