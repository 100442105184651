import React, { useCallback, useEffect } from 'react'

import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'

import { useAllowedCompanies } from 'services/customer'
import { setCustomer, useAppState } from 'services/store'

import Dropdown from 'components/Global/Dropdown'

import Loading from '../../../util/Loading'
import CustomerSelectorStyled from './styled'

const CustomerSelector = () => {
  const {
    appState: { customer },
    appStateDispatch,
  } = useAppState()
  const changeCostumer = useCallback((payload) => appStateDispatch(setCustomer(payload)), [
    appStateDispatch,
  ])
  const { data: allowedCompanies } = useAllowedCompanies()

  useEffect(() => {
    // Make sure there are allowed companies
    if (allowedCompanies?.length) {
      // If no customer or customer is not present in allowed companies, set it to the first allowed company
      if (!customer || !allowedCompanies.find((c) => c.id === customer?.id)) {
        changeCostumer(allowedCompanies[0])
      }
    }
  }, [customer, allowedCompanies, changeCostumer])

  if (!allowedCompanies) {
    return <Loading />
  }

  if (allowedCompanies.length === 0) {
    return null
  }

  if (allowedCompanies.length === 1) {
    return <Typography.Text strong>{allowedCompanies[0].name}</Typography.Text>
  }

  const sortedAllowedCompanies = [...allowedCompanies]

  sortedAllowedCompanies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

  const handleChange = (e, value) => {
    const newCustomer = sortedAllowedCompanies.find((x) => x.id === value.id)
    changeCostumer(newCustomer)
  }

  return (
    // TODO: Check className attribute
    <CustomerSelectorStyled>
      <h3>
        <FormattedMessage id="menuBar.logoDropdown.currentCustomer" />
      </h3>
      <Dropdown
        value={customer}
        options={sortedAllowedCompanies}
        componentName="MenuItem"
        componentProps={{
          valueKey: 'value.name',
        }}
        styleProps={{
          variant: 'CustomerSelector',
        }}
        onChange={handleChange}
      />
    </CustomerSelectorStyled>
  )
}

export default CustomerSelector
