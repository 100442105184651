import React from 'react'

import { withTheme } from 'styled-components'

import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'

import CustomerSelector from '../CustomerSelector'
import LogoDropdownStyled from './styled'

const LogoDropdown = ({ theme }) => {
  return (
    <LogoDropdownStyled className="LogoDropdown">
      <div className="LogoDropdown__img">
        <img src={theme.images?.favicon || '/resources/images/logo-icon.svg'} alt="Company logo" />
      </div>
      <DropdownArrow size=".5rem" className="DropdownArrow" />
      <div className="LogoDropdown__dropdown">
        <div>
          <CustomerSelector />
        </div>
      </div>
    </LogoDropdownStyled>
  )
}

LogoDropdown.propTypes = {}

LogoDropdown.defaultProps = {}

export default withTheme(LogoDropdown)
