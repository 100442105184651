import styled from 'styled-components'

import { CustomerSelector } from './styled.CustomerSelector'

const variants = {
  CustomerSelector,
}

const DropdownStyled = styled.div`
  ${({ variant }) => variants[variant]}
`
export default DropdownStyled
