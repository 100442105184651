import React from 'react'

import { AnimatePresence } from 'framer-motion'
import ReactDOM from 'react-dom'

import Component from 'components/Global/Component/styled'
import { usePortal } from 'components/Global/hooks'
import { Content } from 'components/Layout/Content'
import { useListDetail } from 'components/Layout/ListDetail/ListDetailProvider'

const DetailPortal = ({
  isOpen,
  element = 'root',
  width = '55%',
  component,
  componentProps = {},
  createMode,
  id,
  onDetailAction,
}) => {
  const target = usePortal(element)
  const { state } = useListDetail()
  const { width: panelWidth } = state.detailStack.find((panel) => panel.id === id) || { width }
  return ReactDOM.createPortal(
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <Content width={panelWidth}>
          <Component
            as={component}
            id={id}
            onDetailAction={onDetailAction}
            createMode={createMode}
            {...componentProps}
          />
        </Content>
      )}
    </AnimatePresence>,
    target
  )
}

export default DetailPortal
