import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import HighchartsHighMaps from 'highcharts/highmaps'
import HighchartsStock from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { useTheme } from 'styled-components'

import { useContainerSize } from 'components/Global/hooks'
import RemountOnResize from 'components/Global/RemountOnResize'

import { ChartStyled } from './styled'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/exporting')(HighchartsHighMaps)
require('highcharts/modules/export-data')(HighchartsHighMaps)

const highCharts = {
  default: Highcharts,
  heatmap: HighchartsHighMaps,
  stock: HighchartsStock,
}

const Chart = ({
  rows,
  cols,
  options,
  rootSectionHeight,
  remountTrigger,
  className,
  children,
  type = 'default',
}) => {
  const theme = useTheme()
  const [container, containerSize] = useContainerSize(
    { width: 0, height: 0 },
    rootSectionHeight,
    cols,
    theme.screenSize.screen.current,
    1000,
    remountTrigger
  )

  const chartOptions = useMemo(
    () => ({
      ...options,
      chart: {
        ...options.chart,
        height: containerSize.height,
        width: containerSize.width,
      },
    }),
    [containerSize, options]
  )

  return (
    <ChartStyled
      className={`Chart ${className}`}
      ref={container}
      chartHeight={containerSize.height}
    >
      <RemountOnResize
        h={rows}
        w={cols}
        rootSectionHeight={rootSectionHeight}
        remountTrigger={remountTrigger}
      >
        {containerSize.width > 0 && (
          <HighchartsReact
            highcharts={highCharts[type]}
            constructorType={type === 'stock' && 'stockChart'}
            options={chartOptions}
          />
        )}
      </RemountOnResize>
      {children}
    </ChartStyled>
  )
}

export default Chart
