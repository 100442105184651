import React from 'react'

import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { PropTypes } from 'prop-types'
import { injectIntl } from 'react-intl'

import SearchField from 'components/Global/FormField/SearchField/SearchField'
import { ListHeader } from 'components/Global/List'
import NavButton from 'components/Layout/List/components/NavButton'
import { ButtonGroup } from 'components/Layout/List/styled'

import Icon from '../../../../Global/Icon'
import SelectableRoutePicker from '../../components/SelectableRoutePicker'

const Header = ({
  title,
  compactMode = false,
  searchText,
  onSearch,
  backUrl,
  onClearFilters,
  onImport,
  onDownload,
  onReload,
  onCreate,
  onShowGrid,
  showGrid,
  refetchting,
  translateSelectableRoutePicker = false,
  hideSelectableRoutePicker = false,
  gridIcon,
  children,
  disableListNav = false,
  disableGridNav = false,
  intl,
}) => {
  return (
    <ListHeader compactMode={compactMode}>
      {backUrl && <NavButton url={backUrl} tooltipText="widget.back" />}
      <h2>{title}</h2>
      <SearchField reset={!searchText} onSearch={onSearch} />
      {!hideSelectableRoutePicker && (
        <SelectableRoutePicker hide={compactMode} translateLabel={translateSelectableRoutePicker} />
      )}

      {children}

      {onShowGrid && (
        <ButtonGroup>
          <NavButton
            iconComponent={<UnorderedListOutlined />}
            onClick={() => onShowGrid(false)}
            type={!showGrid && 'primary'}
            disabled={disableListNav}
          />
          <NavButton
            iconComponent={gridIcon || <TableOutlined />}
            onClick={() => onShowGrid(true)}
            type={showGrid && 'primary'}
            disabled={disableGridNav}
          />
        </ButtonGroup>
      )}

      <Tooltip placement="top" title={intl.formatMessage({ id: 'clearFilter' })}>
        <Icon icon="far fa-filter-slash" onClick={onClearFilters} />
      </Tooltip>

      {onImport && (
        <Tooltip placement="top" title={intl.formatMessage({ id: 'import' })}>
          <Icon icon="far fa-upload" onClick={onImport} />
        </Tooltip>
      )}

      {onDownload && (
        <Tooltip placement="top" title={intl.formatMessage({ id: 'download' })}>
          <Icon icon="far fa-download" onClick={onDownload} />
        </Tooltip>
      )}

      {onReload && (
        <Tooltip placement="top" title={intl.formatMessage({ id: 'reload' })}>
          <Icon icon="far fa-rotate-right" onClick={onReload} spin={refetchting} />
        </Tooltip>
      )}

      {onCreate && (
        <Tooltip placement="top" title={intl.formatMessage({ id: 'add' })}>
          <Icon icon="far fa-plus" onClick={onCreate} />
        </Tooltip>
      )}
    </ListHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  compactMode: PropTypes.bool,
  onCategoryChange: PropTypes.func,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onClearFilters: PropTypes.func,
  onHandleDownload: PropTypes.func,
  onReload: PropTypes.func,
  refetchting: PropTypes.bool,
  translateSelectableRoutePicker: PropTypes.string,
  hideSelectableRoutePicker: PropTypes.bool,
}

export default injectIntl(Header)
