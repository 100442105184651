import { css } from 'styled-components'

import Icon from '../Icon/styled'

export const basic = css`
  transition: color 0.2s ease, transform 0.1s ease, border 0.2s ease;
  border-radius: 2rem;
  border: 1px solid transparent;
  box-shadow: ${({ theme }) => theme.shadowElevation1(theme.darkMode)};
  padding: 0.2rem 0.5rem;
  color: ${({ theme }) => theme.color.softText};
  align-self: flex-start;

  &:hover {
    transform: scale(1.1);
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    color: ${({ theme }) => theme.color.action};
  }
  &.active {
    border-color: ${({ theme }) => theme.color.action};
    color: ${({ theme }) => theme.color.action};
  }
  ${Icon} {
    margin-right: 0.5rem;
  }
`
