import { useMemo } from 'react'

import { useDatapointUnit } from '../../../../services/datapoints'

export const useComparatorUnit = ({
  mode,
  comparatorUnit,
  maxComparators,
  minComparators,
  comparators,
  minComparatorsData,
  maxComparatorsData,
  datapoints,
}) => {
  const tag = maxComparators?.[0]?.tag || minComparators?.[0]?.tag || comparators?.[0]?.tag
  const comparatorDataUnit =
    maxComparatorsData?.data?.datapoints?.[0]?.unit ||
    minComparatorsData?.data?.datapoints?.[0]?.unit
  const refDatapoint = useDatapointUnit(
    datapoints?.[0]?.id,
    mode !== 'percentage' && !comparatorUnit && !tag && !comparatorDataUnit
  )
  return useMemo(() => {
    if (mode === 'percentage') {
      return '%'
    }
    if (comparatorUnit) {
      return comparatorUnit
    }
    if (tag) {
      return ''
    }
    return comparatorDataUnit || refDatapoint?.unit
  }, [mode, comparatorUnit, tag, comparatorDataUnit, refDatapoint])
}
