import { buttonSizes } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.status',
    category: 'widget.ghgGaugesIndicators',
    keywords: ['indicator', 'status', 'kpi', 'ghg'],
    img: '/resources/images/widgets/lastvalue.png',
    info: {
      'fr-FR':
        '<h2>Statut</h2>\n' +
        '<p>Le widget État affiche la dernière valeur pour un point de données donné.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        '<li><strong>Afficher le séparateur</strong> Afficher ou non une ligne de séparation horizontale</li>\n' +
        "<li><strong>Couleur d'arrière-plan</strong> Sélectionnez la couleur d'arrière-plan du widget</li>\n" +
        '<li><strong>Couleur de la police</strong> Sélectionnez la couleur de la police du widget</li>\n' +
        '<li><strong>Taille</strong> Sélectionnez la taille du widget</li>\n' +
        '<li><strong>Afficher la flèche de différence</strong> Afficher ou non la flèche de différence</li>\n' +
        '<li><strong>Afficher le pourcentage de différence</strong> Afficher ou non le pourcentage de différence</li>\n' +
        '<li><strong>Couleur de la flèche vers le haut</strong> Sélectionnez la couleur de la flèche vers le haut</li>\n' +
        '<li><strong>Couleur de la flèche vers le bas</strong> Sélectionnez la couleur de la flèche vers le bas</li>\n' +
        '</ul>\n' +
        '<h4>Données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un point de données</li>\n' +
        '<li><strong>Entités</strong> Sélectionner une entité</li>\n' +
        "<li><strong>Type</strong> Sélectionnez le type de point de données ou d'équipement</li>\n" +
        "<li><strong>Afficher l'unité</strong> Afficher l'unité pour la valeur sélectionnée</li>\n" +
        '<li><strong>Masquer la valeur</strong> Masquer la valeur de la valeur sélectionnée</li>\n' +
        '<li><strong>Afficher la description</strong> Afficher la description de la légende</li>\n' +
        "<li><strong>Lien</strong> Définir un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le widget</li>\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h5>Période de comparaison</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Légendes</strong> Attribuez un texte, une couleur et une icône à une valeur</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Status</h2>\n' +
        '<p>The Status widget displays the latest value for a given datapoint.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Display a horizontal divider line or not</li>\n' +
        '<li><strong>Background Color</strong> Select the background color for the widget</li>\n' +
        '<li><strong>Font Color</strong> Select the font color for the widget</li>\n' +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '<li><strong>Show Difference Arrow</strong> Display the difference arrow or not</li>\n' +
        '<li><strong>Show Difference Perecentage</strong> Display the difference percentage or not</li>\n' +
        '<li><strong>Arrow Up Color</strong> Select the color for the upwards arrow</li>\n' +
        '<li><strong>Arrow Down Color</strong> Select the color for the downwards arrow</li>\n' +
        '</ul>\n' +
        '<h4>Data</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints</strong> Select datapoint</li>\n' +
        '<li><strong>Entities</strong> Select entity</li>\n' +
        '<li><strong>Type</strong> Select type for datapoint or equipment</li>\n' +
        '<li><strong>Show Unit</strong> Show unit for selected value</li>\n' +
        '<li><strong>Hide Value</strong> Hide value for selected value</li>\n' +
        '<li><strong>Show Description</strong> Show description of caption</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the widget</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Captions</strong> Assign a text, color and icon to a value</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Status</h2>\n' +
        '<p>De Status-widget geeft de laatste waarde voor een bepaald datapunt weer.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Geef wel of geen horizontale scheidingslijn weer</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Selecteer de achtergrondkleur voor de widget</li>\n' +
        '<li><strong>Letterkleur</strong> Selecteer de letterkleur voor de widget</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '<li><strong>Toon verschilpijl</strong> Geef de verschilpijl weer</li>\n' +
        '<li><strong>Toon verschilpercentage</strong> Geef het verschilpercentage weer</li>\n' +
        '<li><strong>Kleur pijl omhoog</strong> Selecteer de kleur voor de pijl omhoog</li>\n' +
        '<li><strong>Kleur pijl-omlaag</strong> Selecteer de kleur voor de pijl-omlaag</li>\n' +
        '</ul>\n' +
        '<h4>Gegevens</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten</strong> Selecteer datapunt</li>\n' +
        '<li><strong>Entiteiten</strong> Selecteer entiteit</li>\n' +
        '<li><strong>Type</strong> Selecteer type voor datapunt of apparatuur</li>\n' +
        '<li><strong>Eenheid weergeven</strong> Eenheid voor geselecteerde waarde weergeven</li>\n' +
        '<li><strong>Waarde verbergen</strong> Waarde voor geselecteerde waarde verbergen</li>\n' +
        '<li><strong>Beschrijving weergeven</strong> Beschrijving van bijschrift weergeven</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de widget wordt geklikt</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h5>Vergelijkingsperiode</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Bijschriften</strong> Wijs een tekst, kleur en pictogram toe aan een waarde</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'ghg',
      type: 'GhgStatus',
      title: 'widget.status',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      dataKey: 'referenceValue',
      url: null,
      datapoints: [],
      method: 'sum',
      emissionFactor: 'total',
      showUnit: true,
      hideValue: false,
      captions: [],
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
      'emissionFactors',
    ],
    preview: {
      value: 20,
      unit: 'Kg',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      showUnit: true,
      hideValue: false,
      showDescription: false,
      showDiffArrow: true,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDivider' },
        col: 'col2',
        dataKey: 'line',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDifferenceArrow' },
        col: 'col2 first',
        dataKey: 'showDiffArrow',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDifferencePercentage' },
        col: 'col2',
        dataKey: 'diffPercentage',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.arrowUpColor' },
        col: 'col2 first',
        dataKey: 'arrowUpColor',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.arrowDownColor' },
        col: 'col2',
        dataKey: 'arrowDownColor',
      },
      {
        category: 'widget.data',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.showUnit' },
        dataKey: 'showUnit',
        col: 'col2 first',
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.hideValue' },
        dataKey: 'hideValue',
        col: 'col2',
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.showDescription' },
        dataKey: 'showDescription',
        col: 'col2',
      },
      {
        category: 'widget.data',
        componentName: 'LinkPicker',
        label: { formatted: 'widget.link' },
        componentProps: {
          writeDatapointOption: true,
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          hideLabel: true,
          reportingYear: true,
          dataKey: 'timeRange',
          hideGranularity: false,
          range: true,
          disableMonthGranularityValue: false,
        },
        label: { formatted: 'widget.timeRange', tagName: 'h4' },
        dataKey: '',
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          hideLabel: true,
          reportingYear: true,
          dataKey: 'comparisonPeriod',
        },
        label: { formatted: 'widget.comparisonPeriod', tagName: 'h4' },
        dataKey: '',
      },
      {
        category: 'widget.emissionFactor',
        componentName: 'EmissionFactorSelector',
        label: { formatted: 'widget.emissionFactor' },
        dataKey: 'emissionFactor',
      },
      {
        category: 'widget.fixedValues',
        componentName: 'CaptionEditor',
        label: { formatted: 'widget.captions' },
        dataKey: 'captions',
        componentProps: {
          columns: ['value', 'operation', 'title', 'icon', 'color'],
          objectMode: false,
        },
      },
      {
        category: 'widget.advanced',
        label: { formatted: 'widget.unit' },
        dataKey: 'returnUnitId',
        componentName: 'UnitPicker',
        componentProps: {
          startUnit: 'kgCO2e',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
