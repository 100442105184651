import React from 'react'

import 'jsoneditor-react/es/editor.min.css'

import { JsonEditor as Editor } from 'jsoneditor-react'
import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import TextInput from 'components/Form/components/TextInput'
import { JsonFieldStyled } from 'components/Form/fields/JsonField/styled'

const cleanedValue = (value) => {
  const val_str = JSON.stringify(value)
  if (val_str.length > 64) return val_str.substring(0, 64) + '...'
  else return val_str
}

const JsonField = ({
  value,
  name,
  label,
  placeholder,
  validation,
  onBlur,
  viewRender,
  editRender,
  showRequired,
  disabled,
  rows,
  onChange,
  ...fieldProps
}) => {
  return (
    <JsonFieldStyled {...fieldProps} label={label} required={showRequired && validation?.required}>
      {({ register, errors, isEditMode, watch, loading }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        const error = get(errors, name)

        if (loading) {
          return <Skeleton width="200px" />
        }
        if (isEditMode) {
          const field = name ? (
            <Controller
              name={name}
              rules={validation}
              render={({ onChange: controllerOnChange, value: controllerValue }) => {
                const handleOnChangeEditor = (val) => {
                  controllerOnChange(val)
                  onChange && onChange(val)
                }

                return (
                  <div>
                    <Editor
                      value={fieldValue}
                      onChange={handleOnChangeEditor}
                      allowedModes={['tree', 'code']}
                      mode={'tree'}
                      statusBar={false}
                    />
                  </div>
                )
              }}
            />
          ) : (
            <TextInput
              onChange={onChange}
              value={value}
              error={error}
              onBlur={onBlur}
              disabled={disabled}
              rows={5}
            />
          )

          const message = <FieldMessage message={error} label={label} name={name} />

          return editRender ? editRender({ field, message }) : [field, message]
        } else {
          return <span>{cleanedValue(fieldValue)}</span>
        }
      }}
    </JsonFieldStyled>
  )
}

export default JsonField
