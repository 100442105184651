import { lighten, transparentize } from 'polished'
import { css } from 'styled-components'

export const SeverityCounts = css`
  &.kpi {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0 -1.5rem;
    font-size: 1.6rem;
    min-width: 1.5rem;
    font-weight: 600;
    padding: 0.5rem;
    line-height: 1;
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme, noIssues, severity }) =>
      noIssues
        ? theme.color.softStroke
        : theme.color[
            { critical: 'error', warning: 'critical', info: 'warning', success: 'success' }[
              severity
            ]
          ]};
    box-shadow: 0 5px 20px
      ${({ theme, noIssues, severity }) =>
        noIssues
          ? theme.color.softStroke
          : lighten(
              0.3,
              theme.color[
                { critical: 'error', warning: 'critical', info: 'warning', success: 'success' }[
                  severity
                ]
              ]
            )};
    border-radius: 10rem;
    ${({ noIssues }) =>
      !noIssues &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          animation: ${({ value }) => 10 / value}s ease 0s infinite normal none running
            ${({ severity }) => severity};
          border-radius: 10rem;
        }
      `}
    @keyframes critical {
      0% {
        box-shadow: ${({ theme }) => transparentize(0.1, theme.color.error)} 0px 0px 0px 0px;
      }
      70% {
        box-shadow: ${({ theme }) => transparentize(0.85, theme.color.error)} 0px 0px 0px 6px;
      }
      100% {
        box-shadow: ${({ theme }) => transparentize(0.85, theme.color.error)} 0px 0px 0px 0px;
      }
    }
    @keyframes warning {
      0% {
        box-shadow: ${({ theme }) => transparentize(0.1, theme.color.critical)} 0px 0px 0px 0px;
      }
      70% {
        box-shadow: ${({ theme }) => transparentize(0.85, theme.color.critical)} 0px 0px 0px 6px;
      }
      100% {
        box-shadow: ${({ theme }) => transparentize(0.85, theme.color.critical)} 0px 0px 0px 0px;
      }
    }
    @keyframes info {
      0% {
        box-shadow: ${({ theme }) => transparentize(0.3, theme.color.warning)} 0px 0px 0px 0px;
      }
      70% {
        box-shadow: ${({ theme }) => transparentize(1, theme.color.warning)} 0px 0px 0px 6px;
      }
      100% {
        box-shadow: ${({ theme }) => transparentize(1, theme.color.warning)} 0px 0px 0px 0px;
      }
    }
  }
`
