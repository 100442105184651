import { css } from 'styled-components'

import { basic } from '../../Button/styled.basic'
import { tag } from '../../Button/styled.tag'

export const ValuePickerSelectOption = css`
  padding: 0.5rem;
`

export const OptionPicker = css`
  ${basic}
  margin: 0 .5rem .5rem 0;

  &:hover {
    transform: scale(1.05);
    color: ${({ theme }) => theme.color.main};
  }

  &:last-of-type {
    margin-right: 0;
  }
  &.active {
    color: ${({ theme }) => theme.color.main};
    border-color: ${({ theme }) => theme.color.main};
  }
`

export const Tags = css`
  display: flex;
  flex-wrap: wrap;
`

export const TagsSelectOption = css`
  ${tag}
`
