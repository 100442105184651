import React from 'react'

import Sparkline from '@rowno/sparkline'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import InlineErrors from 'components/Dashboard/components/Chart/InlineErrors'
import Caption from 'components/Global/Caption'
import Component from 'components/Global/Component/styled'
import Empty from 'components/Global/Empty'
import CaptionTooltip from 'components/Global/FormField/CaptionTooltip'
import { useContainerSize } from 'components/Global/hooks'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import { getLinkProps } from 'components/Widgets/functions'
import { WidgetError } from 'components/Widgets/WidgetError'

import SparkleStyled from './styled'

const Ui = ({
  styleProps = {},
  className,
  title,
  icon,
  color,
  value,
  unit,
  tooltip,
  hasTooltip,
  w,
  h,
  chart,
  url,
  loading,
  error,
  inlineErrors = [],
}) => {
  const theme = useTheme()
  const [container, containerSize] = useContainerSize(
    { width: 0, height: 0 },
    styleProps?.rootSectionHeight || h,
    w,
    theme.screenSize.screen.current
  )

  const link = getLinkProps(url)
  return (
    <SparkleStyled {...styleProps} {...link} className={`ChartCard ${className}`} color={color}>
      <Component
        as={hasTooltip && tooltip ? CaptionTooltip : Empty}
        caption={{ text: tooltip, icon: 'fas fa-circle-info' }}
        maxWidth={400}
        height={200}
      >
        <Caption
          className="Caption"
          styleProps={{
            ...styleProps,
            color,
          }}
          title={title}
          icon={icon}
          kpiValue={value}
          kpiUnit={unit}
        />
        <div className="ChartCard__container" ref={container}>
          {!loading?.loading && <Sparkline {...chart} {...containerSize} />}
        </div>
      </Component>
      <WidgetLoader {...loading} spinner={false} />
      <WidgetError error={error} />
      {inlineErrors.length > 0 && <InlineErrors errors={inlineErrors} />}
    </SparkleStyled>
  )
}

export default injectIntl(Ui)
