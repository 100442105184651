import {
  configFieldConvertor,
  ghgTrendFieldConvertors,
} from '../../../Dashboard/utils/common/helpers'
import { config as historicalComparisonGraph } from './../HistoricalComparisonGraph/config'

export const config = {
  ...historicalComparisonGraph,
  editProps: {
    ...historicalComparisonGraph.editProps,
    category: 'widget.ghgCharts',
    keywords: [...historicalComparisonGraph.editProps.keywords, 'ghg'],
    default: {
      ...historicalComparisonGraph.editProps.default,
      dataType: 'ghg',
      type: 'GhgHistoricalLineChart',
    },
    fields: configFieldConvertor(
      historicalComparisonGraph.editProps.fields,
      ghgTrendFieldConvertors
    ),
  },
}

export default config
