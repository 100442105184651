import React from 'react'

import { PropTypes } from 'prop-types'
import { injectIntl } from 'react-intl'

import { FilterDropdownStyled } from 'components/Form/components/ValuePicker/styled'
import ValuePicker from 'components/Form/components/ValuePicker/ValuePicker'

const FilterDropdown = ({ intl, menu, filters }) => {
  return (
    <FilterDropdownStyled>
      <div className="FilterDropdown__filters">
        <div className="FilterDropdown__filter-container">
          {filters?.map(({ onChange, options, value }) => (
            <ValuePicker
              onChange={onChange}
              allowClear
              value={value}
              placeholder={intl.formatMessage({ id: 'all' })}
              options={options}
              selectionKeys={null}
              dropdown
            />
          ))}
        </div>
      </div>
      <div className="FilterDropdown__options">{menu}</div>
    </FilterDropdownStyled>
  )
}

FilterDropdown.propTypes = {
  menu: PropTypes.node,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      onChange: PropTypes.func,
      options: PropTypes.arrayOf(PropTypes.node),
    })
  ),
}

FilterDropdown.defaultProps = {}

export default injectIntl(FilterDropdown)
