import { useEffect, useState } from 'react'

import { useNonDatapointEntitiesForCompany } from '../../../../services/entities'
import { useCurrentCustomer } from '../../../../services/store'
import EntityTree from '../../../Dashboard/components/EntityTree'
import { useFilters } from '../../../Form/components/ValuePicker/hooks'

export const useEntitiesFilter = ({ onlyEntityType, tags, entitiesFilter = () => true }) => {
  const customer = useCurrentCustomer()

  const [
    getNonDatapointEntities,
    { data: nonDatapointEntities, loading },
  ] = useNonDatapointEntitiesForCompany()
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [entities, setEntities] = useState([])

  const { onSearch, searchValue, data: filteredEntities } = useFilters(entities, null, {}, 'name')

  useEffect(() => {
    setSelectedEntity(null)
    setSelectedTags([])
  }, [customer])

  useEffect(() => {
    getNonDatapointEntities({
      variables: {
        entityId: selectedEntity?.key || '',
        onlyEntityType,
        tags: [
          ...(tags ? Object.entries(tags).map(([key, value]) => `${key}: ${value}`) : []),
          ...selectedTags.map(({ key, value }) => `${key}: ${value}`),
        ],
      },
    })
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, selectedEntity, onlyEntityType])

  useEffect(() => {
    setEntities(
      nonDatapointEntities
        ? nonDatapointEntities.reduce((datapoints, p) => {
            if (entitiesFilter(p)) {
              datapoints.push({
                id: p.id,
                name: p.name,
                tags: p.tags,
              })
            }
            return datapoints
          }, [])
        : []
    )

    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonDatapointEntities])

  const onSelectTags = (value) => setSelectedTags(value)
  const onSelectEntity = (value) => {
    setSelectedEntity(value)
  }

  return {
    onSelectTags,
    onSelectEntity,
    onSearch,
    datapoints: entities,
    filteredDatapoints: filteredEntities,
    selectedEntity,
    searchValue,
    loading,
    dataSource: {
      id: 'assets',
      treeComponent: EntityTree,
    },
    fromEntityTypes: ['customer', 'site', 'building', 'floor', 'room', 'equip'],
  }
}
