import React, { useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'

import { DASHBOARDS_QUERY } from 'services/dashboards'

import { flattenTree } from '../../EditableTree/utilities'
import { SearchableTree } from '../../Tree'
import DashboardPickerButton from './DashboardPickerButton'
import DashboardPickerStyled from './styled'

const DashboardPicker = ({ value, onChange, singleDatapoint, selected }) => {
  const { data: { sharableDashboards } = {} } = useQuery(DASHBOARDS_QUERY, {
    fetchPolicy: 'network-only',
  })

  const onSelectDashboard = useCallback(
    ({ key, ...item }, type) => {
      return onChange(`/dashboard/${key}`, { ...item, key, url: `/dashboard/${key}` }, type)
    },
    [onChange]
  )

  const treeItemComponentProps = useCallback(
    (item) => {
      const { iconString, icon, title, name, disabled, key } = item
      return {
        icon: iconString || icon,
        label: title || name,
        disabled,
        singleDatapoint,
        variant: 'tag',
        isActive: value.some((dashboard) => dashboard.url === `/dashboard/${key}`),
        isSelected: selected?.raw?.key === key,
        isChildSelected:
          selected?.raw?.children &&
          flattenTree(selected.raw.children).some((dashboard) => dashboard.key === key),
        item,
        onButtonClick: onSelectDashboard,
      }
    },
    [value, onSelectDashboard, selected?.raw, singleDatapoint]
  )

  return (
    <DashboardPickerStyled className="DashboardPicker">
      <SearchableTree
        value={sharableDashboards || []}
        treeItemComponentProps={treeItemComponentProps}
        treeItemComponent={DashboardPickerButton}
        elementHeight={30}
        onButtonClick={onSelectDashboard}
      />
    </DashboardPickerStyled>
  )
}

DashboardPicker.propTypes = {}

DashboardPicker.defaultProps = {
  options: [],
}

export default injectIntl(DashboardPicker)
