import { useMemo } from 'react'

import { queryData } from 'services/data'

export const sortAlphabetically = (valueA, valueB) => {
  switch (typeof valueA) {
    case 'string':
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase())
    default:
      return valueA - valueB
  }
}

export const useDatasourceQuery = (
  query,
  { getVariables, getRowData, onError, onGetRows, skip, queryOptions } = {}
) => {
  return useMemo(
    () => ({
      getRows: async (params) => {
        if (skip) {
          params.success({ rowData: [] })
          return
        }

        if (onGetRows) {
          onGetRows(params)
        }

        const variables =
          typeof getVariables === 'function' ? getVariables(params.request) : undefined

        try {
          const apiRes = await queryData({
            service: 'ghgdataforscope',
            api: 'sust',
            payload: {
              uid: variables.parentEntityId,
              start: variables.startDate,
              end: variables.endDate,
              scopeId: variables.scopeId,
              organisationId: variables.organisationId,
              scope3Subcategory: variables.scope3Subcategory,
            },
          })
            .then(({ data }) => {
              return data
            })
            .catch(() => {
              return {}
            })

          if (apiRes.error) {
            onError({ error: apiRes.error, params })
          }
          const data = { data: { ghgHistorical: apiRes } }
          const rowData = typeof getRowData === 'function' ? getRowData(data, params) : data
          params.success({ rowData })
        } catch (error) {
          if (onError) {
            onError({ error, params })
          } else {
            params.fail()
          }
        }
      },
    }),
    [getVariables, getRowData, onError, onGetRows, skip]
  )
}
