import React from 'react'

import Table from '../../Table'

export const columnsDefinitions = [
  {
    componentName: 'ColorPicker',
    componentProps: {},
    label: { formatted: 'widget.color' },
    dataKey: 'color',
    width: 30,
    defaultValue: '#fdbe10',
  },
  {
    label: { formatted: 'widget.label' },
    dataKey: 'label',
    componentName: 'Input',
    width: 80,
  },
  {
    label: { formatted: 'widget.value' },
    dataKey: 'value',
    componentName: 'Input',
    width: 80,
  },
]

const defaultValues = {
  label: '',
  color: '',
  value: '',
}

const XRangeLineConfigurator = ({ value = [], onChange }) => {
  const data = value.map((line) => ({
    ...line,
    value: line.conditions.map((condition) => condition.value).join(', '),
  }))

  const handleOnChange = (payload) => {
    const newConfig = payload.map(({ value, ...item }) => {
      const conditionValues = value.split(',')
      return {
        ...item,
        conditions: conditionValues.map((conditionValue) => {
          const value = parseInt(conditionValue.trim())

          return {
            value: isNaN(value) ? conditionValue.trim() : value,
            comperator: '=',
          }
        }),
      }
    }, [])

    onChange(newConfig)
  }

  return (
    <Table
      value={data}
      columns={columnsDefinitions}
      onChange={handleOnChange}
      defaultRowValue={defaultValues}
    />
  )
}

export default XRangeLineConfigurator
