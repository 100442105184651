import React, { useEffect, useRef } from 'react'

import { Tooltip } from 'antd'
import ReactDOM from 'react-dom'

import { ResultContent, ResultIcon, ResultItem } from './styled'

export const Item = ({ children, icon, iconInfo, ...props }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (props.selectMode && props.selected) {
      ReactDOM.findDOMNode(itemRef.current).scrollIntoView({ block: 'end', inline: 'nearest' })
    }
  }, [props.selectMode, props.selected])

  return (
    <ResultItem {...props} ref={itemRef}>
      {icon && (
        <Tooltip title={iconInfo} placement="left">
          <ResultIcon icon={icon} />
        </Tooltip>
      )}
      <ResultContent>{children}</ResultContent>
    </ResultItem>
  )
}

export default Item
