import styled from 'styled-components'

const UserBadgeStyled = styled.div`
  margin-left: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-image: url(${(props) => props.avatar});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.color.highlight};
  color: ${({ theme }) => theme.color.highlightText};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`
export default UserBadgeStyled
