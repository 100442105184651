import React from 'react'

import { bool, func, number, object, oneOfType, string } from 'prop-types'

import Icon from '../Icon'
import TextExtended from '../TextExtended'
import { CaptionStyled, Description, Title } from './styled'

const Caption = ({
  styleProps,
  icon,
  iconSize,
  title,
  className,
  kpiValue,
  kpiUnit,
  color,
  description,
  children,
}) => {
  return (
    <CaptionStyled
      {...styleProps}
      className={`Caption ${className || ''} ${title === '-' ? 'no-value' : ''}`}
      color={color}
    >
      {icon && <Icon icon={icon} color={color} size={iconSize} />}
      {title && (
        <Title className="Title">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Title>
      )}
      {typeof kpiValue !== 'undefined' && (
        <TextExtended className="kpi" text={kpiValue} extension={kpiUnit} />
      )}
      {description && (
        <Description className="Description">
          <span dangerouslySetInnerHTML={{ __html: description || title }} />
        </Description>
      )}
      {children}
    </CaptionStyled>
  )
}

Caption.propTypes = {
  icon: string,
  name: string,
  label: oneOfType([string, number]),
  action: object,
  className: string,
  variant: string,
  url: string,
  isActive: bool,
  disabled: bool,
  onClick: func,
}

export default Caption
