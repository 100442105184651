import { css } from 'styled-components'

export const Chart = css`
  padding-top: 0.5rem;
  .ChartDetailCard {
    display: flex;
    margin: 0.5rem 0;
  }
  .ChartDetail__header {
    width: 60%;
    margin-right: auto;
  }
`
