import React, { useContext, useEffect, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { Input, message, Modal } from 'antd'
import jwt from 'jwt-decode'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { WebsocketContext } from 'contexts/WebsocketContext'
import {
  HEYLEN_FIRE_ALERTS,
  useApplicationFeatureAllowed,
} from 'services/accountApplicationFeature'
import { useHasFixedPermissionAccess } from 'services/fixedpermissions'
import { LOGIN_AS_MUTATION, ME_QUERY, QUERY_USERS } from 'services/user'

import ValuePicker from 'components/Form/components/ValuePicker'
import Button from 'components/Global/Button'
import Notifications from 'components/Layout/Notifications/Notifications'

import PageTitle from '../../Layout/PageTitle'
import LanguageSelector from '../LanguageSelector'
import ProfileMenu from '../ProfileMenu'
import TimeSelection from '../TimeSelection'
import { UiContext } from '../UiProvider'
import TopBarStyled from './styled'

function parseUrl(location) {
  const paths = location.pathname.split('/')

  return {
    type: paths[1],
    page: paths[2],
    report: paths[3],
  }
}

const TopBar = ({ location, theme, intl }) => {
  const [userList, setUserList] = useState()
  const [passwordModal, setPasswordModal] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const { subscribeToSquealCount, squealCount } = useContext(WebsocketContext)
  subscribeToSquealCount()
  const [hasAccess] = useHasFixedPermissionAccess()
  const { data: allowed } = useApplicationFeatureAllowed(HEYLEN_FIRE_ALERTS)
  const allowSwitchUser = localStorage.getItem('user') || -1
  const helpDeskInProgress = localStorage.getItem('helpdeskInProgress') || -1
  const flag = allowed
  const { type, page } = parseUrl(location)
  const {
    state: { mainPanel },
  } = useContext(UiContext)
  const mainPanelIsOpen = mainPanel.offset === '380px'

  const squealManager = hasAccess('SQUEAL_MANAGEMENT')

  const [getMyUsers, { data: users }] = useLazyQuery(QUERY_USERS, {
    variables: { aliasId: allowSwitchUser },
  })

  useEffect(() => {
    if (parseFloat(allowSwitchUser) > -1) {
      getMyUsers()
    }
  }, [allowSwitchUser, getMyUsers])

  useEffect(() => {
    if (users?.accounts) {
      setUserList(
        users.accounts.map((user) => ({
          label: user.email,
          value: user.id,
        }))
      )
    }
  }, [users])

  const [loginAction] = useMutation(LOGIN_AS_MUTATION, {
    async update(_, { data: { aliasLogin: login } }) {
      if (login.success) {
        const newToken = jwt(login.message)
        localStorage.setItem('token', login.message)
        if (newToken.id.toString() === localStorage.getItem('user').toString()) {
          localStorage.removeItem('helpdeskInProgress')
        } else {
          localStorage.setItem('helpdeskInProgress', 1)
        }
        window.location.href = '/dashboards'
      } else {
        return message.error(intl.formatMessage({ id: 'general.incorrectPassword' }))
      }
    },
    refetchQueries: [{ query: ME_QUERY }],
  })

  const tryLogin = () => {
    loginAction({ variables: { email, aliasId: parseFloat(allowSwitchUser), password } })
  }

  return (
    <TopBarStyled
      className="TopBar"
      initial={{ left: theme.screenSize.screen.current === 's' ? 0 : mainPanel.offset }}
      animate={{ left: theme.screenSize.screen.current === 's' ? 0 : mainPanel.offset }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      helpdeskMode={helpDeskInProgress !== -1}
    >
      {theme.screenSize.screen.current !== 's' && <PageTitle type={type} page={page} />}
      {type === 'dashboard' && !(mainPanelIsOpen && theme.screenSize.screen.current === 'm') && (
        <TimeSelection screen={theme.screenSize.screen.current} />
      )}
      {allowSwitchUser > -1 && (
        <ValuePicker
          onChange={(id, value) => {
            setEmail(value?.label?.trim())
            setPasswordModal(true)
          }}
          selectionKeys={{
            label: 'label',
            value: 'value',
          }}
          options={userList}
          search
          allowClear={true}
        />
      )}
      {passwordModal && (
        <Modal
          title={intl.formatMessage({ id: 'general.inputOwnPassword' })}
          visible={passwordModal}
          onOk={() => tryLogin()}
          onCancel={() => setPasswordModal(false)}
        >
          <Input.Password
            placeholder="Input password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Modal>
      )}
      {flag && (
        <Button
          name="fire"
          variant="icon"
          icon="fa fa-fire-extinguisher"
          url="/fire-alert-overview"
        />
      )}
      {theme.screenSize.screen.current !== 's' && <Notifications />}
      {squealManager && (
        <Button
          className={`Button ${squealCount > 0 ? 'issues' : ''}`}
          name="squeals"
          icon="icon.squeal"
          url="/squeal-overview"
          variant="squeals"
          label={squealCount > 0 ? squealCount : null}
        />
      )}

      {!(mainPanelIsOpen && theme.screenSize.screen.current === 'm') && <LanguageSelector />}
      {!(mainPanelIsOpen && theme.screenSize.screen.current === 'm') && <ProfileMenu />}
    </TopBarStyled>
  )
}

export default injectIntl(withTheme(withRouter(TopBar)))
