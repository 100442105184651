import React, { useState } from 'react'

import { useQueryData } from '../../../../services/data'
import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { getHeatMapRange } from '../../../Widgets/HeatMap/functions'
import {
  useHeatMapComparatorsPayload,
  useHeatMapGranularity,
  useHeatMapStartEnd,
} from '../../../Widgets/HeatMap/hooks'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import ColorRangeSlider from './ColorRangeSlider'
import { modes } from './constants'
import { useComparatorUnit } from './hooks'

const HeatMapMinMaxColorRangeSlider = ({ onChange, value }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const [mode, setMode] = useState('percentage')

  const {
    datapoints,
    granularity,
    comparators,
    comparatorsCalculationMethod,
    offset,
    startTime,
    endTime,
    comparisonPeriod,
    conversionUnit,
    range,
  } = elementInEditMode.preview

  const comparator = comparators?.[0]
  const datapointComparator =
    (comparator && typeof comparator === 'object') || comparators === 'main'

  const { granularity: detailGranularity, xAxisUnit, yAxisUnit } = useHeatMapGranularity({
    granularity,
  })

  const { start, end } = useHeatMapStartEnd({
    startTime,
    endTime,
    xAxisUnit,
    yAxisUnit,
  })

  const comparatorsPayload = useHeatMapComparatorsPayload({
    datapoints: comparators === 'main' ? datapoints : comparators,
    conversionUnit,
    granularity: detailGranularity,
    comparisonPeriod,
    start,
    end,
    yAxisUnit,
    calculationMethod: comparatorsCalculationMethod,
  })

  const { data } = useQueryData({
    service: 'datapoints',
    payload: { datapoints: comparatorsPayload, widgetType: 'heatMapComparatorData' },
    deps: [startTime, endTime, granularity, comparatorsPayload],
    skip: !datapointComparator,
  })

  const calculatedRange = getHeatMapRange({
    comparators,
    range,
    difference: datapoints[0]?.difference,
    offset,
    data: data?.data?.datapoints,
  })

  const onSelectType = (e, { value }) => {
    setMode(value)
  }

  const unit = useComparatorUnit({
    mode,
    comparators,
    datapoints,
  })

  return (
    <>
      <CheckboxSelector
        className="CheckboxSelector"
        options={modes}
        value={mode}
        onSelect={onSelectType}
      />
      <ColorRangeSlider
        onChange={onChange}
        value={value}
        tickRange={mode === 'percentage' ? [0, 100] : calculatedRange}
        unit={unit}
        mode={mode}
      />
    </>
  )
}

export default HeatMapMinMaxColorRangeSlider
