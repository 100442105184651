import React, { useContext, useEffect, useState } from 'react'

import { AnimatePresence } from 'framer-motion'
import { number } from 'prop-types'

import { useDashboard } from '../../Dashboard/DashboardProvider'
import { UiContext } from '../../Layout/UiProvider'
import LoaderStyled from '../Loader/styled'
import Spinner from '../Spinner'
import RemountOnResizeStyled from './styled'

const RemountOnResize = ({ timeout, children, h, w, rootSectionHeight, remountTrigger }) => {
  const {
    state: {
      mainPanel: { resizeToggle },
    },
  } = useContext(UiContext)
  const {
    state: { elementInEditMode },
  } = useDashboard()
  const [firstMount, setFirstMount] = useState(false)
  const [remount, setRemount] = useState(true)
  const fullscreen = elementInEditMode && elementInEditMode.isFullScreen
  let remountTimeout
  useEffect(() => {
    firstMount && setRemount(false)
    //TODO: check exhaustive-deps problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
    remountTimeout = setTimeout(() => {
      firstMount && setRemount(true)
      !firstMount && setFirstMount(true)
    }, timeout)
    return () => clearTimeout(remountTimeout)
  }, [resizeToggle, h, w, rootSectionHeight, fullscreen, remountTrigger])

  return (
    <AnimatePresence>
      {remount ? (
        <RemountOnResizeStyled
          className="RemountOnResize"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </RemountOnResizeStyled>
      ) : (
        <LoaderStyled
          className="Loader"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          variant="widget"
        >
          <Spinner type="lineScale" color="main" size={20} />
        </LoaderStyled>
      )}
    </AnimatePresence>
  )
}

RemountOnResize.propTypes = {
  timeout: number,
}

RemountOnResize.defaultProps = {
  timeout: 1000,
}

export default RemountOnResize
