import React from 'react'

import { Form } from 'antd'
import { injectIntl } from 'react-intl'

import { AntSelect } from '../AntDesign'

const ManyInputField = ({
  label,
  name,
  intl,
  required,
  customRules = [],
  placeholder,
  maxTagCount,
  maxTagPlaceholder,
}) => {
  const rules = [...customRules]

  if (required) {
    rules.push({
      required: true,
      message: `${label} ${intl.formatMessage({ id: 'form.input.required' })}`,
      type: 'array',
    })
  }

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <AntSelect
        mode="tags"
        showArrow={false}
        dropdownStyle={{ display: 'none' }}
        tokenSeparators={[',', ';', ' ']}
        placeholder={placeholder}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
      />
    </Form.Item>
  )
}

export default injectIntl(ManyInputField)
