import React from 'react'

import FormField from '../index'
import ComparisonPointConfiguratorStyled from './styled'

const ComparisonPointConfigurator = ({ value = {}, onChange }) => {
  const handleChange = ({ value: v, dataKey }) => {
    return onChange && onChange(v, dataKey)
  }

  return (
    <ComparisonPointConfiguratorStyled className="ComparisonPointConfigurator">
      {value.comparators?.length > 0 && (
        <>
          <FormField
            componentName="Switch"
            label={{ formatted: 'widget.percentage' }}
            value={value.percentage}
            dataKey="percentage"
            col="col2 first"
            onChange={({ value }) => handleChange({ value, dataKey: 'percentage' })}
          />
        </>
      )}

      {!value.comparators || value.comparators?.length === 0 ? (
        <FormField
          componentName="NumberRangeInput"
          label={{ formatted: 'widget.minMaxValue' }}
          value={value.range}
          dataKey="range"
          componentProps={{ range: true }}
          onChange={({ value }) => handleChange({ value, dataKey: 'range' })}
        />
      ) : (
        <FormField
          componentName="NumberInput"
          componentProps={{ min: 0 }}
          label={{ formatted: 'widget.offset' }}
          value={value.offset}
          dataKey="offset"
          col="col2"
          onChange={({ value }) => handleChange({ value, dataKey: 'offset' })}
        />
      )}
    </ComparisonPointConfiguratorStyled>
  )
}

export default ComparisonPointConfigurator
