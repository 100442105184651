import React from 'react'

import ValuePickerField from 'components/Form/components/ValuePicker'

const ValuePicker = ({ value, options, onChange, translateLabel, ...props }) => {
  return (
    <ValuePickerField
      value={value}
      options={options}
      selectionKeys={{
        value: 'value',
        label: 'label',
      }}
      onChange={onChange}
      allowClear={false}
      search={!translateLabel}
      {...props}
    />
  )
}

export default ValuePicker
