import React, { useState } from 'react'

import dayjs from 'dayjs'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

import { getGranularityForTimeRange } from 'components/Dashboard/components/TimePicker/functions'
import { useDashboard } from 'components/Dashboard/DashboardProvider'
import CalculationMethod from 'components/Global/FormField/DefaultStartTimeSelector/CalculationMethod'
import FormField from 'components/Global/FormField/index'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import HeatmapIntervalSelector from './HeatmapIntervalSelector'
import RangeSelector from './RangeSelector'
import ReportingYearSelector from './ReportingYearSelector'
import DefaultStartTimeSelectorStyled from './styled'

export const isDatapointComparator = (preview, dataKey) => {
  return (
    preview[dataKey] === 'main' ||
    (preview[dataKey]?.length > 0 && typeof preview[dataKey][0] === 'object')
  )
}

const DefaultStartTimeSelector = ({
  value,
  dataKey: updateDataKey = 'defaultStartTime',
  datapointsDataKey = 'datapoints',
  hideGranularity = true,
  hideCalculationMethod = false,
  hideCalculationUnavailability = false,
  reportingYear = false,
  addRangeMethod,
  period,
  defaultPeriod,
  disableMonthGranularityValue = true,
  heatMapIntervals,
  range,
  hideLabel,
  onChange,
  dataCy = 'DefaultStartTimeSelector',
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} }, data },
  } = useDashboard()

  const isMainDatapoint = datapointsDataKey === 'datapoints'
  const isMainDatapointRef =
    elementInEditMode.preview[datapointsDataKey] === 'main' ||
    (datapointsDataKey === 'maxComparators' && elementInEditMode.preview.minComparators === 'main')
  const selectorDataKey =
    datapointsDataKey === 'maxComparators'
      ? elementInEditMode.preview.maxComparators === 'main' ||
        elementInEditMode.preview.maxComparators?.length > 1
        ? 'maxComparators'
        : 'minComparators'
      : datapointsDataKey

  const method = {
    datapoints: 'method',
    comparators: 'comparatorMethod',
    minComparators: 'minComparatorMethod',
    maxComparators: 'maxComparatorMethod',
  }[selectorDataKey]
  const rangeOptions =
    range ||
    elementInEditMode.preview[selectorDataKey]?.length > 1 ||
    elementInEditMode.preview[method]

  const defaultPeriodValue = ['week', 'month', 'year'].includes(defaultPeriod)
    ? defaultPeriod
    : get(elementInEditMode.preview, defaultPeriod)?.toLowerCase()

  const show =
    isMainDatapoint ||
    isMainDatapointRef ||
    isDatapointComparator(elementInEditMode.preview, selectorDataKey)

  const [presetTime, setPresetTime] = useState(
    value?.[updateDataKey]?.preset ? { name: value?.[updateDataKey]?.preset } : null
  )

  const { startTime, endTime } = value || {}
  const displayStartTime = startTime || value?.[updateDataKey]?.startTime
  const displayEndTime = endTime || value?.[updateDataKey]?.endTime

  const displayValue = displayStartTime && [
    displayStartTime && dayjs(displayStartTime),
    displayEndTime && dayjs(displayEndTime),
  ]

  const formStyle = {
    label: {
      position: 'top',
    },
  }

  const handleGranularityUpdate = (updateValue, granularity, granularityValue = 1) => {
    const { defaultGranularity, defaultGranularityValue } = granularity
      ? { defaultGranularity: granularity, defaultGranularityValue: granularityValue }
      : getGranularityForTimeRange(updateValue, heatMapIntervals)
    onChange(
      defaultGranularityValue,
      heatMapIntervals ? 'heatmapGranularityValue' : 'defaultGranularityValue'
    )
    onChange(defaultGranularity, 'defaultGranularity')
    if (
      value.defaultCalculationMethod === 'raw' &&
      !(defaultGranularity === 'm' && defaultGranularityValue === 1)
    ) {
      onChange('mean', 'defaultCalculationMethod')
    }
    if (value.defaultCalculationMethod === 'range' && defaultGranularity === 'm') {
      onChange('mean', 'defaultCalculationMethod')
    }
  }
  const onPresetChange = ({ value }) => {
    onChange({ preset: value.name }, updateDataKey)
    setPresetTime(value)
    handleGranularityUpdate({ preset: value.name })
  }

  const onDatePickerChange = ({ value }) => {
    if (value.range && value.value[1]) {
      const newValue = { startTime: value.value[0], endTime: value.value[1] }
      onChange(newValue, updateDataKey)
      setPresetTime(null)
      handleGranularityUpdate(newValue)
    }
  }

  const handleChange = (v, dataKey) => {
    if (!dataKey && v.amount === 0 && v.unit === null && v.startOfUnit === null) {
      return
    }
    handleBlur(v, dataKey, updateDataKey)
  }
  const handleBlur = (v, dataKey, fullUpdate) => {
    const newValue = fullUpdate
      ? {
          amount: dataKey === 'amount' ? v : value?.[updateDataKey]?.amount,
          unit: dataKey === 'unit' ? v : value?.[updateDataKey]?.unit,
          duration: dataKey === 'duration' ? v : value?.[updateDataKey]?.duration,
          durationUnit: dataKey === 'durationUnit' ? v : value?.[updateDataKey]?.durationUnit,
          startOfUnit: dataKey === 'startOfUnit' ? v : value?.[updateDataKey]?.startOfUnit,
        }
      : v

    if (fullUpdate) {
      setPresetTime(null)
      handleGranularityUpdate(newValue)
    }
    onChange(newValue, fullUpdate ? updateDataKey : dataKey)
  }

  const handleGranularityChange = ({ value: updateValue, dataKey }) => {
    onChange(updateValue, dataKey)
    if (
      value.defaultCalculationMethod === 'range' &&
      dataKey === 'defaultGranularity' &&
      updateValue === 'm'
    ) {
      onChange('mean', 'defaultCalculationMethod')
    }
  }

  return show ? (
    <DefaultStartTimeSelectorStyled className="DefaultStartTimeSelector">
      {!hideLabel && (
        <FormFieldLabel as="h4">
          <FormattedMessage
            id={
              isMainDatapoint
                ? 'widget.timeRangeDatapoints'
                : 'widget.timeRangeComparisonDatapoints'
            }
          />
        </FormFieldLabel>
      )}
      <FormField
        componentName="DateRangeSelector"
        label={{
          text: 'preset',
          style: formStyle.label,
        }}
        value={presetTime}
        placeholder="Now"
        componentProps={{
          single: !rangeOptions,
          period,
          defaultPeriod: defaultPeriodValue,
          isDetailDashboard: data?.isDetailDashboard,
        }}
        onChange={onPresetChange}
        dataCy={dataCy}
      />
      {rangeOptions && (
        <RangeSelector
          value={value}
          dataKey={updateDataKey}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {reportingYear ? (
        <ReportingYearSelector
          value={value}
          dataKey={updateDataKey}
          onChange={handleChange}
          organisationId={elementInEditMode.preview?.organisationId}
          onGranularityUpdate={handleGranularityUpdate}
          onPresetUpdate={setPresetTime}
        />
      ) : (
        <FormField
          componentName={period ? 'PeriodPicker' : 'DatePicker'}
          componentProps={{
            allowClear: true,
            range: rangeOptions,
            showToday: false,
            showTime: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            disabledDate: period ? null : (current) => current.valueOf() > dayjs().valueOf(),
            defaultPeriod: defaultPeriodValue,
          }}
          value={rangeOptions ? displayValue : displayValue?.startTime}
          label={{
            formatted: rangeOptions
              ? 'graphComponent.presetControl.customPeriod'
              : 'graphComponent.presetControl.customDate',
            style: formStyle.label,
          }}
          placeholder="Now"
          startTime={startTime}
          onChange={onDatePickerChange}
          dataCy={`${dataCy}-durationUnit`}
        />
      )}
      {!hideGranularity && !heatMapIntervals && (
        <FormField
          componentName="GranularitySelector"
          label={{ formatted: 'widget.interval' }}
          componentProps={{
            disableMonthGranularityValue,
            defaultGranularity: value?.defaultGranularity,
            defaultStartTime: value?.defaultStartTime || value?.timeRange,
          }}
          value={value}
          onChange={handleGranularityChange}
          dataCy={dataCy}
        />
      )}
      {heatMapIntervals && (
        <HeatmapIntervalSelector
          value={value}
          disableMonthGranularityValue={disableMonthGranularityValue}
          defaultGranularity={value?.defaultGranularity}
          defaultStartTime={value?.defaultStartTime || value?.timeRange}
          onChange={handleChange}
          onBlur={handleBlur}
          onGranularityChange={handleGranularityChange}
        />
      )}
      {!hideCalculationMethod && (
        <>
          {datapointsDataKey === 'maxComparators' &&
            isDatapointComparator(elementInEditMode.preview, 'minComparators') && (
              <CalculationMethod
                granularity={value?.defaultGranularity}
                granularityValue={value?.defaultGranularityValue}
                datapointsDataKey="minComparators"
                value={value}
                onChange={onChange}
                dataCy={dataCy}
                label="widget.calculationMethodMin"
                hideGranularity={hideGranularity}
                hideUnavailability={hideCalculationUnavailability}
                isMainDatapointRef={isMainDatapointRef}
              />
            )}
          {isDatapointComparator(elementInEditMode.preview, datapointsDataKey) && (
            <CalculationMethod
              granularity={value?.defaultGranularity}
              granularityValue={value?.defaultGranularityValue}
              datapointsDataKey={datapointsDataKey}
              value={value}
              onChange={onChange}
              dataCy={dataCy}
              label={datapointsDataKey === 'maxComparators' && 'widget.calculationMethodMax'}
              hideGranularity={hideGranularity}
              hideUnavailability={hideCalculationUnavailability}
              isMainDatapointRef={isMainDatapointRef}
              addRangeMethod={addRangeMethod}
            />
          )}
        </>
      )}
    </DefaultStartTimeSelectorStyled>
  ) : null
}

export default DefaultStartTimeSelector
