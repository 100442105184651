import { motion } from 'framer-motion'
import styled from 'styled-components'

const RatioBarStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .RatioBar__wrapper {
    width: 15rem;
    height: 0.7rem;
    position: relative;
    display: flex;
    background: ${({ theme, error }) => (error ? theme.color.error : theme.color.softStroke)};
    border-radius: 2rem;
    overflow: hidden;
  }
  .RatioBar__total {
    margin-left: 0.5rem;
  }
`

export const RatioBarSegment = styled.span`
  transition: width 0.2s ease;
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  min-width: ${({ percentage }) => percentage}%;
  background: ${({ color }) => color};
  opacity: ${({ error }) => (error ? 0.2 : 1)};

  &:last-child {
    border-radius: 0 1rem 1rem 0;
  }
`

export const RadioBarSegmentLabel = styled(motion.span)`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 100;
  background-color: ${({ theme }) => theme.color.backgroundFront};
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error : theme.color.softStroke)};
  box-shadow: ${({ theme }) => theme.shadowElevation2()};
  border-radius: 3px;
  padding: 5px;
  width: fit-content;
  color: ${({ theme, error }) => (error ? theme.color.error : theme.color.mainText)};

  .RatioBarSegment__percentage {
    font-weight: 600;
    padding: 0 0.5rem;
  }
  .RatioBarSegment__error {
    max-width: 6rem;
    margin-right: 0.5rem;
  }
`
export default RatioBarStyled
