import React from 'react'

import get from 'lodash/get'

import FormField from '../../../Global/FormField'

export const WidgetConfigurator = ({ value = {}, subPath, widgetConfig, onChange }) => {
  return (
    <div className="WidgetConfigurator">
      {widgetConfig.editProps.fields.map((field) => {
        const fieldValue = field.dataKey ? get(value, field.dataKey) : value
        return field.componentProps?.dataKey === 'datapoints' ||
          field.componentProps?.dataKey === 'dashboardLink' ? null : (
          <FormField {...field} value={fieldValue} subPath={subPath} onChange={onChange} />
        )
      })}
    </div>
  )
}
