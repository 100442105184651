// eslint-disable-next-line simple-import-sort/imports
import { compile } from 'expression-eval'
import { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-clike'

import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { Container, EditorStyled, ValidIcon } from './styled'

const isValid = (code) => {
  try {
    compile(code)
    return true
  } catch (error) {
    return false
  }
}

const CustomLogic = ({ value = '', onChange, defaultValue }) => {
  const [code, setCode] = useState(value)

  useEffect(() => {
    if (typeof value === 'string') {
      setCode(value)
    }
  }, [value])
  const handleBlur = useCallback(() => {
    if (isValid(code)) {
      onChange(code)
    }
  }, [onChange, code])

  const valid = isValid(code)

  const handleChange = (val) => {
    setCode(val)
  }

  return (
    <Container>
      <EditorStyled
        value={code}
        defaultValue={defaultValue}
        onValueChange={handleChange}
        onBlur={handleBlur}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
        }}
      />
      {code && <ValidIcon icon={valid ? 'fas fa-check' : 'fas fa-xmark'} valid={valid} />}
    </Container>
  )
}

CustomLogic.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CustomLogic
