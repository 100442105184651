import styled from 'styled-components'

const ComparatorStyled = styled.div`
  .CheckboxSelector {
    margin-bottom: 1rem;
  }
  .NumberInput {
    width: calc(50% - 0.5rem);
  }
`
export default ComparatorStyled
