import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'

import Table from '../../Table'

const CaptionEditor = ({
  value = [],
  defaultRowValue = {},
  onChange,
  columns = ['value', 'title'],
  objectMode = true,
}) => {
  const theme = useTheme()
  const columnsDefinitions = {
    value: {
      label: { formatted: 'widget.value' },
      dataKey: 'value',
      componentName: 'Input',
      width: 50,
    },
    title: {
      label: { formatted: 'widget.label' },
      dataKey: 'title',
      componentName: 'Input',
      width: 80,
    },
    icon: {
      componentName: 'IconPicker',
      componentProps: {},
      label: { formatted: 'widget.icon' },
      dataKey: 'icon',
      width: 30,
      defaultValue: 'fas fa-info',
    },
    operation: {
      componentName: 'ValuePicker',
      componentProps: {
        options: [
          {
            label: { text: '=' },
            value: '=',
          },
          {
            label: { text: '<' },
            value: '<',
          },
          {
            label: { text: '>' },
            value: '>',
          },
        ],
        selectionKeys: {
          value: 'value',
          label: 'label',
        },
        search: false,
      },
      label: { formatted: 'widget.operator' },
      dataKey: 'operation',
      width: 50,
      defaultValue: '=',
    },
    errorLevel: {
      componentName: 'ValuePicker',
      label: { formatted: 'widget.type' },
      dataKey: 'type',
      width: 40,
      componentProps: {
        options: [
          {
            label: { formatted: 'widget.none' },
            value: 'none',
          },
          {
            label: { formatted: 'widget.inactive' },
            value: 'disabled',
          },
          {
            label: { formatted: 'widget.okValue' },
            value: 'success',
          },
          {
            label: { formatted: 'widget.warning' },
            value: 'warning',
          },
          {
            label: { formatted: 'widget.inProgress' },
            value: 'warning',
          },
          {
            label: { formatted: 'widget.error' },
            value: 'error',
          },
        ],
        selectionKeys: {
          value: 'value',
          label: 'label',
        },
        className: 'error-status',
        defaultValue: 'success',
      },
    },
    color: {
      componentName: 'ColorPicker',
      componentProps: {},
      label: { formatted: 'widget.color' },
      dataKey: 'color',
      width: 60,
      defaultValue: theme.color.main,
    },
  }
  const columnDef = columns.map((col) => columnsDefinitions[col])

  const defaultValues = useMemo(() => {
    return {
      ...columnDef.reduce((result, colmn) => {
        if (colmn.defaultValue !== undefined) {
          result[colmn.dataKey] = colmn.defaultValue
        }
        return result
      }, {}),
      ...defaultRowValue,
    }
  }, [columnDef, defaultRowValue])

  const data = objectMode
    ? Object.entries(value).map(([key, value]) => ({ ...value, value: key }))
    : !Array.isArray(value)
    ? []
    : value

  const _onChange = (payload) => {
    onChange(
      objectMode
        ? payload.reduce((object, { value, ...item }) => {
            object[value] = item
            return object
          }, {})
        : payload
    )
  }

  return (
    <Table value={data} columns={columnDef} onChange={_onChange} defaultRowValue={defaultValues} />
  )
}

export default CaptionEditor
