import styled, { css } from 'styled-components'

const BaseLayoutStyled = styled.div`
  font-family: ${({ theme }) => theme.font.primary};
  background: ${({ theme }) => !theme.darkMode && theme.color.backgroundBack};
  background-image: ${({ theme }) => theme.darkMode && theme.color.backgroundBack};
  color: ${({ theme }) => theme.color.mainText};
  ${({ theme }) =>
    theme.screenSize.screen.current === 's' &&
    css`
      display: flex;
      flex-direction: column-reverse;
      height: 100vh;
    `};
`
export default BaseLayoutStyled
