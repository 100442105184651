import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import HCMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { merge } from 'lodash'
import { useTheme } from 'styled-components'

import {
  getDatapointsCalculatedValue,
  getOverUsePercentageString,
} from 'util/datapointCalculationFunctions'
import { useDatapointsForTimeRangePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/Gauge/Ui'

import { chartOptions } from './../chartOptions'

HCMore(Highcharts)
SolidGauge(Highcharts)

const Consumption = ({
  dataProps = {},
  datapoints = [],
  method = 'sum',
  difference,
  unit,
  colors,
  timeRange,
  comparisonPeriod,
  conversionUnit,
  intl,
  prop,
  preview = { gauge: { series: [] } },
  showDelta = false,
  newSocketValue,
  decimals = 2,
  customLogic,
  defaultCalculationMethod,
  comparatorsCalculationMethod,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps

  const defaultTimeRange = useMemo(() => timeRange || { preset: 'thisMonth' }, [timeRange])
  const defaultComparisonRange = useMemo(() => comparisonPeriod || { preset: 'previousMonth' }, [
    comparisonPeriod,
  ])

  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange: defaultTimeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'consumptionGaugeMain',
    },
    deps: [datapointsPayload],
    skip: datapoints.length === 0,
  })

  const comparatorsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange: defaultComparisonRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: comparatorsData, loading: comparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: comparatorsPayload,
      widgetType: 'consumptionGaugeComparator',
    },
    deps: [comparatorsPayload],
    skip: datapoints.length === 0,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data?.data?.datapoints

  const { series, datapointChartProps, errors } = useMemo(() => {
    const errors = getDatapointErrors(datapoints)
    if (!validData?.length) {
      return isPreviewMode
        ? { series: error ? [] : preview.gauge.series, datapointChartProps: {}, errors }
        : { datapointChartProps: {}, errors }
    }
    const current = getDatapointsCalculatedValue(validData, {
      method,
      customLogic,
      decimals,
      calculationMethod: defaultCalculationMethod,
    })
    const previous = comparatorsData?.data?.datapoints?.length
      ? getDatapointsCalculatedValue(comparatorsData.data.datapoints, {
          method,
          customLogic,
          decimals,
        })
      : 100

    const overusePercentage = getOverUsePercentageString(current, previous, true)
    const displayUnit = validData[0]
      ? validData[0].conversionFactor?.endUnit.name || validData[0].unit
      : ''

    const series = [
      {
        type: 'solidgauge',
        data: [current],
        tooltip: {
          valueSuffix: displayUnit,
        },
        dataLabels: {
          y: overusePercentage.length ? 22 : 10,
          formatter: function () {
            return `<div class="data-labels" style="text-align:center;">
          ${overusePercentage}<br/>
          <span class="value">${localizeNumber(this.y)}</span>
          <span class="unit">${displayUnit}</span>
         </div>`
          },
        },
      },
    ]
    const datapointChartProps = {
      yAxis: {
        min: 0,
        max: previous || 100,
        tickPositions: [0, previous || 0],
        labels: {
          formatter: function () {
            return `${localizeNumber(this.value)} ${displayUnit}`
          },
        },
        stops: colors,
      },
    }
    return { series, datapointChartProps, errors }
  }, [
    validData,
    colors,
    customLogic,
    decimals,
    preview.gauge.series,
    isPreviewMode,
    comparatorsData,
    method,
    error,
    datapoints,
    localizeNumber,
    defaultCalculationMethod,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })

  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <Ui
      {...props}
      className="Consumption"
      options={options}
      loading={{
        loading: loading || comparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default Consumption
