import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { useOrganisationType } from 'services/organisations'

import SelectField from '../../SelectField'

const OrganisationTypeSelectField = ({ onChange, ...props }) => {
  const { data } = useOrganisationType()

  const options = useMemo(
    () =>
      data?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  )

  return <SelectField {...props} options={options} onChange={onChange} />
}

OrganisationTypeSelectField.propTypes = {
  type: PropTypes.string,
}

export default OrganisationTypeSelectField
