import { useCallback } from 'react'

import { useLocaleHook } from 'services/user'

const getTranslation = (translations, locale) => {
  if (!locale) {
    const translation = translations.find(({ language }) => {
      if (language.code) {
        return language.code === 'en-US'
      }
      return language === 'en-US'
    })
    return translation ? translation.translation : undefined
  }

  let translation = translations.find(({ language }) => {
    if (language.code) {
      return language.code === locale
    }
    return language === locale
  })
  if (translation && translation.translation) {
    return translation.translation
  }

  translation = translations.find(({ language }) => {
    if (language.code) {
      return language.code === 'en-US'
    }
    return language === 'en-US'
  })
  return translation ? translation.translation : undefined
}

export default getTranslation

export const useTranslationFromObject = () => {
  const locale = useLocaleHook()

  return useCallback(
    (data) => {
      return (data?.[locale] ?? data?.['en-US']) || null
    },
    [locale]
  )
}
