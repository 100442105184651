import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import HCMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  getComparatorCalculatedValue,
  getDataForEmissionFactor,
  getDatapointsCalculatedValue,
  getOverUsePercentageString,
} from 'util/datapointCalculationFunctions'
import { useLocaleNumber } from 'util/numbers'
import { useGhgData } from 'services/entities'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { useComparatorValues } from 'components/Global/FormField/Comparator'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/Gauge/Ui'

import { getGranularity } from '../../../../Dashboard/utils/common/helpers'
import { chartOptions } from '../chartOptions'

HCMore(Highcharts)
SolidGauge(Highcharts)

const GhgGauge = ({
  datapoints,
  emissionFactor = 'total',
  minComparators,
  maxComparators,
  decimals = 2,
  colors,
  method = 'sum',
  minComparatorsMethod,
  maxComparatorsMethod,
  difference,
  timeRange,
  comparisonPeriod,
  dataProps = {},
  preview = { gauge: { series: [] } },
  newSocketValue,
  customLogic,
  returnUnitId,
  defaultCalculationMethod,
  minComparatorsCalculationMethod,
  maxComparatorsCalculationMethod,
  intl,
  overUse = false,
  showDelta = false,
  linkProps,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps
  const { data, error, refetch, loading } = useGhgData({
    datapoints,
    emissionFactor,
    timeRange,
    returnUnitId,
    granularity: getGranularity({
      defaultStartTime: timeRange || { preset: 'past7Days' },
      type: 'ghg',
    }),
    defaultTimeRange: { preset: 'past7Days' },
    options: {
      poll: true,
    },
    calculationMethod: defaultCalculationMethod,
    widgetType: 'ghgGauge',
  })
  const { data: minComparatorsData, loading: minComparatorsLoading } = useComparatorValues({
    comparator: minComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor,
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: minComparatorsCalculationMethod,
    widgetType: 'ghgGaugeMinComparator',
  })
  const { data: maxComparatorsData, loading: maxComparatorsLoading } = useComparatorValues({
    comparator: maxComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor,
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: maxComparatorsCalculationMethod,
    widgetType: 'ghgAbsoluteMaxComparator',
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const roundedMinimum = getComparatorCalculatedValue(
    getDataForEmissionFactor(minComparatorsData, emissionFactor),
    {
      method: minComparatorsMethod,
      customLogic,
      decimals,
    }
  )

  const roundedMaximum = getComparatorCalculatedValue(
    getDataForEmissionFactor(maxComparatorsData, emissionFactor),
    {
      method: maxComparatorsMethod,
      customLogic,
      decimals,
    }
  )

  const validData = data
  const overusePercentage = getOverUsePercentageString(validData, maxComparatorsData[0], overUse)

  const { series, datapointChartProps, errors } = useMemo(() => {
    if (!validData?.length) {
      return isPreviewMode
        ? { series: preview.gauge.series, datapointChartProps: {}, errors: [] }
        : { datapointChartProps: {}, errors: [] }
    }
    const errors = getDatapointErrors(validData)
    const value = getDatapointsCalculatedValue(
      getDataForEmissionFactor(validData, emissionFactor),
      { method, customLogic, decimals }
    )

    const displayUnit = validData[0]?.returnUnit?.name || 'kgCO2e'
    const series = [
      {
        type: 'solidgauge',
        data: [value],
        tooltip: {
          valueSuffix: displayUnit,
        },
        dataLabels: {
          y: overusePercentage.length ? 22 : 10,
          formatter: function () {
            return `<div class="data-labels" style="text-align:center;">
          ${overusePercentage}<br/>
          <span class="value">${localizeNumber(this.y) || 0}</span>
          <span class="unit">${displayUnit}</span>
         </div>`
          },
        },
      },
    ]
    const datapointChartProps = {
      yAxis: {
        min: roundedMinimum || 0,
        max: roundedMaximum || 100,
        tickPositions: [roundedMinimum, roundedMaximum || 0],
        labels: {
          formatter: function () {
            return `${localizeNumber(this.value) || 0} ${displayUnit}`
          },
        },
        stops: colors,
      },
    }
    return { series, datapointChartProps, errors }
  }, [
    validData,
    roundedMinimum,
    roundedMaximum,
    overusePercentage,
    colors,
    customLogic,
    decimals,
    preview.gauge.series,
    isPreviewMode,
    method,
    emissionFactor,
    localizeNumber,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })

  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <Ui
      {...props}
      className="SolidGauge"
      options={options}
      loading={{
        loading: loading || minComparatorsLoading || maxComparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(GhgGauge)
