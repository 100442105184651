import React, { useEffect, useState } from 'react'

import NumberInputStyled from './styled'

const NumberInput = ({ value, min, max, onChange, onBlur, placeholder, viewRender, ...props }) => {
  const [inputValue, setInputValue] = useState(null)

  useEffect(() => {
    setInputValue(typeof value === 'number' ? value : null)
  }, [value])

  const _onChange = (value) => {
    setInputValue(value)
    onChange && onChange(value * 1)
  }
  const _onBlur = () => {
    setInputValue(inputValue)
    onBlur && onBlur(inputValue)
  }

  return (
    <NumberInputStyled
      {...props}
      className="NumberInput"
      value={inputValue}
      min={min}
      max={max}
      onBlur={_onBlur}
      onChange={_onChange}
      placeholder={placeholder}
    />
  )
}

export default NumberInput
