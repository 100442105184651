import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { FieldStyled, Value } from 'components/Form/components/Field/styled'

export const FileClassificationFieldStyled = styled(Field)`
  ${Value} {
    .FileClassificationField__group {
      display: flex;
    }
  }
  ${FieldStyled} {
    padding: 0;
  }
`
