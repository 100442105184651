import styled from 'styled-components'

import { Connectivity } from './Connectivity'

const variants = {
  Connectivity,
}

const FlexContainerStyled = styled.div`
  display: flex;
  ${(props) => variants[props.variant]}
  ${(props) => variants[props.type]}
`
export default FlexContainerStyled
