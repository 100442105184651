import styled from 'styled-components'

import SwitchStyled from 'components/Global/Switch/styled'

const MenuItem = styled.div`
  display: flex;
  i,
  svg {
    margin-right: 0.7rem;
  }

  color: ${({ theme }) => theme.color.mainText};
  &:hover {
    color: ${({ theme }) => theme.color.strongText};
  }
  ${SwitchStyled} {
    margin-left: auto;
    margin-right: 0;
  }

  .Switch {
    padding-left: 1rem;
  }
`
export default MenuItem
