import React, { useEffect, useRef, useState } from 'react'

import { useLocaleHook } from 'services/user'

import { InputNumberStyled, Suffix, SuffixWrapper } from './styled'

const separators = {
  'en-US': '.',
  'nl-NL': ',',
  'fr-FR': ',',
}

const NumberInput = ({
  onChange,
  value,
  step = '1',
  suffix,
  className,
  error,
  min,
  max,
  ...props
}) => {
  const locale = useLocaleHook()
  const suffixRef = useRef()
  const [suffixWidth, setSuffixWidth] = useState(10)

  useEffect(() => {
    if (suffixRef.current?.clientWidth) {
      setSuffixWidth(suffixRef.current.clientWidth)
    }
  }, [])

  const handleOnChange = (value) => {
    const parsedValue =
      typeof value === 'string' ? parseFloat(value.replace(separators[locale], '.')) : value
    onChange(parsedValue < min ? min : parsedValue > max ? max : parsedValue)
  }

  const field = (
    <InputNumberStyled
      onChange={handleOnChange}
      value={value}
      className={`${!suffix && className} ${error !== undefined && 'error'}`}
      {...props}
      min={min}
      max={max}
      step={step}
      stringMode={true}
      decimalSeparator={separators[locale]}
    />
  )

  if (suffix) {
    return (
      <SuffixWrapper suffixWidth={suffixWidth} className={className}>
        {field}
        {suffix ? <Suffix ref={suffixRef}>{suffix}</Suffix> : null}
      </SuffixWrapper>
    )
  }

  return field
}

export default NumberInput
