import React from 'react'

import { injectIntl } from 'react-intl'

import ValuePicker from 'components/Form/components/ValuePicker'

import { granularityUnits } from '../../../Dashboard/Widget/config/selections'
import { MultiFieldContainer } from '../FormFieldElement/styled'
import FormField from '../index'
import NumberInput from '../NumberInput'
import { FormFieldLabel } from '../styled'
import RawDataConfiguratorStyled from './styled'

const RawDataConfigurator = ({
  value = {
    defaultRawData: false,
    hideRawData: false,
    defaultGranularityValue: 0,
    defaultGranularity: 's',
  },
  hideRawDataConfig = false,
  onChange,
  intl,
}) => {
  // // TODO: causes a loop
  // useEffect(() => {
  //   if ((value.defaultRawData || !value.hideRawData) && !value.defaultGranularity) {
  //     onChange && onChange('m', 'defaultGranularity')
  //     onChange && onChange(30, 'defaultGranularityValue')
  //   }
  // }, [value])

  const handleChange = ({ value: v, dataKey }) => {
    onChange && onChange(v, dataKey)
  }
  const handleBlur = ({ value: v, dataKey }) => {
    onChange && onChange(v, dataKey)
  }

  return (
    <RawDataConfiguratorStyled className="RawDataConfigurator">
      {!hideRawDataConfig && (
        <>
          <FormField
            componentName="Switch"
            label={{ formatted: 'widget.hideRawData' }}
            value={!value.hideRawData}
            dataKey="hideRawData"
            col={`${!value.hideRawData ? 'col2 first' : ''}`}
            onChange={({ value }) => handleChange({ value: !value, dataKey: 'hideRawData' })}
          />
          {!value.hideRawData && (
            <FormField
              componentName="Switch"
              label={{ formatted: 'widget.showRawDataByDefault' }}
              value={value.defaultRawData}
              dataKey="defaultRawData"
              col="col2"
              onChange={({ value }) => handleChange({ value, dataKey: 'defaultRawData' })}
            />
          )}
        </>
      )}
      {!(!value.hideRawData && value.defaultRawData) && (
        <>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.granularity' })}</FormFieldLabel>
          <MultiFieldContainer className="MultiFieldContainer">
            <NumberInput
              className="NumberInput"
              value={value.defaultGranularityValue}
              min={0}
              onBlur={(value) => handleBlur({ value, dataKey: 'defaultGranularityValue' })}
            />
            <ValuePicker
              value={value.defaultGranularity}
              options={granularityUnits}
              onChange={(value) => handleChange({ value, dataKey: 'defaultGranularity' })}
              selectionKeys={{
                label: 'label',
                value: 'value',
              }}
            />
          </MultiFieldContainer>
        </>
      )}
    </RawDataConfiguratorStyled>
  )
}

export default injectIntl(RawDataConfigurator)
