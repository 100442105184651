import React from 'react'

import { useEquipDatapointMap, usePresetsWithDatapoints } from '../EquipmentSelector/hooks'
import FormField from '../index'

const EquipmentDatapoints = ({
  equipments,
  equipmentTemplate,
  detailTemplate,
  matrixDetailGraph,
  onChange,
}) => {
  const { data } = usePresetsWithDatapoints(equipmentTemplate?.presetId)
  const equipDatapointMap = useEquipDatapointMap(data?.entityPreset)
  return (
    <FormField
      value={equipments}
      dataKey="showUnit"
      label={{ formatted: 'widget.preset' }}
      componentName="EquipmentPresetDatapointSelector"
      componentProps={{
        graph: {
          path: matrixDetailGraph?.path?.replace('root.elements[0]', 'detail'),
          dataKey: 'datapoints[0]',
        },
        equipDatapointMap,
        equipments,
        template: detailTemplate,
      }}
      col="col1"
      onChange={({ value, dataKey }) => {
        if (dataKey === 'template') {
          onChange(value, 'detailTemplate', { rootUpdate: true })
        } else {
          onChange(value, dataKey, { rootUpdate: true })
        }
      }}
    />
  )
}

export default EquipmentDatapoints
