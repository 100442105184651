import MobileCombustionTree from 'components/Dashboard/components/MobileCombustionTree/MobileCombustionTree'
import StationaryCombustionTree from 'components/Dashboard/components/StationaryCombustionTree/StationaryCombustionTree'
import UpstreamDownstreamEntityTypeTree from 'components/Dashboard/components/UpstreamDownstreamEntityTypeTree'

export const scopeConfig = {
  0: {
    query: null,
    treeComponent: null,
    defaultEmissions: ['total'],
  },
  1: {
    query: 'getStationaryCombustionMeters',
    treeComponent: StationaryCombustionTree,
    defaultEmissions: ['total'],
  },
  3: {
    query: 'getMobileCombustionVehicles',
    treeComponent: MobileCombustionTree,
    defaultEmissions: ['total'],
  },
  4: {
    query: 'getRefrigerantEquipments',
    treeComponent: StationaryCombustionTree,
    defaultEmissions: ['total'],
  },
  5: {
    query: null,
    treeComponent: null,
    defaultEmissions: ['total'],
  },
  2: {
    query: 'getPurchasedEnergyMeters',
    treeComponent: StationaryCombustionTree,
    defaultEmissions: ['total'],
  },
  6: {
    id: 'upstreamDownstream',
    query: 'getNonAssetEntities',
    name: 'Upstream & Downstream Activities',
    treeComponent: UpstreamDownstreamEntityTypeTree,
  },
}

export const cat3Config = {
  upstreamPurchasedEnergyFuels: {
    id: 'upstreamPurchasedEnergyFuels',
    query: 'getPurchasedEnergyMeters',
    treeComponent: StationaryCombustionTree,
    defaultEmissions: ['total'],
    scopeId: '1',
  },
  upstreamPurchasedVehicleFuels: {
    id: 'upstreamPurchasedVehicleFuels',
    query: 'getMobileCombustionVehicles',
    treeComponent: MobileCombustionTree,
    defaultEmissions: ['total'],
    scopeId: '3',
  },
  upstreamPurchasedEnergy: {
    id: 'upstreamPurchasedEnergy',
    query: 'getPurchasedEnergyMeters',
    treeComponent: StationaryCombustionTree,
    defaultEmissions: ['total'],
    scopeId: '2',
  },
  tndLosses: {
    id: 'tndLosses',
    query: 'getPurchasedEnergyMeters',
    name: 'Upstream & Downstream Activities',
    treeComponent: StationaryCombustionTree,
    scopeId: '2',
  },
}
