import React from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import Table from 'components/Global/Table'

import { EditableTableFieldStyled } from './styled'

const EditableTableField = ({
  value,
  name,
  filter,
  dependencies,
  columns = [{}],
  defaultRowValue,
  onChange,
  onRowActionClick,
  disableAdd,
  ...fieldProps
}) => {
  return (
    <EditableTableFieldStyled {...fieldProps}>
      {({ watch, loading, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }

        const filterFn = ({ item }) => {
          if (!filter) {
            return true
          }
          return filter(item, { watch })
        }

        const tableProps = {
          columns,
          defaultRowValue,
          readOnly: !isEditMode,
          disableAdd,
        }

        return name ? (
          <Controller
            name={name}
            render={({ onChange: fromFieldOnChange, value: controllerValue }) => {
              const handleChange = (value) => {
                if (name) {
                  fromFieldOnChange(value)
                }
                onChange && onChange(value)
              }
              const renderValue =
                value === undefined && name === undefined ? value : controllerValue
              const list =
                typeof renderValue?.filter === 'function' ? renderValue.filter(filterFn) : undefined

              return <Table {...tableProps} value={list} onChange={handleChange} />
            }}
          />
        ) : (
          <Table {...tableProps} onChange={onChange} value={value} />
        )
      }}
    </EditableTableFieldStyled>
  )
}

EditableTableField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
}

export default EditableTableField
