import React from 'react'

import { Layout } from 'antd'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'

import CookieBanner from 'components/Layout/CookieBanner'
import CookieBannerProvider from 'components/Layout/CookieBanner/CookieBannerProvider'

import { Container, Content, Footer, Header, Links, LoginWindow, Logo } from './loginLayout.styled'

const year = new Date().getFullYear()

const LoginLayout = ({ children, theme, intl }) => {
  return (
    <CookieBannerProvider>
      <LoginWindow>
        <Layout.Content>
          <Container>
            <Header>
              <Logo>
                <a href="https://www.brightcanary.eu" target="_blank" rel="noopener noreferrer">
                  <img
                    src={theme.images?.logo || '/resources/images/logo.svg'}
                    alt="Canary - data-driven sustainability"
                  />
                </a>
              </Logo>
            </Header>
            <Content>{children}</Content>
            <Footer>
              <Links>
                <span>
                  <Link to={'/user/login/cookies'}>
                    {intl.formatMessage({ id: 'title.cookies' })}
                  </Link>
                </span>
                <span>
                  <Link to={'/user/login/terms-and-conditions'}>
                    {intl.formatMessage({ id: 'termsAndConditions.title' })}
                  </Link>
                </span>
                <span>
                  <Link exact to={'/user/login/confidential-information'}>
                    {intl.formatMessage({ id: 'title.confidential.informationShort' })}
                  </Link>
                </span>
                <span>
                  <a
                    href="https://brightcanary.freshdesk.com/support/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'support.title' })}
                  </a>
                </span>
                <span>
                  <Link exact to={'/user/login/contact'}>
                    {intl.formatMessage({ id: 'contact.title' })}
                  </Link>
                </span>
                <span>
                  <a
                    href="https://status.iot.canary.expert"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'status' })}
                  </a>
                </span>
              </Links>
              <p>&copy; 2019 - {year} Canary bv. All rights reserved.</p>
            </Footer>
          </Container>
        </Layout.Content>
      </LoginWindow>
      <CookieBanner />
    </CookieBannerProvider>
  )
}

export default injectIntl(withTheme(LoginLayout))
