import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

export const EditableTableFieldStyled = styled(Field)`
  padding: 0.5rem 0;
  width: 100%;
  flex-direction: column;
  align-items: flex-start !important;

  ${Value} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;

    .Table {
      width: 100%;
      text-align: left;
      opacity: ${({ disabled }) => disabled && 0.5};

      input {
        text-align: left;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .FormFieldElement {
        &.Text,
        &.Tooltip > .Button {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
        font-weight: 100;
      }

      .table-head {
        background: ${({ theme }) => lighten(0.02, theme.color.softerStroke)};
        border-bottom-width: 1px;

        .FormFieldLabel {
          text-transform: none;
          font-size: 1rem;
          padding: 0.5rem 1.5rem;
          margin: 0;
          font-weight: 100;
        }
        .ButtonList {
          background: none;
        }
      }
      ${({ disabled }) => {
        if (disabled) {
          return css`
            .Button {
              cursor: not-allowed;
            }
          `
        }
      }}
    }
  }
`
