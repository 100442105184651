import React from 'react'

import { get, sortBy, toLower } from 'lodash'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { Field } from 'components/Form'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'

const EntityPresetSelectField = ({
  children,
  name,
  label,
  defaultValue,
  value = {},
  validation,
  checked,
  disabled,
  disableClick,
  type,
  onChange,
  autoSelectOnSingleItem,
  disableOnSingleItem = false,
  intl,
  placeholder,
  ...props
}) => {
  return (
    <Field {...props} label={label}>
      {({ control, isEditMode, errors, loading }) => {
        const error = get(errors, name)
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              if (loading) {
                return <Skeleton width="200px" />
              }

              const handleOnChange = (payload) => {
                fromFieldOnChange(payload)
                onChange && onChange(payload)
              }

              if (isEditMode) {
                return (
                  <>
                    <ValuePicker
                      value={formFieldValue?.id}
                      placeholder={placeholder}
                      query={{
                        name: 'ENTITY_PRESET_LIST_LANGUAGE',
                        dataKey: 'entityPresets',
                        variables: { type, language: intl.locale },
                      }}
                      selectionKeys={{ value: 'id', label: 'name' }}
                      onChange={handleOnChange}
                      search
                      error={error}
                      onOptionsLoaded={(options) => {
                        if (autoSelectOnSingleItem && options && options.length === 1) {
                          handleOnChange(options[0].id)
                        }
                        return sortBy(options, [(option) => toLower(option.name)])
                      }}
                      disableOnSingleItem={disableOnSingleItem}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              return <span>{formFieldValue?.name}</span>
            }}
          />
        )
      }}
    </Field>
  )
}

EntityPresetSelectField.propTypes = {
  disableOnSingleItem: PropTypes.bool,
}

export default injectIntl(EntityPresetSelectField)
