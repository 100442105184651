import React from 'react'

import { Controller } from 'react-hook-form'

import ListFieldStyled from 'components/Form/fields/ListField/styled'
import List from 'components/Layout/List'

const ListField = ({
  children,
  name,
  defaultValue,
  value = [],
  disabled,
  disableClick,
  onChange,
  onClick,
  validation,
  showRequired,
  uncontrolled = false,
  height,
  ...props
}) => {
  return (
    <ListFieldStyled height={height}>
      {({ control, isEditMode, setValue }) =>
        uncontrolled || !name ? (
          <List data={value} {...props}>
            {typeof children === 'function' ? children({ isEditMode, setValue }) : children}
          </List>
        ) : (
          <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={defaultValue}
            render={({ value: formFieldValue }) => {
              return (
                <List data={formFieldValue} {...props}>
                  {typeof children === 'function'
                    ? children({
                        isEditMode,
                        value: formFieldValue,
                        setValue,
                        name,
                      })
                    : children}
                </List>
              )
            }}
          />
        )
      }
    </ListFieldStyled>
  )
}

export default ListField
