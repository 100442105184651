import styled from 'styled-components'

const ValueTypeSelectorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .Input {
    width: 100%;
  }
  > .FormField.Switch {
    width: 50%;
    margin-bottom: 0;
  }
  .ValuePicker {
    width: 100%;
  }
`
export default ValueTypeSelectorStyled
