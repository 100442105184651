import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

export const MarkerListFieldStyled = styled(Field)`
  padding: 0.5rem;
  flex-direction: column;

  ${Value} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
`
