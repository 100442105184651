import arrayMove from 'array-move'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import uniqid from 'uniqid'

import { widgetConfig } from '../Widget/config'
import { sectionTemplates, widgetTemplates } from '../Widget/config/templates'
import {
  alignEquipmentLayoutWithTemplate,
  getAddedSubSectionElements,
  getCurrentValue,
  getEquipmentsLayoutForTemplate,
  getGraphDataKey,
  updateMatrixElementsArray,
} from './functions'

export const initialState = {
  data: { layout: { sections: [] } },
  history: {},
  clipboard: {},
  newSectionIndex: null,
  editModeContext: null,
  templates: {
    widgets: widgetTemplates,
    sections: sectionTemplates,
  },
  drawer: {
    isOpen: false,
    sections: [],
  },
  elementInEditMode: null,
  bottomDrawer: {
    isOpen: false,
    children: null,
    component: null,
    componentProps: null,
  },
  placeholder: {
    type: 'Placeholder',
    w: 12,
    h: 100,
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload.permissions,
        },
      }
    case 'SHOW_WIDGET_DETAIL':
      return {
        ...state,
        widgetDetail: action.payload,
      }
    case 'CLOSE_WIDGET_DETAIL':
      return {
        ...state,
        widgetDetail: null,
      }
    case 'SET_ADVANCED_MODE':
      return {
        ...state,
        advanced: action.payload.value,
      }
    case 'UPDATE_UI':
      return {
        ...state,
        newSectionIndex: action.payload.newSectionIndex ?? state.newSectionIndex,
        data:
          state.editModeContext === 'Matrix' && !action.payload.rootUpdate
            ? state.data
            : {
                ...state.data,
                layout: action.payload.value,
              },
        elementInEditMode:
          state.editModeContext === 'Matrix' && !action.payload.rootUpdate
            ? {
                ...state.elementInEditMode,
                preview: {
                  ...state.elementInEditMode.preview,
                  detailTemplate: action.payload.value.sections[0],
                  equipments: action.payload.layoutUpdate
                    ? alignEquipmentLayoutWithTemplate(
                        state.elementInEditMode.preview.equipments,
                        action.payload.layoutUpdate
                      )
                    : state.elementInEditMode.preview.equipments,
                },
              }
            : state.elementInEditMode,
      }

    case 'SET_WIDGET_EDIT_MODE': {
      const isMatrixWidgetUpdate =
        action.payload.source === 'Matrix' || state.editModeContext === 'Matrix'
      const preview = isMatrixWidgetUpdate
        ? state.elementInEditMode.preview
        : get(state.data.layout, getGraphDataKey(action.payload.graph.path))
      const widget = widgetConfig({ type: action.payload.value }).editProps
      return {
        ...state,
        editModeContext: action.payload.value === 'Matrix' ? 'Matrix' : action.payload.source,
        elementInEditMode: {
          show: true,
          graph: isMatrixWidgetUpdate ? state.elementInEditMode.graph : action.payload.graph,
          matrixDetailGraph: isMatrixWidgetUpdate ? action.payload.graph : null,
          preview,
          type: action.payload.value,
          info: widget.info,
          selectedDatapointMap: {},
          containerHeight:
            state.elementInEditMode?.containerHeight || action.payload.elementRef.clientHeight,
        },
        drawer: {
          isOpen: true,
          sections: [
            {
              isOpen: false,
              name: 'fullScreen',
              width: '0px',
              drawerSelector: {
                icon: 'fas fa-expand',
                variant: 'roundedButton',
              },
            },
            ...(state.isDataEntryDashboard
              ? action.payload.value === 'Placeholder'
                ? [
                    {
                      isOpen: true,
                      name: 'elementSelector',
                      width: '420px',
                      drawerSelector: {
                        icon: 'fas fa-chart-area',
                        variant: 'roundedButton',
                      },
                      components: [
                        {
                          componentName: 'WidgetList',
                          componentProps: {},
                        },
                      ],
                    },
                  ]
                : []
              : [
                  {
                    isOpen: action.payload.value === 'Placeholder',
                    name: 'elementSelector',
                    width: '420px',
                    drawerSelector: {
                      icon: 'fas fa-chart-area',
                      variant: 'roundedButton',
                    },
                    components: [
                      {
                        componentName: 'WidgetList',
                        componentProps: {},
                      },
                    ],
                  },
                  {
                    isOpen: false,
                    disabled: !state.permissions?.hasSustainabilityAccess,
                    name: 'sdgElementSelector',
                    width: '420px',
                    drawerSelector: {
                      icon: 'fas fa-leaf',
                      variant: 'roundedButton',
                    },
                    components: [
                      {
                        componentName: 'SdgWidgetList',
                        componentProps: {},
                      },
                    ],
                  },
                ]),
            {
              isOpen: action.payload.value !== 'Placeholder',
              name: 'widgetEditor',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-gears',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'WidgetConfigurator',
                  componentProps: {},
                },
              ],
            },
            {
              isOpen: false,
              name: 'info',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-info',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'WidgetInfo',
                  componentProps: {},
                },
              ],
            },
          ],
        },
      }
    }

    case 'SET_MATRIX_WIDGET_SETTINGS': {
      return {
        ...state,
        drawer: {
          isOpen: true,
          sections: [
            {
              isOpen: false,
              name: 'fullScreen',
              width: '0px',
              drawerSelector: {
                icon: 'fas fa-expand',
                variant: 'roundedButton',
              },
            },
            {
              isOpen: false,
              name: 'elementSelector',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-chart-area',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'WidgetList',
                  componentProps: {},
                },
              ],
            },
            {
              isOpen: false,
              disabled: !state.permissions?.hasSustainabilityAccess,
              name: 'sdgElementSelector',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-leaf',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'SdgWidgetList',
                  componentProps: {},
                },
              ],
            },
            {
              isOpen: true,
              name: 'widgetEditor',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-gears',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'WidgetConfigurator',
                  componentProps: {},
                },
              ],
            },
            {
              isOpen: false,
              name: 'info',
              width: '420px',
              drawerSelector: {
                icon: 'fas fa-info',
                variant: 'roundedButton',
              },
              components: [
                {
                  componentName: 'WidgetInfo',
                  componentProps: {},
                },
              ],
            },
          ],
        },
      }
    }

    case 'CANCEL_WIDGET_EDIT_MODE':
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isOpen: false,
          sections: [],
        },
        elementInEditMode: {
          ...state.elementInEditMode,
          show: false,
          containerHeight: null,
        },
        editModeContext: null,
      }

    case 'SELECT_WIDGET':
      const widget = widgetConfig({ type: action.payload.value }).editProps
      const currentWidget = state.elementInEditMode.preview
      const widgetProperties =
        currentWidget.dataType === widget.default.dataType
          ? Object.entries(widget.default).reduce(
              (widgetProperties, [property, value]) => {
                if (property !== 'type') {
                  const mappedProperty = {
                    defaultStartTime: 'timeRange',
                    timeRange: 'defaultStartTime',
                    comparators: 'maxComparators',
                    maxComparators: 'comparators',
                    maxComparatorsMethod: 'comparatorsMethod',
                    comparatorsMethod: 'maxComparatorsMethod',
                  }[property]
                  let propertyValue =
                    currentWidget[property] || currentWidget[mappedProperty] || value

                  if (
                    currentWidget[property] &&
                    [
                      'datapoints',
                      'datapointsGroups',
                      'comparators',
                      'maxComparators',
                      'minComparators',
                    ].includes(property)
                  ) {
                    const singleDatapointWidget = widget.fields.some(
                      ({ componentProps }) =>
                        componentProps &&
                        componentProps.dataKey === property &&
                        componentProps.singleDatapoint
                    )

                    if (typeof currentWidget[property] === 'string') {
                      // comparators can have string value eg. 'main'
                      propertyValue = currentWidget[property]
                    } else if (currentWidget.type === 'Image' || widget.default.type === 'Image') {
                      propertyValue = currentWidget[property].map(
                        ({ id, label, name, caption }) => ({
                          id,
                          name,
                          ...(widget.default.type === 'Image'
                            ? { label: caption, x: 0, y: 0 }
                            : { caption: label }),
                        })
                      )
                    } else {
                      propertyValue = currentWidget[property].reduce((items, item, index) => {
                        if (!singleDatapointWidget || index === 0) {
                          items.push(
                            Object.entries(item).reduce((item, [key, value]) => {
                              if (!widget.unUsedDatapointProperties?.includes(key)) {
                                item[key] = value
                              }
                              return item
                            }, {})
                          )
                        }
                        return items
                      }, [])
                    }
                  }
                  if (property === 'title') {
                    const currentWidgetDefaultTitle = widgetConfig(currentWidget).editProps.default
                      .title
                    const previousTitleIsDefault =
                      currentWidget.title ===
                      (currentWidgetDefaultTitle &&
                        action.payload.intl.formatMessage({
                          id: currentWidgetDefaultTitle,
                        }))
                    propertyValue =
                      previousTitleIsDefault || !currentWidget.title
                        ? action.payload.intl.formatMessage({ id: widget.default.title })
                        : currentWidget.title
                  }
                  widgetProperties[property] = propertyValue
                }
                return widgetProperties
              },
              { type: action.payload.value }
            )
          : {
              ...widget.default,
              title: action.payload.intl.formatMessage({ id: widget.default.title }),
            }

      const isMatrixContext =
        state.editModeContext === 'Matrix' && state.elementInEditMode.matrixDetailGraph
      const matrixDettailGraphPath =
        isMatrixContext &&
        state.elementInEditMode.matrixDetailGraph.path.replace('root.elements[0].', '')

      const { w, h } = isMatrixContext
        ? get(state.elementInEditMode.preview.detailTemplate, matrixDettailGraphPath)
        : state.elementInEditMode?.preview || widget

      return {
        ...state,
        elementInEditMode: isMatrixContext
          ? set(
              `preview.detailTemplate.${matrixDettailGraphPath}`,
              {
                ...widgetProperties,
                w,
                h,
                title: widgetProperties.title.startsWith('widget')
                  ? action.payload.intl.formatMessage({ id: widgetProperties.title })
                  : widgetProperties.title,
              },
              state.elementInEditMode
            )
          : {
              ...state.elementInEditMode,
              preview: {
                ...widgetProperties,
                w,
                h,
              },
              type: action.payload.value,
              info: widget.info,
            },
        drawer: {
          ...state.drawer,
          sections: [
            {
              ...state.drawer.sections[0],
              isOpen: false,
            },
            {
              ...state.drawer.sections[1],
              isOpen: false,
            },
            {
              ...state.drawer.sections[2],
              isOpen: !!state.isDataEntryDashboard,
            },
            {
              ...state.drawer.sections[3],
              isOpen: !state.isDataEntryDashboard,
            },
            {
              ...state.drawer.sections[4],
              isOpen: false,
            },
          ],
        },
      }

    case 'RESET_WIDGET':
      const resetWidget = widgetConfig({ type: action.payload.value }).editProps

      const isResetMatrixContext =
        state.editModeContext === 'Matrix' && state.elementInEditMode.matrixDetailGraph
      const matrixDetailGraphPath =
        isResetMatrixContext &&
        state.elementInEditMode.matrixDetailGraph.path.replace('root.elements[0].', '')

      const { w: currentWidth, h: currentHeight } = isResetMatrixContext
        ? get(state.elementInEditMode.preview.detailTemplate, matrixDetailGraphPath)
        : state.elementInEditMode?.preview || resetWidget

      const resetTitle = resetWidget.default.title.startsWith('widget')
        ? action.payload.intl.formatMessage({ id: resetWidget.default.title })
        : resetWidget.default.title

      return {
        ...state,
        elementInEditMode: isResetMatrixContext
          ? set(
              `preview.detailTemplate.${matrixDetailGraphPath}`,
              {
                ...resetWidget.default,
                w: currentWidth,
                h: currentHeight,
                title: resetTitle,
              },
              state.elementInEditMode
            )
          : {
              ...state.elementInEditMode,
              preview: {
                ...resetWidget.default,
                w: currentWidth,
                h: currentHeight,
                title: resetTitle,
              },
              type: action.payload.value,
              info: resetWidget.info,
            },
      }

    case 'SAVE_WIDGET':
      const assetWidgets = [
        'BarChart',
        'HistoricalBarChart',
        'EnergyClassification',
        'Calendar',
        'CircleGauge',
        'EfficiencyGauge',
        'AbsoluteGauge',
        'RelativeGauge',
        'Consumption',
        'Gauge',
        'Grid',
        'YearOverview',
        'DatapointHeatMap',
        'GraphCard',
        'HistoricalComparisonGraph',
        'TrendLine',
        'Image',
        'Status',
        'ComparisonPieChart',
        'ChartCard',
      ]
      const ghgWidgets = [
        'GhgBarChart',
        'GhgHistoricalBarChart',
        'GhgAbsoluteGauge',
        'GhgRelativeGauge',
        'GhgGauge',
        'GhgGrid',
        'GhgHistoricalLineChart',
        'GhgLineChart',
        'GhgTrendLine',
        'GhgStatus',
        'GhgPieChart',
        'GhgSparkline',
      ]
      const dataType = assetWidgets.includes(state.elementInEditMode.preview.type)
        ? 'assets'
        : ghgWidgets.includes(state.elementInEditMode.preview.type)
        ? 'ghg'
        : null
      const cleanedWidget = Object.entries({
        dataType,
        ...state.elementInEditMode.preview,
      }).reduce((cleanedWidget, [key, value]) => {
        if (value !== undefined && value !== null) {
          cleanedWidget[key] = value
        }
        return cleanedWidget
      }, {})

      return {
        ...state,
        data: set(
          `layout.${getGraphDataKey(state.elementInEditMode.graph.path)}`,
          cleanedWidget,
          state.data
        ),
        drawer: {
          ...state.drawer,
          isOpen: false,
          sections:
            [] ||
            state.drawer.sections.map((section) => ({ ...section, isOpen: false, width: '0px' })),
        },
        elementInEditMode: {
          ...state.elementInEditMode,
          matrixDetailGraph: null,
          preview: { ...cleanedWidget },
          show: false,
          containerHeight: null,
        },
        editModeContext: null,
      }

    case 'TOGGLE_LOCK_WIDGET':
      const currentValue = getCurrentValue(state.data.layout, action.payload.graph.path)
      return set(
        `data.layout.${getGraphDataKey(action.payload.graph.path)}`,
        {
          ...currentValue,
          isLocked: !currentValue.isLocked,
          width: currentValue.isLocked ? null : action.payload.elementRef.clientWidth,
          height: currentValue.isLocked ? null : action.payload.elementRef.clientHeight,
        },
        state
      )
    case 'COPY_WIDGET_TO_CLIPBOARD':
      const widgetToCopy = getCurrentValue(state.data.layout, action.payload.graph.path)
      const clipboard = JSON.parse(sessionStorage.getItem('clipboard')) || {}
      sessionStorage.setItem(
        'clipboard',
        JSON.stringify({
          ...clipboard,
          widget: widgetToCopy,
        })
      )
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          widget: widgetToCopy,
        },
      }

    case 'PASTE_WIDGET_FROM_CLIPBOARD':
      const widgetToReplace = getCurrentValue(state.data.layout, action.payload.graph.path)
      const { widget: widgetFromClipboard } = JSON.parse(sessionStorage.getItem('clipboard')) || {}

      return set(
        `data.layout.${getGraphDataKey(action.payload.graph.path)}`,
        {
          ...widgetFromClipboard,
          isLocked: widgetToReplace.isLocked,
          width: widgetToReplace.isLocked ? widgetToReplace.width : null,
          height: widgetToReplace.isLocked ? widgetToReplace.height : null,
          h: widgetToReplace.h,
          w: widgetToReplace.w,
        },
        state
      )

    case 'RESTORE_SECTION':
      return {
        ...state,
        newSectionIndex: null,
      }

    case 'COPY_SECTION_TO_CLIPBOARD':
      const currentClipboard = JSON.parse(sessionStorage.getItem('clipboard')) || {}
      sessionStorage.setItem(
        'clipboard',
        JSON.stringify({
          ...currentClipboard,
          section: action.payload.section,
        })
      )
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          section: action.payload.section,
        },
      }

    case 'PASTE_SECTION_FROM_CLIPBOARD':
      const { section } = JSON.parse(sessionStorage.getItem('clipboard')) || {}
      return {
        ...state,
        newSectionIndex: action.payload.sectionIndex + 1,
        data: {
          ...state.data,
          layout: {
            ...state.data.layout,
            sections: [
              ...state.data.layout.sections.slice(0, action.payload.sectionIndex + 1),
              { ...section, id: uniqid() },
              ...state.data.layout.sections.slice(action.payload.sectionIndex + 1),
            ],
          },
        },
      }

    case 'ADD_SUB_SECTION':
      if (state.editModeContext === 'Matrix') {
        const elementsPath = getGraphDataKey(state.elementInEditMode.graph.path)
        const currentElementsArray = getCurrentValue(state.data.layout, elementsPath).detailTemplate
          .elements

        const newElementsArray = getAddedSubSectionElements(
          currentElementsArray,
          action.payload.elementIndex
        )

        return {
          ...state,
          elementInEditMode: {
            ...state.elementInEditMode,
            preview: {
              ...state.elementInEditMode.preview,
              detailTemplate: {
                ...state.elementInEditMode.preview.detailTemplate,
                elements: newElementsArray,
              },
              equipments: getEquipmentsLayoutForTemplate(
                state.elementInEditMode.preview.equipments,
                newElementsArray
              ),
            },
          },
        }
      }

      const elementsPath = getGraphDataKey(action.payload.graph.path, action.payload.dataKey)
      const currentElementsArray = getCurrentValue(state.data.layout, elementsPath)

      const newElementsArray = getAddedSubSectionElements(
        currentElementsArray,
        action.payload.elementIndex
      )

      return set(`data.layout.${elementsPath}`, newElementsArray, state)

    case 'ON_PREVIEW_UPDATE':
      const _matrixDetailGraphPath =
        state.editModeContext === 'Matrix' &&
        state.elementInEditMode.matrixDetailGraph?.path.replace('root.elements[0].', '')
      const selectedGroupIndex = action.payload.meta?.groupDataKey
        ? state.elementInEditMode.selectedDatapointMap[action.payload.meta.groupDataKey]
        : undefined
      let update = state
      if (!(selectedGroupIndex !== undefined && action.payload.meta.removed.length)) {
        update = set(
          state.editModeContext === 'Matrix' &&
            !action.payload.rootUpdate &&
            action.payload.graph.dataKey !== 'detailTemplate'
            ? `elementInEditMode.preview.detailTemplate.${_matrixDetailGraphPath}.${action.payload.graph.dataKey}`
            : `elementInEditMode.preview${
                action.payload.graph.subPath ? `.${action.payload.graph.subPath}` : ''
              }${action.payload.graph.dataKey ? `.${action.payload.graph.dataKey}` : ''}`,
          action.payload.value,
          state
        )
      }

      if (action.payload.meta?.groupDataKey) {
        if (selectedGroupIndex !== undefined && selectedGroupIndex !== -1) {
          const currentValue =
            state.elementInEditMode.preview[action.payload.meta.groupDataKey]?.[selectedGroupIndex]
              ?.datapoints || []
          const value = action.payload.meta.added?.length
            ? currentValue.concat(action.payload.meta.added.map(({ id }) => id))
            : currentValue.filter(
                (item) => !action.payload.meta.removed.some(({ id }) => item === id)
              )
          update = set(
            `elementInEditMode.preview.${action.payload.meta.groupDataKey}[${selectedGroupIndex}].datapoints`,
            value,
            update
          )
        }
      }

      return update

    case 'ON_DATAPOINT_SELECT':
      return {
        ...state,
        elementInEditMode: {
          ...state.elementInEditMode,
          ...action.payload.meta,
          selectedDatapointMap: {
            ...state.elementInEditMode?.selectedDatapointMap,
            [action.payload.dataKey]: action.payload.value,
          },
        },
      }

    case 'SET_SECTION_EDIT_MODE':
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.graph.path]: action.payload.section,
        },
      }

    case 'ON_SECTION_UPDATE':
      return state.editModeContext === 'Matrix'
        ? set(
            `elementInEditMode.preview.detailTemplate.${action.payload.graph.dataKey}`,
            action.payload.value,
            state
          )
        : set(
            `data.layout.${action.payload.graph.path}.${action.payload.graph.dataKey}`,
            action.payload.value,
            state
          )

    case 'ADD_SECTION':
      return {
        ...state,
        newSectionIndex: action.payload.sectionIndex + 1,
        editModeContext: action.payload.editModeContext,
        drawer: {
          isOpen: true,
          sections: [
            {
              isOpen: true,
              name: 'elementSelector',
              width: '420px',
              components: [
                {
                  componentName: 'TabPanel',
                  componentProps: {
                    tabs: [
                      {
                        name: 'widgetSelector',
                        title: 'widget.layouts',
                        description: 'widget.selectALayout',
                        listDataKey: 'elements',
                        componentName: 'Widget',
                        componentProps: {
                          elements: sectionTemplates.filter(
                            ({ elements }) =>
                              elements[0]?.type !== 'DataEntry' &&
                              elements[0]?.type !== 'DataStatusGrid'
                          ),
                          variant: 'widgetSelector',
                          dataProps: {
                            graph: {},
                            isSelectMode: true,
                          },
                        },
                      },
                    ],
                  },
                },
              ],
              buttons: [
                {
                  name: 'cancel',
                  label: 'widget.cancel',
                  variant: 'mainButton',
                  action: {
                    type:
                      action.payload.editModeContext === 'Matrix'
                        ? 'SET_MATRIX_WIDGET_SETTINGS'
                        : 'CLOSE_DRAWER',
                  },
                },
              ],
            },
          ],
        },
      }

    case 'SELECT_SECTION':
      const isMatrixUpdate = state.elementInEditMode?.type === 'Matrix'
      return {
        ...state,
        data: isMatrixUpdate
          ? state.data
          : {
              ...state.data,
              layout: {
                ...state.data.layout,
                sections: [
                  ...state.data.layout.sections.slice(0, state.newSectionIndex),
                  { ...action.payload.value, id: uniqid() },
                  ...state.data.layout.sections.slice(state.newSectionIndex),
                ],
              },
            },
        elementInEditMode: isMatrixUpdate
          ? {
              ...state.elementInEditMode,
              preview: {
                ...state.elementInEditMode.preview,
                equipments: getEquipmentsLayoutForTemplate(
                  state.elementInEditMode.preview.equipments,
                  action.payload.value.elements
                ),
                detailTemplate: {
                  elements: action.payload.value.elements,
                  h: 400,
                  header: {},
                },
              },
            }
          : state.elementInEditMode,
        drawer: {
          ...state.drawer,
          isOpen: isMatrixUpdate,
          sections: isMatrixUpdate
            ? [
                {
                  isOpen: false,
                  name: 'fullScreen',
                  width: '0px',
                  drawerSelector: {
                    icon: 'fas fa-expand',
                    variant: 'roundedButton',
                  },
                },
                {
                  isOpen: action.payload.value === 'Placeholder',
                  name: 'elementSelector',
                  width: '420px',
                  drawerSelector: {
                    icon: 'fas fa-chart-area',
                    variant: 'roundedButton',
                  },
                  components: [
                    {
                      componentName: 'TabPanel',
                      componentProps: {
                        tabs: [
                          {
                            name: 'widgetSelector',
                            title: 'widget.widgets',
                            description: 'widget.selectAWidget',
                            listDataKey: 'elements',
                            componentName: 'Widget',
                            componentProps: {
                              elements: widgetTemplates,
                              variant: 'widgetSelector',
                              dataProps: {
                                graph: {},
                                isSelectMode: true,
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  isOpen: action.payload.value !== 'Placeholder',
                  name: 'widgetEditor',
                  width: '420px',
                  drawerSelector: {
                    icon: 'fas fa-gears',
                    variant: 'roundedButton',
                  },
                  components: [
                    {
                      componentName: 'WidgetConfigurator',
                      componentProps: {},
                    },
                  ],
                },
                {
                  isOpen: false,
                  name: 'info',
                  width: '420px',
                  drawerSelector: {
                    icon: 'fas fa-info',
                    variant: 'roundedButton',
                  },
                  components: [
                    {
                      componentName: 'WidgetInfo',
                      componentProps: {},
                    },
                  ],
                },
              ]
            : [
                {
                  ...state.drawer.sections[0],
                  isOpen: false,
                },
              ],
        },
      }

    case 'ON_BOTTOM_DRAWER_UPDATE':
      return {
        ...state,
        bottomDrawer: action.payload.dataKey
          ? set(action.payload.dataKey, action.payload.value, state.bottomDrawer)
          : action.payload.value,
      }

    case 'SORT_ELEMENTS_ARRAY':
      const arrayPath = getGraphDataKey(action.payload.graph.path, action.payload.dataKey)
      const currentArray = getCurrentValue(state.data.layout, arrayPath)
      const newArray = arrayMove(currentArray, action.payload.oldIndex, action.payload.newIndex)
      return set(`data.layout.${arrayPath}`, newArray, state)

    case 'UPDATE_ELEMENTS_ARRAY':
      const path = getGraphDataKey(action.payload.graph.path, action.payload.dataKey)
      const matrixDetailArrayPath =
        state.editModeContext === 'Matrix' &&
        action.payload.graph.path.replace('root.elements[0]', '') // || (state.elementInEditMode.matrixDetailGraph?.path.replace('root.elements[0].', '') || action.payload.graph.path.replace('root.elements[0]', ''))
      return state.editModeContext === 'Matrix'
        ? {
            ...state,
            elementInEditMode: {
              ...state.elementInEditMode,
              matrixDetailGraph: action.payload.graph,
              preview: {
                ...state.elementInEditMode.preview,
                ...updateMatrixElementsArray({
                  currentValue: state.elementInEditMode.preview,
                  dataKey: action.payload.dataKey,
                  value: action.payload.value,
                  path: matrixDetailArrayPath,
                }),
              },
            },
          }
        : set(`data.layout.${path}`, action.payload.value, state)

    case 'SET_ACTIVE_DRAWER':
      const isFullScreen =
        !state.elementInEditMode.isFullScreen && action.payload.value === 'fullScreen'
      return {
        ...state,
        elementInEditMode: {
          ...state.elementInEditMode,
          isFullScreen,
        },
        drawer: {
          ...state.drawer,
          sections: state.drawer.sections.map((section) => ({
            ...section,
            isOpen:
              state.elementInEditMode.isFullScreen && action.payload.value === 'fullScreen'
                ? section.name === 'widgetEditor'
                : section.name === action.payload.value,
            drawerSelector: {
              ...section.drawerSelector,
              icon:
                section.name === 'fullScreen'
                  ? isFullScreen
                    ? 'fas fa-compress'
                    : 'fas fa-expand'
                  : section.drawerSelector?.icon,
            },
          })),
        },
      }

    case 'CLOSE_DRAWER':
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isOpen: false,
          sections: state.drawer.sections.map((section) => ({
            ...section,
            isOpen: false,
          })),
        },
      }
    default:
      return state
  }
}

export default reducer
