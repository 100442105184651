/**
 * Round the value down to certain decimal precision without trailing zeros
 * Useful for truncating values for display and getting rid of long floating point values
 * @param value value to round
 * @param decimals maximum amount of decimals to round to
 */
const round = (value, decimals = 2) => {
  const scale = Math.pow(10, decimals)
  return Math.round((value + Number.EPSILON) * scale) / scale
}

export default round
