import { gql, useQuery } from '@apollo/client'

const QUERY_MENU_ITEMS = gql`
  query MenuItems($filter: MenuItemFilter!) {
    menuItems(filter: $filter) {
      id
      title
    }
  }
`

export const useMenuItems = (menuId) => {
  const { data, ...rest } = useQuery(QUERY_MENU_ITEMS, {
    variables: {
      filter: {
        menu: {
          id: menuId,
        },
      },
    },
    skip: !menuId,
  })

  return { data: data?.menuItems, ...rest }
}
