import React, { useState } from 'react'

import { get } from 'lodash'
import { injectIntl } from 'react-intl'

// import { useEntityFeatureTypeDescription } from 'services/entities'
import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import Field from 'components/Form/components/Field'
import { FieldMessage } from 'components/Form/components/Field/styled'
import SwitchField from 'components/Form/fields/SwitchField'
import TagEditor from 'components/Form/fields/TagField/TagEditor'
import TextField from 'components/Form/fields/TextField'
import TooltipContent from 'components/Global/Tooltip/TooltipContent'

import { TagFieldStyled } from './styled'

const TagField = ({
  value,
  defaultValue,
  name,
  parentIsEnabled,
  readOnly,
  tagName,
  softDeleteMode = true,
  itemIndex,
  intl,
  onChange = () => {},
  ...fieldProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Field {...fieldProps}>
      {({ watch, setValue, isEditMode, errors, getValues }) => {
        const values = getValues()
        const tagTooltip = watch(`${name}.tooltip`)
        const fieldErrors = get(errors, name)
        const enabled = watch(`${name}.enabled`)
        const required = watch(`${name}.required`)
        const type = watch(`${name}.type.name`)
        const label = watch(`${name}.name`)

        if (softDeleteMode && required && parentIsEnabled && !enabled) {
          setValue(`${name}.enabled`, true)
        }
        const switchProps = softDeleteMode
          ? {
              name: `${name}.enabled`,
              disabled: required || readOnly,
              onChange: (value) => !value && setValue(`${name}.value`, null),
            }
          : {
              onChange: (value) => onChange(value, 'delete', itemIndex),
              value: true,
              uncontrolled: true,
              disabled: required || readOnly,
            }
        const color = generateTagColor(tagName)
        return (
          <TagFieldStyled
            disabled={softDeleteMode && !enabled}
            error={fieldErrors !== undefined}
            color={color}
          >
            <SwitchField {...switchProps} size="small" disableClick={!isEditMode} />
            <TextField
              name={`${name}.name`}
              readOnly
              className="TagField__label"
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            />

            <TooltipContent variant="Datapoints" isOpen={isOpen} maxWidth="20rem">
              <span>{tagTooltip && tagTooltip !== '' ? label + ': ' + tagTooltip : label}</span>
            </TooltipContent>
            <TagEditor
              {...fieldProps}
              name={name}
              tagName={tagName}
              value={value}
              values={values}
              defaultValue={defaultValue}
              enabled={enabled}
              parentIsEnabled={parentIsEnabled}
              required={required}
              readOnly={readOnly}
              isEditMode={isEditMode}
              onChange={onChange}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
            />
            <span className="TagField__type">{type}</span>
            {fieldErrors?.value && (
              <FieldMessage>
                {intl.formatMessage({ id: `form.tag.error.${fieldErrors.value.type}` })}
              </FieldMessage>
            )}
          </TagFieldStyled>
        )
      }}
    </Field>
  )
}

export default injectIntl(TagField)
