import { useMemo } from 'react'

import { get } from 'lodash'

import { fuzzyMatch } from 'components/Global/FormField/utils/functions'

export const useFilteredData = (data, searchText, options = {}) => {
  return useMemo(() => {
    if (!searchText) {
      return data
    }

    const { searchKeys = ['name'] } = options

    return data?.filter((row) => {
      return searchKeys.some((key) => {
        const value = get(row, key)

        if (!value) {
          return false
        }

        return fuzzyMatch(value, searchText)
      })
    })
  }, [data, searchText, options])
}
