import styled from 'styled-components'

import Field from 'components/Form/components/Field'

const CascaderFieldStyled = styled(Field)`
  .ant-cascader-picker-label {
    z-index: 1;
    padding-left: 3rem;
  }
`
export default CascaderFieldStyled
