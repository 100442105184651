import { setLightness } from 'polished'
import styled from 'styled-components'

export const AugmentedEntity = styled.div`
  transition: box-shadow 0.2s ease, transform 0.2s ease, opacity 0.2s ease, background 0.2s ease;
  margin: 0.2rem 0.5rem 0.3rem 0;
  max-width: 100%;
  padding: 0 0.5rem !important;
  color: ${({ theme }) => theme.color.mainText};
  background: ${({ theme }) => theme.color.backgroundFront};
  border-top: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-bottom: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-left: 4px solid ${({ color, theme }) => color || theme.color.softText};
  border-right: 1px solid ${({ color, theme }) => color || theme.color.softText};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadowElevation1(theme.darkMode)};
  font-weight: 100;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &:not(.selected) {
    opacity: 0.7;
  }
  &:hover {
    opacity: 1;
  }

  .AugmentedEntity__label {
    width: 100%;
  }
`

export const Tag = styled.span`
  transition: background 0.2s ease;
  border: 1px solid ${({ color }) => color};
  border-left-width: 3px;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ theme }) => theme.color.backgroundFront};

  &:hover {
    background: ${({ color }) => setLightness(0.97, color)};
    color: ${({ color }) => color};
    border-color: ${({ color }) => color};
  }

  &.selected {
    background: ${({ color }) => color};
    color: ${({ theme }) => theme.color.backgroundFront};
    border-color: ${({ color }) => color};
  }
`
