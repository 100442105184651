import { useContext, useMemo } from 'react'

import { useRouteMatch } from 'react-router'

import { SelectableContext } from './SelectableProvider'

export const useSelectableOptions = () => {
  const { options } = useContext(SelectableContext)

  return options
}

export const useCurrentSelectedOption = () => {
  const options = useSelectableOptions()
  const { path } = useRouteMatch()

  return useMemo(
    () =>
      options?.find((option) =>
        option.exact ? path === option.path : path.startsWith(option.path)
      ),
    [options, path]
  )
}
