export const getNonAssetTreeByDataSource = (entities, { rootLevel }, dataSource) => {
  switch (dataSource) {
    case 'vehicles':
      return [
        {
          name: 'Departments',
          children: rootLevel.departments.map((dep) => ({
            ...dep,
            children: entities.reduce((children, { department, ...entity }) => {
              if (department.id === dep.id) {
                children.push({ ...entity, key: entity.id })
              }
              return children
            }, []),
          })),
        },
        {
          name: 'Vehicle types',
          children: rootLevel.vehicleTypes.map((type) => ({
            ...type,
            children: entities.reduce((children, { vehicleType, ...entity }) => {
              if (vehicleType.id === type.id) {
                children.push({ ...entity, key: entity.id })
              }
              return children
            }, []),
          })),
        },
      ]
    default:
      return []
  }
}

export const getTreeConfigByDataSource = ({ dataSource, departments, vehicleTypeList }) => {
  return {
    vehicles: {
      rootLevel: {
        departments: departments || [],
        vehicleTypes: vehicleTypeList || [],
      },
    },
  }[dataSource]
}
