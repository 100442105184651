import React from 'react'

import { Controller } from 'react-hook-form'

import IconButtonFieldStyled from 'components/Form/fields/IconButtonField/styled'
import Icon from 'components/Global/Icon'

const IconButtonField = ({
  children,
  name,
  icon,
  defaultValue,
  onChangeValue,
  value = true,
  checked,
  disabled,
  disableClick,
  size,
  onChange,
  onClick,
  ...props
}) => {
  return (
    <IconButtonFieldStyled {...props}>
      {({ control }) => {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              const handleChange = (value) => {
                const updateValue = typeof onChangeValue === 'boolean' ? onChangeValue : value
                fromFieldOnChange(updateValue)
                onChange && onChange(updateValue)
              }
              return (
                <Icon
                  icon={icon}
                  className={formFieldValue === value ? 'active' : 'inactive'}
                  onClick={handleChange}
                />
              )
            }}
          />
        )
      }}
    </IconButtonFieldStyled>
  )
}

export default IconButtonField
