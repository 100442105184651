import React, { useEffect, useRef } from 'react'

import { Container, Search } from './styled'

export const SearchBar = ({
  value,
  onChange,
  children,
  onClose,
  onKeyDown,
  onKeyUp,
  onSelect,
  placeholder,
  ...props
}) => {
  const searchRef = useRef()

  useEffect(() => {
    searchRef.current.focus()
    const domEl = searchRef.current.input

    const eventHandler = (event) => {
      switch (event.code) {
        case 'ArrowDown':
          onKeyDown()
          break
        case 'ArrowUp':
          onKeyUp()
          break
        case 'Enter':
          onSelect()
          break
        case 'Escape':
          onClose()
          break
        default:
      }
    }

    domEl.addEventListener('keydown', eventHandler)

    return () => {
      domEl.removeEventListener('keydown', eventHandler)
    }
  }, [onKeyDown, onKeyUp, onSelect, onClose])

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
      }}
      {...props}
    >
      <Search onSearch={({ value }) => onChange(value)} ref={searchRef} placeholder={placeholder} />
      {children}
    </Container>
  )
}

export default SearchBar
