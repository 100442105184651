import React, { memo } from 'react'

import { useLocation } from 'react-router'
import { useTheme } from 'styled-components'

import MenuHeader from 'components/Layout/MenuBar/components/MenuHeader'
import ShareDashboardButton from 'components/Layout/MenuBar/components/ShareDashboardButton'
import EditDashboardsButton from 'components/LayoutComponents/Menu/MenuLeft/EditDashboardButton'

import { SearchableTree } from '../../../../Global/Tree'
import MenuItemButton from './MenuItemButton'
import DashboardMenuStyled from './styled'

const DashboardsMenu = ({ dashboards, onClose }) => {
  const theme = useTheme()
  const location = useLocation()
  const slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

  const defaultTreeItemComponentProps = (item) => {
    const { icon, title, name, key, disabled } = item

    return {
      icon,
      label: title || name,
      disabled,
      variant: 'treeItem',
      url: `/dashboard/${key}`,
      caption: { text: title },
      isActive: slug === key,
    }
  }
  const screen = theme.screenSize.screen.current
  return (
    <DashboardMenuStyled>
      <MenuHeader className="MenuHeader" title="menu.dashboards">
        {screen !== 's' && (
          <>
            <ShareDashboardButton />
            <EditDashboardsButton {...dashboards} onClose={onClose} />
          </>
        )}
      </MenuHeader>
      <SearchableTree
        value={dashboards}
        treeItemComponent={MenuItemButton}
        treeItemComponentProps={defaultTreeItemComponentProps}
        elementHeight={40}
        variant="DashboardMenu"
      />
    </DashboardMenuStyled>
  )
}

const propsAreEqual = (prevProps, nextProps) => {
  return prevProps.dashboards === nextProps.dashboards
}

export default memo(DashboardsMenu, propsAreEqual)
