import React from 'react'

import Chart from 'components/Dashboard/components/Chart'
import InlineErrors from 'components/Dashboard/components/Chart/InlineErrors'
import { WidgetCaption } from 'components/Global/Caption/styled.WidgetCaption'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import GaugeStyled from 'components/Widgets/Gauge/styled'
import { WidgetError } from 'components/Widgets/WidgetError'

const Ui = ({
  styleProps,
  className,
  title,
  icon,
  color,
  options,
  w,
  h,
  loading,
  error,
  inlineErrors = [],
}) => {
  return (
    <GaugeStyled className={`Gauge ${className}`}>
      <WidgetCaption styleProps={styleProps} title={title} icon={icon} color={color} />
      {!loading?.loading && options.series && (
        <Chart
          cols={w}
          rows={h}
          options={options}
          rootSectionHeight={styleProps?.rootSectionHeight}
        />
      )}
      <WidgetLoader {...loading} spinner={false} />
      <WidgetError error={error} />
      {inlineErrors.length > 0 && <InlineErrors errors={inlineErrors} />}
    </GaugeStyled>
  )
}

export default Ui
