import React, { useEffect, useState } from 'react'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { useIconsQuery } from '../../../LayoutComponents/Menu/MenuLeft/useIconsQuery'
import Button from '../../Button'
import Icon from '../../Icon'
import Upload from '../Upload'
import IconListPortal from './IconListPortal'
import IconPickerStyled, { IconButton, IconPickerTrigger } from './styled'

const IconPicker = ({
  value,
  bottomDrawer,
  onChange,
  companyWide = true,
  dataCy = 'IconPicker',
}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const { data: icons, loading, refetch: refetchIcons } = useIconsQuery()
  const {
    state: {
      bottomDrawer: { isOpen },
    },
    action,
  } = useDashboard()

  useEffect(() => {
    if (!isOpen) setIsOpenState(false)
  }, [isOpen])

  const onToggleOpen = (payload) => {
    if (bottomDrawer) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: {
            isOpen: true,
            width: bottomDrawer.width,
          },
        },
      })
    }
    return setIsOpenState((state) => (typeof payload !== 'undefined' ? !!payload : !state))
  }
  const onIconClick = (e, value) => {
    e && e.stopPropagation()
    if (bottomDrawer) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: {
            isOpen: false,
            width: bottomDrawer.width,
          },
        },
      })
    }
    onChange(value)
    setIsOpenState((state) => !state)
  }

  return bottomDrawer ? (
    <IconPickerTrigger>
      <IconButton
        icon={value}
        onClick={onToggleOpen}
        isActive={isOpen}
        variant="IconPicker"
        dataCy={`${dataCy}-trigger`}
      />
      {value && (
        <Icon
          icon="fas fa-xmark"
          variant="clearIcon"
          onClick={(e) => onIconClick(e, null)}
          dataCy={`${dataCy}-clear`}
        />
      )}
      {isOpen && isOpenState && (
        <IconListPortal
          icons={icons}
          onIconClick={(e, value) => onIconClick(e, value)}
          onUpload={refetchIcons}
          value={value}
          companyWide={companyWide}
        />
      )}
    </IconPickerTrigger>
  ) : (
    <IconPickerStyled
      className="IconPicker"
      button={{
        dataCy,
        icon: value,
        children: value && (
          <Icon
            className="close-button"
            icon="fas fa-xmark"
            variant="clearIcon"
            onClick={(e) => onIconClick(e, null)}
            dataCy={`${dataCy}-clear`}
          />
        ),
      }}
      variant="IconPicker"
      isOpen={isOpenState}
      onToggleOpen={onToggleOpen}
    >
      {value && (
        <Icon
          icon="fas fa-xmark"
          variant="clearIcon"
          onClick={(e) => onIconClick(e, null)}
          dataCy={`${dataCy}-clear`}
        />
      )}
      <Upload
        onChange={(value) => {
          refetchIcons()
          onIconClick(null, value)
        }}
        icon="fas fa-upload"
        placeholderIcon="fas fa-xmark"
        loadingText={false}
        companyWide={companyWide}
      />
      {!loading &&
        !bottomDrawer &&
        icons.map((icon) => (
          <Button
            key={icon}
            icon={icon}
            onClick={(e) => onIconClick(e, icon)}
            isActive={icon === value}
            dataCy={`IconPicker-${icon}`}
          />
        ))}
    </IconPickerStyled>
  )
}

IconPicker.propTypes = {}

IconPicker.defaultProps = {}

export default IconPicker
