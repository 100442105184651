import React from 'react'

import { getDatapointTags, getNodeTags } from 'components/Dashboard/components/Tags/functions'
import Tags from 'components/Dashboard/components/Tags/Tags'

const DatapointTags = ({ datapoints, node, fromEntityTypes, numberValuesOnly, ...props }) => {
  const nodeTags = getNodeTags(node, fromEntityTypes, numberValuesOnly)
  const datapointTags = node ? getDatapointTags(datapoints, nodeTags) : []

  return <Tags {...props} tags={datapointTags} />
}

export default DatapointTags
