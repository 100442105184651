import { motion } from 'framer-motion'
import styled from 'styled-components'

const BulletGraphStyled = styled.div`
  display: flex;
  position: relative;

  .BulletGraph__legend {
    display: flex;
    width: 100%;
  }
`

export const GraphSection = styled.div`
  position: relative;
  width: ${({ percentage }) => percentage}%;
  background: ${({ color }) => color};
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  &:first-child {
    border-radius: 2rem 0 0 2rem;
  }
  &:last-child {
    border-radius: 0 2rem 2rem 0;
  }

  .GraphSection__label {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
  }

  .GraphSection__value {
    position: absolute;
    bottom: 100%;
    right: 0;
    color: ${({ theme }) => theme.color.softText};
  }
`

export const GraphValue = styled(motion.span)`
  position: absolute;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 75%;

  .GraphValue__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ size }) => `0 ${size / 2}px ${size * 0.6}px ${size / 2}px`};
    border-color: transparent transparent ${({ theme }) => theme.color.backgroundFront} transparent;
    position: relative;
    z-index: 10;
    margin-bottom: -2px;
  }

  .GraphValue__value {
    //border: 1px solid ${({ theme }) => theme.color.softText};
    border-radius: 1rem;
    background: ${({ theme }) => theme.color.backgroundFront};
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    padding: 0 0.8rem;
  }
`

export default BulletGraphStyled
