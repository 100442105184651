import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { DatapointList } from 'components/Global/FormField/Datapoints'
import EntitySelector from 'components/Global/FormField/EntitySelector'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import DatapointsStyled from './styled'

const Entities = ({
  dataKey = 'entities',
  subPath,
  singleDatapoint,
  selectByType,
  clearDataointsAfterSave,
  onChange,
  onClick,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const selectedDatapoints =
    get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []

  const handleDatapointClick = (e, { id, name, index }) => {
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: index,
          dataKey,
        },
      })
      onClick(e, { id, name, index })
    }
  }

  const onRemoveDatapoint = (e, value) => {
    e.stopPropagation()
    const newDatapointArray = selectedDatapoints.filter(({ id }) => id !== value.id)
    onChange(newDatapointArray, dataKey)
  }

  const onRemoveAllDatapoints = () => {
    onChange([], dataKey)
  }

  const onBottomDrawerSave = () => {
    clearDataointsAfterSave && onChange([], 'datapoints')
  }

  const onOpenBottomDrawer = (e, { name }) => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: true,
          width: 'full',
          component: EntitySelector,
          key: dataKey,
          componentProps: {
            dataKey,
            subPath,
            singleDatapoint,
            intl,
            onSave: onBottomDrawerSave,
            ...(selectByType && {
              onlyEntityType: {
                site: 2,
                building: 3,
                floor: 4,
                room: 5,
                equip: 7,
              }[name],
            }),
          },
        },
      },
    })
  }

  const entityTypes = [
    {
      name: 'site',
      label: 'widget.site',
    },
    {
      name: 'building',
      label: 'widget.building',
    },
    {
      name: 'floor',
      label: 'widget.floor',
    },
    {
      name: 'room',
      label: 'widget.room',
    },
    {
      name: 'equip',
      label: 'widget.equipment',
    },
  ]

  return (
    <DatapointsStyled className="Datapoints">
      {selectedDatapoints !== 'main' && (
        <DatapointList
          datapoints={selectedDatapoints}
          onDatapointClick={handleDatapointClick}
          onRemoveDatapoint={onRemoveDatapoint}
          selectedDatapointMapIndex={
            elementInEditMode.selectedDatapointMap &&
            elementInEditMode.selectedDatapointMap[dataKey]
          }
          buttons={false}
        />
      )}
      {selectedDatapoints !== 'main' && (
        <div className="Datapoints__buttons">
          {selectByType ? (
            entityTypes.map(({ name, label }) => (
              <Button
                key={name}
                name={name}
                className="SmallButton"
                label={intl.formatMessage({
                  id: label,
                })}
                onClick={onOpenBottomDrawer}
                variant="smallButton"
              />
            ))
          ) : (
            <Button
              className="SmallButton"
              label={intl.formatMessage({
                id: 'widget.selectEntity',
              })}
              onClick={onOpenBottomDrawer}
              variant="smallButton"
            />
          )}
          {selectedDatapoints.length > 0 && (
            <Button
              label={intl.formatMessage({
                id: 'widget.clearEntity',
              })}
              onClick={onRemoveAllDatapoints}
              variant="smallButton"
            />
          )}
        </div>
      )}
    </DatapointsStyled>
  )
}

export default injectIntl(Entities)
