import { motion } from 'framer-motion'
import styled from 'styled-components'

export const BulkSelectStyled = styled(motion.div)`
  overflow: hidden;
  background: ${({ theme }) => theme.color.backgroundHighlight};
  margin-bottom: -1rem;

  > .BulkSelect__content {
    padding: 1rem;
    display: flex;
    align-items: center;
  }
  .BulkSelect__label {
    margin-right: 1rem;

    strong {
      margin-right: 0.5rem;
    }
  }

  .BulkSelect__items {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
  }

  .BulkSelect__fields {
    padding-top: 0.2rem;
  }

  .FormField {
    flex-direction: row;

    .FormFieldLabel {
      margin: auto 0.5rem;
    }

    .FormFieldElement {
      margin: auto;
    }
  }
`

export const PaginationStyled = styled.div`
  .anticon {
    padding: 0.5rem;
    cursor: pointer;
  }
`
