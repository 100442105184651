import React from 'react'

import { get } from 'lodash'
import { Controller } from 'react-hook-form'

import SwitchFieldStyled from 'components/Form/fields/SwitchField/styled'

import Switch from '../../components/Switch'

const SwitchField = ({
  children,
  name,
  defaultValue,
  value = true,
  checked,
  disabled,
  disableClick,
  size = 'small',
  color,
  onChange,
  onClick,
  validation,
  showRequired,
  uncontrolled = false,
  checkedChildren,
  unCheckedChildren,
  confirm,
  confirmText,
  confirmOkText,
  confirmCancelText,
  className,
  ...props
}) => {
  return (
    <SwitchFieldStyled
      {...props}
      className={`SwitchField ${className}`}
      color={color}
      required={showRequired && validation?.required}
    >
      {({ control, isEditMode, errors }) => {
        const error = get(errors, name)

        if (uncontrolled) {
          return (
            <Switch
              onChange={() => !disableClick && onChange && onChange(value)}
              onClick={onClick}
              disabled={disabled ?? !isEditMode}
              size={size}
              checked={value}
              checkedChildren={checkedChildren}
              unCheckedChildren={unCheckedChildren}
              confirm={confirm}
              confirmText={confirmText}
              okText={confirmOkText}
              cancelText={confirmCancelText}
              error={error?.message}
            />
          )
        }

        return (
          <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={defaultValue}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              const handleChange = (value) => {
                fromFieldOnChange(value)
                onChange && onChange(value)
              }

              return (
                <Switch
                  value={value}
                  checked={checked ?? formFieldValue === value}
                  onChange={disableClick ? () => {} : handleChange}
                  onClick={onClick}
                  disabled={disabled ?? !isEditMode}
                  size={size}
                  checkedChildren={checkedChildren}
                  unCheckedChildren={unCheckedChildren}
                  confirm={isEditMode && confirm}
                  confirmText={confirmText}
                  okText={confirmOkText}
                  cancelText={confirmCancelText}
                  error={error?.message}
                />
              )
            }}
          />
        )
      }}
    </SwitchFieldStyled>
  )
}

export default SwitchField
