import React, { useMemo, useState } from 'react'

import { useEnergyClassificationsDetails } from 'services/energyClassification'

import ClassificationElement from 'components/Global/FormField/ClassificationPicker/ClassificationElement'

import Icon from '../../Icon'
import ClassificationPickerStyled from './styled'

const ClassificationPicker = ({ value, disabled, onChange, dataCy = 'ClassificationPicker' }) => {
  const { data, loading } = useEnergyClassificationsDetails()
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (payload, classification) => {
    onChange(
      classification
        ? {
            id: classification.id,
            unit: classification.unit.name,
            interval: classification.interval.label?.toLowerCase(),
          }
        : null
    )
    setIsOpen(false)
  }

  const displayValue = useMemo(() => {
    if (data) {
      return data.find(({ id }) => (id = value?.id))
    }
  }, [data, value])

  return (
    <ClassificationPickerStyled
      className="ClassificationPicker"
      isOpen={isOpen}
      onToggleOpen={(payload) => {
        if (!disabled) {
          setIsOpen((state) => (typeof payload !== 'undefined' ? payload : !state))
        }
      }}
      button={{
        dataCy,
        children: (
          <>
            <ClassificationElement size="s" value={displayValue} />
            {value && (
              <Icon
                icon="fas fa-xmark"
                variant="clearIcon"
                onClick={(e) => handleChange(e, null)}
                dataCy={`${dataCy}-clear`}
              />
            )}
          </>
        ),
      }}
      variant="ValuePicker"
    >
      {!loading &&
        data?.map((classification) => (
          <ClassificationElement
            key={classification.id}
            onChange={(e) => handleChange(e, classification)}
            size="s"
            dataCy={`ClassificationPicker-${classification.id}`}
            value={classification}
            padding
          />
        ))}
    </ClassificationPickerStyled>
  )
}

ClassificationPicker.propTypes = {}

ClassificationPicker.defaultProps = {}

export default ClassificationPicker
