import React, { useCallback, useMemo } from 'react'

import pathToRegexp from 'path-to-regexp'
import { PropTypes } from 'prop-types'
import { useHistory } from 'react-router'

import {
  useCurrentSelectedOption,
  useSelectableOptions,
} from 'components/Router/SelectableProvider'

import * as variants from './variants'

const SelectableRoutePicker = ({ translateLabel, variant, onChange, ...props }) => {
  const selectableOptions = useSelectableOptions()
  const currentOption = useCurrentSelectedOption()
  const history = useHistory()

  const options = useMemo(
    () =>
      selectableOptions &&
      selectableOptions.map(({ name, disabled, path }) => ({
        value: name,
        disabled,
        label: translateLabel ? translateLabel(name) : name,
        createRoute: path
          ? pathToRegexp.compile(path)
          : () => console.warn(`No path set for "${name}" in selectable route`),
      })),
    [selectableOptions, translateLabel]
  )

  const handleChange = useCallback(
    (key) => {
      const item = options.find((option) => option.value === key)

      if (typeof onChange === 'function') {
        onChange(key)
      }

      if (item) {
        history.push(item.createRoute())
      } else {
        console.warn(`Selectable route item "${key}"" does not exists`)
      }
    },
    [history, options, onChange]
  )

  if (selectableOptions === undefined) {
    return null
  }

  const RenderComponent = variants[variant] || variants.default

  return (
    <RenderComponent
      {...{ value: currentOption?.name, options, onChange: handleChange }}
      {...props}
    />
  )
}

SelectableRoutePicker.propTypes = {
  translateLabel: PropTypes.string,
}

export default SelectableRoutePicker
