import { Tree } from 'antd'
import styled from 'styled-components'

const { DirectoryTree } = Tree

export const AntTree = styled(Tree)``

export const AntDirectoryTree = styled(DirectoryTree)`
  .ant-tree-treenode-selected:hover::before,
  .ant-tree-treenode-selected::before {
    background: ${({ theme }) => theme.color.main} !important;
  }
`
