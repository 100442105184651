import { useCallback, useMemo } from 'react'

import { gql, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'

import { ghgScope3EnabledCategories } from 'config'
import { useQueryData } from 'services/data'

import { useDefaultTimeRange } from 'components/Dashboard/components/TimePicker/functions'

import { useCurrentCustomer } from './store'
import { useLocaleHook } from './user'

export const systemTags = ['highestDegreeOfEducation', 'dateOfBirth', 'organisationRef']

export const PERMITTED_ENTITIES = gql`
  query allowedEntities($type: String) {
    allowedEntities(type: $type) {
      type
      id
      tags
      name
    }
  }
`

export const PERMITTED_GEOCOORD_EQUIPS = gql`
  query allowedGeoCoordEquips {
    allowedGeoCoordEquips {
      id
      name
      type
      tags
      datapoints {
        id
        tags
      }
    }
  }
`

export const PERMITTED_DATAPOINTS = gql`
  query allowedDatapoints {
    allowedDatapoints {
      type
      id
      name
      last
      tags
    }
  }
`

export const ALLOWED_DATAPOINTS_FOR_ROLE = gql`
  query allowedDatapointsForRole($id: Float!) {
    allowedDatapointsForRole(id: $id) {
      id
      name
      tags
      namedReferences
      unit
    }
  }
`
export const ALLOWED_DATAPOINTS_FOR_TAGS = gql`
  query allowedDatapointsForTags($data: TagsInput!) {
    allowedDatapointsForTags(data: $data) {
      id
      name
      tags
      namedReferences
      unit
    }
  }
`

export const RAW_ALLOWED_DATAPOINTS_FOR_TAGS = gql`
  query rawAllowedDatapointsForTags(
    $data: TagsInput!
    $languageId: Float!
    $getDatapoints: Boolean
    $getNonDatapoints: Boolean
    $doExtraPermissionCheck: Boolean
  ) {
    rawAllowedDatapointsForTags(
      data: $data
      languageId: $languageId
      getDatapoints: $getDatapoints
      getNonDatapoints: $getNonDatapoints
      doExtraPermissionCheck: $doExtraPermissionCheck
    ) {
      id
      name
      tags
      unit
      unitObject {
        difference
      }
      type
      namedReferences
    }
  }
`

export const DUPLICATE_ENTITY = gql`
  mutation DuplicateEntity($destinatinId: ID, $companyId: ID!, $id: ID!) {
    duplicateEntity(destinatinId: $destinatinId, companyId: $companyId, id: $id) {
      success
      message
      object {
        id
      }
    }
  }
`

export const DELETE_ENTITY = gql`
  mutation deleteEntity($id: String!) {
    deleteEntity(id: $id) {
      success
      message
    }
  }
`

export const MOVE_ENTITY = gql`
  mutation MoveEntity($companyId: ID!, $destinationId: ID!, $id: ID!) {
    moveEntity(companyId: $companyId, destinationId: $destinationId, id: $id) {
      success
      message
      object {
        id
        site {
          name
        }
        building {
          name
        }
        floor {
          name
        }
        room {
          name
        }
      }
    }
  }
`

export const CUSTOMER_ENTITIES_QUERY = gql`
  query customerEntities($id: String!) {
    customerEntities(id: $id) {
      id
      name
      rawName
      type
      tags
    }
  }
`
// TODO: add childDatapoints, preset resolver
// second argument: showDeleted - needs work on backend to not filter out deleted entities.
export const ENTITY_QUERY = gql`
  query entity($id: String!, $companyId: ID, $includeDeleted: Boolean) {
    entity(id: $id, companyId: $companyId) {
      id
      name
      rawName
      type
      tags
      preset {
        id
      }
      datapoints(includeDeleted: $includeDeleted) {
        id
        name
        rawName
        type
        tags
        deletedAt
        preset {
          id
        }
      }
    }
  }
`

export const ENTITY_DATAPOINTS_QUERY = gql`
  query entity($id: ID!, $companyId: ID, $includeDeleted: Boolean) {
    entity(id: $id, companyId: $companyId) {
      id
      name
      datapoints(includeDeleted: $includeDeleted) {
        id
        name
        equipment {
          id
        }
      }
    }
  }
`

export const ENTITY_INFO_QUERY = gql`
  query entity($id: String!, $companyId: ID) {
    entity(id: $id, companyId: $companyId) {
      id
      name
      rawName
      type
      tags
      preset {
        id
      }
    }
  }
`

export const UPSERT_ENTITY = gql`
  mutation upsertEntity($data: AviaryEntityInput!) {
    upsertEntity(data: $data) {
      success
      message
      object {
        id
        name
        preset {
          id
        }
      }
    }
  }
`

export const PERMISSION_COMPANY_ENTITIES_QUERY = gql`
  query permissionCompanyEntities($companyId: Float!) {
    permissionCompanyEntities(companyId: $companyId) {
      id
      type
      tags
      name
    }
  }
`

export const COMPANY_ENTITIES_QUERY = gql`
  query companyEntities($companyId: Float!) {
    companyEntities(companyId: $companyId) {
      id
      type
      tags
      name
    }
  }
`
export const FILTERED_ENTITIES_QUERY = gql`
  query filteredEntities($entityId: String!, $tags: [String!]!, $onlyEntityType: Float) {
    filteredEntities(
      filter: { entityId: $entityId, tags: $tags, onlyEntityType: $onlyEntityType }
    ) {
      id
      name
      tags
    }
  }
`

export const NON_DATAPOINT__ENTITIES_FOR_COMPANY_QUERY = gql`
  query nonDatapointEntitiesForCompany(
    $entityId: String!
    $tags: [String!]!
    $onlyEntityType: Float
  ) {
    nonDatapointEntitiesForCompany(
      filter: { entityId: $entityId, tags: $tags, onlyEntityType: $onlyEntityType }
    ) {
      id
      name
      tags
    }
  }
`

export const FILTERED_ENTITIES_DATAPOINTS_QUERY = gql`
  query filteredEntities($entityId: String!, $tags: [String!]!, $onlyEntityType: Float) {
    filteredEntities(
      filter: { entityId: $entityId, tags: $tags, onlyEntityType: $onlyEntityType }
    ) {
      id
      name
      tags
      datapoints {
        id
        name
        tags
        features {
          name
          type
          value
        }
        unit {
          id
          name
        }
      }
    }
  }
`

export const ENTITY_FEATURE_TYPES = gql`
  query entityFeatureType($typeName: String) {
    entityFeatureType(typeName: $typeName) {
      id
      name
      showPreview
      description
    }
  }
`

export const ENTITY_FEATURE_TYPE_DESCRIPTION = gql`
  query entityFeature($name: String!) {
    entityFeature(name: $name)
  }
`

export const ENTITIES_BY_ID_QUERY = gql`
  query entitiesByIds($ids: [String!]!, $filterMarkers: [String!]!, $filterTags: [String!]!) {
    entitiesByIds(ids: $ids) {
      id
      datapoints(filterMarkers: $filterMarkers, filterTags: $filterTags) {
        id
      }
    }
  }
`

export const STATIONARY_COMBUSTIONMETERS_FOR_ENTITY = gql`
  query stationaryCombustionMetersForEntity($meterInfo: MeterInfoInput!) {
    stationaryCombustionMetersForEntity(meterInfo: $meterInfo) {
      id
      datapointName
      equipName
      datapointTags
    }
  }
`

export const PURCHASED_ENERGY_METERS_FOR_ENTITY = gql`
  query purchasedEnergyMetersForEntity($meterInfo: MeterInfoInput!) {
    purchasedEnergyMetersForEntity(meterInfo: $meterInfo) {
      id
      datapointName
      equipName
      datapointTags
    }
  }
`

export const METER_HIERARCHY = gql`
  query meterHierarchy($rootMeters: [RootMeter!]!) {
    meterHierarchy(rootMeters: $rootMeters) {
      meterHierarchy {
        id
        name
        value
        parent
        namedReferences
        unit
        meterType
      }
    }
  }
`

export const useAllowedDatapointForRole = (variables, skip = false) => {
  const { data, error, loading } = useQuery(ALLOWED_DATAPOINTS_FOR_ROLE, { variables, skip })
  return { data, error, loading }
}

export const useAllowedDatapointForTags = (inputData, skip = false) => {
  const { data, error, loading, refetch } = useQuery(ALLOWED_DATAPOINTS_FOR_TAGS, {
    variables: {
      data: inputData,
    },
    skip,
  })
  return { data, error, loading, refetch }
}

export const useRawAllowedDatapointForTags = (
  inputData,
  languageId = 1,
  getDatapoints = true,
  getNonDatapoints = true,
  skip = false
) => {
  const { data, error, loading, refetch } = useQuery(RAW_ALLOWED_DATAPOINTS_FOR_TAGS, {
    variables: {
      data: inputData,
      languageId,
      getDatapoints,
      getNonDatapoints,
    },
    skip,
  })
  return { data, error, loading, refetch }
}

export const useEntities = () => {
  const { data, error, loading } = useQuery(PERMITTED_ENTITIES)
  if (error || loading) {
    return { error, loading }
  }
  return { data: data.allowedEntities }
}

export const useEntitiesById = (entityIds, filterTags) => {
  const { data, error, loading } = useQuery(ENTITIES_BY_ID_QUERY, {
    skip: !entityIds?.length,
    variables: {
      ids: entityIds,
      filterMarkers: filterTags,
      filterTags: entityIds.map((id) => `buildingRef=${id}`),
    },
  })
  if (error || loading) {
    return { error, loading }
  }
  return { data: data.entitiesByIds }
}

export const useDatapoints = () => {
  const { data, error, loading } = useQuery(PERMITTED_DATAPOINTS)
  if (error || loading) {
    return { error, loading }
  }
  return { data: data.allowedDatapoints }
}

export const useCustomerEntities = (id) => {
  const [fetch, { called, loading, error, data, refetch }] = useLazyQuery(CUSTOMER_ENTITIES_QUERY, {
    variables: { id },
  })
  if (error) {
    console.error(error)
  }
  if (!id) {
    return { loading: false, data: [], refetch: (e) => e }
  }
  if (!called) {
    fetch()
    return { loading: true }
  }
  if (loading) {
    return { loading: true }
  }
  return { data: data.customerEntities, refetch }
}

export const useEntity = (id, includeDeleted = undefined) => {
  const customer = useCurrentCustomer()

  const { data, error, loading, refetch } = useQuery(ENTITY_QUERY, {
    variables: { id, companyId: customer?.id, includeDeleted },
  })
  if (error) {
    console.error(error)
  }
  if (loading) {
    return { loading: true }
  }
  return { data: data.entity, refetch }
}

export const useEntitiesDetails = (id, includeDeleted = undefined) => {
  const customer = useCurrentCustomer()

  const { data, error, loading, refetch } = useQuery(ENTITIES_BY_ID_QUERY, {
    variables: { id, companyId: customer?.id, includeDeleted },
  })
  if (error) {
    console.error(error)
  }
  if (loading) {
    return { loading: true }
  }
  return { data: data.entity, refetch }
}

export const useDuplicateEntity = (options = {}) => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()
  const [duplicateEntity, result] = useMutation(DUPLICATE_ENTITY, {
    refetchQueries: [
      {
        query: ENTITY_TREE_DATA_FOR_COMPANY,
        variables: { companyId: customer?.id, language: locale },
      },
    ],
  })

  const duplicate = useCallback(
    (id, destinatinId) => {
      return duplicateEntity({
        variables: { id, companyId: customer?.id, destinatinId },
        ...options,
      })
    },
    [duplicateEntity, customer, options]
  )

  return [duplicate, result]
}

export const useDeleteEntityMutation = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()
  const [deleteEntity] = useMutation(DELETE_ENTITY, {
    refetchQueries: [
      {
        query: ENTITY_TREE_DATA_FOR_COMPANY,
        variables: { companyId: customer?.id, language: locale },
        fetchPolicy: 'network-only',
      } /* ,
      {
        query: CUSTOMER_DATAPOINTS_QUERY,
        variables: {
          id: customer?.entity?.id || undefined
        }
      },
      {
        query: CUSTOMER_ENTITIES_QUERY,
        variables: {
          id: customer?.entity?.id || undefined
        }
      } */,
    ],
  })
  return async (id) => {
    const {
      data: {
        deleteEntity: { success, message },
      },
    } = await deleteEntity({
      variables: { id },
    })
    return { success, message }
  }
}

export const usePermissionCompanyEntities = (companyId) => {
  const { data, loading, error } = useQuery(PERMISSION_COMPANY_ENTITIES_QUERY, {
    variables: { companyId },
    skip: !companyId,
  })
  return { data: data?.permissionCompanyEntities, loading, error }
}

export const useCompanyEntities = (companyId) => {
  /* const { data, loading, error } = useQuery(COMPANY_ENTITIES_QUERY, {
    variables: { companyId },
    skip: !companyId,
    fetchPolicy: 'network-only',
  })
  return { data: data?.companyEntities, loading, error } */
  const { data, error, loading } = useQueryData({
    service: 'company_entities',
    payload: { companyId },
    deps: [companyId],
    skip: !companyId,
  })
  return { data: data?.entities, loading, error }
}

const UPDATE_ENTITY_MUTATION = gql`
  mutation updateEntity($object: AviaryEntityUpdateInput!) {
    updateEntity(object: $object) {
      id
      name
      rawName
      features {
        id
        name
        value
        enabled
        required
      }
      unit {
        id
        name
      }
      varia
    }
  }
`

export const useUpdateEntity = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()

  const [update, data] = useMutation(UPDATE_ENTITY_MUTATION, {
    refetchQueries: [
      {
        query: ENTITY_TREE_DATA_FOR_COMPANY,
        variables: { companyId: customer?.id, language: locale },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const updateFn = useCallback(
    (options = {}) => {
      return update({
        ...options,
        variables: {
          ...options.variables,
          language: locale,
        },
      })
    },
    [update, locale]
  )

  return [updateFn, data]
}

const CREATE_ENTITY_MUTATION = gql`
  mutation createEntity($object: AviaryEntityCreateInput!) {
    createEntity(object: $object) {
      id
      entityType {
        id
      }
    }
  }
`

export const useCreateEntity = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()

  const [create, data] = useMutation(CREATE_ENTITY_MUTATION, {
    refetchQueries: (result) => {
      const queries = []
      const typeIds = result.data.createEntity.entityType.id

      switch (typeIds) {
        case 11: // persona
        case 12: // vehicle
        case 13: // varia
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19: // varia
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
          queries.push({
            query: QUERY_NON_ASSET_ENTITIES,
            variables: { companyId: customer?.id, typeIds },
            fetchPolicy: 'network-only',
          })
          break
        default:
          queries.push({
            query: ENTITY_TREE_DATA_FOR_COMPANY,
            variables: { companyId: customer?.id, language: locale },
            fetchPolicy: 'network-only',
          })
      }

      return queries
    },
  })

  const createFn = useCallback(
    (options = {}) => {
      return create({
        ...options,
        variables: {
          ...options.variables,
          language: locale,
        },
      })
    },
    [create, locale]
  )

  return [createFn, data]
}

const ENTITY_TREE_DATA_FOR_COMPANY = gql`
  query entityTreeForCompany($companyId: Float!, $language: String!) {
    entities: settingsEntities(companyId: $companyId, language: $language) {
      id
      name
      type
      preset {
        id
        name
      }
      hierarchy
      entityTags
      countryInfo {
        countryImgName
      }
    }
  }
`

const ENTITY_TREE_DATA_FOR_COMPANY_WITH_TAGS = gql`
  query entityTreeForCompany($companyId: Float!, $language: String!) {
    entities: settingsEntities(companyId: $companyId, language: $language) {
      id
      name
      type
      preset {
        id
        name
      }
      hierarchy
      countryInfo {
        countryImgName
      }
    }
  }
`

export const useEntityTreeDataForCompany = (options = {}) => {
  const locale = useLocaleHook()

  const customer = useCurrentCustomer()

  const queryResult = useQuery(ENTITY_TREE_DATA_FOR_COMPANY, {
    ...options,
    variables: { companyId: customer?.id, language: locale },
    skip: options.skip || !customer?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    return { ...queryResult, data: queryResult?.data?.entities, refetch: queryResult?.refetch }
  }, [queryResult])
}

export const useTaggedEntityTreeDataForCompany = (options = {}) => {
  const locale = useLocaleHook()

  const customer = useCurrentCustomer()

  const queryResult = useQuery(ENTITY_TREE_DATA_FOR_COMPANY_WITH_TAGS, {
    ...options,
    variables: { companyId: customer?.id, language: locale },
    skip: options.skip || !customer?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    return { ...queryResult, data: queryResult?.data?.entities, refetch: queryResult?.refetch }
  }, [queryResult])
}

export const FRAGMENT_AVIARY_ENTITY_TAGS = gql`
  fragment Tags on AviaryEntity {
    tags: features(typeName: ["tag", "field"]) {
      id
      name
      tooltip
      value
      enabled
      readOnly
      required
      type {
        id
        name
      }
    }
  }
`

export const FRAGMENT_AVIARY_ENTITY_MARKERS = gql`
  fragment Markers on AviaryEntity {
    markers: features(typeName: ["marker"]) {
      id
      name
      tooltip
      enabled
      required
      type {
        id
      }
    }
  }
`

const QUERY_ENTITY_DETAIL = gql`
  ${FRAGMENT_AVIARY_ENTITY_TAGS}
  ${FRAGMENT_AVIARY_ENTITY_MARKERS}

  query entity($id: ID!, $language: String!, $companyId: ID, $filterTags: [String!]) {
    entity(id: $id, companyId: $companyId) {
      id
      name: rawName
      active
      remark
      type
      site {
        id
        name
      }
      building {
        id
        name
      }
      floor {
        id
        name
      }
      room {
        name
        id
      }
      createdAt
      updatedAt
      preset {
        id
        name(language: $language)
      }
      entityType {
        id
      }
      varia
      ...Tags
      ...Markers
      freeTags: tags
      hierarchy
      files {
        id
        type {
          id
        }
        file {
          id
          key
          name
          mimetype
        }
      }
      datapoints: presetDatapoints(language: $language) {
        id
        entity {
          id
          flowId
        }
        preset {
          id
          required
        }
        enabled
        name
        unit {
          id
          name
          difference
        }
        unitDisabled
        tags: features(typeName: ["tag", "field"]) {
          id
          name
          value
          enabled
          required
          type {
            id
            name
          }
        }
        markers: features(typeName: ["marker"]) {
          id
          name
          enabled
          required
          type {
            id
            name
          }
        }
      }
      datapointInfo: datapoints(filterTags: $filterTags) {
        id
        type
        name
        tags
      }
    }
  }
`

export const ENTITY_DETAILS_QUERY = gql`
  ${FRAGMENT_AVIARY_ENTITY_TAGS}
  ${FRAGMENT_AVIARY_ENTITY_MARKERS}
  query entity($id: ID!, $companyId: ID) {
    entity(id: $id, companyId: $companyId) {
      id
      name: rawName
      active
      remark
      type
      site {
        id
        name
      }
      building {
        id
        name
      }
      floor {
        id
        name
      }
      room {
        name
        id
      }
      createdAt
      updatedAt
      preset {
        id
      }
      entityType {
        id
      }
      varia
      ...Tags
      ...Markers
      freeTags: tags
      hierarchy
      files {
        id
        type {
          id
        }
        file {
          id
          key
          name
          mimetype
        }
      }
    }
  }
`

const QUERY_ENTITY_OPTIONS = gql`
  ${FRAGMENT_AVIARY_ENTITY_TAGS}
  ${FRAGMENT_AVIARY_ENTITY_MARKERS}

  query entityOptions($id: ID!, $companyId: ID) {
    entity(id: $id, companyId: $companyId) {
      id
      name: rawName
      remark
      type
      site {
        id
        name
      }
      building {
        id
        name
      }
      floor {
        id
        name
      }
      room {
        name
        id
      }
      createdAt
      updatedAt
      entityType {
        id
      }
      varia
      ...Tags
      ...Markers
    }
  }
`
const QUERY_ENTITY_FILES = gql`
  query entityFiles($id: ID!, $companyId: ID) {
    entity(id: $id, companyId: $companyId) {
      files {
        id
        type {
          id
        }
        file {
          id
          key
          name
          mimetype
        }
      }
    }
  }
`

const ACTIVITY_DIRECTIONS = gql`
  query ActivityDirections {
    activityDirections {
      title
      categories {
        name
        entityDetails {
          entityType {
            name
            id
          }
        }
        id
        order
      }
    }
  }
`

export const useEntityDetail = (id, options = {}, datapointInfo = false) => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()
  const result = useQuery(QUERY_ENTITY_DETAIL, {
    ...options,
    variables: {
      id,
      language: locale,
      companyId: customer?.id,
      ...(datapointInfo && { filterTags: [`equipRef=${id}`] }),
    },
    skip: options.skip || !id,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    const entity = result?.data?.entity

    return {
      ...result,
      data: entity
        ? {
            ...entity,
            tags: entity?.tags?.map((tag) => {
              const isSystem = tag.name.substr(-3) === 'Ref' || systemTags.includes(tag.name)

              return {
                ...tag,
                isSystem,
              }
            }),
          }
        : undefined,
    }
  }, [result])
}

export const useEntityDatapoints = () => {
  const [getEntityDatapoints, result] = useLazyQuery(ENTITY_DATAPOINTS_QUERY)

  return useMemo(() => {
    return [getEntityDatapoints, { ...result, data: result?.data?.entity }]
  }, [result, getEntityDatapoints])
}

export const useMoveEntity = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()
  const [moveEntity, data] = useMutation(MOVE_ENTITY, {
    refetchQueries: [
      {
        query: ENTITY_TREE_DATA_FOR_COMPANY,
        variables: { companyId: customer?.id, language: locale },
      },
    ],
  })

  const call = (id, destinationId) => {
    return moveEntity({
      variables: {
        id,
        destinationId,
        companyId: customer?.id || undefined,
      },
    })
  }

  return [call, data]
}

export const useEntityFeatureTypes = (typeName, options = {}) => {
  const result = useQuery(ENTITY_FEATURE_TYPES, {
    ...options,
    variables: { typeName },
  })

  return useMemo(() => {
    return { ...result, data: result?.data?.entityFeatureType }
  }, [result])
}

export const useEntityFeatureTypeDescription = (name) => {
  const result = useQuery(ENTITY_FEATURE_TYPE_DESCRIPTION, {
    variables: { name },
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    return { ...result, data: result?.data?.entityFeature }
  }, [result])
}

const QUERY_ENTITIES_SITES_AND_BUILDINGS_TREE = gql`
  query sitesAndBuildings($companyId: ID!) {
    entities(typeIds: [1, 4, 5, 6, 7, 8, 9, 10], companyId: $companyId) {
      id
      rawName
      type
      site {
        id
      }
    }
  }
`

export const useSitesAndBuildingsTree = () => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ENTITIES_SITES_AND_BUILDINGS_TREE, {
    variables: { companyId: customer?.id },
    skip: !customer?.id,
  })

  const treeData = useMemo(() => {
    if (!data?.entities) {
      return undefined
    }

    const tree = orderBy(data.entities, ['rawName']).reduce((map, entity) => {
      if (entity.type === 'site') {
        map[entity.id] = { ...entity, children: map[entity.id]?.children || [] }
      } else {
        const siteId = entity.site.id
        if (map[siteId]) {
          map[siteId].children.push(entity)
        } else {
          map[siteId] = { children: [entity] }
        }
      }

      return map
    }, {})

    return Object.values(tree)
  }, [data])

  return { treeData, data, ...rest }
}

export const QUERY_NON_ASSET_ENTITIES = gql`
  query nonAssetEntities($companyId: ID!, $typeIds: [ID!], $active: Boolean) {
    nonAssetEntities(companyId: $companyId, typeIds: $typeIds, active: $active) {
      id
      name
      type
      tags
      country {
        id
        name
      }
      namedReferences
      department {
        id
        name
      }
      vehicleFuelType {
        id
        name
      }
      vehicleType {
        id
        description
      }
      organisationObject {
        name
      }
      site {
        id
        name
      }
      entityType {
        id
      }
      active
    }
  }
`

export const useNonAssetEntities = (typeIds, { skip, filter: { active } = {} } = {}) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_NON_ASSET_ENTITIES, {
    variables: { companyId: customer?.id, typeIds, active },
    skip: skip || !customer?.id,
    nextFetchPolicy: 'network-only',
  })

  return { data: data?.nonAssetEntities, ...rest }
}

export const useLazyNonAssetEntities = () => {
  const customer = useCurrentCustomer()

  const [getNonAssetEntities, { data, ...rest }] = useLazyQuery(QUERY_NON_ASSET_ENTITIES, {
    variables: { companyId: customer?.id },
    nextFetchPolicy: 'network-only',
  })

  return [getNonAssetEntities, { data: data?.nonAssetEntities, ...rest }]
}

export const useEntityTypes = () => {
  return useMemo(() => {
    const items = ['assets', 'persona', 'vehicles', 'varia']
    return { data: items.map((key) => ({ key })), loading: false }
  }, [])
}

const QUERY_ENTITIES_BY_TYPE_IDS = gql`
  query entitiesByType($companyId: ID, $typeIds: [ID!], $siteId: ID) {
    entities(typeIds: $typeIds, companyId: $companyId, inverseFilter: false, siteId: $siteId) {
      id
      name
    }
  }
`

export const useEntitiesByTypeIds = (typeIds, { siteId } = {}) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ENTITIES_BY_TYPE_IDS, {
    variables: { typeIds, companyId: customer?.id, siteId },
    skip: !customer,
  })

  return { data: data?.entities, ...rest }
}

export const useStationaryCombustionMetersForEntity = (meterInfo) => {
  return useLazyQuery(STATIONARY_COMBUSTIONMETERS_FOR_ENTITY, {
    variables: { meterInfo },
    skip: !meterInfo,
  })
}

export const usePurchasedEnergyMetersForEntity = (meterInfo) => {
  return useLazyQuery(PURCHASED_ENERGY_METERS_FOR_ENTITY, {
    variables: { meterInfo },
    skip: !meterInfo,
  })
}

export const useGhgData = ({
  datapoints,
  datapointsGroups,
  emissionFactor,
  timeRange,
  granularity = '1h',
  defaultTimeRange,
  groupProperties,
  skip = false,
  timezoneOffset,
  calculationMethod,
  returnUnitId: returnUnitDefault,
  widgetType,
}) => {
  const _skip = skip || !datapoints?.length
  const { startTime, endTime } = useDefaultTimeRange(timeRange, defaultTimeRange, _skip)

  const { data, error, loading, refetch } = useQueryData({
    api: 'sust',
    service: 'ghgtimeseries',
    payload: {
      data:
        datapoints &&
        datapoints.map(
          ({
            id,
            scopeId,
            entityId,
            returnUnitId,
            organisationId,
            emissionFactors,
            datapointId,
            activityDirectionId,
            categoryId,
            entityTypeId,
            tags,
            defaultCalculationMethod,
            scope3SubCategory,
            groupId,
          }) => {
            return {
              id,
              entityId,
              datapointId,
              activityDirectionId,
              categoryId,
              entityTypeId,
              calculationMethod: groupProperties?.[id]
                ? groupProperties[id].defaultCalculationMethod || calculationMethod
                : defaultCalculationMethod || calculationMethod,
              emissions: emissionFactor
                ? [emissionFactor]
                : groupProperties?.[id]?.emissionFactors || emissionFactors || ['total'],
              scopeId: parseInt(scopeId),
              organisationId,
              granularity,
              startTime,
              endTime,
              timezoneOffset,
              returnUnitId:
                returnUnitId ||
                returnUnitDefault ||
                (groupProperties && groupProperties[id]?.returnUnitId) ||
                undefined,
              ...(tags && { tags: JSON.stringify(tags) }),
              scope3SubCategory,
              groupId,
            }
          }
        ),
      widgetType,
    },
    deps: [
      startTime,
      endTime,
      granularity,
      datapoints,
      datapointsGroups,
      calculationMethod,
      returnUnitDefault,
    ],
    skip: _skip,
  })

  return { data: data?.data, loading, error, refetch }
}

export const useComparedGhgData = ({
  datapoints,
  datapointsGroups,
  emissionFactor,
  timeRange,
  granularity = '1h',
  groupProperties,
  skip = false,
  timezoneOffset,
  calculationMethod,
  widgetType,
}) => {
  const _skip = skip || !datapoints?.length
  const { startTime, endTime, preset } = timeRange
  const { duration: presetDuration } =
    {
      thisDay: {
        duration: 86400000,
        unit: 'day',
      },
      thisWeek: {
        duration: 604800000,
        unit: 'week',
      },
      thisMonth: {
        duration: 2629743000,
        unit: 'MS',
      },
    }[preset] || {}

  const duration = presetDuration || endTime - startTime

  const { data, error, loading, refetch } = useQueryData({
    service: 'ghgtimeseries',
    api: 'sust',
    payload: {
      data:
        datapoints &&
        datapoints.reduce(
          (
            data,
            {
              id,
              scopeId,
              entityId,
              groupId,
              tags,
              organisationId,
              offsets,
              emissionFactors = ['total'],
              datapointId,
              returnUnitId,
              defaultCalculationMethod,
            }
          ) => {
            if (offsets?.length) {
              offsets.forEach(
                ({ unit, value, defaultCalculationMethod: offsetCalculationMethod }) => {
                  const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
                  const offsetEndTime = offsetStartTime + duration
                  data.push({
                    id: `${id}:${offsetStartTime}`,
                    entityId,
                    datapointId,
                    organisationId,
                    returnUnitId,
                    emissions: emissionFactor
                      ? [emissionFactor]
                      : (groupProperties && groupProperties[id]?.emissionFactors) ||
                        emissionFactors,
                    scopeId: parseInt(scopeId),
                    granularity,
                    startTime: offsetStartTime,
                    endTime: offsetEndTime,
                    timezoneOffset,
                    calculationMethod:
                      offsetCalculationMethod || defaultCalculationMethod || calculationMethod,
                    ...(tags && { tags: JSON.stringify(tags) }),
                  })
                }
              )
            }

            if (groupProperties[id]?.offsets?.length) {
              groupProperties[id].offsets.forEach(
                ({ unit, value, defaultCalculationMethod: offsetCalculationMethod }, index) => {
                  const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
                  const offsetEndTime = offsetStartTime + duration

                  data.push({
                    id: `offset${index}-${id}:${offsetStartTime}`,
                    entityId,
                    datapointId,
                    organisationId,
                    emissions: emissionFactor
                      ? [emissionFactor]
                      : (groupProperties && groupProperties[id]?.emissionFactors) ||
                        emissionFactors,
                    scopeId: parseInt(scopeId),
                    granularity,
                    calculationMethod:
                      offsetCalculationMethod || defaultCalculationMethod || calculationMethod,
                    startTime: offsetStartTime,
                    endTime: offsetEndTime,
                    timezoneOffset,
                    ...(tags && { tags: JSON.stringify(tags) }),
                  })
                }
              )
            }

            data.push({
              id,
              entityId,
              datapointId,
              organisationId,
              returnUnitId,
              groupId,
              emissions: emissionFactor
                ? [emissionFactor]
                : (groupProperties && groupProperties[id]?.emissionFactors) || emissionFactors,
              scopeId: parseInt(scopeId),
              granularity,
              calculationMethod: defaultCalculationMethod || calculationMethod,
              startTime,
              endTime,
              timezoneOffset,
              ...(tags && { tags: JSON.stringify(tags) }),
            })

            return data
          },
          []
        ),
      widgetType,
    },
    deps: [startTime, endTime, granularity, datapoints, datapointsGroups, calculationMethod],
    skip: _skip,
  })

  return { data: data?.data, loading, error, refetch }
}

const QUERY_IS_TAG_UNIQUE = gql`
  query isTagUnique($tag: String!, $value: String!) {
    findEntitiesByTag(tag: $tag, value: $value) {
      id
    }
  }
`

export const useIsTagUnique = ({ tag, id }) => {
  const client = useApolloClient()

  const isUnique = async (value, options) => {
    const tagName = options?.tag ?? tag
    const { data } = await client.query({
      query: QUERY_IS_TAG_UNIQUE,
      variables: { tag: tagName, value },
      fetchPolicy: 'network-only',
    })

    const entities = data?.findEntitiesByTag?.filter((entity) => entity.id !== id)

    return entities?.length === 0
  }

  return isUnique
}

export const useEntityOptions = (entity) => {
  const locale = useLocaleHook()

  const { data } = useQuery(QUERY_ENTITY_OPTIONS, {
    variables: {
      id: entity,
      language: locale,
    },
    skip: !entity,
  })
  return { data: data?.entity }
}

export const useEntityFiles = (entity) => {
  const locale = useLocaleHook()

  const { data } = useQuery(QUERY_ENTITY_FILES, {
    variables: {
      id: entity,
      language: locale,
    },
    skip: !entity,
  })
  return { data: data?.entity }
}

export const useNonDatapointEntitiesForCompany = () => {
  const [getFilteredEntities, { data, loading }] = useLazyQuery(
    NON_DATAPOINT__ENTITIES_FOR_COMPANY_QUERY
  )
  return [getFilteredEntities, { data: data?.nonDatapointEntitiesForCompany, loading }]
}

export const useFilteredEntities = () => {
  const [getFilteredEntities, { data }] = useLazyQuery(FILTERED_ENTITIES_QUERY)
  return [getFilteredEntities, { data: data?.filteredEntities }]
}

export const useNonAssetDatapoints = () => {
  const [getFilteredEntities, { data }] = useLazyQuery(FILTERED_ENTITIES_DATAPOINTS_QUERY)
  const datapoints = useMemo(
    () =>
      data?.filteredEntities
        ? data?.filteredEntities.reduce((datapoints, entity) => {
            datapoints.push(
              ...entity.datapoints.map((datapoint) => ({
                ...datapoint,
                tags: { ...datapoint.tags, ...entity.tags },
              }))
            )
            return datapoints
          }, [])
        : [],
    [data?.filteredEntities]
  )

  return [getFilteredEntities, { datapoints }]
}

const QUERY_ENTITY_VARIA = gql`
  query entityVaira($companyId: ID!, $id: ID!) {
    entity(companyId: $companyId, id: $id) {
      varia
      presetDatapoints(language: "en-US") {
        id
        preset {
          id
        }
        entity {
          id
          lastHistoryRecord {
            value
            time
          }
        }
      }
    }
  }
`

export const useEntityVaria = (id) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ENTITY_VARIA, {
    variables: { companyId: customer?.id, id },
    skip: !customer?.id || !id,
  })

  return { data: data?.entity, ...rest }
}

const QUERY_ENTITY_LAST_HISTORY_RECORD = gql`
  query entityLastHistoryRecord($companyId: ID!, $id: ID!) {
    entity(companyId: $companyId, id: $id) {
      id
      lastHistoryRecord {
        time
        value
      }
    }
  }
`

export const useEntitytLastHistoryRecord = (id) => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ENTITY_LAST_HISTORY_RECORD, {
    variables: { id, companyId: customer?.id },
    skip: !id || !customer?.id,
    fetchPolicy: 'network-only',
  })

  return { data: data?.entity?.lastHistoryRecord, ...info }
}

export const useAssetDatapoints = () => {
  const [getNonAssetDatapoints, { data, loading }] = useLazyQuery(RAW_ALLOWED_DATAPOINTS_FOR_TAGS)

  return [getNonAssetDatapoints, { datapoints: data?.rawAllowedDatapointsForTags, loading }]
}

export const useUpstreamDownstreamEntities = () => {
  return useQuery(ACTIVITY_DIRECTIONS)
}

const ACTIVITY_CATEGORIES = gql`
  query ActivityCategories {
    activityCategories {
      id
      name
      order
      entityDetails {
        entityType {
          name
          id
        }
      }
      direction {
        id
        title
      }
    }
  }
`

export const useActivityCategories = () => {
  const { data, ...rest } = useQuery(ACTIVITY_CATEGORIES)
  const activityCategories = data?.activityCategories?.map((cat) => ({
    ...cat,
    enabled: ghgScope3EnabledCategories.includes(parseInt(cat.id)),
  }))

  return { data: data ? { activityCategories } : undefined, ...rest }
}

export const SCOPE_3_ENTITIES = gql`
  query GhgScope3Entities(
    $activityCategoryId: ID!
    $companyId: ID!
    $entityTypeId: ID
    $organisationId: ID
    $entityId: ID
  ) {
    ghgScope3Entities(
      activityCategoryId: $activityCategoryId
      companyId: $companyId
      entityTypeId: $entityTypeId
      organisationId: $organisationId
      entityId: $entityId
    ) {
      id
      name
      tags
      datapoints {
        tags
        id
        name
        unit {
          name
        }
      }
    }
  }
`

export const useScope3Datapoints = () => {
  const customer = useCurrentCustomer()
  const [getScope3Entities, { data }] = useLazyQuery(SCOPE_3_ENTITIES, {
    variables: {
      companyId: customer?.id,
    },
  })
  const datapoints = useMemo(
    () =>
      data?.ghgScope3Entities
        ? data?.ghgScope3Entities.reduce((datapoints, entity) => {
            datapoints.push(
              ...entity.datapoints.map((datapoint) => ({
                ...datapoint,
                tags: { ...datapoint.tags, ...entity.tags },
              }))
            )
            return datapoints
          }, [])
        : [],
    [data?.ghgScope3Entities]
  )

  return [getScope3Entities, { datapoints }]
}

export const useMetersHierarchy = (meters) => {
  const { data, loading, errors } = useQuery(METER_HIERARCHY, {
    variables: {
      rootMeters: meters.map(({ id }) => ({ id })),
    },
  })

  return {
    data: data?.meterHierarchy,
    loading,
    errors,
  }
}

const CREATE_ENTITY_DATAPOINTS_MUTATION = gql`
  mutation createEntityDatapoints($object: [AviaryEntityCreateInput!]!) {
    createEntityDatapoints(object: $object)
  }
`

export const useCreateEntityDatapoints = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()

  const [create, data] = useMutation(CREATE_ENTITY_DATAPOINTS_MUTATION, {
    refetchQueries: (result) => {
      const queries = []
      const typeIds = result.data.createEntityDatapoints

      switch (typeIds) {
        case 11: // persona
        case 12: // vehicle
        case 13: // varia
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19: // varia
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
          queries.push({
            query: QUERY_NON_ASSET_ENTITIES,
            variables: { companyId: customer?.id, typeIds },
            fetchPolicy: 'network-only',
          })
          break
        default:
        /* queries.push({
            query: ENTITY_TREE_DATA_FOR_COMPANY,
            variables: { companyId: customer?.id, language: locale },
            fetchPolicy: 'network-only',
          }) */
      }

      return queries
    },
  })

  const createFn = useCallback(
    (options = {}) => {
      return create({
        ...options,
        variables: {
          ...options.variables,
          language: locale,
        },
      })
    },
    [create, locale]
  )

  return [createFn, data]
}

export const useUpdateEntityDatapoints = () => {
  const locale = useLocaleHook()
  const customer = useCurrentCustomer()

  const [update, data] = useMutation(UPDATE_ENTITY_DATAPOINTS_MUTATION, {
    refetchQueries: [
      {
        query: ENTITY_TREE_DATA_FOR_COMPANY,
        variables: { companyId: customer?.id, language: locale },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const updateFn = useCallback(
    (options = {}) => {
      return update({
        ...options,
        variables: {
          ...options.variables,
          language: locale,
        },
      })
    },
    [update, locale]
  )

  return [updateFn, data]
}

const UPDATE_ENTITY_DATAPOINTS_MUTATION = gql`
  mutation updateEntityDatapoints($object: [AviaryEntityUpdateInput!]!) {
    updateEntityDatapoints(object: $object)
  }
`
