import React from 'react'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { Field } from 'components/Form'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'

import { TreeSelectStyled } from './styled'

const findItemInTree = (data, value) => {
  for (const item of data) {
    if (item.value === value) {
      return item
    }

    if (item.children) {
      return findItemInTree(item.children, value)
    }
  }
}

const TreeSelectField = ({
  children,
  name,
  treeData,
  defaultValue,
  value = true,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  label,
  loading,
  allowClear,
  showSearch,
  ...props
}) => {
  return (
    <Field {...props} label={label}>
      {({ control, errors, isEditMode, loading: formLoading }) => {
        const error = get(errors, name)

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              if (loading || formLoading) {
                return <Skeleton width="200px" />
              }

              if (isEditMode) {
                return (
                  <>
                    <TreeSelectStyled
                      value={formFieldValue}
                      treeData={treeData}
                      onChange={fromFieldOnChange}
                      allowClear={allowClear}
                      showSearch={showSearch}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              if (treeData) {
                const item = findItemInTree(treeData, formFieldValue)
                return <span>{item?.title}</span>
              }

              return <span>-</span>
            }}
          />
        )
      }}
    </Field>
  )
}

TreeSelectField.propTypes = {
  showSearch: PropTypes.bool,
}

export default TreeSelectField
