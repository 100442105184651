import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

const AccordeonContext = createContext(null)
export const useAccordeonContext = () => useContext(AccordeonContext) || {}

const AccordeonProvider = ({ children }) => {
  const [recalculateHeight, setRecalculateHeight] = useState(0)
  const timeout = useRef()
  const handleRecalculateHeight = () => {
    timeout.current = setTimeout(() => {
      setRecalculateHeight((state) => state + 1)
    }, 300)
  }
  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])
  return (
    <AccordeonContext.Provider value={{ recalculateHeight, handleRecalculateHeight }}>
      {children}
    </AccordeonContext.Provider>
  )
}

export default AccordeonProvider
