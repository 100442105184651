export const chartOptions = ({ theme, series = [{ type: 'solidgauge' }] }) => ({
  title: undefined,
  chart: {
    backgroundColor: 'transparent',
  },
  pane: {
    size: '100%',
    center: ['50%', '55%'],
    startAngle: -115,
    endAngle: 115,
    background: [
      {
        innerRadius: '85%',
        outerRadius: '95%',
        shape: 'arc',
        borderColor: 'transparent',
        backgroundColor: theme.color.softerText,
      },
    ],
  },

  exporting: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    stops: [
      [0, theme.color.success],
      [0.33, theme.color.warning],
      [0.66, theme.color.critical],
      [1, theme.color.error],
    ],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickAmount: 2,
    labels: {
      y: 30,
      x: 0,
      distance: '80%',
    },
    min: 0,
    endOnTick: false,
  },
  plotOptions: {
    solidgauge: {
      innerRadius: '78%',
      rounded: true,
      linecap: 'round',
      dataLabels: {
        borderWidth: 0,
        useHTML: true,
        allowOverlap: true,
      },
    },
  },
  credits: {
    enabled: false,
  },
  series,
})
