import React, { useState } from 'react'

import { AnimatePresence } from 'framer-motion'
import { get } from 'lodash/object'
import { injectIntl } from 'react-intl'

import RatioBarStyled, { RadioBarSegmentLabel, RatioBarSegment } from './styled'

export const colorGenerator = (key, index = 0) => {
  let charSum = index
  for (let i = 0; i < (key ? key.length : (index + 1) * 2); i++) {
    charSum += key ? key.charCodeAt(i) : (index + 1) * 2
  }
  const colorIndex = Math.round(10 * Math.sin(charSum) + 10) || 10

  return [
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#26C6DA',
    '#26A69A',
    '#66BB6A',
    '#9CCC65',
    '#D4E157',
    '#FFCA28',
    '#FFA726',
    '#FF7043',
    '#8D6E63',
    '#BDBDBD',
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#26C6DA',
    '#26A69A',
    '#66BB6A',
    '#9CCC65',
    '#D4E157',
    '#FFCA28',
    '#FFA726',
    '#FF7043',
    '#8D6E63',
    '#BDBDBD',
  ][colorIndex]
}
const RatioBar = ({
  segments = [],
  randomColors = false,
  labelKey,
  intl,
  errorMessage,
  ...props
}) => {
  const [hoveredElement, setHoveredElement] = useState(null)
  const segmentOffset = `${segments.reduce(
    (offset, segment, index) => (index > hoveredElement - 2 ? offset : offset + segment.percentage),
    0
  )}%`
  const total = segments.reduce((total, segment) => total + segment.percentage, 0)
  const error = total > 100 && {
    type: 'error',
    message: errorMessage || intl.formatMessage({ id: 'totalExceedsAmount' }, { amount: '100%' }),
  }

  return (
    <RatioBarStyled className="RatioBar" {...props} error={!!error}>
      <div className="RatioBar__wrapper">
        {segments.map(({ percentage, color, id, ...segment }, index) => {
          const segmentColor =
            color || (randomColors && colorGenerator(id, segments.length - index))
          return (
            <RatioBarSegment
              key={`segment-${index}`}
              percentage={percentage}
              color={segmentColor}
              error={!!error}
              offset={index > 0 ? segments[index - 1].percentage : 0}
              onMouseEnter={() => setHoveredElement(index + 1)}
              onMouseLeave={() => setHoveredElement(null)}
            />
          )
        })}
      </div>
      <AnimatePresence>
        {(error || (hoveredElement && labelKey)) && (
          <RadioBarSegmentLabel
            error={!!error}
            element={segments[hoveredElement - 1]}
            animate={{
              opacity: 1,
              scale: 1,
              left: segmentOffset,
            }}
            initial={{
              opacity: 0,
              scale: 0,
              left: segmentOffset,
            }}
            exit={{
              opacity: 0,
              scale: 0,
              left: segmentOffset,
            }}
          >
            <span className="RatioBarSegment__error">{error && error.message}</span>
            {hoveredElement && get(segments[hoveredElement - 1], labelKey)}
            {hoveredElement && (
              <span className="RatioBarSegment__percentage">
                {segments[hoveredElement - 1].percentage}%
              </span>
            )}
          </RadioBarSegmentLabel>
        )}
      </AnimatePresence>
      <span className="RatioBar__total">{total}%</span>
    </RatioBarStyled>
  )
}

export default injectIntl(RatioBar)
