export const config = {
  displayProps: {},
  editProps: {
    name: 'widget.emptySpace',
    category: 'widget.others',
    keywords: ['empty', 'placeholder'],
    img: '/resources/images/widgets/empty.png',
    info: {
      'fr-FR': '',
      'en-US': '',
      'nl-NL': '',
    },
    default: {
      type: 'Empty',
      title: 'widget.emptySpace',
    },
    preview: {},
    fields: [
      {
        category: 'widget.general',
        componentName: 'Text',
        componentProps: {
          text: { formatted: 'widget.noConfigurationOptions' },
        },
      },
    ],
  },
}

export default config
