import {
  configFieldConvertor,
  ghgTrendFieldConvertors,
} from '../../../Dashboard/utils/common/helpers'
import { config as barChart } from './../BarChart/config'

export const config = {
  ...barChart,
  editProps: {
    ...barChart.editProps,
    category: 'widget.ghgCharts',
    keywords: [...barChart.editProps.keywords, 'ghg'],
    default: {
      ...barChart.editProps.default,
      dataType: 'ghg',
      type: 'GhgBarChart',
    },
    fields: configFieldConvertor(barChart.editProps.fields, ghgTrendFieldConvertors),
  },
}

export default config
