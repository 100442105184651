import React from 'react'

import { useFormContext } from 'react-hook-form'

import ImageField from 'components/Form/fields/ImagesField/ImageField'
import Upload from 'components/Global/FormField/Upload'
import Icon from 'components/Global/Icon'

import { ImagesFieldStyled, ImageThumb, ImageThumbButtons } from './styled'

const ImagesField = ({ value, name, onUpload, ...fieldProps }) => {
  const { isEditMode, watch } = useFormContext()
  const fieldValue = value || watch(name)

  return (
    <ImagesFieldStyled {...fieldProps}>
      {isEditMode ? (
        <>
          {fieldValue.map(({ itemIndex, file }) => (
            <ImageField key={file.id} name={`${name}.${itemIndex}`} width={150} />
          ))}
          <ImageThumb width={150}>
            <Upload
              className="ImageThumb__thumb upload"
              placeholderIcon="lnr lnr-upload"
              onChange={onUpload}
              hidePreview
              hideLoading
            />
            <ImageThumbButtons>
              <Icon icon="lnr lnr-upload" />
            </ImageThumbButtons>
          </ImageThumb>
        </>
      ) : (
        <>
          {fieldValue.map(({ itemIndex, file }) => (
            <ImageField key={file.id} name={`${name}.${itemIndex}`} width={150} />
          ))}
        </>
      )}
    </ImagesFieldStyled>
  )
}

export default ImagesField
