import React, { useMemo } from 'react'

import get from 'lodash/get'

import { useActiveConversionFactors } from 'services/conversionFactors'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import ValuePicker from 'components/Form/components/ValuePicker'

const UnitPicker = ({
  value,
  dataKey = 'datapoints',
  subPath,
  selectionKeys = { value: 'id', label: 'name' },
  onChange,
  startUnit: startUnitDefault,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, selectedDatapointMap: {} } },
  } = useDashboard()
  let startUnit = startUnitDefault

  if (!startUnit) {
    const groupDatapointKey = dataKey.includes('Groups') && dataKey.replace('Groups', '')
    const groupDatapointId =
      groupDatapointKey &&
      get(
        elementInEditMode.preview,
        `${subPath ? `${subPath}.` : ''}${dataKey}[${
          elementInEditMode.selectedDatapointMap[dataKey]
        }].datapoints[0]`
      )
    const groupDatapointIndex =
      groupDatapointKey &&
      elementInEditMode.preview[groupDatapointKey].findIndex(({ id }) => id === groupDatapointId)
    const startUnitDataKey = groupDatapointId
      ? `${subPath ? `${subPath}.` : ''}${groupDatapointKey}[${groupDatapointIndex}].unit`
      : `${subPath ? `${subPath}.` : ''}${dataKey}[${
          elementInEditMode.selectedDatapointMap[dataKey] || 0
        }].unit`
    startUnit = get(elementInEditMode.preview, startUnitDataKey, groupDatapointKey)
  }

  const { data } = useActiveConversionFactors(!startUnit)

  const options = useMemo(() => {
    if (!data || !startUnit) {
      return []
    }
    return data.reduce(
      (options, { id, endUnit, ...factor }) => {
        if (factor.startUnit.name === startUnit) {
          options.push(endUnit)
        }
        return options
      },
      [{ name: `${startUnit} (default)` }]
    )
  }, [startUnit, data])

  const handleOnChange = (value) => {
    onChange(value)
  }

  return (
    <ValuePicker
      options={options}
      value={value}
      selectionKeys={selectionKeys}
      allowClear
      placeholder={startUnit ? `${startUnit} (default)` : null}
      onChange={handleOnChange}
    />
  )
}

export default UnitPicker
