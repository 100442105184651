import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FAIcon = (icon) => {
  if (!icon || !icon.icon) {
    return null
  }
  const _icon = icon?.icon || icon
  const iconArr = _icon.replace('fa-', '').split(' ')
  const style = iconArr[0]
  const code = iconArr[1]
  return <FontAwesomeIcon className="Icon" icon={[style, code]} />
}

export default FAIcon
