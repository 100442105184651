import React, { useState } from 'react'

import { useGhgData } from '../../../../services/entities'
import {
  getComparatorCalculatedValue,
  getDataForEmissionFactor,
} from '../../../../util/datapointCalculationFunctions'
import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { getGranularity } from '../../../Dashboard/utils/common/helpers'
import { useComparatorValues } from '../Comparator'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import ColorRangeSlider from './ColorRangeSlider'
import { getMinMaxByWidgetType, modes } from './constants'

const GhgMinMaxColorRangeSlider = ({ onChange, value }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const [mode, setMode] = useState('percentage')

  const {
    datapoints,
    comparators,
    minComparators,
    maxComparators,
    emissionFactor = 'total',
    comparisonPeriod,
    returnUnitId,
    customLogic,
    decimals,
    deviation,
    comparatorsMethod,
    minComparatorsMethod,
    maxComparatorsMethod,
    comparatorsCalculationMethod,
    minComparatorsCalculationMethod,
    maxComparatorsCalculationMethod,
  } = elementInEditMode.preview

  const { data } = useGhgData({
    datapoints,
    emissionFactor,
    returnUnitId,
    granularity: getGranularity({
      defaultStartTime: { preset: 'today' },
      type: 'ghg',
    }),
    defaultTimeRange: { preset: 'today' },
    options: {
      poll: true,
    },
    calculationMethod: 'mean',
    widgetType: 'ghgGauge',
  })

  const singleComparator = typeof deviation === 'number'

  const { data: minComparatorsData } = useComparatorValues({
    comparator: minComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: !singleComparator && 'ghg',
    emissionFactor: emissionFactor || 'total',
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: minComparatorsCalculationMethod,
    widgetType: 'ghgGaugeMinComparator',
  })

  const { data: maxComparatorsData } = useComparatorValues({
    comparator: singleComparator ? comparators : maxComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor: emissionFactor || 'total',
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: singleComparator
      ? comparatorsCalculationMethod
      : maxComparatorsCalculationMethod,
    widgetType: 'ghgAbsoluteMaxComparator',
  })

  const roundedMinimum = getComparatorCalculatedValue(
    getDataForEmissionFactor(minComparatorsData, emissionFactor),
    {
      method: minComparatorsMethod,
      customLogic,
      decimals,
    }
  )

  const roundedMaximum = getComparatorCalculatedValue(
    getDataForEmissionFactor(maxComparatorsData, emissionFactor),
    {
      method: singleComparator ? comparatorsMethod : maxComparatorsMethod,
      customLogic,
      decimals,
    }
  )

  const onSelectType = (e, { value }) => {
    setMode(value)
  }

  return (
    <>
      <CheckboxSelector
        className="CheckboxSelector"
        options={modes}
        value={mode}
        onSelect={onSelectType}
      />
      <ColorRangeSlider
        onChange={onChange}
        value={value}
        tickRange={
          mode === 'percentage'
            ? [0, 100]
            : getMinMaxByWidgetType(roundedMinimum, roundedMaximum, deviation)
        }
        unit={mode === 'percentage' ? '%' : data?.[0]?.returnUnit?.name || 'kgCO2e'}
        mode={mode}
      />
    </>
  )
}

export default GhgMinMaxColorRangeSlider
