import React from 'react'

export const HvacStandby = () => (
  <svg width="30px" height="25px" viewBox="0 25 479.52 399.6">
    <defs />
    <g id="g3760" transform="matrix(0.9365625 0 0 0.78046875 0 0)">
      <path
        id="path3756"
        transform="translate(28.208302, 126.23796)"
        d="M332.516 308.254L332.516 166.809L349.772 181.795L368.64 160.068L184.32 0L0 160.068L18.8683 181.794L36.1246 166.808L36.1246 308.254M64.9001 308.254L64.9001 141.819L184.32 38.1125L303.74 141.819L303.74 308.254"
      />
      <g id="g3851" transform="matrix(3.1613422 0 0 2.7703448 373.92826 286.15496)">
        <g id="g3849">
          <circle
            id="circle3845"
            transform="translate(22.179001, 2.842170943e-14)"
            r="4.507"
            cx="4.507"
            cy="4.507"
          />
          <path
            id="path3847"
            transform="translate(15.459010339, 11.000480306)"
            d="M12.797 0.16252C11.674 -0.0654803 10.453 -0.0554803 9.34999 0.20452C1.85699 1.08252 -0.57601 9.75552 0.11099 16.3685C0.40899 19.2275 4.91599 19.2575 4.61499 16.3685C4.36499 13.9585 4.47199 10.3215 5.75299 7.73652C5.75299 10.8785 5.75299 14.0205 5.75299 17.1615C5.75299 17.2725 5.76399 17.3765 5.76899 17.4835C5.76599 17.5345 5.75399 17.5775 5.75399 17.6295C5.75399 25.1085 5.74099 32.5845 5.43199 40.0575C5.29499 43.3795 10.446 43.3665 10.582 40.0575C10.824 34.2005 10.885 28.3405 10.899 22.4795C11.143 22.4955 11.387 22.4955 11.631 22.4815C11.646 28.3425 11.705 34.2025 11.945 40.0575C12.082 43.3665 17.233 43.3795 17.095 40.0575C16.786 32.5845 16.775 25.1085 16.775 17.6295C16.775 17.3975 16.744 17.1865 16.697 16.9835C16.69 13.7365 16.566 10.4865 16.604 7.24152C18.138 9.83852 18.278 13.7995 18.012 16.3665C17.71 19.2535 22.218 19.2245 22.516 16.3665C23.219 9.61652 20.669 0.71852 12.797 0.16252Z"
          />
        </g>
      </g>
    </g>
    <g id="g3762" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3764" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3766" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3768" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3770" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3772" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3774" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3776" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3778" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3780" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3782" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3784" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3786" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3788" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3790" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
  </svg>
)
