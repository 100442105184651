import { useCallback } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import { useCurrentCustomer } from './store'

const QUERY_DEFAULT_CONVERSION_FACTORS = gql`
  query defaultConversionFactors {
    defaultConversionFactors {
      id
      startDate
      multiplyFactor
      operation
      startUnit {
        id
        name
      }
      endUnit {
        id
        name
      }
    }
  }
`

const QUERY_ACTIVE_CONVERSION_FACTORS = gql`
  query activeConversionFactors {
    activeConversionFactors {
      id
      operation
      multiplyFactor
      startUnit {
        name
        id
      }
      endUnit {
        name
        id
      }
    }
  }
`
const QUERY_CONVERSION_FACTORS_BY_TYPE = gql`
  query conversionFactorsByType($type: String!) {
    conversionFactorsByType(type: $type) {
      id
      value
      label
      country
      year
    }
  }
`

export const useConversionFactorsByType = (type, skip) => {
  const { data, ...rest } = useQuery(QUERY_CONVERSION_FACTORS_BY_TYPE, {
    variables: {
      type,
    },
    fetchPolicy: 'network-only',
    skip,
  })
  return { data: data?.conversionFactorsByType, ...rest }
}

export const useActiveConversionFactors = (skip = false) => {
  const { data, ...rest } = useQuery(QUERY_ACTIVE_CONVERSION_FACTORS, {
    fetchPolicy: 'network-only',
    skip,
  })
  return { data: data?.activeConversionFactors, ...rest }
}

export const useDefaultConversionFactors = () => {
  const { data, ...rest } = useQuery(QUERY_DEFAULT_CONVERSION_FACTORS)
  return { data: data?.defaultConversionFactors, ...rest }
}

const QUERY_DEFAULT_CONVERSION_FACTOR_BY_ID = gql`
  query defaultConversionFactor($id: ID!) {
    defaultConversionFactor(id: $id) {
      id
      startDate
      multiplyFactor
      operation
      startUnit {
        id
        name
      }
      endUnit {
        id
        name
      }
      entity {
        id
      }
      energyComposition {
        id
        percentage
        energyType {
          id
          energySource
        }
      }
    }
  }
`

export const useDefaultConversionFactorById = (id) => {
  const { data, ...rest } = useQuery(QUERY_DEFAULT_CONVERSION_FACTOR_BY_ID, {
    variables: { id },
    skip: !id,
  })
  return { data: data?.defaultConversionFactor, ...rest }
}

const MUTATION_UPDATE_DEFAULT_CONVERSION_FACTOR = gql`
  mutation updateDefaultConversionFactor($id: ID!, $data: ConversionFactorUpdateInput!) {
    updateDefaultConversionFactor(id: $id, data: $data) {
      id
      startDate
      multiplyFactor
      operation
      readOnly
      startUnit {
        id
        name
      }
      endUnit {
        id
        name
      }
      entity {
        id
      }
      energyComposition {
        id
        percentage
        energyType {
          id
          energySource
        }
      }
    }
  }
`

export const useUpdateDefaultConversionFactor = (id) => {
  const customer = useCurrentCustomer()

  const [call, info] = useMutation(MUTATION_UPDATE_DEFAULT_CONVERSION_FACTOR, {
    refetchQueries: [
      {
        query: QUERY_DEFAULT_CONVERSION_FACTORS,
        variables: { companyId: customer?.id },
        skip: !customer?.id,
      },
    ],
  })

  const save = useCallback((data) => call({ variables: { id, data } }), [call, id])

  return [save, info]
}

const MUTATION_CREATE_DEFAULT_CONVERSION_FACTOR = gql`
  mutation createDefaultConversionFactor($data: ConversionFactorCreateInput!) {
    createDefaultConversionFactor(data: $data) {
      id
    }
  }
`

export const useCreateDefaultConversionFactor = () => {
  const customer = useCurrentCustomer()

  const [call, info] = useMutation(MUTATION_CREATE_DEFAULT_CONVERSION_FACTOR, {
    refetchQueries: [
      {
        query: QUERY_DEFAULT_CONVERSION_FACTORS,
        variables: { companyId: customer?.id },
        skip: !customer?.id,
      },
    ],
  })

  const create = useCallback(
    (data) => call({ variables: { data: { company: { id: customer?.id }, ...data } } }),
    [call, customer]
  )

  return [create, info]
}
