import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Caption from 'components/Global/Caption'

const getBackgroundColor = ({ theme, color, caption }) => {
  if (caption?.color) {
    return caption.color
  }
  if (color) {
    return color
  }
  return theme.color.backgroundFront
}

const getForegroundColor = ({ theme, fontColor, caption }) => {
  if (caption?.color) {
    return theme.color.backgroundFront
  }
  if (fontColor) {
    return fontColor
  }
  return theme.color.mainText
}

const titleIcon = (color, bgColor) => css`
  background: ${color};
  color: ${bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  min-width: 2.2rem;
  height: 2.2rem;
  border-radius: 0.3rem;
  justify-self: end;
  margin-left: 0.5rem;
`
const captionIcon = (color, bgColor) => css`
  background: ${color};
  color: ${bgColor};
  width: auto;
  padding: 0.5rem;
`

export const StatusStyled = styled.div`
  position: relative;
  transition: box-shadow 0.2s ease, color 0.2s ease, border 0.2s ease;
  padding: 0;
  box-shadow: ${({ theme, noTitle, img, label, informationWidget }) =>
    !informationWidget && (label || ((!noTitle || img) && theme.shadowElevation1(theme.darkMode)))};
  border: ${({ noTitle, img }) => (!noTitle || img) && '1px solid transparent'};
  border-radius: 0.3rem;
  height: ${({ informationWidget }) => !informationWidget && '100%'};
  color: ${({ theme }) => theme.color.mainText};
  cursor: default;
  margin: ${({ informationWidget }) => informationWidget && '.25rem .5rem'};

  ${({ color, fontColor, caption, toggleState, theme, source, border }) => {
    const backgroundColor = getBackgroundColor({
      theme,
      color,
      caption,
      toggleState,
      source,
    })
    const foregroundColor = getForegroundColor({
      theme,
      color,
      fontColor,
      caption,
      toggleState,
      source,
    })
    return css`
      background: ${backgroundColor};

      &:hover {
        border: 1px solid
          ${toggleState && toggleState > -1
            ? backgroundColor
            : transparentize(0.5, backgroundColor || theme.color.main)};
      }

      border: 1px solid
        ${border
          ? foregroundColor
          : toggleState && toggleState > -1
          ? transparentize(0.5, backgroundColor)
          : 'transparent'};

      > .StatusContainer {
        &:after {
          background: ${foregroundColor};
        }

        > .Status__caption {
          i.Icon {
            ${titleIcon(foregroundColor, backgroundColor)}
          }
        }

        .Status__title-container {
          border-bottom: ${({ line }) =>
            line && `1px solid ${transparentize(0.5, foregroundColor)}`};
          > .Icon.type-img {
            width: 2.2rem;
            min-width: 2.2rem;
            height: 2.2rem;
            border-radius: 0.3rem;
            padding: 0;
          }
        }

        > .StatusCaption {
          justify-content: center;

          &:not(:first-child) {
            border-top: 1px solid ${transparentize(0.5, foregroundColor)};
          }

          i.Icon {
            ${captionIcon(backgroundColor, foregroundColor)}
          }
        }

        .Title,
        .kpi,
        .StatusArrow {
          color: ${foregroundColor};
        }
      }

      .Status__tag {
        border-color: ${foregroundColor} !important;
        color: ${foregroundColor};
      }

      ${({ informationWidget }) =>
        informationWidget &&
        css`
          .StatusContainer {
            i.Icon {
              ${titleIcon(foregroundColor, backgroundColor)}
            }
            .Icon.type-img {
              ${titleIcon(foregroundColor, backgroundColor)}
              padding: 0;
              background: transparent;
            }
          }
        `}
    `
  }}
  ${({ size, width }) =>
    ({
      s: css`
        .Title {
          font-size: 1.2rem;
        }
      `,
      m: css`
        .Title {
          font-size: 2rem;
        }

        .Button__label {
          font-size: 2rem;
        }
      `,
      l: css`
        .Title {
          font-size: 3rem;
        }

        .Button__label {
          font-size: 3rem;
        }
      `,
      auto: css`
        .Title {
          font-size: ${width / 15}px;
        }
      `,
    }[size || 's'])}
  ${({ hasLabel, informationWidget }) =>
    hasLabel
      ? css``
      : css`
          flex: ${!informationWidget && 1};

          .Icon {
            &.type-img {
              height: 100%;
              grid-column: span 2;
              padding: 0.7rem;
            }
          }
        `}
  ${({ caption, informationWidget }) =>
    caption
      ? css`
          > .StatusContainer {
            display: ${!informationWidget && 'flex'};
            flex-direction: ${!informationWidget && 'column'};

            > .Status__caption {
              height: auto;
            }
          }
        `
      : css`
          > .StatusContainer {
            > .Status__caption {
              height: 100%;
            }
          }
        `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      .StatusContainer {
        width: 100%;
        height: 100%;
        flex-direction: column;
      }

      &:hover {
        box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
        color: ${({ theme }) => theme.color.mainText};
    `}

  ${({ theme }) =>
    theme.screenSize.screen.current !== 's' && theme.screenSize.screen.current !== 'm'
      ? css`
          flex-wrap: wrap;

          .Title {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              display: block;
            }
          }
        `
      : css`
          min-width: fit-content;
        `}
`

export const StatusCaption = styled(Caption)`
  width: 100%;

  &:hover {
    .Description {
      opacity: 1;
      visibility: visible;
    }
  }

  .Icon {
    padding: 3% !important;
    width: 100%;
    height: 100%;
  }

  .Title {
    transition: opacity 0.2s ease, visibility 0.2s ease;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
  }

  .Description {
    transition: opacity 0.2s ease, visibility 0.2s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => transparentize(0.1, theme.color.backgroundFront)};
    text-align: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }
`

export const StatusArrow = styled.div`
  position: relative;
  line-height: 1;
  height: 1rem;

  ${({ diffValue, fontColor, arrowUpColor, arrowDownColor, showDiffArrow }) => {
    const color = diffValue > 0 ? arrowUpColor : diffValue < 0 ? arrowDownColor : fontColor
    return css`
      color: ${color};

      &:after {
        content: '';
        opacity: ${showDiffArrow ? 1 : 0};
        position: absolute;
        width: ${diffValue === 0 ? '.7rem' : 0};
        height: 0;
        top: 0.25rem;
        right: 100%;
        border-style: ${({ fontColor }) => fontColor && 'solid'};
        border-width: ${diffValue > 0
          ? '0 .5rem .5rem .5rem'
          : diffValue < 0
          ? '.5rem .5rem 0 .5rem'
          : '0 0 .2rem 0'};
        border-color: ${diffValue > 0
          ? `transparent transparent ${color} transparent`
          : diffValue < 0
          ? `${color} transparent transparent transparent`
          : `transparent transparent ${fontColor} transparent`};
        margin: 0 0.5rem 0 0;
      }
    `
  }}
  span {
    font-size: 9px;
  }
`
export const StatusContainer = styled.div`
  height: ${({ informationWidget }) => !informationWidget && '100%'};
  position: relative;

  ${({ hasIcon, img, hasKpi, caption, hideValue, line }) =>
    hasIcon && (hasKpi || caption?.title)
      ? css`
          position: relative;

          &:after {
            content: ${caption ? 'none' : ''};
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            opacity: 0.7;
          }

          > .Status__caption {
            .Status__title-container {
              justify-content: space-between;
              align-items: flex-start;
              padding-bottom: ${!hideValue && !line && '0 !important'};
              height: ${img && '100%'};

              i.Icon {
              }

              .Title {
                opacity: 0.8;
              }
            }

            .Status__kpi-container {
              justify-content: space-between;
              align-items: flex-end;
              padding-top: 0.2rem !important;
            }
          }

          > .StatusCaption {
            .Title {
              padding: 0 0.7rem 0;
              font-size: ${!caption?.icon && '1.8rem'};
              flex: 1;
              display: flex;
              align-items: center;
            }
          }
        `
      : css`
          > .Status__caption {
            display: flex;
            align-items: ${({ img }) => (img ? 'center' : 'flex-start')};
            justify-content: center;

            .Status__title-container {
              align-items: center;
              flex-direction: row-reverse;
              padding: ${hasIcon && !img ? '0.7rem 0.7rem 0.7rem 25%' : '0'} !important;
              height: ${img && '100%'};

              .Title {
                text-align: ${!hasIcon && 'center'};
                padding: 0.5rem 1rem;
              }
            }
          }
        `}
  > div:last-child {
    flex: 1;
    overflow: hidden;
  }

  .Icon {
    &.type-img {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  i.Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 60%;
      height: 60%;
    }
  }

  > .Status__caption {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    i.Icon {
      grid-column: 2;
      grid-row: 1;
    }
  }

  .Status__title-container {
    display: flex;
    padding: 0.7rem;
    flex: 1;
  }

  .Status__kpi-container {
    padding: 0.7rem;
    display: flex;
    flex: 1;
  }

  .Title {
    text-align: left;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 0.5rem;

    span {
      height: 100%;
    }
  }

  .kpi {
    text-align: left;
    font-size: ${({ valueWidget }) => (valueWidget ? '2rem' : '1.5rem')};
    line-height: 0.9;
    font-weight: 600 !important;
    grid-column: 1;
    margin-bottom: 0;

    label {
      font-size: 1rem;
      margin-left: 0.2rem;
    }
  }

  .Status__tags {
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    grid-column: 2;
    padding: 0 0.7rem;
  }

  .Status__tag {
    padding: 0 0.5rem;
    border: 1px solid;
    border-left: 4px solid;
    border-radius: 0.2rem;
    margin: 0 0.5rem 0.5rem 0;

    strong {
      margin-left: 0.5rem;
    }
  }

  ${({ informationWidget }) =>
    informationWidget &&
    css`
      display: grid;
      grid-template: auto / auto 1fr;
      padding: 0.5rem 0.7rem;

      > i.Icon {
        grid-row: 1 / span 2;
        grid-column: 1;
        width: 3rem !important;
        height: 3rem !important;
        margin-right: 0.5rem;
        margin-left: 0;
        align-self: start;

        svg {
          width: 50%;
          height: 50%;
        }
      }

      .Title {
        font-size: 1rem;
        opacity: 0.8;
        grid-column: 2;
        margin-bottom: 0;
      }

      .Status__kpi-container {
        padding: 0.2rem 0;
        grid-column: 2;

        .kpi {
          font-size: 1.6rem;
          flex: 1;
        }
      }

      > .StatusCaption {
        border-top: none !important;
        grid-row: 1 / span 2;
        grid-column: 3;
        flex-direction: row-reverse;
        align-items: center;

        > i.Icon {
          padding: 0 !important;
          height: 3rem;
        }
      }
    `}
`
