import React from 'react'

import Button from 'components/Global/Button'
import { DatapointList } from 'components/Global/FormField/Datapoints'
import Icon from 'components/Global/Icon'
import { useAccordeonContext } from 'components/LayoutComponents/Accordeon/AccordeonProvider'
import { AccordeonContent } from 'components/LayoutComponents/Accordeon/styled'
import { useAccordeon } from 'components/LayoutComponents/Accordeon/utils'

import { DatapointGroupStyled } from './styled'

const DatapointGroup = ({
  group,
  groupIndex,
  groupDataKey,
  dataKey,
  onRemoveDatapoint,
  onRemoveGroup,
  selected,
  selectedItemIndex,
  selectedDatapointMap = {},
  defaultOpen,
  onDatapointClick,
}) => {
  const { wrapper, height, setIsOpen, isOpen } = useAccordeon(defaultOpen, group.datapoints)
  const { handleRecalculateHeight } = useAccordeonContext()
  const handleToggleGroupOpen = () => {
    setIsOpen((state) => !state)
    handleRecalculateHeight && handleRecalculateHeight()
  }

  return (
    <DatapointGroupStyled className="DatapointGroup">
      <Icon
        icon={isOpen ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'}
        onClick={handleToggleGroupOpen}
      />
      <Button
        label={`${group.name} (${group.datapoints?.length})`}
        variant="tag"
        onIconClick={(e) => onRemoveGroup(e, { groupIndex })}
        icon={onRemoveGroup && 'fas fa-xmark'}
        color={group.color}
        isActive={selected || selectedDatapointMap[groupDataKey] === groupIndex}
        onClick={(e) => onDatapointClick(e, { typeIndex: groupIndex, type: 'groups' })}
      />
      <AccordeonContent
        initial={{ height: 0 }}
        animate={{ height }}
        className={`AccordeonContent ${isOpen ? 'active' : 'inactive'}`}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <div className="DatapointGroup__datapoints" ref={wrapper}>
          <DatapointList
            datapoints={group.datapoints}
            onDatapointClick={onDatapointClick}
            onRemoveDatapoint={onRemoveDatapoint}
            groupIndex={groupIndex}
            type="groups"
            selectedDatapointMapIndex={selectedDatapointMap[dataKey]}
            selectedItemIndex={selectedItemIndex}
            buttons={false}
            hideMethod
          />
        </div>
      </AccordeonContent>
    </DatapointGroupStyled>
  )
}

export default DatapointGroup
