import React, { Children, createContext, useMemo } from 'react'

import { PropTypes } from 'prop-types'

import SelectableRoute from './components/SelectableRoute'

export const SelectableContext = createContext({ options: undefined })

const SelectableProvider = ({ children, options }) => {
  const mergedOptions = useMemo(() => {
    const foundOptions = Children.toArray(children)
      .filter(({ type }) => type === SelectableRoute)
      .map(({ props }) => ({ name: props.name, path: props.path, exact: props.exact || false }))

    if (!options) {
      return foundOptions
    }

    return options.map((option) => {
      const foundOption = foundOptions.find((foundOption) => foundOption.name === option.name)

      const extraProps = foundOption ?? { disabled: true }

      return {
        ...option,
        ...extraProps,
      }
    })
  }, [options, children])

  return (
    <SelectableContext.Provider value={{ options: mergedOptions }}>
      {children}
    </SelectableContext.Provider>
  )
}

SelectableProvider.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ),
}

export default SelectableProvider
