export const modes = [
  {
    value: 'percentage',
    defaultValue: null,
    label: {
      formatted: 'widget.percentage',
    },
  },
  {
    value: 'absolute',
    defaultValue: [0],
    label: {
      formatted: 'widget.absolute',
    },
  },
]

export const getMinMaxByWidgetType = (min, max, deviation) => {
  if (typeof deviation === 'number') {
    return [max - (max * deviation) / 100, max + (max * deviation) / 100]
  }
  return [min, max]
}
