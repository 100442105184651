import styled from 'styled-components'

const MapZoomConfiguratorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .FormField {
    &.NumberInput {
      height: 35px;
      width: auto !important;
    }
    &.Input {
      flex: 1;
    }
  }
`
export default MapZoomConfiguratorStyled
