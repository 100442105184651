import React from 'react'

import ContentStyled from './styled'

const Content = ({ children, width = '65%', className }) => {
  return (
    <ContentStyled
      className={`Content ${className}`}
      initial={{
        width: 0,
      }}
      animate={{
        width,
      }}
      exit={{
        width: 0,
      }}
      transition={{
        type: 'spring',
        stiffness: 200,
        damping: 24,
      }}
    >
      {children}
    </ContentStyled>
  )
}

export default Content
