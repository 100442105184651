import { motion } from 'framer-motion'
import styled from 'styled-components'

import FormField from 'components/Global/FormField/styled'
import { Title } from 'components/Layout/Content'

export const DatapointSelectorContainerStyled = styled.div``

export const SubDrawer = styled(motion.div)`
  //position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.color.backgroundFront};
  box-shadow: ${({ theme }) => theme.panelElevationBottom(theme.darkMode)};
  z-index: 100;
  ${Title} {
    padding: 1rem 1rem 0;
    font-size: 1.2rem;
  }
`

const DatapointSelectorStyled = styled.div`
  display: flex;
  flex-direction: column;

  .DatapointSelector__editor {
    display: flex;
    flex: 1;
    max-height: 50vh;
    overflow: hidden;

    > div {
      position: relative;
      padding: 1rem;
      flex: 1;
      max-width: 33.333333%;

      > .Tags {
        overflow: hidden;
        padding-bottom: 1rem;
        height: 100%;

        .TooltipContent {
          flex: 1;
          padding: 0.5rem;
          overflow: auto;
        }
      }
    }

    ${FormField} {
      margin-bottom: 1rem;
    }
  }

  .ValuePicker__button-container {
    display: none;
  }

  .DatapointSelector__buttons {
    display: flex;

    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
`
export default DatapointSelectorStyled
