import React, { useMemo } from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useEntityOptions } from 'services/entities'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { useIsNumberValueTag } from '../FixedValueSelector/hooks'
import EntityValueTypePickerStyled from './styled'

const EntityTagPicker = ({
  value,
  onChange,
  subPath,
  dataKey = 'entities',
  types = [{ value: 'name', label: { formatted: 'widget.name' }, valueType: 'dataKey' }],
  tagsOnly,
  numberValues,
  allowClear,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()

  const datapointId = get(
    elementInEditMode.preview,
    `${subPath ? `${subPath}.` : ''}${dataKey}[0].id`
  )

  const { data } = useEntityOptions(`${datapointId}`)

  const isNumberValueTag = useIsNumberValueTag()

  const availableOptions = useMemo(() => {
    if (data) {
      return [
        ...(tagsOnly ? [] : types),
        ...Object.entries(data).reduce((options, [key, value]) => {
          if (!tagsOnly) {
            if (value?.__typename === 'AviaryEntity') {
              options.push({
                key,
                value: `${key}.name`,
                label: { text: key },
              })
            } else if (['type', 'remark', 'varia', 'markers', 'tags'].includes(key)) {
              options.push({
                key,
                value: key,
                label: { text: key },
              })
              if (key === 'markers') {
                value.forEach((v) => {
                  options.push({
                    tagName: v.name,
                    value: v.enabled ? 1 : 0,
                    label: { text: v.name },
                  })
                })
              }
            }
          }
          if (key === 'tags') {
            value.forEach((v) => {
              if (numberValues) {
                if (isNumberValueTag({ tagValue: v.value, tagName: v.name })) {
                  options.push({
                    tagName: v.name,
                    value: v.value * 1,
                    label: { text: v.name },
                  })
                }
              } else {
                options.push({
                  key: v.name,
                  value: v.value,
                  label: { text: v.name },
                })
              }
            })
          }
          return options
        }, []),
      ]
    }
    return types
  }, [data, types, tagsOnly, numberValues, isNumberValueTag])

  const handleChange = (payload, object, meta) => {
    if (!tagsOnly) {
      onChange(meta.label?.text, 'title')
    }
    onChange({ ...object, datapointId })
  }

  return (
    <EntityValueTypePickerStyled
      value={value.tagName}
      className="EntityValueTypePicker"
      input
      search
      options={availableOptions}
      selectionKeys={{ label: 'label', value: 'tagName' }}
      valueKey="value"
      onChange={handleChange}
      allowClear={allowClear}
    />
  )
}

EntityTagPicker.defaultProps = {
  options: [],
}

export default injectIntl(EntityTagPicker)
