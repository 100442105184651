import { useState } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

const QUERY_ENTITY_DETAIL = gql`
  query entityDetails($id: ID!, $companyId: ID) {
    entity(id: $id, companyId: $companyId) {
      name
      namedReferences
    }
  }
`

export const useEntitytHover = () => {
  const [hoveredElement, setHoveredElement] = useState(null)
  const [getDetails, { data: detail, loading }] = useLazyQuery(QUERY_ENTITY_DETAIL)

  const onHover = (e, id) => {
    if (id) {
      getDetails({
        variables: {
          id: id,
        },
      })
    }

    setHoveredElement(id ? e.target : null)
  }

  return {
    detail: detail?.entity || { namedReferences: {} },
    hoveredElement,
    loading,
    onHover,
  }
}
