import React from 'react'

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { FieldStyled } from './styled'

const { RangePicker } = DatePicker

const DateRangeField = ({
  value,
  name,
  format = 'PPpp',
  validation,
  defaultValue,
  onChange,
  showTime,
  picker,
  disabled,
  intl,
  ...fieldProps
}) => {
  return (
    <FieldStyled {...fieldProps}>
      {({ control, isEditMode }) => {
        return (
          <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={defaultValue}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              const [date1, date2] = formFieldValue || []
              const rangeValue = [
                date1 ? dayjs(date1) : undefined,
                date2 ? dayjs(date2) : undefined,
              ]

              const handleChange = (dayjsDates, dates) => {
                fromFieldOnChange(dates)
              }

              return (
                <RangePicker
                  {...{ showTime, picker }}
                  disabled={!isEditMode || disabled}
                  onChange={handleChange}
                  value={rangeValue}
                />
              )
            }}
          />
        )
      }}
    </FieldStyled>
  )
}

export default injectIntl(DateRangeField)
