import React from 'react'

import FileField from 'components/Form/fields/FilesField/FileField'
import { ImageThumb, ImageThumbButtons } from 'components/Form/fields/ImagesField/styled'
import Upload from 'components/Global/FormField/Upload'
import Icon from 'components/Global/Icon'

import { FilesFieldStyled } from './styled'

const FilesField = ({ value, name, onUpload, ...fieldProps }) => {
  return (
    <FilesFieldStyled {...fieldProps}>
      {({ watch, isEditMode }) => {
        const fieldValue = value || watch(name)

        if (isEditMode) {
          return (
            <>
              {fieldValue.map(({ itemIndex, file }) => (
                <FileField key={file.id} name={`${name}.${itemIndex}`} />
              ))}
              <ImageThumb width={150}>
                <Upload
                  className="ImageThumb__thumb upload"
                  placeholderIcon="lnr lnr-upload"
                  onChange={onUpload}
                  hidePreview
                  hideLoading
                />
                <ImageThumbButtons>
                  <Icon icon="lnr lnr-upload" />
                </ImageThumbButtons>
              </ImageThumb>
            </>
          )
        }
        return (
          <>
            {fieldValue.map(({ itemIndex, file }) => (
              <FileField key={file.id} name={`${name}.${itemIndex}`} />
            ))}
          </>
        )
      }}
    </FilesFieldStyled>
  )
}

export default FilesField
