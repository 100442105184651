import React, { useMemo } from 'react'

import get from 'lodash/get'
import { Controller, useWatch } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { useActiveConversionFactors } from 'services/conversionFactors'
import { useDatapointUnit } from 'services/datapoints'

import Field from 'components/Form/components/Field'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'

const DatapointUnitSelectField = ({
  children,
  name,
  datapointField,
  defaultValue = 'default',
  value = true,
  checked,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  label,
  intl,
  ...props
}) => {
  const { data } = useActiveConversionFactors()
  const selectedDatapointId = useWatch({ name: datapointField })

  const datapointDetails = useDatapointUnit(selectedDatapointId)
  const options = useMemo(() => {
    if (!data || !datapointDetails) {
      return []
    }

    const conversions = data
      .filter(({ endUnit }) => endUnit.name === datapointDetails.rawUnit)
      .map((conversion) => {
        return {
          value: conversion.id,
          label: conversion.startUnit.name,
          data: conversion,
        }
      })

    return [
      {
        value: 'default',
        label: `${datapointDetails.unit} (${intl.formatMessage({ id: 'default' })})`,
      },
      ...conversions,
    ]
  }, [data, datapointDetails, intl])

  return (
    <Field {...props} label={label}>
      {({ control, errors, setValue, isEditMode, loading }) => {
        const error = get(errors, name)

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: formFieldOnChange, value: formFieldValue }) => {
              if (loading) {
                return <Skeleton width="200px" />
              }

              if (isEditMode) {
                return (
                  <>
                    <ValuePicker
                      options={options}
                      value={formFieldValue}
                      onChange={(value, option) => {
                        setValue(
                          `meta.${name}.conversionFactor`,
                          value === 'default' ? undefined : option.data
                        )
                        formFieldOnChange(value)
                        if (typeof onChange === 'function') {
                          onChange(value === 'default' ? undefined : value)
                        }
                      }}
                      search
                      placeholder="Select"
                      error={error}
                      disabled={disabled}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              return <span>{formFieldValue?.name}</span>
            }}
          />
        )
      }}
    </Field>
  )
}

export default injectIntl(DatapointUnitSelectField)
