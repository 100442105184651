import React, { useEffect, useState } from 'react'

import { FormattedMessage, injectIntl } from 'react-intl'

import DatapointLabel from 'components/Dashboard/components/FloorMap/DatapointLabel'
import Highlight from 'components/Dashboard/components/FloorMap/Highlight'
import NavigationLabel from 'components/Dashboard/components/FloorMap/NavigationLabel'
import PhaseToggle from 'components/Dashboard/components/FloorMap/PhaseToggle'
import { widgetConfig } from 'components/Dashboard/Widget/config'
import { HighlightConfigurator } from 'components/Dashboard/Widget/MicroWidgetConfigurator/HighlightConfigurator'
import Component from 'components/Global/Component/styled'
import FormField from 'components/Global/FormField'
import { CircleGauge, Consumption, Gauge } from 'components/Widgets/Gauge'
import LastValueWidget from 'components/Widgets/other/LastValueWidget/LastValueWidget/LastValueWidget'
import { ChartCard } from 'components/Widgets/Sparkline'

import Button from '../../../Global/Button'
import FlexContainer from '../../../Global/FlexContainer'
import { useDashboard } from '../../DashboardProvider'
import { DatapointLabelConfigurator } from './DatapointLabelConfigurator'
import { NavigationLabelConfigurator } from './NavigationLabelConfigurator'
import { PhaseToggleConfigurator } from './PhaseToggleConfigurator'
import MicroWidgetConfiguratorStyled, {
  ValueElement,
  WidgetSelector,
  WidgetWrapper,
} from './styled'
import { WidgetConfigurator } from './WidgetConfigurator'

const microWidgets = {
  Label: {
    component: DatapointLabel,
    configurator: DatapointLabelConfigurator,
    label: 'widget.label',
    defaultProps: {
      controlType: 'none',
      initialValue: 'IN_EDIT',
    },
  },
  NavigationLabel: {
    component: NavigationLabel,
    configurator: NavigationLabelConfigurator,
    label: 'widget.navigation',
    defaultProps: {
      size: 's',
      initialValue: 'IN_EDIT',
    },
  },
  DatapointLabel: {
    component: DatapointLabel,
    configurator: DatapointLabelConfigurator,
    label: 'widget.control',
    defaultProps: {
      controlType: 'increment',
      initialValue: 'IN_EDIT',
    },
  },
  PhaseToggle: {
    component: PhaseToggle,
    configurator: PhaseToggleConfigurator,
    defaultProps: {
      iconOn: null,
      iconOff: null,
      initialValue: 'IN_EDIT',
    },
    label: 'widget.onOff',
  },
  LightBulb: {
    component: PhaseToggle,
    configurator: PhaseToggleConfigurator,
    defaultProps: {
      iconOn: 'fas fa-lightbulb',
      iconOff: 'far fa-lightbulb',
      initialValue: 'IN_EDIT',
    },
    label: 'widget.icon',
  },
  Navigation: {
    component: LastValueWidget,
    configurator: WidgetConfigurator,
    defaultProps: {
      icon: 'fas fa-info',
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.navigation',
  },
  LastValueWidget: {
    component: LastValueWidget,
    configurator: WidgetConfigurator,
    defaultProps: {
      icon: 'fas fa-info',
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.status',
  },
  ChartCard: {
    component: ChartCard,
    configurator: WidgetConfigurator,
    defaultProps: {
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.kpiWidget',
  },
  Gauge: {
    component: Gauge,
    configurator: WidgetConfigurator,
    defaultProps: {
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.gauge',
  },
  Consumption: {
    component: Consumption,
    configurator: WidgetConfigurator,
    defaultProps: {
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.consumption',
  },
  CircleGauge: {
    component: CircleGauge,
    configurator: WidgetConfigurator,
    defaultProps: {
      initialValue: 'IN_EDIT',
      dataProps: {
        isPreviewMode: true,
      },
    },
    label: 'widget.gauge',
  },
  Highlight: {
    component: Highlight,
    configurator: HighlightConfigurator,
    defaultProps: {
      initialValue: 'IN_EDIT',
      width: 15,
      height: 15,
      rotation: 0,
      opacity: 0.2,
    },
    label: 'widget.transparentZone',
  },
}

const MicroWidgetConfigurator = ({ widgets, value, onChange, intl }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const [selectedWidget, setSelectedWidget] = useState(value || {})
  const [clipboard, setClipboard] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSelectedWidget(value)
  }, [value])

  const handleSelectWidget = (type) => {
    setSelectedWidget((state) => {
      const widget = { ...state, ...microWidgets[type].defaultProps, type }
      onChange(widget)
      return widget
    })
    setIsOpen(false)
  }

  const handleWidgetUpdate = ({ value, dataKey }) => {
    !value?.section && setSelectedWidget((state) => ({ ...state, [dataKey]: value }))
    setIsOpen(false)
    onChange(value, dataKey)
  }

  const handleCopyToClipboard = () => {
    const { id, name, x, y, ...newClipboard } = selectedWidget
    setClipboard(newClipboard)
  }

  const handlePasteFromClipboard = () => {
    setSelectedWidget((state) => {
      const widget = { ...state, ...clipboard }
      onChange(widget)
      return widget
    })
  }

  const config = widgetConfig({ type: selectedWidget?.type })

  return (
    <MicroWidgetConfiguratorStyled className="MicroWidgetConfigurator">
      {
        <>
          <WidgetSelector
            valueElement={
              <ValueElement
                className="ValueElement value"
                onClick={() => setIsOpen((state) => !state)}
              >
                {selectedWidget?.type ? (
                  <Component
                    {...selectedWidget}
                    as={microWidgets[selectedWidget?.type].component}
                    preview={config.editProps.preview}
                    datapoints={[{ id: selectedWidget?.id }]}
                    initialValue={'IN_EDIT'}
                  />
                ) : (
                  <FormattedMessage id="widget.selectWidget" />
                )}
              </ValueElement>
            }
            isOpen={isOpen}
            width={400}
            height={350}
            onToggleOpen={(payload) =>
              setIsOpen((state) => (typeof payload !== 'undefined' ? payload : !state))
            }
            variant="ValuePicker"
            effect="scaleY"
          >
            <WidgetWrapper>
              {widgets.map((widget) => {
                const config = widgetConfig({ type: widget.type })
                const combinedProps = {
                  ...selectedWidget,
                  ...microWidgets[widget.type].defaultProps,
                  preview: config.editProps.preview,
                  datapoints: [{ id: selectedWidget?.id }],
                  type: widget.type,
                }
                return (
                  <ValueElement
                    key={widget.type}
                    className="ValueElement option"
                    onClick={() => handleSelectWidget(widget.type)}
                  >
                    <FormattedMessage id={microWidgets[widget.type].label} />
                    <Component {...combinedProps} as={microWidgets[widget.type].component} />
                  </ValueElement>
                )
              })}
            </WidgetWrapper>
          </WidgetSelector>
          <FlexContainer>
            <Button
              label={intl.formatMessage({ id: 'widget.copySettings' })}
              variant="smallButton"
              icon="fas fa-copy"
              onClick={handleCopyToClipboard}
            />
            {clipboard && (
              <Button
                label={intl.formatMessage({ id: 'widget.pasteSettings' })}
                variant="smallButton"
                icon="fas fa-clipboard"
                isPrimary
                onClick={handlePasteFromClipboard}
              />
            )}
          </FlexContainer>
        </>
      }
      <FormField
        value={value?.x}
        dataKey="x"
        label={{ text: 'x' }}
        componentName="NumberInput"
        componentProps={{
          min: 0,
        }}
        col="col2 first"
        onChange={handleWidgetUpdate}
      />
      <FormField
        value={value?.y}
        dataKey="y"
        label={{ text: 'y' }}
        componentName="NumberInput"
        componentProps={{
          min: 0,
        }}
        col="col2"
        onChange={handleWidgetUpdate}
      />
      {selectedWidget?.type && (
        <div className="MicroWidgetConfigurator__fields">
          <Component
            value={selectedWidget}
            as={microWidgets[selectedWidget.type].configurator}
            widgetConfig={config}
            subPath={
              elementInEditMode.selectedDatapointMap &&
              elementInEditMode.selectedDatapointMap.datapoints > -1 &&
              `datapoints[${
                elementInEditMode.selectedDatapointMap &&
                elementInEditMode.selectedDatapointMap.datapoints
              }]`
            }
            type={selectedWidget?.type}
            onChange={handleWidgetUpdate}
          />
        </div>
      )}
    </MicroWidgetConfiguratorStyled>
  )
}

export default injectIntl(MicroWidgetConfigurator)
