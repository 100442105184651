import React from 'react'

import { gql, useQuery } from '@apollo/client'
import { Checkbox } from 'antd'
import { get } from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'

import { useDashboard } from 'components/Dashboard/DashboardProvider'

import {
  FRAGMENT_AVIARY_ENTITY_MARKERS,
  FRAGMENT_AVIARY_ENTITY_TAGS,
} from '../../../../services/entities'
import { detailFields } from '../../../Dashboard/Widget/config/selections'
import { hiddenTags } from '../../../Widgets/other/Detail/Ui'
import EntityFilePicker from '../EntityFilePicker'
import { FormFieldLabel } from '../styled'
import { InformationDetailConfiguratorStyled } from './styled'

const INFORMATION_ENTITY_DETAIL = gql`
  ${FRAGMENT_AVIARY_ENTITY_TAGS}
  ${FRAGMENT_AVIARY_ENTITY_MARKERS}
  query entity($id: ID!) {
    entity(id: $id) {
      id
      name: rawName
      remark
      type
      site {
        id
        name
      }
      country {
        id
        alpha2
      }
      degreeDayReference {
        name
      }
      building {
        id
        name
      }
      floor {
        id
        name
      }
      room {
        name
        id
      }
      varia
      ...Tags
      ...Markers
      files {
        id
        type {
          id
        }
        file {
          id
          key
          name
          mimetype
        }
      }
    }
  }
`

const InformationDetailConfigurator = ({ subPath, onChange, intl }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const line = get(elementInEditMode.preview, subPath)
  const hiddenFields = line?.hiddenFields || []

  const { data } = useQuery(INFORMATION_ENTITY_DETAIL, {
    variables: {
      id: line.entities?.[0]?.id,
    },
    skip: !line.entities?.[0]?.id,
  })

  const handleChange = (value) => {
    onChange(
      hiddenFields.includes(value)
        ? hiddenFields.filter((field) => field !== value)
        : hiddenFields.concat(value)
    )
  }

  const handleFileClick = ({ key }) => {
    const value = `files.${key}`
    onChange(
      hiddenFields.includes(value)
        ? hiddenFields.filter((field) => field !== value)
        : hiddenFields.concat(value)
    )
  }

  return data?.entity ? (
    <InformationDetailConfiguratorStyled className={`InformationDetailConfigurator`}>
      {detailFields.map(({ label, dataKey }) => {
        const fieldValue = get(data.entity, dataKey)
        const hidden = hiddenFields.includes(dataKey)
        return (
          <p>
            <Checkbox checked={!hidden} onChange={() => handleChange(dataKey)} />
            <label className="InformationDetailConfigurator__label">
              <FormattedMessage id={label.formatted} />
            </label>
            <span>{fieldValue}</span>
          </p>
        )
      })}
      {data.entity.tags.reduce((tags, { name, value }) => {
        const hidden = hiddenFields.includes(`tags.${name}`) || hiddenTags.includes(name)
        if (!hidden) {
          tags.push(
            <p>
              <Checkbox checked={!hidden} onChange={() => handleChange(`tags.${name}`)} />
              <label className="InformationDetailConfigurator__label">{name}</label>
              <span>{value}</span>
            </p>
          )
        }
        return tags
      }, [])}
      {data.entity.markers.map(({ name, value }) => {
        const hidden = hiddenFields.includes(`markers.${name}`) || value !== '1'
        return (
          <p>
            <Checkbox checked={!hidden} onChange={() => handleChange(`markers.${name}`)} />
            <label className="InformationDetailConfigurator__label">{name}</label>
          </p>
        )
      })}
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.files' })}</FormFieldLabel>
      <EntityFilePicker
        files={data.entity.files}
        value={data.entity.files.reduce(
          (files, { file }) =>
            !hiddenFields.includes(`files.${file.key}`) ? files.concat(file) : files,
          []
        )}
        onFileClick={handleFileClick}
      />
    </InformationDetailConfiguratorStyled>
  ) : null
}

export default injectIntl(InformationDetailConfigurator)
