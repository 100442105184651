import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { useOrganisationForms } from 'services/organisations'

import SelectField from '../../SelectField'

const OrganisationFormSelectField = ({ type, ...props }) => {
  const { data } = useOrganisationForms({ type })

  const options = useMemo(
    () =>
      data?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  )

  return <SelectField {...props} options={options} />
}

OrganisationFormSelectField.propTypes = {
  type: PropTypes.string,
}

export default OrganisationFormSelectField
