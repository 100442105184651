import get from 'lodash/get'

export const fuzzyMatch = (value, compareValue, threshold) => {
  if (!value || !compareValue) return true
  switch (threshold) {
    case 'low':
      compareValue = compareValue.split('').reduce((a, b) => {
        return a + '[^' + b + ']*' + b
      })
      return new RegExp(compareValue).test(value)

    case 'medium':
      compareValue = '.*' + compareValue.split('').join('.*') + '.*'
      return new RegExp(compareValue).test(value)

    case 'high':
      return value.toString().toLowerCase().includes(compareValue.toString().toLowerCase())
    default:
      return value.toString().toLowerCase().includes(compareValue.toString().toLowerCase())
  }
}

export const getLabel = (option, labelKey, intl) => {
  if (labelKey) {
    if (labelKey === 'translations' && option.translations) {
      return option.translations.find((translation) => translation.language.code === intl.locale)
        ?.translation
    }
    const label = get(option, labelKey)
    return (
      label &&
      (label.text || (label.formatted && intl.formatMessage({ id: label.formatted })) || label)
    )
  }
  return (
    (option.label && option.label.text) ||
    (option.label.formatted && intl.formatMessage({ id: option.label.formatted })) ||
    option.label ||
    option.value
  )
}

export const validateField = (value, validation = {}, intl) => {
  switch (validation.type) {
    case 'maxLength':
      if (value?.length < validation.value) {
        return { isValid: true }
      }
      return {
        isValid: false,
        message: `${intl.formatMessage({ id: 'form.validation.maxLength' })} ${
          validation.value
        } ${intl.formatMessage({ id: 'form.validation.characters' })}`,
      }
    case 'required':
      if (value) {
        return { isValid: true, message: null }
      }

      return {
        isValid: false,
        message: `${intl.formatMessage({ id: 'form.field.error.required' })}`,
      }

    default:
  }
}

const checkDependency = ({
  dependentOnValue,
  dependencyValue,
  dependencyType,
  dependencyStatus,
}) => {
  switch (dependencyType) {
    case 'valueIs':
      return dependencyStatus && dependentOnValue === dependencyValue
    case 'valueIsIn':
      return dependencyStatus && dependencyValue.includes(dependentOnValue)
    case 'valueIsNot':
      return dependencyStatus && dependentOnValue !== dependencyValue
    case 'hasValue':
      if (Array.isArray(dependentOnValue)) {
        return dependentOnValue.length > 0
      }
      return !!dependentOnValue
    case 'hasLength':
      return dependentOnValue.length > dependencyValue
    default:
  }
}

export const onCheckDependencies = ({ dependencies, ...field }, dataObject) => {
  const defaultDependencies = { show: true }
  if (!dependencies) {
    return defaultDependencies
  }

  return dependencies.reduce(
    (dependencyObject, { dependentOn, dependencyValue, dependencyType, dependencyResolver }) => {
      const dependentOnValue = get(dataObject, dependentOn)
      dependencyObject[dependencyResolver] = checkDependency({
        dependentOnValue,
        dependencyValue,
        dependencyType,
        dependencyStatus: dependencyObject[dependencyResolver],
      })

      return dependencyObject
    },
    defaultDependencies
  )
}
