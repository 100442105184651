import React from 'react'

import { NotificationProgressStyled } from './styled'

const NotificationProgress = ({ theme }) => {
  return (
    <NotificationProgressStyled className="NotificationProgress" theme={theme}>
      <span />
    </NotificationProgressStyled>
  )
}

export default NotificationProgress
