import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client'

import { settingsVar } from './vars'

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (object.__typename === 'Datapoint') {
      // Datapoint objects are resolved based on multiple contextual fields such as multiplier, influxFunction, and more
      // Because these fields are not available on returned result, we cannot create a unique identifier for the returned datapoint
      // So we return null here to disable datapoint client side caching all together
      return null
    }
    return defaultDataIdFromObject(object)
  },
  typePolicies: {
    Query: {
      fields: {
        settings: {
          read() {
            return settingsVar()
          },
        },
      },
    },
    OrganisationLinkOverview: {
      keyFields: ['id', 'organisationId'],
    },
    ReportingYearLock: {
      keyFields: ['year', 'organisationId'],
    },
  },
})

export default cache
