import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { capitalizeString } from '../../../Dashboard/functions'
import Button from '../../Button'
import { FormFieldLabel } from '../styled'

const LinkedData = ({
  subPath,
  elementInEditMode,
  selectedDatapointMap,
  onDatapointClick,
  dataKey,
  label,
  intl,
  onChange,
  onClick,
  action,
}) => {
  const linkedData = get(elementInEditMode, `${subPath ? `${subPath}.` : ''}${dataKey}Linked`) || []
  const onAddLinkedData = (type) => {
    const linked = linkedData.concat({
      id: type,
      name: intl.formatMessage({ id: `widget.linked${capitalizeString(type)}` }),
    })
    onChange(linked, `${dataKey}Linked`)
    const linkedIndex = linked.length - 1
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: linkedIndex,
        dataKey: `${dataKey}Linked`,
      },
    })
    onClick(null, { ...linked[linkedIndex], type: 'linked', typeIndex: linkedIndex })
  }
  const onRemoveLinkedData = (e, { itemIndex }) => {
    e.stopPropagation()
    const linked = linkedData.filter((data, index) => index !== itemIndex)
    onChange(linked, `${dataKey}Linked`)
  }
  return (
    <>
      <FormFieldLabel as="h4">{intl.formatMessage({ id: label })}</FormFieldLabel>
      {linkedData.map(({ id, name, color }, itemIndex) => (
        <Button
          label={name}
          variant="tag"
          onIconClick={(e) => onRemoveLinkedData(e, { id, name, itemIndex })}
          icon="fas fa-xmark"
          color={color}
          isActive={selectedDatapointMap[dataKey] === itemIndex}
          onClick={(e) => onDatapointClick(e, { id, name, typeIndex: itemIndex, type: 'linked' })}
        />
      ))}
      <div className="Datapoints__buttons">
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addWidgetDatapoints' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('datapoints')}
        />
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addWidgetComparators' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('comparators')}
        />
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addWidgetMinComparators' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('minComparators')}
        />
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addWidgetMaxComparators' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('maxComparators')}
        />
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addWidgetExtraDatapoints' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('linkDatapoints')}
        />
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: 'widget.addClickedPointValue' })}
          variant="smallButton"
          onClick={() => onAddLinkedData('pointValue')}
        />
      </div>
    </>
  )
}

export default injectIntl(LinkedData)
