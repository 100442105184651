import { gql, useQuery } from '@apollo/client'

const QUERY = gql`
  query GlobalWarmingPotentials {
    globalWarmingPotentials {
      id
      name
    }
  }
`

export const useGlobalWarmingPotentials = (menuId) => {
  const { data, ...rest } = useQuery(QUERY)

  return { data: data?.globalWarmingPotentials, ...rest }
}
