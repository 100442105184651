import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Label, Value } from 'components/Form/components/Field/styled'

export const TextFieldStyled = styled(Field)`
  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    min-height: 100%;
  }
  ${Value} {
    overflow: hidden;
  }
`

export const PasswordFieldStyled = styled(Field)`
  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    min-height: 100%;
  }
`
export const LocationSearchFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    align-items: center;
  }

  ${Label} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`
