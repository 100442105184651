import React from 'react'

import NavigationLabelStyled from './styled'

const NavigationLabel = ({ icon, label, name, size, url, color, w, className }) => (
  <NavigationLabelStyled
    className={`Navigation NavigationLabel ${className} ${size}`}
    img={icon && icon.includes('/')}
    url={url}
    size={size}
    color={color}
    width={w}
    icon={icon}
    label={label || name}
    scrollOffset={65}
    target={url && (url.startsWith('http') ? '_blank' : '_self')}
  />
)

export default NavigationLabel
