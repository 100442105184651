import React from 'react'

import { Checkbox, Form } from 'antd'

const CheckboxField = ({ label, name, disabled, hidden, direction = 'vertical' }) => (
  <Form.Item
    style={
      direction === 'horizontal'
        ? {
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: '7px',
          }
        : { width: '100%' }
    }
    name={name}
    label={label}
    hidden={hidden}
    valuePropName="checked"
  >
    <Checkbox disabled={disabled} />
  </Form.Item>
)

export default CheckboxField
