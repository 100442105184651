import { lazy } from 'react'

import LoginLayout from '../layouts/Login'
import MainLayout from '../layouts/Main'
import NoLayout from '../layouts/NoLayout'

const Login = lazy(() => import('../pages/user/login'))
const ForgotPassword = lazy(() => import('../pages/user/forgot'))
const ResetPassword = lazy(() => import('../pages/user/reset'))
const Invite = lazy(() => import('../pages/user/invite'))
const SquealOverview = lazy(() => import('../pages/squeal_overview'))
const DashboardsOverview = lazy(() => import('../pages/dashboard/landing'))
const DashboardsManagement = lazy(() => import('../pages/dashboard/Management'))
const Settings = lazy(() => import('../pages/settings'))
const Fluvius = lazy(() => import('../pages/fluvius'))
const Dashboard = lazy(() => import('../pages/dashboard'))
const FireAlertOverview = lazy(() => import('../pages/fire_alert_overview'))
const Sustainability = lazy(() => import('../pages/sustainability'))
const Downloading = lazy(() => import('../pages/downloading'))
const UserMarketingRegistration = lazy(() =>
  import('components/LayoutComponents/User/UserMarketingRegistration')
)

export default [
  // System Pages
  {
    path: '/user/login',
    component: Login,
    public: true,
    layout: LoginLayout,
  },
  {
    path: '/user/forgot-password',
    component: ForgotPassword,
    public: true,
    exact: true,
    layout: LoginLayout,
  },
  {
    path: '/user/reset',
    public: true,
    signOut: true,
    component: ResetPassword,
    layout: LoginLayout,
  },
  {
    path: '/user/invite/:token',
    component: Invite,
    public: true,
    signOut: true,
    exact: true,
    layout: LoginLayout,
  },
  {
    path: '/dashboards',
    component: DashboardsOverview,
    layout: MainLayout,
  },
  {
    path: '/settings',
    component: Settings,
    layout: MainLayout,
  },
  {
    path: '/fluvius',
    component: Fluvius,
    layout: MainLayout,
  },
  {
    path: '/squeal-overview',
    component: SquealOverview,
    layout: MainLayout,
  },
  {
    path: '/dashboard/management',
    component: DashboardsManagement,
    layout: MainLayout,
  },
  {
    path: '/dashboard/:id',
    component: Dashboard,
    layout: MainLayout,
  },
  {
    path: '/fire-alert-overview',
    component: FireAlertOverview,
    layout: MainLayout,
  },
  {
    path: '/sustainability',
    component: Sustainability,
    layout: MainLayout,
  },
  {
    path: '/downloading',
    component: Downloading,
    layout: NoLayout,
  },
  {
    path: '/EnerdoGreenprint',
    component: UserMarketingRegistration,
    public: true,
    signOut: true,
    exact: true,
    layout: LoginLayout,
  },
]
