export default {
  etl1: 'Batch {name} has started',
  etl0: 'Batch {name} is finished and took {duration}sec',
  report1: 'Report {name} is being generated',
  report0: 'Report {name} is available and took {duration}sec',
  notificationsCount: 'You have {amount} notifications',
  notificationsCountSingle: 'You have 1 notification',
  operationInputLimit:
    'Operation "{operation}" can not contain more than {max} inputs. The remaining inputs wil be removed.',
  itemsUpdated: 'items updated.',
  fieldNotAvailableForThisConfiguration: 'Field "{field}" is not available for this configuration',
}
