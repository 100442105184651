import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Caption from 'components/Global/Caption'
import { status } from 'components/Global/Caption/styled.status'

const getBackgroundColor = ({ theme, color, caption, source }) => {
  if (source === 'Equipment') {
    return theme.color.backgroundFront
  }
  if (caption?.color) {
    switch (caption.color) {
      case 'success':
        return theme.color.success
      case 'error':
        return theme.color.error
      case 'warning':
        return theme.color.warning
      default:
        return caption.color
    }
  }
  if (color) {
    return color
  }
  return theme.color.backgroundFront
}

const getForegroundColor = ({ theme, color, fontColor, caption, toggleState, source }) => {
  if (source === 'Equipment') {
    const foregroundColor = getBackgroundColor({
      theme,
      color,
      caption,
      toggleState,
    })
    if (foregroundColor === theme.color.backgroundFront) {
      return theme.color.mainText
    }
    return foregroundColor
  }
  if (caption?.color) {
    return theme.color.backgroundFront
  }
  if (fontColor) {
    return fontColor
  }
  return theme.color.mainText
}

export const LastValueWidgetStyled = styled.div`
  position: relative;
  transition: box-shadow 0.2s ease, color 0.2s ease, border 0.2s ease;
  padding: 0;
  box-shadow: ${({ theme, noTitle, img, label }) =>
    label || ((!noTitle || img) && theme.shadowElevation1(theme.darkMode))};
  border: ${({ noTitle, img }) => (!noTitle || img) && '1px solid transparent'};
  border-radius: 0.3rem;
  height: 100%;
  color: ${({ theme }) => theme.color.mainText};
  cursor: default;

  ${({ color, fontColor, caption, toggleState, theme, source }) => {
    const backgroundColor = getBackgroundColor({
      theme,
      color,
      caption,
      toggleState,
      source,
    })
    const foregroundColor = getForegroundColor({
      theme,
      color,
      fontColor,
      caption,
      toggleState,
      source,
    })
    return css`
      background: ${backgroundColor};

      &:hover {
        border: 1px solid
          ${toggleState && toggleState > -1
            ? backgroundColor
            : transparentize(0.5, backgroundColor || theme.color.main)};
      }

      border: 1px solid
        ${toggleState && toggleState > -1 ? transparentize(0.5, backgroundColor) : 'transparent'};

      > .LastValueWidgetContainer {
        &:after {
          background: ${foregroundColor};
        }

        > .LastValueWidget__caption {
          i.Icon {
            background: ${foregroundColor};
            color: ${backgroundColor};
          }
        }

        .LastValueWidget__kpi-container {
          border-top: ${({ line }) => line && `1px solid ${transparentize(0.5, foregroundColor)}`};
        }

        > .LastValueWidgetCaption {
          &:not(:first-child) {
            border-top: 1px solid ${transparentize(0.5, foregroundColor)};
          }

          i.Icon {
            color: ${foregroundColor};
            background: ${backgroundColor};
          }

          .Icon.type-img {
            background: ${({ theme }) => theme.color.backgroundFront};
          }
        }

        .Title,
        .kpi {
          color: ${foregroundColor};
        }
      }
    `
  }}
  ${({ size, width }) =>
    ({
      s: css`
        .Title {
          font-size: 1.2rem;
        }
      `,
      m: css`
        .Title {
          font-size: 2rem;
        }

        .Button__label {
          font-size: 2rem;
        }
      `,
      l: css`
        .Title {
          font-size: 3rem;
        }

        .Button__label {
          font-size: 3rem;
        }
      `,
      auto: css`
        .Title {
          font-size: ${width / 15}px;
        }
      `,
    }[size || 's'])}
  ${({ hasLabel }) =>
    hasLabel
      ? css``
      : css`
          flex: 1;

          .Icon {
            &.type-img {
              height: 100%;
              grid-column: span 2;
              padding: 0.7rem;
            }
          }
        `}
  ${({ caption }) =>
    caption
      ? css`
          > .LastValueWidgetContainer {
            display: flex;
            flex-direction: column;

            > .LastValueWidget__caption {
              height: auto;
            }
          }
        `
      : css`
          > .LastValueWidgetContainer {
            > .LastValueWidget__caption {
              height: 100%;
            }
          }
        `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      .LastValueWidgetContainer {
        width: 100%;
        height: 100%;
        flex-direction: column;
      }

      &:hover {
        box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
        color: ${({ theme }) => theme.color.mainText};
    `}

  ${({ theme }) =>
    theme.screenSize.screen.current !== 's' && theme.screenSize.screen.current !== 'm'
      ? css`
          flex-wrap: wrap;

          .Title {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              display: block;
            }
          }
        `
      : css`
          min-width: fit-content;
        `}
`

export const LastValueWidgetCaption = styled(Caption)`
  width: 100%;
  flex-direction: column;
  justify-content: center;

  ${({ color, title, icon, description, type }) =>
    color &&
    !title &&
    !icon &&
    !description &&
    css`
      ${status(type, color)}
    `}
  &:hover {
    .Description {
      opacity: 1;
      visibility: visible;
    }
  }

  .Icon {
    padding: 3%;
    width: 100%;
    height: 100%;
  }

  .Title {
    transition: opacity 0.2s ease, visibility 0.2s ease;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .Description {
    transition: opacity 0.2s ease, visibility 0.2s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => transparentize(0.1, theme.color.backgroundFront)};
    text-align: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
`

export const LastValueWidgetArrow = styled.div`
  position: relative;
  line-height: 1;
  height: 1rem;

  ${({ diffValue, fontColor, arrowUpColor, arrowDownColor, showDiffArrow }) => {
    const color = diffValue > 0 ? arrowUpColor : diffValue < 0 ? arrowDownColor : fontColor
    return css`
      color: ${color};

      &:after {
        content: '';
        opacity: ${showDiffArrow ? 1 : 0};
        position: absolute;
        width: ${diffValue === 0 ? '.7rem' : 0};
        height: 0;
        top: 0.25rem;
        right: 100%;
        border-style: solid;
        border-width: ${diffValue > 0
          ? '0 .5rem .5rem .5rem'
          : diffValue < 0
          ? '.5rem .5rem 0 .5rem'
          : '0 0 .2rem 0'};
        border-color: ${diffValue > 0
          ? `transparent transparent ${color} transparent`
          : diffValue < 0
          ? `${color} transparent transparent transparent`
          : `transparent transparent ${fontColor} transparent`};
        margin: 0 0.5rem 0 0;
      }
    `
  }}
  span {
    font-size: 9px;
  }
`
export const LastValueWidgetContainer = styled.div`
  height: 100%;
  position: relative;

  ${({ hasIcon, img, hasKpi, caption, centeredText, hideValue, line }) =>
    hasIcon && (hasKpi || caption?.title)
      ? css`
          position: relative;

          &:after {
            content: ${caption ? 'none' : ''};
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            opacity: 0.7;
          }

          > .LastValueWidget__caption {
            .LastValueWidget__title-container {
              justify-content: space-between;
              align-items: flex-start;
              padding-bottom: ${!hideValue && !line && '0 !important'};

              i.Icon {
                position: ${!hideValue && !line && 'absolute'};
                right: 0.7rem;
              }

              .Title {
                opacity: 0.8;
              }
            }

            .LastValueWidget__kpi-container {
              justify-content: space-between;
              align-items: flex-end;
              padding-top: 0.2rem !important;
            }
          }

          > .LastValueWidgetCaption {
            .Title {
              position: ${caption?.icon && 'absolute'};
              padding: 0.7rem;
              font-size: ${!caption?.icon && '1.8rem'};
              flex: 1;
              display: flex;
              align-items: center;
              text-align: ${caption?.icon ? 'center' : 'left'};
            }
          }
        `
      : css`
          > .LastValueWidget__caption {
            display: flex;
            align-items: ${({ img }) => (img ? 'center' : 'flex-start')};
            justify-content: center;

            .LastValueWidget__title-container {
              align-items: center;
              flex-direction: row-reverse;
              padding: ${hasIcon && !img && !centeredText
                ? '0.7rem 0.7rem 0.7rem 25%'
                : '.7rem'} !important;
              height: ${img && '100%'};
              width: 100%;
              justify-content: ${centeredText ? 'center' : 'flex-end'};

              .Title {
                text-align: ${!hasIcon && 'center'};
                padding: 0.5rem 1rem;
              }
            }
          }
        `}
  > div:last-child {
    flex: 1;
    overflow: hidden;
  }

  .Icon {
    &.type-img {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  i.Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 60%;
      height: 60%;
    }
  }

  > .LastValueWidget__caption {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    .LastValueWidget__title-container {
      display: flex;
      padding: 0.7rem;
      flex: 1;
    }

    .LastValueWidget__kpi-container {
      padding: 0.7rem;
      display: flex;
      flex: 1;
    }

    i.Icon {
      grid-column: 2;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.2rem;
      min-width: 2.2rem;
      height: 2.2rem;
      border-radius: 0.3rem;
      justify-self: end;
    }

    .Title {
      text-align: left;
      line-height: 1.2;
      font-weight: 400;
      margin-bottom: 0;

      span {
        height: 100%;
      }
    }

    .kpi {
      text-align: left;
      font-size: 2rem;
      line-height: 0.9;
      font-weight: 600 !important;
      grid-column: 1;
      margin-bottom: 0;

      label {
        font-size: 1rem;
        margin-left: 0.2rem;
      }
    }
  }
`
