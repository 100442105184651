import React, { useEffect, useMemo } from 'react'

import { sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { useCurrentEnergyContracts } from 'services/energyContracts'

import SelectField from '../../SelectField'

const EnergyContractSelectField = ({
  interalIdValue = false,
  onOptionsLoaded,
  isFuelType,
  ...props
}) => {
  const { data, loading } = useCurrentEnergyContracts({ isFuelType })

  useEffect(() => {
    if (onOptionsLoaded && data) {
      onOptionsLoaded(data)
    }
  }, [data, onOptionsLoaded])

  const options = useMemo(
    () =>
      sortBy(
        data?.map(({ id, name, internalId }) => ({
          value: interalIdValue ? internalId : id,
          label: name,
          disabled: interalIdValue && !internalId,
        })),
        ['disabled', 'label']
      ),
    [data, interalIdValue]
  )

  return <SelectField {...props} options={options} loading={loading} />
}

EnergyContractSelectField.propTypes = {
  interalIdValue: PropTypes.bool,
  isFuelType: PropTypes.bool,
}

export default EnergyContractSelectField
