import React, { useCallback, useState } from 'react'

import { injectIntl } from 'react-intl'

import { useEntityPresetList } from 'services/entitypresets'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import ValuePicker from 'components/Form/components/ValuePicker'
import Button from 'components/Global/Button'
import { DatapointDetails } from 'components/Global/FormField/Datapoints'
import { useDatapointHover } from 'components/Global/FormField/Datapoints/functions'

import FormField from '../index'
import { FormFieldLabel } from '../styled'
import { useEquipDatapointMap, usePresetsWithDatapoints } from './hooks'
import EquipmentSelectorStyled from './styled'

const EquipmentSelector = ({ selectedIndex, onClick, intl, dataCy = 'EquipmentSelector' }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()

  const [selectedEntityPreset, setSelectedEntityPreset] = useState({
    id: elementInEditMode.preview.equipmentTemplate?.presetId,
  })
  const { data: entityPresetList } = useEntityPresetList({ type: 'equip' })
  const { data, loading } = usePresetsWithDatapoints(selectedEntityPreset?.id)

  const equipDatapointMap = useEquipDatapointMap(data?.entityPreset)

  const { hoveredElement, detail, onDatapointHover } = useDatapointHover({ delay: 5000 })

  const getEquipmentDatapointsForTemplate = (equipment, template, equipDatapointMap) => {
    const getDatapoints = (template) => {
      return {
        elements: template.elements.map((element) =>
          element.presetId
            ? {
                datapoints: [equipDatapointMap[equipment.id][element.presetId].datapoint],
              }
            : getDatapoints(element)
        ),
      }
    }
    const elements = getDatapoints(template)
    return elements.elements
  }

  const onEquipmentArrayUpdate = useCallback(
    (payload) => {
      let equipments = payload
      if (payload.length > elementInEditMode.preview.equipments.length) {
        equipments = payload.map((equipment, index) => {
          if (index === elementInEditMode.preview.equipments.length) {
            // added item

            return {
              ...equipment,
              elements:
                elementInEditMode.preview.equipmentTemplate &&
                getEquipmentDatapointsForTemplate(
                  equipment,
                  elementInEditMode.preview.equipmentTemplate,
                  equipDatapointMap
                ),
            }
          }
          return equipment
        })
      }
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: {
            dataKey: 'equipments',
          },
          value: equipments,
          rootUpdate: true,
        },
      })
    },
    [action, elementInEditMode, equipDatapointMap]
  )

  const onRemoveEquipment = (e, value) => {
    action({
      type: 'ON_PREVIEW_UPDATE',
      payload: {
        graph: {
          dataKey: 'equipments',
        },
        value: elementInEditMode.preview.equipments.filter(({ id }) => id !== value.id),
        rootUpdate: true,
      },
    })
  }

  const handleEquipmentClick = (e, { id, name, label, elements, index }) => {
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: index,
        dataKey: 'equipments',
      },
    })
    onClick(e, {
      id,
      name,
      label,
      elements,
      index,
      equipDatapointMap,
    })
  }

  const handleEntityPresetSelect = useCallback(
    (payload) => {
      setSelectedEntityPreset({ id: payload.value })

      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: {
            dataKey: 'equipmentTemplate',
          },
          value: {
            presetId: payload.value,
            elements: [],
          },
          rootUpdate: true,
        },
      })
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: {
            dataKey: 'equipments',
          },
          value: [],
          rootUpdate: true,
        },
      })
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: {
            dataKey: 'detailTemplate',
          },
          value: null,
          rootUpdate: true,
        },
      })
    },
    [action]
  )

  return (
    <EquipmentSelectorStyled className="EquipmentSelector">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.selectedEquipments' })}</FormFieldLabel>
      {elementInEditMode.preview.equipments.map(
        ({ id, name = 'equipment', label, elements }, index) => (
          <div
            key={id}
            onMouseEnter={(e) => onDatapointHover(e, { id, name })}
            onMouseLeave={(e) => onDatapointHover(e, null)}
          >
            <Button
              key={id}
              label={name}
              variant="tag"
              onIconClick={(e) => onRemoveEquipment(e, { id, name })}
              icon="fas fa-xmark"
              isActive={selectedIndex === index}
              onClick={(e) => handleEquipmentClick(e, { id, name, label, elements, index })}
              dataCy={`${dataCy}-${id}`}
            />
          </div>
        )
      )}

      <FormField
        value={selectedEntityPreset?.id}
        componentName="ValuePicker"
        label={{ formatted: 'widget.equipmentType' }}
        disabled={elementInEditMode.preview.equipments.length > 0}
        componentProps={{
          search: true,
          options: entityPresetList,
          selectionKeys: {
            label: 'translations',
            value: 'id',
          },
        }}
        dataCy={`${dataCy}-equipmentTemplate.presetId`}
        onChange={handleEntityPresetSelect}
      />
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.equipmentsForType' })}</FormFieldLabel>
      <ValuePicker
        options={data?.entityPreset?.entities || []}
        value={elementInEditMode.preview.equipments}
        //dropdown="open"
        search
        input
        selectionKeys={{ label: 'name', value: 'id' }}
        isMultiSelect
        hideValue
        variant="Tags"
        onChange={onEquipmentArrayUpdate}
        onOptionHover={onDatapointHover}
        loading={loading}
        persistSearchValue
        dataCy={`${dataCy}-equipments`}
      />
    </EquipmentSelectorStyled>
  )
}

export default injectIntl(EquipmentSelector)
