export const config = {
  displayProps: {},
  editProps: {
    name: 'widget.consumptionExport',
    category: 'widget.others',
    keywords: ['other'],
    img: '/resources/images/widgets/clock.png',
    info: {
      'fr-FR':
        '<h4>Horloge</h4>\n' +
        "<p>Le widget de l'horloge affiche l'heure et la date actuelle.</p>" +
        '<ul>\n' +
        '<li><strong>Label </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> icône du widget</li>\n' +
        '<li><strong>Couleur </strong> Sélectionnez une couleur générale pour le widget</li>\n' +
        '<li><strong>Taille </strong> Sélectionnez la taille du widget</li>\n' +
        '<li><strong>Afficher la date </strong> Afficher la date ou non</li>\n' +
        '<li><strong>Format de la date </strong> Sélectionnez le format de la date</li>\n' +
        "<li><strong>Format de l'heure </strong> Sélectionnez le format de l'heure</li>" +
        '</ul>',
      'en-US':
        '<h4>Clock</h4>\n' +
        '<p>The clock widget displays the current time and date.</p>' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Color</strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '<li><strong>Show Date</strong> Display the date or not</li>\n' +
        '<li><strong>Date Format</strong> Select the date format</li>\n' +
        '<li><strong>Time Format</strong> Select the time format</li>' +
        '</ul>\n',
      'nl-NL':
        '<h4>Klok</h4>\n' +
        '<p>De klok widget toont de huidige tijd en datum</p>' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's titel</li>\n" +
        '<li><strong>Pictogram</strong> Pictogram van de widget</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '<li><strong>Toon datum</strong> Toon de datum of niet</li>\n' +
        '<li><strong>Datum formaat</strong> Selecteer de datumnotatie</li>\n' +
        '<li><strong>Tijd Formaat</strong> Selecteer het tijdformaat</li>' +
        '</ul>\n',
    },
    preview: {},
    default: {
      type: 'ConsumptionExport',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'CustomerPicker',
        dataKey: 'customer',
        label: { formatted: 'widget.customer' },
      },
      {
        category: 'widget.datapoints',
        componentName: 'EntitiesPicker',
        label: { formatted: 'widget.entities' },
        dataKey: 'siteRefs',
        componentProps: {
          search: true,
          query: {
            name: 'PERMITTED_ENTITIES',
            dataKey: 'allowedEntities',
            variables: {
              type: 'building',
            },
          },
          isMultiSelect: true,
          selectionKeys: {
            value: 'id',
            label: 'name',
          },
          variant: 'Tags',
        },
      },
    ],
  },
}

export default config
