import { useCallback, useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { useOrganisationReportingYear } from '../../../../services/organisations'
import { useDashboard } from '../../DashboardProvider'
import { parseDefaultTime } from './functions'
import { useTimeSelection } from './TimeSelectionProvider'

const getDefaultStartTime = (defaultStartTime, locationData) => {
  if (defaultStartTime?.preset === 'linkedWidget' && locationData?.startTime) {
    return {
      startTime: dayjs(locationData.startTime),
      endTime: dayjs(locationData.endTime),
    }
  }
  if (
    (defaultStartTime?.preset && defaultStartTime.preset !== 'none') ||
    defaultStartTime?.amount ||
    defaultStartTime?.startTime
  ) {
    return {
      preset: defaultStartTime?.preset,
      startTime: parseDefaultTime(defaultStartTime || { amount: 1, unit: 'day' }, 'startTime'),
      endTime: defaultStartTime ? parseDefaultTime(defaultStartTime, 'endTime') : null,
    }
  }
  if (defaultStartTime?.startOfUnit) {
    return {
      startTime: dayjs().startOf(defaultStartTime.startOfUnit),
      endTime: dayjs(),
    }
  }

  return {}
}

const getDefaultGranularity = (granularity, locationData) => {
  return granularity.granularity === 'linkedData' ? locationData?.granularity || {} : granularity
}
export const useDefaultTimeParams = ({
  defaultStartTime,
  defaultGranularityValue,
  defaultGranularity,
  heatmapGranularityValue: heatmapGranularityValueDefault,
  heatmapGranularityUnit: heatmapGranularityUnitDefault,
  defaultCalculationMethod,
  organisationId,
  dataProps,
}) => {
  const { state } = useDashboard()
  const { time: sectionTime, source: sectionSource } = useTimeSelection() || {}
  const { data: reportingYearData, createDateRange } = useOrganisationReportingYear(
    organisationId,
    defaultStartTime && defaultStartTime.preset !== 'none'
  )

  const locationData = dataProps?.locationData || state.locationData

  const [time, setTime] = useState(() => getDefaultStartTime(defaultStartTime, locationData))

  const [{ granularity, granularityValue }, setGranularity] = useState(() =>
    getDefaultGranularity(
      {
        granularity: defaultGranularity,
        granularityValue: defaultGranularityValue,
      },
      locationData
    )
  )
  const [{ heatmapGranularityValue, heatmapGranularityUnit }, setHeatmapGranularity] = useState({
    heatmapGranularityValue: heatmapGranularityValueDefault,
    heatmapGranularityUnit: heatmapGranularityUnitDefault,
  })
  const [calculationMethod, setCalculationMethod] = useState(defaultCalculationMethod)
  const [source, setSource] = useState(sectionSource)

  useEffect(() => {
    if (sectionTime?.startTime) {
      setTime(sectionTime)
      setSource(sectionSource)
    }
  }, [sectionSource, sectionTime])

  useEffect(() => {
    setHeatmapGranularity({
      heatmapGranularityValue: heatmapGranularityValueDefault,
      heatmapGranularityUnit: heatmapGranularityUnitDefault,
    })
  }, [heatmapGranularityValueDefault, heatmapGranularityUnitDefault])

  useEffect(() => {
    setTime(getDefaultStartTime(defaultStartTime, locationData))
  }, [defaultStartTime, locationData])

  useEffect(() => {
    if (reportingYearData) {
      const year = dayjs().year()
      const dateRange = createDateRange(year)
      setTime({
        startTime: dayjs(dateRange[0]),
        endTime: dayjs(dateRange[1]),
      })
    }
  }, [reportingYearData, createDateRange])

  useEffect(() => {
    setCalculationMethod(defaultCalculationMethod)
  }, [defaultCalculationMethod])
  useEffect(() => {
    setGranularity(
      getDefaultGranularity(
        {
          granularity: defaultGranularity,
          granularityValue: defaultGranularityValue,
        },
        locationData
      )
    )
  }, [defaultGranularity, defaultGranularityValue, locationData])

  const onValueUpdate = useCallback((value, dataKey) => {
    if (dataKey === 'granularity' || dataKey === 'granularityValue') {
      return setGranularity((state) => ({ ...state, [dataKey]: value }))
    }
    if (dataKey === 'time') {
      return setTime(value)
    }
    if (dataKey === 'source') {
      return setSource(value)
    }
    if (dataKey === 'calculationMethod') {
      return setCalculationMethod(value)
    }
    if (dataKey === 'heatmapGranularity') {
      return setHeatmapGranularity(value)
    }
  }, [])

  return {
    time,
    granularity,
    granularityValue,
    heatmapGranularityValue,
    heatmapGranularityUnit,
    calculationMethod,
    source,
    onValueUpdate,
  }
}
