import React from 'react'

import { Value } from 'components/Form/components/Field/styled'
import Skeleton from 'components/Form/components/Skeleton/styled'
import TextInput from 'components/Form/components/TextInput'
import { Unit, ValueUnitFieldStyled } from 'components/Form/fields/ValueUnitField/styled'

import { useLocaleNumber } from '../../../../util/numbers'

const ValueUnitField = ({ value, unit, name, unitName, ...fieldProps }) => {
  const localizeNumber = useLocaleNumber()
  return (
    <ValueUnitFieldStyled {...fieldProps}>
      {({ register, isEditMode, watch, loading }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        const fieldUnit = unit === undefined && unitName !== undefined ? watch(unitName) : unit
        if (loading) {
          return <Skeleton width="200px" />
        }
        if (isEditMode) {
          return (
            <>
              <Value as={TextInput} register={register} value={value} name={name} />
              <Unit>{fieldUnit}</Unit>
            </>
          )
        }

        return (
          <>
            <Value>{localizeNumber(fieldValue)}</Value>
            <Unit>{fieldUnit}</Unit>
          </>
        )
      }}
    </ValueUnitFieldStyled>
  )
}

export default ValueUnitField
