import React from 'react'

import { withTheme } from 'styled-components'

import MainPanelStyled from './styled'

const MainPanel = ({ children }) => {
  return (
    <MainPanelStyled className="MainPanel" id="main-panel">
      {children}
    </MainPanelStyled>
  )
}

MainPanel.propTypes = {}

MainPanel.defaultProps = {}

export default withTheme(MainPanel)
