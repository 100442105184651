import { css } from 'styled-components'

export const WidgetEditor = css`
  background: ${({ theme }) => theme.color.backgroundFront};
  opacity: 0 !important;
  box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
  transform: scale(0);
  padding: 0.5em;
  border-radius: 1em;
`
