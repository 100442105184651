import React, { useEffect, useState } from 'react'

import { injectIntl } from 'react-intl'

import { useAccordeonContext } from 'components/LayoutComponents/Accordeon/AccordeonProvider'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import FormField from '../index'
import LinkSelector from '../LinkSelector'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import LinkPickerStyled from './styled'

const linkTypes = [
  {
    value: 'link',
    label: {
      formatted: 'widget.link',
    },
  },
  {
    value: 'writeDatapoint',
    label: {
      formatted: 'widget.writeDatapoint',
    },
  },
  {
    value: 'clear',
    label: {
      formatted: 'widget.noLink',
    },
  },
]

const LinkPicker = ({
  value,
  writeDatapointOption,
  dropdown,
  subPath,
  onChange,
  dataCy = 'LinkPicker',
  hideSections = false,
  intl,
}) => {
  const { action } = useDashboard()
  const { handleRecalculateHeight } = useAccordeonContext()

  const [isOpen, setIsOpen] = useState(false)
  const [linkType, setLinkType] = useState('clear')
  const url = value?.url || ''

  useEffect(() => {
    if (writeDatapointOption && value?.writeDatapoint?.length) {
      return setLinkType('writeDatapoint')
    }

    return setLinkType(url ? 'link' : 'clear')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectDatapoint = () => {
    onChange(null, 'url')
    setIsOpen((state) => !state)
  }
  const onSelectLinkType = (e, { value }) => {
    switch (value) {
      case 'clear':
        onChange([], 'writeDatapoint')
        onChange(null, 'url')
        break
      case 'writeDatapoint':
        onChange(null, 'url')
        break
      default:
        onOpenBottomDrawer()
    }
    setLinkType(value)
    handleRecalculateHeight && handleRecalculateHeight()
  }

  const onOpenBottomDrawer = () => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: true,
          width: 'full',
          component: LinkSelector,
          key: 'link-selector',
          componentProps: {
            intl,
            onSave: onBottomDrawerSave,
            singleDatapoint: true,
            dataKey: 'url',
          },
        },
      },
    })
  }

  const onBottomDrawerSave = (payload = {}) => {
    if (payload?.raw) {
      onChange(payload.raw.url, 'url', payload.raw)
      onChange(payload.raw.title, 'name', payload.raw)
    }
  }

  const fieldConfig = {
    writeDatapoint: {
      componentName: 'Datapoints',
      label: { formatted: 'widget.writeDatapoint' },
      subPath,
      componentProps: {
        value: value?.writeDatapoint,
        dataKey: 'writeDatapoint',
        singleDatapoint: true,
      },
      onChange: onSelectDatapoint,
      dataCy,
    },
  }[linkType]

  return (
    <LinkPickerStyled
      className="LinkPicker"
      button={dropdown !== 'open' && { icon: url }}
      isOpen={dropdown === 'open' || isOpen}
      onToggleOpen={(payload) =>
        setIsOpen((state) => (typeof payload !== 'undefined' ? payload : !state))
      }
      dropdown={dropdown}
    >
      <CheckboxSelector
        options={linkTypes.filter((type) =>
          writeDatapointOption
            ? type
            : type.value !== 'writeDatapoint' && !(hideSections && type.value === 'section')
        )}
        value={linkType}
        onSelect={onSelectLinkType}
        dataCy={`${dataCy}-type-selector`}
      />
      {url && <Button variant="tag" label={value.name || url} onClick={onSelectLinkType} />}
      {fieldConfig && <FormField {...fieldConfig} />}
    </LinkPickerStyled>
  )
}

LinkPicker.propTypes = {}

LinkPicker.defaultProps = {
  options: [],
}

export default injectIntl(LinkPicker)
