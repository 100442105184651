import React, { useMemo } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { message as notification, Tooltip } from 'antd'
import { injectIntl } from 'react-intl'

import Icon from 'components/Global/Icon'

import {
  DASHBOARD_FAVOURITES_QUERY,
  DASHBOARD_QUERY,
  DASHBOARDS_FOR_MENU_QUERY,
  UPDATE_DASHBOARD_MUTATION,
} from '../../../services/dashboards'
import { FavouriteButton, PageTitleStyled } from './styled'

const PageTitle = ({ type, page, intl }) => {
  const { data: dashboard, error } = useQuery(DASHBOARD_QUERY, {
    skip: type !== 'dashboard' || (type === 'dashboard' && page === 'management'),
    variables: {
      id: page,
    },
  })

  const { data: favouriteData } = useQuery(DASHBOARD_FAVOURITES_QUERY)

  // Favorite update
  const [updateDashboard] = useMutation(UPDATE_DASHBOARD_MUTATION, {
    refetchQueries: [
      {
        query: DASHBOARDS_FOR_MENU_QUERY /* DASHBOARDS_QUERY */,
        fetchPolicy: 'network-only',
      },
      { query: DASHBOARD_QUERY, variables: { id: page } },
      { query: DASHBOARD_FAVOURITES_QUERY },
    ],
  })
  if (error) {
    console.error('Failed fetching dashboard')
  }

  const { id, title, icon, isFavourite } = (dashboard && dashboard.dashboard) || {
    dashboard: {},
  }

  const disableFavourite = useMemo(() => {
    const favouriteCount = favouriteData?.favouriteDashboards?.length
    return !isFavourite && (favouriteCount === undefined ? true : favouriteCount >= 5)
  }, [favouriteData, isFavourite])

  const _title = {
    dashboard: title,
    'squeal-overview': 'Squeal Overview',
    settings: 'Settings',
    sustainability: type === 'sustainability' && intl.formatMessage({ id: 'sustainability.menu' }),
  }[type]

  const onClickFavourite = async () => {
    const res = await updateDashboard({
      variables: {
        id: id,
        data: {
          favourite: !isFavourite,
        },
      },
    })

    const { success, message } = res.data.updateDashboard
    if (success) {
      if (isFavourite) {
        notification.success(`Removed dashboard ${title} from favourites`, 3)
      } else {
        notification.success(`Added dashboard ${title} to favourites`, 3)
      }
    } else {
      notification.error(`Something went wrong: ${message}`, 3)
    }
  }

  return (
    <PageTitleStyled className="PageTitle">
      {icon && <Icon icon={icon} />}
      {_title && <h1>{_title}</h1>}
      {type === 'dashboard' && page !== 'squeal-overview' && page !== 'management' && (
        <Tooltip
          title={intl.formatMessage(
            {
              id: disableFavourite
                ? 'maxFavouritesReached'
                : 'menu.dashboardForm.favouriteDashboard',
            },
            { amount: 5 }
          )}
        >
          <span>
            <FavouriteButton
              onClick={onClickFavourite}
              icon={isFavourite ? 'fas fa-star' : 'far fa-star'}
              isActive={isFavourite}
              disabled={disableFavourite}
            />
          </span>
        </Tooltip>
      )}
    </PageTitleStyled>
  )
}

PageTitle.propTypes = {}

PageTitle.defaultProps = {}

export default injectIntl(PageTitle)
