import React, { useEffect, useState } from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import Component from 'components/Global/Component/styled'
import ValueTypeSelector from 'components/Global/FormField/ValueTypeSelector'

import { calculationMethods } from '../../../Dashboard/Widget/config/selections'
import OptionPicker from '../../../Form/components/OptionPicker'
import DatapointGroups from '../DatapointGroups'
import { Datapoints } from '../Datapoints'
import { Entities } from '../Entities'
import EntityValueTypePicker from '../EntityValueTypePicker'
import NumberInput from '../NumberInput'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import { FormFieldLabel } from '../styled'
import ComparatorStyled from './styled'

const selector = {
  DatapointSelector: Datapoints,
  GhgDataSelector: DatapointGroups,
}

const Comparator = ({
  value = {},
  bottomDrawer = 'DatapointSelector',
  dataKey = 'datapoints',
  subPath,
  method,
  onChange,
  fixedUnit,
  hideMethod,
  disableProperty = false,
  canReselect = true,
  buttons,
  label,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()
  const [comparatorType, setComparatorType] = useState('none')

  const comparatorTypes = [
    ...(disableProperty
      ? [
          {
            value: 'none',
            defaultValue: null,
            label: {
              formatted: 'widget.none',
            },
          },
        ]
      : []),
    {
      value: 'fixed',
      defaultValue: [0],
      label: {
        formatted: 'widget.fixedValue',
      },
    },
    {
      value: 'main',
      defaultValue: 'main',
      label: {
        formatted: 'widget.mainDatapoint',
      },
    },
    {
      value: 'datapoint',
      defaultValue: [],
      label: {
        formatted: 'widget.otherDatapoint',
      },
    },
    {
      value: 'entity',
      defaultValue: [],
      label: {
        formatted: 'widget.entityValue',
      },
    },
  ]

  const dataKeyValue = value[dataKey] || []
  const mainDatapoints =
    comparatorType === 'main' &&
    get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}datapoints`)
  const mainMethodDataKey = comparatorType === 'main' && `${dataKey}Method`

  useEffect(() => {
    if (dataKeyValue === 'main') {
      return setComparatorType('main')
    }
    if (dataKeyValue[0] !== undefined) {
      setComparatorType(
        typeof dataKeyValue[0] === 'number'
          ? 'fixed'
          : dataKeyValue[0]?.tag
          ? 'entity'
          : 'datapoint'
      )
    }
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (comparatorType === 'entity') {
      const tag = dataKeyValue.find(({ tag }) => tag)?.tag
      onChange(
        dataKeyValue.map((entity) => ({ ...entity, tag })),
        dataKey
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKeyValue.length, comparatorType])

  const onSelectComparatorType = (e, { value, defaultValue }) => {
    setComparatorType(value)
    if (value === 'fixed') {
    }
    onChange(defaultValue, dataKey)
  }

  const onValueUpdate = (payload) => {
    onChange([payload * 1], dataKey)
  }

  const onEntitySelect = (value, fieldDataKey) => {
    onChange(value, fieldDataKey || dataKey)
  }

  const onTagSelect = (payload) => {
    const entities = get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`)
    onChange(
      entities.map((entity) => ({ ...entity, tag: payload?.replace('tags.', '') })),
      dataKey
    )
  }

  return (
    <ComparatorStyled className="Comparator">
      <CheckboxSelector
        className="CheckboxSelector"
        options={comparatorTypes}
        value={comparatorType}
        onSelect={onSelectComparatorType}
      />

      {comparatorType === 'fixed' && <NumberInput value={dataKeyValue[0]} onBlur={onValueUpdate} />}
      {comparatorType === 'datapoint' && (
        <Component
          as={selector[bottomDrawer]}
          value={value}
          dataKey={dataKey}
          subPath={subPath}
          onChange={onEntitySelect}
          method={`${dataKey}Method`}
          fixedUnit={fixedUnit}
          bottomDrawer={bottomDrawer}
          hideMethod={hideMethod}
          buttons={buttons}
          label={label}
          canReselect={canReselect}
        />
      )}
      {comparatorType === 'entity' && (
        <>
          <Entities
            value={value}
            dataKey={dataKey}
            subPath={subPath}
            onChange={(value, fieldDataKey) => onChange(value, fieldDataKey || dataKey)}
            method={`${dataKey}Method`}
            fixedUnit={fixedUnit}
            bottomDrawer={bottomDrawer}
            hideMethod={hideMethod}
            buttons={buttons}
            label={label}
            canReselect={canReselect}
          />
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.value' })}</FormFieldLabel>
          <EntityValueTypePicker
            dataKey={dataKey}
            subPath={subPath}
            tagsOnly
            types={[]}
            onChange={onTagSelect}
            value={`tags.${dataKeyValue?.[0]?.tag}`}
          />
          {dataKeyValue.length > 1 && (
            <>
              <FormFieldLabel>
                {intl.formatMessage({ id: 'widget.calculationMethod' })}
              </FormFieldLabel>
              <OptionPicker
                options={calculationMethods.filter(
                  ({ value }) => value !== 'range' && value !== 'amount'
                )}
                onChange={(value) => onChange(value, `${dataKey}Method`)}
                value={value[`${dataKey}Method`]}
              />
            </>
          )}
        </>
      )}
      {comparatorType === 'main' && mainDatapoints?.length > 1 && (
        <ValueTypeSelector
          value={get(
            elementInEditMode.preview,
            `${subPath ? `${subPath}.` : ''}${mainMethodDataKey}`
          )}
          componentName="ValuePicker"
          label={{ formatted: 'widget.method' }}
          dataKey={method}
          datapointsDataKey={dataKey}
          subPath={subPath}
          onChange={({ value }) => onChange(value, mainMethodDataKey)}
        />
      )}
    </ComparatorStyled>
  )
}

export default injectIntl(Comparator)
