import React from 'react'

import { injectIntl } from 'react-intl'

import ValuePicker from 'components/Form/components/ValuePicker'
import Button from 'components/Global/Button'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import { useDashboard } from '../../DashboardProvider'
import MicroWidgetEdgesStyled from './styled'

const MicroWidgetEdges = ({ value = [], onChange, onClick, intl }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()

  const handleDatapointClick = (e, { id, name, index }) => {
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: index,
          dataKey: 'edges',
        },
      })
      onClick(e, { id, name, index })
    }
  }

  const handleSelectDatapoint = (datapoints) => {
    onChange(datapoints.map(({ id, name }) => ({ id, name })))
  }

  const onRemoveDatapoint = (datapoint) => {
    onChange(value.filter(({ id }) => id !== datapoint.id))
  }
  return (
    <MicroWidgetEdgesStyled className="MicroWidgetEdges">
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.connectedDatapoints' })}</FormFieldLabel>
      {value.map(({ id, name, label, elements, color }, index) => (
        <Button
          key={id}
          label={name}
          variant="tag"
          onIconClick={() => onRemoveDatapoint({ id, name })}
          icon="fas fa-xmark"
          color={color}
          onClick={(e) => handleDatapointClick(e, { id, name, label, elements, index })}
        />
      ))}
      <ValuePicker
        options={elementInEditMode.preview.datapoints || []}
        value={value}
        input
        search
        selectionKeys={{ label: 'name', value: 'id' }}
        isMultiSelect
        hideValue
        variant="Tags"
        onChange={handleSelectDatapoint}
      />
    </MicroWidgetEdgesStyled>
  )
}

export default injectIntl(MicroWidgetEdges)
