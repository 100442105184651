import React, { useState } from 'react'

// import { ShareAltOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Form, message, Tooltip } from 'antd'
import { injectIntl, intlShape } from 'react-intl'

import Icon from 'components/Global/Icon'
import { CanaryModal } from 'components/Global/Modal'

import { MenuActionButtonStyled } from '../MenuPanel/styled'
import ShareDashboard from './ShareDashboard'

const SHARE_DASHBOARD_MUTATION = gql`
  mutation shareDashboards($data: ShareDashboardInput!) {
    shareDashboards(data: $data) {
      ... on DashboardUpdateResult {
        success
        message
      }
      ... on CheckDashboardResult {
        status
        dashboardCheckInfo {
          user
          dashboardTitle
        }
      }
    }
  }
`

const ShareDashboardButton = ({ intl }) => {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [shareDashboards] = useMutation(SHARE_DASHBOARD_MUTATION)
  const [form] = Form.useForm()

  const handleFinish = async () => {
    if (!form.getFieldValue('dashboards')?.length) {
      message.error({
        content: `${intl.formatMessage({ id: 'settings.dashboards.selectDashboards' })}`,
      })
      return
    }

    setConfirmLoading(true)
    const values = await form.validateFields().catch((err) => {
      console.error('Validation failed: ', err)
    })

    if (!values) {
      setConfirmLoading(false)
      return
    }

    const { data } = await shareDashboards({
      variables: { data: values },
    })

    // Check if sharing MDB doesn't result in duplicates for invitees
    if (data?.shareDashboards?.status === 'nok') {
      const info = data?.shareDashboards?.dashboardCheckInfo?.map(
        (line) => `User ${line.user} already has dashboard '${line.dashboardTitle}'`
      )
      message.error({
        content: info.join('\n'),
      })
      setConfirmLoading(false)
      return
    }

    if (data?.error || !data?.shareDashboards?.success) {
      message.error({
        content: `${intl.formatMessage({ id: 'menu.dashboard.shareFailed' })} ${
          data?.error || data?.shareDashboards?.message
        }`,
      })
    }
    if (data?.shareDashboards?.success) {
      message.success({
        content: intl.formatMessage({ id: 'menu.dashboard.shareSuccess' }),
      })

      form.resetFields()
      setVisible(false)
    }

    setConfirmLoading(false)
  }

  return (
    <>
      <Tooltip
        overlayStyle={{ zIndex: 2500 }}
        title={intl.formatMessage({ id: 'menu.dashboard.shareDashboard' })}
      >
        <MenuActionButtonStyled
          onClick={() => setVisible(true)}
          className="ButtonStyled"
          type="link"
          as="span"
        >
          <Icon icon={`far fa-share-from-square`} />
        </MenuActionButtonStyled>
      </Tooltip>
      <CanaryModal
        destroyOnClose
        title={intl.formatMessage({ id: 'menu.dashboard.shareDashboard' })}
        visible={visible}
        centered={true}
        width={'60%'}
        bodyStyle={{
          height: '50vh',
        }}
        okText={intl.formatMessage({ id: 'menu.dashboard.share' })}
        onOk={handleFinish}
        confirmLoading={confirmLoading}
        onCancel={() => {
          form.resetFields()
          setConfirmLoading(false)
          setVisible(false)
        }}
      >
        <ShareDashboard form={form} />
      </CanaryModal>
    </>
  )
}

ShareDashboardButton.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ShareDashboardButton)
