import React, { useState } from 'react'

import error from 'locales/en-US/error'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Global/Icon'

import { Error, InlineErrorsStyled } from './styled'

const InlineErrors = ({ errors }) => {
  const [hidden, setHidden] = useState([])
  const icon = {
    error: 'fas fa-circle-xmark',
    warning: 'fas fa-triangle-exclamation',
    info: 'fas fa-circle-info',
  }
  return (
    <InlineErrorsStyled className="InlineErrors">
      {errors.map(({ code, level, details, message }, index) =>
        hidden.includes(index) ? null : (
          <Error
            className="Error"
            type={level}
            key={`error-${index}`}
            dropdown
            button={{
              icon: icon[level],
            }}
          >
            {error[code] ? (
              <FormattedMessage id={code} values={details} tagName="span" />
            ) : (
              <span>
                {level} on datapoint {details.datapointName}: {message}
              </span>
            )}
            <Icon
              className="hide-button"
              icon="fas fa-xmark"
              onClick={() => setHidden((state) => state.concat(index))}
            />
          </Error>
        )
      )}
    </InlineErrorsStyled>
  )
}

export default InlineErrors
