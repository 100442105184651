import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { GridChartsModule } from '@ag-grid-enterprise/charts'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'

LicenseManager.setLicenseKey(
  'CompanyName=Canary,LicensedApplication=Aviary,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-036294,SupportServicesEnd=24_February_2024_[v2]_MTcwODczMjgwMDAwMA==445e7e455aea789c35a394c4b2d63e92'
)

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  RowGroupingModule,
  MenuModule,
  SetFilterModule,
  RangeSelectionModule,
  ClipboardModule,
  GridChartsModule,
])
