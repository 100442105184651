import React from 'react'

import AvailableItem from './AvailableItem'
import { StyledAvailableContainer } from './styled'

const AvailableContainer = ({ availableItems }) => {
  return (
    <StyledAvailableContainer>
      {availableItems.map((item) => (
        <AvailableItem caption={item} />
      ))}
    </StyledAvailableContainer>
  )
}

export default AvailableContainer
