export default {
  'license.energyInsights': 'Energy insights',
  'license.small': 'Small',
  'license.medium': 'Medium',
  'license.enterprise': 'Enterprise',

  'moduleGroup.audit': 'Marketplace',
  'moduleGroup.dataAccess': 'Data Access',
  'moduleGroup.general': 'Varia',
  'moduleGroup.services': 'Services',
  'moduleGroup.sustainability': 'Sustainability',
  'moduleGroup.sustainability.enterprise': 'Sustainability - Enterprise',

  'module.oData': 'OData',
  'module.restApi': 'REST Api',
  'module.dataReadiness': 'Data Readiness',
  'module.csrd': 'CSRD (Corporate Sustainability Reporting Directive)',
  'module.esg': 'ESG (Environment Social Governance)',
  'module.ghg': 'GHG (Greenhouse Gas Protocol)',
  'module.ghg.scope3': 'GHG Scope 3',
  'module.gri': 'GRI (Global Reporting Initiative)',
  'module.sbti': 'SBTi (Science Based Targets initiative)',
  'module.sdg': 'SDG (Sustainable Development Goals)',
  'module.dataAccess.oData': 'OData',
  'module.dataAccess.restApi': 'REST API',
  'module.smsModule': 'SMS',
  'module.inviteCompany': 'Inviter des Entreprises',
  'module.audit': 'Sustainability AUDIT',
  'module.enquiry': 'Sustainability ENQUIRY',

  'quota.maxSites': 'Max sites',
  'quota.maxUsers': 'Utilisateurs max',
  'quota.smsLimit': 'Limite de SMS',
  'quota.maxDatapoints': 'Nombre de données max',
  'quota.maxDataposts': 'Max dataposts',
}
