import React, { useState } from 'react'

import { useQueryData } from '../../../../services/data'
import { getComparatorValue } from '../../../../util/datapointCalculationFunctions'
import { useComparatorsPayload } from '../../../../util/hooks'
import { useDashboard } from '../../../Dashboard/DashboardProvider'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import ColorRangeSlider from './ColorRangeSlider'
import { getMinMaxByWidgetType, modes } from './constants'
import { useComparatorUnit } from './hooks'

const MinMaxColorRangeSlider = ({ onChange, value }) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const [mode, setMode] = useState('percentage')

  const {
    datapoints,
    deviation,
    comparators,
    minComparators,
    maxComparators,
    timeRange,
    comparisonPeriod,
    conversionUnit,
    comparatorUnit,
    customLogic,
    decimals,
    comparatorsMethod,
    minComparatorsMethod,
    maxComparatorsMethod,
    comparatorsCalculationMethod,
    minComparatorsCalculationMethod,
    maxComparatorsCalculationMethod,
    type,
  } = elementInEditMode.preview

  const singleComparator = typeof deviation === 'number' || type === 'Consumption'

  const {
    values: minComparatorValues,
    comparatorDatapoints: minComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints,
    comparators: minComparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: minComparatorsData } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: minComparatorDatapoints,
      widgetType: 'gaugeMinComparator',
    },
    deps: [minComparatorDatapoints],
    skip: singleComparator || minComparatorDatapoints.length === 0,
  })

  const {
    values: maxComparatorValues,
    comparatorDatapoints: maxComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints,
    comparators:
      type === 'Consumption'
        ? datapoints
        : singleComparator
        ? comparators
        : maxComparators || comparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: maxComparatorsData } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: maxComparatorDatapoints,
      widgetType: 'gaugeMaxComparator',
    },
    deps: [maxComparatorDatapoints],
    skip: maxComparatorDatapoints.length === 0,
  })

  const roundedMinimum = getComparatorValue({
    comparators: minComparators,
    comparatorValues: minComparatorValues,
    comparatorsData: minComparatorsData,
    method: minComparatorsMethod,
    customLogic,
    decimals,
    calculationMethod: minComparatorsCalculationMethod,
    defaultValue: 0,
  })

  const roundedMaximum = getComparatorValue({
    comparators:
      type === 'Consumption' ? datapoints : singleComparator ? comparators : maxComparators,
    comparatorValues: maxComparatorValues,
    comparatorsData: maxComparatorsData,
    method: singleComparator ? comparatorsMethod : maxComparatorsMethod,
    customLogic,
    decimals,
    calculationMethod: singleComparator
      ? comparatorsCalculationMethod
      : maxComparatorsCalculationMethod,
    defaultValue: 100,
  })

  const onSelectType = (e, { value }) => {
    setMode(value)
  }

  const unit = useComparatorUnit({
    mode,
    comparatorUnit,
    maxComparators,
    minComparators,
    comparators,
    minComparatorsData,
    maxComparatorsData,
    datapoints,
  })

  return (
    <>
      <CheckboxSelector
        className="CheckboxSelector"
        options={modes}
        value={mode}
        onSelect={onSelectType}
      />
      <ColorRangeSlider
        onChange={onChange}
        value={value}
        tickRange={
          mode === 'percentage'
            ? [0, 100]
            : getMinMaxByWidgetType(roundedMinimum, roundedMaximum, deviation)
        }
        unit={unit}
        mode={mode}
      />
    </>
  )
}

export default MinMaxColorRangeSlider
