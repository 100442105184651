import React from 'react'

import round from '../../../../util/round'
import ColorPicker from '../ColorPicker'
import { HandleStyled } from './styled'

const getAbsoluteValue = ([min, max], percentage) => {
  const diff = max - min
  return (diff * percentage) / 100 + min
}

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  tickRange,
  mode,
  unit,
  color,
  getHandleProps,
  onClick,
  onEdit,
  onColorChange,
  index,
}) => {
  const displayValue = round(
    mode === 'percentage' ? percent : getAbsoluteValue(tickRange, percent),
    0
  )
  const handleEditClick = (e, type) => {
    e.stopPropagation()
    onEdit(type, index)
  }
  return (
    <HandleStyled className="Handle" percent={percent} color={color}>
      <div {...getHandleProps(id)} onClick={onClick}>
        <span className="Handle__value">
          {displayValue}
          {unit}
        </span>
        <span className="Handle__edit">
          <span onClick={(e) => handleEditClick(e, 'delete')}>-</span>
          <span onClick={(e) => handleEditClick(e, 'add')}>+</span>
        </span>
      </div>
      <ColorPicker
        value={color}
        onChange={(value) => onColorChange(value, index)}
        clearIcon={false}
      />
      <div className="Handle__handle" />
      <div role="slider" aria-valuemin={min} aria-valuemax={max} aria-valuenow={value} />
    </HandleStyled>
  )
}

export default Handle
