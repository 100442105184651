import dayjs from 'dayjs'
import { transparentize } from 'polished'

import {
  CALCULATIONS,
  DATE_FORMAT_BY_UNIT,
  DATETIME_NAME_FORMAT,
  HIGHCHARTS_DASH_STYLES,
} from 'util/constants'
import customLogicRunner from 'util/customLogicRunner'
import { getEmissionUnit } from 'util/datapointCalculationFunctions'
import round from 'util/round'

import { emissionFactors } from 'components/Dashboard/Widget/config/selections'

import {
  calculateMinMax,
  getConversionFn,
  getTargetHistory,
  getTooltipDate,
  getZones,
  translateHistoryLabel,
} from '../../Dashboard/utils/common/helpers'

function DEFAULT_TIMESTAMP_FORMATTER(value) {
  return dayjs(value).format(DATETIME_NAME_FORMAT)
}

const historyToHighchartsSeries = (history = [], offSetShift = 0) =>
  history.reduce(
    (output, { time, value }) => {
      output.series.push([time + offSetShift, value])
      return output
    },
    { series: [] }
  )

const getConversionHistory = ({ customLogic, multiplyFactor, operation, offset }, history = []) => {
  if (!customLogic && !operation && !offset) {
    return history
  }
  return history.map((record) => {
    const value = operation
      ? getConversionFn(operation)(record.value, multiplyFactor)
      : record.value

    return {
      ...record,
      time: offset ? record.time + offset : record.time,
      value: customLogic ? customLogicRunner(customLogic, { value }) : value,
    }
  })
}

const getOffsetsForGhgDatapoint = (
  { offsets, id, caption, name, ...datapoint },
  datapointsData,
  startTime,
  locale
) => {
  return offsets.map(({ value, unit, ...offset }) => {
    const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
    const data = datapointsData.find((data) => data.id === `${id}:${offsetStartTime}`)
    return {
      ...datapoint,
      ...data,
      ...offset,
      offset: startTime - offsetStartTime,
      caption: `${caption || name} - ${value} ${translateHistoryLabel(value, unit, locale)}`,
    }
  })
}

const getEmissionGroupData = ({
  groupEmissions,
  emissions,
  targetData,
  targetDetails,
  datapoint,
  group,
}) => {
  return emissions.reduce((emissions, { type, history, forecast, ...emission }) => {
    if (history.length > 0) {
      let forecastHistory
      let trendStart
      if (targetDetails) {
        trendStart = dayjs(history?.[history.length - 1]?.time)
          .add(targetDetails.granularityValue, targetDetails.granularityUnit)
          .valueOf()

        forecastHistory = getTargetHistory({
          ...targetDetails,
          trendStart,
          targetStartValue: forecast?.[0]?.value || 0,
          targetEndValue: forecast?.[1]?.value || 0,
          targetPercentage: null,
          offset: false,
        })
      }
      emissions[type] = emissions[type]
        ? {
            ...emissions[type],
            history: [
              ...emissions[type].history,
              [...history, ...(targetData ? forecastHistory : [])],
            ],
          }
        : {
            ...emission,
            history: [[...history, ...(targetData ? forecastHistory : [])]],
            zoneAxis: 'x',
            ...(trendStart && {
              zones: [
                {
                  value: trendStart,
                },
                { dashStyle: 'dot' },
              ],
            }),
          }

      if (targetData) {
        const { emissions: targetEmissions } =
          targetData.find((data) => data.id === datapoint.id) || {}
        const targetEmission = targetEmissions.find(({ type: targetType }) => targetType === type)
        const targetStartValue = targetEmission?.history?.[0]?.value || 0
        const targetHistory = getTargetHistory({
          targetStartValue,
          ...targetDetails,
          targetPercentage: group.targetPercentage || targetDetails.targetPercentage,
          targetEndValue: group.targetValue || targetDetails.targetEndValue,
        })

        emissions[`${type}Target`] = emissions[`${type}Target`]
          ? {
              ...emissions[`${type}Target`],
              history: [...emissions[`${type}Target`].history, targetHistory],
            }
          : {
              ...emission,
              history: [targetHistory],
              dashStyle: 'Dash',
              opacity: 0,
              color: transparentize(0.5, group.color),
              lineWidth: 1,
            }
      }
    }
    return emissions
  }, groupEmissions)
}

export const getSeriesFromGhgData = ({
  datapoints,
  datapointsGroups = [],
  datapointsData,
  timeRange,
  chartStyle = 'area',
  graphOpacity,
  showInLegend,
  stacking,
  constants = [],
  targetData,
  targetDetails,
  intl,
}) => {
  const { startTime } = timeRange || {}
  const zones = getZones(constants)

  let grouped
  if (datapointsGroups.length) {
    grouped = datapointsGroups.reduce(
      (groups, group, index) => {
        const groupData = group.datapoints.reduce(
          (groupData, datapoint) => {
            const { emissions = [], returnUnit, ...dp } =
              datapointsData.find((dp) => dp.id === datapoint && dp.groupId === group.id) || {}

            groups.groupedDatapoints.push(datapoint)
            groupData.emissions = getEmissionGroupData({
              groupEmissions: groupData.emissions,
              emissions,
              targetData,
              targetDetails,
              datapoint: dp,
              group,
            })
            groupData.returnUnit = returnUnit
            return groupData
          },
          { emissions: {} }
        )

        groups.groups.push({ ...group, ...groupData })
        if (group.offsets) {
          group.offsets.forEach(({ value, unit, ...offset }, index) => {
            const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
            const historicalData = group.datapoints.reduce(
              (historicalData, datapoint) => {
                const { emissions = [], returnUnit } =
                  datapointsData.find(
                    ({ id }) => id === `offset${index}-${datapoint}:${offsetStartTime}`
                  ) || {}

                groups.groupedDatapoints.push(`offset${index}-${datapoint}:${offsetStartTime}`)
                historicalData.emissions = getEmissionGroupData({
                  groupEmissions: historicalData.emissions,
                  emissions,
                  targetData,
                  targetDetails,
                })
                historicalData.returnUnit = returnUnit
                return historicalData
              },
              {
                ...offset,
                emissions: {},
                offset: startTime - offsetStartTime,
                startTime: startTime,
              }
            )

            groups.groups.push({
              ...group,
              ...historicalData,
              caption: `${group.name} - ${value} ${translateHistoryLabel(
                value,
                unit,
                intl.locale
              )}`,
            })
          }, [])
        }

        if (index === datapointsGroups.length - 1) {
          datapoints.forEach(({ offsets, groupId, ...datapoint }) => {
            if (!groupId) {
              const data = datapointsData.find(({ id }) => id === datapoint.id)
              groups.remainingDatapoints.push({
                ...datapoint,
                ...data,
              })
              if (offsets?.length > 0) {
                groups.remainingDatapoints = groups.remainingDatapoints.concat(
                  getOffsetsForGhgDatapoint(
                    { ...datapoint, offsets },
                    datapointsData,
                    startTime,
                    intl.locale
                  )
                )
              }
            }
          })
        }
        return groups
      },
      { groups: [], groupedDatapoints: [], remainingDatapoints: [] }
    )
  } else {
    grouped = {
      groups: [],
      remainingDatapoints: mergeGhgHistoryData({
        datapoints,
        datapointsData,
        timeRange,
        startTime,
        targetData,
        targetDetails,
        intl,
      }),
    }
  }
  const { groups, remainingDatapoints } = grouped

  const groupedData = groups.map(({ emissions, ...group }) => ({
    ...group,
    emissions: Object.entries(emissions).map(
      ([type, { history, zones, zoneAxis, color, opacity, dashStyle, lineWidth }]) => {
        return {
          type,
          history: history[0].map((hist, index) => ({
            ...hist,
            value: CALCULATIONS[group.method || 'sum'](
              history.reduce(
                (group, h) => (h[index]?.value ? group.concat(h[index]?.value) : group),
                []
              ),
              0
            ),
          })),
          zones,
          zoneAxis,
          color,
          opacity,
          dashStyle,
          lineWidth,
        }
      }
    ),
  }))

  const combinedData = [...groupedData, ...remainingDatapoints]

  return combinedData.reduce(
    (output, datapoint, index) => {
      const {
        useSteps,
        line,
        lineWidth,
        color,
        opacity,
        dashStyle,
        unitObject,
        customLogic,
        emissions = [],
        name,
        caption,
        offset,
        returnUnit,
        constants: datapointConstants,
      } = datapoint

      emissions.forEach(
        ({
          history,
          dashStyle: emissionDashStyle,
          opacity: emissionOpacity,
          color: emissionColor,
          zones: dpZones,
          zoneAxis,
          type: emissionType,
          lineWidth: emissionLineWidth,
        }) => {
          const processedHistory = getConversionHistory(
            { ...unitObject, customLogic, offset },
            history
          )

          const { series } = historyToHighchartsSeries(processedHistory)
          const isTarget = emissionType.includes('Target')
          const { label } =
            emissionFactors.find(({ value }) => value === emissionType.replace('Target', '')) || {}
          const datapointUnit = returnUnit?.name || 'kgCO2e'

          const unitIndex = output.yAxisUnits.findIndex((yAxisUnit) => yAxisUnit === datapointUnit)
          if (unitIndex === -1) {
            output.yAxisUnits.push(datapointUnit)
          }
          let data = series

          const datapointZones =
            dpZones ||
            (datapointConstants?.length
              ? getZones(
                  [
                    ...(datapointConstants
                      ? datapointConstants.map((c) => ({ ...c, apply: true }))
                      : []),
                    ...constants.filter(
                      ({ type, value }) =>
                        !datapointConstants?.some((c) => c.type === type && c.value === value)
                    ),
                  ],
                  color
                )
              : zones)

          output.series.push({
            name: `${caption || name} - ${label} ${isTarget ? ' Target' : ''}`,
            data,
            type: line ? 'line' : chartStyle,
            color: emissionColor || color,
            yAxis: unitIndex === -1 ? output.yAxisUnits.length - 1 : unitIndex,
            zIndex: line ? 1 : 0,
            step: useSteps ? 'left' : undefined,
            fillOpacity:
              emissionOpacity ||
              (chartStyle === 'area' && !line ? opacity || graphOpacity : undefined),
            ...(lineWidth || (emissionLineWidth && { lineWidth: emissionLineWidth || lineWidth })),
            dashStyle:
              emissionDashStyle ||
              (HIGHCHARTS_DASH_STYLES.includes(dashStyle) ? dashStyle : 'Solid'),
            showInLegend,
            zones: datapointZones?.map((zone) => ({
              ...zone,
              color: zone.color === 'color' ? color : zone.color,
            })),
            zoneAxis,
          })
        }
      )

      if (constants.length > 0 && index === combinedData.length - 1) {
        const { minValue, maxValue } = calculateMinMax(output.series, stacking)
        output.minValue = minValue > 0 ? 0 : minValue
        output.maxValue = maxValue
      }

      return output
    },
    { series: [], maxValue: null, minValue: 0, yAxisUnits: [] }
  )
}

export const getPlotLines = ({ constants, yAxisUnit, i, maxValue, minValue }) => {
  return constants.reduce(
    (output, { value, label, color, dashStyle, unit, type }) => {
      if (type === 'fixedValue' && typeof value === 'number' && !isNaN(value)) {
        output.plotLines.push({
          value,
          color,
          width: 2,
          dashStyle: dashStyle || 'Solid',
          label: label || {
            text: `${value} ${unit || yAxisUnit}`,
            align: i % 2 === 1 ? 'right' : 'left',
          },
        })
        output.max = output.max > value ? output.max : value
        output.min = output.min < value ? output.min : value
      }
      return output
    },
    { plotLines: [], max: maxValue, min: minValue }
  )
}

export const getHistoricalChartProps = (
  {
    yAxisUnits,
    constants,
    captions,
    minValue,
    maxValue,
    hideUnit,
    theme,
    granularityUnit,
    granularityValue,
  },
  localizeNumber
) => {
  return {
    yAxis: Object.keys(yAxisUnits).map((unit, i) => ({
      title: {
        text: yAxisUnits[unit],
      },
      gridLineColor: i === 0 ? theme.color.softStroke : 'transparent',
      opposite: i % 2 === 1,
      ...getPlotLines({
        constants,
        rawUnit: unit,
        yAxisUnit: yAxisUnits[unit],
        i,
        maxValue,
        minValue,
      }),
    })),
    tooltip: {
      formatter: function () {
        const points = this.points

        if (!points) {
          return ''
        }
        return points.reduce((s, point) => {
          const yAxis = point.series.yAxis
          const unit = yAxis.axisTitle?.textStr
          const seriesName = point.series.name
          const offset = point.series.options.offset
          const range = typeof point.point.low === 'number'

          const value = range
            ? `${localizeNumber(
                captions ? captions[point.point.low].title : point.point.low
              )} - ${localizeNumber(
                captions ? captions[point.point.high].title : point.point.high
              )}`
            : localizeNumber(captions ? captions[point.y].title : point.y)
          return `${s}
            <span class="tooltip__name">
                <span style="background:${point.color}" class="tooltip__color"></span>
                    ${seriesName} - ${
            offset
              ? DATE_FORMAT_BY_UNIT(dayjs(this.x).valueOf() - offset, granularityUnit, true)
              : DATE_FORMAT_BY_UNIT(this.x, granularityUnit, true)
          }
            </span>
            <span class="tooltip__value ${range ? 'range' : ''}">${value}
                <span class="tooltip__unit">${hideUnit ? '' : unit}</span>
            </span>`
        }, getTooltipDate({ time: this.x, granularityUnit, granularityValue, color: theme.color.softText }))
      },
    },
  }
}

const mergeGhgHistoryData = ({
  datapoints,
  datapointsData,
  timeRange,
  targetData,
  targetDetails,
  startTime,
  intl,
}) => {
  return datapoints.reduce(
    (remainingDatapoints, { id, offsets, targetPercentage, targetValue, ...datapoint }) => {
      const data = datapointsData.find(
        (data) => data.id === id && (timeRange ? timeRange.startTime === data.startTime : true)
      )
      if (data) {
        remainingDatapoints.push({
          ...datapoint,
          ...data,
          emissions: data.emissions.map(({ history, forecast, ...emission }) => {
            const trendStart =
              targetDetails &&
              dayjs(history?.[history.length - 1]?.time)
                .add(targetDetails.granularityValue, targetDetails.granularityUnit)
                .valueOf()
            return {
              ...emission,
              history: [
                ...history,
                ...(targetData
                  ? getTargetHistory({
                      ...targetDetails,
                      trendStart,
                      targetStartValue: forecast?.[0]?.value || 0,
                      targetEndValue: forecast?.[1]?.value || 0,
                      targetPercentage: null,
                      offset: false,
                    })
                  : []),
              ],
              ...(trendStart && {
                zoneAxis: 'x',
                zones: [
                  {
                    value: trendStart,
                  },
                  { dashStyle: 'dot' },
                ],
              }),
            }
          }),
        })
      }
      if (offsets?.length > 0) {
        remainingDatapoints = remainingDatapoints.concat(
          getOffsetsForGhgDatapoint(
            { ...datapoint, id, offsets },
            datapointsData,
            startTime,
            intl.locale
          )
        )
      }
      if (targetData) {
        const { unit, rawUnit, emissions } = targetData.find((data) => data.id === id) || {}

        const targetEmissions = emissions.map(({ history, ...emission }) => {
          const targetStartValue = history?.[0]?.value || 0
          const targetHistory = getTargetHistory({
            targetStartValue,
            ...targetDetails,
            targetPercentage: targetPercentage || targetDetails.targetPercentage,
            targetEndValue: targetValue || targetDetails.targetEndValue,
          })
          return {
            ...emission,
            history: targetHistory,
          }
        })

        remainingDatapoints.push({
          caption: `${datapoint.caption || datapoint.name} - Target`,
          id: `${id}-target`,
          dashStyle: 'Dash',
          opacity: 0,
          color: transparentize(0.5, datapoint.color),
          lineWidth: 1,
          emissions: targetEmissions,
          unit,
          rawUnit,
        })
      }
      return remainingDatapoints
    },
    []
  )
}

export const getGhgLineChartProps = (
  { yAxisUnits, constants, captions, minValue, maxValue, hideUnit, theme },
  localizeNumber
) => {
  return {
    yAxis: yAxisUnits.map((unit, i) => ({
      title: {
        text: getEmissionUnit(unit) || unit,
      },
      gridLineColor: i === 0 ? theme.color.softStroke : 'transparent',
      opposite: i % 2 === 1,
      ...getPlotLines({
        constants,
        yAxisUnit: getEmissionUnit(unit) || unit,
        formattedUnit: getEmissionUnit(unit) || unit,
        i,
        ...(constants.length > 0 && { minValue, maxValue }),
      }),
    })),
    tooltip: {
      formatter: function () {
        const points = this.points

        if (!points) {
          return ''
        }
        return points.reduce((s, point) => {
          const yAxis = point.series.yAxis
          const unit = yAxis.axisTitle?.textStr || ''
          const value = round(point.y, 2)
          const seriesName = point.series.name

          return `${s}
            <span class="tooltip__name">
                <span style="background:${point.color}" class="tooltip__color"></span>
                ${seriesName}
            </span>
            <span class="tooltip__value">
                ${localizeNumber(captions ? captions[value].title : value)}
                <span class="tooltip__unit">${hideUnit ? '' : unit}</span>
            </span>`
        }, `<span class="tooltip__date">${DEFAULT_TIMESTAMP_FORMATTER(this.x)}</span>`)
      },
    },
  }
}
