import React from 'react'

import PropTypes from 'prop-types'

import ContentFlex from './ContentFlex'
import { IconContainer, Title } from './styled'

const ContentHeader = ({ title, titleComp, icon, children, buttons, hideButtons = false }) => {
  const hasTitleContent = icon || title || titleComp
  return (
    <>
      {hasTitleContent && (
        <div className="ContentHeader__title-container">
          {icon && <IconContainer>{icon}</IconContainer>}
          {titleComp || <Title>{title}</Title>}
          {children}
        </div>
      )}
      {!hideButtons && <ContentFlex padding={false}>{buttons}</ContentFlex>}
    </>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string,
  titleComp: PropTypes.element,
  hideButtons: PropTypes.bool,
  buttons: PropTypes.element,
}

export default ContentHeader
