import React, { useEffect, useState } from 'react'

import InputStyled from './styled'

const Input = ({ value, onChange, onBlur, type, ...rest }) => {
  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const _onChange = (e) => {
    setInputValue(e.target.value)
    onChange && onChange(e)
  }
  const _onBlur = (e) => {
    setInputValue(e.target.value)
    onBlur && onBlur(e)
  }

  return (
    <InputStyled
      className="Input"
      value={inputValue}
      onBlur={_onBlur}
      onChange={_onChange}
      type={type}
      {...rest}
    />
  )
}

export default Input
