import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const MainMenuStyled = styled.div`
  flex: 1;
  display: flex;

  .MainMenu__sub {
    display: flex;
    overflow: auto;
    flex: 1;
  }

  ${({ theme }) =>
    theme.screenSize.screen.current === 's'
      ? css`
          justify-content: space-around;
          align-items: flex-end;
          width: 100%;

          .MainMenu__sub {
            align-items: flex-start;
            margin: 0 0 -0.5rem 1rem;
            padding: 0 0 0.4rem;
          }
        `
      : css`
          flex-direction: column;
          align-items: center;
          overflow: hidden;

          .MainMenu__sub {
            flex-direction: column;
            overflow: auto;

            ::-webkit-scrollbar {
              width: 5px;
            }

            &:hover {
              ::-webkit-scrollbar-thumb {
                background: ${({ theme }) => transparentize(0.6, theme.color.mainTextColor)};

                &:hover {
                  background: ${({ theme }) => transparentize(0.3, theme.color.mainTextColor)};
                }
              }
            }

            ::-webkit-scrollbar-track-piece {
              background: transparent !important;
            }

            ::-webkit-scrollbar-track {
              background: transparent !important;
            }

            ::-webkit-scrollbar-thumb {
              border: none;
              background: transparent;
              width: 5px;
            }
          }
        `}
`
export default MainMenuStyled
