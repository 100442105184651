import React, { useCallback, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import treeifyHiearchyList from 'util/treeifyHiearchyList'
import { useEntityDatapoints, useEntityTreeDataForCompany } from 'services/entities'
import { useCurrentCustomer } from 'services/store'

import CascaderField from '../CascaderField'

const DatapointCascaderField = ({
  name,
  endType = null,
  disabledTypes,
  savePath = false,
  onChange,
  ...props
}) => {
  const { setValue } = useFormContext() || {}
  const [valuePath, setValuePath] = useState([])
  const [popupVisible, setPopupVisible] = useState('reset')
  const { data } = useEntityTreeDataForCompany()
  const [getDatapointsForEntity, { data: entityDatapoints }] = useEntityDatapoints()
  const options = useMemo(
    () =>
      data
        ? treeifyHiearchyList({
            list: data,
            idKey: 'id',
            depthType: null,
            disabledTypes: ['room'],
          })[0].children
        : [],
    [data]
  )
  const customer = useCurrentCustomer()

  const datapoints = entityDatapoints?.datapoints
    .filter(({ equipment }) => equipment.id === valuePath[valuePath.length - 1])
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))

  const treeWalk = useCallback(
    (tree, level = 0, path) => {
      return {
        ...tree,
        children: tree.children?.map((child) => {
          if (child.value === path[level]) {
            if (level === path.length - 1) {
              return {
                ...child,
                children: datapoints,
              }
            }
            if (!child.children) {
              return child
            }
            return treeWalk(child, level + 1, path)
          }
          return child
        }),
      }
    },
    [datapoints]
  )

  const optionsWithDatapoints = useMemo(() => {
    if (!datapoints?.length) {
      return options
    }
    return options.map((option) => treeWalk(option, 1, valuePath))
  }, [datapoints, options, valuePath, treeWalk])

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  const displayRender = (label) => {
    return label[label.length - 1]
  }

  const handleChange = (values) => {
    const value = values[values.length - 1]
    if (values.length === 5) {
      getDatapointsForEntity({
        variables: {
          id: value,
          companyId: customer?.id,
        },
      })
      setValue(name, value)
      setValuePath(values)
      return setPopupVisible('open')
    }
    setValue(name, value)
    setValuePath(values.slice(0, values.length - 1))
    setPopupVisible('closed')
    if (onChange) {
      onChange(value)
    }
    return setTimeout(() => setPopupVisible('reset'), 500)
  }

  const cascaderProps = {
    ...props,
    ...(popupVisible === 'open'
      ? {
          popupVisible: true,
        }
      : popupVisible === 'closed' && {
          popupVisible: false,
        }),
  }

  return (
    <CascaderField
      {...cascaderProps}
      name={name ? `meta.${name}` : undefined}
      onChange={handleChange}
      options={optionsWithDatapoints}
      showSearch={{ filter, matchInputWidth: false }}
      displayRender={displayRender}
    />
  )
}

DatapointCascaderField.propTypes = {
  savePath: PropTypes.bool,
  endType: PropTypes.string,
  disabledTypes: PropTypes.arrayOf(PropTypes.string),
}

DatapointCascaderField.defaultProps = {}

export default DatapointCascaderField
