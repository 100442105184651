import React from 'react'

import DropdownValue from '../DropdownValue'
import DropdownOptionsStyled from './styled'

const DropdownOptions = ({ options, componentName, componentProps, styleProps, onChange }) => {
  return (
    <DropdownOptionsStyled className="DropdownOptions">
      {options.map((option, index) => (
        <DropdownValue
          key={`option${index}`}
          componentName={componentName}
          componentProps={componentProps}
          styleProps={styleProps}
          value={option}
          onChange={onChange}
        />
      ))}
    </DropdownOptionsStyled>
  )
}

DropdownOptions.propTypes = {}

DropdownOptions.defaultProps = {}

export default DropdownOptions
