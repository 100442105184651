import { transparentize } from 'polished'
import { css } from 'styled-components'

export const IconCellDropdown = css`
  background: ${({ theme }) => theme.color.backgroundFront};
  box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
  min-height: unset;
  font-size: 0.7rem;
  text-transform: uppercase;
  border-radius: 3px;
  overflow: hidden;

  p {
    transition: background 0.2s ease;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0;
    font-size: 0.9rem;

    &:hover {
      background: ${({ theme }) => transparentize(0.9, theme.color.main)};
    }
  }

  &.iconCellMore {
    > span {
      font-size: 0.8rem;
      display: flex;
      padding: 1rem;
      font-weight: 100;
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.color.action};
      }

      em {
        margin-left: 1em;
        text-transform: initial;
      }
    }

    .Icon {
      font-size: 0.9rem;
      margin-right: 0.5rem;
    }
  }
`
