import React from 'react'

import dayjs from 'dayjs'

import Icon from 'components/Global/Icon'

// Map component constants
export const MAX_WORLD_BOUNDS = [
  [-90, -180],
  [90, 180],
]

const removeNonNumnbers = (arr) => arr.filter((number) => typeof number === 'number')

export const CALCULATIONS = {
  sum: (arr, nullValue = null) =>
    arr.length === 0 ? nullValue : removeNonNumnbers(arr).reduce((a, b) => (a || 0) + (b || 0), 0),
  addition: (arr, nullValue = null) =>
    arr.length === 0 ? nullValue : removeNonNumnbers(arr).reduce((a, b) => (a || 0) + (b || 0), 0),
  subtraction: (arr, nullValue = null) =>
    arr.length === 0
      ? nullValue
      : removeNonNumnbers(arr).reduce((a, b, index) => (index === 0 ? b || 0 : a || 0 - b || 0), 0),
  multiply: (arr, nullValue = null) =>
    arr.length === 0 ? nullValue : removeNonNumnbers(arr).reduce((a, b) => a * b, 1),
  divide: (arr, nullValue = null) =>
    arr.length === 0
      ? nullValue
      : removeNonNumnbers(arr).reduce((a, b, index) => (index === 0 ? b || 1 : a / b), 1),
  mean: (arr, nullValue = null) =>
    arr.length === 0 ? nullValue : removeNonNumnbers(arr).reduce((a, b) => a + b, 0) / arr.length,
  min: (arr) => Math.min(...removeNonNumnbers(arr)),
  max: (arr) => Math.max(...removeNonNumnbers(arr)),
  amount: (arr) => arr.length,
  percentage: (arr) => (arr[0] / 100) * arr[1],
  percentageDiff: (arr) => {
    const diff = arr[0] - arr[1]
    return (diff * 100) / arr[1]
  },
  none: (arr) => arr[0],
}

export const TIME_AMOUNT_BY_UNIT = (time, unit) => {
  switch (unit) {
    case 'month':
      return dayjs(time).month()
    case 'day':
      return dayjs(time).date()
    case 'hour':
      return dayjs(time).hour()
    case 'minute':
      return dayjs(time).minute()
    default:
  }
}

export const DATE_FORMAT_BY_UNIT = (time, unit, formatted) => {
  switch (unit) {
    case 'month':
    case 'MS':
      const monthFormatted = dayjs(time).format('YYYY-MM-DD')
      return formatted
        ? dayjs(monthFormatted).format(DATE_NAME_FORMAT)
        : dayjs(monthFormatted).valueOf()
    case 'day':
    case 'week':
    case 'w':
    case 'd':
      const dayFormatted = dayjs(time).format('YYYY-MM-DD')
      return formatted
        ? dayjs(dayFormatted).format(DATE_NAME_FORMAT)
        : dayjs(dayFormatted).valueOf()
    case 'hour':
    case 'h':
      const hourFormatted = dayjs(time).format('YYYY-MM-DD[T]HH[:00]')
      return formatted
        ? dayjs(hourFormatted).format(DATETIME_FORMAT_HOUR)
        : dayjs(hourFormatted).valueOf()
    case 'minute':
    case 'm':
      const minuteFormatted = dayjs(time).format('YYYY-MM-DD[T]HH:mm[:00]')
      return formatted
        ? dayjs(minuteFormatted).format(DATETIME_FORMAT_MINUTE)
        : dayjs(minuteFormatted).valueOf()
    default:
  }
}

export const TIME_FORMAT_BY_UNIT = (time, unit, formatted, format) => {
  switch (unit) {
    case 'month':
    case 'MS':
      const monthFormatted = dayjs(time).format('YYYY-MM-DD')
      return formatted
        ? dayjs(monthFormatted).format(format ? DATE_FORMATS[format] : DATE_NAME_FORMAT)
        : dayjs(monthFormatted).valueOf()
    case 'day':
    case 'week':
    case 'w':
    case 'd':
      const dayFormatted = dayjs(time).format('YYYY-MM-DD')
      return formatted
        ? dayjs(dayFormatted).format(format ? DATE_FORMATS[format] : DATE_NAME_FORMAT)
        : dayjs(dayFormatted).valueOf()
    case 'hour':
    case 'h':
      const hourFormatted = dayjs(time).format('YYYY-MM-DD[T]HH[:00]')
      return formatted
        ? dayjs(hourFormatted).format(format ? DATE_FORMATS[format] : HOUR_FORMAT)
        : dayjs(hourFormatted).valueOf()
    case 'minute':
    case 'm':
      const minuteFormatted = dayjs(time).format('YYYY-MM-DD[T]HH:mm[:00]')
      return formatted
        ? dayjs(minuteFormatted).format(format ? DATE_FORMATS[format] : DATETIME_FORMAT_MINUTE)
        : dayjs(minuteFormatted).valueOf()
    default:
  }
}

// Default formatting for date time strings
export const DATETIME_FORMAT_DATE = 'L'
export const DATETIME_FORMAT_HOUR = 'L HH:00'
export const DATETIME_FORMAT_MINUTE = 'L HH:mm'
export const DATETIME_FORMAT = 'L HH:mm:ss'
export const DATETIME_DETAIL_FORMAT = 'll LTS'
export const DATETIME_NAME_FORMAT = 'lll'
export const DATE_NAME_FORMAT = 'll'
export const DAY_NAME_FORMAT = 'dddd'
export const HOUR_FORMAT = 'HH:mm'
export const MONTH_FORMAT = 'MMM YYYY'

export const DATE_FORMATS = {
  DATETIME_FORMAT_DATE,
  DATETIME_FORMAT_HOUR,
  DATETIME_FORMAT_MINUTE,
  DATETIME_FORMAT,
  DATETIME_DETAIL_FORMAT,
  DATETIME_NAME_FORMAT,
  DATE_NAME_FORMAT,
  DAY_NAME_FORMAT,
  HOUR_FORMAT,
  MONTH_FORMAT,
}

// Map marker constants
export const MIN_WIDTH_MARKER_SIZE = 25
export const MAX_WIDTH_MARKER_SIZE = MIN_WIDTH_MARKER_SIZE * 2
export const MIN_HEIGHT_MARKER_SIZE = 40
export const MAX_HEIGHT_MARKER_SIZE = MIN_HEIGHT_MARKER_SIZE * 2
export const MARKER_SIZE_CURVE = 0.035
export const MULTIPLE_MARKER_ZOOM_PADDING = 0
export const SINGLE_MARKER_ZOOM_PADDING = 0

// Time constants
export const MILLISECONDS_IN_SECOND = 1000
export const SECONDS_IN_MINUTE = 60
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE
export const MINUTES_IN_HOUR = 60
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTE
export const HOURS_IN_DAY = 24
export const MILLISECONDS_IN_DAY = HOURS_IN_DAY * MILLISECONDS_IN_HOUR

//Selection parameter constants
export const RULE_SELECTION_PARAM = 'id'

// Epoch in ms for Monday January 5th 1970
export const FIRST_MONDAY_AFTER_EPOCH = MILLISECONDS_IN_DAY * 4

export const CANARY_SUCCESS_COLOR = '#46BE8A'
export const CANARY_WARNING_COLOR = '#F39834'
export const CANARY_DANGER_COLOR = '#FB434A'
export const CANARY_LIGHT_ORANGE_COLOR = '#FDBE10'

export const CANARY_ORANGE = '#F9A11B'

export const CANARY_COLOR_0 = '#42A5F5'
export const CANARY_COLOR_1 = '#29B6F6'
export const CANARY_COLOR_2 = '#26C6DA'
export const CANARY_COLOR_3 = '#66BB6A'
export const CANARY_COLOR_4 = '#9CCC65'
export const CANARY_COLOR_5 = '#D4E157'
export const CANARY_COLOR_6 = '#FFCA28'
export const CANARY_COLOR_7 = '#FFA726'
export const CANARY_COLOR_8 = '#FF7043'
export const CANARY_COLOR_9 = '#EF5350'
export const CANARY_COLOR_10 = '#EC407A'
export const CANARY_COLOR_11 = '#AB47BC'
export const CANARY_COLOR_12 = '#7E57C2'
export const CANARY_COLOR_13 = '#5C6BC0'
export const CANARY_COLOR_14 = '#8D6E63'
export const CANARY_COLOR_15 = '#BDBDBD'
export const CANARY_COLOR_16 = '#4E4E4E'

export const COLORS = [
  CANARY_COLOR_0,
  CANARY_COLOR_1,
  CANARY_COLOR_2,
  CANARY_COLOR_3,
  CANARY_COLOR_4,
  CANARY_COLOR_5,
  CANARY_COLOR_6,
  CANARY_COLOR_7,
  CANARY_COLOR_8,
  CANARY_COLOR_9,
  CANARY_COLOR_10,
  CANARY_COLOR_11,
  CANARY_COLOR_12,
  CANARY_COLOR_13,
  CANARY_COLOR_14,
  CANARY_COLOR_15,
  CANARY_COLOR_16,
]

export const HIGHCHARTS_DEFAULT_COLOR_SCHEME = [
  CANARY_COLOR_1,
  CANARY_COLOR_3,
  CANARY_COLOR_5,
  CANARY_COLOR_7,
  CANARY_COLOR_9,
  CANARY_COLOR_11,
  CANARY_COLOR_13,
  CANARY_COLOR_14,
  CANARY_COLOR_15,
  CANARY_COLOR_2,
  CANARY_COLOR_4,
  CANARY_COLOR_6,
  CANARY_COLOR_8,
  CANARY_COLOR_10,
  CANARY_COLOR_12,
]

export const REF_ORDER = [
  'customerRef',
  'siteRef',
  'buildingRef',
  'floorRef',
  'spaceRef',
  'roomRef',
  'zoneRef',
  'hvac-zone-spaceRef',
  'equipRef',
]

export const TYPE_ORDER = ['customer', 'site', 'building', 'floor', 'room', 'equip', 'point']

export const ICON_MAPPING = {
  /* customer: <i className="anticon fas fa-address-card" />,
  site: <i className="anticon fas fa-map-marked" />,
  building: <i className="anticon fas fa-warehouse" />,
  floor: <i className="anticon fas fa-layer-group" />,
  room: <i className="anticon fas fa-cube" />,
  equip: <i className="anticon fas fa-gears" />,
  point: <i className="anticon fas fa-dot-circle" />, */
  customer: <Icon className="anticon" icon="fas fa-address-card" />,
  site: <Icon className="anticon" icon="fa-map-marked" />,
  building: <Icon className="anticon" icon="fas fa-warehouse" />,
  floor: <Icon className="anticon" icon="fas fa-layer-group" />,
  room: <Icon className="anticon" icon="fas fa-cube" />,
  equip: <Icon className="anticon" icon="fas fa-gears" />,
  point: <Icon className="anticon" icon="fas fa-circle-dot" />,
}

export const ICON_STRING_MAPPING = {
  customer: 'fas fa-address-card',
  site: 'fas fa-map-location-dot',
  building: 'fas fa-warehouse',
  floor: 'fas fa-layer-group',
  room: 'fas fa-cube',
  equip: 'fas fa-gears',
  point: 'fas fa-circle-dot',
}

export const HIGHCHARTS_DASH_STYLES = [
  'Solid',
  'ShortDash',
  'ShortDot',
  'ShortDashDot',
  'ShortDashDotDot',
  'Dot',
  'Dash',
  'LongDash',
  'DashDot',
  'LongDashDot',
  'LongDashDotDot',
]

export const VIRTUAL_ENTITY_TAG_NAME = 'virtual'

export const TRANSLATED_TIME_UNITS = {
  year: {
    'en-US': 'year',
    'fr-FR': 'an',
    'nl-NL': 'jaar',
  },
  years: {
    'en-US': 'years',
    'fr-FR': 'ans',
    'nl-NL': 'jaar',
  },
  month: {
    'en-US': 'month',
    'fr-FR': 'mois',
    'nl-NL': 'maand',
  },
  months: {
    'en-US': 'months',
    'fr-FR': 'mois',
    'nl-NL': 'maanden',
  },
  week: {
    'en-US': 'week',
    'fr-FR': 'semaine',
    'nl-NL': 'week',
  },
  weeks: {
    'en-US': 'weeks',
    'fr-FR': 'semaines',
    'nl-NL': 'weken',
  },
  day: {
    'en-US': 'day',
    'fr-FR': 'jour',
    'nl-NL': 'dag',
  },
  days: {
    'en-US': 'days',
    'fr-FR': 'jours',
    'nl-NL': 'dagen',
  },
  hour: {
    'en-US': 'hour',
    'fr-FR': 'heure',
    'nl-NL': 'uur',
  },
  hours: {
    'en-US': 'hours',
    'fr-FR': 'heures',
    'nl-NL': 'uren',
  },
}
