import React, { useRef, useState } from 'react'

import { get } from 'lodash'
import set from 'lodash/fp/set'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import uniqid from 'uniqid'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { EditableTree } from '../../EditableTree/EditableTree'
import Icon from '../../Icon'
import { TableRow } from '../../Table/styled'
import FormField from '../index'
import LinkPicker from '../LinkPicker'
import LinkSelector from '../LinkSelector'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import { FormFieldLabel } from '../styled'
import NavigationTypeSelectorStyled from './styled'

const navigationTypes = [
  {
    value: 'single',
    label: {
      formatted: 'widget.singleLink',
    },
  },
  {
    value: 'multi',
    label: {
      formatted: 'widget.multipleLinks',
    },
  },
]

const NavigationTypeSelector = ({ onChange, dataCy = 'NavigationTypeSelector', value, intl }) => {
  const [navigationType, setNavigationType] = useState(value.links ? 'multi' : 'single')
  const theme = useTheme()
  const { action } = useDashboard()
  const editing = useRef(null)

  const handleEditTree = (value) => {
    onChange(value, 'links')
  }

  const handleEditTreeItem = ({ value: update, dataKey, path }) => {
    if (dataKey === 'url') {
      editing.current = path
      return onOpenBottomDrawer(path)
    }
    onChange(set(`${path}.${dataKey}`, update, { root: value.links }).root, 'links')
  }

  const handleSelectNavigationType = (e, { value: type }) => {
    setNavigationType((current) => {
      if (current !== type) {
        if (type === 'multi') {
          const { links, type: widgetType, fontColor, size, centeredText, ...single } = value
          onChange([{ ...single, id: uniqid(), children: [], color: theme.color.main }], 'links')
        } else {
          onChange(null, 'links')
        }
      }
      return type
    })
  }

  const onOpenBottomDrawer = (path) => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: true,
          width: 'full',
          component: LinkSelector,
          key: 'link-selector',
          componentProps: {
            intl,
            onSave: onBottomDrawerSave,
            path,
          },
        },
      },
    })
  }

  const setProperties = ({ children, id, title, name, url, icon, color, key }, level = 0) => {
    return {
      title,
      name: level > 0 ? title : name,
      url: url || `/dashboard/${key}`,
      icon,
      color,
      id: id.toString(),
      ...(children && { children: children.map((child) => setProperties(child, level + 1)) }),
    }
  }

  const onBottomDrawerSave = (payload) => {
    const { raw } = payload || {}
    if (raw) {
      const currentItem = get({ root: value.links }, editing.current)
      const newItem = setProperties({
        ...currentItem,
        title: raw.title,
        name: raw.name,
        url: raw.url,
        icon: raw.icon,
        color: raw.color,
        children: raw.children?.length ? raw.children : currentItem.children,
      })

      onChange(set(`${editing.current}`, newItem, { root: value.links }).root, 'links')
    }
  }

  return (
    <NavigationTypeSelectorStyled className="DatapointGroups">
      <CheckboxSelector
        options={navigationTypes}
        value={navigationType}
        onSelect={handleSelectNavigationType}
        dataCy={`${dataCy}-type-selector`}
      />
      {navigationType === 'single' ? (
        <>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.link' })}</FormFieldLabel>
          <LinkPicker onChange={onChange} value={value} />
        </>
      ) : (
        <>
          <FormField
            label={{ formatted: 'widget.defaultExpanded' }}
            instance={value.defaultExpanded}
            dataKey="defaultExpanded"
            onChange={({ value, dataKey }) => onChange(value, dataKey)}
            componentName="Switch"
          />
          <EditableTree
            collapsible
            enableCrud
            value={value.links || []}
            preventRootDelete
            onChange={handleEditTree}
            treeItemComponent={TableRow}
            treeItemComponentProps={{
              onChange: handleEditTreeItem,
              variant: 'Table',
              fields: [
                {
                  name: 'title',
                  dataKey: 'title',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                },
                {
                  name: 'url',
                  dataKey: 'url',
                  componentName: 'CaptionTooltip',
                  componentProps: {
                    children: <Icon icon={'fas fa-link'} />,
                    caption: (link) => ({ text: link.name || link.url }),
                    portal: false,
                    variant: 'EditableTree',
                    selectionKeys: { label: 'name' },
                  },
                  label: { formatted: 'widget.url' },
                },
                {
                  name: 'color',
                  dataKey: 'color',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                },
                {
                  name: 'icon',
                  dataKey: 'icon',
                  componentName: 'IconPicker',
                  label: { formatted: 'widget.icon' },
                },
              ],
            }}
            defaultValue={{
              title: 'New link',
              icon: 'fas fa-link',
              color: theme.color.main,
              url: 'https://brightcanary.eu',
              children: [],
            }}
            indentationWidth={30}
          />
        </>
      )}
    </NavigationTypeSelectorStyled>
  )
}

export default injectIntl(NavigationTypeSelector)
