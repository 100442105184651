import React, { useState } from 'react'

import { Tag } from 'antd'
import { Controller /* , useWatch */ } from 'react-hook-form'
import { useTheme } from 'styled-components'

// import { useEntityFeatureTypeDescription } from 'services/entities'
import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import CheckableTag from 'components/Form/components/CheckableTag/CheckableTag'
import TooltipContent from 'components/Global/Tooltip/TooltipContent'

import { MarkerFieldStyled } from './styled'

const MarkerField = ({
  value,
  defaultValue,
  name,
  parentIsEnabled,
  markMissing = false,
  softDeleteMode = true,
  itemIndex,
  onChange,
  ...fieldProps
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MarkerFieldStyled {...fieldProps}>
      {({ control, isEditMode, watch, getValues }) => {
        const required = getValues(`${name}.required`)
        const tagTooltip = watch(`${name}.tooltip`)
        return (
          <Controller
            control={control}
            name={`${name}.enabled`}
            defaultValue={defaultValue.enabled}
            render={({ onChange: controllerOnChange, value: controllerValue }) => {
              const handleOnChange = (value) => {
                if (softDeleteMode) {
                  return controllerOnChange(value)
                }
                if (typeof onChange === 'function') {
                  onChange(value, !softDeleteMode && 'delete', itemIndex)
                }
              }
              const getColor = (tagName) => {
                if (fieldValue) return theme.color.main || generateTagColor(tagName)
                if (required && markMissing) return theme.color.error
                return theme.color.main || generateTagColor(tagName)
              }
              const tag = watch(name)
              const fieldValue = value === undefined && name !== undefined ? controllerValue : value
              const color = getColor(tag?.name)

              if (isEditMode) {
                if (required && parentIsEnabled && !controllerValue) {
                  handleOnChange(true)
                }

                const disabled = required && fieldValue

                return (
                  <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    <CheckableTag
                      size="small"
                      className={disabled ? 'disabled' : fieldValue ? 'checked' : 'unchecked'}
                      type={fieldValue && 'primary'}
                      onChange={(value) => !disabled && handleOnChange(value)}
                      checked={fieldValue}
                      color={color}
                    >
                      {tag?.name}
                    </CheckableTag>
                    {tagTooltip && tagTooltip !== '' ? (
                      <TooltipContent variant="Datapoints" isOpen={isOpen} maxWidth="20rem">
                        <span>{tagTooltip}</span>
                      </TooltipContent>
                    ) : (
                      <></>
                    )}
                  </div>
                )
              }

              return (
                <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                  <Tag color={color}>{tag?.name}</Tag>
                  {tagTooltip && tagTooltip !== '' ? (
                    <TooltipContent variant="Datapoints" isOpen={isOpen} maxWidth="20rem">
                      <span>{tagTooltip}</span>
                    </TooltipContent>
                  ) : (
                    <></>
                  )}
                </div>
              )
            }}
          />
        )
      }}
    </MarkerFieldStyled>
  )
}

export default MarkerField
