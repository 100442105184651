import { configFieldConvertor } from '../../../Dashboard/utils/common/helpers'
import { config as graphCard } from './../GraphCard/config'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.trendLine',
    category: 'widget.charts',
    keywords: ['chart', 'trend'],
    img: '/resources/images/widgets/trendline.png',
    info: {
      'nl-NL':
        '<h2>Trendlijn</h2>\n' +
        '<p>De trendlijnwidget toont de prognose voor een bepaald datapunt (gebaseerd op werkelijke waarden) ten opzichte van een vooraf gedefinieerd doel.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groepen (LINK)</strong> Een bestaande groep datapunten toevoegen of selecteren</li>\n' +
        '<li><strong>Datapunten (LINK)</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Doel</h5>\n' +
        '<ul>\n' +
        '<li><strong>Doel einddatum of geleidelijke afname</strong> Selecteer een methode om het doel te definiëren: door vaste einddatum of door een geleidelijke afname</li>\n' +
        '<li><strong>Doelperiode</strong> Periode van het doel (begin- en eindtijd)</li>\n' +
        '<li><strong>Doelwaarde</strong> Waarde van het doel</li>\n' +
        '<li><strong>Percentage</strong> Drukt de doelwaarde uit als een percentage</li>\n' +
        '<li><strong>Verleng periode tot doeltijd</strong> Toont de doelperiode volledig op de widget (tot de einddatum) </li>\n' +
        '<li><strong>Startdatum doel</strong> Datum waarop het doel begint</li>\n' +
        '<li><strong>Verlagen</strong> Specificeert hoe het doel moet worden bereikt (in percentage per periode)</li>\n' +
        '</ul>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor het datapunt</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Doelwaarde</strong> Waarde van het doel</li>\n' +
        '<li><strong>Percentage</strong> Drukt de doelwaarde uit als een percentage</li>\n' +
        '<li><strong>Verleng periode tot doeltijd</strong> Toont de doelperiode volledig op de widget (tot de einddatum) </li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Ligne de tendance</h2>\n' +
        '<p>Le widget Ligne de tendance affiche les prévisions pour un certain point de données (basées sur des valeurs réelles) par rapport à une cible prédéfinie.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groupes (LINK)</strong> Ajouter ou sélectionner un groupe existant de points de données</li>\n' +
        '<li><strong>Points de données (LINK)</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Cible</h5>\n' +
        '<ul>\n' +
        "<li><strong>Date de fin cible ou diminution progressive</strong> Sélectionnez une méthode pour définir l'objectif : par date de fin fixe ou par diminution progressive</li>\n" +
        '<li><strong>Période cible</strong> Période de la cible (heure de début et de fin)</li>\n' +
        '<li><strong>Valeur cible</strong> Valeur de la cible</li>\n' +
        '<li><strong>Pourcentage</strong> Exprime la valeur cible sous forme de pourcentage</li>\n' +
        "<li><strong>Étendre la période à l'heure cible</strong> Affiche la période cible entièrement sur le widget (jusqu'à la date de fin) </li>\n" +
        '<li><strong>Date de début cible</strong> Date à laquelle la cible commence</li>\n' +
        "<li><strong>Diminuer</strong> Spécifie comment l'objectif doit être atteint (en pourcentage par période)</li>\n" +
        '</ul>\n' +
        '<h5>Plage de temps pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Étiquette</strong> Saisissez une étiquette pour le point de données</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le point de données</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Valeur cible</strong> Valeur de la cible</li>\n' +
        '<li><strong>Pourcentage</strong> Exprime la valeur cible sous forme de pourcentage</li>\n' +
        "<li><strong>Étendre la période à l'heure cible</strong> Affiche la période cible entièrement sur le widget (jusqu'à la date de fin) </li>\n" +
        '</ul>',
      'en-US':
        '<h2>Trend line</h2>\n' +
        '<p>The Trend line widget shows the forecast for a certain datapoint (based on actual values) against a predefined target.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groups (LINK)</strong> Add or select an existing group of datapoints</li>\n' +
        '<li><strong>Datapoints (LINK)</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Target</h5>\n' +
        '<ul>\n' +
        '<li><strong>Target End Date or Gradual Decrease</strong> Select a method to define the target: by fixed end date or by a gradual decrease</li>\n' +
        '<li><strong>Target Period</strong> Period of the target (start and end time)</li>\n' +
        '<li><strong>Target Value</strong> Value of the target</li>\n' +
        '<li><strong>Percentage</strong> Expresses the target value as a percentage</li>\n' +
        '<li><strong>Extend Period to Target Time</strong> Displays the target period entirely on the widget (until the end date) </li>\n' +
        '<li><strong>Target Start Date</strong> Date on which the target starts</li>\n' +
        '<li><strong>Decrease</strong> Specifies how the target should be reached (in percentage by period)</li>\n' +
        '</ul>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Color</strong> Select a color for the datapoint</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Target Value</strong> Value of the target</li>\n' +
        '<li><strong>Percentage</strong> Expresses the target value as a percentage</li>\n' +
        '<li><strong>Extend Period to Target Time</strong> Displays the target period entirely on the widget (until the end date) </li>\n' +
        '</ul>',
    },
    default: {
      ...graphCard.editProps.default,
      type: 'TrendLine',
      title: 'widget.trendLine',
    },
    unUsedDatapointProperties: ['rangeId', 'offsets'],
    preview: {
      icon: 'fas fa-chart-line',
      chart: {
        series: [
          {
            color: '#109afd',
            data: [
              [1, 20],
              [2, 15],
              [3, 10],
            ],
            type: 'line',
          },
          {
            color: '#109afd',
            data: [
              [3, 10],
              [4, 8],
              [5, 6],
              [6, 4],
            ],
            dashStyle: 'Dot',
            lineWidth: 1,
            type: 'line',
          },
          {
            color: variables.color.light.softStroke,
            data: [
              [1, 20],
              [2, 16.8],
              [3, 13.6],
              [4, 10.4],
              [5, 7.2],
              [6, 4],
            ],
            type: 'line',
            dashStyle: 'ShortDash',
          },
        ],
      },
    },
    fields: configFieldConvertor(graphCard.editProps.fields, [
      {
        selector: (field) =>
          field.dataKey === 'showTotals' ||
          field.category === 'widget.datapointTotals' ||
          field.dataKey === 'stacking' ||
          field.dataKey === 'graphOpacity',
        resolver: () => 'exclude',
      },
      {
        selector: (field) => field.category === 'widget.fixedValues',
        resolver: (field) => {
          return {
            ...field,
            componentProps: {
              ...field.componentProps,
              includeType: false,
            },
          }
        },
      },
      {
        selector: (field) => field.componentName === 'DatapointGroups',
        resolver: (field) => [
          {
            ...field,
            category: 'widget.data',
            componentProps: {
              ...field.componentProps,
              section: {
                ...field.componentProps.section,
                componentProps: {
                  ...field.componentProps.section.componentProps,
                  fields: configFieldConvertor(field.componentProps.section.componentProps.fields, [
                    {
                      selector: (field) =>
                        field.dataKey === 'dashStyle' ||
                        field.dataKey === 'useSteps' ||
                        field.category === 'widget.fixedValues',
                      resolver: () => 'exclude',
                    },
                    {
                      selector: (field) => field.componentName === 'ColorPicker',
                      resolver: (field) => [
                        field,
                        {
                          category: 'widget.interval',
                          componentName: 'DecreaseConfigurator',
                          label: { formatted: 'widget.target', tagName: 'h4' },
                          dataKey: '',
                          componentProps: {
                            dataKey: 'decreasePeriod',
                            modeSelector: false,
                          },
                        },
                      ],
                    },
                  ]),
                },
              },
              groupSection: {
                ...field.componentProps.groupSection,
                componentProps: {
                  ...field.componentProps.groupSection.componentProps,
                  fields: configFieldConvertor(
                    field.componentProps.groupSection.componentProps.fields,
                    [
                      {
                        selector: (field) =>
                          field.dataKey === 'dashStyle' ||
                          field.dataKey === 'useSteps' ||
                          field.category === 'widget.fixedValues',
                        resolver: () => 'exclude',
                      },
                      {
                        selector: (field) => field.dataKey === 'method',
                        resolver: (field) => [
                          field,
                          {
                            category: 'widget.interval',
                            componentName: 'DecreaseConfigurator',
                            label: { formatted: 'widget.target', tagName: 'h4' },
                            dataKey: '',
                            componentProps: {
                              dataKey: 'decreasePeriod',
                              modeSelector: false,
                            },
                          },
                        ],
                      },
                      {
                        selector: (field) => field.componentName === 'CalculationMethod',
                        resolver: (field) => ({
                          ...field,
                          componentProps: {
                            ...field.componentProps,
                            addRangeMethod: false,
                          },
                        }),
                      },
                    ]
                  ),
                },
              },
            },
          },
          {
            category: 'widget.interval',
            componentName: 'DecreaseConfigurator',
            label: { formatted: 'widget.target', tagName: 'h4' },
            dataKey: '',
            componentProps: {
              dataKey: 'decreasePeriod',
            },
          },
        ],
      },
      {
        selector: (field) => field.componentName === 'DefaultStartTimeSelector',
        resolver: (field) => ({
          ...field,
          componentProps: {
            ...field.componentProps,
            reportingYear: true,
            disableMonthGranularityValue: false,
            addRangeMethod: false,
          },
        }),
      },
    ]),
  },
}

export default config
