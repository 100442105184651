import jwt from 'jsonwebtoken'
/**
 * Force logout a user with tokens before a specific time
 */
export const forceLogoutUsersBeforeTime = (thresholdTime, logout) => {
  const token = localStorage.getItem('token')

  if (token) {
    const decodedToken = jwt.decode(token)

    if (decodedToken.iat < thresholdTime) {
      logout(true)
    }
  }
}
