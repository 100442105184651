import React from 'react'

import { message } from 'antd'

import Button from 'components/Global/Button'
import Icon from 'components/Global/Icon'
import NotificationProgress from 'components/Layout/Notifications/NotificationProgress'
import { Notification } from 'components/Layout/Notifications/styled'

export const showMessage = async (action, theme, intl) => {
  const {
    id,
    type = 'info',
    icon,
    text,
    buttons,
    inProgress,
    formattedLabel,
    ...messageProps
  } = action.payload.value

  message[type]({
    ...messageProps,
    key: id,
    icon: (
      <Icon
        icon={
          icon ||
          {
            error: 'fas fa-xmark',
            info: 'fas fa-info',
            warning: 'fas fa-exclamation',
            success: 'fas fa-check',
          }[type]
        }
        variant="notification"
        color={theme.color[type]}
        theme={theme}
      />
    ),
    content: (
      <Notification theme={theme} variant="notification">
        <span
          className="Notification__label"
          dangerouslySetInnerHTML={{
            __html: formattedLabel
              ? formattedLabel.details
                ? Object.entries(formattedLabel.details).reduce(
                    (text, [key, value]) => text.replace(`{${key}}`, value),
                    intl.formatMessage({ id: formattedLabel.id })
                  )
                : intl.formatMessage({ id: formattedLabel.id })
              : text,
          }}
        ></span>
        {buttons?.map((button) => (
          <Button
            {...button}
            {...(button.onClick && {
              onClick: (payload) => {
                button.onClick(payload)
                message.destroy(id)
              },
            })}
            variant="smallButton"
            theme={theme}
          />
        ))}
        {inProgress && <NotificationProgress theme={theme} />}
        <Icon
          className="Notification__close"
          icon="fas fa-xmark"
          onClick={() => message.destroy(id)}
        />
      </Notification>
    ),
  })
  if (action.payload.function) {
    await action.payload.function().then((res) => {
      if (action.payload.onComplete) {
        if (action.payload.onComplete.action) {
          showMessage(action.payload.onComplete.action, theme, res)
        }
        if (action.payload.onComplete.function) {
          action.payload.onComplete.function(res)
        }
      }
    })
  }
}
