import React from 'react'

import { Controller } from 'react-hook-form'

import CascaderFieldStyled from 'components/Form/fields/CascaderField/styled'

import Cascader from '../../components/Cascader'

const CascaderField = ({
  intl,
  name,
  label,
  className,
  readOnly,
  onChange,
  validation,
  ...props
}) => {
  return (
    <CascaderFieldStyled {...{ label, className, readOnly }}>
      {name ? (
        <Controller
          name={name}
          rules={validation}
          render={({ onChange: fieldOnChange, value }) => {
            const handleChange = (value) => {
              fieldOnChange(value)
              onChange(value)
            }

            return <Cascader {...props} onChange={handleChange} value={value} />
          }}
        />
      ) : (
        <Cascader {...props} onChange={onChange} />
      )}
    </CascaderFieldStyled>
  )
}

CascaderField.propTypes = {}

CascaderField.defaultProps = {}

export default CascaderField
