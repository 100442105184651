import React, { useCallback, useMemo, useState } from 'react'

import { Divider } from 'antd'
import { injectIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import {
  CustomerFixedPermissionsSelectorStyled,
  TooltipContent,
} from 'components/Global/Form/styled'
import Tooltip from 'components/Global/Tooltip'

import { AntForm } from '../AntDesign'
import CheckboxField from './CheckboxField'
import InputField from './InputField'
import ManyInputField from './ManyInputField'
import PasswordField from './PasswordField'
import RadioField from './RadioField'
import SelectField from './SelectField'

const FormRow = styled.div`
  ${({ theme }) =>
    theme.screenSize.screen.current !== 's' &&
    css`
      display: flex;
      justify-content: space-between;

      > div:not(:first-child) {
        padding-left: 10px;
      }
    `}
`

function CustomerFixedPermissionsSelector({ options, intl, index, label, ...props }) {
  const [selectedCustomers, setSelectedCustomers] = useState(
    props?.initialValues?.map(JSON.parse) || []
  )

  const selectCustomers = useCallback(
    (customers) => {
      setSelectedCustomers(customers?.map(JSON.parse))
    },
    [setSelectedCustomers]
  )
  const selectOptions = useMemo(
    () =>
      options
        .map((permissions) => {
          const amountOfPermissions = permissions.permissions.filter(
            (permission) => permission.selected
          ).length
          return {
            value: JSON.stringify(permissions),
            label: `${permissions.company.name} ${
              amountOfPermissions ? `(${amountOfPermissions})` : ''
            }`,
          }
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [options]
  )

  const customers = useMemo(
    () =>
      selectedCustomers.map((customer, idx) =>
        renderFormField(
          {
            type: 'radio',
            name: `fixedPermissions_${customer.company.id}`,
            direction: 'column',
            label: `${intl.formatMessage({ id: 'form.label.fixedPermissions' })}: ${
              customer.company.name
            }`,
            options: customer.permissions.map(({ id, name, enabled }) => ({
              value: id,
              label: intl.formatMessage({ id: `settings.fixedpermission.${name}` }),
              disabled: !enabled,
            })),
          },
          intl,
          idx
        )
      ),
    [selectedCustomers, intl]
  )
  const formField = useMemo(
    () =>
      renderFormField(
        {
          type: 'select',
          name: 'customers',
          mode: 'multiple',
          options: selectOptions,
          label: label,
          onChange: selectCustomers,
        },
        intl,
        index
      ),
    [selectOptions, selectCustomers, label, intl, index]
  )
  return (
    <CustomerFixedPermissionsSelectorStyled>
      {formField}
      <Tooltip
        button={{
          icon: 'fas fa-circle-info',
          label: intl.formatMessage({ id: 'form.label.fixedPermissions' }),
        }}
        height={400}
        width={600}
        variant="ValuePicker"
      >
        <TooltipContent>
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.USER_MANAGEMENT_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.DATAPOINT_MANAGEMENT_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.PERSONA_MANAGEMENT_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.RULE_MANAGEMENT_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.FLOW_MANAGEMENT_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.IMPORT_VISITOR_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.IMPORT_EXECUTOR_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.IMPORT_ADMINISTRATOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.ORGANISATION_ADMINISTRATOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.INVOICING_ADMINISTRATOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.LOG_MANAGEMENT_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.SQUEAL_MANAGEMENT_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.SUSTAINABILITY_VISITOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR_INFO',
              }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.ODATA_USER_INFO' }),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'settings.fixedpermission.RESTAPI_USER_INFO' }),
            }}
          />
        </TooltipContent>
      </Tooltip>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat( auto-fit, minmax(260px, 1fr) )',
          width: '100%',
        }}
      >
        {customers}
      </div>
    </CustomerFixedPermissionsSelectorStyled>
  )
}

const renderFormField = ({ type, ...field }, intl, index) => {
  if (field.visible === false) {
    return <></>
  }
  switch (type) {
    default:
      return ''
    case 'customerFPSelector':
      return (
        <CustomerFixedPermissionsSelector
          key={field.name}
          {...{
            ...field,
            label: intl.formatMessage({ id: 'form.label.fixedPermissions' }),
            intl,
            index,
          }}
        />
      )
    case 'manyInput':
      return <ManyInputField key={field.name} {...field} />
    case 'radio':
      return <RadioField key={field.name} {...field} />
    case 'input':
      return <InputField key={field.name} {...field} />
    case 'numberInput':
      return (
        <InputField
          key={field.name}
          {...field}
          type="number"
          step={field.step || 1}
          min={field.min || 0}
        />
      )

    case 'email':
      if (!field.customRules) {
        field.customRules = []
      }
      return (
        <InputField
          key={field.name}
          type="email"
          {...field}
          customRules={[
            ...(field?.customRules || []),
            {
              type: 'email',
              message: `${field.label} ${intl.formatMessage({ id: 'form.input.invalid' })}`,
            },
          ]}
        />
      )
    case 'select':
      return <SelectField key={field.name} {...field} />
    case 'checkbox':
      return <CheckboxField key={field.name} {...field} />
    case 'password':
      return (
        <PasswordField
          key={field.name}
          type="password"
          autoComplete={field.autoComplete}
          {...field}
        />
      )
    case 'confirmPassword':
      return (
        <PasswordField
          key={field.name}
          type="confirmPassword"
          autoComplete={field.autoComplete}
          {...field}
        />
      )
    case 'divider':
      return (
        <Divider key={`form-divider-${index}`} orientation="center">
          {field.name}
        </Divider>
      )
    case 'label':
      return field.label
  }
}

const FormComponent = ({
  rows,
  form,
  intl,
  layout = 'vertical',
  fields,
  initialValues,
  disabled = false,
}) => {
  const formLayout =
    layout === 'horizontal'
      ? {
          labelCol: {
            span: 10,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : {}

  // Sort fields into rows (optional)
  const content = useMemo(() => {
    if (rows) {
      const arr = []
      fields.forEach((f) => {
        const field = { ...f, disabled: disabled ? disabled : f.disabled }
        if (arr[field.row]) {
          arr[field.row].push(field)
        } else {
          arr[field.row] = [field]
        }
      })

      return arr.map((row) => (
        <FormRow key={`row-${row.map((f) => f.name).join('-')}`}>
          {row.map((f) => renderFormField(f, intl))}
        </FormRow>
      ))
    } else {
      return fields.map((f) => renderFormField(f, intl))
    }
  }, [fields, rows, intl, disabled])

  return (
    <AntForm form={form} layout={layout} initialValues={initialValues} {...formLayout}>
      {content}
    </AntForm>
  )
}

export default injectIntl(FormComponent)
