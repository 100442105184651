import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { useAllFixedFileRecords } from 'services/fixedFiles'
import { useCurrentCustomer } from 'services/store'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import ValuePicker from 'components/Form/components/ValuePicker'

const AllFixedFilesSelectField = ({ value, featureType, onChange, readOnly }) => {
  const customer = useCurrentCustomer()
  const { data } = useAllFixedFileRecords(6, false, customer?.id, readOnly)

  const options = useMemo(
    () =>
      !readOnly && data
        ? data.records.reduce((options, { fields }) => {
            if (!featureType || fields['Feature Type'] === featureType) {
              options.push({
                ...fields,
                id: fields.id,
                name: fields.Name,
                color: generateTagColor(fields.Name),
                type: {
                  id: {
                    tag: 1,
                    marker: 2,
                    field: 3,
                    file: 4,
                  }[featureType],
                },
              })
            }
            return options
          }, [])
        : [],
    [data, featureType, readOnly]
  )
  return (
    <ValuePicker
      value={value}
      options={options}
      search
      input
      variant="Tags"
      selectionKeys={{ label: 'name', value: 'id' }}
      isMultiSelect
      onChange={onChange}
      width={380}
    />
  )
}

AllFixedFilesSelectField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default AllFixedFilesSelectField
