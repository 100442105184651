import React from 'react'

import Loader from 'components/LayoutComponents/Loader'

import SecondaryMenu from '../SecondaryMenu'
import useSettingsMenu from './menu'

const SettingsMenu = ({ setSelectedKeys, baseUrl }) => {
  const [menuItems, { loading }] = useSettingsMenu(baseUrl)

  if (loading) {
    return <Loader />
  }

  return (
    <SecondaryMenu
      title="menu.settings"
      items={menuItems}
      setSelectedKeys={setSelectedKeys}
      localisedLabels
    />
  )
}

export default SettingsMenu
