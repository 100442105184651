import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { OpenCloseButton } from 'components/Global/Button/OpenCloseButton'
import { ScrollableContainer } from 'components/Layout/MenuBar/components/SecondaryMenu/styled'

export const MenuActionButtonStyled = styled(NavLink)`
  color: ${({ theme }) => theme.color.sidebarText};

  &:last-child {
    margin-right: 0.8rem;
  }

  &:visited,
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.sidebarText};

    .Icon {
      opacity: 1;
    }
  }

  &:active,
  &.active {
    color: ${({ theme }) => theme.color.highlight};
    .Icon {
      opacity: 1;
    }
  }

  .Icon {
    opacity: 0.7;
    font-size: 1.3rem;
    margin: auto 0 auto 0.3rem;
  }
`

export const MenuPanelStyled = styled.div`
  position: absolute;
  transition: opacity 0.3s ease;
  flex-direction: column;
  background: ${({ theme }) => theme.color.sideBar};

  ${({ theme, isActive }) =>
    theme.screenSize.screen.current === 's'
      ? css`
          transition: bottom 0.5s cubic-bezier(0.68, 0.08, 0.2, 0.9);
          height: 70vh;
          width: 100%;
          border-radius: 1rem 1rem 0 0;
          left: 0;
          bottom: ${isActive ? '100%' : '-72vh'};
          box-shadow: ${`0 -15px 24px rgba(${
            theme.darkMode ? '0,0,0' : '180, 180, 180'
          }, 0.16), 0 3px 6px rgba(${theme.darkMode ? '0,0,0' : '180, 180, 180'}, 0.23)`};

          ${OpenCloseButton} {
            position: absolute;
            transition: top 0.3s ease;
            top: ${({ isActive }) => (isActive ? '-1rem' : '-5rem')};
            left: 50%;
            margin-left: -1.3rem;
            transform: rotate(90deg);
            z-index: 90;
          }
        `
      : css`
          transition: left 0.5s cubic-bezier(0.68, 0.08, 0.2, 0.9);
          //transition-delay: 0.3s;
          width: 380px;
          left: -280px;
          height: 100vh;
          display: flex;
          flex-direction: column;
          box-shadow: ${theme.panelElevationRight(theme.isDarkSideBar && '0,0,0')};

          /*&:hover {
            left: 100px;

            > div {
              opacity: 1;
            }
          }*/

          > div {
            transition: opacity 0.5s ease;
            opacity: 0;
          }

          ${ScrollableContainer} {
            flex: 1;
          }
        `}
`

export const ActionContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 4rem;
`
