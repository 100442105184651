import { getLuminance } from 'polished'
import styled, { css } from 'styled-components'

import Button from 'components/Global/Button'

const NavigationLabelStyled = styled(Button)`
  padding: 0 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  box-shadow: ${({ theme }) => theme.shadowElevation1(theme.darkMode)};
  border-radius: 0.2rem;
  ${({ color, theme }) => {
    const luminance = color && getLuminance(color)
    return color
      ? css`
          background: ${color};
          color: ${luminance < 0.7 ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)'} !important;

          &:hover {
            opacity: 0.6;
          }
        `
      : css`
          background: ${theme.color.backgroundFront};
          color: ${theme.color.mainText};
        `
  }}
`
export default NavigationLabelStyled
