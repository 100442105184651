import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Icon from '../Icon'
import { getNewSlideState } from './functions'
import Slide from './Slide'
import SliderStyled from './styled'

const Slider = ({ styleProps, slides, activeSlide = 0, effect, resetScrollTrigger, nav }) => {
  const [{ activeSlide: _activeSlide, prevSlide, reverse }, _setActiveSlide] = useState({
    activeSlide: activeSlide,
    reverse: false,
  })

  useEffect(() => {
    _setActiveSlide((state) => getNewSlideState(state, activeSlide, slides))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide])

  const onNavClick = (direction) => {
    _setActiveSlide((state) => getNewSlideState(state, direction, slides))
  }

  return (
    <SliderStyled {...styleProps} className="Slider">
      {slides.map((slide, index) => (
        <Slide
          {...slide}
          key={slide.name}
          effect={effect}
          activeSlide={_activeSlide}
          prevSlide={prevSlide}
          slideIndex={index}
          reverse={reverse}
          resetScrollTrigger={resetScrollTrigger}
        />
      ))}
      {nav && (
        <div className="Slider__nav">
          <Icon icon="fas fa-chevron-left" onClick={() => onNavClick('prev')} />
          <Icon icon="fas fa-chevron-right" onClick={() => onNavClick('next')} />
        </div>
      )}
    </SliderStyled>
  )
}

Slider.propTypes = {
  defaultActiveSlide: PropTypes.number,
  styleProps: PropTypes.object,
  slides: PropTypes.arrayOf(PropTypes.object),
  onButtonClick: PropTypes.func,
}

Slider.defaultProps = {
  defaultActiveSlide: 0,
  slides: [{}],
}

export default Slider
