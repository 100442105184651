import React, { useMemo } from 'react'

import { FormattedMessage } from 'react-intl'

import Icon from '../../../../Global/Icon'
import { FieldStyled } from '../styled'

const Field = ({ label, value, dataKey }) => {
  const fieldComponent = useMemo(() => {
    if (typeof value === 'boolean') {
      return <Icon icon={value ? 'fas fa-check' : 'fas fa-times'} />
    }
    if (dataKey === 'country.alpha2') {
      return <Icon icon={`/resources/images/countryflags/${value?.toLowerCase()}.png`} />
    }
    return value
  }, [value, dataKey])
  return value !== null && value !== undefined ? (
    <FieldStyled>
      {label.text ? (
        <label>{label.text}</label>
      ) : (
        <FormattedMessage id={label.formatted} tagName="label" />
      )}
      <span>{fieldComponent}</span>
    </FieldStyled>
  ) : null
}

export default Field
