import styled from 'styled-components'

export const MenuHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
  min-height: 4rem;
  padding: 0 0 0 2.5rem;
  position: relative;
  z-index: 90;

  h2 {
    font-size: ${({ theme }) => theme.text.xl.size}rem;
    font-weight: ${({ theme }) => theme.text.xl.weight};
    color: ${({ theme }) => theme.color.sidebarText};
    flex: 1;
    margin-bottom: 0;
  }
`
