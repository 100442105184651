import { arrayMove } from '@dnd-kit/sortable'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import uniqid from 'uniqid'

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform)

function getDragDepth(offset, indentationWidth) {
  return Math.round(offset / indentationWidth)
}

export function getProjection(items, activeId, overId, dragOffset, indentationWidth, idKey) {
  const overItemIndex = items.findIndex(({ [idKey]: id }) => id === overId)
  const activeItemIndex = items.findIndex(({ [idKey]: id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = arrayMove(items, activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex - 1]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  const maxDepth = getMaxDepth({
    previousItem,
  })
  const minDepth = getMinDepth({ nextItem })
  let depth = projectedDepth

  if (projectedDepth >= maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId
    }

    if (depth > previousItem.depth) {
      return previousItem.id
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId

    return newParent ?? null
  }
}

function getMaxDepth({ previousItem }) {
  if (previousItem) {
    return previousItem.depth + 1
  }

  return 0
}

function getMinDepth({ nextItem }) {
  if (nextItem) {
    return nextItem.depth
  }

  return 0
}

function flatten(items = [], idKey, parentId = null, depth = 0, path = 'root') {
  return items.reduce((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index, path: `${path}[${index}]` },
      ...flatten(item.children, idKey, item[idKey], depth + 1, `${path}[${index}].children`),
    ]
  }, [])
}

export function flattenTree(items, idKey = 'id') {
  return flatten(items, idKey)
}

export const findParents = (data, item, withParents = [], idKey) => {
  if (item?.parentId && !withParents.some(({ [idKey]: id }) => id === item.parentId)) {
    const parent = data.find(({ [idKey]: id }) => id === item.parentId)
    if (parent) {
      withParents.push(parent)
      findParents(data, parent, withParents, idKey)
    }
  }
  return withParents
}

export const filterTree = (data, filteredData, idKey = 'id') => {
  const flattenedItems = filteredData.reduce((withParents, item) => {
    if (!withParents.some(({ [idKey]: id }) => id === item[idKey])) {
      withParents.push(item)
      findParents(data, item, withParents, idKey)
    }
    return withParents
  }, [])

  return {
    flattened: flattenedItems,
    tree: buildTree({
      flattenedItems,
      idKey,
    }),
  }
}

export const getAllOpenItems = (tree, path, openItems = []) => {
  return tree.children.reduce((openItems, child, index) => {
    if (child.children.length) {
      const childPath = `${path}.children[${index}]`
      openItems.push(childPath)
      getAllOpenItems(child, childPath, openItems)
    }
    return openItems
  }, openItems)
}

export function buildTree({
  flattenedItems,
  idKey,
  movedItemId,
  prevParentId,
  newParentId,
  sortKey = 'sortOrder',
}) {
  const root = { id: 'root', children: [], updated: [] }
  const nodes = { [root.id]: root }
  const items = flattenedItems.map((item) => ({ ...item, children: [] }))

  for (const item of items) {
    const { [idKey]: id, children } = item
    const parentId = item.parentId ?? root.id
    const parent = nodes[parentId] ?? findItem(items, parentId, idKey)

    nodes[id] = { [idKey]: id, children }

    const sortOrder = parent.children.length
    parent.children.push({ ...item, [sortKey]: sortOrder })
    if (
      item.id === movedItemId ||
      ((parent.id === prevParentId || parent.id === newParentId) &&
        (item[sortKey] !== sortOrder || item.parentId !== parent.id))
    ) {
      root.updated.push({
        [idKey]: item.id,
        ...(item[sortKey] !== sortOrder && { [sortKey]: sortOrder }),
        ...((item.id === movedItemId || item.parentId !== parent.id) && { parentId: parent.id }),
        item,
      })
    }
  }

  return {
    items: root.children,
    updated: root.updated,
  }
}

export function findItem(items, itemId, idKey) {
  return items.find(({ [idKey]: id }) => id === itemId)
}

export function findItemDeep(items, itemId, idKey) {
  for (const item of items) {
    const { [idKey]: id, children } = item

    if (id === itemId) {
      return item
    }

    if (children.length) {
      const child = findItemDeep(children, itemId, idKey)

      if (child) {
        return child
      }
    }
  }

  return undefined
}

const getCurrentArray = (items, path) => {
  const arrayPath = path.substring(0, path.lastIndexOf('['))
  const parentPath = arrayPath.substring(0, arrayPath.lastIndexOf('.'))
  const itemIndex = parseInt(path.substring(path.lastIndexOf('[') + 1, path.lastIndexOf(']')))
  const currentArray = get({ root: items }, arrayPath)
  return {
    arrayPath,
    parentPath,
    itemIndex,
    currentArray,
  }
}

const getAllChildren = (item, children = []) => {
  if (item.children.length) {
    item.children.forEach((child) => {
      children.push(child)
      return getAllChildren(child, children)
    })
  }
  return children
}

export function removeItem({ items, path, idKey, sortKey = 'sortOrder' }) {
  const { arrayPath, parentPath, itemIndex, currentArray } = getCurrentArray(items, path)
  const { newArray, deleted } = currentArray.reduce(
    (output, item, index) => {
      if (index === itemIndex) {
        output.deleted = [item, ...getAllChildren(item)]
      } else {
        output.newArray.push({ ...item, [sortKey]: index > itemIndex ? index - 1 : index })
      }
      return output
    },
    { newArray: [], deleted: [] }
  )
  return {
    items: arrayPath === 'root' ? newArray : set(arrayPath.replace('root', ''), newArray, items),
    deleted,
    sortedArray: newArray,
    parentId: get({ root: items }, parentPath)?.[idKey],
  }
}

export function addItem({ items, path, idKey, sortKey = 'sortOrder', defaultValue }) {
  const { arrayPath, parentPath, itemIndex, currentArray } = getCurrentArray(items, path)
  const added = defaultValue
    ? { ...defaultValue, [idKey]: defaultValue[idKey] || uniqid(), [sortKey]: itemIndex + 1 }
    : { ...currentArray[itemIndex], [idKey]: uniqid(), [sortKey]: itemIndex + 1 }
  const newArray = currentArray.reduce((newArray, item, index) => {
    newArray.push({ ...item, [sortKey]: index <= itemIndex ? index : index + 1 })
    if (index === itemIndex) {
      newArray.push({ ...added, [sortKey]: index + 1 })
    }
    return newArray
  }, [])

  return {
    items: arrayPath === 'root' ? newArray : set(arrayPath.replace('root', ''), newArray, items),
    added,
    sortedArray: newArray,
    parentId: get({ root: items }, parentPath)?.[idKey],
  }
}

export function setProperty({ items, dataKey, setter, path }) {
  const item = get({ root: items }, path)
  return set(`${path}.${dataKey}`, setter(item), { root: items }).root
}

function countChildren(items, count = 0) {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1)
    }

    return acc + 1
  }, count)
}

export function getChildCount(items, id, idKey) {
  const item = findItemDeep(items, id, idKey)

  return item ? countChildren(item.children) : 0
}

export function removeChildrenOf(items, ids, idKey) {
  const excludeParentIds = [...ids]

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item[idKey])
      }
      return false
    }

    return true
  })
}
