import React, { useState } from 'react'

import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { FieldMessage } from 'components/Form/components/Field/styled'
import SelectField from 'components/Form/fields/SelectField'
import UserSelectField from 'components/Form/fields/UserSelectField'

import Icon from '../../../Global/Icon'
import { NumberField } from '../index'
import FlowFieldCondition from './FlowFieldCondition'
import FlowOperation from './FlowOperation'
import { FlowFieldStyled, FlowParameter } from './styled'

const FlowField = ({
  value,
  name,
  filter,
  action,
  onOpenDetails,
  intl,
  allowEdit,
  removeFlowAction,
  createMode,
  index,
  isLast,
  ...fieldProps
}) => {
  const { watch, isEditMode, errors, setValue } = useFormContext()

  const flowAction = watch(name)

  const [selectedAction, setSelectedAction] = useState(flowAction?.action?.type)

  const changeAction = (value) => {
    setValue(`${name}.action.recipients.users`, [])
    setSelectedAction(value)
  }

  const fieldErrors = get(errors, name)

  const enabled = true

  return (
    <>
      <FlowFieldStyled {...fieldProps} disabled={!enabled}>
        <FlowFieldCondition>
          <FlowParameter>{intl.formatMessage({ id: 'squealThreshold' })}</FlowParameter>
          <FlowOperation operation=">=" />
          <NumberField
            name={`${name}.threshold`}
            validation={{ required: true }}
            defaultValue={flowAction.threshold}
            addonAfter="%"
            max={100}
            min={0}
          />
          {(createMode || isEditMode) && (
            <Icon
              icon={'far fa-trash'}
              className={`${!isEditMode ? 'disabled' : ''}`}
              onClick={() => removeFlowAction(index)}
            />
          )}
        </FlowFieldCondition>
        <FlowFieldCondition label="then">
          <SelectField
            name={`${name}.action.type`}
            defaultValue={flowAction.action.type}
            onChange={changeAction}
            validation={{ required: true }}
            options={[
              {
                value: 'mail',
                label: intl.formatMessage({
                  id: 'sendMail',
                }),
              },
              {
                value: 'sms',
                label: intl.formatMessage({
                  id: 'sendSms',
                }),
              },
              {
                value: 'voice_mobile',
                label: intl.formatMessage({
                  id: 'sendVoiceMessage',
                }),
              },
              {
                value: 'voice_fixed',
                label: intl.formatMessage({
                  id: 'sendVoiceFixedMessage',
                }),
              },
              {
                value: 'doNothing',
                label: intl.formatMessage({
                  id: 'doNothing',
                }),
              },
            ]}
          />
          {selectedAction !== 'doNothing' && <FlowOperation operation="to" />}
          {selectedAction !== 'doNothing' && (
            <UserSelectField
              name={`${name}.action.recipients.users`}
              defaultValue={flowAction.action.recipients?.users}
              showRequired
              userType={selectedAction}
            />
          )}
        </FlowFieldCondition>

        {fieldErrors?.value && (
          <FieldMessage>
            {intl.formatMessage({ id: `form.field.error.${fieldErrors.value.type}` })}
          </FieldMessage>
        )}
      </FlowFieldStyled>
    </>
  )
}

export default injectIntl(FlowField)
