import { makeVar } from '@apollo/client'

export const settingsVar = makeVar({
  id: 0,
  isMobileView: false,
  isMobileMenuOpen: false,
  isLightTheme: true,
  isSettingsOpen: false,
  isMenuTop: false,
  isMenuCollapsed: false,
  isBorderless: true,
  isSquaredBorders: false,
  isFixedWidth: false,
  isMenuShadow: true,
  isModalOpen: false,
})
