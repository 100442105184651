import React, { useState } from 'react'

import { Switch } from 'antd'
import { injectIntl } from 'react-intl'

import AgGridToggleCellStyled from './styled'

const AgGridToggleCell = ({ onChange, value, intl, label = {}, className }) => {
  const [isActive, setIsActive] = useState(value)

  return (
    <AgGridToggleCellStyled
      className={`AgGridToggleCell ${className}`}
      ref={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          setIsActive((state) => {
            onChange(!state)
            return !state
          })
        }
      }}
    >
      <span className="label">
        {intl.formatMessage({
          id: isActive ? label.on : label.off,
        })}
      </span>
      <Switch checked={isActive} size="small" />
    </AgGridToggleCellStyled>
  )
}

export default injectIntl(AgGridToggleCell)
