import React from 'react'

import { injectIntl } from 'react-intl'

import { DatapointList } from 'components/Global/FormField/Datapoints'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import LinkedData from '../DatapointGroups/LinkedData'
import { useEquipDatapointMap, usePresetsWithDatapoints } from '../EquipmentSelector/hooks'
import FormField from '../index'
import DatapointsStyled from './styled'

const Datapoints = ({
  dataKey = 'datapoints',
  subPath,
  onChange,
  onClick,
  intl,
  linkedMode,
  ...props
}) => {
  const {
    state: { editModeContext, elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const { data } = usePresetsWithDatapoints(elementInEditMode.preview.equipmentTemplate?.presetId)

  const equipDatapointMap = useEquipDatapointMap(data?.entityPreset)

  const handleDatapointClick = (e, { id, name, itemIndex }) => {
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: itemIndex,
          dataKey,
        },
      })
      onClick(e, { id, name, index: itemIndex })
    }
  }

  return editModeContext === 'Matrix' && dataKey === 'datapoints' ? (
    <FormField
      value={elementInEditMode.preview.equipments}
      dataKey="showUnit"
      label={{ formatted: 'widget.preset' }}
      componentName="EquipmentPresetDatapointSelector"
      componentProps={{
        graph: {
          path: elementInEditMode.matrixDetailGraph.path.replace('root.elements[0]', 'detail'),
          dataKey: 'datapoints[0]',
        },
        equipDatapointMap,
        equipments: elementInEditMode.preview.equipments,
        template: elementInEditMode.preview.detailTemplate,
      }}
      col="col1"
      onChange={({ value, dataKey }) => {
        if (dataKey === 'template') {
          onChange(value, 'detailTemplate', { rootUpdate: true })
        } else {
          onChange(value, dataKey, { rootUpdate: true })
        }
      }}
    />
  ) : (
    <DatapointsStyled className="Datapoints">
      <DatapointList
        {...props}
        dataKey={dataKey}
        elementInEditMode={elementInEditMode.preview}
        selectedDatapointMap={elementInEditMode?.selectedDatapointMap}
        onDatapointClick={handleDatapointClick}
        subPath={subPath}
        onChange={onChange}
        action={action}
      />
      {linkedMode && (
        <LinkedData
          label="widget.linkedWidget"
          elementInEditMode={elementInEditMode?.preview}
          selectedDatapointMap={elementInEditMode?.selectedDatapointMap}
          dataKey={dataKey}
          subPath={subPath}
          onChange={onChange}
          onClick={onClick}
          action={action}
          onDatapointClick={handleDatapointClick}
        />
      )}
    </DatapointsStyled>
  )
}

export default injectIntl(Datapoints)
