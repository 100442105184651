import { useCallback } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

// Max depth for nested elements (currently dashboards only)
const maxDepth = 15

// This function generates recurring elements for the queries.
function generateNestedQueries(element, closingElement) {
  let result = ''
  let closing = ''
  for (let i = 0; i < maxDepth; i++) {
    result = `${result}${element}\n`
    closing = `${closing}${closingElement}\n`
  }
  return `${result}${closing}`
}

export const DASHBOARDS_QUERY = gql`
  fragment dashboardsfragment on SimpleDashboard {
    id
    key
    title
    icon
    isFavourite
    order
    owner
    readOnly
    icon
  }

  query dashboards($omitMasterDashboardsFromOthers: Boolean) {
    sharableDashboards(omitMasterDashboardsFromOthers: $omitMasterDashboardsFromOthers) {
      ...dashboardsfragment
      ${generateNestedQueries('children: children {...dashboardsfragment', '}')}
    }
  }
`

export const DASHBOARD_OVERVIEW_QUERY = gql`
  query dashboardOverview($omitMasterDashboardsFromOthers: Boolean) {
    dashboardOverview(omitMasterDashboardsFromOthers: $omitMasterDashboardsFromOthers) {
      dashboardInfo {
        id
        key
        title
        icon
        masterDashboardRights
        creatorMail
        order
        readOnly
        owner
        parentDashboard {
          id
        }
        numberOfChildren
      }
      hierarchy
    }
  }
`

export const MASTER_DASHBOARD_COPIE_OWNERS_QUERY = gql`
  query masterDashboardCopyOwners($id: Float!) {
    masterDashboardCopyOwners(id: $id) {
      account {
        id
        email
      }
      masterDashboardRights
    }
  }
`

export const DASHBOARDS_ID_QUERY = gql`
  fragment dashboardIdfragment on SimpleDashboard {
    id
    key: id
    title
    owner
    readOnly
    masterDashboardRights
  }

  query dashboards ($omitMasterDashboardsFromOthers: Boolean){
    sharableDashboards (omitMasterDashboardsFromOthers:$omitMasterDashboardsFromOthers) {
      ...dashboardIdfragment
      ${generateNestedQueries('children: children {...dashboardIdfragment', '}')}
    }
  }
`

export const CREATE_DASHBOARD_MUTATION = gql`
  mutation createDashboard($data: DashboardInput!) {
    createDashboard(data: $data) {
      success
      message
      object {
        id
        key
      }
    }
  }
`

export const DASHBOARD_QUERY = gql`
  fragment dashboardfragment on SimpleDashboard {
    id
    key
    title
    isFavourite
    order
    creatorMail
    icon
    isDataEntryDashboard
  }
  query dashboard($id: String!) {
    dashboard(id: $id) {
      ...dashboardfragment
    }
  }
`

export const DASHBOARDS_FOR_MENU_QUERY = gql`
  fragment children on MenuDashboard {
    id
    title
    key
    icon
    order
    owner
    readOnly
    isFavourite
  }

  fragment childrenRecursive on MenuDashboard {
    children {
      ...children
      children {
        ...children
        children {
          ...children
          children {
            ...children
            children {
              ...children
              children {
                ...children
                children {
                  ...children
                  children {
                    ...children
                    children {
                      ...children
                      children {
                        ...children
                        children {
                          ...children
                          children {
                            ...children
                            children {
                              ...children
                              children {
                                ...children
                                children {
                                  ...children
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  query dashboardsForMenu($omitMasterDashboardsFromOthers: Boolean) {
    dashboardsForMenu(omitMasterDashboardsFromOthers: $omitMasterDashboardsFromOthers) {
      id
      title
      key
      icon
      order
      owner
      readOnly
      isFavourite
      children {
        ...children
      }
      ...childrenRecursive
    }
  }
`

export const DASHBOARD_HIERARCHY_UPWARDS_QUERY = gql`
  fragment parentDashboardfragment on SimpleDashboard {
    id
    title
    isFavourite
    creatorMail
    isDataEntryDashboard
  }

  query dashboard($id: String!) {
    dashboard(id: $id) {
      ...parentDashboardfragment,
      ${generateNestedQueries('parentDashboard {...parentDashboardfragment', '}')}
    }
  }
`

export const DASHBOARD_DETAIL_QUERY = gql`
  query Dashboard($id: String!) {
    dashboard(id: $id) {
      id
      key
      layout
      icon
      title
      isFavourite
      readOnly
      owner
      parentDashboard {
        id
      }
      creatorMail
      createdAt
      updatedAt
      masterDashboardRights
    }
  }
`

export const DASHBOARD_LAYOUT_QUERY = gql`
  query Dashboard($id: String!) {
    dashboard(id: $id) {
      id
      title
      layout
      owner
      readOnly

      creatorMail
      masterDashboardRights
      isDataEntryDashboard
    }
  }
`

export const UPDATE_DASHBOARD_MUTATION = gql`
  mutation updateDashboard($id: ID!, $data: DashboardUpdateInput!) {
    updateDashboard(id: $id, data: $data) {
      success
      message
    }
  }
`

export const DASHBOARD_FAVOURITES_QUERY = gql`
  query FavouriteDashboards {
    favouriteDashboards {
      id
    }
  }
`

export const DUPLICATE_DASHBOARD_MUTATION = gql`
  mutation duplicateDashboard($id: ID!) {
    duplicateDashboard(id: $id) {
      success
    }
  }
`

export const DEEP_DUPLICATE_DASHBOARD_MUTATION = gql`
  mutation deepDuplicateDashboard($id: ID!) {
    deepDuplicateDashboard(id: $id) {
      success
    }
  }
`

export const UPDATE_MULTIPLE_DASHBOARDS_MUTATION = gql`
  mutation updateDashboard($id: ID!, $data: DashboardUpdateInput!) {
    updateDashboard(id: $id, data: $data) {
      success
      message
    }
  }
`

// Delete dashboard
export const DELETE_DASHBOARD_MUTATION = gql`
  mutation deleteDashboard($id: Float!, $onlyMyDashboards: Boolean) {
    deleteDashboard(id: $id, onlyMyDashboards: $onlyMyDashboards) {
      success
      message
      object {
        id
        key
      }
    }
  }
`

export const MOVE_DASHBOARD_MUTATION = gql`
  mutation moveDashboards($moveDashboardInput: MoveDashboardInput!) {
    moveDashboards(moveDashboardInput: $moveDashboardInput)
  }
`
// Custom hook
export const useDeleteDashboard = (mutationUpdate) => {
  const [mutationCallback, { loading, error, data }] = useMutation(
    DELETE_DASHBOARD_MUTATION,
    {},
    {
      update(_, { data }) {
        mutationUpdate(data)
      },
    }
  )
  const deleteDashboard = (id, onlyMyDashboards = false) =>
    mutationCallback({
      variables: { id, onlyMyDashboards },
      refetchQueries: [
        {
          query: DASHBOARD_OVERVIEW_QUERY,
          fetchPolicy: 'network-only',
        },
        {
          query: DASHBOARDS_FOR_MENU_QUERY /* DASHBOARDS_QUERY */,
          fetchPolicy: 'network-only',
        },
      ],
    })
  return [deleteDashboard, { loading, error, data }]
}

// Custom hook
export const useDuplicateDashboard = (mutationUpdate) => {
  const [mutationCallback, { loading, error, data }] = useMutation(
    DUPLICATE_DASHBOARD_MUTATION,
    {},
    {
      update(_, { data }) {
        mutationUpdate(data)
      },
    }
  )
  const duplicateDashboard = (id) =>
    mutationCallback({
      variables: { id },
      refetchQueries: [
        {
          query: DASHBOARD_OVERVIEW_QUERY,
          fetchPolicy: 'network-only',
        },
        {
          query: DASHBOARDS_FOR_MENU_QUERY /* DASHBOARDS_QUERY */,
          fetchPolicy: 'network-only',
        },
      ],
    })
  return [duplicateDashboard, { loading, error, data }]
}

export const useDeepDuplicateDashboard = (mutationUpdate) => {
  const [mutationCallback, { loading, error, data }] = useMutation(
    DEEP_DUPLICATE_DASHBOARD_MUTATION,
    {},
    {
      update(_, { data }) {
        mutationUpdate(data)
      },
    }
  )
  const deepDuplicateDashboard = (id) =>
    mutationCallback({
      variables: { id },
      refetchQueries: [
        {
          query: DASHBOARD_OVERVIEW_QUERY,
          fetchPolicy: 'network-only',
        },
        {
          query: DASHBOARDS_FOR_MENU_QUERY,
          fetchPolicy: 'network-only',
        },
      ],
    })
  return [deepDuplicateDashboard, { loading, error, data }]
}

export const useDashboards = (omitMasterDashboardsFromOthers = null) => {
  const { data, ...rest } = useQuery(DASHBOARDS_FOR_MENU_QUERY, {
    variables: { omitMasterDashboardsFromOthers },
    fetchPolicy: 'network-only',
  })
  return { data: data?.dashboardsForMenu || [], ...rest }
}

export const useDashboardOverview = (omitMasterDashboardsFromOthers = null) => {
  const { data, ...rest } = useQuery(DASHBOARD_OVERVIEW_QUERY, {
    variables: { omitMasterDashboardsFromOthers },
    fetchPolicy: 'network-only',
  })
  return { data: data?.dashboardOverview || [], ...rest }
}

export const useDashboardByKey = (id) =>
  useQuery(DASHBOARD_LAYOUT_QUERY, {
    variables: { id },
  })

export const useDefaultDashboard = () => useQuery(DASHBOARDS_QUERY)

export const useUpdateDashboard = () => {
  const [update, data] = useMutation(UPDATE_DASHBOARD_MUTATION, {
    refetchQueries: [
      {
        query: DASHBOARD_OVERVIEW_QUERY,
        fetchPolicy: 'network-only',
      },
      {
        query: DASHBOARDS_FOR_MENU_QUERY /* DASHBOARDS_QUERY */,
        fetchPolicy: 'network-only',
      },
      { query: DASHBOARD_FAVOURITES_QUERY },
    ],
  })

  const updateFn = useCallback(
    (options = {}) => {
      return update({
        ...options,
        variables: {
          ...options.variables,
        },
      })
    },
    [update]
  )

  return [updateFn, data]
}

export const useCreateDashboard = () => {
  const [create, data] = useMutation(CREATE_DASHBOARD_MUTATION, {
    refetchQueries: [
      {
        query: DASHBOARD_OVERVIEW_QUERY,
        fetchPolicy: 'network-only',
      },
      {
        query: DASHBOARDS_FOR_MENU_QUERY /* DASHBOARDS_QUERY */,
        fetchPolicy: 'network-only',
      },
    ],
  })

  const createFn = useCallback(
    (options = {}) => {
      return create({
        ...options,
        variables: {
          ...options.variables,
        },
      })
    },
    [create]
  )

  return [createFn, data]
}

export const useMoveDashboard = () => {
  const [moveDashboard, data] = useMutation(MOVE_DASHBOARD_MUTATION, {
    refetchQueries: [
      {
        query: DASHBOARD_OVERVIEW_QUERY,
        fetchPolicy: 'network-only',
      },
      { query: /* DASHBOARDS_QUERY */ DASHBOARDS_FOR_MENU_QUERY, fetchPolicy: 'network-only' },
    ],
  })
  return [moveDashboard, data]
}
