import React from 'react'

import { PropTypes } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Container } from './styled'

const SelectionCounter = ({ count = 0 }) => {
  return (
    <Container>
      <strong>{count}</strong> <FormattedMessage id="selected" />
    </Container>
  )
}

SelectionCounter.propTypes = {
  count: PropTypes.number,
}

export default SelectionCounter
