import { css } from 'styled-components'

export const ColorPicker = css`
  background: ${({ theme }) => theme.color.backgroundFront};
  box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
  display: flex;
  flex-wrap: wrap;
  transform-origin: left top;

  .chrome-picker {
    width: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background: none !important;
    font-family: ${({ theme }) => theme.font.primary} !important;
  }

  .ColorPicker__presets {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .ColorPicker__button-container {
    border-top: 1px solid ${({ theme }) => theme.color.softStroke};
    display: flex;
    width: auto;
    margin: 0.8rem 0;
    padding-top: 0.8rem;
    flex: 1;
    justify-content: flex-end;
    .Button {
      margin-right: 1rem;
      border: 1px solid ${({ theme }) => theme.color.action} !important;
    }
  }
`
