import React, { useCallback } from 'react'

import { getGranularityForTimeRange } from 'components/Dashboard/components/TimePicker/functions'
import { granularityUnits } from 'components/Dashboard/Widget/config/selections'
import ValuePicker from 'components/Form/components/ValuePicker'
import { MultiFieldContainer } from 'components/Global/FormField/FormFieldElement/styled'
import NumberInput from 'components/Global/FormField/NumberInput'

const GranularitySelector = ({
  value,
  defaultStartTime,
  disableMonthGranularityValue = true,
  disabled,
  heatMapIntervals,
  dataCy = 'GranularitySelector',
  onChange,
}) => {
  //const isDetailDashboard = true
  const handleBlur = useCallback(
    ({ value: v, dataKey }) => {
      onChange && onChange(v, dataKey)
    },
    [onChange]
  )
  const {
    allowedIntervals,
    defaultGranularityValue,
    defaultGranularity,
  } = getGranularityForTimeRange(defaultStartTime, heatMapIntervals)
  const allowedUnits = granularityUnits.filter(({ value }) => allowedIntervals?.includes(value))

  return (
    <MultiFieldContainer className="MultiFieldContainer">
      <NumberInput
        className="NumberInput"
        value={parseInt(value?.defaultGranularityValue || defaultGranularityValue)}
        min={1}
        onBlur={(value) => handleBlur({ value, dataKey: 'defaultGranularityValue' })}
        disabled={
          disabled ||
          (disableMonthGranularityValue && value?.defaultGranularity === 'MS') ||
          value?.defaultGranularity === 'linkedData'
        }
        dataCy={`${dataCy}-defaultGranularityValue`}
      />
      <ValuePicker
        value={value?.defaultGranularity || defaultGranularity}
        options={[
          ...allowedUnits,
          /*...(isDetailDashboard
            ? [{ value: 'linkedData', label: { formatted: 'widget.linkedWidget' } }]
            : []),*/
        ]}
        onChange={(value) => handleBlur({ value, dataKey: 'defaultGranularity' })}
        search={false}
        dataCy={`${dataCy}-defaultGranularity`}
        disabled={disabled}
      />
    </MultiFieldContainer>
  )
}

export default GranularitySelector
