import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'
import TextInputStyled from 'components/Form/components/TextInput/styled'

export const ValueUnitFieldStyled = styled(Field)`
  > ${Value} {
    display: flex;
    align-items: baseline;
  }

  ${Value} {
    font-size: 1.2rem;
    font-weight: 600;
  }

  ${TextInputStyled} {
    input {
      top: 0.3rem;
    }
  }
`

export const Unit = styled.span`
  margin-left: 0.2rem;
  font-size: 10px;
`
