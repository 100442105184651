import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const speedMap = {
  slow: '0.8s',
  fast: '0.4s',
}
const AgGridStatusCellStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .AgGridStatusCell__container {
    display: flex;
  }

  ${({ variant }) =>
    variant === 'label'
      ? css`
          justify-content: center;
          align-items: flex-start;

          .AgGridStatusCell__container {
            align-items: center;
            border: 1px solid ${({ theme, color, status }) => color || theme.color[status]};
            border-radius: 1rem;

            span {
              margin: 0.4rem;
            }

            label {
              margin: 0 0.5rem 0 0.2rem;
              text-transform: none;
              font-weight: 400;
              font-size: 0.9rem;
            }
          }
        `
      : css`
          .AgGridStatusCell__container {
            flex-direction: column;
          }
        `}
  span {
    display: block;
    width: .8rem;
    height: .8rem;
    transition: transform .2s ease;
    margin: 1rem auto .5rem;
    background: ${({ theme, color, status }) => color || theme.color[status]};
    box-shadow: 0 0 1px 2px ${({ theme, color, status }) =>
      transparentize(0.5, color || theme.color[status] || theme.shadowBaseColor)};
    border-radius: 100%;

    &:hover {
      transform: scale(1.2);
    }

    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    animation: ${({ blinking, blinkingSpeed }) =>
      blinking &&
      `${speedMap[blinkingSpeed] || '0.8s'} ease 0s infinite normal none running pulse`};
  }

  label {
    font-size: .7rem;
    line-height: 1;
    color: ${({ theme }) => theme.color.softText};
    text-transform: uppercase;
  }

}
`

export default AgGridStatusCellStyled
