import { css } from 'styled-components'

export const ring = css`
  display: inline-block;
  position: relative;
  div {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    box-sizing: border-box;
    display: block;
    position: absolute;
    border: ${({ size }) => Math.ceil(size / 10)}px solid #fff;
    border-radius: 50%;
    border-color: ${({ theme, color }) => theme.color[color]} transparent transparent transparent;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
