import React from 'react'

import { OpenCloseButton } from 'components/Global/Button/OpenCloseButton'

import { MenuPanelStyled } from './styled'

const MenuPanel = ({ width, screen, isActive, togglePanelWidth, children }) => {
  return (
    <MenuPanelStyled
      className={`MenuPanel ${isActive ? 'active' : 'inactive'}`}
      width={width}
      isActive={isActive}
    >
      {children}
      {screen === 's' && (
        <OpenCloseButton variant="openCloseButton" label=" " onClick={togglePanelWidth} />
      )}
    </MenuPanelStyled>
  )
}

MenuPanel.defaultProps = {
  componentProps: {},
}

export default MenuPanel
