import { useCallback, useMemo } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import { useDatasourceQuery } from 'util/agGrid'
import { useCurrentCustomer } from 'services/store'

const convertTreeToList = (isEnabled) => (items, path = []) => {
  return items.reduce((list, { subGhgs, id, __typename, ...item }) => {
    const hierarchy = [...path, `${__typename}:${id}`]

    const listItem = {
      id,
      ...item,
      hierarchy,
      __typename,
    }

    list.push(listItem)

    if (subGhgs) {
      return list.concat(convertTreeToList(isEnabled)(subGhgs, hierarchy))
    }

    return list
  }, [])
}

const QUERY_GHG_TREE = gql`
  query ghgs {
    ghgs {
      id
      name
    }
  }
`

export const useGhgTreeData = () => {
  const { data, ...queryInfo } = useQuery(QUERY_GHG_TREE)

  const treeData = useMemo(() => {
    if (!data?.ghgs) {
      return undefined
    }

    return convertTreeToList(() => true)(data.ghgs)
  }, [data])

  return { data: treeData, ...queryInfo }
}

const QUERY_GHG_DETAIL = gql`
  query ghg($id: ID!) {
    ghg(id: $id) {
      name
      description
    }
  }
`

export const useGhgDetail = (id) => {
  const { data, ...queryInfo } = useQuery(QUERY_GHG_DETAIL, { variables: { id } })

  return { data: data?.ghg, ...queryInfo }
}

const QUERY_GHG_CONTRACTS = gql`
  query energyContractsForPeriod($fromDate: DateTime!, $toDate: DateTime!, $datapointId: String!) {
    energyContractsForPeriod(fromDate: $fromDate, toDate: $toDate, datapointId: $datapointId) {
      id
      activationDate
      name
      energyContractCompositionParts {
        id
        percentage
        energyType {
          id
          energySource
          renewable
          standardUnit {
            id
            name
          }
        }
        fuelType {
          id
          name
          standardUnit {
            id
            name
          }
        }
      }
    }
  }
`

export const useGhgContracts = (variables) => {
  const { data, ...queryInfo } = useQuery(QUERY_GHG_CONTRACTS, {
    variables,
    skip: !variables.datapointId,
  })

  return { data: data?.energyContractsForPeriod, ...queryInfo }
}

const QUERY_GHG_HISTORY = gql`
  fragment GasData on GhgHistoricalGasValue {
    value
    achieved
    targetValue
    currentPercentage
    targetPercentage
    referenceValue
    isComplete
  }

  fragment GhgHistoryRecord on GhgHistoricalOutput {
    id
    entity {
      name
    }
    hasChildren
    gases {
      co2 {
        ...GasData
      }
      ch4 {
        ...GasData
      }
      ch4Co2e {
        ...GasData
      }
      biogenicCo2 {
        ...GasData
      }
      n2o {
        ...GasData
      }
      n2oCo2e {
        ...GasData
      }
      total {
        ...GasData
      }
      hfc {
        ...GasData
      }
      hfcCo2e {
        ...GasData
      }
      pfc {
        ...GasData
      }
      pfcCo2e {
        ...GasData
      }
      sf6 {
        ...GasData
      }
      sf6Co2e {
        ...GasData
      }
      nf3 {
        ...GasData
      }
      nf3Co2e {
        ...GasData
      }
    }
  }

  query ghgHistorical(
    $parentEntityId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $scopeId: ID!
    $organisationId: ID!
    $scope3Subcategory: ID
  ) {
    ghgHistorical(
      parentEntityId: $parentEntityId
      startDate: $startDate
      endDate: $endDate
      scopeId: $scopeId
      organisationId: $organisationId
      scope3Subcategory: $scope3Subcategory
    ) {
      ...GhgHistoryRecord
      children {
        ...GhgHistoryRecord
      }
    }
  }
`

export const useGhgHistoricalDatasource = ({ getVariables, ...props }) => {
  const getRowData = useCallback((data, { parentNode }) => {
    if (parentNode.level < 0 && data?.data?.ghgHistorical) {
      const { id, entity, hasChildren, gases } = data?.data?.ghgHistorical

      return [
        {
          id,
          entity,
          hasChildren,
          gases,
        },
      ]
    }

    return data?.data?.ghgHistorical?.children
  }, [])
  return useDatasourceQuery(QUERY_GHG_HISTORY, { getRowData, getVariables, ...props })
}

const QUERY_GHG_REFRIGERANT_HISTORY = gql`
  fragment GhgRefrigerantRecord on GhgHistoricalRefrigerantOutput {
    id
    entity {
      name
    }
    hasChildren
    value
  }

  query ghgRefrigerantHistorical(
    $parentEntityId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $scopeId: ID!
    $organisationId: ID!
  ) {
    ghgRefrigerantsHistorical(
      parentEntityId: $parentEntityId
      startDate: $startDate
      endDate: $endDate
      scopeId: $scopeId
      organisationId: $organisationId
    ) {
      ...GhgRefrigerantRecord
      children {
        ...GhgRefrigerantRecord
      }
    }
  }
`

export const useGhgRefrigerantHistoricalDatasource = ({ getVariables, ...props }) => {
  const getRowData = useCallback((data, { parentNode }) => {
    if (parentNode.level < 0 && data?.data?.ghgRefrigerantsHistorical) {
      const { id, entity, hasChildren, value } = data?.data?.ghgRefrigerantsHistorical

      return [
        {
          id,
          entity,
          hasChildren,
          value,
        },
      ]
    }

    return data?.data?.ghgRefrigerantsHistorical?.children
  }, [])
  return useDatasourceQuery(QUERY_GHG_REFRIGERANT_HISTORY, { getRowData, getVariables, ...props })
}

const QUERY_GHG_TRANS_REMARK = gql`
  query ghgTransparancyRemark($companyId: ID!, $ghgId: ID!) {
    ghgTransparancyRemark(companyId: $companyId, ghgId: $ghgId) {
      id
      remark
    }
  }
`

export const useGhgTransparancyRemark = (ghgId) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_GHG_TRANS_REMARK, {
    variables: { companyId: customer.id, ghgId },
    skip: !customer,
  })

  return { ...rest, data: data?.ghgTransparancyRemark }
}

const MUTATION_GHG_TRANS_REMARK = gql`
  mutation updateGhgTransparancyInput($companyId: ID!, $remark: String!, $ghgId: ID!) {
    updateGhgTransparancyRemark(remark: $remark, companyId: $companyId, ghgId: $ghgId) {
      id
      remark
    }
  }
`

export const useUpdateGhgTransparancyRemark = () => {
  const customer = useCurrentCustomer()

  const [update, info] = useMutation(MUTATION_GHG_TRANS_REMARK)
  const handleUpdate = (remark, ghgId) =>
    update({ variables: { remark, companyId: customer.id, ghgId } })

  return [handleUpdate, info]
}

export const QUERY_GHG_HISTORICAL_MOBILE = gql`
  fragment benchmarkGasValues on GhgMobileHistoricalBenchmarkGasValue {
    targetValue
    referenceValue
  }

  query ghgMobileHistorical(
    $startDate: DateTime!
    $endDate: DateTime!
    $companyId: ID!
    $organisationId: ID!
    $scope3Subcategory: ID
  ) {
    ghgMobileHistorical(
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      organisationId: $organisationId
      scope3Subcategory: $scope3Subcategory
    ) {
      benchmarks {
        benchmark {
          id
          targetPercentage
          vehicleType {
            id
          }
          organisationForm {
            id
          }
        }
        co2 {
          ...benchmarkGasValues
        }
        ch4 {
          ...benchmarkGasValues
        }
        ch4Co2e {
          ...benchmarkGasValues
        }
        biogenicCo2 {
          ...benchmarkGasValues
        }
        n2o {
          ...benchmarkGasValues
        }
        n2oCo2e {
          ...benchmarkGasValues
        }
        total {
          ...benchmarkGasValues
        }
      }
      data {
        id
        error
        entity {
          name
          department {
            id
            name
          }
          vehicleType {
            id
            description
          }
        }
        gases {
          co2 {
            value
          }
          ch4 {
            value
          }
          ch4Co2e {
            value
          }
          biogenicCo2 {
            value
          }
          n2o {
            value
          }
          n2oCo2e {
            value
          }
          total {
            value
          }
        }
      }
    }
  }
`

export const useGhgMobileHistoricalData = ({
  startDate,
  endDate,
  organisationId,
  scope3Subcategory,
}) => {
  const customer = useCurrentCustomer()

  const { data, ...other } = useQuery(QUERY_GHG_HISTORICAL_MOBILE, {
    variables: { startDate, endDate, companyId: customer?.id, organisationId, scope3Subcategory },
    skip: !customer?.id || !organisationId,
  })

  return { data: data?.ghgMobileHistorical, ...other }
}

const QUERY_GHG_HISTORCAL_TOTAL = gql`
  query ghgTotalHistorical(
    $organisationId: ID!
    $endDate: DateTime!
    $startDate: DateTime!
    $uid: ID!
    $getScope3Cat3Total: Boolean
  ) {
    ghgTotalHistorical(
      organisationId: $organisationId
      endDate: $endDate
      startDate: $startDate
      uid: $uid
      getScope3Cat3Total: $getScope3Cat3Total
    ) {
      gases {
        co2 {
          value
          isComplete
        }
        ch4 {
          value
          isComplete
        }
        ch4Co2e {
          value
          isComplete
        }
        n2o {
          value
          isComplete
        }
        n2oCo2e {
          value
          isComplete
        }
        biogenicCo2 {
          value
          isComplete
        }
        total {
          value
          isComplete
        }
        hfc {
          value
          isComplete
        }
        hfcCo2e {
          value
          isComplete
        }
        pfc {
          value
          isComplete
        }
        pfcCo2e {
          value
          isComplete
        }
        sf6 {
          value
          isComplete
        }
        sf6Co2e {
          value
          isComplete
        }
        nf3 {
          value
          isComplete
        }
        nf3Co2e {
          value
          isComplete
        }
      }
      scope {
        id
        description
        order
      }
      subScope {
        key
      }
    }
  }
`

export const useGhgTotalHistoricalData = ({
  startDate,
  endDate,
  organisationId,
  getScope3Cat3Total,
}) => {
  const customer = useCurrentCustomer()
  const uid = customer?.entity?.id

  const { data, ...other } = useQuery(QUERY_GHG_HISTORCAL_TOTAL, {
    variables: { uid, startDate, endDate, organisationId, getScope3Cat3Total },
    skip: !organisationId || !uid,
  })

  return { data: data?.ghgTotalHistorical, ...other }
}

export const useGhgTotalHistoricalScope3Data = ({
  startDate,
  endDate,
  organisationId,
  getScope3Cat3Total,
}) => {
  const customer = useCurrentCustomer()
  const uid = customer?.entity?.id

  const { data, ...other } = useQuery(QUERY_GHG_HISTORCAL_TOTAL, {
    variables: { uid, startDate, endDate, organisationId, getScope3Cat3Total },
    skip: !organisationId || !uid,
  })

  return { data: data?.ghgTotalHistorical, ...other }
}

export const GHG_AUDIT_EXPORT_QUERY = gql`
  mutation ghgAuditExport(
    $companyId: Float!
    $organisationId: ID!
    $creatorName: String!
    $env: String!
    $entityId: String!
    $format: String!
    $reportType: GhgReportTypeEnum!
    $settings: ReportSettingsInput
  ) {
    ghgAuditExport(
      companyId: $companyId
      organisationId: $organisationId
      creatorName: $creatorName
      env: $env
      entityId: $entityId
      format: $format
      reportType: $reportType
      settings: $settings
    )
  }
`

const QUERY_GHG_CONSUMPTION = gql`
  query ghgConsumption($id: ID!, $start: DateTime!, $end: DateTime!) {
    ghgConsumption(id: $id, start: $start, end: $end) {
      id
      value
      entity {
        name
        unit {
          formatted
        }
      }
      details {
        value
        energyContract {
          name
          activationDate
          energyContractCompositionParts {
            percentage
            fuelType {
              name
            }
          }
        }
      }
    }
  }
`

export const useGhgConsumption = ({ id, start, end }) => {
  const { data, ...rest } = useQuery(QUERY_GHG_CONSUMPTION, {
    variables: { id, start, end },
    skip: !id,
  })

  return { data: data?.ghgConsumption, ...rest }
}

const QUERY_GHG_EMISSIONS_TRANSPARANCY = gql`
  query GetGasEmissionsTransparancy(
    $organisationId: ID!
    $end: DateTime!
    $start: DateTime!
    $id: ID!
  ) {
    getGasEmissionsTransparancy(
      organisationId: $organisationId
      end: $end
      start: $start
      id: $id
    ) {
      gases {
        gas
        added
        removed
      }
      id
    }
  }
`

export const useGhgRefrigerantTransparency = ({ id, start, end, organisationId }, options = {}) => {
  const { data, ...rest } = useQuery(QUERY_GHG_EMISSIONS_TRANSPARANCY, {
    variables: {
      organisationId,
      start,
      end,
      id,
    },
    ...options,
  })

  return { data: data?.getGasEmissionsTransparancy?.gases, ...rest }
}

const QUERY_SCOPE_DEFINITION = gql`
  query scopeDefinition($scopeId: Float) {
    scopeDefinition(scopeId: $scopeId) {
      id
      description
      tags
    }
  }
`

export const useScopeDefinition = (id) => {
  const { data, ...rest } = useQuery(QUERY_SCOPE_DEFINITION, {
    variables: id,
  })

  return { data: data?.scopeDefinition, ...rest }
}

const MUTATION_GHG_REFRIGERANT_DATA_INPUT = gql`
  mutation GhgRefrigerantDataInput($date: DateTime!, $method: GhgDataInputMethods!) {
    ghgRefrigerantDataInput(date: $date, method: $method) {
      success
    }
  }
`

export const useSaveRefrigerantDataInput = () => {
  const [call, data] = useMutation(MUTATION_GHG_REFRIGERANT_DATA_INPUT)

  const save = useCallback(
    (variables, options = {}) => {
      return call({ variables, ...options })
    },
    [call]
  )

  return [save, data]
}

export const scope3SubCategories = [
  'all',
  'upstreamPurchasedEnergyFuels',
  'upstreamPurchasedVehicleFuels',
  'upstreamPurchasedEnergy',
  'tndLosses',
]
