import { transparentize } from 'polished'

export const chartOptions = ({ theme, series }) => ({
  title: undefined,
  chart: {
    backgroundColor: 'transparent',
    type: 'gauge',
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },
  pane: {
    size: '100%',
    center: ['50%', '55%'],
    startAngle: -115,
    endAngle: 115,
    background: [
      {
        backgroundColor: theme.color.softerText,
        outerRadius: '97%',
        innerRadius: '88%',
        shape: 'arc',
        borderColor: 'transparent',
      },
    ],
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  yAxis: [
    {
      tickWidth: 0,
      minorTickWidth: 0,
      lineWidth: 0,
      labels: {
        align: 'center',
        reserveSpace: 100,
        y: 16,
      },
    },
  ],
  series,
  plotOptions: {
    gauge: {
      dial: {
        baseWidth: 15,
        baseLength: '65%',
        topWidth: 0,
        rearLength: '-65%',
        radius: '108%',
        backgroundColor: transparentize(0.2, theme.color.softStroke),
        borderColor: theme.color.mainText,
        borderWidth: 1,
      },
      pivot: {
        radius: 0,
      },
    },
  },
})

export const getSerie = ({ value, unit, theme }, secondary = false, localizeNumber) => {
  const serie = {
    data: [value],
    dataLabels: {
      useHTML: true,
      borderWidth: 0,
      y: 0,
      formatter: () => {
        return `<div class="data-labels" style="text-align:center;">
                  <span class="value">${localizeNumber(value)}</span>
                  <span class="unit">${unit}</span><br/>
                </div>`
      },
    },
    pivot: {
      radius: 0,
    },
    overshoot: 0,
  }

  if (secondary) {
    serie.dataLabels.y = 20
    serie.dial = {
      ...serie.dial,
      backgroundColor: transparentize(0.5, theme.color.softStroke),
      borderColor: theme.color.error,
    }
  }

  return serie
}

export const getOptions = ({
  value = 0,
  compareValue,
  unit = '',
  deviationPercentage,
  intl,
  localizeNumber,
} = {}) => {
  const fullOptions = {}

  const percentageLabel =
    deviationPercentage < 0 ? ` (${deviationPercentage}%)` : ` (+${deviationPercentage}%)`
  fullOptions.series = deviationPercentage
    ? [getSerie({ value, unit: unit + percentageLabel }, false, localizeNumber)]
    : [
        getSerie(
          { value, unit: `${unit} - ${intl.formatMessage({ id: 'widgets.current' })}` },
          false,
          localizeNumber
        ),
      ]
  if (compareValue !== undefined) {
    fullOptions.series.push(
      getSerie(
        {
          value: compareValue,
          unit: `${unit} - ${intl.formatMessage({ id: 'widgets.reference' })}`,
        },
        true,
        localizeNumber
      )
    )
  }

  return fullOptions
}
