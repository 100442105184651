import { transparentize } from 'polished'
import styled from 'styled-components'

import Field from 'components/Form/components/Field'

export const MarkerFieldStyled = styled(Field)`
  width: auto !important;
  padding-left: 0;

  button {
    &:not(.ant-tag) {
      &:not(:focus) {
        color: ${({ theme }) => theme.color.softText};
      }
    }
  }

  .ant-tag[disabled],
  .ant-tag[disabled]:hover,
  .ant-tag[disabled]:focus,
  .ant-tag[disabled]:active {
    background: ${({ theme }) => transparentize(0.5, theme.color.main)};
    border-color: ${({ theme }) => transparentize(0.5, theme.color.main)};
    color: ${({ theme }) => theme.color.backgroundFront};
  }

  span.ant-tag {
    font-size: 0.9rem;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    > .Icon {
      padding: 0 0 0 0.5rem;
    }
  }
`
