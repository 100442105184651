import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

const CountrySelectFieldStyled = styled(Field)`
  > ${Value} {
    > span {
      display: flex !important;
      flex-direction: row-reverse;

      .Icon {
        width: 22px;
        margin-left: 0.8rem;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`
export default CountrySelectFieldStyled
