import React from 'react'

export const HvacEco = () => (
  <svg width="30px" height="25px" viewBox="0 25 479.52 399.6">
    <defs />
    <g id="g3760" transform="matrix(0.9365625 0 0 0.78046875 0 0)">
      <path
        id="path3756"
        transform="translate(28.208302, 126.23796)"
        d="M332.516 308.254L332.516 166.809L349.772 181.795L368.64 160.068L184.32 0L0 160.068L18.8683 181.794L36.1246 166.808L36.1246 308.254M64.9001 308.254L64.9001 141.819L184.32 38.1125L303.74 141.819L303.74 308.254"
      />
      <path
        id="path3936"
        transform="translate(390.38798, 120.83870655)"
        d="M42.6874 0.00259345C24.4034 -0.189887 7.70173 10.3457 0 26.9291C4.84701 24.8143 10.0965 23.7786 15.3835 23.8936C36.1081 23.9169 52.9031 40.7119 52.9264 61.4365C52.7189 75.4551 44.9839 88.278 32.6809 95.0015C35.9989 95.4239 39.3428 95.6131 42.6874 95.5664C69.0773 95.5664 90.4693 74.1736 90.4693 47.7845C90.4693 21.3946 69.0773 0.00259345 42.6874 0.00259345ZM42.6874 0.00259345"
      />
    </g>
    <g id="g3762" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3764" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3766" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3768" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3770" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3772" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3774" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3776" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3778" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3780" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3782" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3784" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3786" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3788" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3790" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
  </svg>
)
