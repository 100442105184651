import styled from 'styled-components'

import Button from '../../Button'
import { OptionPicker, Tags, TagsSelectOption, ValuePickerSelectOption } from './styled.variants'

const variants = {
  Tags,
}

const selectOptionVariants = {
  ValuePicker: ValuePickerSelectOption,
  Tags: TagsSelectOption,
  OptionPicker,
}

const SelectorStyled = styled.div`
  .selectAll {
    position: absolute;
    right: 7px;
    top: 5px;
  }
  ${({ variant }) => variants[variant]}
`

export const SelectOption = styled(Button)`
  ${({ variant }) => selectOptionVariants[variant]}
`
export default SelectorStyled
