import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'
import { ImageThumb } from 'components/Form/fields/ImagesField/styled'
import IconStyled from 'components/Global/Icon/styled'

export const FilesFieldStyled = styled(Field)`
  padding: 1rem;

  ${Value} {
    display: flex;
    flex-wrap: wrap;
    .Upload {
      padding: 0;
    }
  }

  ${ImageThumb} {
    height: auto;
  }
`

export const FileFieldButtons = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-size: 2rem;

  ${IconStyled} {
    transition: opacity 0.2s ease, transform 0.2s ease;
    color: ${({ theme }) => theme.color.backgroundFront};
    opacity: 0;
    transform: scale(0);
  }
`

export const FileFieldStyled = styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.color.softStroke};
  border-radius: 0.2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.color.main};
  }
  ${({ deleted }) =>
    deleted &&
    css`
      ${FileFieldButtons} {
        border-color: ${({ theme }) => theme.color.main};
        background: ${({ theme }) => transparentize(0.5, theme.color.main)};

        ${IconStyled} {
          opacity: 1 !important;
          transform: scale(1) !important;
        }
      }
      > ${IconStyled}, > span {
        opacity: 0.5;
      }
    `}
  &:hover {
    ${FileFieldButtons} {
      border-color: ${({ theme }) => theme.color.main};
      background: ${({ theme }) => transparentize(0.3, theme.color.main)};

      ${IconStyled} {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  > ${IconStyled} {
    font-size: 1.5rem;
    margin: 0 0.5rem;
  }
`
