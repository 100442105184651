import { css } from 'styled-components'

const breakpoints = {
  mobile: '320px',
  mobileL: '480px',
  tablet: '768px',
  laptop: '990px',
  laptopL: '1240px',
  desktop: '1440px',
  desktopL: '1920px',
  desktopXL: '2048px'
}

// Iterate through the sizes and create a media template
const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default media
