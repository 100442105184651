import React, { useRef } from 'react'

import { AnimatePresence } from 'framer-motion'
import { bool, string } from 'prop-types'

import { useOnClickOutside } from 'components/Global/hooks'

import TooltipContentStyled from './styled'

const variants = {
  scale: {
    enter: {
      opacity: 0,
      scale: 0,
    },
    active: {
      opacity: 1,
      scale: 1,
    },
    exit: {
      opacity: 0,
      scale: 0,
    },
  },
  scaleY: {
    enter: {
      opacity: 0,
      scaleY: 0,
    },
    active: {
      opacity: 1,
      scaleY: 1,
    },
    exit: {
      opacity: 0,
      scaleY: 0,
    },
  },
}
const TooltipContent = ({
  children,
  effect,
  isOpen,
  className,
  variant,
  position,
  width,
  height,
  minHeight,
  maxWidth,
  fixedHeight,
  preventClickOutside,
  dataCy,
  onToggleOpen = () => {},
}) => {
  const ref = useRef()

  useOnClickOutside(ref, () => !preventClickOutside && onToggleOpen(null, false))
  return (
    <AnimatePresence>
      {isOpen && (
        <TooltipContentStyled
          className={`TooltipContent ${className}`}
          variants={variants[effect]}
          initial="enter"
          animate="active"
          exit="exit"
          position={position}
          width={width}
          height={height}
          fixedHeight={fixedHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          variant={variant}
          ref={ref}
          data-cy={dataCy}
        >
          {children}
        </TooltipContentStyled>
      )}
    </AnimatePresence>
  )
}

TooltipContent.propTypes = {
  isOpen: bool,
  variant: string,
}

TooltipContent.defaultProps = {
  effect: 'scale',
}

export default TooltipContent
