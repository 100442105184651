import React, { useMemo } from 'react'

import set from 'lodash/fp/set'
import get from 'lodash/get'

import ValuePicker from 'components/Form/components/ValuePicker'

const updateEquipmentMatrix = ({ graphPath, equipments, presetId, equipDatapointMap }) => {
  return equipments.map((equipment) =>
    set(graphPath, equipDatapointMap[equipment.id][presetId].datapoint || {}, equipment)
  )
}

const EquipmentPresetDatapointSelector = ({
  graph,
  template,
  equipments,
  equipDatapointMap,
  onChange,
  dataCy = 'EquipmentPresetDatapointSelector',
}) => {
  const presetList = useMemo(() => {
    const [firstEquipment] = equipDatapointMap ? Object.values(equipDatapointMap) : [{}]
    return Object.values(firstEquipment || {}).map((element) => element.preset)
  }, [equipDatapointMap])

  const graphPath = graph.path?.replace('root.', '')
  return (
    <ValuePicker
      value={get(template, `${graphPath?.replace('detail.', '')}.presetId`)}
      options={presetList}
      selectionKeys={{ value: 'id', label: 'translations' }}
      search
      onChange={(presetId) => {
        const equipmentValue = updateEquipmentMatrix({
          graphPath: `${graphPath}.${graph.dataKey}`,
          template,
          equipments,
          presetId,
          equipDatapointMap,
        })
        onChange(equipmentValue, 'equipments')
        const templateValue = set(
          `${graphPath.replace('detail.', '')}.presetId`,
          presetId,
          template
        )
        onChange(templateValue, 'template')
      }}
      dataCy={`${dataCy}-${graphPath}.${graph.dataKey}`}
    />
  )
}

export default EquipmentPresetDatapointSelector
