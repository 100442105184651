import React from 'react'

import { AnimatePresence } from 'framer-motion'
import { bool, object, oneOfType, shape, string } from 'prop-types'

import Icon from '../Icon'
import Spinner from '../Spinner'
import LoaderStyled, { Overlay } from './styled'

const Loader = ({
  fullscreen,
  spinner = { type: 'lineScale', size: 20 },
  icon,
  label,
  variant,
  loading,
  error,
  children,
  className,
  overlay = false,
}) => {
  return (
    <AnimatePresence>
      {(loading || error) && (
        <LoaderStyled
          className={`Loader ${className}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          fullscreen={fullscreen}
          variant={variant}
          transition={{ duration: 0.3 }}
        >
          {overlay && <Overlay></Overlay>}
          {spinner && <Spinner {...spinner} />}
          {icon && <Icon icon={icon} />}
          {label && <label>{label}</label>}
          {children}
        </LoaderStyled>
      )}
    </AnimatePresence>
  )
}

Loader.propTypes = {
  fullscreen: bool,
  spinner: shape({
    type: string,
  }),
  variant: string,
  loading: oneOfType([bool, object]),
}

Loader.defaultProps = {}

export default Loader
