import { calculationMethods } from 'components/Dashboard/Widget/config/selections'

export const config = {
  displayProps: {},
  editProps: {
    name: 'widget.relativeGauge',
    category: 'widget.ghgGaugesIndicators',
    keywords: ['gauge', 'indicator', 'relative', 'ghg'],
    img: '/resources/images/widgets/relativegauge.png',
    info: {
      'en-US':
        '<h2>Relative Gauge</h2>\n' +
        '<p>The Relative Gauge displays the actual value of a datapoint in comparison to a reference value for the same datapoint with a certain positive and negative deviation.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's title</li>\n" +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select a widget datapoint</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Show reference value </strong> Display the reference value or not</li>\n' +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Date/Period</strong> Select a custom date/period for the selected value</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period - Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Date/Period</strong> Select a custom date/period for the selected value</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Max deviation in % </strong> Determine the maximum deviation from the reference value (applies to both min and max)</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Relatieve meter</h2>\n' +
        '<p>De relatieve meter geeft de werkelijke waarde van een datapunt weer in vergelijking met een referentiewaarde voor hetzelfde datapunt met een bepaalde positieve en negatieve afwijking.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong> Widgets titel</li>\n' +
        '<li><strong>Pictogram </strong> Widgetpictogram</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunten </strong> Selecteer een widgetgegevenspunt</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '<li><strong>Referentiewaarde weergeven </strong> Referentiewaarde weergeven of niet</li>\n' +
        '<li><strong>Kleuren </strong> Definieer kleuren voor de overgang tussen waarden</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum/periode</strong> Selecteer een aangepaste datum/periode voor de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h5>Vergelijkingsperiode - Tijdsbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum/periode</strong> Selecteer een aangepaste datum/periode voor de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Max. afwijking in % </strong> Bepaal de maximale afwijking van de referentiewaarde (geldt voor zowel min als max)</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Jauge relative</h2>\n' +
        "<p>La jauge relative affiche la valeur réelle d'un point de données par rapport à une valeur de référence pour le même point de données avec un certain écart positif et négatif.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez un point de données de widget</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '<li><strong>Afficher la valeur de référence </strong> Afficher ou non la valeur de référence</li>\n' +
        '<li><strong>Couleurs </strong> Définir les couleurs pour la transition entre les valeurs</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date/période personnalisée</strong> Sélectionnez une date/période personnalisée pour la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h5>Période de comparaison - Plage de temps pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date/période personnalisée</strong> Sélectionnez une date/période personnalisée pour la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        "<li><strong>Écart maximum en % </strong> Déterminer l'écart maximum par rapport à la valeur de référence (s'applique à la fois au minimum et au maximum)</li>\n" +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'ghg',
      type: 'GhgRelativeGauge',
      title: 'widget.relativeGauge',
      icon: 'fas fa-info',
      color: null,
      datapoints: [],
      comparators: [],
      colors: [
        [0, '#44bc66'],
        [0.33, '#fed33a'],
        [0.66, '#f88c1d'],
        [1, '#f14e3e'],
      ],
      timeRange: {
        preset: 'past7Days',
      },
      comparisonPeriod: null,
      customLogic: '',
      returnUnitId: null,
      emissionFactor: 'total',
      method: 'mean',
      comparatorsMethod: 'mean',
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
      'emissionFactors',
    ],
    preview: {
      icon: 'fas fa-gauge',
      gauge: {
        series: [
          {
            type: 'gauge',
            data: [0],
            tooltip: { valueSuffix: 'm³' },
            dataLabels: {
              useHTML: true,
              borderWidth: 0,
              y: 0,
              formatter: function () {
                return `<div class="data-labels" style="text-align:center;">
          <span class="value">0</span>
          <span class="unit">kwh</span><br/>
         </div>`
              },
            },
          },
        ],
        yAxis: {
          min: 0,
          max: 100,
        },
        unit: 'm³',
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.data',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.calculationMethod' },
        dataKey: 'method',
        componentProps: {
          selectionKeys: { label: 'label', value: 'value' },
          valueKey: 'value',
          options: calculationMethods,
        },
        dependencies: [
          {
            dependentOn: 'widget.datapoints',
            dependencyType: 'hasLength',
            dependencyValue: 1,
            dependencyResolver: 'show',
          },
        ],
      },
      {
        category: 'widget.data',
        componentName: 'Comparator',
        label: { formatted: 'widget.comparisonPoint', tagName: 'h4' },
        componentProps: {
          dataKey: 'comparators',
          disableProperty: true,
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.showComparator' },
        dataKey: 'showComparator',
        col: 'col2',
      },
      {
        category: 'widget.data',
        componentName: 'NumberInput',
        label: { formatted: 'widget.deviation' },
        dataKey: 'deviation',
      },
      {
        category: 'widget.data',
        componentName: 'GhgMinMaxColorRangeSlider',
        label: { formatted: 'widget.colors' },
        dataKey: 'colors',
        componentProps: {},
      },
      {
        category: 'widget.interval',
        dataKey: '',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          dataKey: 'timeRange',
          datapointsDataKey: 'datapoints',
          range: true,
          reportingYear: true,
        },
      },
      {
        category: 'widget.interval',
        label: { formatted: 'widget.comparisonPeriod', tagName: 'h4' },
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          range: true,
          dataKey: 'comparisonPeriod',
          datapointsDataKey: 'comparators',
          reportingYear: true,
        },
        dataKey: '',
      },
      {
        category: 'widget.emissionFactor',
        componentName: 'EmissionFactorSelector',
        label: { formatted: 'widget.emissionFactor' },
        dataKey: 'emissionFactor',
      },
      {
        category: 'widget.advanced',
        label: { formatted: 'widget.unit' },
        dataKey: 'returnUnitId',
        componentName: 'UnitPicker',
        componentProps: {
          startUnit: 'kgCO2e',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
