import { motion } from 'framer-motion'
import styled from 'styled-components'

const CookieBannerStyled = styled(motion.div)`
  background: ${({ theme }) => theme.color.backgroundFront};
  position: fixed;
  z-index: 1000;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.panelElevationBottom};

  .CookieBanner__buttons {
    display: flex;

    .Button {
      margin-right: 1rem;
    }
  }
`
export default CookieBannerStyled
