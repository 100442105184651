import React, { useState } from 'react'

import ScrollbarStyled from 'components/LayoutComponents/Scrollbar/styled'

const Scrollbar = ({ children, className, color }) => {
  const [scroll, setScroll] = useState(false)
  return (
    <ScrollbarStyled
      className={`Scrollbar ${className}`}
      color={color}
      scroll={scroll ? 'srcoll' : undefined}
      onScrollStart={() => setScroll(true)}
      onScrollStop={() => setScroll(false)}
      scrollDetectionThreshold={1000}
    >
      {children}
    </ScrollbarStyled>
  )
}

export default Scrollbar
