import React, { createContext, useContext, useReducer } from 'react'

import reducer, { initialState } from './reducer'

export const ListDetailContext = createContext(null)

export const useListDetail = () => useContext(ListDetailContext) || { state: { detailStack: [] } }

const ListDetailProvider = ({ children, detailWidth }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, detailWidth })

  const action = (action) => {
    switch (action.type) {
      default:
    }
    dispatch(action)
  }

  return (
    <ListDetailContext.Provider value={{ state, action }}>{children}</ListDetailContext.Provider>
  )
}

export default ListDetailProvider
