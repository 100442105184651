import styled from 'styled-components'

import Selector from '../Selector/styled'

const DecreaseConfiguratorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .Input {
    width: 100%;
  }
  ${Selector} {
    margin-bottom: 1rem;
  }

  .FormField {
    width: 100%;
    &.Switch {
      width: 50%;
      max-width: 50%;
    }
  }
`
export default DecreaseConfiguratorStyled
