import React from 'react'

import { Controller } from 'react-hook-form'

import Search from 'components/Form/components/Search'
import SearchFieldStyled from 'components/Form/fields/SearchField/styled'

const SearchField = ({ name, defaultValue, disabled, onChange, ...props }) => {
  return (
    <SearchFieldStyled {...props}>
      {({ control }) => {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange: fromFieldOnChange }) => {
              const handleChange = ({ value }) => {
                fromFieldOnChange(value)
                onChange && onChange(value)
              }
              return <Search onSearch={handleChange} />
            }}
          />
        )
      }}
    </SearchFieldStyled>
  )
}

export default SearchField
