import { cloneDeep, isEmpty, isEqual, pickBy, uniqWith } from 'lodash'

import { ICON_MAPPING, ICON_STRING_MAPPING, TYPE_ORDER } from 'util/constants'

export const RESERVED_TAGS = [
  'geoCoord',
  'influxTag',
  'mqttTag',
  'point',
  'equip',
  'kind',
  'customerRef',
  'siteRef',
  'buildingRef',
  'floorRef',
  'roomRef',
  'equipRef',
  'applicationKey',
  'deviceEUI',
  'deviceName',
  'geoCountry',
  'geoCity',
  'geocoord_latitude',
  'geocoord_longitude',
  'geoPostalCode',
  'geoLoaction',
  'geoStreet',
  'TTIUrl',
  'ttnApplication',
  'user',
  'localPassword',
  'package',
  'rootPassword',
  'subnet',
  'port',
  'catchReference',
  'externalReference',
  'guid',
  'ipAddress',
  'password',
  'clientSecret',
]

// Builds treenodes from entity input
export const treeify = (node, parentPermission = null, references) => ({
  key: node.id,
  title: node.name,
  type: node.type,
  icon: ICON_MAPPING[node.type],
  iconString: ICON_STRING_MAPPING[node.type],
  tags: pickBy(node.tags, (v, k) => !k.endsWith('Ref')),
  refs: { ...references },
  children: node.children?.map((child) =>
    treeify(child, parentPermission, { ...references, [`${node.type}Ref`]: node.id })
  ),
  selectable: node.type !== 'point',
  parentPermission,
})

export const buildParentRelations = (map) => {
  let types = []
  map.forEach((typeMap, index) => {
    if (!isEmpty(typeMap)) {
      types.push(TYPE_ORDER[index])
    }
  })

  const newMap = map.filter((typeMap) => !isEmpty(typeMap))

  // Loop over all entity types and loop over their entities each
  // Note we are looping over entity types TWICE
  for (let i = types.length - 1; i >= 1; i--) {
    Object.values(newMap[i]).forEach((entity) => {
      // Select the parent tag we need (for site this is customer, etc..)

      const parentRefTag = `${types[i - 1]}Ref`
      const parentRef = entity.tags[parentRefTag]

      // Select the parent from the entities
      const parent = newMap[i - 1][parentRef]

      // If parent is valid, assign the current entity to their children
      if (!parent) {
        console.warn(`${entity.id} has incorrect parentRef '${parentRef}' for '${parentRefTag}'`)
        return
      }
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(entity)
    })
  }
  return newMap
}

// Traverses given tree schema and returns the node with id
export const findTreeNodeById = (schema, id) => {
  const result = schema.find((node) => node?.key === id)
  if (result) {
    return result
  }

  const children = schema.flatMap((node) => node?.children).filter(Boolean)
  if (children.length) {
    return findTreeNodeById(children, id)
  }

  return undefined
}

// sorts tags by their keys, then by their value
export const tagCompare = (tagA, tagB) => {
  const keyA = tagA[0].toLowerCase()
  const valueA = tagA[1]

  const keyB = tagB[0].toLowerCase()
  const valueB = tagB[1]

  if (keyA > keyB) {
    return 1
  }
  if (keyA < keyB) {
    return -1
  }
  if (keyA === keyB) {
    if (valueA > valueB) {
      return 1
    }
    if (valueA < valueB) {
      return -1
    }
    return 0
  }
  return 0
}

// returns list of unique tags from selected node and all its children
export const getUniqueTags = (
  node,
  fromEntityTypes = ['customer', 'site', 'building', 'floor', 'room', 'equip', 'point'],
  numberValuesOnly
) => {
  const uniqueTags = fromEntityTypes.includes(node.type)
    ? Object.entries(node.tags).filter(
        ([tagName, tagValue]) =>
          (!numberValuesOnly || typeof tagValue === 'number') && !RESERVED_TAGS.includes(tagName)
      )
    : []
  let childTags = []

  if (node.children) {
    childTags = node.children.flatMap((child) =>
      getUniqueTags(child, fromEntityTypes, numberValuesOnly)
    )
  }
  return uniqWith([...uniqueTags, ...childTags], isEqual)
}

// Performs a serie of logic to generate tree structure from a flat list of entities
export const generateTree = (entities, parentPermission = null) => {
  if (!entities) {
    return []
  }
  // group entities by type and assign children
  const groupedRelations = buildParentRelations(
    cloneDeep(
      TYPE_ORDER.map((entityType) =>
        [...entities]
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .filter(({ type }) => type === entityType)
          .reduce((res, next) => {
            res[next.id] = next
            return res
          }, {})
      )
    )
  )
  if (!groupedRelations.length) {
    return []
  }
  return Object.values(groupedRelations[0]).map((customer) => treeify(customer, parentPermission))
}
