import { motion } from 'framer-motion'
import styled from 'styled-components'

const LoaderStyled = styled(motion.div)`
  position: ${({ fullscreen }) => (fullscreen ? 'fixed' : 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Spinner {
    z-index: 50;
  }
`
export default LoaderStyled

export const Overlay = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.7;
  position: absolute;
`
