import React from 'react'

import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import About from 'components/About'
import { CanaryModal } from 'components/Global/Modal'

const StyledCanaryModal = styled(CanaryModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export const AboutModal = ({ intl, onClose }) => {
  return (
    <StyledCanaryModal
      title={intl.formatMessage({ id: 'versions' })}
      visible
      footer={null}
      onClose={onClose}
      onCancel={onClose}
    >
      <About />
    </StyledCanaryModal>
  )
}

export default injectIntl(AboutModal)
