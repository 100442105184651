import { useCallback, useEffect, useMemo, useState } from 'react'

import axios from 'axios'

import { coreConfig } from 'services/store'

const apiUrls = {
  core: process.env.REACT_APP_CORE_URL,
  sust: process.env.REACT_APP_SUST_URL,
}

export const queryData = ({
  api = 'core',
  service = 'datapoints',
  payload,
  method,
  ...options
}) => {
  const coreconfig = coreConfig()
  const url = apiUrls[api]

  const config = {
    method: method ?? 'post',
    url: `${url}${service}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + coreconfig.token,
    },
    data: JSON.stringify(payload),
    ...(options || {}),
  }

  return axios(config)
}

export const useQueryData = ({ api, service, payload, deps, skip = false, method }) => {
  const [data, setData] = useState()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const queryPayload = useMemo(
    () => payload,
    // eslint-disable-next-line
    deps
  )

  const refetch = useCallback(
    (cache = true, socketValue = null) => {
      let timer
      if ((queryPayload || method === 'GET') && !skip) {
        setLoading(true)
        if (!cache) {
          queryPayload.cache = false
          if (socketValue) {
            queryPayload.socketValue = socketValue
          }
        }

        queryData({ api, service, payload: queryPayload, method })
          .then(function (response) {
            setData(response.data)
            setError(response.data.error)
            clearTimeout(timer)
          })
          .catch(function (error) {
            if (error.message === 'Network Error') {
              timer = setTimeout(() => refetch(), 2000)
            } else {
              setError({ message: error.message, level: 'error' })
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [queryPayload, service, skip, api, method]
  )

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayload /*, service*/])

  return { data, error, loading, refetch }
}
