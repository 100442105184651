import React from 'react'

import IframeResizer from 'iframe-resizer-react'
import { ButtonContainer } from 'pages/sustainability/components/Modules/components/Ghg/scenes/GhgDetail/components/HistoricalGrid/components/ReportExport/styled'

import { ButtonField } from 'components/Form'

import OfficeViewerStyled from './styled'

const OfficeViewer = ({ url }) => {
  return (
    <OfficeViewerStyled className="OfficeViewer">
      <IframeResizer
        title="Preview"
        src={'https://view.officeapps.live.com/op/embed.aspx?src=' + url}
        width="900px"
        height="400px"
        style={{ minWidth: '100%' }}
      />
      <ButtonContainer>
        <ButtonField
          type="primary"
          value={'https://view.officeapps.live.com/op/view.aspx?src=' + url}
          target="_blank"
          color="action"
        >
          View Report
        </ButtonField>
        <ButtonField type="default" size="small" value={url} target="_blank" color="action">
          Download
        </ButtonField>
      </ButtonContainer>
    </OfficeViewerStyled>
  )
}

export default OfficeViewer
