import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { Image } from 'antd'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

import fileUrl from 'util/fileUrl'
import imageStyleUrl from 'util/imageStyleUrl'

import Button from 'components/Global/Button'
import Icon from 'components/Global/Icon'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

import { ICON_STRING_MAPPING } from '../../../../util/constants'
import { detailFields } from '../../../Dashboard/Widget/config/selections'
import Slider from '../../../Global/Slider'
import Field from './Detail/Field'
import { DetailContainer, DetailStyled, TagStyled } from './styled'

export const hiddenTags = ['degreeDayReference', 'geoCountry']

const Detail = ({
  caption,
  files = [],
  name,
  title,
  icon,
  line,
  color,
  fontColor,
  size,
  layout,
  loading,
  styleProps = {},
  tags,
  markers,
  __typename,
  className,
  index,
  hiddenFields = [],
  url,
  target,
  ...detail
}) => {
  const container = useRef({})
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 })
  useLayoutEffect(() => {
    setTimeout(() => {
      if (container.current?.clientHeight) {
        setSize({
          width: container.current.clientWidth,
          height: container.current.clientHeight,
        })
      }
    }, 800)
  }, [container.current?.clientHeight])

  const hasCaptionComponent = name || icon

  const { filesArray, imagesArray } = useMemo(() => {
    if (files.length) {
      return files.reduce(
        (files, { file }) => {
          if (!hiddenFields.includes(`files.${file.key}`)) {
            if (file.mimetype?.startsWith('image')) {
              files.imagesArray.push(file)
            } else {
              files.filesArray.push(file)
            }
          }
          return files
        },
        { filesArray: [], imagesArray: [] }
      )
    }
    return { filesArray: [], imagesArray: [] }
  }, [files, hiddenFields])

  return (
    <DetailStyled
      {...styleProps}
      className={`Detail  ${files.length > 0 ? 'has-files' : ''}`}
      img={icon && icon.includes('.')}
      color={color}
      fontColor={fontColor}
      line={line}
      size={size}
      ref={container}
      width={width}
      height={height}
      layout={layout}
      informationWidget
      hasIcon={!!icon}
      caption={caption}
    >
      <DetailContainer
        className="InformationContainer"
        variant={styleProps.variant}
        hasLabel
        color={color}
        fontColor={fontColor}
        hasIcon={!!icon}
        caption={caption}
        line={line}
        informationWidget
        {...(url && {
          url,
          target,
          as: Button,
        })}
      >
        {hasCaptionComponent && (
          <div className="Information__caption">
            <div className="Information__title-container">
              <Icon icon={icon || ICON_STRING_MAPPING[detail.type]} />
              <p className="Title">{title || <FormattedMessage id={`widget.${detail.type}`} />}</p>
              {name && <p className="Title__value">{name}</p>}
            </div>
          </div>
        )}
        <div className="DetailContainer__content">
          <div className="DetailContainer__content-wrapper">
            <div className="DetailContainer__fields">
              {detailFields.map(({ label, dataKey }) => {
                const fieldValue = get(detail, dataKey)
                const hidden = hiddenFields.includes(dataKey)
                return hidden ? null : <Field value={fieldValue} label={label} dataKey={dataKey} />
              })}
            </div>
            <div className="DetailContainer__fields tags">
              {tags?.reduce((tags, { name, value }) => {
                const hidden = hiddenFields.includes(`tags.${name}`) || hiddenTags.includes(name)
                if (!hidden) {
                  tags.push(<Field value={value} label={{ text: name }} />)
                }
                return tags
              }, [])}
            </div>
            <div className="DetailContainer__fields markers">
              {markers?.map(({ name, value }) => {
                const hidden = hiddenFields.includes(`markers.${name}`) || value !== '1'
                return hidden ? null : <TagStyled>{name}</TagStyled>
              })}
            </div>
          </div>
          {files.length > 0 && (
            <div className="DetailContainer__content-wrapper files">
              {imagesArray.length > 0 && (
                <div className="DetailContainer__images">
                  <Slider
                    slides={imagesArray.map((image) => ({
                      component: Image,
                      componentProps: {
                        key: image.key,
                        src: imageStyleUrl(image.key, 'large'),
                      },
                    }))}
                    nav
                  />
                </div>
              )}
              {filesArray.length > 0 && (
                <div className="DetailContainer__files">
                  {filesArray.map((file) => (
                    <Button
                      key={file.key}
                      target="_blank"
                      url={fileUrl(file.key, 'large')}
                      label={file.name}
                      icon="fas fa-file-pdf"
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </DetailContainer>
      <WidgetLoader {...loading} loading={!!loading} spinner={false} />
    </DetailStyled>
  )
}

export default Detail
