import React from 'react'

import DashboardSearchResult from './types/DashboardSearchResult'
import MenuItemSearchResult from './types/MenuItemSearchResult'

export const MenuResultItem = ({ data, selected, clickRef, ...props }) => {
  switch (data.__typename) {
    case 'MenuItemSearchResult':
      return (
        <MenuItemSearchResult
          data={data}
          selected={selected}
          clickRef={selected && clickRef}
          {...props}
        />
      )
    case 'DashboardSearchResult':
      return (
        <DashboardSearchResult
          data={data}
          selected={selected}
          clickRef={selected && clickRef}
          {...props}
        />
      )
    default:
      return <div {...props}>{data.caption}</div>
  }
}

export default MenuResultItem
