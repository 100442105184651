export default {
  'settings.menu.users': 'User settings',
  'settings.menu.users.users': 'Users & companies',
  'settings.menu.users.roles': 'Roles',
  'settings.menu.data': 'Data settings',
  'settings.menu.data.datapoints': 'Datapoints',
  'settings.menu.data.environmentSetup': 'Environment Setup',
  'settings.menu.data.rules': 'Rules',
  'settings.menu.data.flows': 'Flows',
  'settings.menu.data.import': 'Import',
  'settings.menu.data.importETL': 'Import (ETL)',
  'settings.menu.data.reportTemplates': 'Document templates',
  'settings.menu.data.organisation': 'Organisation',
  'settings.menu.data.conversionFactors': 'Conversion factors',
  'settings.menu.data.dataManagement': 'Data management',
  'settings.menu.admin': 'Canary admin',
  'settings.menu.admin.customers': 'Customers',
  'settings.menu.admin.entityTypeManager': 'Entity types',
  'settings.menu.admin.fixedFiles': 'Fixed files',
  'settings.menu.admin.fixedFileConfiguration': 'Fixed file configuration',
  'settings.menu.admin.menu': 'Menu',
  'settings.menu.admin.logging': 'Logging',
  'settings.menu.invoicing': 'Invoicing & Setting',
  'settings.menu.invoicing.customer': 'Customer Details',

  // Datapoints
  'settings.trendline.title': 'Trendline',
  'settings.loading': 'Loading...',
  'settings.id': 'id',
  'settings.noData': 'No data',
  'settings.showAll': 'Show all',
  'settings.actualValue': 'Actual value',
  'settings.created': 'Created',
  'settings.updated': 'Updated',
  'settings.timestamp': 'Timestamp',
  'settings.status': 'Status',
  'settings.equipment': 'Equipment',
  'settings.tagsFields': 'Tags & fields',
  'settings.actions': 'Actions',
  'settings.description': 'Description',
  'settings.markers': 'Markers',
  'settings.tags': 'Tags',
  'settings.datapoints': 'Datapoints',
  'settings.filesRemarks': 'Files & remarks',
  'settings.unit': 'Unit',
  'settings.entityPreset': 'Entity preset',
  'settings.entities.equipmentMoveFailed': 'Equipments can only be moved to a room',
  'settings.entities.equipmentMoveSucces': ' has been moved to room ',
  'settings.entities.chooseLocation': 'Choose a location',
  'settings.entities.deleteEntity': 'Delete entity',
  'settings.entities.duplicateEntity': 'Duplicate entity',
  'settings.catches.deleteCatch': 'Delete catch',
  'settings.entities.entity': 'Entity',
  'settings.entities.department': 'Department',
  'settings.rules': 'Rules',
  'settings.rules.curErr.name': 'Current Error',
  'settings.rules.heartbeat.name': 'Heartbeat',
  'settings.rules.minVal.name': 'Minimum Value detection',
  'settings.rules.maxVal.name': 'Maximum Value detection',
  'settings.rules.filter.name': 'Filter Maintenance Alarm',
  'settings.rules.fireAlarm.name': 'Fire Alarm',
  'settings.rules.outDoorTempDiff.name': 'Outdoor Temperature Difference',
  'settings.rules.switchActions.name': 'Maximum Switch activity',
  'settings.rules.co2LevelCheck.name': 'CO2 Level detection',
  'settings.rules.ReportingPeriodControlYearly.name': 'ReportingPeriodControlYearly',
  'settings.rules.ReportingPeriodControlQuarterly.name': 'ReportingPeriodControlQuarterly',
  'settings.rules.ReportingPeriodControlMonthly.name': 'ReportingPeriodControlMonthly',
  'settings.rules.ReportingPeriodControlWeekly.name': 'ReportingPeriodControlWeekly',
  'settings.rules.ReportingPeriodControlDaily.name': 'ReportingPeriodControlDaily',
  'settings.rules.ReportingPeriodControlHourly.name': 'ReportingPeriodControlHourly',
  'settings.rules.leakage.name': 'Leakage detection',
  'settings.rules.overflow.name': 'Overflow detection',
  'settings.rules.curErr.fileName': 'curErr.png',
  'settings.rules.heartbeat.fileName': 'heartbeat.png',
  'settings.rules.minVal.fileName': 'minVal.png',
  'settings.rules.maxVal.fileName': 'maxVal.png',
  'settings.rules.filter.fileName': 'filter.png',
  'settings.rules.fireAlarm.fileName': 'fireAlarm.png',
  'settings.rules.outDoorTempDiff.fileName': 'outDoorTempDiff.png',
  'settings.rules.switchActions.fileName': 'switchActions.png',
  'settings.rules.co2LevelCheck.fileName': 'co2LevelCheck.png',
  'settings.rules.ReportingPeriodControlYearly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlQuarterly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlMonthly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlWeekly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlDaily.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlHourly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.leakage.fileName': 'leakage.png',
  'settings.rules.overflow.fileName': 'overflow.png',
  'settings.rules.curErr.description':
    'The system monitors the datapoint value and generates a squeal as soon as value 1 is reached.',
  'settings.rules.heartbeat.description':
    'The system checks the life status of a device according to its heartbeat. If this heartbeat is missing during the time period below the system will generate a squeal.',
  'settings.rules.minVal.description':
    'The system will monitor that the value is not lower than the minimum value below. If the value is between the minimum and the critical value, a linear value of the squeal is calculated. If the value is below the critical minimum value, the generated squeal is at 100%.',
  'settings.rules.maxVal.description':
    'The system will monitor that the value is not higher than the maximum value below. If the value is between the maximum and the critical value, a linear value of the squeal is calculated. If the value is above the critical maximum value, the generated squeal is at 100%.',
  'settings.rules.filter.description':
    'The system will monitor the datapoint value and generate a squeal if the filter maintenance value is reached.',
  'settings.rules.fireAlarm.description':
    'The system will generate a squeal as soon as a potential fire state state value has been received.',
  'settings.rules.outDoorTempDiff.description':
    'The system will monitor the difference between the OpenWeather temperature and the measured outdoor temperature of a local thermometer. When the difference is higher than 5 degrees a Squeal is generated.',
  'settings.rules.switchActions.description':
    'The system will count how many times a switch is triggered. As soon as the maximum number below is reached a squeal will be triggered.',
  'settings.rules.co2LevelCheck.description':
    'The system will monitor that the CO2 value is not higher than the maximum value (900 ppm). If the value is between the maximum and the critical value (1200ppm), a linear value of the squeal is calculated. If the value is above the critical maximum value, the generated squeal is at 100%.',
  'settings.rules.ReportingPeriodControlYearly.description': ' ',
  'settings.rules.ReportingPeriodControlQuarterly.description': ' ',
  'settings.rules.ReportingPeriodControlMonthly.description': ' ',
  'settings.rules.ReportingPeriodControlWeekly.description': ' ',
  'settings.rules.ReportingPeriodControlDaily.description': ' ',
  'settings.rules.ReportingPeriodControlHourly.description': ' ',
  'settings.rules.leakage.description':
    'The system will monitor for the period below during the check period below that the value is not lower than the minimum value below. If the value is between the minimum and the critical value, a linear value of the squeal is calculated. If the value is below the critical minimum value, the generated squeal is at 100%.',
  'settings.rules.overflow.description':
    'The system will monitor for the period below during the check period below that the value is not higher than the maximum value below. If the value is between the maximum and the critical value, a linear value of the squeal is calculated. If the value is above the critical maximum value, the generated squeal is at 100%.',

  // ETL

  'settings.menu.etl.management': 'ETL Management',

  // Organisations

  'settings.menu.organisation': 'Organisation settings',
  'settings.menu.organisation.management': 'Organisation management',
  'settings.menu.organisation.structure': 'Organisation structure',
  'settings.menu.organisation.environment': 'Organisation environment',

  // Invoice & Settings
  'settings.menu.invoiceSettings': 'Invoicing & Settings',
  'settings.menu.invoiceSettings.products': 'My Products',
  'settings.menu.invoiceSettings.licenses': 'Licenses',
  'settings.menu.invoiceSettings.customer': 'Customer details',
  'settings.menu.invoiceSettings.graphics': 'Graphics',
  'settings.menu.invoiceSettings.marketplace': 'Marketplace',
  'settings.menu.invoiceSettings.activity': 'User activity',

  // Conversion Factors

  'settigns.conversionFactors': 'Conversion factors',
  'settigns.conversionFactors.create': 'Create conversion factor',
  'settigns.conversionFactors.startUnit': 'Start Unit',
  'settigns.conversionFactors.endUnit': 'End Unit',
  'settigns.conversionFactors.multiplyFactor': 'Multiply factor',
  'settigns.conversionFactors.readOnly': 'Read only',
  'settigns.conversionFactors.country': 'Country',
  'settigns.conversionFactors.startDate': 'Start date',
  'settigns.conversionFactors.example': 'Example',
  'settigns.conversionFactors.operation': 'Operation',
  'settigns.conversionFactors.entity': 'Entity',
  'settigns.conversionFactors.energyComposition': 'Energy composition',

  // Entities
  'settings.entities.title': 'Env. Setup',

  // Entity categories
  'settings.entity.category.assets': 'Assets',
  'settings.entity.category.persona': 'Persona',
  'settings.entity.category.vehicles': 'Vehicles',
  'settings.entity.category.finance': 'Finance',
  'settings.entity.category.varia': 'Varia',
  'settings.entity.category.valueChain': 'Upstream/Downstream Activities',
  'settings.entity.category.degreeDays': 'Equivalent Degree Days',

  // User overview
  'settings.users.inviteCompany': 'Invite a company.',
  'settings.users.manageInvites': 'Manage unsent invites',
  'settings.users.inviteUser': 'Invite a user.',
  'settings.users.users': 'Users',
  'settings.users.companies': 'Companies',
  'settings.users.pending': 'Pending',
  'settings.users.active': 'Active',
  'settings.users.inActive': 'Inactive',
  'settings.users.delete': 'Cancel invite',
  'settings.users.renew': 'Renew invite',
  'settings.users.inviteCancelled': 'Invite cancelled',
  'settings.users.inviteRenewed': 'Invite renewed',
  'settings.users.inviteFailed': 'Invite failed',
  'settings.users.sendInvite': 'Send invite',
  'settings.users.editInvite': 'Edit invite',
  'settings.users.sendInviteConfirm': 'Send invite now?',
  'settings.users.editInviteConfirm': 'Edit this invite?',

  // User detail
  'settings.userDetail.noRolesFound': 'No Roles found',

  // Invite a company modal
  'settings.inviteCompany.success': ' has been invited!',
  'settings.inviteCompany.failed': ' could not be invited',
  'settings.inviteCompany.title': 'Invite a company',

  // Edit a company modal
  'settings.editCompany.title': 'Edit company access',
  'settings.editCompany.failed': 'Company access could not be modified',
  'settings.editCompany.success': 'Company access modified!',

  // Invite a user modal
  'settings.inviteUser.title': 'Invite user',
  'settings.inviteUser.success': 'User has been invited!',
  'settings.inviteUser.saved': 'Invite saved.',
  'settings.inviteUser.bulkSuccess': 'Selected invites have been sent!',
  'settings.inviteUser.doubleDashboards':
    'Same dashboard is assigned multiple times with different permissions',
  'settings.inviteUser.failed': 'User could not be invited',

  // Edit a user modal
  'settings.editUser.title': 'Edit user',
  'settings.editUser.failed': 'User could not be modified',
  'settings.editUser.success': 'User modified!',

  // Edit a customer modal
  'settings.editCustomer.title': 'Edit customer',
  'settings.createCustomer.title': 'Create customer',
  'settings.editCustomer.failed': 'Customer could not be modified',
  'settings.editCustomer.success': 'Customer modified!',
  'settings.editCustomer.dealerHasClients':
    'Dealer can not be deactivated as long as there are connected clients.',
  'settings.editCustomer.confirmChangeDealer':
    'Are you sure you want to change the dealer? All permissions will be transferred!',
  'settings.insertCustomer.success': 'Customer created successfully!',
  'settings.insertCustomer.failed': 'Customer could not be created.',
  'settings.editCustomer.modules': 'Modules',
  'settings.editCustomer.license': 'License',

  'settings.editCustomer.modules.audit': 'Audit',
  'settings.editCustomer.modules.audit.sdg': 'SDG Audit',
  'settings.editCustomer.modules.audit.dataReadiness': 'Data Readiness',
  'sustainability.menu.configuration': 'Configuration',
  'sustainability.menu.config.research': 'Research',
  'sustainability.menu.config.configuration-assistant': 'Configuration Assistant',
  'sustainability.menu.config.data-survey': 'Data Survey',
  'sustainability.menu.config.validation': 'Validation',
  'settings.editCustomer.modules.modules': 'Sustainability',
  'settings.editCustomer.modules.modules.csrd':
    'CSRD (Corporate Sustainability Reporting Directive)',
  'settings.editCustomer.modules.modules.esg': 'ESG (Environment Social Governance)',
  'settings.editCustomer.modules.modules.ghg': 'GHG (Greenhouse Gas Protocol)',
  'settings.editCustomer.modules.modules.gri': 'GRI (Global Reporting Initiative)',
  'settings.editCustomer.modules.modules.sbti': 'SBTi (Science Based Targets initiative)',
  'settings.editCustomer.modules.modules.sdg': 'SDG (Sustainable Development Goals)',
  'settings.editCustomer.modules.dataAccess': 'Data Access',
  'settings.editCustomer.modules.dataAccess.oData': 'OData',
  'settings.editCustomer.modules.dataAccess.restApi': 'REST API',
  'settings.editCustomer.modules.services.smsModule': 'SMS',
  'settings.editCustomer.modules.services': 'Services',
  'settings.editCustomer.modules.general': 'Varia',
  'settings.editCustomer.modules.general.inviteCompany': 'Invite Companies',

  // Edit Profile
  'settings.updateProfile.success': 'Profile updated!',
  'settings.updateProfile.failed': 'Profile update failed.',
  'settings.updateProfile.title': 'Update profile',
  'settings.updateProfile.changePassword': 'CHANGE PASSWORD (OPTIONAL)',
  'settings.updateProfile.oldPassword': 'Old password',
  'settings.updateProfile.newPassword': 'New password',
  'settings.updateProfile.confirmPassword': 'Confirm new password',
  'settings.upateProfile.UpdatePasswordIncomplete':
    'Please fill out all password fields to change password or leave them all blank',
  'settings.updateProfile.advanced.active.success': 'Your account is now in advanced mode',
  'settings.updateProfile.advanced.disabled.success': 'Your account is now in normal mode',
  'settings.updateProfile.advanced.failed': 'Error changing the advanced mode',

  // Fixed permissions
  'settings.fixedpermission.USER_MANAGEMENT': 'User management',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT': 'Datapoint management',
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT': 'Roles & permission management',
  'settings.fixedpermission.RULE_MANAGEMENT': 'Rule management',
  'settings.fixedpermission.FLOW_MANAGEMENT': 'Flow management',
  'settings.fixedpermission.PERSONA_MANAGEMENT': 'Persona management',
  'settings.fixedpermission.SUSTAINABILITY_VISITOR': 'Data Entry - Visitor',
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR': 'Data Entry - Contributor',
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR': 'Data Entry - Administrator',
  'settings.fixedpermission.ODATA_USER': 'OData user',
  'settings.fixedpermission.RESTAPI_USER': 'REST API user',
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR': 'Organisation administrator',
  'settings.fixedpermission.SQUEAL_MANAGEMENT': 'Squeal management',
  'settings.fixedpermission.IMPORT_VISITOR': 'Import visitor',
  'settings.fixedpermission.IMPORT_EXECUTOR': 'Import executor',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR': 'Import administrator',
  'settings.fixedpermission.DATA_ENTRY_MANAGEMENT': 'Data entry management',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR': 'Invoicing Administrator',
  'settings.fixedpermission.LOG_MANAGEMENT': 'Log management',

  // Fixed permission Info

  'settings.fixedpermission.USER_MANAGEMENT_INFO':
    '<strong>User management</strong>: Grants full access to Users & Companies',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT_INFO':
    '<strong>Datapoint management</strong>: Grants full access to Datapoints (Assets), Environment Setup (Assets) and Catches',
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT_INFO':
    '<strong>Roles & permission management</strong>: Grants full access to Roles',
  'settings.fixedpermission.RULE_MANAGEMENT_INFO':
    '<strong>Rule management</strong>: Grants full access to Rules',
  'settings.fixedpermission.FLOW_MANAGEMENT_INFO':
    '<strong>Flow management</strong>: Grants full access to Flows',
  'settings.fixedpermission.PERSONA_MANAGEMENT_INFO':
    '<strong>Persona management</strong>: Grants full access to Datapoints (Persona) and Environment Setup (Persona). Datapoint management permission is a prerequisite',
  'settings.fixedpermission.SUSTAINABILITY_VISITOR_INFO':
    '<strong>Data Entry - Visitor</strong>: Grants read only access to the available Sustainability modules, Data Entry dashboards, Datapoints (Vehicles & Up/Downstream Activities) and Environment Setup (Vehicles & Up/Downstream Activities). Datapoint management permission is a prerequisite for the latter',
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR_INFO':
    '<strong>Data Entry - Contributor</strong>: Grants execution access to the available Sustainability modules, Data Entry dashboards, Datapoints (Vehicles & Up/Downstream Activities) and Environment Setup (Vehicles & Up/Downstream Activities). Datapoint management permission is a prerequisite for the latter',
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR_INFO':
    '<strong>Data Entry - Administrator</strong>: Grants full access to the available Sustainability modules and Data Entry dashboards, Data management, Document templates, Datapoints (Vehicles & Up/Downstream Activities) and Environment Setup (Vehicles & Up/Downstream Activities). Datapoint management permission is a prerequisite for the latter',
  'settings.fixedpermission.ODATA_USER_INFO':
    '<strong>OData user</strong>: Allows the usage of the external OData interface',
  'settings.fixedpermission.RESTAPI_USER_INFO':
    '<strong>REST API user</strong>: Allows the usage of the external REST API interface',
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR_INFO':
    '<strong>Organisation administrator</strong>: Grants full access to Organisation settings',
  'settings.fixedpermission.SQUEAL_MANAGEMENT_INFO':
    '<strong>Squeal management</strong>: Grants full access to Squeal notification and overview',
  'settings.fixedpermission.IMPORT_VISITOR_INFO':
    '<strong>Import visitor</strong>: Grants read-only access to Import (ETL)',
  'settings.fixedpermission.IMPORT_EXECUTOR_INFO':
    '<strong>Import executor</strong>: Grants execution access to Import (ETL)',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR_INFO':
    '<strong>Import administrator</strong>: Grants full access to Import (ETL)',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR_INFO':
    '<strong>Invoicing Administrator</strong>: Grants full access to Invoicing & settings',
  'settings.fixedpermission.LOG_MANAGEMENT_INFO': `<strong>Log management</strong>: Grants access to the User Activity overview`,

  // Rule overview
  'settings.rules.active': 'ON',
  'settings.rules.inActive': 'OFF',
  'settings.rules.createRule': 'Create new rule',
  'settings.rules.ruleOverview': 'Rule overview',
  'settings.rules.ruleType': 'Type',
  'settings.rules.ruleName': 'Rule',
  'settings.rules.ruleNumberOfInstances': '# Instances',
  'settings.rules.ruleStatus': 'Status',

  // Rule instances overview
  'settings.ruleInstances.ruleInstancesOverview': 'Overview instances of rule',
  'settings.ruleInstances.viewInfo': 'INFO',
  'settings.ruleInstances.ruleInstance': 'Rule instance',
  'settings.ruleInstances.ruleName': 'Rule',
  'settings.ruleInstances.siteName': 'Site',
  'settings.ruleInstances.buildingName': 'Building',
  'settings.ruleInstances.floorName': 'Floor',
  'settings.ruleInstances.roomName': 'Room',
  'settings.ruleInstances.equipmentName': 'Equipment',
  'settings.ruleInstances.datapointName': 'Datapoint',
  'settings.ruleInstances.isActive': 'Status',

  // Flow list
  'settings.flows.flow': 'Flow',
  'settings.flows.flows': 'Flows',
  'settings.flows.active': 'ON',
  'settings.flows.inActive': 'OFF',
  'settings.flows.description': 'Description',
  'settings.flows.rule': 'Rule',
  'settings.flows.status': 'Status',

  // Fixed files
  'settings.fixedFiles.title': 'Fixed files',
  'settings.fixedFiles.selectToStart': 'Select fixed file',
  'settings.fixedFiles.header.currentFixedFile': 'Fixed file',

  // Data Import
  'settings.dataImport.title': 'Data Import',
  // Catchs
  'settings.catchs.title': 'Catches',
  'settings.catchs.name': 'Name',
  'settings.catchs.dealer': 'Dealer',
  'settings.catchs.company': 'Company',
  'settings.catchs.lastLifeSign': 'Last Life Sign',
  'settings.catchs.version': 'Version',
  'settings.catchs.load': 'Load',
  'settings.catchs.memory': 'Memory (MB)',
  'settings.catchs.configuration': 'Configuration',
  'settings.catchs.externalAccess': 'External Access',
  'settings.catchs.status': 'Status',
  'settings.catchs.open': 'Open',
  'settings.catchs.close': 'Closed',
  'settings.catchs.basicUI': 'Basic interface',
  'settings.catchs.catchUI': 'Catch interface',
  'settings.catchs.openUI': 'Open',
  'settings.catchs.installationScript': 'Installation Script',
  'settings.catchs.installation': 'Installation',
  'settings.catchs.generate': 'Generate',
  'settings.catchs.model': 'Model',
  'settings.catchs.firmware': 'Base Firmware',
  'settings.catchs.catchFirmware': 'Catch Firmware',
  'settings.catchs.load1m': 'Load 1m',
  'settings.catchs.load5m': 'Load 5m',
  'settings.catchs.load15m': 'Load 15m',
  'settings.catchs.processes': 'Processes',
  'settings.catchs.rootPassword': 'Root Password',
  'settings.catchs.localPassword': 'Local Password',
  'settings.catchs.requiredVersion': 'Required Version',

  // Data management
  'settings.importDefaultData.confirmImportWarning':
    'Are you sure you want to import default data? WARNING: all current data will be archived!',
  'settings.importDefaultData.confirmImport':
    'Are you sure you want to import and add default data?',

  // Conversion factors
  'settings.conversionFactors.stationaryCombustion': 'Conv: Stationary Combustion',
  'settings.conversionFactors.purchasedEnergy': 'Conv: Purchased Energy',
  'settings.conversionFactors.mobileCombustion': 'Conv: Mobile Combustion',
  'settings.conversionFactors.vehicleFuelEmission': 'Conv: Upstream - Vehicle fuels',
  'settings.conversionFactors.energyGenerationFuelEmission': 'Conv: Upstream - Energy fuels',
  'settings.conversionFactors.upstreamPurchasedEnergy': 'Conv: Upstream - Purchased Energy',
  'settings.conversionFactors.productTransport': 'Conv: Product Transport',
  'settings.conversionFactors.peopleTransport': 'Conv: People Transport',
  'settings.conversionFactors.transportDistributionLoss': 'Transmission & Distribution Losses',

  // Energy contracts
  'settings.energyContracts.energyContracts': 'Energy & fuel contracts',
  'settings.energyContracts.energyType.energySource': 'Energy source',
  'settings.energyContracts.energyType.renewable': 'Renewable',
  'settings.energyContracts.energyType.compositionError':
    'ERROR: total energy composition should not exceed 100%',
  'settings.energyContracts.percentage': 'Percentage',
  'settings.energyContracts.fuelType': 'Fuel type',
  'settings.energyContracts.unit': 'Unit',
  'settings.energyContracts.name': 'Contract name',

  // Purchased energy contracts
  'settings.purchasedEnergyContracts.title': 'Purchased Energy contracts',
  'settings.stationaryCombustionContracts.title': 'Stationary Combustion contracts',
  'settings.purchasedEnergyContracts.assignContract': 'Assign contract',
  'settings.purchasedEnergyContracts.model.assign.title': 'Assign new contract to {amount} items',
  'settings.purchasedEnergyContracts.model.assign.success':
    'Contract is assigned to {amount} items',

  // Persona
  'settings.persona.location': 'Person location information',
  'settings.persona.personal': 'Personal information',

  // Vehicles
  'settings.vehicles': 'Vehicle settings',

  // Dashboards
  'settings.dashboards.rolesAndPermissions': 'Roles & Permissions',
  'settings.dashboards.info': 'Info',
  'settings.dashboards.dashboards': 'Dashboards',
  'settings.dashboards.dashboards0': 'Dashboards with Visitor rights',
  'settings.dashboards.dashboards1': 'Dashboards with Editor rights',
  'settings.dashboards.dashboards2': 'Dashboards with Owner rights',
  'settings.dashboards.convDashboards': 'Conventional Dashboards',
  'settings.dashboards.successfullyDeleted': 'Dashboard successfully deleted.',
  'settings.dashboards.unsuscriptionSuccessful': 'Successfully unsuscribed.',
  'settings.dashboards.dashboardName': 'Name',
  'settings.dashboards.icon': 'Icon',
  'settings.dashboards.creator': 'Creator',
  'settings.dashboards.saved': 'Changes saved.',
  'settings.dashboards.errorSaving': 'Error saving the dashboard.',
  'settings.dashboards.confirmChangeCreator':
    'Are you sure you want to change the dashboard creator for this group?',
  'settings.dashboards.canNotSetOwner':
    'Can not assign new creator. New creator must have Owner permissions.',
  'settings.dashboards.noRightsDefined': 'Please assign rights to all subscribers.',
  'settings.dashboards.addToFavourites': 'Add to favourites',
  'settings.dashboards.masterDashboardGroup': 'Master dashboard group',
  'settings.dashboards.subscriber': 'Subscriber',
  'settings.dashboards.rights': 'Rights',
  'settings.dashboards.selectDashboards': 'Please select at least one dashboard to share.',
  'settings.dashboards.mailAdded': 'E-mail address was added to the list.',
  'settings.dashboards.unknownMail': 'E-mail address is unknown.',
  'settings.dashboards.visitor': 'Visitor',
  'settings.dashboards.visitorWithShare': 'Visitor (Allow share)',
  'settings.dashboards.editor': 'Editor',
  'settings.dashboards.owner': 'Owner',
  'settings.dashboards.conventional': 'Conventional',
  'settings.dashboards.master': 'Master',
  'settings.dashboards.pleaseSelect': 'Please select',
  'settings.dashboards.addManually': 'Enter e-mail address manually',
  'settings.dashboards.addButton': 'Add',
  'settings.dashboards.createdAt': 'Create date',
  'settings.dashboards.updatedAt': 'Last update',
  'settings.dashboards.conventionalHint':
    'These dashboards will not be updated after having been shared',
  'settings.dashboards.masterHint':
    'These dashboards will be automatically updated after having been shared',
  'settings.dashboards.delete': 'Delete',

  // Menu
  'settings.menu.title': 'Menus',
  'settings.menu.editItems': 'Edit menu items',
  'settings.menuItems.title': 'Items',

  // My Products & Licenses
  'settings.myProducts.title': 'My Products',
  'settings.licenses.title': 'Totals',
  'settings.dataUsage.title': 'Data Usage',
  'settings.dataUsage.last12Months': 'Last 12 Months',
  'settings.dataUsage.historyOfLast': 'History of last ',
  'settings.dataUsage.months': ' months',
  'settings.licenses.dataposts': 'Dataposts',
  'settings.licenses.totalDataposts': 'Total Dataposts',
  'settings.licenses.allowedDataposts': 'Max Dataposts',
  'settings.licenses.totals': 'Totals',
  'settings.licenses.numberOfUsers': 'Total Users',
  'settings.licenses.numberOfCompanies': 'Total Companies',
  'settings.licenses.numberOfOrganisations': 'Total Organisations',
  'settings.licenses.numberOfSites': 'Total Sites',
  'settings.licenses.numberOfBuildings': 'Total Buildings',
  'settings.licenses.numberOfAssets': 'Total Assets',
  'settings.licenses.numberOfPersona': 'Total Persona',
  'settings.licenses.numberOfVehicles': 'Total Vehicles',
  'settings.licenses.numberOfUpstreamDownstreamActivities': 'Total Up/Down Activities',
  'settings.licenses.numberOfDatapoints': 'Total Datapoints',
  'settings.licenses.numberOfCatches': 'Total Catches',
  'settings.licenses.totalDatapostsPrevious': 'Dataposts M-1',
  'settings.dataUsage.customer': 'Customer',
  'settings.licenses.sms': 'SMS',
  'settings.licenses.totalSms': 'SMS Total',
  'settings.licenses.totalSmssPrevious': 'SMS M-1',
  'settings.licenses.allowedSms': 'Max SMS',
  'settings.licenses.sites': 'Sites',
  'settings.licenses.maxSites': 'Max sites',
  'settings.licenses.datapoints': 'Datapoints',
  'settings.licenses.maxDatapoints': 'Max datapoints',
  'settings.licenses.users': 'Users',
  'settings.licenses.maxUsers': 'Max users',
}
