import React from 'react'

import { injectIntl } from 'react-intl'

import { DateRangeSelectorButton, DateRangeSelectorStyled } from './styled'

const DateRangeSelector = ({
  value,
  single,
  period,
  defaultPeriod,
  isDetailDashboard,
  onChange,
  intl,
  dataCy = 'DateRangeSelector',
}) => {
  const _onClick = (e, payload) => {
    e.stopPropagation()
    onChange(payload)
  }

  const options = {
    range: [
      { name: 'none', unit: 'none' },
      ...(isDetailDashboard ? [{ name: 'linkedWidget', unit: 'linked' }] : []),
      { name: 'thisDay', unit: 'day' },
      { name: 'thisWeek', unit: 'week' },
      { name: 'thisMonth', unit: 'month' },
      { name: 'thisYear', unit: 'year' },
      { name: 'pastDay', unit: 'day' },
      { name: 'past7Days', unit: 'day' },
      { name: 'past28Days', unit: 'day' },
      { name: 'yesterday', unit: 'day' },
      { name: 'dayBefore', unit: 'day' },
      { name: 'thisDayLastWeek', unit: 'day' },
      { name: 'previousWeek', unit: 'week' },
      { name: 'previousMonth', unit: 'month' },
      { name: 'previousYear', unit: 'year' },
    ],
    period: [
      { name: 'none', unit: 'none' },
      ...(isDetailDashboard ? [{ name: 'linkedWidget', unit: 'linked' }] : []),
      { name: 'thisWeek', unit: 'week' },
      { name: 'thisMonth', unit: 'month' },
      { name: 'thisYear', unit: 'year' },
      { name: 'previousWeek', unit: 'week' },
      { name: 'previousMonth', unit: 'month' },
      { name: 'previousYear', unit: 'year' },
    ],
    single: [
      { name: 'none' },
      ...(isDetailDashboard ? [{ name: 'linkedWidget', unit: 'linked' }] : []),
      { name: 'thisDay' },
      { name: 'yesterday' },
      { name: 'dayBefore' },
      { name: 'thisDayLastWeek' },
    ],
  }[period ? 'period' : single ? 'single' : 'range']

  const selectOptions = defaultPeriod
    ? options.filter(({ unit }) => unit === defaultPeriod)
    : options
  return (
    <DateRangeSelectorStyled className="DateRangeSelector">
      {selectOptions.map((button) => (
        <DateRangeSelectorButton
          {...button}
          label={intl.formatMessage({
            id: `graphComponent.presetControl.${button.name}`,
          })}
          isActive={value && value.name === button.name}
          onClick={_onClick}
          dataCy={`${dataCy}-${button.name}`}
        />
      ))}
    </DateRangeSelectorStyled>
  )
}

export default injectIntl(DateRangeSelector)
