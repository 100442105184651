import React, { useMemo } from 'react'

import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  calculatePlotBands,
  getComparatorValue,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useComparatorsPayload, useDatapointsForTimeRangePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import { chartOptions, getSerie } from 'components/Widgets/Gauge/ClassicGauge/chartOptions'
import { SolidGauge } from 'components/Widgets/Gauge/ClassicGauge/styled'

const AbsoluteGauge = ({
  dataProps,
  decimals = 2,
  datapoints,
  method,
  minComparatorsMethod,
  maxComparatorsMethod,
  conversionUnit,
  timeRange,
  comparisonPeriod,
  minComparators,
  maxComparators,
  intl,
  prop,
  preview,
  customLogic,
  newSocketValue,
  defaultCalculationMethod,
  minComparatorsCalculationMethod,
  maxComparatorsCalculationMethod,
  unit,
  colors = [
    [0, '#44bc66'],
    [0.33, '#fed33a'],
    [0.66, '#f88c1d'],
    [1, '#f14e3e'],
  ],
  comparatorUnit,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()

  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'absoluteGauge',
    },
    deps: [datapointsPayload],
    skip: datapoints.length === 0,
  })

  const {
    values: minComparatorValues,
    comparatorDatapoints: minComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints,
    comparators: minComparators,
    timeRange: comparisonPeriod || timeRange,
  })

  const { data: minComparatorsData, loading: minComparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: minComparatorDatapoints,
      widgetType: 'absoluteGaugeMinComparator',
    },
    deps: [minComparatorDatapoints],
    skip: minComparatorDatapoints.length === 0,
  })

  const {
    values: maxComparatorValues,
    comparatorDatapoints: maxComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints,
    comparators: maxComparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: maxComparatorsData, loading: maxComparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: maxComparatorDatapoints,
      widgetType: 'absoluteGaugeMaxComparator',
    },
    deps: [maxComparatorDatapoints],
    skip: maxComparatorDatapoints.length === 0,
  })

  useWidgetDataRefetch(
    refetch,
    newSocketValue,
    loading,
    dataProps?.isPreviewMode,
    dataProps?.isEditMode
  )

  const roundedMinimum = getComparatorValue({
    comparators: minComparators,
    comparatorValues: minComparatorValues,
    comparatorsData: minComparatorsData,
    method: minComparatorsMethod,
    decimals,
    calculationMethod: minComparatorsCalculationMethod,
    defaultValue: 0,
  })

  const roundedMaximum = getComparatorValue({
    comparators: maxComparators,
    comparatorValues: maxComparatorValues,
    comparatorsData: maxComparatorsData,
    method: maxComparatorsMethod,
    decimals,
    calculationMethod: maxComparatorsCalculationMethod,
    defaultValue: 100,
  })

  const validData = data?.data?.datapoints

  const { series, datapointChartProps, errors } = useMemo(() => {
    const errors = getDatapointErrors(validData)
    if (!validData?.length) {
      return dataProps?.isPreviewMode
        ? { series: error ? [] : preview?.gauge?.series, datapointChartProps: {}, errors }
        : { datapointChartProps: {}, errors }
    }

    const value = getDatapointsCalculatedValue(validData, {
      method,
      customLogic,
      decimals,
      calculationMethod: defaultCalculationMethod,
    })
    const displayUnit = validData[0].conversionFactor?.endUnit.name || validData[0].unit
    const series = [getSerie({ value, unit: displayUnit, theme }, false, localizeNumber)]

    const datapointChartProps = {
      yAxis: [
        {
          min: roundedMinimum,
          max: roundedMaximum,
          tickPositions: [roundedMinimum, roundedMaximum],
          labels: {
            formatter: function () {
              return `${localizeNumber(this.value)} ${comparatorUnit || displayUnit}`
            },
          },
          plotBands: calculatePlotBands(colors, roundedMinimum, roundedMaximum).map(
            ({ from, to, color }) => ({
              from,
              to,
              color,
              outerRadius: '100%',
              innerRadius: '78%',
              linecap: 'round',
              round: true,
            })
          ),
        },
      ],
    }
    return { series, datapointChartProps, errors }
  }, [
    validData,
    colors,
    customLogic,
    decimals,
    preview?.gauge?.series,
    roundedMinimum,
    roundedMaximum,
    dataProps?.isPreviewMode,
    method,
    error,
    theme,
    localizeNumber,
    defaultCalculationMethod,
    comparatorUnit,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })
  const options = merge({}, globalChartOptions, datapointChartProps)
  return (
    <SolidGauge
      {...props}
      className="AbsoluteGauge"
      options={options}
      loading={{
        loading: loading || minComparatorsLoading || maxComparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(AbsoluteGauge)
