import { Modal } from 'antd'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const ModalText = styled.div`
  margin: 0 auto;
  width: 100%;
  border: none;
  overflow: auto;

  li {
    list-style-type: decimal;
    font-size: ${({ theme }) => theme.text.l.size}rem;
    font-weight: ${({ theme }) => theme.text.l.weight};
    letter-spacing: ${({ theme }) => theme.text.l.spacing}px;
  }

  p {
    font-size: ${({ theme }) => theme.text.s.size}rem;
    font-weight: ${({ theme }) => theme.text.s.weight};
    letter-spacing: ${({ theme }) => theme.text.s.spacing}rem;
  }

  i {
    font-size: ${({ theme }) => theme.text.s.size}rem;
    font-weight: ${({ theme }) => theme.text.s.weight};
    letter-spacing: ${({ theme }) => theme.text.s.spacing}rem;
  }

  font-family: ${({ theme }) => theme.font.primary};
`

export const CanaryModal = styled(Modal)`
  ${({ theme }) =>
    theme.screenSize.screen.current === 's' &&
    css`
      width: auto !important;
    `}
  .ant-modal-title {
    font-size: ${({ theme }) => theme.text.xl.size}rem;
    font-weight: ${({ theme }) => theme.text.xl.weight};
    letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
  }

  .ant-input {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  }

  .ant-checkbox + span {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  }

  span {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  }

  .ant-card-head-title {
    font-size: ${({ theme }) => theme.text.l.size}rem;
    font-weight: ${({ theme }) => theme.text.l.weight};
    letter-spacing: ${({ theme }) => theme.text.l.spacing}rem;
  }

  .ant-tree-title {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  }

  .ant-typography {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
  }

  .ant-list-header {
    font-size: ${({ theme }) => theme.text.l.size}rem;
    font-weight: ${({ theme }) => theme.text.l.weight};
    letter-spacing: ${({ theme }) => theme.text.l.spacing}rem;
  }

  .ant-btn {
    font-size: ${({ theme }) => theme.text.m.size}rem;
    font-weight: ${({ theme }) => theme.text.m.weight};
    letter-spacing: ${({ theme }) => theme.text.m.spacing}rem;
    padding: 0 5px 0 5px;
  }

  .ant-tag {
    font-size: ${({ theme }) => theme.text.s.size}rem;
    font-weight: ${({ theme }) => theme.text.s.weight};
    letter-spacing: ${({ theme }) => theme.text.s.spacing}rem;
  }

  .ant-modal-header {
    padding: 24px 36px 16px 24px;
  }

  .ant-modal-footer {
    padding: 10px 22px;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-btn:not(.ant-btn-danger):not(.ant-btn-primary):hover,
  .ant-btn:not(.ant-btn-danger):focus {
    color: ${({ theme }) => lighten(0.1, theme.color.main)};
    border-color: ${({ theme }) => lighten(0.1, theme.color.main)};
  }

  .ant-modal-content {
    height: 100%;
  }

  font-family: ${({ theme }) => theme.font.primary};
`
