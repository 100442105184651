import { transparentize } from 'polished'
import styled from 'styled-components'

const InputStyled = styled.input`
  transition: all 0.3s;
  border: 1px solid ${({ theme }) => theme.color.softStroke};
  padding: 5px 13px;
  border-radius: 2px;
  background: transparent;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.color.main};
    border-right-width: 1px !important;
  }
  ::selection {
    background: ${({ theme }) => transparentize(0.8, theme.color.main)};
  }
`
export default InputStyled
