import React, { useMemo, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'

import { QUERY_NON_ASSET_ENTITIES, useActivityCategories } from 'services/entities'
import { useCurrentCustomer } from 'services/store'

import ValuePicker from 'components/Form/components/ValuePicker'
import { SearchableTree } from 'components/Global/Tree'

import EntityTreeStyled from './styled'

const UpstreamDownstreamEntityTree = ({ onChange, intl }) => {
  const customer = useCurrentCustomer()
  const { data } = useActivityCategories()
  const [entityTypeEntities, setEntityTypeEntities] = useState({})
  const [getEntityTypeEntities] = useLazyQuery(QUERY_NON_ASSET_ENTITIES, {
    onCompleted: (data) => {
      setEntityTypeEntities((state) =>
        data.nonAssetEntities.reduce(
          (map, { entityType, ...entity }) => {
            map[entityType.id] = map[entityType.id]
              ? map[entityType.id].some(({ id }) => id === entity.id)
                ? map[entityType.id]
                : map[entityType.id].concat(entity)
              : [entity]
            return map
          },
          { ...state }
        )
      )
    },
  })

  const onSelectSearchedEntity = (value, valueObject) => {
    onChange(valueObject)
  }

  const handleTreeOpenClick = async ({ __typename, children }) => {
    if (__typename === 'ActivityCategory') {
      getEntityTypeEntities({
        variables: {
          companyId: customer?.id,
          typeIds: children.map(({ id }) => id),
        },
      })
    }
  }

  const { tree, list } = useMemo(() => {
    if (data?.activityCategories) {
      return data?.activityCategories.reduce(
        (output, { entityDetails, name, direction, id, ...category }) => {
          output.tree.push({
            ...category,
            id,
            categoryId: id,
            name: `${direction.title} - ${name}`,
            children: entityDetails.map(({ entityType }) => {
              output.list.push({ ...entityType, categoryId: id })
              return {
                ...entityType,
                categoryId: id,
                children:
                  entityTypeEntities[entityType.id]?.map((entity) => {
                    output.list.push({ ...entity, categoryId: id })
                    return { ...entity, categoryId: id }
                  }) || [],
              }
            }),
          })

          output.list.push(category)
          return output
        },
        { tree: [], list: Object.values(entityTypeEntities) }
      )
    }
    return { tree: [], list: [] }
  }, [data?.activityCategories, entityTypeEntities])

  return (
    <EntityTreeStyled className="EntityTree">
      <ValuePicker
        options={list}
        placeholder={intl.formatMessage({ id: 'widget.search' })}
        search
        input
        selectionKeys={{ label: 'name', value: 'id' }}
        onChange={onSelectSearchedEntity}
      />
      <SearchableTree
        value={tree}
        level={0}
        elementHeight={30}
        show
        onButtonClick={onChange}
        onTreeOpenClick={handleTreeOpenClick}
      />
    </EntityTreeStyled>
  )
}

export default injectIntl(UpstreamDownstreamEntityTree)
