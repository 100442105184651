import { Form } from 'antd'
import styled from 'styled-components'

export const AntForm = styled(Form)`
  color: ${({ theme }) => theme.color.mainText};

  a:hover,
  a:active,
  a:focus {
    color: ${({ theme }) => theme.color.main};
  }

  .ant-form-item {
    margin: 1rem ${({ margin }) => margin || 0} 0.3rem;

    .ant-form-item-control {
      min-height: unset;
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-form-item-label {
    padding: 0 0 0;

    label {
      color: ${({ theme }) => theme.color.softText};
      font-size: 0.8rem;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  height: calc(100% - 60px);

  .form-actions {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;

    .ant-btn {
      margin-left: 1rem;
    }
  }
`
