import React, { useMemo } from 'react'

import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  calculatePlotBands,
  getComparatorCalculatedValue,
  getDataForEmissionFactor,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useLocaleNumber } from 'util/numbers'
import { useGhgData } from 'services/entities'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { useComparatorValues } from 'components/Global/FormField/Comparator'
import { getDatapointErrors } from 'components/Widgets/functions'
import { chartOptions, getSerie } from 'components/Widgets/Gauge/ClassicGauge/chartOptions'
import { SolidGauge } from 'components/Widgets/Gauge/ClassicGauge/styled'

import { getGranularity } from '../../../../Dashboard/utils/common/helpers'

const GhgAbsoluteGauge = ({
  dataProps = {},
  decimals = 2,
  datapoints,
  emissionFactor = 'total',
  method,
  minComparatorsMethod,
  maxComparatorsMethod,
  conversionUnit,
  timeRange,
  comparisonPeriod,
  minComparators,
  maxComparators,
  intl,
  prop,
  preview = { gauge: { series: [] } },
  customLogic,
  newSocketValue,
  unit,
  colors = [
    [0, '#44bc66'],
    [0.33, '#fed33a'],
    [0.66, '#f88c1d'],
    [1, '#f14e3e'],
  ],
  returnUnitId,
  defaultCalculationMethod,
  minComparatorsCalculationMethod,
  maxComparatorsCalculationMethod,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps

  const { data, error, refetch, loading } = useGhgData({
    datapoints,
    emissionFactor,
    timeRange,
    returnUnitId,
    granularity: getGranularity({
      defaultStartTime: timeRange || { preset: 'past7Days' },
      type: 'ghg',
    }),
    defaultTimeRange: { preset: 'past7Days' },
    options: {
      poll: true,
    },
    calculationMethod: defaultCalculationMethod,
    widgetType: 'ghgAbsoluteGauge',
  })
  const { data: minComparatorsData, loading: minComparatorsLoading } = useComparatorValues({
    comparator: minComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor,
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: minComparatorsCalculationMethod,
    widgetType: 'ghgAbsoluteGaugeMinComparator',
  })
  const { data: maxComparatorsData, loading: maxComparatorsLoading } = useComparatorValues({
    comparator: maxComparators,
    timeRange: comparisonPeriod,
    datapoints,
    type: 'ghg',
    emissionFactor,
    defaultTimeRange: { preset: 'past7Days' },
    returnUnitId,
    calculationMethod: maxComparatorsCalculationMethod,
    widgetType: 'ghgAbsoluteGaugeMaxComparator',
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const roundedMinimum =
    getComparatorCalculatedValue(getDataForEmissionFactor(minComparatorsData, emissionFactor), {
      method: minComparatorsMethod,
      customLogic,
      decimals,
    }) || 0

  const roundedMaximum =
    getComparatorCalculatedValue(getDataForEmissionFactor(maxComparatorsData, emissionFactor), {
      method: maxComparatorsMethod,
      customLogic,
      decimals,
    }) || 100

  const validData = data

  const { series, datapointChartProps, errors } = useMemo(() => {
    if (!validData?.length) {
      return isPreviewMode
        ? { series: preview.gauge.series, datapointChartProps: {}, errors: [] }
        : { datapointChartProps: {}, errors: [] }
    }
    const errors = getDatapointErrors(validData)
    const value = getDatapointsCalculatedValue(
      getDataForEmissionFactor(validData, emissionFactor),
      { method, customLogic, decimals }
    )
    const displayUnit = validData[0]?.returnUnit?.name || 'kgCO2e'
    const series = [getSerie({ value, unit: displayUnit, theme }, false, localizeNumber)]

    const datapointChartProps = {
      yAxis: [
        {
          min: roundedMinimum,
          max: roundedMaximum,
          tickPositions: [roundedMinimum, roundedMaximum],
          labels: {
            formatter: function () {
              return `${localizeNumber(this.value) || ''} ${displayUnit}`
            },
          },
          plotBands: calculatePlotBands(colors, roundedMinimum, roundedMaximum).map(
            ({ from, to, color }) => ({
              from,
              to,
              color,
              outerRadius: '100%',
              innerRadius: '78%',
            })
          ),
        },
      ],
    }
    return { series, datapointChartProps, errors }
  }, [
    validData,
    colors,
    customLogic,
    decimals,
    preview.gauge.series,
    roundedMinimum,
    roundedMaximum,
    isPreviewMode,
    method,
    emissionFactor,
    theme,
    localizeNumber,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })
  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <SolidGauge
      {...props}
      className="AbsoluteGauge"
      options={options}
      loading={{
        loading: loading || minComparatorsLoading || maxComparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(GhgAbsoluteGauge)
