import React, { useEffect, useState } from 'react'

import { message, Tooltip } from 'antd'
import copyToClipboard from 'copy-to-clipboard'
import { AnimatePresence } from 'framer-motion'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { VIRTUAL_ENTITY_TAG_NAME } from 'util/constants'

import Field from 'components/Form/components/Field'
import { FieldMessage } from 'components/Form/components/Field/styled'
import { MarkerListField, TagListField } from 'components/Form/fields/index'
import SwitchField from 'components/Form/fields/SwitchField'
import TextField from 'components/Form/fields/TextField'
import UnitSelectField from 'components/Form/fields/UnitSelectField'
import Icon from 'components/Global/Icon'

import {
  ClickableIdField,
  DatapointFieldContent,
  DatapointFieldHeader,
  DatapointFieldStyled,
  DetailLink,
} from './styled'

const DatapointField = ({
  value,
  name,
  filter,
  isAllOpen,
  action,
  onOpenDetails,
  intl,
  ...fieldProps
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(isAllOpen)
  const { watch, setValue, isEditMode, errors, getValues } = useFormContext()
  const [isVirtual, setIsVirtual] = useState(false)
  const myMarkers = getValues(`${name}.markers`)
  const virtual = myMarkers?.some((marker) => marker.name === VIRTUAL_ENTITY_TAG_NAME)

  useEffect(() => {
    setIsOpen(isAllOpen)
  }, [isAllOpen])

  useEffect(() => {
    if (virtual) {
      setIsVirtual(virtual)
    }
  }, [virtual])

  const fieldErrors = get(errors, name)

  const enabled = watch(`${name}.enabled`)
  const unitDisabled = watch(`${name}.unitDisabled`)
  const required = watch(`${name}.required`)
  const { entity, name: datapointName, unit } = watch(name)

  const enableDatapoint = enabled
    ? undefined
    : () => setValue(`${name}.enabled`, true, { shouldDirty: true })

  const open = isEditMode ? isOpen || enabled : isOpen

  return (
    <Field {...fieldProps}>
      <DatapointFieldStyled
        disabled={!enabled}
        error={open ? false : fieldErrors !== undefined}
        virtual={virtual}
      >
        <DatapointFieldHeader
          onClick={() => setIsOpen((state) => !state)}
          className={`${open ? 'active' : 'inactive'}`}
        >
          <SwitchField
            name={`${name}.enabled`}
            size="small"
            disabled={required}
            disableClick={!isEditMode}
            onClick={(value, e) => {
              e.stopPropagation()
              if (!value) {
                setValue(`${name}.value`, null)
              }
            }}
          />
          <TextField name={`${name}.name`} className="TagField__label" readOnly />
          {isVirtual && entity?.id && (
            <div className="DatapointField__actions">
              {/*  {entity.flowId && (
                <Icon
                  icon="fas fa-stopwatch"
                  className={`DatapointField__action ${isEditMode ? '' : 'disabled'}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    action &&
                      isEditMode &&
                      action({
                        value: {
                          flowId: entity.flowId,
                          id: entity.id,
                          name: datapointName,
                          unit,
                        },
                        componentName: 'TriggerConfiguratorPanel',
                      })
                  }}
                />
              )} */}
              <Icon
                icon="fas fa-gear"
                className={`DatapointField__action ${isEditMode ? '' : 'disabled'}`}
                onClick={(e) => {
                  e.stopPropagation()
                  action &&
                    isEditMode &&
                    action({
                      value: {
                        flowId: entity.flowId,
                        id: entity.id,
                        name: datapointName,
                        unit,
                      },
                      componentName: 'VirtualDatapointConfiguratorPanel',
                    })
                }}
              />
            </div>
          )}
          <Icon
            icon={open ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'}
            className="DatapointField__expand"
            style={
              enabled && isEditMode ? { cursor: 'not-allowed', color: theme.color.softerText } : {}
            }
          />
        </DatapointFieldHeader>
        <AnimatePresence>
          {open && (
            <DatapointFieldContent
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              exit={{ scaleY: 0 }}
            >
              <MarkerListField
                label="Markers"
                name={`${name}.markers`}
                filter={filter}
                markMissing={enabled}
                parentIsEnabled={!!enabled}
                onChange={enableDatapoint}
                isVirtual={isVirtual}
                disableList={[VIRTUAL_ENTITY_TAG_NAME]}
              />
              <TagListField
                label="Tags"
                name={`${name}.tags`}
                filter={filter}
                parentIsEnabled={!!enabled}
                onChange={enableDatapoint}
                context={fieldProps.context}
                isVirtual={isVirtual}
                disableList={[VIRTUAL_ENTITY_TAG_NAME]}
              />
              <UnitSelectField
                size="small"
                name={`${name}.unit`}
                label="settings.unit"
                validation={{ required: enabled }}
                onChange={enableDatapoint}
                disabled={unitDisabled}
              />
              <TextField
                name={`${name}.entity.id`}
                readOnly
                align="left"
                label="settings.id"
                viewRender={(value) => {
                  return (
                    <div>
                      <Tooltip title={intl.formatMessage({ id: 'clickToCopy' })} placement="bottom">
                        <ClickableIdField
                          onClick={() => {
                            copyToClipboard(value)
                            message.info(intl.formatMessage({ id: 'copiedToClipboard' }), 3)
                          }}
                        >
                          {value}
                        </ClickableIdField>
                      </Tooltip>

                      {value && !isEditMode && (
                        <DetailLink
                          href="#datapoint"
                          onClick={(e) => {
                            e.preventDefault()
                            onOpenDetails(value)
                          }}
                        >
                          <FormattedMessage id="showDetails" />
                        </DetailLink>
                      )}
                    </div>
                  )
                }}
              />
            </DatapointFieldContent>
          )}
        </AnimatePresence>

        {fieldErrors?.value && (
          <FieldMessage>
            {intl.formatMessage({ id: `form.field.error.${fieldErrors.value.type}` })}
          </FieldMessage>
        )}
      </DatapointFieldStyled>
    </Field>
  )
}

export default injectIntl(DatapointField)
