import styled from 'styled-components'

import { TextField } from 'components/Form'
import { FieldMessage, Value } from 'components/Form/components/Field/styled'

export const TitleField = styled(TextField).attrs({ align: 'left' })`
  font-size: ${({ theme }) => theme.text.xl.size}rem;
  font-weight: ${({ theme }) => theme.text.xl.weight};
  letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
  line-height: 1;
  min-height: 2.4rem;
  margin-bottom: 0.7rem !important;
  margin-right: 1rem;
  color: ${({ theme }) => theme.color.strongText};

  ${Value} {
    width: 100%;
    text-align: inherit;

    input,
    > span {
      line-height: 1;
      font-size: ${({ theme }) => theme.text.xl.size}rem;

      letter-spacing: ${({ theme }) => theme.text.xl.spacing}rem;
      padding: 0.2rem 0;
      height: 100%;
    }
  }
  ${FieldMessage} {
    position: absolute;
    top: 100%;
    left: 0;
  }
`
