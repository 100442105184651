import styled from 'styled-components'

const EntityFilePickerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  > .Icon {
    transition: opacity 0.2s ease;
    width: 120px;
    height: 120px;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.color.softStroke};
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      border-color: ${({ theme }) => theme.color.main};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  label {
    width: 100%;
  }

  .ValuePicker {
    width: 100%;
  }

  .Button {
    border: 1px solid ${({ theme }) => theme.color.softStroke};
    border-radius: 0.3rem;
    margin: 0.7rem 0.7rem 0 0;
    padding: 0.5rem;
    color: ${({ theme }) => theme.color.mainText};

    &.active {
      border-color: ${({ theme }) => theme.color.main};
      color: ${({ theme }) => theme.color.main};
    }

    .Icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`
export default EntityFilePickerStyled
