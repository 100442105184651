import React, { useState } from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'

import ValuePicker from 'components/Form/components/ValuePicker'
import { DatapointDetails } from 'components/Global/FormField/Datapoints'
import { useAccordeonContext } from 'components/LayoutComponents/Accordeon/AccordeonProvider'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import { useEntitytHover } from '../EntitiesPicker/hooks'
import MapEntitySelectorStyled from './styled'

const MapEntitySelector = ({
  dataKey = 'datapoints',
  customMarkerOption,
  selectedIndex,
  onChange,
  onClick,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()
  const { handleRecalculateHeight } = useAccordeonContext()
  const selectedDatapoints = get(elementInEditMode.preview, dataKey) || []
  const [selectMode, setSelectMode] = useState(!!selectedDatapoints.length)
  const [selectQuery, setSelectQuery] = useState({
    name: 'PERMITTED_ENTITIES',
    dataKey: 'allowedEntities',
    variables: {
      type: 'site',
    },
  })
  const handleChange = (value) => {
    onChange(
      value.map(({ tags, name, ...entity }) => ({ ...entity, ...tags })),
      dataKey
    )
  }

  const handleSelectionClick = (type) => {
    if (type === 'site') {
      setSelectQuery({
        name: 'PERMITTED_ENTITIES',
        dataKey: 'allowedEntities',
        variables: {
          type: 'site',
        },
      })
    } else {
      setSelectQuery({
        name: 'PERMITTED_GEOCOORD_EQUIPS',
        dataKey: 'allowedGeoCoordEquips',
      })
    }
    setSelectMode(true)
    handleRecalculateHeight && handleRecalculateHeight()
  }

  const onRemoveEntity = (e, v) => {
    onChange(
      selectedDatapoints.filter(({ id, geoCoord }) =>
        v.id ? v.id !== id : v.geoCoord !== geoCoord
      ),
      dataKey
    )
  }

  const handleDatapointClick = (e, { id, name, index, type }) => {
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: index,
        meta: {
          selectedDatapointType: type,
        },
        dataKey,
      },
    })
    onClick(e, { id, name, index })
  }

  const onAddCustomMarker = () => {
    onChange(
      selectedDatapoints.concat({
        id: uniqid(),
        name: intl.formatMessage({ id: 'widget.customMarker' }),
        type: 'custom',
        geoCoord: '50.8503,4.3517',
        icon: 'fas fa-map-marker-alt',
      }),
      dataKey
    )
  }

  const { hoveredElement, detail, onHover } = useEntitytHover()

  return (
    <MapEntitySelectorStyled className="MapEntitySelector">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      <div>
        {selectedDatapoints.map(({ id, name, ...option }, index) => (
          <div onMouseEnter={(e) => onHover(e, id)} onMouseLeave={(e) => onHover(e, null)}>
            <Button
              key={id}
              label={name}
              variant="tag"
              onIconClick={(e) => onRemoveEntity(e, { id, name })}
              icon={'fas fa-xmark'}
              isActive={selectedIndex === index}
              onClick={(e) => handleDatapointClick(e, { ...option, id, name, index })}
            />
          </div>
        ))}
      </div>
      <div className="MapEntitySelector__button-wrapper">
        <Button
          label={intl.formatMessage({ id: 'widget.addEntities' })}
          onClick={() => handleSelectionClick('site')}
          variant="smallButton"
        />
        {customMarkerOption && (
          <Button
            label={intl.formatMessage({ id: 'widget.addCustomMarker' })}
            onClick={onAddCustomMarker}
            variant="smallButton"
          />
        )}
        {
          <Button
            label={intl.formatMessage({ id: 'widget.addTraceableDevices' })}
            onClick={() => handleSelectionClick('traceableDevice')}
            variant="smallButton"
          />
        }
      </div>
      <ValuePicker
        value={selectedDatapoints}
        hideValue
        selectedIndex={selectedIndex}
        search={selectMode}
        query={selectQuery}
        isMultiSelect
        selectionKeys={{
          value: 'id',
          label: 'name',
        }}
        variant={'Tags'}
        valueKeys={['id', 'name', 'tags', 'type']}
        transformQuotes
        placeholder={
          selectQuery.dataKey === 'allowedEntities'
            ? intl.formatMessage({ id: 'widget.selectSite' })
            : intl.formatMessage({ id: 'widget.selectTraceableDevice' })
        }
        onChange={handleChange}
        onIconClick={onRemoveEntity}
        onHover={(event, entity) => onHover(event, entity?.id)}
      />
    </MapEntitySelectorStyled>
  )
}

export default injectIntl(MapEntitySelector)
