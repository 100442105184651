import { configFieldConvertor, removeObjectKeys } from '../../../../Dashboard/utils/common/helpers'
import { config as gauge } from './../Gauge/config'

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    ...gauge.editProps,
    name: 'widget.consumption',
    category: 'widget.gaugesIndicators',
    keywords: [...gauge.editProps.keywords, 'consumption'],
    img: '/resources/images/widgets/consumption.png',
    info: {
      'fr-FR':
        '<h2>Consommation</h2>\n' +
        "<p>Le widget Consommation affiche la consommation du mois en cours par rapport à la consommation du mois précédent. La valeur est représentée par une barre de couleur progressant autour d'un demi-cercle du vert au rouge sur l'ambre, où l'extrémité droite représente la valeur du mois précédent pour la métrique. Si la consommation du mois en cours dépasse la consommation du mois dernier, la barre de progression sera entièrement rouge et l'augmentation sera affichée sous forme de pourcentage supplémentaire par rapport au mois dernier.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '<li><strong>Couleurs</strong> Définissez les transitions entre les valeurs verte, ambre et rouge</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h5>Période de comparaison pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Modifiez l'unité de la valeur du widget</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur du widget</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Consumption</h2>\n' +
        "<p>The Consumption widget displays the consumption of the current month compared to the consumption of the previous month. The value is represented by a color bar progressing around an semi-circle from green to red over amber, where the right end represents the previous month's value for the metric. In case the current month's consumption exceeds last month's consumption, the progress bar wil be fully red and the increase wil be shown as an additional percentage compared to last month.</p>\n" +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Colors</strong> Define the transitions between green, amber and red values</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the widget value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the widget value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Verbruik</h2>\n' +
        '<p>De verbruikswidget toont het verbruik van de huidige maand in vergelijking met het verbruik van de vorige maand. De waarde wordt weergegeven door een kleurenbalk die rond een halve cirkel loopt van groen naar rood over amber, waarbij het rechteruiteinde de waarde van de vorige maand voor de statistiek vertegenwoordigt. Als het verbruik van de huidige maand hoger is dan het verbruik van de vorige maand, wordt de voortgangsbalk volledig rood en wordt de stijging weergegeven als een extra percentage ten opzichte van de vorige maand.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '<li><strong>Kleuren</strong> Definieer de overgangen tussen groene, oranje en rode waarden</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h5>Vergelijkingsperiode voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de widgetwaarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de widgetwaarde</li>\n' +
        '</ul>',
    },
    default: {
      ...removeObjectKeys(gauge.editProps.default, [
        'minComparatorsMethod',
        'maxComparatorsMethod',
        'minComparators',
        'maxComparators',
      ]),
      type: 'Consumption',
      title: 'widget.consumption',
      method: 'sum',
    },
    fields: configFieldConvertor(gauge.editProps.fields, [
      {
        selector: (field) =>
          field.componentName === 'Comparator' || field.dataKey === 'comparatorUnit',
        resolver: () => 'exclude',
      },
      {
        selector: (field) => field.componentProps?.dataKey === 'comparisonPeriod',
        resolver: (field) => ({
          ...field,
          componentProps: {
            ...field.componentProps,
            datapointsDataKey: null,
          },
        }),
      },
    ]),
  },
}

export default config
