import { format } from 'date-fns'
import { enUS, fr, nl } from 'date-fns/locale'

const locales = {
  'nl-NL': nl,
  en: enUS,
  'fr-FR': fr,
}

export const convertOffsetsToMs = (offsets) =>
  offsets?.map((offset) => {
    return offset.unit === 'year'
      ? 31536000000 * offset.value
      : offset.unit === 'month'
      ? 2592000000 * offset.value
      : offset.unit === 'week'
      ? 604800000 * offset.value
      : 86400000 * offset.value
  })

export const getLocalizedFormattedDate = (date, displayFormat, locale) =>
  format(new Date(date), displayFormat, { locale: locales[locale] })
