import styled from 'styled-components'

import Loader from 'components/Global/Loader'

const EntityTree = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  > .Tooltip {
    z-index: 100;
    margin-bottom: 0.5rem;
  }
  > .Tree {
    flex: 1;
  }
`

export const EntityTreeLoader = styled(Loader)`
  color: ${({ theme }) => theme.color.softText};
  text-transform: uppercase;
  .Icon {
    color: ${({ theme }) => theme.color.softerText};
    font-size: 2rem;
  }

  label {
    font-size: 0.7rem;
  }

  .Spinner {
    order: 1;
    margin-top: 0.5rem;
  }
`

export const CountryGroup = styled.div`
  padding: 1rem 0 0 2rem;

  .Tree {
    padding-left: 2rem;
  }
`
export const Country = styled.div`
  cursor: pointer;
  margin-bottom: 1rem;

  img {
    height: 1rem;
    margin-right: 1rem;
  }
`

export const Customer = styled.div`
  cursor: pointer;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`
export default EntityTree
