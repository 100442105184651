import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { useCompanyEntities } from 'services/entities'
import { useCurrentCustomer } from 'services/store'

import { SearchableTree } from 'components/Global/Tree'
import { generateTree } from 'components/SettingsComponents/PermissionsModal/helpers'

import EntityTreeStyled, { EntityTreeLoader } from './styled'

const EntityTree = ({ onChange, intl }) => {
  const customer = useCurrentCustomer()
  const { data: entities, loading: entitiesLoading, error: entitiesError } = useCompanyEntities(
    customer?.id
  )

  const treeifiedEntities = useMemo(() => generateTree(entities), [entities])

  if (entitiesLoading || !treeifiedEntities) {
    return (
      <EntityTreeLoader
        loading
        spinner={entitiesLoading && { type: 'lineScale', size: 10 }}
        icon="fas fa-database"
        label={intl.formatMessage({ id: 'widget.noData' })}
      />
    )
  }

  return (
    <EntityTreeStyled className="EntityTree">
      {treeifiedEntities && !entitiesError && (
        <SearchableTree
          value={treeifiedEntities}
          level={0}
          elementHeight={30}
          show
          onButtonClick={onChange}
          idKey="key"
        />
      )}
    </EntityTreeStyled>
  )
}

export default injectIntl(EntityTree)
