import React from 'react'

import { number, object, oneOfType, string } from 'prop-types'
import { injectIntl } from 'react-intl'

import { getLocalizedFormattedDate } from 'util/timeConversionFunctions'

import { DateTimeStyled } from './styled'

const DateTime = ({ value, styleProps, className, format, intl }) => {
  return (
    <DateTimeStyled className={className} {...styleProps}>
      {getLocalizedFormattedDate(value, format, intl.locale)}
    </DateTimeStyled>
  )
}

DateTime.propTypes = {
  text: oneOfType([string, number]),
  className: string,
  styleProps: object,
}

DateTime.defaultProps = {}

export default injectIntl(DateTime)
