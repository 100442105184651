import dayjs from 'dayjs'
import { compile } from 'expression-eval'
import { transparentize } from 'polished'
import uniqid from 'uniqid'

import {
  CALCULATIONS,
  DATE_FORMAT_BY_UNIT,
  DATETIME_FORMAT_MINUTE,
  HIGHCHARTS_DEFAULT_COLOR_SCHEME,
  TRANSLATED_TIME_UNITS,
} from '../../../../util/constants'
import round from '../../../../util/round'
import { getPlotLines } from '../../../Widgets/LineChart/functions'
import { getGranularityForTimeRange } from '../../components/TimePicker/functions'
import { barChartTypes, detailTypes } from '../../Widget/config/selections'
import { arrow } from './arrow'

export const customLogicRunner = (code, vars) => {
  try {
    const fn = compile(code)
    return fn(vars)
  } catch (error) {
    return 0
  }
}

export const getDefaultColorByIndex = (color, index, offset = 0) => {
  if (color) {
    return color
  }
  return HIGHCHARTS_DEFAULT_COLOR_SCHEME[(index + offset) % HIGHCHARTS_DEFAULT_COLOR_SCHEME.length]
}

const conversionCalculations = {
  divide: (value, factor) => value / factor,
  multiply: (value, factor) => value * factor,
  subtraction: (value, factor) => value - factor,
  addition: (value, factor) => value + factor,
}

export const getConversionFn = (operation) => {
  if (conversionCalculations[operation]) {
    return conversionCalculations[operation]
  }

  throw new Error(`Conversion "${operation}" does not exists`)
}

export const calculateMinMax = (series, stacking) => {
  if (!series[0]) {
    return {
      maxValue: 0,
      minValue: 0,
    }
  }

  const combinedSeries = series[0].data.map((v, index) =>
    series.map((serie) => serie.data[index]?.[1] || 0)
  )
  const max = CALCULATIONS.max(
    combinedSeries.map((timestampValues) => CALCULATIONS[stacking ? 'sum' : 'max'](timestampValues))
  )

  return {
    maxValue:
      max < 0.1 ? Math.ceil(max * 100) / 100 : max < 1 ? Math.ceil(max * 10) / 10 : Math.ceil(max),
    minValue: Math.floor(
      CALCULATIONS.min(
        combinedSeries.map((timestampValues) =>
          CALCULATIONS[stacking ? 'sum' : 'min'](timestampValues)
        )
      )
    ),
  }
}

export const getConstantsMinMax = (constants) => {
  return constants.reduce((output, { type, value }) => {
    if (type === 'yAxisMax') {
      output.yAxisMax = value
    }
    if (type === 'yAxisMin') {
      output.yAxisMin = value
    }
    return output
  }, {})
}

export const historyToHighchartsSeries = (history = []) =>
  history.reduce(
    (output, { time, value }) => {
      output.series.push([time, value])
      return output
    },
    { series: [] }
  )

export const rangesToHighchartsSeries = (
  { customLogic, multiplyFactor, operation, offset },
  ranges = []
) =>
  ranges.reduce(
    (output, [min, max]) => {
      const convertedMin = getConvertedRecord({
        record: min,
        operation,
        multiplyFactor,
        customLogic,
        offset,
      })
      const convertedMax = getConvertedRecord({
        record: max,
        operation,
        multiplyFactor,
        customLogic,
        offset,
      })

      output.series.push([convertedMin.time, convertedMin.value, convertedMax.value])
      return output
    },
    { series: [] }
  )

const getConvertedRecord = ({ record, operation, multiplyFactor, customLogic, offset }) => {
  const conversionValue = operation
    ? getConversionFn(operation)(record.value, multiplyFactor)
    : record.value

  const value = customLogic
    ? customLogicRunner(customLogic, { value: conversionValue })
    : conversionValue

  return {
    ...record,
    time: offset ? record.time + offset : record.time,
    value,
  }
}

export const getConversionHistory = (
  { customLogic, multiplyFactor, operation, offset },
  history = []
) => {
  if (!customLogic && !operation && !offset) {
    return history
  }
  return history.map((record) =>
    getConvertedRecord({ record, operation, multiplyFactor, customLogic, offset })
  )
}

export const getCalculatedHistory = (
  { customLogic, multiplyFactor, operation, offset },
  history = [],
  method = 'sum',
  decimals
) => {
  const processedHistory = getConversionHistory(
    { customLogic, multiplyFactor, operation, offset },
    history
  )
  return round(CALCULATIONS[method](processedHistory.map(({ value }) => value)), decimals)
}

export const getConstantValues = (constants, data) => {
  if (constants?.length) {
    return constants.reduce((constants, { tag, ...constant }) => {
      if (typeof constant.value === 'number') {
        constants.push(constant)
      }

      if (data && constant.value?.tagName) {
        const datapointId = constant.value.datapointId || constant.value.entityId
        const dataForConstant = data.find(
          ({ id, tag }) => tag === constant.value.tagName && id === datapointId
        )
        if (dataForConstant) {
          constants.push({
            ...constant,
            value: dataForConstant.tags[constant.value.tagName] * 1,
            tagName: constant.value.tagName,
          })
        }
      }

      return constants
    }, [])
  } else {
    return []
  }
}

const getComparator = ({ point, grouped, id, index, name, ...props }) => {
  if (typeof point === 'number') {
    return point
  }
  return {
    ...point,
    ...(grouped ? { groupId: `${id}-${index}` } : { name: `${point.name} | ${name}`, ...props }),
  }
}

export const getWidgetDatapoints = ({
  datapoints,
  comparators,
  maxComparators,
  minComparators,
  datapointsGroups,
  datapointsLinked = [],
  constants,
  locationData,
  localizeNumber,
  theme,
}) => {
  const annotations = locationData?.pointTime
    ? [
        {
          value: locationData.pointTime,
          width: 20,
          color: transparentize(0.93, locationData.pointColor || theme.color.error),
          label: {
            useHTML: true,
            rotation: 0,
            formatter: function () {
              return `<span class="x-axis-label"><span class="title">${
                locationData.title
              }</span> <span class="time">${dayjs(locationData.pointTime).format(
                DATETIME_FORMAT_MINUTE
              )}</span> <span class="value" style="background: ${
                locationData.pointColor
              };">${localizeNumber(locationData.pointValue)}<span>${
                locationData.unit || ''
              }</span></span></span>`
            },
          },
        },
        {
          value: locationData.pointTime,
          width: 1,
          color: locationData.pointColor || theme.color.error,
        },
      ]
    : []

  return datapointsLinked.reduce(
    (widgetDatapoints, { id, name, grouped, ...props }, index) => {
      if (locationData) {
        if (id === 'datapoints') {
          widgetDatapoints.datapoints.push(
            ...locationData.datapoints.map(({ groupId, ...dp }) => {
              return {
                ...dp,
                ...(grouped
                  ? { groupId: `${id}-${index}` }
                  : { ...props, name: `${dp.name} | ${name}`, color: props.color || dp.color }),
              }
            })
          )
        }
        if (id === 'comparators' && locationData.comparators) {
          const locationComparators =
            locationData.comparators === 'main' ? locationData.datapoints : locationData.comparators
          widgetDatapoints.comparators.push(
            ...locationComparators.map((dp) =>
              getComparator({
                point: dp,
                grouped,
                id,
                index,
                name,
                ...props,
              })
            )
          )
        }
        if (id === 'minComparators' && locationData.minComparators) {
          const locationComparators =
            locationData.minComparators === 'main'
              ? locationData.datapoints
              : locationData.minComparators
          widgetDatapoints.minComparators.push(
            ...locationComparators.map((dp) =>
              getComparator({
                point: dp,
                grouped,
                id,
                index,
                name,
                ...props,
              })
            )
          )
        }
        if (id === 'maxComparators' && locationData.maxComparators) {
          const locationComparators =
            locationData.maxComparators === 'main'
              ? locationData.datapoints
              : locationData.maxComparators
          widgetDatapoints.maxComparators.push(
            ...locationComparators.map((dp) =>
              getComparator({
                point: dp,
                grouped,
                id,
                index,
                name,
                ...props,
              })
            )
          )
        }
        if (id === 'linkDatapoints' && locationData.linkDatapoints) {
          widgetDatapoints.datapoints.push(
            ...locationData.linkDatapoints.map((dp) => ({
              ...dp,
              ...(grouped ? { groupId: `${id}-${index}` } : { name: dp.name, ...props }),
            }))
          )
        }
        if (id === 'pointValue') {
          widgetDatapoints.constants.push({
            ...props,
            color: props.color || locationData.pointColor,
            label: {
              text: `${locationData.title}: ${localizeNumber(locationData.pointValue)}${
                locationData.unit || ''
              }`,
              title: `${locationData.title} ${dayjs(locationData.pointTime).format(
                DATETIME_FORMAT_MINUTE
              )}`,
            },
            value: locationData.pointValue,
            unit: locationData.unit,
            type: 'fixedValue',
          })
        }
        if (grouped) {
          widgetDatapoints.datapointsGroups.push({
            id: `${id}-${index}`,
            datapoints: locationData.datapoints.map(({ id }) => id),
            name,
            ...props,
            color: props.color || locationData.pointColor,
          })
        }
      }
      return widgetDatapoints
    },
    {
      datapoints: datapoints ? [...datapoints] : [],
      comparators: comparators ? [...comparators] : [],
      minComparators: minComparators ? [...minComparators] : [],
      maxComparators: maxComparators ? [...maxComparators] : [],
      datapointsGroups: datapointsGroups ? [...datapointsGroups] : [],
      annotations,
      constants: constants ? [...constants] : [],
    }
  )
}

export const getZones = (constants, trendLineColor) => {
  const zones = constants.filter(({ value }) => typeof value === 'number')
  return zones
    .sort((a, b) => a.value - b.value)
    .reduce((zones, { type = 'max', color, value }, index) => {
      const apply = type === 'max' || type === 'min'
      if (apply) {
        const isLast = !zones.some(({ type }, i) => i > index && apply)
        zones.push({
          color: type === 'min' ? color : isLast ? trendLineColor || 'color' : color,
          value,
        })
        if (isLast && type === 'max') {
          zones.push({
            color,
          })
        }
      }
      return zones
    }, [])
}

export const translateHistoryLabel = (value, unit, locale = 'en-US') => {
  const labelUnit = value === 1 ? unit : `${unit}s`

  return `${TRANSLATED_TIME_UNITS[labelUnit][locale]} ${
    {
      'en-US': 'ago',
      'fr-FR': 'passé',
      'nl-NL': 'geleden',
    }[locale]
  }`
}

const addValue = ({ series, start, end, granularityUnit, startValue, slice, source }) => {
  const time = dayjs(start).add(1, granularityUnit).valueOf()
  const value = startValue - slice

  series.push({ time, value })
  if (time < end) {
    return addValue({ series, start: time, end, granularityUnit, startValue: value, slice, source })
  }
  return series
}

const calculateDecreaseValue = ({ startValue, startTime, time, decreaseUnit, percentage }) => {
  const timeDiff = Math.abs(dayjs(startTime).diff(dayjs(time), decreaseUnit || 'month'))
  let decreaseValue = startValue
  for (let i = 0; i < timeDiff; i++) {
    decreaseValue = decreaseValue - (decreaseValue / 100) * percentage
  }
  return decreaseValue
}

export const getTargetHistory = ({
  targetStartValue,
  targetEndValue,
  targetStart,
  targetEnd,
  targetPercentage,
  percentage = false,
  decreaseUnit = 'month',
  trendStart,
  trendEnd,
  granularityUnit,
  granularityValue,
  offset = true,
  source,
}) => {
  const trendGranularity =
    dayjs(trendStart).add(granularityValue, granularityUnit).valueOf() - trendStart
  const targetTimeDiff = targetEnd - targetStart

  const calculatedTargetEndValue = targetPercentage
    ? calculateDecreaseValue({
        startValue: targetStartValue,
        startTime: targetStart,
        time: targetEnd,
        decreaseUnit,
        percentage: targetPercentage,
      })
    : percentage
    ? (targetStartValue * targetEndValue) / 100
    : targetEndValue

  const targetValueDiff = targetStartValue - calculatedTargetEndValue
  const trendStartValue = targetPercentage
    ? calculateDecreaseValue({
        startValue: targetStartValue,
        startTime: targetStart,
        time: trendStart,
        decreaseUnit,
        percentage: targetPercentage,
      })
    : offset
    ? targetStartValue - (trendStart - targetStart) * (targetValueDiff / targetTimeDiff)
    : targetStartValue

  const trendEndValue = targetPercentage
    ? calculateDecreaseValue({
        startValue: targetStartValue,
        startTime: targetStart,
        time: trendEnd,
        decreaseUnit,
        percentage: targetPercentage,
      })
    : offset
    ? calculatedTargetEndValue - (trendEnd - targetEnd) * (targetValueDiff / targetTimeDiff)
    : targetEndValue

  const slice = (trendStartValue - trendEndValue) / (trendEnd - trendStart)
  if (isNaN(trendStartValue) || isNaN(slice)) {
    return []
  }
  let series = [{ time: trendStart, value: trendStartValue }]
  return addValue({
    series,
    start: trendStart,
    end: trendEnd,
    granularityUnit,
    startValue: trendStartValue,
    percentage,
    slice: slice * trendGranularity,
    source,
  })
}

export const getTooltipDate = ({ time, granularityUnit, granularityValue, color }) => {
  const start = DATE_FORMAT_BY_UNIT(time, granularityUnit, true)
  if (granularityValue === 1 && granularityUnit !== 'w') {
    return `<span class="tooltip__date">${granularityUnit === 'MS' ? start.slice(1) : start}</span>`
  }
  const endDate = DATE_FORMAT_BY_UNIT(
    dayjs(time).add(granularityValue, granularityUnit),
    granularityUnit,
    true
  )
  const end =
    (granularityUnit === 'h' || granularityUnit === 'm') &&
    start.substring(0, 10) === endDate.substring(0, 10)
      ? endDate.substring(11)
      : endDate
  return `<span class="tooltip__date">${start}${arrow(color)}${end}</span>`
}

export const getChartProps = (
  {
    yAxisUnits,
    constants,
    captions,
    minValue,
    maxValue,
    hideUnit,
    theme,
    granularityValue,
    granularityUnit,
    masterDetail,
    annotations,
  },
  localizeNumber = () => ''
) => {
  return {
    yAxis: Object.keys(yAxisUnits).map((unit, i) => {
      return {
        title: {
          text: yAxisUnits[unit],
        },
        gridLineColor: i === 0 ? theme.color.softStroke : 'transparent',
        opposite: i % 2 === 1,
        ...getPlotLines({
          constants,
          yAxisUnit: unit,
          formattedUnit: yAxisUnits[unit],
          i,
          ...((constants.length > 0 || masterDetail) && { minValue, maxValue }),
        }),
      }
    }),
    tooltip: {
      formatter: function () {
        const points = this.points

        if (!points) {
          return ''
        }
        return points.reduce((s, point) => {
          const yAxis = point.series.yAxis
          const unit = yAxis.axisTitle.textStr
          const seriesName = point.series.name
          const range = typeof point.point.low === 'number'

          const value = range
            ? `${localizeNumber(
                captions ? captions[point.point.low].title : point.point.low
              )} - ${localizeNumber(
                captions ? captions[point.point.high].title : point.point.high
              )}`
            : localizeNumber(captions ? captions[point.y].title : point.y)
          return `${s}
              <span class="tooltip__name">
                  <span style="background:${point.color};" class="tooltip__color"></span>
                      ${seriesName}
              </span>
              <span class="tooltip__value ${range ? 'range' : ''}">
                  ${value}
                  <span class="tooltip__unit">${hideUnit ? '' : unit}</span>
              </span>
          `
        }, getTooltipDate({ time: this.x, granularityValue, granularityUnit, color: theme.color.softText }))
      },
    },
  }
}

export const getGroupProperties = (groups = []) => {
  return groups.reduce(
    (output, { datapoints, conversionUnit, offsets, defaultCalculationMethod }) => {
      const rangeId = defaultCalculationMethod === 'range' && uniqid()
      datapoints.forEach((datapoint) => {
        output[datapoint] = {
          conversionUnit,
          offsets,
          defaultCalculationMethod,
          rangeId,
        }
      })
      return output
    },
    {}
  )
}

export const getGranularity = ({
  granularity,
  calculationMethod,
  type,
  chartType,
  defaultStartTime,
}) => {
  if (granularity) {
    return granularity
  }
  switch (type) {
    case 'ghg':
      const { defaultGranularity } = getGranularityForTimeRange(defaultStartTime)
      return chartType === 'bar'
        ? {
            m: '1h',
            h: '1d',
            d: '1m',
          }[defaultGranularity]
        : `1${defaultGranularity === 'm' ? 'h' : defaultGranularity}`
    default:
      if (calculationMethod === 'raw') {
        return undefined
      }

      if (defaultStartTime) {
        return {
          year: '1w',
          month: '1d',
          week: '1h',
          day: '1m',
        }[defaultStartTime.unit]
      }
      return '30m'
  }
}

export const getTagDatapoints = (constants) => {
  if (constants?.length) {
    return constants.reduce((datapoints, { value }) => {
      if (typeof value !== 'number' && typeof value !== 'string') {
        datapoints.push({ id: value.datapointId || value.entityId, tag: value.tagName })
      }
      return datapoints
    }, [])
  } else {
    return []
  }
}

export const getLinkedDatapoints = () => {}

const checkConditions = (field, conditions) => {
  const condition = conditions.find(({ selector }) => selector(field))
  return condition && condition.resolver(field)
}
export const configFieldConvertor = (fields, convertors) => {
  return fields.reduce((fields, field) => {
    const converted = checkConditions(field, convertors)
    if (converted) {
      if (converted === 'exclude') {
      } else if (Array.isArray(converted)) {
        fields.push(...converted)
      } else {
        fields.push(converted)
      }
    } else {
      fields.push(field)
    }
    return fields
  }, [])
}

export const ghgTrendFieldConvertors = [
  {
    selector: (field) =>
      field.dataKey === 'showTotals' || field.category === 'widget.datapointTotals',
    resolver: () => 'exclude',
  },
  {
    selector: (field) => field.componentName === 'DatapointGroups',
    resolver: (field) => ({
      ...field,
      category: 'widget.data',
      componentProps: {
        ...field.componentProps,
        bottomDrawer: 'GhgDataSelector',
        section: {
          ...field.componentProps.section,
          componentProps: {
            ...field.componentProps.section.componentProps,
            bottomDrawer: 'GhgDataSelector',
            fields: configFieldConvertor(field.componentProps.section.componentProps.fields, [
              {
                selector: (field) => field.dataKey === 'offsets',
                resolver: (field) => ({
                  ...field,
                  componentProps: {
                    ...field.componentProps,
                    columns: configFieldConvertor(field.componentProps.columns, [
                      {
                        selector: (field) => field.componentName === 'CalculationMethod',
                        resolver: (field) => ({
                          ...field,
                          componentProps: {
                            ...field.componentProps,
                            difference: false,
                            addRangeMethod: false,
                          },
                        }),
                      },
                    ]),
                  },
                }),
              },
              {
                selector: (field) => field.dataKey === 'constants',
                resolver: (field) => [
                  field,
                  {
                    category: 'widget.emissionFactors',
                    componentName: 'EmissionFactorSelector',
                    label: { formatted: 'widget.emissionFactors' },
                    dataKey: 'emissionFactors',
                    componentProps: {
                      isMultiSelect: true,
                    },
                  },
                ],
              },
              {
                selector: (field) => field.componentName === 'CalculationMethod',
                resolver: (field) => ({
                  ...field,
                  componentProps: { ...field.componentProps, addRangeMethod: false },
                }),
              },
              {
                selector: (field) => field.componentName === 'UnitPicker',
                resolver: (field) => ({
                  ...field,
                  componentProps: { startUnit: 'kgCO2e' },
                  dataKey: 'returnUnitId',
                }),
              },
            ]),
          },
        },
        groupSection: {
          ...field.componentProps.groupSection,
          componentProps: {
            ...field.componentProps.groupSection.componentProps,
            bottomDrawer: 'GhgDataSelector',
            fields: configFieldConvertor(field.componentProps.groupSection.componentProps.fields, [
              {
                selector: (field) => field.componentName === 'CalculationMethod',
                resolver: (field) => ({
                  ...field,
                  componentProps: { ...field.componentProps, addRangeMethod: false },
                }),
              },
              {
                selector: (field) => field.dataKey === 'offsets',
                resolver: (field) => ({
                  ...field,
                  componentProps: {
                    ...field.componentProps,
                    columns: configFieldConvertor(field.componentProps.columns, [
                      {
                        selector: (field) => field.componentName === 'CalculationMethod',
                        resolver: (field) => ({
                          ...field,
                          componentProps: {
                            ...field.componentProps,
                            addRangeMethod: false,
                          },
                        }),
                      },
                    ]),
                  },
                }),
              },
              {
                selector: (field) => field.dataKey === 'constants',
                resolver: (field) => [
                  field,
                  {
                    category: 'widget.emissionFactors',
                    componentName: 'EmissionFactorSelector',
                    label: { formatted: 'widget.emissionFactors' },
                    dataKey: 'emissionFactors',
                    componentProps: {
                      isMultiSelect: true,
                    },
                  },
                ],
              },
              {
                selector: (field) => field.componentName === 'UnitPicker',
                resolver: (field) => ({
                  ...field,
                  componentProps: {
                    startUnit: 'kgCO2e',
                  },
                }),
              },
            ]),
          },
        },
      },
    }),
  },
  {
    selector: (field) => field.componentName === 'DefaultStartTimeSelector',
    resolver: (field) => ({
      ...field,
      componentProps: {
        ...field.componentProps,
        reportingYear: true,
        disableMonthGranularityValue: false,
        addRangeMethod: false,
      },
    }),
  },
  {
    selector: (field) => field.dataKey === 'constants',
    resolver: (field) => ({
      ...field,
      componentProps: {
        ...field.componentProps,
        columns: configFieldConvertor(field.componentProps.columns, [
          {
            selector: (field) => field.dataKey === 'unit',
            resolver: (field) => ({
              ...field,
              componentName: 'UnitPicker',
              componentProps: {
                startUnit: 'kgCO2e',
                selectionKeys: { value: 'name', label: 'name' },
              },
            }),
          },
        ]),
      },
    }),
  },
]

export const barChartFieldConvertors = [
  {
    selector: (field) => field.dataKey === 'useSteps',
    resolver: () => 'exclude',
  },
  {
    selector: (field) => field.dataKey === 'graphOpacity',
    resolver: () => ({
      category: 'widget.general',
      componentName: 'OptionPicker',
      label: { formatted: 'widget.type' },
      dataKey: 'chartStyle',
      componentProps: {
        selectionKeys: { label: 'label', value: 'value' },
        options: barChartTypes,
      },
    }),
  },
  {
    selector: (field) => field.componentName === 'DatapointGroups',
    resolver: (field) => ({
      ...field,
      componentProps: {
        ...field.componentProps,
        section: {
          ...field.componentProps.section,
          componentProps: {
            ...field.componentProps.section.componentProps,
            fields: configFieldConvertor(field.componentProps.section.componentProps.fields, [
              {
                selector: (field) => field.dataKey === 'useSteps' || field.dataKey === 'dashStyle',
                resolver: () => 'exclude',
              },
              {
                selector: (field) => field.dataKey === 'offsets',
                resolver: (field) => ({
                  ...field,
                  componentProps: {
                    ...field.componentProps,
                    columns: configFieldConvertor(field.componentProps.columns, [
                      {
                        selector: (field) => field.dataKey === 'dashStyle',
                        resolver: () => 'exclude',
                      },
                    ]),
                  },
                }),
              },
            ]),
          },
        },
        groupSection: {
          ...field.componentProps.groupSection,
          componentProps: {
            ...field.componentProps.groupSection.componentProps,
            fields: configFieldConvertor(field.componentProps.groupSection.componentProps.fields, [
              {
                selector: (field) =>
                  field.dataKey === 'useSteps' ||
                  field.dataKey === 'dashStyle' ||
                  field.dataKey === 'constants',
                resolver: () => 'exclude',
              },
            ]),
          },
        },
      },
    }),
  },
  {
    selector: (field) => field.dataKey === 'constants',
    resolver: (field) => ({
      ...field,
      componentProps: {
        ...field.componentProps,
        columns: configFieldConvertor(field.componentProps.columns, [
          {
            selector: (field) => field.dataKey === 'type' || field.dataKey === 'apply',
            resolver: () => 'exclude',
          },
        ]),
      },
    }),
  },
]

export const generalConfigFields = [
  {
    category: 'widget.general',
    componentName: 'Input',
    label: { formatted: 'widget.label' },
    dataKey: 'title',
    validation: {
      type: 'maxLength',
      value: 50,
    },
  },
  {
    category: 'widget.general',
    componentName: 'IconPicker',
    col: 'col2 first',
    componentProps: {
      bottomDrawer: true,
    },
    label: { formatted: 'widget.icon' },
    dataKey: 'icon',
  },
  {
    category: 'widget.general',
    componentName: 'ColorPicker',
    col: 'col2',
    label: { formatted: 'widget.color' },
    dataKey: 'color',
  },
]

export const generalTrendFields = [
  {
    category: 'widget.general',
    componentName: 'Switch',
    label: { formatted: 'widget.showTotals' },
    dataKey: 'showTotals',
    col: 'col3',
  },
  {
    category: 'widget.general',
    componentName: 'Switch',
    label: { formatted: 'widget.masterDetail' },
    dataKey: 'masterDetail',
    col: 'col3 first',
  },
  {
    category: 'widget.general',
    componentName: 'Switch',
    label: { formatted: 'widget.showLegend' },
    dataKey: 'showLegend',
    col: 'col3',
  },
  {
    category: 'widget.general',
    componentName: 'Switch',
    label: { formatted: 'widget.enableExport' },
    dataKey: 'enableExport',
    col: 'col3',
  },
  {
    category: 'widget.general',
    componentName: 'Switch',
    label: { formatted: 'widget.stack' },
    dataKey: 'stacking',
    col: 'col3',
  },
  {
    category: 'widget.general',
    componentName: 'OptionPicker',
    label: { formatted: 'widget.details' },
    dataKey: 'details',
    componentProps: {
      selectionKeys: { label: 'label', value: 'value' },
      options: detailTypes,
    },
  },
  {
    category: 'widget.general',
    componentName: 'RangeSlider',
    label: { formatted: 'widget.opacity' },
    dataKey: 'graphOpacity',
    componentProps: {
      min: 0,
      max: 1,
      step: 0.1,
    },
  },
]

export const removeObjectKeys = (object, keys = []) => {
  return Object.entries(object).reduce((newObject, [key, value]) => {
    if (!keys.includes(key)) {
      newObject[key] = value
    }
    return newObject
  }, {})
}
