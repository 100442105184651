import React, { useCallback, useMemo } from 'react'

import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { useFieldArray, useFormContext } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import RuleField from 'components/Form/fields/RuleField'

import { RuleListFieldStyled } from './styled'

const RuleListField = ({
  value,
  name,
  filter,
  datapointFilter,
  format = 'PPpp',
  dependencies,
  onOpenDetails,
  intl,
  allowEdit,
  flowOptions,
  ...fieldProps
}) => {
  const { fields } = useFieldArray({
    name,
  })

  const { loading, watch, getValues } = useFormContext()
  const isAllOpen = watch('ui.datapoints.toggleExpandAll')

  const filterFn = useCallback(
    ({ item }) => {
      if (!filter) {
        return true
      }
      return filter(item, { watch, getValues })
    },
    [filter, getValues, watch]
  )

  const list = useMemo(() => {
    const data = value === undefined && name === undefined ? value : fields
    const filteredData = data.map((item, index) => ({ item, index })).filter(filterFn)

    return sortBy(filteredData, 'item.name')
  }, [value, name, fields, filterFn])

  if (!list || list.length === 0) {
    return (
      <span>
        {intl
          ? intl.formatMessage({
              id: 'componentCaptions.noRules',
            })
          : 'No rules'}
      </span>
    )
  }

  if (loading) {
    return <Skeleton width="200px" />
  }

  return (
    <RuleListFieldStyled {...fieldProps}>
      {list.map(({ index, item }) => (
        <RuleField
          key={item.id}
          name={`${name}[${index}]`}
          filter={datapointFilter}
          isAllOpen={isAllOpen}
          context={fieldProps.context}
          action={fieldProps.action}
          onOpenDetails={onOpenDetails}
          allowEdit={allowEdit}
          flowOptions={flowOptions}
        />
      ))}
    </RuleListFieldStyled>
  )
}

RuleListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      required: PropTypes.bool,
    })
  ),
}

export default RuleListField
