import { Steps } from 'antd'
import styled from 'styled-components'

export const AntSteps = styled(Steps)`
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${({ theme }) => theme.color.main};
    border-color: ${({ theme }) => theme.color.main};
  }
  &
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-title,
  &
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-subtitle,
  &
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-description {
    color: ${({ theme }) => theme.color.main};
  }
  &
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.color.main};
  }
  &
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: ${({ theme }) => theme.color.main};
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.color.main};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.color.main};
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.color.main};
  }
`
