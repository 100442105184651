import { css } from 'styled-components'

export const grid = css`
  display: grid;
  grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
  div {
    width: ${({ size }) => Math.ceil(size / 4)}px;
    height: ${({ size }) => Math.ceil(size / 4)}px;
    border-radius: 50%;
    background: ${({ theme, color }) => theme.color[color]};
    animation: grid 1.2s linear infinite;
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: -0.4s;
    }
    &:nth-child(3) {
      animation-delay: -0.8s;
    }
    &:nth-child(4) {
      animation-delay: -0.4s;
    }
    &:nth-child(5) {
      animation-delay: -0.8s;
    }
    &:nth-child(6) {
      animation-delay: -1.2s;
    }
    &:nth-child(7) {
      animation-delay: -0.8s;
    }
    &:nth-child(8) {
      animation-delay: -1.2s;
    }
    &:nth-child(9) {
      animation-delay: -1.6s;
    }
  }
  @keyframes grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`
