import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react'

import { ConfigProvider, message } from 'antd'
import { isEmpty } from 'lodash'
import { getLuminance, setLightness, setSaturation, shade } from 'polished'
import { ThemeProvider } from 'styled-components'
import themeVariables from 'theme/variables'

import Button from 'components/Global/Button'
import { useWindowSize } from 'components/Global/hooks'
import Icon from 'components/Global/Icon'
import Modal from 'components/Layout/UiProvider/Modal'

import reducer, { initialState } from './reducer'

export const UiContext = createContext(null)
export const useUi = () => useContext(UiContext) || { state: {} }

const UiProvider = ({ children, theme }) => {
  const breakpoints = {
    s: 0,
    m: 600,
    l: 960,
  }
  const screenSize = useWindowSize(breakpoints)
  const [state, dispatch] = useReducer(reducer, { ...initialState, darkMode: theme.darkMode })

  const action = useCallback(
    (action) => {
      switch (action.type) {
        case 'SET_NOTIFICATION':
          const { type = 'info', text, ...messageProps } = action.payload.message
          message[type]({
            ...messageProps,
            icon: <Icon icon="fas fa-info" variant="notification" color={theme.colors[type]} />,
            content: (
              <>
                {text}
                <Button label="test" variant="smallButton" />
              </>
            ),
          })
          break
        default:
      }
      dispatch(action)
    },
    [theme]
  )

  const customTheme = useMemo(() => {
    const color = (!isEmpty(theme?.colors) && theme.name) || 'aviary'

    const darkMode = state.darkMode
    const colorTheme = darkMode ? 'dark' : 'light'
    const dbScheme = (!isEmpty(theme?.colors) && theme.colors) || themeVariables.color.color[color]

    const highlightLuminance = dbScheme.highlight && getLuminance(dbScheme.highlight)
    const actionLuminance = dbScheme.action && getLuminance(dbScheme.action)
    const isDarkSideBar = dbScheme.sideBar && getLuminance(dbScheme.sideBar) < 0.5
    const strongText = darkMode
      ? setLightness(0.9, shade(0.9, setSaturation(0.1, dbScheme.main)))
      : '#353535'

    return {
      ...themeVariables,
      black: '#000000',
      white: '#FFFFFF',
      disabled: '#EEEEEE',
      color: {
        ...themeVariables.color[colorTheme],
        ...dbScheme,
        action: dbScheme.action || dbScheme.main,
        backgroundHighlight: darkMode
          ? setLightness(0.18, shade(0.85, setSaturation(0.08, dbScheme.main)))
          : setLightness(0.98, shade(0.91, setSaturation(0.25, dbScheme.main))),
        backgroundBack: darkMode
          ? `linear-gradient(315deg, ${setLightness(
              0.14,
              shade(0.85, setSaturation(0.1, dbScheme.main))
            )} 0%, ${setLightness(0.15, shade(0.85, setSaturation(0.07, dbScheme.main)))} 74%)`
          : '#FFFFFF',
        backgroundFront: darkMode
          ? setLightness(0.17, shade(0.85, setSaturation(0.08, dbScheme.main)))
          : setLightness(0.99, shade(0.95, setSaturation(0.25, dbScheme.main))),
        softText: darkMode ? setLightness(0.7, setSaturation(0.1, dbScheme.main)) : '#8a8a8a',
        softerText: darkMode ? setLightness(0.6, setSaturation(0.1, dbScheme.main)) : '#e4e9f0',
        mainText: darkMode ? setLightness(0.8, setSaturation(0.1, dbScheme.main)) : '#494949',
        strongText,
        sidebarText: isDarkSideBar
          ? themeVariables.color[colorTheme].white
          : themeVariables.color[colorTheme].strongText,
        mainTextColor:
          dbScheme.main && getLuminance(dbScheme.main) < 0.4
            ? themeVariables.color[colorTheme].white
            : themeVariables.color[colorTheme].strongText,
        highlightText:
          highlightLuminance < 0.4
            ? themeVariables.color[colorTheme].white
            : highlightLuminance > 0.6
            ? themeVariables.color[colorTheme].strongText
            : strongText,
        actionText:
          actionLuminance < 0.4
            ? themeVariables.color[colorTheme].white
            : actionLuminance > 0.6
            ? themeVariables.color[colorTheme].strongText
            : strongText,
        mainStroke: darkMode
          ? setLightness(0.6, shade(0.9, setSaturation(0.1, dbScheme.main)))
          : '#c1c7da',
        softStroke: darkMode
          ? setLightness(0.4, shade(0.9, setSaturation(0.1, dbScheme.main)))
          : '#e3e7ed',
        softerStroke: darkMode
          ? setLightness(0.2, shade(0.8, setSaturation(0.1, dbScheme.main)))
          : '#f2f4f8',
      },
      isDarkSideBar,
      darkMode,
      images: theme?.images,
    }
  }, [theme, state])

  const antDTheme = useMemo(() => {
    return {
      token: {
        colorPrimary: customTheme.color.main,
        fontFamily: customTheme.font.primary,
        colorText: customTheme.color.mainText,
        colorLink: customTheme.color.softText,
        colorLinkHover: customTheme.color.main,
        fontSize: 13,
      },
    }
  }, [customTheme])

  return (
    <UiContext.Provider value={{ state, action }}>
      <ThemeProvider
        theme={{
          ...customTheme,
          darkMode: state.darkMode,
          screenSize,
        }}
      >
        <ConfigProvider theme={antDTheme}>{children}</ConfigProvider>
        <Modal />
      </ThemeProvider>
    </UiContext.Provider>
  )
}

export default UiProvider
