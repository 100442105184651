import styled from 'styled-components'

const SearchStyled = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  .ant-input-affix-wrapper {
    border-radius: 3rem;
    padding: 5px 7px;
    border-color: transparent;

    &:hover,
    &.ant-input-affix-wrapper-focused {
      background: ${({ theme }) => theme.color.backgroundFront};
    }

    &.ant-input-affix-wrapper-focused {
      border-color: ${({ theme }) => theme.color.main};
    }

    input {
      background: transparent;
    }
  }

  .anticon {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.mainText};
  }

  .ant-input-clear-icon {
    color: ${({ theme }) => theme.color.softText};
    font-size: 1.4rem;
  }
`
export default SearchStyled
