import React from 'react'

import { PropTypes } from 'prop-types'

import { OptionStyled } from 'components/Form/components/ValuePicker/styled'

const Option = ({ image, label, disabled, dataCy = 'Option' }) => {
  return (
    <OptionStyled disabled={disabled} data-cy={dataCy}>
      {image ? <>{image}&nbsp;&nbsp;&nbsp;</> : null}
      {label}
    </OptionStyled>
  )
}

Option.propTypes = {
  label: PropTypes.string,
  image: PropTypes.node,
}

Option.defaultProps = {}

export default Option
