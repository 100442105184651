import React from 'react'

import { Controller } from 'react-hook-form'

import { AntButton } from 'components/Global/AntDesign'

import ButtonFieldStyled from './styled'

const ButtonField = ({
  children,
  name,
  defaultValue,
  value = true,
  checked,
  disabled,
  disableClick,
  size = 'small',
  type,
  submit,
  target,
  color,
  onChange,
  onClick,
  validation,
  showRequired,
  className,
  loading,
  ...props
}) => {
  const buttonProps = {
    onClick: onClick,
    disabled: disabled,
    size: size,
    color: color,
    type: type,
    target: target,
    loading,
  }

  if (submit) {
    buttonProps.htmlType = 'submit'
  }

  return (
    <ButtonFieldStyled {...props} color={color} required={showRequired && validation?.required}>
      {name ? (
        <Controller
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ onChange: fromFieldOnChange }) => {
            const handleClick = () => {
              fromFieldOnChange(value)
              onChange && onChange(value)
            }

            return (
              <AntButton {...buttonProps} onClick={handleClick} color={color}>
                {children}
              </AntButton>
            )
          }}
        />
      ) : (
        <AntButton {...buttonProps} href={!onClick && value} color={color}>
          {children}
        </AntButton>
      )}
    </ButtonFieldStyled>
  )
}

export default ButtonField
