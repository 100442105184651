import React from 'react'

import Receiver from './Receiver'
import { StyledAvailableContainer } from './styled'

const ReceiverContainer = ({ valuesToMatchTo, handleRemove, handleAdd }) => {
  return (
    <StyledAvailableContainer>
      {valuesToMatchTo.map((item) => (
        <Receiver valuesToMatchTo={[item]} handleRemove={handleRemove} handleAdd={handleAdd} />
      ))}
    </StyledAvailableContainer>
  )
}

export default ReceiverContainer
