import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
import { pick } from 'lodash'

const PRESETS_WITH_DATAPOINTS = gql`
  query entityChildPresetsAndEntities($id: Float!) {
    entityPreset(id: $id) {
      entities {
        id
        name
      }
      childPresets {
        id
        translations {
          translation
          language {
            code
          }
        }
        entities {
          id
          name
          tags
        }
      }
    }
  }
`

export const usePresetsWithDatapoints = (presetId) => {
  const { data, loading } = useQuery(PRESETS_WITH_DATAPOINTS, {
    variables: {
      id: presetId,
    },
    skip: !presetId,
  })
  return { data, loading }
}

export const useEquipDatapointMap = (entityPreset) => {
  return useMemo(() => {
    if (!entityPreset?.entities) {
      return {}
    }

    return entityPreset.entities.reduce((equipMap, { id: equipId }) => {
      equipMap[equipId] = entityPreset.childPresets.reduce((presetMap, preset) => {
        const datapointResult = preset.entities.find((dp) => dp.tags?.equipRef === equipId)
        // skip this part when datapointResult is empty to filter out presets without datapoints
        presetMap[preset.id] = {
          preset: pick(preset, ['id', 'translations']),
          datapoint: datapointResult ? pick(datapointResult, ['id', 'name']) : null,
        }

        return presetMap
      }, {})

      return equipMap
    }, {})
  }, [entityPreset])
}
