import React from 'react'

import { number, object, oneOfType, string } from 'prop-types'

import TextStyled from './styled'

const Text = ({ text, styleProps, className }) => {
  return (
    <TextStyled className={className} {...styleProps}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </TextStyled>
  )
}

Text.propTypes = {
  text: oneOfType([string, number]),
  className: string,
  styleProps: object,
}

Text.defaultProps = {}

export default Text
