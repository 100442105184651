import React from 'react'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'
import ColorPickerFieldStyled from 'components/Form/fields/ColorPickerField/styled'
import ColorPicker from 'components/Global/FormField/ColorPicker'

const ColorPickerField = ({
  children,
  name,
  options,
  defaultValue,
  value = true,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  label,
  fullWidth,
  selectionKeys,
  allowClear = false,
  dropdownRender,
  optionComponent,
  filters,
  open,
  width,
  showRequired,
  ...props
}) => {
  return (
    <ColorPickerFieldStyled
      {...props}
      label={label}
      required={showRequired && validation?.required}
    >
      {({ control, errors, isEditMode, loading }) => {
        if (!name) {
          return (
            <ColorPicker
              {...{
                value,
                options,
                onChange,
                fullWidth,
                dropdownRender,
                selectionKeys,
                open,
                optionComponent,
                filters,
                width,
              }}
              search
            />
          )
        }

        const error = get(errors, name)
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              if (loading) {
                return <Skeleton width="200px" />
              }

              if (isEditMode) {
                return (
                  <>
                    <ColorPicker
                      value={formFieldValue}
                      clearIcon={false}
                      onChange={(value, itemObject) => {
                        fromFieldOnChange(value)
                        if (typeof onChange === 'function') {
                          onChange(value, itemObject)
                        }
                      }}
                      /* search
                      {...{
                        options,
                        disabled,
                        error,
                        fullWidth,
                        dropdownRender,
                        selectionKeys,
                        allowClear,
                        open,
                        optionComponent,
                        filters,
                        width,
                      }} */
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              return <ColorPicker value={formFieldValue} disabled={true} />
            }}
          />
        )
      }}
    </ColorPickerFieldStyled>
  )
}

ValuePicker.propTypes = {
  fullWidth: PropTypes.bool,
  allowClear: PropTypes.bool,
}

export default ColorPickerField
