import React, { useEffect, useMemo, useState } from 'react'

import { scope3SubCategories } from 'services/sustainability/ghg'

import ValuePicker from 'components/Form/components/ValuePicker/ValuePicker'
import { DatapointDetails } from 'components/Global/FormField/Datapoints'
import GhgDataSelector from 'components/Global/FormField/GhgDataSelector/GhgDataSelector'
import { useGhgData } from 'components/Global/FormField/GhgDataSelector/hooks'
import ScopeSelector from 'components/Global/FormField/GhgDataSelector/ScopeSelector'
import { DatapointSelectorContainerStyled } from 'components/Global/FormField/GhgDataSelector/styled'

const GhgDataSelectorContainer = ({
  singleDatapoint,
  fixedUnit,
  dataKey = 'datapoints',
  groupDataKey,
  groupId,
  subPath,
  canReselect,
  intl,
}) => {
  const {
    hoveredElement,
    detail,
    selectedScope,
    organisationId,
    organisationData,
    onSelectScope,
    onSelectOrganisation,
    selectedEntity,
    onSelectEntity,
    ...ghgData
  } = useGhgData({
    singleDatapoint,
    fixedUnit,
    dataKey,
    groupDataKey,
    subPath,
    groupId,
    intl,
  })

  const [subDrawer, setSubDrawer] = useState(null)
  const cat3 = useMemo(
    () =>
      selectedScope?.id === '6' &&
      scope3SubCategories.some((cat) => cat !== 'all' && cat === selectedEntity?.categoryId) &&
      selectedEntity?.categoryId,
    [selectedScope?.id, selectedEntity?.categoryId]
  )

  useEffect(() => {
    if (cat3) {
      setSubDrawer({ open: true, cat3: { ...selectedEntity, id: cat3 } })
    }
  }, [cat3, selectedEntity])

  return (
    <DatapointSelectorContainerStyled className="DatapointSelectorContainer">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      <GhgDataSelector
        {...ghgData}
        key="mainDrawer"
        intl={intl}
        singleDatapoint={singleDatapoint}
        organisationId={organisationId}
        selectedScopeId={selectedScope?.id}
        treeComponent={selectedScope?.treeComponent}
        onSelectEntity={onSelectEntity}
        selectedEntity={selectedEntity}
        height="40vh"
        canReselect={canReselect}
        subDrawer={subDrawer}
        onSubDrawerChange={setSubDrawer}
        selectors={
          <>
            <ScopeSelector value={selectedScope} onChange={onSelectScope} />
            <ValuePicker
              options={organisationData || []}
              placeholder={intl.formatMessage({ id: 'widget.organisation' })}
              search
              input
              selectionKeys={{ label: 'name', value: 'id' }}
              onChange={onSelectOrganisation}
              value={organisationId}
            />
          </>
        }
      />
    </DatapointSelectorContainerStyled>
  )
}

export default GhgDataSelectorContainer
