import React, { useMemo } from 'react'

import { CharacterCountStyled } from './styled'

const CharacterCount = ({ value, mode = 'diff', max }) => {
  const { count, diff, status } = useMemo(() => {
    const count = value?.length || 0
    const status = max - count > 0 ? 'success' : 'error'
    return {
      diff: `${status === 'success' ? '-' : '+'}${Math.abs(max - count)}`,
      count,
      status,
    }
  }, [value, max])

  return (
    <CharacterCountStyled className="CharacterCount" status={status}>
      {mode === 'diff' ? diff : count}
    </CharacterCountStyled>
  )
}

export default CharacterCount
