import React, { useMemo } from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useEntityFiles } from 'services/entities'

import Button from 'components/Global/Button'
import { FormFieldLabel } from 'components/Global/FormField/styled'
import Icon from 'components/Global/Icon'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import EntityFilePickerStyled from './styled'

const EntityFilePicker = ({
  value = [],
  files: incomingFiles,
  onChange,
  onFileClick,
  subPath,
  dataKey = 'entities',
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {} } },
  } = useDashboard()

  const { data } = useEntityFiles(
    !incomingFiles &&
      get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}[0].id`)
  )

  const { files, images } = useMemo(() => {
    const files = incomingFiles || data?.files
    if (files) {
      return files.reduce(
        (files, { file }, index) => {
          if (file.mimetype?.startsWith('image')) {
            files.images.push({ ...file, index })
          } else {
            files.files.push({ ...file, index })
          }
          return files
        },
        { images: [], files: [] }
      )
    }
    return { images: [], files: [] }
  }, [data, incomingFiles])

  const handleChange = ({ __typename, id, ...meta }) => {
    if (onFileClick) {
      return onFileClick({ __typename, id, ...meta })
    }
    onChange(
      value.some(({ key }) => key === meta.key)
        ? value.filter(({ key }) => key === meta.key)
        : value.concat(meta)
    )
  }

  return (
    <EntityFilePickerStyled>
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.image' })}</FormFieldLabel>
      {images.map(({ __typename, id, ...image }) => (
        <Icon
          className={`${value.some(({ key }) => key === image.key) ? 'active' : 'inactive'}`}
          icon={image.key}
          onClick={() => handleChange(image)}
        />
      ))}
      <FormFieldLabel>{intl.formatMessage({ id: 'widget.file' })}</FormFieldLabel>
      {files.map(({ __typename, id, ...file }) => (
        <Button
          className={`${value.some(({ key }) => key === file.key) ? 'active' : 'inactive'}`}
          isActive={value.some(({ key }) => key === file.key)}
          label={file.name}
          icon="fas fa-file-pdf"
          onClick={() => handleChange(file)}
        />
      ))}
    </EntityFilePickerStyled>
  )
}

export default injectIntl(EntityFilePicker)
