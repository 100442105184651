import styled from 'styled-components'

import SearchField from 'components/Form/components/Search'

export const Container = styled.div`
  width: 480px;
  height: fit-content;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
`

export const Search = styled(SearchField)`
  margin: 0 1rem;

  /* Reset :focus styling */
  box-shadow: none !important;
  border: none !important;
  background: none !important;

  .ant-input-prefix {
    margin-right: 1rem;
  }
`
