import React, { useMemo, useState } from 'react'

import { Radio } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { useEnergyTypes, useFuelTypes } from 'services/energy'

import { colorGenerator } from 'components/Form/components/RatioBar/RatioBar'
import { EnergyCompositionFieldStyled } from 'components/Form/fields/EnergyCompositionField/styled'
import { EditableTableField } from 'components/Form/fields/index'
import RatioBarField from 'components/Form/fields/RatioBarField/RatioBarField'

const EnergyCompositionField = ({ intl, ...props }) => {
  const [currentContractType, setCurrentContractType] = useState('energy')
  const { data: energyTypes } = useEnergyTypes()
  const { data: fuelTypes } = useFuelTypes()
  const energyTypeOptions = useMemo(
    () =>
      energyTypes
        ? energyTypes.map(({ tags, energySource, ...item }) => ({
            ...item,
            energySource,
            label: `${energySource} ${
              tags ? Object.keys(tags).reduce((tags, tag) => `${tags} ${tag}`, '') : ''
            }`,
          }))
        : [],
    [energyTypes]
  )

  const fuelTypeOptions = useMemo(
    () =>
      fuelTypes
        ? fuelTypes.map(({ tags, name, ...item }) => ({
            ...item,
            name,
            label: `${name} ${
              tags ? Object.keys(tags).reduce((tags, tag) => `${tags} ${tag}`, '') : ''
            }`,
          }))
        : [],
    [fuelTypes]
  )

  return (
    <EnergyCompositionFieldStyled>
      {({ isEditMode, watch, setValue }) => {
        const contract = watch()

        if (
          contract.energyContractCompositionParts?.length &&
          !_.isEmpty(contract.energyContractCompositionParts[0].energyType) &&
          !_.isEmpty(contract.energyContractCompositionParts[0].fuelType)
        ) {
          setCurrentContractType(
            contract.energyContractCompositionParts[0].energyType?.id ? 'energy' : 'fuel'
          )
        }

        const handleContractTypeChange = (e) => {
          if (isEditMode) {
            setCurrentContractType(e.target.value)
            setValue('energyContractCompositionParts', [
              {
                energyType: currentContractType === 'energy' ? {} : null,
                fuelType: currentContractType === 'fuel' ? {} : null,
                percentage: 0,
              },
            ])
          }
        }

        return (
          <>
            <Radio.Group
              value={currentContractType}
              size="small"
              onChange={handleContractTypeChange}
            >
              <Radio.Button value="energy">Energy Contract</Radio.Button>
              <Radio.Button value="fuel">Fuel Contract</Radio.Button>
            </Radio.Group>
            <EditableTableField
              {...props}
              columns={[
                ...(currentContractType === 'energy'
                  ? [
                      {
                        dataKey: isEditMode ? 'energyType' : 'energyType.energySource',
                        label: { formatted: 'settings.energyContracts.energyType.energySource' },
                        componentName: isEditMode ? 'ValuePicker' : 'Text',
                        componentProps: {
                          options: energyTypeOptions,
                          selectionKeys: {
                            label: 'energySource',
                          },
                          search: true,
                        },
                        width: 100,
                      },
                      {
                        dataKey: 'energyType.renewable',
                        label: { formatted: 'settings.energyContracts.energyType.renewable' },
                        componentName: 'Icon',
                        componentProps: {
                          viewRender: (value) =>
                            value === undefined ? null : value ? 'fas fa-leaf' : 'fas fa-industry',
                        },
                        width: 50,
                        className: 'centered',
                      },
                      {
                        dataKey: 'energyType.standardUnit.name',
                        label: { formatted: 'settings.energyContracts.unit' },
                        componentName: 'Text',
                        width: 40,
                        className: 'centered',
                      },
                    ]
                  : []),
                ...(currentContractType === 'fuel'
                  ? [
                      {
                        dataKey: isEditMode ? 'fuelType' : 'fuelType.name',
                        label: { formatted: 'settings.energyContracts.fuelType' },
                        componentName: isEditMode ? 'ValuePicker' : 'Text',
                        componentProps: {
                          options: fuelTypeOptions,
                          selectionKeys: {
                            label: 'name',
                          },
                          search: true,
                        },
                        width: 100,
                      },
                      {
                        dataKey: 'fuelType.standardUnit.name',
                        label: { formatted: 'settings.energyContracts.unit' },
                        componentName: 'Text',
                        width: 40,
                        className: 'centered',
                      },
                    ]
                  : []),
                {
                  dataKey: 'percentage',
                  label: { formatted: 'settings.energyContracts.percentage' },
                  componentName: isEditMode ? 'NumberInput' : 'Text',
                  componentProps: {
                    formatter: (value) => value !== undefined && `${value}%`,
                    viewRender: (value) => value !== undefined && `${value}%`,
                    parser: (value) => value !== undefined && value.replace('%', ''),
                    min: 0,
                    max: 100,
                  },
                  width: 50,
                },
                {
                  dataKey: 'percentage',
                  componentName: 'Progress',
                  componentProps: {
                    colorRender: ({ id, rowIndex, rowsTotal }) => {
                      return colorGenerator(id, rowsTotal - rowIndex)
                    },
                    status: 'active',
                  },
                  width: 80,
                },
                {
                  componentName: 'Text',
                  width: 10,
                },
              ]}
              defaultRowValue={{
                energyType: currentContractType === 'energy' ? {} : null,
                fuelType: currentContractType === 'fuel' ? {} : null,
                id: null,
                percentage: 0,
              }}
            />
            <RatioBarField
              {...props}
              randomColors
              label="Total"
              labelKey="energyType.energySource"
              errorMessage={intl.formatMessage({
                id: 'settings.energyContracts.energyType.compositionError',
              })}
            />
          </>
        )
      }}
    </EnergyCompositionFieldStyled>
  )
}

EnergyCompositionField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
}

export default injectIntl(EnergyCompositionField)
