import React, { useMemo, useRef } from 'react'

import ReactDOM from 'react-dom'

import { useOnClickOutside, usePortal, useWindowSize } from 'components/Global/hooks'
import TooltipContent from 'components/Global/Tooltip/TooltipContent/index'

const TooltipContentPortal = ({
  parentRef,
  preventClickOutside,
  onToggleOpen,
  className,
  height,
  fixedHeight,
  width,
  value,
  updatePosition,
  dataCy,
  ...props
}) => {
  const screenSize = useWindowSize()
  const target = usePortal('main-panel')
  const ref = useRef()
  useOnClickOutside(
    ref,
    preventClickOutside || !onToggleOpen ? () => {} : () => onToggleOpen(null, false)
  )

  const tooltipContentPosition = useMemo(() => {
    const element = parentRef?.getBoundingClientRect()
    if (!element) {
      return null
    }
    const topRender = element.top + element.height + height < screenSize.height
    const leftRender = element.left + width < screenSize.width
    return element
      ? {
          top: topRender ? `${element.top + element.height}px` : 'auto',
          left: leftRender ? `${element.left}px` : 'auto',
          bottom: topRender ? 'auto' : `${screenSize.height - element.top}px`,
          right: leftRender ? 'auto' : `${screenSize.width - (element.left + element.width)}px`,
          transformOrigin: topRender ? 'top' : 'bottom',
        }
      : {}
    // eslint-disable-next-line
  }, [parentRef, height, screenSize.height, screenSize.width, width, updatePosition])

  return ReactDOM.createPortal(
    <div ref={ref} data-cy={`${dataCy}-container`}>
      <TooltipContent
        {...props}
        className={className}
        position={tooltipContentPosition}
        width={width}
        height={height}
        fixedHeight={fixedHeight}
        dataCy={dataCy}
      />
    </div>,
    target
  )
}

export default TooltipContentPortal
