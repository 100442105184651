import { useMemo } from 'react'

import { useHasFixedPermissionAccess } from 'services/fixedpermissions'
import { useHasLicense } from 'services/security/license'
import { useHasModule } from 'services/security/modules'

const useSettingsMenu = (url) => {
  const [hasFixedPermission, { loading: fixedPermLoading, user }] = useHasFixedPermissionAccess()
  const [hasLicense, { loading: licenseLoading }] = useHasLicense()
  const [hasModule, { loading: moduleLoading }] = useHasModule()

  const menuItems = useMemo(() => {
    const isOrganisationAllowed =
      hasModule(['gri', 'sdg', 'esg0', 'ghg', 'csrd', 'sbti', 'ghg.scope3']) &&
      hasFixedPermission('ORGANISATION_ADMINISTRATOR')

    const items = [
      {
        title: 'settings.menu.users',
        key: 'user-settings',
        selectable: false,
        url: `${url}/roles`,
        children: [
          {
            title: 'settings.menu.users.users',
            key: 'users',
            url: `${url}/users`,
            disabled: !hasFixedPermission('USER_MANAGEMENT'),
          },
          {
            title: 'settings.menu.users.roles',
            key: 'roles',
            url: `${url}/roles`,
            disabled: !hasFixedPermission('ROLES_PERMISSION_MANAGEMENT'),
          },
        ],
      },
      {
        title: 'settings.menu.data',
        key: 'data-settings',
        selectable: false,
        url: `${url}/datapoints`,
        children: [
          {
            title: 'settings.menu.data.datapoints',
            key: 'datapoints',
            url: `${url}/datapoints`,
            disabled: !hasFixedPermission('DATAPOINT_MANAGEMENT'),
          },
          {
            title: 'settings.menu.data.environmentSetup',
            key: 'environmentSetup',
            url: `${url}/environment`,
            disabled: !hasFixedPermission('DATAPOINT_MANAGEMENT'),
          },
          {
            title: 'Catches',
            key: 'catches',
            url: `${url}/catches`,
            disabled: !hasFixedPermission('DATAPOINT_MANAGEMENT'),
          },
          {
            title: 'settings.menu.data.rules',
            key: 'rules',
            url: `${url}/rules`,
            disabled: !hasFixedPermission('RULE_MANAGEMENT'),
          },
          {
            title: 'settings.menu.data.flows',
            key: 'flows',
            url: `${url}/company-flows`,
            disabled: !hasFixedPermission('FLOW_MANAGEMENT'),
          },
          {
            title: 'settings.menu.data.dataManagement',
            key: 'dataManagement',
            url: `${url}/data-management`,
            disabled: !hasFixedPermission('SUSTAINABILITY_ADMINISTRATOR'),
          },
          // {
          //   title: 'settings.menu.data.import',
          //   key: 'imports',
          //   url: `${url}/imports`,
          //   disabled: !hasAccess('DATAPOINT_MANAGEMENT'),
          // },
          {
            title: 'settings.menu.data.importETL',
            key: 'etl',
            url: `${url}/etl`,
            disabled: !(
              (hasLicense('small') || hasLicense('medium') || hasLicense('enterprise')) &&
              (hasFixedPermission('IMPORT_VISITOR') ||
                hasFixedPermission('IMPORT_EXECUTOR') ||
                hasFixedPermission('IMPORT_ADMINISTRATOR'))
            ),
          },
          {
            title: 'settings.menu.data.reportTemplates',
            key: 'reportTemplates',
            url: `${url}/report-templates`,
            disabled: !hasFixedPermission('SUSTAINABILITY_ADMINISTRATOR'),
          },
        ],
      },
      {
        title: 'settings.menu.organisation',
        key: 'organisation-settings',
        selectable: false,
        url: `${url}/organisation`,
        disabled: !isOrganisationAllowed,
        children: [
          {
            title: 'settings.menu.organisation.management',
            key: 'organisation-management',
            url: `${url}/organisation-management`,
            disabled: !isOrganisationAllowed,
          },
          {
            title: 'settings.menu.organisation.structure',
            key: 'organisation-structure',
            url: `${url}/organisation-structure`,
            disabled: !isOrganisationAllowed,
          },
          {
            title: 'settings.menu.organisation.environment',
            key: 'organisation-environment',
            url: `${url}/organisation-environment`,
            disabled: !isOrganisationAllowed,
          },
        ],
      },
      {
        title: 'settings.menu.invoiceSettings',
        key: 'invoice-settings',
        selectable: false,
        url: `${url}/invoice-settings`,
        children: [
          {
            title: 'settings.menu.invoiceSettings.marketplace',
            key: 'marketplace',
            url: `${url}/marketplace`,
            disabled: true,
          },
          {
            title: 'settings.menu.invoiceSettings.graphics',
            key: 'graphics',
            url: `${url}/graphics`,
            disabled: !hasFixedPermission('INVOICING_ADMINISTRATOR'),
          },
          {
            title: 'settings.menu.invoiceSettings.products',
            key: 'invoice-products',
            url: `${url}/invoice-settings/products`,
            disabled: !hasFixedPermission('INVOICING_ADMINISTRATOR'),
          },
          {
            title: 'settings.menu.invoiceSettings.licenses',
            key: 'invoice-licenses',
            url: `${url}/invoice-settings/licenses`,
            disabled: !hasFixedPermission('INVOICING_ADMINISTRATOR'),
          },
          {
            title: 'settings.menu.invoiceSettings.customer',
            key: 'invoice-customer',
            url: `${url}/invoice-settings/customer`,
            disabled: !hasFixedPermission('INVOICING_ADMINISTRATOR'),
          },
          {
            title: 'settings.menu.invoiceSettings.activity',
            key: 'activity',
            url: `${url}/invoice-settings/activity`,
            disabled: !hasFixedPermission('LOG_MANAGEMENT'),
          },
        ],
      },
    ]

    if (user.company.id === 2) {
      items.push({
        title: 'settings.menu.admin',
        key: 'aviary-admin',
        selectable: false,
        url: `${url}/customers`,
        children: [
          {
            title: 'settings.menu.admin.customers',
            key: 'customers',
            url: `${url}/customers`,
            disabled: false,
          },
          {
            title: 'settings.menu.admin.entityTypeManager',
            key: 'entity-type-manager',
            url: `${url}/entity-type-manager`,
            disabled: false,
          },
          {
            title: 'settings.menu.admin.fixedFiles',
            key: 'fixedFiles',
            url: `${url}/fixed-files`,
            disabled: false,
          },
          {
            title: 'settings.menu.admin.fixedFileConfiguration',
            key: 'fixed-configuration',
            url: `${url}/fixed-configuration`,
            disabled: !hasFixedPermission('DATAPOINT_MANAGEMENT'),
          },
          // {
          //   title: `Fluvius integration`,
          //   key: 'fluvius',
          //   url: `/fluvius`,
          //   disabled: !hasAccess('DATAPOINT_MANAGEMENT'),
          // },
          {
            title: `settings.menu.admin.menu`,
            key: 'menu',
            url: `${url}/menu`,
            disabled: false,
          },
          {
            title: `settings.menu.admin.logging`,
            key: 'menu',
            url: `${url}/logging`,
            disabled: false,
          },
        ],
      })
      if (
        user.email &&
        (user.email === 'nils@canary.expert' || user.email === 'arnaud@canary.expert')
      )
        items[items.length - 1].children.push({
          title: `Fluvius integration`,
          key: 'fluvius',
          url: `/fluvius`,
          disabled: !hasFixedPermission('DATAPOINT_MANAGEMENT'),
        })
    }

    return items
  }, [hasLicense, hasFixedPermission, url, user, hasModule])

  return [menuItems, { loading: fixedPermLoading || licenseLoading || moduleLoading }]
}

export default useSettingsMenu
