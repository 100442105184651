import React from 'react'

import { injectIntl } from 'react-intl'

import { FlowOperationStyled } from './styled'

const FlowOperation = ({ operation, intl }) => {
  const isMath = ['>', '<', '>=', '<=', '+', '%', '/'].includes(operation)
  return (
    <FlowOperationStyled className={`FlowOperation ${isMath ? 'math' : ''}`}>
      {isMath ? operation : intl.formatMessage({ id: operation })}
    </FlowOperationStyled>
  )
}

export default injectIntl(FlowOperation)
