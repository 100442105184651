import React, { useCallback, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Dropdown, Menu, Tooltip } from 'antd'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

import useErrorHandler from 'util/useErrorHandler'
// import { Link } from 'react-router-dom'
import { useAppState } from 'services/store'
import { ME_QUERY, UPDATE_ACCOUNT_MUTATION } from 'services/user'

import { AntMenu } from 'components/Global/AntDesign'
import Icon from 'components/Global/Icon'
import MenuItem from 'components/Global/MenuItem'
import Switch from 'components/Global/Switch'
import { useMessages } from 'components/Layout/Notifications'
import { useUi } from 'components/Layout/UiProvider'
import { EditProfileModal } from 'components/Modals/Settings/EditProfileModal'

import { MultiIconMenuItem } from './styled'
import UserBadge from './UserBadge'

const ProfileMenu = ({ intl }) => {
  const [editingProfile, setEditingProfile] = useState(false)

  const { success, error: errorMessage } = useMessages()

  const handleCloseProfileModal = () => {
    setEditingProfile(false)
  }

  const {
    appState: { logoutUser },
  } = useAppState()

  const { state: uiState, action: uiAction } = useUi()

  const [errorHandler] = useErrorHandler()

  const { data, error, loading, refetch } = useQuery(ME_QUERY)

  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION)

  const handleToggleDarkMode = useCallback(() => {
    uiAction({
      type: 'TOGGLE_DARK_MODE',
    })
  }, [uiAction])
  const handleToggleAdvanced = useCallback(
    async (advanced) => {
      try {
        await updateAccount({
          variables: {
            data: {
              id: data.me.id,
              advanced,
            },
          },
        })

        refetch()

        success(
          intl.formatMessage({
            id: advanced
              ? 'settings.updateProfile.advanced.active.success'
              : 'settings.updateProfile.advanced.disabled.success',
          })
        )
      } catch (e) {
        console.error(e)
        errorMessage(intl.formatMessage({ id: 'settings.updateProfile.advanced.failed' }))
      }
    },
    [updateAccount, intl, errorMessage, success, data, refetch]
  )

  if (error) {
    errorHandler(error)
    console.error(error)
  }

  if (!data || loading) {
    return null
  }

  const user = data.me

  const menu = (
    <AntMenu selectable={false}>
      <Menu.Item>
        <MenuItem>
          <Tooltip title={user.email} placement="left">
            <span>
              <FormattedMessage id="topBar.profileMenu.hello" values={{ name: user.firstname }} />
            </span>
          </Tooltip>
        </MenuItem>
      </Menu.Item>

      <Menu.Divider />

      <MultiIconMenuItem>
        <MenuItem>
          <Tooltip title={intl.formatMessage({ id: 'support.title' })} placement="bottom">
            <a
              href="https://brightcanary.freshdesk.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="fas fa-comments-question-check" />
            </a>
          </Tooltip>
          {/* <Tooltip title={intl.formatMessage({ id: 'contact.title' })} placement="bottom">
            <Link exact to={'/user/login/contact'}>
              <Icon icon="fas fa-envelope" />
            </Link>
          </Tooltip> */}
          <Tooltip title={intl.formatMessage({ id: 'status' })} placement="bottom">
            <a href="https://status.iot.canary.expert" target="_blank" rel="noopener noreferrer">
              <Icon icon="fas fa-traffic-light" />
            </a>
          </Tooltip>
        </MenuItem>
      </MultiIconMenuItem>

      <Menu.Divider />

      <Menu.Item>
        <MenuItem icon="fas fa-circle-half-stroke">
          <FormattedMessage id="topBar.profileMenu.theme" />
          <Switch checked={uiState.darkMode} onChange={handleToggleDarkMode} />
        </MenuItem>
      </Menu.Item>

      <Menu.Item>
        <MenuItem icon="fas fa-graduation-cap">
          <FormattedMessage id="topBar.profileMenu.advanced" />
          <Switch checked={user.advanced} onChange={handleToggleAdvanced} />
        </MenuItem>
      </Menu.Item>

      <Menu.Item>
        <MenuItem onClick={() => setEditingProfile(true)} icon="fas fa-pen">
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </MenuItem>
      </Menu.Item>

      <Menu.Item>
        <MenuItem href={null} onClick={() => logoutUser()} icon="fas fa-power-off">
          <FormattedMessage id="topBar.profileMenu.logout" />
        </MenuItem>
      </Menu.Item>
    </AntMenu>
  )

  const initials = user ? `${user.firstname[0]}${user.surname[0]}` : ''

  return (
    <Dropdown
      overlay={
        editingProfile ? (
          <EditProfileModal userId={data.me.id} handleClose={handleCloseProfileModal} intl={intl} />
        ) : (
          menu
        )
      }
      trigger={['click']}
    >
      <div>
        <UserBadge initials={initials} avatar={data.me.avatar} />
      </div>
    </Dropdown>
  )
}

ProfileMenu.propTypes = {
  intl: intlShape.isRequired,
}
export default injectIntl(ProfileMenu)
