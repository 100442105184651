import styled from 'styled-components'

const TagsStyled = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  .Tags__selected {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
  }
  .OptionPicker {
    overflow: hidden auto;
  }
  .ValuePickerValue {
    .Field {
      width: 8rem;
      .Value {
        > span {
          height: 1.4rem;
        }
      }
    }
  }
`
export default TagsStyled
