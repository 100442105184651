import React from 'react'

import PropTypes from 'prop-types'
import { useFieldArray } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import { AllFixedFilesSelectField } from 'components/Form/fields/index'
import MarkerField from 'components/Form/fields/MarkerField'

import { MarkerListFieldStyled } from './styled'

const MarkerListField = ({
  value,
  name,
  format = 'PPpp',
  filter,
  dependencies,
  markMissing,
  parentIsEnabled,
  isVirtual,
  onChange,
  disableList = [],
  intl,
  ...fieldProps
}) => {
  const { fields, remove, append } = useFieldArray({
    name,
  })
  return (
    <MarkerListFieldStyled {...fieldProps}>
      {({ watch, loading, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }

        const filterFn = ({ item }) => {
          if (!filter) {
            return true
          }
          return filter(item, { watch })
        }

        const handleChange = (value, action, itemIndex, array) => {
          if (action === 'delete') {
            remove(itemIndex)
          }
          if (action === 'add') {
            append({ ...value, enabled: true })
          }
          return onChange && onChange(array)
        }

        const renderValue = value === undefined && name === undefined ? value : fields
        const list = Array.isArray(renderValue)
          ? renderValue?.map((item, index) => ({ item, index })).filter(filterFn)
          : []

        return (
          <>
            {isEditMode && isVirtual && (
              <AllFixedFilesSelectField
                value={list.map(({ item }) => item)}
                onChange={(value, option) => handleChange(option, 'add', null, value)}
                featureType="marker"
              />
            )}
            {!list || list.length === 0 ? (
              <span>
                {intl
                  ? intl.formatMessage({
                      id: 'componentCaptions.noMarkers',
                    })
                  : 'No markers'}
              </span>
            ) : (
              list?.map(({ item, index }) => (
                <MarkerField
                  key={item.id}
                  name={`${name}[${index}]`}
                  markMissing={markMissing}
                  parentIsEnabled={parentIsEnabled}
                  softDeleteMode={!isVirtual}
                  onChange={handleChange}
                  itemIndex={index}
                  disabled={disableList.includes(item.name)}
                  defaultValue={item}
                />
              ))
            )}
          </>
        )
      }}
    </MarkerListFieldStyled>
  )
}

MarkerListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default MarkerListField
