import React, { useCallback, useEffect } from 'react'

import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { Item, Title } from '../../../components/Results'

export const MenuItemSearchResult = ({ data: { title, key }, close, clickRef, intl, ...props }) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push(`/dashboard/${key}`)
    close()
  }, [key, history, close])

  useEffect(() => {
    if (clickRef) {
      clickRef.current = handleClick
    }
  }, [clickRef, handleClick])

  return (
    <Item
      onClick={handleClick}
      icon="far gauge-high"
      iconInfo={intl.formatMessage({ id: 'dashboard' })}
      {...props}
    >
      <Title>{title}</Title>
    </Item>
  )
}

export default injectIntl(MenuItemSearchResult)
