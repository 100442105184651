import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'

import { DashboardMenu } from './DashboardMenu.styled'

const variants = {
  DashboardMenu,
}

const TreeStyled = styled.div`
  ${({ level }) =>
    level === 0
      ? css`
          height: 100%;
          overflow: hidden auto;
        `
      : css`
          padding-left: 2rem;
        `}
  ${({ level }) =>
    level === 1 &&
    css`
      //box-shadow: 0px -10px 20px rgba(100, 100, 100, 0.1) inset;
    `}
`
export const SearchableTreeStyled = styled.div`
  display: flex;
  flex-direction: column;

  > .Tree {
    margin-top: 0.5rem;
  }
`
export const TreeItemStyled = styled.div`
  ${({ theme, level, dropdownIsOpen }) => css`
    position: relative;
    border-radius: ${({ level }) => level < 1 && '1rem'};
    font-size: ${theme.screenSize.screen.current === 's' ? '1.2rem' : '1rem'};
    overflow: hidden;

    > ${DropdownArrow} {
      transform: rotate(-90deg);
    }

    ${dropdownIsOpen &&
    css`
      background: ${theme.color.backgroundFront};
      margin-bottom: 0.5rem;

      > .Button {
        box-shadow: ${level === 0 && '0 0 20px rgba(100, 100, 100, 0.15)'};
      }

      > ${DropdownArrow} {
        transform: rotate(0deg);
      }
    `}
    ${DropdownArrow} {
      transition: background 0.2s ease;
      position: absolute;
      left: 0;
      top: 0;
      width: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      height: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;

      &:after {
        left: auto;
        top: auto;
        width: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
        height: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
      }

      &:hover {
        &:after {
          border-color: ${({ theme }) => theme.color.main};
        }
      }
    }

    > .Icon {
      position: absolute;
      left: 0.5rem;
      top: 0;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 1px;
        top: 50%;
        left: 100%;
        background: ${({ theme }) => theme.color.softText};
      }
    }
  `}
  ${({ variant }) => variants[variant]}
`
export const TreeItemButton = styled.span``

export const TreeItemWrapper = styled(motion.div)`
  z-index: 20;
  padding: 0 1rem 0 2rem;
  display: flex;
  align-items: center;
`
export default TreeStyled
