export const initialState = {
  detailStack: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_PANEL_TO_STACK':
      return {
        ...state,
        detailStack: state.detailStack.some(({ id }) => id !== action.payload.value.id)
          ? state.detailStack
          : [action.payload.value, ...state.detailStack],
        detailWidth: action.payload.detailWidth || state.detailWidth,
      }
    case 'REMOVE_PANEL_FROM_STACK':
      return {
        ...state,
        detailStack: state.detailStack.filter(({ id }) => id !== action.payload.value.id),
        detailWidth: action.payload.detailWidth || state.detailWidth,
      }
    case 'REMOVE_ALL_PANELS_FROM_STACK':
      return {
        ...state,
        detailStack: [],
        detailWidth: action.payload?.detailWidth || state.detailWidth,
      }
    default:
      return state
  }
}

export default reducer
