import { lighten } from 'polished'
import styled from 'styled-components'

export const ListWrapper = styled.div`
  flex: 1;
  height: ${({ tableHeight }) => !tableHeight && '100%'};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  .ant-empty {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-top: 2rem solid ${({ theme }) => lighten(0.02, theme.color.softerStroke)};
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`

export const ButtonGroup = styled.div`
  display: flex;

  .ant-btn {
    margin: 0;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
`
