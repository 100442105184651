import React, { useRef, useState } from 'react'

import { useOnClickOutside } from '../hooks'
import DropdownOptions from './DropdownOptions'
import DropdownValue from './DropdownValue'
import DropdownStyled from './styled'

const Dropdown = ({
  value,
  options,
  componentName,
  componentProps,
  styleProps,
  preventClickOutside,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => !preventClickOutside && onOpenToggle(null, false))

  const onOpenToggle = (e) => {
    e && e.stopPropagation()
    setIsOpen((state) => !state)
  }

  const _styleProps = { ...styleProps, isOpen }
  return (
    <DropdownStyled {..._styleProps} className="Dropdown" onClick={onOpenToggle}>
      <DropdownValue
        componentName={componentName}
        componentProps={componentProps}
        styleProps={_styleProps}
        value={value}
        showArrow
      />
      <DropdownOptions
        options={options}
        componentName={componentName}
        componentProps={componentProps}
        styleProps={_styleProps}
        onChange={onChange}
      />
    </DropdownStyled>
  )
}

Dropdown.propTypes = {}

Dropdown.defaultProps = {}

export default Dropdown
