import { css } from 'styled-components'

const Switch = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  margin-top: 0;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 0 !important;
  }
`
const Text = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  margin-top: 0;
  justify-content: center;

  > p {
    padding: 0 1.5rem;
    margin: auto 0;
  }
`

const DateTime = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  margin-top: 0;
  justify-content: center;

  > p {
    padding: 0 1.5rem;
    margin: auto 0;
  }
`

const SelectionText = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  margin-top: 0;
  justify-content: center;

  > p {
    padding: 0 1.5rem;
    margin: auto 0;
  }
`

const ColorPicker = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};

  .ColorPicker {
    padding: 0 2rem 0 0.5rem;
    border-bottom: none !important;
  }
`

const IconPicker = css`
  .Button {
    .Icon {
      img {
        height: 1.4rem !important;
      }
    }
  }
`

const UnitPicker = css`
  .Tooltip {
    height: 100%;
  }
`

const Color = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};

  .Color {
    margin: 0 1.5rem;
    width: auto !important;
    border-bottom: none;
  }
`
const DatePicker = css`
  .ant-picker {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &.ant-picker-disabled {
      background: none;
    }

    &.ant-picker-focused {
      box-shadow: none;
    }
  }
`
const TableIconPicker = css`
  .Button {
    padding: 0 1.5rem !important;
    justify-content: flex-start !important;
  }
`
const Icon = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};

  .Icon {
    margin: 0 0 0 1.5rem;

    svg,
    img {
      width: 1.2rem;
    }
  }
`

const CaptionTooltip = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
  height: 100%;

  .CaptionTooltip {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Icon {
    svg,
    img {
      width: 1.2rem;
    }
  }
`

const components = {
  Switch,
  SelectionText,
  ColorPicker,
  Color,
  DatePicker,
  TableIconPicker,
  Icon,
  Text,
  DateTime,
  UnitPicker,
  CaptionTooltip,
  IconPicker,
  EntityTagPicker: UnitPicker,
}

export const Table = css`
  ${({ componentName }) => components[componentName]}
`
