import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

const QUERY_ACTIVE_LICENSE = gql`
  query ActiveLicense($companyId: ID!) {
    activeLicense(companyId: $companyId) {
      id
      key
    }
  }
`

export const useHasLicense = () => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ACTIVE_LICENSE, {
    variables: {
      companyId: customer?.id,
    },
    skip: !customer?.id,
  })

  return [(key) => data?.activeLicense?.key === key, info]
}
