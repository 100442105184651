import React from 'react'

import { FormattedMessage, injectIntl } from 'react-intl'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import { defaultStartTimeUnits } from 'components/Dashboard/Widget/config/selections'
import ValuePicker from 'components/Form/components/ValuePicker'
import CalculationMethod from 'components/Global/FormField/DefaultStartTimeSelector/CalculationMethod'
import { isDatapointComparator } from 'components/Global/FormField/DefaultStartTimeSelector/DefaultStartTimeSelector'
import { MultiFieldContainer } from 'components/Global/FormField/FormFieldElement/styled'
import NumberInput from 'components/Global/FormField/NumberInput'
import { FormFieldLabel } from 'components/Global/FormField/styled'
import TimeOffsetStyled from 'components/Global/FormField/TimeOffset/styled'

const TimeOffset = ({ value, min = 0, datapointsDataKey, hideLabel, onChange, intl }) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} }, advanced },
  } = useDashboard()

  const handleOnChange = (updateValue, dataKey) => {
    return onChange({
      ...value,
      [dataKey]: updateValue,
    })
  }

  return advanced && isDatapointComparator(elementInEditMode.preview, datapointsDataKey) ? (
    <TimeOffsetStyled className="TimeOffset">
      {!hideLabel && (
        <FormFieldLabel as="h4">
          <FormattedMessage
            id={
              elementInEditMode.preview[datapointsDataKey] === 'main'
                ? 'widget.timeRangeDatapoints'
                : 'widget.timeRangeComparisonDatapoints'
            }
          />
        </FormFieldLabel>
      )}
      <MultiFieldContainer className="MultiFieldContainer">
        <NumberInput
          className="NumberInput"
          value={value?.amount || 0}
          min={min}
          onBlur={(value) => handleOnChange(value, 'amount')}
        />
        <ValuePicker
          value={value?.unit}
          options={defaultStartTimeUnits}
          onChange={(value) => handleOnChange(value, 'unit')}
          selectionKeys={{
            label: 'label',
            value: 'value',
          }}
          placeholder={intl.formatMessage({ id: 'widget.offset' })}
          search={false}
        />
      </MultiFieldContainer>
      {!elementInEditMode.preview[datapointsDataKey][0].difference && (
        <CalculationMethod
          value={elementInEditMode.preview}
          datapointsDataKey={datapointsDataKey}
          onChange={onChange}
        />
      )}
    </TimeOffsetStyled>
  ) : null
}

export default injectIntl(TimeOffset)
