import React, { Children, useCallback, useEffect, useMemo, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { Empty } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router'

import { DATETIME_FORMAT_DATE } from 'util/constants'
import { useCurrentCustomer } from 'services/store'

import { ListSubHeader } from 'components/Global/List'

import DefaultGridLayout from './components/DefaultGridLayout'
import Header from './scenes/Haeder'
import Table from './scenes/Table'
import { ListWrapper } from './styled'

const expandParent = (node) => {
  const parent = node.parent
  if (parent) {
    parent.setExpanded(true)
  }

  if (parent.parent) {
    expandParent(parent)
  }
}

const List = ({
  key,
  title,
  headerContent,
  subHeaderContent,
  data,
  className,
  refetch,
  networkStatus,
  selectedId,
  hideSelectableRoutePicker,
  translateSelectableRoutePicker,
  hideDownload,
  hideRefresh,
  tableOnly,
  gridApiRef,
  GridComponent = DefaultGridLayout,
  GridTileComponent,
  gridTileExtraProps,
  defaultInGridMode = true,
  backUrl,
  defaultSorting,
  tableHeight,
  sessionStorageKey: customSessionStorageKey,
  gridIcon,
  context = {},
  disableListNav,
  disableGridNav,
  onGridViewChange,
  onGridReady,
  onRowClicked,
  onCreate,
  onImport,
  onSearch,
  children,
  searchOptions,
  defaultColDef,
  ...agGridProps
}) => {
  const { url } = useRouteMatch()
  const sessionStorageKey = customSessionStorageKey ?? `viewMode_${url}`
  const customer = useCurrentCustomer()
  const [expandedNodes, setExpandedNodes] = useState([])
  const sessionStoredViewMode = sessionStorage.getItem(sessionStorageKey)
  const initialViewGridMode = sessionStoredViewMode
    ? sessionStoredViewMode === 'grid'
    : defaultInGridMode
  const [viewGridMode, setViewGridMode] = useState(GridTileComponent ? initialViewGridMode : false)

  useEffect(() => {
    if (typeof onGridViewChange === 'function') {
      onGridViewChange(initialViewGridMode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetViewGridMode = useCallback(
    (gridMode) => {
      setViewGridMode(gridMode)
      sessionStorage.setItem(sessionStorageKey, gridMode ? 'grid' : 'list')

      if (typeof onGridViewChange === 'function') {
        onGridViewChange(gridMode)
      }
    },
    [sessionStorageKey, onGridViewChange]
  )

  const compactMode = selectedId !== undefined

  const loading = networkStatus === NetworkStatus.loading
  const refetching = networkStatus === NetworkStatus.refetch

  const [gridApi, setGridApi] = useState(null)

  useEffect(() => {
    if (data && gridApi) {
      gridApi.setRowData(data)
    }
  }, [data, gridApi])

  const [searchText, setSearchText] = useState('')

  const handleRowDataChanged = useCallback(
    ({ api }) => {
      const activeNode = api.getRowNode(selectedId)
      if (activeNode) {
        expandParent(activeNode)
      }

      expandedNodes.forEach((id) => {
        const node = api.getRowNode(id)
        node && node.setExpanded(true)
      })
    },
    [expandedNodes, selectedId]
  )

  const sortColumns = useCallback(
    (params) => {
      if (defaultSorting) {
        params.columnApi.applyColumnState({ state: defaultSorting })
      }
    },
    [defaultSorting]
  )

  const handleGridReady = useCallback(
    (params) => {
      setGridApi(params.api)
      sortColumns(params)

      if (typeof onGridReady === 'function') {
        onGridReady(params)
      }
    },
    [onGridReady, sortColumns, setGridApi]
  )

  const rowClassRules = useMemo(
    () => ({
      active: ({ data, context }) => {
        return (
          data?.id !== undefined &&
          context?.selectedId !== undefined &&
          data.id === context.selectedId
        )
      },
    }),
    []
  )

  const handleSearch = useCallback(
    ({ value }) => {
      setSearchText(value)

      if (gridApi) {
        if (value === '') {
          gridApi.collapseAll()
        } else {
          gridApi.expandAll()
        }
      }

      if (typeof onSearch === 'function') {
        onSearch(value)
      }
    },
    [setSearchText, onSearch, gridApi]
  )

  const clearFilters = useCallback(async () => {
    if (gridApi) {
      gridApi.setFilterModel(null)
      gridApi.onFilterChanged()
      gridApi.setSortModel(null)
      gridApi.onSortChanged()
    }
    setSearchText('')
  }, [setSearchText, gridApi])

  const handleDownload = () => {
    if (!gridApi) {
      return
    }
    gridApi.exportDataAsCsv({
      fileName: `export ${dayjs().format(DATETIME_FORMAT_DATE)}.csv`,
      allColumns: true,
    })
  }

  const renderView = () => {
    if (GridTileComponent && viewGridMode) {
      return (
        <GridComponent
          key={`${customer?.id}${key}`}
          data={data}
          TileComponent={GridTileComponent}
          loading={loading}
          searchText={searchText}
          searchOptions={searchOptions}
          gridTileExtraProps={gridTileExtraProps}
          onRowClicked={onRowClicked}
          columns={Children.toArray(children)}
          {...agGridProps}
        />
      )
    }

    if (Children.count(children) > 0) {
      return (
        <Table
          compactMode={compactMode}
          key={`${customer?.id}${key}`} // force unmounting when switching customer for tabel to show reloading
          tableHeight={tableHeight}
          onRowDataChanged={handleRowDataChanged}
          quickFilterText={searchText}
          rowClassRules={rowClassRules}
          onGridReady={handleGridReady}
          onRowGroupOpened={({ data, node: { expanded } }) => {
            setExpandedNodes((list) =>
              expanded ? [...list, data?.id] : list.filter((id) => id !== data?.id)
            )
          }}
          context={{ selectedId, ...context }}
          onRowClicked={(params) => {
            if (typeof onRowClicked === 'function') {
              onRowClicked(params)
            }
            params.api.redrawRows()
          }}
          defaultColDef={{
            filterParams: { newRowsAction: 'keep', ...(defaultColDef?.filterParams || {}) },
            ...(defaultColDef || {}),
          }}
          {...agGridProps}
        >
          {children}
        </Table>
      )
    }

    return <Empty />
  }

  return (
    <ListWrapper className={className} tableHeight={tableHeight}>
      {!tableOnly && (
        <>
          <Header
            title={title}
            compactMode={compactMode}
            searchText={searchText}
            onSearch={handleSearch}
            onClearFilters={clearFilters}
            onImport={onImport}
            onDownload={!viewGridMode && !hideDownload && handleDownload}
            onReload={!hideRefresh && (() => refetch())}
            onShowGrid={GridTileComponent && handleSetViewGridMode}
            showGrid={viewGridMode}
            refetchting={refetching}
            onCreate={onCreate}
            translateSelectableRoutePicker={translateSelectableRoutePicker}
            hideSelectableRoutePicker={hideSelectableRoutePicker}
            backUrl={backUrl}
            gridIcon={gridIcon}
            disableListNav={disableListNav}
            disableGridNav={disableGridNav}
          >
            {headerContent}
          </Header>
          {subHeaderContent && <ListSubHeader>{subHeaderContent}</ListSubHeader>}
        </>
      )}
      {renderView()}
    </ListWrapper>
  )
}

List.propTypes = {
  translateSelectableRoutePicker: PropTypes.string,
  hideDownload: PropTypes.bool,
  hideRefresh: PropTypes.bool,
  disableRowHighlight: PropTypes.bool,
}

List.defaultProps = {}

export default List
