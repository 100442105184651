import styled from 'styled-components'

import { Label, Value } from 'components/Form/components/Field/styled'

import Field from '../../components/Field'

const ButtonFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    align-items: center;
    flex: none !important;
  }

  ${Label} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`
export default ButtonFieldStyled
