import React from 'react'

import { Slider } from 'antd'

import RangeSliderStyled from './styled'

const RangeSlider = ({ value = [], range, step, disabled, min, max, dataCy, onChange }) => {
  const marks = {
    [min]: min,
    [max]: max,
  }
  return (
    <RangeSliderStyled className="RangeSlider" data-cy={dataCy}>
      <Slider
        range={range}
        value={value}
        disabled={disabled}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        marks={marks}
        tooltipPlacement="bottom"
      />
    </RangeSliderStyled>
  )
}

export default RangeSlider
