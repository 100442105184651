import styled from 'styled-components'

const LogoDropdownStyled = styled.div`
  position: fixed;
  z-index: 910;
  top: 0;
  left: 0;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0 2em;

  &:hover {
    .LogoDropdown__dropdown {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0s;

      > div {
        opacity: 1;
        transition-delay: 0.1s;
      }
    }

    .LogoDropdown__img {
      transform: translateX(0.5rem);
      border: 2px solid transparent;
    }
  }

  .LogoDropdown__img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, border 0.3s ease, background 0.3s ease;
    position: relative;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    transform: translateX(0);
    background: ${({ theme }) => theme.color.sidebarText};
    border: 2px solid ${({ theme }) => theme.color.softStroke};
    padding: 1rem;
    border-radius: 1.4rem;

    img {
      width: 90%;
    }
  }

  .DropdownArrow {
    margin-top: 0.8rem;

    &:after {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: -0.25rem;
      border-color: ${({ theme }) => theme.color.sidebarText};
    }
  }

  .LogoDropdown__dropdown {
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    transition-delay: 0.1s;
    background: ${({ theme }) => theme.color.backgroundFront};
    box-shadow: ${({ theme }) => theme.shadowElevation4(theme.darkMode)};
    z-index: 1;
    border-radius: 0 0 1rem 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-100%);
    padding: 2rem 1rem 3rem 8rem;
    left: 0;
    top: 0;
    min-width: 25rem;

    .DropdownOptions {
      max-height: 70vh;
      overflow: auto;
      min-width: 200px;
    }

    > div {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }
`
export default LogoDropdownStyled
