import { useCallback } from 'react'

import { useFormContext } from 'react-hook-form'

/**
 * Works like react useState hook, but stores the data in the form state.
 * This way when the form is reset, this data will alo get resetted
 * @param string fieldType the name of the component
 * @param string fieldName the name of the field in the form
 * @param string name the name of the variable
 * @param any defaultValue
 * @returns tuple with [value, setFunction]
 */
export const useFieldMetaState = (fieldType, fieldName, name, defaultValue) => {
  const { setValue, watch } = useFormContext() || {}

  const key = `meta.${fieldType}.${fieldName}.${name}`
  const value = watch(key, defaultValue)
  const set = useCallback((value) => setValue(key, value), [setValue, key])

  return [value, set]
}
