import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom'

import { CALCULATIONS } from 'util/constants'

const getGroupHistoryByMethod = (groupHistory, method) => {
  return groupHistory[0].map(({ time }, index) => ({
    time,
    value: CALCULATIONS[method || 'sum'](groupHistory.map((h) => h[index].value)),
  }))
}

export const getHistoryForGroup = ({ history, compareHistory, first, last, method, ...group }) => ({
  ...group,
  method,
  history: getGroupHistoryByMethod(history, method),
  ...(compareHistory?.length && {
    compareHistory: compareHistory[0].map((history, index) =>
      getGroupHistoryByMethod(
        compareHistory.map((history) => history[index]),
        method
      )
    ),
  }),
  ...(first?.length && {
    first: { ...first[0], value: CALCULATIONS[method || 'sum'](first.map(({ value }) => value)) },
  }),
  ...(last?.length && {
    last: { ...last[0], value: CALCULATIONS[method || 'sum'](last.map(({ value }) => value)) },
  }),
})

export const getDatapointErrors = (datapoints) => {
  return datapoints
    ? datapoints?.reduce((errors, { error, name, id }) => {
        if (error) {
          errors.push({
            ...error,
            id,
            details: {
              ...error.details,
              datapointName: <b> {name} </b>,
            },
          })
        }
        return errors
      }, [])
    : []
}

export const getLinkProps = (url) => {
  if (!url) {
    return null
  }
  const isAnchorLink = url && url.startsWith('#')
  return {
    to: isAnchorLink ? null : url,
    onMouseOver: ({ currentTarget }) => (currentTarget.style.color = '#003'),
    onMouseOut: ({ currentTarget }) => (currentTarget.style.color = '#99A'),
    as: isAnchorLink ? AnchorLink : url.startsWith('http') ? 'a' : Link,
    href: (isAnchorLink || url.startsWith('http')) && url,
    offset: isAnchorLink ? 65 : undefined,
    target: url.startsWith('http') ? '_blank' : '_self',
  }
}
