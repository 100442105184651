import React, { useMemo } from 'react'

import SelectField from '../../SelectField'
import { useActivities } from './hooks'

const ActivityCategorySelectField = ({ intl, ...props }) => {
  const { data, loading } = useActivities()

  const options = useMemo(() => {
    return data?.map(({ id, name, enabled }) => ({
      value: id,
      label: name,
      disabled: !enabled,
    }))
  }, [data])

  return <SelectField options={options} loading={loading} {...props} />
}

export default ActivityCategorySelectField
