import React from 'react'

import PropTypes from 'prop-types'
import { useFieldArray } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import { AllFixedFilesSelectField } from 'components/Form/fields/index'
import TagField from 'components/Form/fields/TagField'

import { TagListFieldStyled } from './styled'

const TagListField = ({
  value,
  name,
  filter,
  parentIsEnabled,
  dependencies,
  onChange,
  isVirtual,
  context,
  disableList = [],
  entityType = 7,
  intl,
  ...fieldProps
}) => {
  const { fields, remove, append } = useFieldArray({
    name,
  })
  return (
    <TagListFieldStyled {...fieldProps}>
      {({ loading, watch, getValues, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }

        const filterFn = ({ item }) => {
          if (!filter) {
            return true
          }
          return filter(item, { watch, getValues })
        }

        const renderValue = value === undefined && name === undefined ? value : fields

        const list = renderValue?.map((item, index) => ({ item, index })).filter(filterFn)

        const handleChange = (value, action, itemIndex, array) => {
          if (action === 'delete') {
            remove(itemIndex)
          }
          if (action === 'add') {
            append({ ...value, enabled: true })
          }
          return onChange && onChange(array)
        }

        return (
          <>
            {isEditMode && isVirtual && (
              <AllFixedFilesSelectField
                value={list.map(({ item }) => item)}
                onChange={(value, option) => handleChange(option, 'add', null, value)}
                featureType="tag"
              />
            )}
            {!list || list.length === 0 ? (
              <span>
                {intl
                  ? intl.formatMessage({
                      id: 'componentCaptions.noTags',
                    })
                  : 'No tags'}
              </span>
            ) : (
              list
                .filter(
                  ({ item }) =>
                    !item.isSystem ||
                    [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27].includes(entityType)
                )
                .map(({ item, index }) => (
                  <TagField
                    key={item.id}
                    name={`${name}[${index}]`}
                    parentIsEnabled={parentIsEnabled}
                    onChange={handleChange}
                    readOnly={item.readOnly}
                    tagName={item.name}
                    context={context}
                    softDeleteMode={!isVirtual}
                    itemIndex={index}
                    disabled={disableList.includes(item.name)}
                    defaultValue={item}
                  />
                ))
            )}
          </>
        )
      }}
    </TagListFieldStyled>
  )
}

TagListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      required: PropTypes.bool,
    })
  ),
}

export default TagListField
