import React from 'react'

import { FormattedMessage } from 'react-intl'

import Component from 'components/Global/Component/styled'
import { CanaryModal } from 'components/Global/Modal'
import { useUi } from 'components/Layout/UiProvider/index'
import OfficeViewer from 'components/Modals/OfficeViewer'

const components = {
  OfficeViewer,
}

const Modal = () => {
  const {
    state: { modal },
    action,
  } = useUi()

  const { title, modalComponent, ...modalComponentProps } = modal || {}

  const handleClose = () => {
    action({
      type: 'SET_MODAL',
      payload: null,
    })
  }
  return (
    <CanaryModal
      title={title && <FormattedMessage id={title} />}
      centered
      width={{ OfficeViewer: 948 }[modalComponent] || 500}
      footer={null}
      visible={!!modal}
      onCancel={handleClose}
    >
      <Component {...modalComponentProps} as={components[modalComponent]} />
    </CanaryModal>
  )
}

export default Modal
