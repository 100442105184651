import { css } from 'styled-components'

const Switch = css`
  margin-left: 1.5rem;
`

const ColorPicker = css`
  margin: 0 1.5rem;
  width: auto;
`

const components = {
  Switch,
  ColorPicker,
  Color: ColorPicker,
}

export const Table = css`
  ${({ componentName }) => components[componentName]}
`
