import React from 'react'

import { func, string } from 'prop-types'

import Icon from '../Icon'
import MenuItemStyled from './styled'

const MenuItem = ({ value, icon, href, className, children, ...props }) => {
  return (
    <MenuItemStyled className={`MenuItem ${className}`} as={href ? 'a' : 'div'} {...props}>
      {icon && <Icon icon={icon} />}
      {value}
      {children}
    </MenuItemStyled>
  )
}

MenuItem.propTypes = {
  variant: string,
  href: string,
  onClick: func,
}

MenuItem.defaultProps = {}

export default MenuItem
