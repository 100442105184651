import { calendarTypes } from 'components/Dashboard/Widget/config/selections'

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.calendar',
    category: 'widget.controls',
    keywords: ['calendar', 'time', 'controls'],
    img: '/resources/images/widgets/calendar.png',
    info: {
      'fr-FR':
        '<h2>Calendrier</h2>\n' +
        "<p>Le widget Calendrier affiche le schéma opérationnel d'un appareil de manière graphique.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong>Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '<li><strong>Type </strong> Sélectionnez le type de calendrier : Par défaut ou Activé/Désactivé</li>\n' +
        '<li><strong>Point de données </strong>Sélectionnez un point de données de widget</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez un point de données de widget</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Légendes </strong> Ajouter une étiquette, une icône et un type pour les valeurs fixes</li>' +
        '</ul>',
      'en-US':
        '<h2>Calendar</h2>\n' +
        "<p>The Calendar widget displays a device's operational schema in a graphical way.</p>\n" +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong>Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '<li><strong>Type </strong> Select the type of calendar: Default or On/off</li>\n' +
        '<li><strong>Datapoint </strong>Select a widget datapoint</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select a widget datapoint</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Captions </strong> Add label, Icon and Type for fixed values</li>' +
        '</ul>',
      'nl-NL':
        '<h2>Agenda</h2>\n' +
        '<p>De Agenda-widget geeft het operationele schema van een apparaat op een grafische manier weer.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Pictogram </strong>Widgetpictogram</li>\n' +
        '<li><strong>Kleur </strong>Widgetpictogramkleur</li>\n' +
        '<li><strong>Type </strong> Selecteer het type agenda: Standaard of Aan/uit</li>\n' +
        '<li><strong>Datapunt </strong>Selecteer een widget-datapunt</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunten </strong> Selecteer een widgetgegevenspunt</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Bijschriften </strong> Label, pictogram en type toevoegen voor vaste waarden</li>' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'Calendar',
      title: 'widget.calendar',
      icon: 'fas fa-info',
      color: null,
      calendarType: 'enum',
      datapoints: [],
      captions: {},
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-calendar',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
        col: 'col2 first',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.type' },
        dataKey: 'calendarType',
        componentProps: {
          options: calendarTypes,
          selectionKeys: {
            value: 'value',
            label: 'label',
          },
        },
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoint' },
        componentProps: {
          dataKey: 'datapoints',
          singleDatapoint: true,
          canReselect: false,
        },
      },
      {
        category: 'widget.fixedValues',
        componentName: 'CaptionEditor',
        label: { formatted: 'widget.captions' },
        dataKey: 'captions',
        componentProps: {
          columns: ['value', 'title', 'color'],
        },
      },
    ],
  },
}

export default config
