import React, { useCallback } from 'react'

import get from 'lodash/get'

import { useDashboard } from 'components/Dashboard/DashboardProvider'

import DatapointSelectorButtons from './DatapointSelectorButtons'
import EntityFilter from './EntityFilter'
import { useDatapointsFilter, useFixedUnit } from './hooks'
import DatapointSelectorStyled from './styled'

export const isAddedItem = ({ item, groupId, addedItem, addedItems, selectedDatapoints }) => {
  if (groupId) {
    if (addedItems) {
      return (
        addedItems.some(({ id }) => item.id === id) &&
        !selectedDatapoints.some(({ id, groupId: gId }) => item.id === id && gId === groupId)
      )
    }
    return (
      item.id === addedItem.id &&
      !selectedDatapoints.some(({ id, groupId: gId }) => {
        return item.id === id && gId === groupId
      })
    )
  }
  if (addedItems) {
    return false
  }
  return !selectedDatapoints.some(({ id }) => item.id === id)
}

const DatapointSelector = ({
  value,
  singleDatapoint,
  fixedUnit,
  dataKey = 'datapoints',
  groupUnit,
  groupDataKey,
  groupIndex,
  groupId,
  subPath,
  tags,
  canReselect = true,
  onSave,
  onCancel,
  onValueUpdate,
  tagFilter,
  intl,
}) => {
  const {
    state: { elementInEditMode, isDataEntryDashboard },
    action,
  } = useDashboard()

  const selectedDatapoints =
    value ||
    (elementInEditMode?.preview &&
      get(elementInEditMode?.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`)) ||
    []

  const groupDatapoints = elementInEditMode?.preview?.[groupDataKey]?.[groupIndex]?.datapoints

  const datapointsUnit = useFixedUnit({
    preview: elementInEditMode?.preview,
    groupDataKey,
    groupUnit,
    fixedUnit,
    selectedDatapoints,
    groupDatapoints,
  })

  const datapointAssetsFilter = useCallback(
    (datapoint) => {
      return (
        !(isDataEntryDashboard && datapoint.tags.virtual === '1') &&
        (!fixedUnit || !datapointsUnit || datapoint.unit === datapointsUnit)
      )
    },
    [isDataEntryDashboard, datapointsUnit, fixedUnit]
  )

  const datapointNonAssetsFilter = useCallback(
    (datapoint) => {
      return !fixedUnit || !datapointsUnit || datapoint.unit?.name === datapointsUnit
    },
    [fixedUnit, datapointsUnit]
  )

  const datapointsFilter = useDatapointsFilter({
    datapointAssetsFilter,
    datapointNonAssetsFilter,
    tags,
    preview: elementInEditMode?.preview,
    isDataEntryDashboard,
    groupDataKey,
    groupIndex,
  })

  const onSelectDatapoints = (values, addedItems, addedItem) => {
    const { datapointsToSave, added } = values.reduce(
      (update, { tags, ...item }) => {
        const added = isAddedItem({
          item,
          groupId,
          addedItems,
          addedItem,
          selectedDatapoints,
        })

        update.datapointsToSave.push({ ...item, ...(added && { groupId }) })
        if (added) {
          update.added.push({ ...item, ...(groupId && { groupId }) })
        }
        return update
      },
      {
        datapointsToSave: [],
        added: groupId ? [] : addedItems || [],
      }
    )

    const { tags, ...newItem } = values[values.length - 1] || {}
    const newValue =
      singleDatapoint && selectedDatapoints
        ? Object.keys(newItem).length
          ? [newItem]
          : []
        : datapointsToSave
    const meta = {
      groupDataKey,
      removed: selectedDatapoints.filter(({ id }) => !datapointsToSave.some((dp) => dp.id === id)),
      added,
    }

    if (action) {
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: { dataKey, subPath },
          value: newValue,
          meta,
        },
      })
    }
    onValueUpdate && onValueUpdate(newValue, meta)
  }

  return (
    <DatapointSelectorStyled className="DatapointSelector" height="40vh">
      <EntityFilter
        {...datapointsFilter}
        selectedDatapoints={selectedDatapoints}
        onValueUpdate={onSelectDatapoints}
        canReselect={canReselect}
        tagFilter={tagFilter}
        intl={intl}
        fixedUnit={fixedUnit}
      />
      <DatapointSelectorButtons onSave={onSave} onCancel={onCancel} intl={intl} />
    </DatapointSelectorStyled>
  )
}

export default DatapointSelector
