import React from 'react'

import { TrackStyled } from './styled'

const Track = ({ source, target, getTrackProps, colors = [] }) => {
  return (
    <TrackStyled className="Track" source={source} target={target} colors={colors}>
      <div {...getTrackProps()} />
    </TrackStyled>
  )
}

export default Track
