import styled from 'styled-components'

const ComparisonPointConfiguratorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .NumberInput {
    width: auto;
  }
  .FormField.Switch {
    margin-bottom: -0.5rem;
  }
`
export default ComparisonPointConfiguratorStyled
