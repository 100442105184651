import { motion } from 'framer-motion'
import styled from 'styled-components'

const SlideStyled = styled(motion.li)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .ant-image {
    width: 100%;
  }
`
export default SlideStyled
