import React, { forwardRef } from 'react'

import { ActionStyled } from './styled'

export const Action = forwardRef(({ active, className, cursor, style, onClick, ...props }, ref) => {
  return (
    <ActionStyled
      ref={ref}
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      className={`Action ${className}`}
      tabIndex={0}
      style={{
        ...style,
        cursor,
        '--fill': active?.fill,
        '--background': active?.background,
      }}
    />
  )
})
