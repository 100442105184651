import React from 'react'

import { format } from 'date-fns'
import { enUS, fr, nl } from 'date-fns/locale'
import { injectIntl } from 'react-intl'

import OptionPicker from 'components/Form/components/OptionPicker'

const locales = {
  'nl-NL': nl,
  en: enUS,
  fr: fr,
}

const DateTimeFormatPicker = ({ value, type = 'date', dataKey, onChange, intl }) => {
  const options = {
    date: [
      { value: 'P', label: { text: format(new Date(), 'P', { locale: locales[intl.locale] }) } },
      { value: 'PP', label: { text: format(new Date(), 'PP', { locale: locales[intl.locale] }) } },
      {
        value: 'PPP',
        label: { text: format(new Date(), 'PPP', { locale: locales[intl.locale] }) },
      },
      {
        value: 'PPPP',
        label: { text: format(new Date(), 'PPPP', { locale: locales[intl.locale] }) },
      },
    ],
    time: [
      {
        value: 'kk:mm',
        label: { text: format(new Date(), 'kk:mm', { locale: locales[intl.locale] }) },
      },
      {
        value: 'kk:mm:ss',
        label: { text: format(new Date(), 'kk:mm:ss', { locale: locales[intl.locale] }) },
      },
      {
        value: 'h:mmaa',
        label: { text: format(new Date(), 'h:mmaa', { locale: locales[intl.locale] }) },
      },
      {
        value: 'h:mm:ssaa',
        label: { text: format(new Date(), 'h:mm:ssaa', { locale: locales[intl.locale] }) },
      },
    ],
  }

  return (
    <OptionPicker
      value={value}
      options={options[type]}
      dropdown="open"
      dataKey={dataKey}
      selectionKeys={{ label: 'label.text', value: 'value' }}
      onChange={onChange}
      search={false}
    />
  )
}

export default injectIntl(DateTimeFormatPicker)
