import React, { useCallback, useState } from 'react'

import { GlobalHotKeys } from 'react-hotkeys'

import AboutModal from 'components/Modals/AboutModal'

import ShortcutOverview from './components/ShortcutOverview'
import GotoSearch from './search/GotoSearch/GotoSearch'
import SwitchCustomerSearch from './search/SwitchCustomerSearch'

const keyMap = {
  GOTO: {
    sequence: 'g',
    action: 'keyup',
  },
  SWITCH_CUSTOMER: {
    sequence: 's',
    action: 'keyup',
  },
  VERSIONS: {
    sequence: 'v',
    action: 'keyup',
  },
  CHEATSHEET: {
    sequences: ['?', 'shift+?'],
    action: 'keyup',
  },
  CLOSE: 'esc',
}

export const Shortcuts = ({ children }) => {
  const [show, setShow] = useState()

  const handleClose = useCallback(() => {
    setShow(undefined)
  }, [setShow])

  const handlers = {
    GOTO: () => {
      setShow('GOTO')
    },
    SWITCH_CUSTOMER: () => {
      setShow('SWITCH_CUSTOMER')
    },
    VERSIONS: () => {
      setShow('VERSIONS')
    },
    CHEATSHEET: () => {
      setShow('CHEATSHEET')
    },
    CLOSE: handleClose,
  }

  const renderSearch = () => {
    switch (show) {
      case 'GOTO':
        return <GotoSearch onClose={handleClose} />
      case 'SWITCH_CUSTOMER':
        return <SwitchCustomerSearch onClose={handleClose} />
      case 'VERSIONS':
        return <AboutModal onClose={handleClose} />
      case 'CHEATSHEET':
        return <ShortcutOverview onClose={handleClose} />
      default:
        break
    }
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      {renderSearch()}
      {children}
    </GlobalHotKeys>
  )
}

export default Shortcuts
