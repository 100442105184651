import { fileEndpoint } from 'config'

export const fileUrl = (key) => {
  if (!key) {
    throw new Error('Key for file is empty')
  }

  return `${fileEndpoint}/file/${key}`
}

export default fileUrl
