import { config as gauge } from './../../SolidGauge/Gauge/config'

export const config = {
  ...gauge,
  editProps: {
    ...gauge.editProps,
    name: 'widget.absoluteGauge',
    category: 'widget.gaugesIndicators',
    keywords: [...gauge.editProps.keywords, 'absolute'],
    img: '/resources/images/widgets/absolutegauge.png',
    info: {
      'en-US':
        '<h2>Absolute Gauge</h2>\n' +
        '<p>The Absolute Gauge widget shows real-time data which ranges between a minimum and a maximum for one or more datapoints. The numeric value fills up a half-circle as it approaches its maximum value. The datapoint values are compared to a reference.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's title</li>\n" +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select a widget datapoint</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Min </strong> Determine the minimum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Max </strong> Determine the maximum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Date/Period</strong> Select a custom date/period for the selected value</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Absolute meter</h2>\n' +
        '<p>De Absolute Gauge-widget toont realtime gegevens die variëren tussen een minimum en een maximum voor een of meer gegevenspunten. De numerieke waarde vult een halve cirkel naarmate deze de maximale waarde nadert. De datapuntwaarden worden vergeleken met een referentie.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong> Widgets titel</li>\n' +
        '<li><strong>Pictogram </strong> Widgetpictogram</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunten </strong> Selecteer een widgetgegevenspunt</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '<li><strong>Min </strong> Bepaal de minimumwaarde voor de meter. Dit kan geen, een vaste waarde of een datapunt zijn.</li>\n' +
        '<li><strong>Max </strong> Bepaal de maximale waarde voor de meter. Dit kan geen, een vaste waarde of een datapunt zijn.</li>\n' +
        '<li><strong>Kleuren </strong> Definieer kleuren voor de overgang tussen waarden</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum/periode</strong> Selecteer een aangepaste datum/periode voor de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Jauge absolue</h2>\n' +
        "<p>Le widget Absolute Gauge affiche des données en temps réel qui varient entre un minimum et un maximum pour un ou plusieurs points de données. La valeur numérique remplit un demi-cercle lorsqu'elle approche de sa valeur maximale. Les valeurs des points de données sont comparées à une référence.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez un point de données de widget</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        "<li><strong>Min </strong> Déterminez la valeur minimale de la jauge. Il peut s'agir d'aucune, d'une valeur fixe ou d'un point de données.</li>\n" +
        "<li><strong>Max </strong> Déterminez la valeur maximale de la jauge. Il peut s'agir d'aucune, d'une valeur fixe ou d'un point de données.</li>\n" +
        '<li><strong>Couleurs </strong> Définir les couleurs pour la transition entre les valeurs</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date/période personnalisée</strong> Sélectionnez une date/période personnalisée pour la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
    },
    default: {
      ...gauge.editProps.default,
      type: 'AbsoluteGauge',
      title: 'widget.absoluteGauge',
    },
    preview: {
      icon: 'fas fa-gauge',
      gauge: {
        ...gauge.editProps.preview.gauge,
        series: [
          {
            ...gauge.editProps.preview.gauge.series[0],
            type: 'gauge',
          },
        ],
      },
    },
    fields: gauge.editProps.fields,
  },
}

export default config
