import styled from 'styled-components'

import Field from 'components/Form/components/Field'

export const JsonFieldStyled = styled(Field)`
  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    min-height: 100%;
  }
`
