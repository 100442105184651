import variables from 'theme/variables'

import { configFieldConvertor, removeObjectKeys } from '../../../../Dashboard/utils/common/helpers'
import { config as gauge } from './../../../Gauge/SolidGauge/Gauge/config'

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    ...gauge.editProps,
    name: 'widget.circleGauge',
    category: 'widget.gaugesIndicators',
    keywords: ['gauge', 'indicator', 'circle'],
    img: '/resources/images/widgets/circlegauge.png',
    info: {
      'fr-FR':
        '<h2>Jauge de cercle</h2>\n' +
        "<p>Le widget Jauge circulaire affiche la valeur d'une mesure particulière sur une échelle définie. La valeur est représentée par une barre de couleur progressant autour d'un cercle, tandis que l'échelle est déterminée par une valeur fixe. Le widget affichera à la fois la valeur numérique de la métrique et la progression en pourcentage.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur globale pour le widget</li>\n' +
        '<li><strong>Afficher le pourcentage</strong> Afficher les pourcentages ou non</li>\n' +
        '<li><strong>Afficher la valeur inversée</strong> Bascule entre le montant atteint et le montant restant</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        "<li><strong>Point de comparaison</strong> Comparez le point de données du widget avec une valeur fixe, le(s) point(s) de données principal(s) ou d'autres points de données\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Modifiez l'unité de la valeur du widget</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur du widget</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Circle Gauge</h2>\n' +
        '<p>The Circle Gauge widget displays the value of a particular metric on a set scale. The value is represented by a color bar progressing around a circle, whereas the scale is determined by a fixed value. The widget will display both the numeric value of the metric and the progress as a percentage.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Color</strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Show Percentage</strong> Display percentages or not</li>\n' +
        '<li><strong>Show Inverted Value</strong> Toggles between amount reached and amount left</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Comparison Point</strong> Compare the widget datapoint against a Fixed value, the main datapoint(s) or other datapoint(s)\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the widget value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the widget value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Cirkelmeter</h2>\n' +
        '<p>De widget Cirkelmeter geeft de waarde van een bepaalde metriek weer op een ingestelde schaal. De waarde wordt weergegeven door een kleurenbalk die rond een cirkel loopt, terwijl de schaal wordt bepaald door een vaste waarde. De widget toont zowel de numerieke waarde van de metriek als de voortgang als een percentage.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Percentage weergeven</strong> Percentages weergeven of niet</li>\n' +
        '<li><strong>Toon omgekeerde waarde</strong> Schakelt tussen het bereikte bedrag en het resterende bedrag</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '<li><strong>Vergelijkingspunt</strong> Vergelijk het widgetgegevenspunt met een vaste waarde, het hoofdgegevenspunt(en) of andere gegevenspunt(en)\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de widgetwaarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de widgetwaarde</li>\n' +
        '</ul>',
    },
    default: {
      ...removeObjectKeys(gauge.editProps.default, [
        'minComparatorsMethod',
        'maxComparatorsMethod',
        'minComparators',
        'maxComparators',
        'colors',
      ]),
      type: 'CircleGauge',
      title: 'widget.circleGauge',
      comparators: [],
      comparatorsMethod: 'mean',
    },
    preview: {
      icon: 'fas fa-gauge',
      gauge: {
        series: [
          {
            data: {
              color: variables.color.color.aviary.main,
              radius: '90%',
              innerRadius: '78%',
              y: 40,
            },
            unit: 'm³',
            type: 'solidgauge',
          },
        ],
      },
    },
    fields: configFieldConvertor(gauge.editProps.fields, [
      {
        selector: (field) =>
          field.componentProps?.dataKey === 'minComparators' ||
          field.componentName === 'MinMaxColorRangeSlider' ||
          field.dataKey === 'comparatorUnit',
        resolver: () => 'exclude',
      },
      {
        selector: (field) => field.dataKey === 'color',
        resolver: (field) => [
          field,
          {
            category: 'widget.general',
            componentName: 'Switch',
            label: { formatted: 'widget.showPercentage' },
            dataKey: 'showPercentage',
            col: 'col2 first',
          },
          {
            category: 'widget.general',
            componentName: 'Switch',
            label: { formatted: 'widget.showInvertedValue' },
            dataKey: 'showInvertedValues',
            col: 'col2',
          },
        ],
      },
      {
        selector: (field) => field.componentProps?.dataKey === 'maxComparators',
        resolver: (field) => ({
          ...field,
          label: { formatted: 'widget.comparisonPoint', tagName: 'h4' },
          componentProps: {
            ...field.componentProps,
            dataKey: 'comparators',
            method: 'comparatorMethod',
          },
        }),
      },
      {
        selector: (field) => field.componentProps?.dataKey === 'comparisonPeriod',
        resolver: (field) => ({
          ...field,
          componentProps: {
            ...field.componentProps,
            datapointsDataKey: 'comparators',
          },
        }),
      },
    ]),
  },
}

export default config
