import React from 'react'

import { injectIntl } from 'react-intl'

import { useAllowedCompanies } from 'services/customer'

import Search, { useSearch } from '../../components/Search'
import CustomerResultItem from './CustomerResultItem'

export const SwitchCustomerSearch = ({ onClose, intl }) => {
  const { props, text } = useSearch({ onClose })

  const { data, ...result } = useAllowedCompanies()

  const filteredData = data?.filter((customer) =>
    customer.name.toLowerCase().includes(text.toLowerCase())
  )

  return (
    <Search
      {...props}
      {...result}
      data={filteredData}
      RowComponent={CustomerResultItem}
      placeholder={intl.formatMessage({ id: 'search.switchCustomer.placeholder' })}
    />
  )
}

export default injectIntl(SwitchCustomerSearch)
