import variables from 'theme/variables'

import {
  calculationMethods,
  dashStyles,
  graphTypes,
  optionalSortOrder,
} from 'components/Dashboard/Widget/config/selections'

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.comparisonPieChart',
    category: 'widget.charts',
    keywords: ['chart', 'pie'],
    img: '/resources/images/widgets/piechart.png',
    info: {
      'en-US':
        '<h2>Pie Chart</h2>\n' +
        '<p>The Pie Chart is a special chart that uses "pie slices" to show relative sizes of data. The chart is divided into sectors, where each sector shows the relative size of each value.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Percentage</strong> Display the values as percentages or absolute values</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '<li><strong>Opacity</strong> Determine the opacity of the items</li>\n' +
        '<li><strong>Donut</strong> Determine the donut degree of the pie chart</li>\n' +
        '<li><strong>Sort Order</strong> Select whether to order the values from highest to lowest or vice versa</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groups</strong> Add or select an existing group of datapoints</li>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h3>Group Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Selected Group</strong> This section displays all existing groups and allows to select new datapoints for it</li>\n' +
        '<li><strong>Ungrouped Datapoints</strong> This section displays all selected, but ungrouped datapoints. They can be added to the selected group by clicking on them.</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the group</li>\n' +
        '<li><strong>Color</strong> Select a color for the group</li>\n' +
        '<li><strong>Style</strong> Select a line style for the group</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method for the group</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Color</strong> Select a color for the datapoint</li>\n' +
        '<li><strong>Style</strong> Select a line style for the datapoint</li>\n' +
        '<li><strong>Use Steps</strong> Display the trend in a step wise manner</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Cirkeldiagram</h2>\n' +
        "<p>Het cirkeldiagram is een speciaal diagram dat gebruikmaakt van 'taartsegmenten' om relatieve gegevensgroottes weer te geven. De grafiek is verdeeld in sectoren, waarbij elke sector de relatieve grootte van elke waarde laat zien.</p>\n" +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Toon percentage</strong> Geef de waarden weer als percentages of absolute waarden</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '<li><strong>Dekking</strong> Bepaal de dekking van de items</li>\n' +
        '<li><strong>Donut</strong> Bepaal de donutgraad van het cirkeldiagram</li>\n' +
        '<li><strong>Sorteervolgorde</strong> Selecteer of u de waarden wilt ordenen van hoog naar laag of omgekeerd</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groepen</strong> Een bestaande groep datapunten toevoegen of selecteren</li>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h3>Groepsconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Geselecteerde groep</strong> In deze sectie worden alle bestaande groepen weergegeven en kunt u er nieuwe datapunten voor selecteren</li>\n' +
        '<li><strong>Niet-gegroepeerde gegevenspunten</strong> In deze sectie worden alle geselecteerde, maar niet-gegroepeerde gegevenspunten weergegeven. Ze kunnen aan de geselecteerde groep worden toegevoegd door erop te klikken.</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor de groep</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor de groep</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor de groep</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode voor de groep</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor het datapunt</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor het datapunt</li>\n' +
        '<li><strong>Gebruik stappen</strong> Geef de trend stapsgewijs weer</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Camembert</h2>\n' +
        '<p>Le graphique à secteurs est un graphique spécial qui utilise des « tranches à secteurs » pour afficher les tailles relatives des données. Le graphique est divisé en secteurs, où chaque secteur montre la taille relative de chaque valeur.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher le pourcentage</strong> Afficher les valeurs sous forme de pourcentages ou de valeurs absolues</li>\n' +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        "<li><strong>Opacité</strong> Déterminer l'opacité des éléments</li>\n" +
        '<li><strong>Donut</strong> Déterminer le degré de donut du camembert</li>\n' +
        "<li><strong>Ordre de tri</strong> Sélectionnez s'il faut trier les valeurs de la plus élevée à la plus faible ou vice versa</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groupes</strong> Ajoutez ou sélectionnez un groupe existant de points de données</li>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration de groupe</h3>\n' +
        '<ul>\n' +
        '<li><strong>Groupe sélectionné</strong> Cette section affiche tous les groupes existants et permet de sélectionner de nouveaux points de données</li>\n' +
        '<li><strong>Points de données non groupés</strong> Cette section affiche tous les points de données sélectionnés, mais non groupés. Ils peuvent être ajoutés au groupe sélectionné en cliquant dessus.</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le groupe</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le groupe</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le groupe</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul pour le groupe</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le point de données</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le point de données</li>\n' +
        '<li><strong>Utiliser les étapes</strong> Afficher la tendance par étapes</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'ComparisonPieChart',
      title: 'widget.comparisonPieChart',
      icon: 'fas fa-info',
      color: variables.color.color.aviary.main,
      graphOpacity: 1,
      datapoints: [],
      datapointsGroups: [],
      datapointTotals: [],
      constants: [],
      defaultStartTime: {
        preset: 'past7Days',
      },
      showLegend: true,
      showPercentage: true,
      sortOrder: 'none',
      defaultGranularityValue: 1,
      defaultGranularity: 'd',
      calculationMethod: 'mean',
    },
    unUsedDatapointProperties: ['rangeId', 'offsets'],
    preview: {
      icon: 'fas fa-chart-pie',
      chart: {
        type: 'pie',
        credits: {
          enabled: false,
        },
        series: [
          {
            name: 'Meters',
            colorByPoint: true,
            data: [
              {
                name: 'Meter 1',
                y: 61.41,
                sliced: true,
                selected: true,
              },
              {
                name: 'Meter 2',
                y: 38.59,
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.enableExport' },
        dataKey: 'enableExport',
        col: 'col3 first',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showPercentage' },
        dataKey: 'showPercentage',
        col: 'col3',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showLegend' },
        dataKey: 'showLegend',
        col: 'col3',
      },
      {
        category: 'widget.general',
        componentName: 'RangeSlider',
        label: { formatted: 'widget.opacity' },
        dataKey: 'graphOpacity',
        componentProps: {
          min: 0.1,
          max: 1,
          step: 0.1,
        },
      },
      {
        category: 'widget.general',
        componentName: 'RangeSlider',
        label: { formatted: 'widget.donut' },
        dataKey: 'innerSize',
        componentProps: {
          min: 0,
          max: 100,
        },
      },
      {
        category: 'widget.general',
        label: { formatted: 'widget.sortOrder' },
        dataKey: 'sortOrder',
        componentName: 'OptionPicker',
        componentProps: {
          selectionKeys: {
            value: 'value',
            label: 'label',
          },
          options: optionalSortOrder,
        },
      },
      {
        category: 'widget.datapoints',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          fixedUnit: true,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapoints',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
          groupSection: {
            name: 'datapointsGroups',
            componentName: 'DatapointGroupConfigurator',
            componentProps: {
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'name',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.groupCalculationMethod' },
                  dataKey: 'method',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: calculationMethods,
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapointsGroups',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
          linkedSection: {
            name: 'datapointsLinked',
            componentName: 'LinkedDataConfigurator',
            componentProps: {
              dataKey: 'datapointsLinked',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'name',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                    clearIcon: true,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.useSteps' },
                  dataKey: 'useSteps',
                  col: 'col2',
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.style' },
                  dataKey: 'dashStyle',
                  componentName: 'ValuePicker',
                  componentProps: {
                    options: dashStyles,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: graphTypes,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.groupDatapoints' },
                  dataKey: 'grouped',
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.groupCalculationMethod' },
                  dataKey: 'method',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: calculationMethods.filter(
                      ({ value }) => value !== 'range' && value !== 'amount'
                    ),
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.grouped',
                      dependencyType: 'hasValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                    addRangeMethod: true,
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapointsGroups',
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          range: true,
          dataKey: 'defaultStartTime',
        },
        dataKey: '',
      },
    ],
  },
}

export default config
