import React from 'react'

import dayjs from 'dayjs'
import { useLocation } from 'react-router'

import { setGlobalTime, useAppState } from 'services/store'

import TimePicker from 'components/Dashboard/components/TimePicker'
import { parseDefaultTime } from 'components/Dashboard/components/TimePicker/functions'

const TimeSelection = ({ screen }) => {
  const { state } = useLocation()
  const {
    appState: { globalTime },
    appStateDispatch,
  } = useAppState()

  const changeGlobalTime = (value) => {
    return appStateDispatch(
      setGlobalTime({
        startTime: parseDefaultTime(value, 'startTime'),
        endTime: parseDefaultTime(value, 'endTime'),
      })
    )
  }

  return (
    <TimePicker
      value={{
        startTime: globalTime?.startTime || (state?.startTime && dayjs(state?.startTime)),
        endTime: globalTime?.endTime || (state?.endTime && dayjs(state?.endTime)),
      }}
      onChange={changeGlobalTime}
      screen={screen}
      width={700}
    />
  )
}

export default TimeSelection
