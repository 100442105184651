import React from 'react'

import { adjustHue } from 'polished'
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import { useTheme } from 'styled-components'

import Handle from './Handle'
import SliderRail from './SliderRail'
import ColorRangeSliderStyled from './styled'
import Tick from './Tick'
import Track from './Track'

const domain = [0, 10]

const getNewNumberValue = (value, copyIndex) => {
  if (copyIndex === value.length - 1) {
    return value[copyIndex][0] === 1
      ? value[copyIndex - 1][0] + (1 - value[copyIndex - 1][0]) / 2
      : 1
  }
  return value[copyIndex][0] + (value[copyIndex + 1][0] - value[copyIndex][0]) / 2
}

const ColorRangeSlider = ({ onChange, value, unit, mode, tickRange = [0, 100] }) => {
  const theme = useTheme()
  const _value = value || [
    [0, theme.color.success],
    [0.33, theme.color.warning],
    [0.66, theme.color.critical],
    [1, theme.color.error],
  ]
  const values = _value.map((v) => v[0] * domain[1])

  const onValueChange = (payload) => {
    onChange(_value.map((v, index) => [payload[index] / domain[1], v[1]]))
  }
  const onColorChange = (color, index) => {
    onChange([..._value.slice(0, index), [_value[index][0], color], ..._value.slice(index + 1)])
  }
  const onEdit = (type, index) => {
    if (type === 'delete') {
      if (_value.length > 2) {
        return onChange([..._value.slice(0, index), ..._value.slice(index + 1)])
      }
      return
    }
    const number = getNewNumberValue(_value, index)
    const copy = [number, adjustHue(180, _value[index][1])]
    return onChange([..._value.slice(0, index + 1), copy, ..._value.slice(index + 1)])
  }
  const getTickValue = (tickValue) => {
    const range = tickRange[1] - tickRange[0]
    return Math.round(tickRange[0] + (tickValue / domain[1]) * range)
  }
  return (
    <ColorRangeSliderStyled className="ColorRangeSlider">
      <Slider mode={2} domain={domain} onChange={onValueChange} values={values}>
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle, index) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  color={_value[index][1]}
                  index={index}
                  onColorChange={onColorChange}
                  tickRange={tickRange}
                  mode={mode}
                  unit={unit}
                  onEdit={onEdit}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }, index) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  colors={[_value[index][1], _value[index + 1][1]]}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={5}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick
                  key={tick.id}
                  tick={{ ...tick, value: getTickValue(tick.value) }}
                  count={ticks.length}
                  format={(value) => (unit ? `${value}${unit}` : value)}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </ColorRangeSliderStyled>
  )
}

export default ColorRangeSlider
