import React from 'react'

import { injectIntl } from 'react-intl'

import { FlowFieldConditionStyled } from './styled'

const FlowFieldCondition = ({ children, label, intl }) => {
  return (
    <FlowFieldConditionStyled className="FlowFieldCondition">
      {label && (
        <label>
          <span className="FlowFieldCondition__label">{intl.formatMessage({ id: label })}</span>
        </label>
      )}
      <div className="FlowFieldCondition__content">{children}</div>
    </FlowFieldConditionStyled>
  )
}

export default injectIntl(FlowFieldCondition)
