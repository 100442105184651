import { useAppState } from 'services/store'

const ErrorHandler = () => {
  const {
    appState: { logoutUser },
  } = useAppState()
  const CheckAuth = (error) => {
    // Check if there are graphql errors
    if (error?.graphQLErrors[0]) {
      if (error?.graphQLErrors[0]?.message === 'Account not authenticated') {
        logoutUser(true)
      }
      if (error?.graphQLErrors[0]?.message === 'Account has insufficient permission') {
        return 'insufficientPermissions'
      }
    }
    // Handle middleware errors from the server here
    if (error?.networkError?.statusCode === 401) {
      logoutUser(true)
    }
  }

  return [CheckAuth]
}

export default ErrorHandler
