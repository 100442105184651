import React from 'react'

import { useTheme } from 'styled-components'

import { useLastDatapointQuery } from 'services/datapoints'

import { handleError } from 'components/Dashboard/functions'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

import { useLocaleNumber } from '../../../../../util/numbers'
import HighlightStyled, { ResizeHandler } from './styled'

const Highlight = ({
  id,
  captions = {},
  onResizeStart,
  rotation,
  color,
  inheritedColor,
  circle,
  opacity,
  isSelected,
  dataProps = {},
  newSocketValue,
  className,
  hoveredEdge,
}) => {
  const { data, loading, error } = useLastDatapointQuery(id)
  const localizeNumber = useLocaleNumber()
  const { lastHistoryRecord } = data?.datapoint || {}
  const value = localizeNumber(newSocketValue?.value || lastHistoryRecord?.value)
  const theme = useTheme()

  const loadingHandler = error
    ? handleError(error, !data?.datapoint)
    : loading && { spinner: { type: 'lineScale', size: 10 } }

  const caption =
    Object.keys(captions).length > 0 &&
    (((value || value === 0) && {
      ...captions[value],
      color:
        (captions[value] && captions[value].color) ||
        [theme.color.error, theme.color.success][value],
    }) ||
      {})

  return (
    <HighlightStyled
      className={`Highlight ${className}`}
      value={value}
      rotation={rotation}
      color={caption.color || inheritedColor || color}
      inheritedColor={inheritedColor}
      circle={circle}
      opacity={opacity}
      isSelected={isSelected}
      isPreviewMode={dataProps.isPreviewMode}
      hoveredEdge={hoveredEdge}
    >
      <ResizeHandler
        className="ResizeHandler"
        id="rotate"
        color={caption.color || color}
        onMouseDown={(e) => onResizeStart && onResizeStart(e, 'topLeft')}
      />
      <ResizeHandler
        className="ResizeHandler"
        id="resize"
        color={caption.color || color}
        onMouseDown={(e) => onResizeStart && onResizeStart(e, 'bottomRight')}
      />
      <div className={`Highlight__content`} />
      <WidgetLoader {...loadingHandler} loading={!!loadingHandler} />
    </HighlightStyled>
  )
}

export default Highlight
