import React from 'react'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { Field } from 'components/Form'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import SelectionText from 'components/Form/components/SelectionText/SelectionText'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'

const SelectField = ({
  children,
  name,
  options,
  defaultValue,
  value = true,
  disabled,
  validation,
  disableClick,
  size,
  onChange,
  label,
  fullWidth,
  selectionKeys,
  allowClear = false,
  dropdownRender,
  optionComponent,
  filters,
  open,
  width,
  showRequired,
  placeholder,
  variant,
  onOptionsLoaded,
  valueElement,
  loading,
  search = true,
  ...props
}) => {
  return (
    <Field
      {...props}
      label={label}
      required={showRequired && validation?.required}
      fullWidth={fullWidth}
    >
      {({ control, errors, isEditMode, loading: formLoading }) => {
        if (!name) {
          if (loading) {
            return <Skeleton width="200px" />
          }

          return (
            <ValuePicker
              {...{
                value,
                options,
                disabled,
                onChange,
                fullWidth,
                dropdownRender,
                selectionKeys,
                open,
                optionComponent,
                filters,
                width,
                placeholder,
                variant,
                onOptionsLoaded,
                search,
                allowClear,
              }}
            />
          )
        }

        const error = get(errors, name)
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validation}
            render={({ onChange: fromFieldOnChange, value: formFieldValue }) => {
              if (formLoading || loading) {
                return <Skeleton width="200px" />
              }

              if (isEditMode) {
                return (
                  <>
                    <ValuePicker
                      value={formFieldValue}
                      onChange={(value, itemObject) => {
                        fromFieldOnChange(value)
                        if (typeof onChange === 'function') {
                          onChange(value, itemObject)
                        }
                      }}
                      {...{
                        options,
                        disabled,
                        error,
                        fullWidth,
                        dropdownRender,
                        selectionKeys,
                        allowClear,
                        open,
                        optionComponent,
                        filters,
                        width,
                        placeholder,
                        variant,
                        onOptionsLoaded,
                        search,
                      }}
                    />
                    <FieldMessage message={error} label={label} />
                  </>
                )
              }

              if (options) {
                return <span>{options.find(({ value }) => value === formFieldValue)?.label}</span>
              }

              return (
                <SelectionText
                  value={formFieldValue}
                  options={options}
                  selectionKeys={selectionKeys}
                />
              )
            }}
          />
        )
      }}
    </Field>
  )
}

ValuePicker.propTypes = {
  fullWidth: PropTypes.bool,
  allowClear: PropTypes.bool,
}

export default SelectField
