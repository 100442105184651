import { compile } from 'expression-eval'

const customLogicRunner = (code, vars) => {
  try {
    const fn = compile(code)
    return fn(vars)
  } catch (error) {
    return 0
  }
}

export default customLogicRunner
