import React from 'react'

import { Progress as AntProgress } from 'antd'
import PropTypes from 'prop-types'

import ProgressStyled from './styled'

const Progress = ({ value, size = 'small', color, status }) => {
  return (
    <ProgressStyled className="Progress">
      <AntProgress percent={value} size={size} status={status} strokeColor={color} />
    </ProgressStyled>
  )
}

Progress.propTypes = {
  selectOptions: PropTypes.array,
}

Progress.defaultProps = {
  selectOptions: [],
}

export default Progress
