import React from 'react'

import { AvailableItemStyled } from 'components/Form/components/DndMapper/components/styled'

const AvailableItem = ({ caption }) => {
  const handleDragStart = (source) => {
    source.dataTransfer.setData('id', source.target.id)
  }

  return (
    <AvailableItemStyled draggable={true} onDragStart={handleDragStart} id={caption}>
      <span className="AvailableItem__caption">{caption}</span>
    </AvailableItemStyled>
  )
}

export default AvailableItem
