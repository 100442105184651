import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import SelectField from '../../SelectField'

const educations = [
  'primary_education',
  'basic_education',
  'secondary_education',
  'college_graduate',
  'bachelor',
  'master',
  'doctor',
]

const EducationSelectField = ({ intl, ...props }) => {
  const options = useMemo(() => {
    return educations.map((label, value) => ({
      value,
      label: intl.formatMessage({ id: `form.field.education.${label}` }),
    }))
  }, [intl])

  return <SelectField {...props} options={options} />
}

export default injectIntl(EducationSelectField)
