import React, { useEffect, useState } from 'react'

import { injectIntl } from 'react-intl'

import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useActionConfirmation } from 'components/Dashboard/components/ActionConfirmation/hooks'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

import Mode from './Mode/Mode'
import ModeControl from './ModeControl'
import OnOffControl from './OnOffControl'
import PlusMinusControl from './PlusMinusControl'
import { Buttons, DataPointLabelStyled } from './styled'

const DatapointLabel = ({
  range = [0, 100],
  increments,
  id,
  writeDatapoint = [],
  label,
  isMobileView,
  controlType,
  color,
  colorOn,
  colorOff,
  iconOn,
  iconOff,
  buttons: modeButtons,
  hoveredEdge,
  enlargeLabelOnHover,
  newSocketValue,
  location,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue?.value?.value)
  const localizeNumber = useLocaleNumber()
  const [confirmation, onConfirmationRequest] = useActionConfirmation()

  const { data: apiResponse } = useQueryData({
    service: 'datapoints',
    payload: { datapoints: [{ id }], widgetType: 'datapointLabel' },
    deps: [],
    skip: initialValue !== 'IN_EDIT',
  })

  let unit
  let name
  let last
  if (initialValue !== 'IN_EDIT') {
    unit = initialValue?.unit
    name = initialValue?.name
    last = initialValue?.value?.value
  } else {
    unit = apiResponse?.data?.datapoints?.length > 0 ? apiResponse?.data?.datapoints[0].unit : null
    name = apiResponse?.data?.datapoints?.length > 0 ? apiResponse?.data?.datapoints[0].name : null
    last =
      apiResponse?.data?.datapoints?.length > 0
        ? apiResponse?.data?.datapoints[0].last?.value
        : null
  }

  useEffect(() => {
    if (newSocketValue) {
      setValue(newSocketValue.value)
    }
  }, [newSocketValue])

  useEffect(() => {
    if (last) {
      setValue(last)
    }
  }, [last])

  useEffect(() => {
    if (confirmation.isConfirmed) {
      setValue(confirmation.value)
    }
  }, [confirmation])

  let buttons

  switch (controlType) {
    case 'increment':
      buttons = (
        <PlusMinusControl
          setValue={setValue}
          value={value}
          min={range[0]}
          max={range[1]}
          increments={increments}
          datapoint={{ id }}
          writeDatapoint={writeDatapoint[0]}
          onConfirmationRequest={onConfirmationRequest}
          confirmation={{ ...confirmation, location }}
          unit={unit}
        />
      )
      break
    case 'toggle':
      buttons = (
        <OnOffControl
          setValue={setValue}
          value={value}
          iconOn={iconOn}
          iconOff={iconOff}
          colorOn={colorOn}
          colorOff={colorOff}
          datapoint={{ id }}
          writeDatapoint={writeDatapoint[0]}
          onConfirmationRequest={onConfirmationRequest}
          confirmation={{ ...confirmation, location }}
        />
      )
      break
    case 'modeControl':
      buttons = (
        <ModeControl
          value={value}
          unit={unit}
          datapoint={{ id }}
          writeDatapoint={writeDatapoint[0]}
          onConfirmationRequest={onConfirmationRequest}
          confirmation={{ ...confirmation, location }}
        />
      )
      break
    case 'mode':
      buttons = (
        <Mode
          value={value}
          unit={unit}
          datapoint={{ id }}
          writeDatapoint={writeDatapoint[0]}
          onConfirmationRequest={onConfirmationRequest}
          confirmation={{ ...confirmation, location }}
          buttons={modeButtons}
        />
      )
      break
    default:
  }

  // const _loading = !initialValue && { spinner: { type: 'lineScale', size: 8 } }

  const _loading = !initialValue && { spinner: { type: 'lineScale', size: 8 } }
  const isModeControl = controlType === 'modeControl' || controlType === 'mode'

  return (
    <DataPointLabelStyled
      className={`DataPointLabel ${confirmation.isActive ? 'confirmation' : ''}`}
      title={!isMobileView && (label || name)}
      kpiValue={isModeControl ? undefined : localizeNumber(value)}
      kpiUnit={!isModeControl && unit}
      description={label || name}
      buttons={!!buttons}
      color={color}
      hoveredEdge={hoveredEdge}
      enlargeLabelOnHover={enlargeLabelOnHover}
      modeControl={isModeControl}
    >
      {buttons && (
        <Buttons className="Buttons" modeControl={isModeControl}>
          {buttons}
        </Buttons>
      )}
      <WidgetLoader {..._loading} loading={!initialValue} />
    </DataPointLabelStyled>
  )
}

export default injectIntl(DatapointLabel)
