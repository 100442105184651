import { gql, useQuery } from '@apollo/client'

export const ALLOWED_COMPANIES_QUERY = gql`
  query allowedCompanies($filter: AllowedCompaniesFilter) {
    allowedCompanies(filter: $filter) {
      id
      name
      entity {
        id
      }
      isActive
      dealer {
        id
      }
    }
  }
`

export const useAllowedCompanies = () => {
  const { data, error, loading, ...info } = useQuery(ALLOWED_COMPANIES_QUERY, {
    variables: { filter: { isActive: true } },
    fetchPolicy: 'network-only',
  })

  if (error) {
    console.error(error)
  }

  return { data: data?.allowedCompanies || null, error, loading, ...info }
}
