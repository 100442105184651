import { gql, useQuery } from '@apollo/client'

const QUERY_SEARCH = gql`
  query Search($text: String!) {
    search(text: $text) {
      ... on MenuItemSearchResult {
        id
        title: menuTitle
        menu
        url
      }
      ... on DashboardSearchResult {
        id
        title: dashboardTitle
        key
      }
    }
  }
`

export const useSearchQuery = (text) => {
  return useQuery(QUERY_SEARCH, { variables: { text }, skip: !text })
}
