import { gql, useQuery } from '@apollo/client'

export const LANGUAGES_QUERY = gql`
  query languages {
    languages {
      id
      name
      code
    }
  }
`

export const useLanguages = () => {
  const { data, ...info } = useQuery(LANGUAGES_QUERY)

  return { data: data?.languages, ...info }
}
