import { transparentize } from 'polished'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'

export const MenuListItemStyled = styled.div`
  ${({ theme, isOpen, level, dropdownIsOpen }) => css`
    overflow: hidden;
    position: relative;
    border-radius: ${({ level }) => level < 1 && '1rem'};
    font-size: ${theme.screenSize.screen.current === 's' ? '1.2rem' : '1rem'};
    margin: ${level === 0 && '0 0 .5rem'};

    ${dropdownIsOpen &&
    level === 0 &&
    css`
      background: ${transparentize(0.92, theme.color.sidebarText)};

      > .Button {
        box-shadow: ${level === 0 && '0 0 20px rgba(0, 0, 0, 0.15)'};
        background: ${({ theme }) => transparentize(0.95, theme.color.sidebarText)};
      }

      > ${DropdownArrow} {
        transform: rotate(180deg);
      }
    `}
    ${DropdownArrow} {
      position: absolute;
      right: 0;
      top: ${theme.screenSize.screen.current === 's' ? '1rem' : '.7rem'};
      width: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      height: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;
      z-index: 20;

      &:after {
        transition: opacity 0.2s ease;
        left: auto;
        top: auto;
        width: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
        height: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
        border-color: ${({ theme }) => theme.color.sidebarText};
        opacity: 0.8;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    > .Button {
      padding: ${(isOpen || level === 0) &&
      (theme.screenSize.screen.current === 's'
        ? '1.8rem 1rem 1.8rem 2.5rem'
        : '1rem 1rem 1rem 2.5rem')};
      line-height: ${(isOpen || level === 0) && 1.2};
      z-index: 10;
    }
  `}
`
export const MenuListItemButton = styled(NavLink)`
  justify-content: flex-start;
  transition: padding 0.2s ease, lin-height 0.2s ease;
  padding-left: ${({ icon }) => (icon ? '2.5rem' : '1.5rem')} !important;
  line-height: 0;
  display: flex;
  position: relative;
  opacity: ${({ disabled }) => disabled && 0.5};

  &:hover,
  &:focus {
    .Button__label {
      opacity: 1;
    }
  }

  &.active {
    .Button__label {
      opacity: 1;
    }

    .Icon {
      opacity: 1;
    }
  }

  .Button__label {
    transition: opacity 0.2s ease;
    margin-left: ${({ icon }) => icon && '1.5rem'};
    white-space: nowrap;
    color: ${({ theme }) => theme.color.sidebarText};
    opacity: 0.7;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1em;
  }

  .Icon {
    position: absolute;
    top: ${({ theme }) => (theme.screenSize.screen.current === 's' ? '1s.8rem' : '1rem')};
    left: 1.5rem;
    width: 1.2rem;
    height: 1.2rem;
    color: ${({ theme }) => theme.color.sidebarText};
    opacity: 0.6;

    img,
    svg {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
    }

    path {
      transition: color 0.2s ease;
    }
  }
`
