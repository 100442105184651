export default {
  'settings.menu.users': 'Paramètres utilisateur',
  'settings.menu.users.users': 'Utilisateurs et entreprises',
  'settings.menu.users.roles': 'Roles',
  'settings.menu.data': 'Paramètres des données',
  'settings.menu.data.datapoints': 'Points de mesure',
  'settings.menu.data.environmentSetup': `Configuration de l'environnement`,
  'settings.menu.data.rules': 'Règles',
  'settings.menu.data.flows': 'Flux',
  'settings.menu.data.import': 'Importer',
  'settings.menu.data.importETL': 'Importer (ETL)',
  'settings.menu.data.reportTemplates': 'Modèles de documents',
  'settings.menu.data.organisation': 'Organisation',
  'settings.menu.data.conversionFactors': 'Facteurs de conversion',
  'settings.menu.data.dataManagement': 'Gestion des données',
  'settings.menu.admin': 'Canary admin',
  'settings.menu.admin.customers': 'Clients',
  'settings.menu.admin.entityTypeManager': `Types d'entité`,
  'settings.menu.admin.fixedFiles': 'Fichiers fixes',
  'settings.menu.admin.fixedFileConfiguration': 'Configuration des fichiers fixes',
  'settings.menu.admin.menu': 'Menu',
  'settings.menu.admin.logging': 'Logging',

  // Datapoints
  'settings.trendline.title': 'Trendline',
  'settings.loading': 'Chargement...',
  'settings.noData': 'Pas de données',
  'settings.id': 'id',
  'settings.showAll': 'Afficher tout',
  'settings.actualValue': 'Valeur actuelle',
  'settings.created': 'Créé',
  'settings.updated': 'Actualisé',
  'settings.timestamp': 'Horodatage',
  'settings.status': 'Statut',
  'settings.equipment': 'Equipment',
  'settings.tagsFields': 'Balises et champs',
  'settings.markers': 'Marqueurs',
  'settings.actions': 'Actions',
  'settings.description': 'Description',
  'settings.tags': 'Balises',
  'settings.datapoints': 'Points de données',
  'settings.filesRemarks': 'Dossiers et remarques',
  'settings.unit': 'Unité',
  'settings.entityPreset': "Préréglage d'entité",
  'settings.entities.equipmentMoveFailed': 'Equipments ne peuvent être déplacés que dans une pièce',
  'settings.entities.equipmentMoveSucces': ' a été déplacé dans la pièce ',
  'settings.entities.chooseLocation': 'Choisissez un emplacement',
  'settings.entities.deleteEntity': "Supprimer l'entité",
  'settings.entities.duplicateEntity': "Dupliquer l'entité",
  'settings.catches.deleteCatch': 'Supprimer le catch',
  'settings.entities.entity': 'Entité',
  'settings.entities.department': 'Département',
  'settings.rules': 'Règles',
  'settings.rules.curErr.name': 'Erreur Actuelle',
  'settings.rules.heartbeat.name': 'Heartbeat',
  'settings.rules.minVal.name': 'Détection de la valeur minimale',
  'settings.rules.maxVal.name': 'Détection de la valeur maximale',
  'settings.rules.filter.name': 'Alarme de Maintenance de filtre',
  'settings.rules.fireAlarm.name': 'Alarme incendie',
  'settings.rules.outDoorTempDiff.name': 'Différence Température extérieure',
  'settings.rules.switchActions.name': "Activité maximale de l'interrupteur",
  'settings.rules.co2LevelCheck.name': 'Détection du niveau de CO2',
  'settings.rules.ReportingPeriodControlYearly.name': 'ReportingPeriodControlYearly',
  'settings.rules.ReportingPeriodControlQuarterly.name': 'ReportingPeriodControlQuarterly',
  'settings.rules.ReportingPeriodControlMonthly.name': 'ReportingPeriodControlMonthly',
  'settings.rules.ReportingPeriodControlWeekly.name': 'ReportingPeriodControlWeekly',
  'settings.rules.ReportingPeriodControlDaily.name': 'ReportingPeriodControlDaily',
  'settings.rules.ReportingPeriodControlHourly.name': 'ReportingPeriodControlHourly',
  'settings.rules.leakage.name': 'Détection de fuite',
  'settings.rules.overflow.name': 'Détection de débordement',
  'settings.rules.curErr.fileName': 'curErr.png',
  'settings.rules.heartbeat.fileName': 'heartbeat.png',
  'settings.rules.minVal.fileName': 'minVal.png',
  'settings.rules.maxVal.fileName': 'maxVal.png',
  'settings.rules.filter.fileName': 'filter.png',
  'settings.rules.fireAlarm.fileName': 'fireAlarm.png',
  'settings.rules.outDoorTempDiff.fileName': 'outDoorTempDiff.png',
  'settings.rules.switchActions.fileName': 'switchActions.png',
  'settings.rules.co2LevelCheck.fileName': 'co2LevelCheck.png',
  'settings.rules.ReportingPeriodControlYearly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlQuarterly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlMonthly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlWeekly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlDaily.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlHourly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.leakage.fileName': 'leakage.png',
  'settings.rules.overflow.fileName': 'overflow.png',
  'settings.rules.curErr.description':
    'Le système surveille la valeur du point de référence et génère un squeal dès que la valeur 1 est atteinte.',
  'settings.rules.heartbeat.description':
    "Le système vérifie l'état de vie d'un appareil en fonction de son heartbeat. Si ce hearteat est manquant pendant la période de temps indiquée ci-dessous, le système génère un squeal.",
  'settings.rules.minVal.description':
    "Le système vérifie que la valeur n'est pas inférieure à la valeur minimale ci-dessous. Si la valeur est comprise entre la valeur minimale et la valeur critique, une valeur linéaire du squeal est calculée. Si la valeur est inférieure à la valeur minimale critique, le squeal généré est de 100 %.",
  'settings.rules.maxVal.description':
    "Le système vérifie que la valeur n'est pas supérieure à la valeur maximale ci-dessous. Si la valeur est comprise entre la valeur maximale et la valeur critique, une valeur linéaire du squeal est calculée. Si la valeur est supérieure à la valeur maximale critique, le squeal généré est de 100 %.",
  'settings.rules.filter.description':
    'Le système surveille la valeur du point de données et génère un squeal si la valeur de maintenance du filtre est atteinte.',
  'settings.rules.fireAlarm.description':
    "Le système génère un squeal dès qu'une valeur d'état d'incendie potentiel est reçue.",
  'settings.rules.outDoorTempDiff.description':
    'Le système surveille la différence entre la température OpenWeather et la température extérieure mesurée par un thermomètre local. Si la différence dépasse 5 degrés, une alarme est déclenchée.',
  'settings.rules.switchActions.description':
    "Le système compte le nombre de fois qu'un interrupteur est déclenché. Dès que le nombre maximum ci-dessous est atteint, un squeal est généré.",
  'settings.rules.co2LevelCheck.description':
    "Le système vérifie que la valeur n'est pas supérieure à la valeur maximale (900 ppm). Si la valeur est comprise entre la valeur maximale et la valeur critique (1200ppm), une valeur linéaire du squeal est calculée. Si la valeur est supérieure à la valeur maximale critique, le squeal généré est de 100 %.",
  'settings.rules.ReportingPeriodControlYearly.description': ' ',
  'settings.rules.ReportingPeriodControlQuarterly.description': ' ',
  'settings.rules.ReportingPeriodControlMonthly.description': ' ',
  'settings.rules.ReportingPeriodControlWeekly.description': ' ',
  'settings.rules.ReportingPeriodControlDaily.description': ' ',
  'settings.rules.ReportingPeriodControlHourly.description': ' ',
  'settings.rules.leakage.description':
    "Le système vérifie pour la période ci-dessous durant la période de contrôle ci-dessous que la valeur n'est pas inférieure à la valeur minimale ci-dessous. Si la valeur est comprise entre la valeur minimale et la valeur critique, une valeur linéaire du squeal est calculée. Si la valeur est inférieure à la valeur minimale critique, le squeal généré est de 100 %.",
  'settings.rules.overflow.description':
    "Le système vérifie pour la période ci-dessous durant la période de contrôle ci-dessous que la valeur n'est pas supérieure à la valeur maximale ci-dessous. Si la valeur est comprise entre la valeur maximale et la valeur critique, une valeur linéaire du squeal est calculée. Si la valeur est supérieure à la valeur maximale critique, le squeal généré est de 100 %.",

  // ETL

  'settings.menu.etl.management': 'ETL Management',

  // Organisations

  'settings.menu.organisation': 'Paramètres organisation',
  'settings.menu.organisation.management': 'Gestion organisation',
  'settings.menu.organisation.structure': 'Structure organisation',
  'settings.menu.organisation.environment': 'Environnement organisation',

  // Invoice & Settings
  'settings.menu.invoiceSettings': 'Facturation & Paramètres',
  'settings.menu.invoiceSettings.products': 'Mes Produits',
  'settings.menu.invoiceSettings.licenses': 'Licenses',
  'settings.menu.invoiceSettings.customer': 'Données du client',
  'settings.menu.invoiceSettings.graphics': 'Graphique',
  'settings.menu.invoiceSettings.marketplace': 'Marketplace',
  'settings.menu.invoiceSettings.activity': 'Activité des utilisateurs',

  // Entities
  'settings.entities.title': `Conf. de l'env.`,

  // Entity categories
  'settings.entity.category.assets': 'Actifs',
  'settings.entity.category.persona': 'Personnes',
  'settings.entity.category.vehicles': 'Véhicules',
  'settings.entity.category.finance': 'La finance',
  'settings.entity.category.varia': 'Varia',
  'settings.entity.category.valueChain': 'Activités Amont/Aval',
  'settings.entity.category.degreeDays': 'Degrés-Jours Equivalents',

  // User overview
  'settings.users.inviteCompany': 'inviter une entreprise.',
  'settings.users.manageInvites': 'Géstion des invitations non-envoyées',
  'settings.users.inviteUser': 'inviter une utilisateur.',
  'settings.users.users': 'Utilisateurs',
  'settings.users.companies': 'Entreprises',
  'settings.users.pending': 'En attente',
  'settings.users.active': 'Actif',
  'settings.users.inActive': 'Désactivé',
  'settings.users.delete': 'Annuler invitation',
  'settings.users.renew': 'Réinviter',
  'settings.users.inviteCancelled': 'Invitation annulée',
  'settings.users.inviteRenewed': 'Inviation renouvelée',
  'settings.users.inviteFailed': 'Invitation échouée',
  'settings.users.sendInvite': 'Envoyer invitation',
  'settings.users.editInvite': 'Editer invitation',
  'settings.users.sendInviteConfirm': 'Envoyer invitation?',
  'settings.users.editInviteConfirm': 'Editer cette invitation?',

  // User detail
  'settings.userDetail.noRolesFound': 'Aucun rôle trouvé',

  // Invite a company modal
  'settings.inviteCompany.success': ' a été invité!',
  'settings.inviteCompany.failed': " n'a pas pu être invité",
  'settings.inviteCompany.title': 'Inviter une entreprise',

  // Edit a company modal
  'settings.editCompany.title': "Modifier l'accès de l'entreprise",
  'settings.editCompany.failed': "L'accès à l'entreprise n'a pas pu être modifié",
  'settings.editCompany.success': 'Accès entreprise modifié!',

  // Invite a user modal
  'settings.inviteUser.title': 'Inviter utilisateur',
  'settings.inviteUser.success': 'Utilisateur a été invité!',
  'settings.inviteUser.saved': 'Invitation a été sauvegardée.',
  'settings.inviteUser.bulkSuccess': 'Invitations sont envoyées!',
  'settings.inviteUser.doubleDashboards':
    'Le même tableau est attribué plusiers fois avec des droits différents',
  'settings.inviteUser.failed': "Utilisateur n'a pas pu être invité",

  // Edit a user modal
  'settings.editUser.title': "Modifier l'utilisateur",
  'settings.editUser.failed': "L'utilisateur n'a pas pu être modifié, réessayez.",
  'settings.editUser.success': 'Utilisateur ajusté!',

  // Edit a customer modal
  'settings.editCustomer.title': 'Modifier client',
  'settings.createCustomer.title': 'Créer client',
  'settings.editCustomer.failed': "Client n'a pas pu être modifié, réessayez.",
  'settings.editCustomer.success': 'Client ajusté!',
  'settings.editCustomer.dealerHasClients':
    'Revendeur ne peut pas être déactivé comme il y a des clients connectés.',
  'settings.editCustomer.confirmChangeDealer':
    'Etes-vous sûr de changer le revendeur? Toutes les permissions seront transférées!',
  'settings.insertCustomer.success': 'Client enregistré!',
  'settings.insertCustomer.failed': 'Client pouvait pas étre enregistré.',
  'settings.editCustomer.modules': 'Modules',
  'settings.editCustomer.license': 'License',

  'settings.editCustomer.modules.audit': 'Audit',
  'settings.editCustomer.modules.audit.sdg': 'SDG Audit',
  'settings.editCustomer.modules.audit.dataReadiness': 'Data Readiness',
  'sustainability.menu.configuration': 'Configuration',
  'sustainability.menu.config.research': 'Recherche',
  'sustainability.menu.config.configuration-assistant': 'Assistant de Configuration',
  'sustainability.menu.config.data-survey': 'Enquête de Données',
  'sustainability.menu.config.validation': 'Validation',
  'settings.editCustomer.modules.modules': 'Durabilité',
  'settings.editCustomer.modules.modules.csrd':
    'CSRD (Corporate Sustainability Reporting Directive)',
  'settings.editCustomer.modules.modules.esg': 'ESG (Environment Social Governance)',
  'settings.editCustomer.modules.modules.ghg': 'GHG (Greenhouse Gas Protocol)',
  'settings.editCustomer.modules.modules.gri': 'GRI (Global Reporting Initiative)',
  'settings.editCustomer.modules.modules.sbti': 'SBTi (Science Based Targets initiative)',
  'settings.editCustomer.modules.modules.sdg': 'SDG (Sustainable Development Goals)',
  'settings.editCustomer.modules.dataAccess': 'Data Access',
  'settings.editCustomer.modules.dataAccess.oData': 'OData',
  'settings.editCustomer.modules.dataAccess.restApi': 'REST API',
  'settings.editCustomer.modules.services.smsModule': 'SMS',
  'settings.editCustomer.modules.services': 'Services',
  'settings.editCustomer.modules.general': 'Varia',
  'settings.editCustomer.modules.general.inviteCompany': 'Inviter des Entreprises',

  // Edit Profile
  'settings.updateProfile.success': 'Profil mis à jour!',
  'settings.updateProfile.failed': 'Mise à jour pas réussie.',
  'settings.updateProfile.title': 'Editer profil',
  'settings.updateProfile.changePassword': 'CHANGE MOT DE PASSE (OPTIONNEL)',
  'settings.updateProfile.oldPassword': 'Mot de passe actuel',
  'settings.updateProfile.newPassword': 'Nouveau mot de passe',
  'settings.updateProfile.confirmPassword': 'Confirmez nouveau mot de passe',
  'settings.upateProfile.UpdatePasswordIncomplete':
    'Veuillez remplir tous les champs "Mot de passe" pour changer mot de passe',
  'settings.updateProfile.advanced.active.success': 'Votre compte est maintenant en mode avancé',
  'settings.updateProfile.advanced.disabled.success': 'Votre compte est maintenant en mode normal',
  'settings.updateProfile.advanced.failed': 'Erreur de modification du mode avancé',

  // Fixed permissions
  'settings.fixedpermission.USER_MANAGEMENT': 'Gestion des utilisateurs',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT': 'Gestion des points de données',
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT': 'Rôles et gestion des autorisations',
  'settings.fixedpermission.RULE_MANAGEMENT': 'Gestion des règles',
  'settings.fixedpermission.FLOW_MANAGEMENT': 'Gestion des flux',
  'settings.fixedpermission.PERSONA_MANAGEMENT': 'Gestion des persona',
  'settings.fixedpermission.SUSTAINABILITY_VISITOR': 'Saisie des Données - Visiteur',
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR': 'Saisie des Données - contributeur',
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR': 'Saisie des Données - administrateur',
  'settings.fixedpermission.ODATA_USER': 'OData utilisateur',
  'settings.fixedpermission.RESTAPI_USER': 'REST API utilisateur',
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR': 'Administration des organisations',
  'settings.fixedpermission.SQUEAL_MANAGEMENT': 'Gestion des anomalies',
  'settings.fixedpermission.IMPORT_VISITOR': 'Import visiteur',
  'settings.fixedpermission.IMPORT_EXECUTOR': 'Import exécuteur',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR': 'Import administrateur',
  'settings.fixedpermission.DATA_ENTRY_MANAGEMENT': 'Gestion de la saisie des données',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR': 'Facturation Administrateur',
  'settings.fixedpermission.LOG_MANAGEMENT': 'Gestion des journaux',

  // Fixed permission Info

  'settings.fixedpermission.USER_MANAGEMENT_INFO':
    '<strong>Gestion des utilisateurs </strong>: Accorde un accès complet aux Utilisateurs et entreprises',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT_INFO':
    "<strong>Gestion des points de données </strong>: Donne un accès complet aux Points de mesures (actifs), à la Configuration de l'environnement (actifs) et aux Catches",
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT_INFO':
    '<strong>Rôles et gestion des autorisations </strong>: Accorde un accès complet aux Rôles',
  'settings.fixedpermission.RULE_MANAGEMENT_INFO':
    '<strong>Gestion des règles </strong>: Donne un accès complet aux Règles',
  'settings.fixedpermission.FLOW_MANAGEMENT_INFO':
    '<strong>Gestion des flux </strong>: Donne un accès complet aux Flux',
  'settings.fixedpermission.PERSONA_MANAGEMENT_INFO':
    "<strong>Gestion des Persona </strong>: Accorde un accès complet aux Points de mesures (Personnages) et à la Configuration de l'environnement (Personnages). L'autorisation de Gestion des points de données est un prérequis",
  'settings.fixedpermission.SUSTAINABILITY_VISITOR_INFO':
    "<strong>Saisie de données - Visiteur </strong>: Donne un accès en lecture seule aux modules de durabilité disponibles, aux tableaux de bord de saisie de données, aux Points de mesures (véhicules et activités en amont/aval) et à la Configuration de l'environnement (véhicules et activités en amont/aval). L'autorisation de Gestion des points de données est une condition préalable à cette dernière",
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR_INFO':
    "<strong>Saisie des données - Contributeur </strong>: Accorde un accès d'exécution aux modules de durabilité disponibles, aux tableaux de bord de saisie de données, aux Points de mesures (véhicules et activités en amont/aval) et à la Configuration de l'environnement (Véhicules et activités en amont/aval). L'autorisation de Gestion des points de données est une condition préalable à cette dernière",
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR_INFO':
    "<strong>Saisie des données - Administrateur </strong>: Donne un accès complet aux modules de durabilité disponibles et aux tableaux de bord de saisie des données, à la gestion des données, aux Modèles de documents, aux Points de mesures (Véhicules et Activités en amont/aval) et à la Configuration de l'environnement (Véhicules et Activités en amont/aval). L'autorisation de Gestion des points de données est une condition préalable à cette dernière",
  'settings.fixedpermission.ODATA_USER_INFO':
    "<strong>OData Utilisateur </strong>: Permet l'utilisation de l'interface OData externe",
  'settings.fixedpermission.RESTAPI_USER_INFO':
    "<strong>REST API Utilisateur </strong>: Permet l'utilisation de l'interface externe REST API",
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR_INFO':
    '<strong>Administrateur des organisations </strong>: Accorde un accès complet aux Paramètres organisation',
  'settings.fixedpermission.SQUEAL_MANAGEMENT_INFO':
    "<strong>Gestion des anomalies </strong>: Donne un accès complet à la notification et l'aperçu des Squeals",
  'settings.fixedpermission.IMPORT_VISITOR_INFO':
    '<strong>Import visiteur </strong>: Accorde un accès en lecture seule à Importer (ETL)',
  'settings.fixedpermission.IMPORT_EXECUTOR_INFO':
    '<strong>Import exécuteur </strong>: Donne un accès en exécution à Importer (ETL)',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR_INFO':
    '<strong>Import administrateur </strong>: Donne un accès complet à Importer (ETL)',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR_INFO':
    '<strong>Facturation administrateur </strong>: Donne un accès complet à la Facturation & paramètres',
  'settings.fixedpermission.LOG_MANAGEMENT_INFO': `<strong>Gestion des journaux</strong>: Permet d'accéder à la vue d'ensemble de l'activité des utilisateurs`,

  // Rule overview
  'settings.rules.active': 'ACTIF',
  'settings.rules.inActive': 'INACTIF',
  'settings.rules.createRule': 'Créer nouveau règle',
  'settings.rules.ruleOverview': 'Apperçu des règles',
  'settings.rules.ruleType': 'Type',
  'settings.rules.ruleName': 'Règle',
  'settings.rules.ruleNumberOfInstances': '# Instances',
  'settings.rules.ruleStatus': 'Statut',

  //Rule instances overview
  'settings.ruleInstances.ruleInstancesOverview': 'Aperçu des instances de la règle',
  'settings.ruleInstances.viewInfo': 'INFO',
  'settings.ruleInstances.ruleName': 'Règle',
  'settings.ruleInstances.ruleInstance': 'Instances de la règle',
  'settings.ruleInstances.siteName': 'Site',
  'settings.ruleInstances.buildingName': 'Bâtiment',
  'settings.ruleInstances.floorName': 'Etage',
  'settings.ruleInstances.roomName': 'Pièce',
  'settings.ruleInstances.equipmentName': 'Equipement',
  'settings.ruleInstances.datapointName': 'Datapoint',
  'settings.ruleInstances.isActive': 'Statut',

  //Flow list
  'settings.flows.flow': 'Flux',
  'settings.flows.flows': 'Flux',
  'settings.flows.active': 'ACTIF',
  'settings.flows.inActive': 'INACTIF',
  'settings.flows.description': 'Description',
  'settings.flows.rule': 'Règle',
  'settings.flows.status': 'Statut',

  //Fixed files
  'settings.fixedFiles.title': 'Fichiers fixes',
  'settings.fixedFiles.selectToStart': 'Sélectionnez un fichier fixe',
  'settings.fixedFiles.header.currentFixedFile': 'Fichier fixe',

  // Data Import
  'settings.dataImport.title': 'Importation données',

  //Catches
  'settings.catchs.title': 'Catches',
  'settings.catchs.name': 'Nom',
  'settings.catchs.dealer': 'Dealer',
  'settings.catchs.company': 'Compagnie',
  'settings.catchs.lastLifeSign': 'Signe de vie',
  'settings.catchs.version': 'Version',
  'settings.catchs.load': 'Charge',
  'settings.catchs.memory': 'Mémoire (MB)',
  'settings.catchs.configuration': 'Configuration',
  'settings.catchs.externalAccess': 'Accès externe',
  'settings.catchs.status': 'Statut',
  'settings.catchs.open': 'Ouvert',
  'settings.catchs.close': 'Fermé',
  'settings.catchs.basicUI': 'Interface basique',
  'settings.catchs.catchUI': 'Interface catch',
  'settings.catchs.openUI': 'Ouvrir',
  'settings.catchs.installationScript': "Script d'installation",
  'settings.catchs.installation': 'Installation',
  'settings.catchs.generate': 'Générer',
  'settings.catchs.model': 'Modèle',
  'settings.catchs.firmware': 'Firmware de base',
  'settings.catchs.catchFirmware': 'Firmware du catch',
  'settings.catchs.load1m': 'Charge 1m',
  'settings.catchs.load5m': 'Charge 5m',
  'settings.catchs.load15m': 'Charge 15m',
  'settings.catchs.processes': 'Processus',
  'settings.catchs.rootPassword': 'Mot de passe root',
  'settings.catchs.localPassword': 'Mot de passe local',
  'settings.catchs.requiredVersion': 'Version requise',

  // Data management
  'settings.importDefaultData.confirmImportWarning':
    'Êtes-vous sûr de vouloir importer les données par défaut? ATTENTION: les données actuelles seront archivées!',
  'settings.importDefaultData.confirmImport':
    'Êtes-vous sûr de vouloir importer et ajouter les données par défaut?',

  // Conversion factors
  'settings.conversionFactors.stationaryCombustion': 'Conv: Stationary Combustion',
  'settings.conversionFactors.purchasedEnergy': 'Conv: Purchased Energy',
  'settings.conversionFactors.mobileCombustion': 'Conv: Mobile Combustion',
  'settings.conversionFactors.vehicleFuelEmission': 'Conv: Upstream - Vehicle fuels',
  'settings.conversionFactors.energyGenerationFuelEmission': 'Conv: Upstream - Energy fuels',
  'settings.conversionFactors.upstreamPurchasedEnergy': 'Conv: Upstream - Purchased Energy',
  'settings.conversionFactors.productTransport': 'Conv: Product Transport',
  'settings.conversionFactors.peopleTransport': 'Conv: People Transport',
  'settings.conversionFactors.transportDistributionLoss': 'Transmission & Distribution Losses',

  // Energy contracts
  'settings.energyContracts.energyContracts': 'Contrats énergétiques',
  'settings.energyContracts.energyType.energySource': "Source d'énergie",
  'settings.energyContracts.energyType.renewable': 'Renouvelable',
  'settings.energyContracts.energyType.compositionError':
    'ERREUR: la composition énergétique totale ne peut pas dépasser 100%',
  'settings.energyContracts.percentage': 'Pourcentage',
  'settings.energyContracts.fuelType': 'Brandstoftype',
  'settings.energyContracts.unit': 'Unité',
  'settings.energyContracts.name': 'Nom de contrat',

  // Purchased energy contracts
  'settings.purchasedEnergyContracts.title': 'Purchased Energy contracts',
  'settings.stationaryCombustionContracts.title': 'Stationary Combustion contracts',
  'settings.purchasedEnergyContracts.assignContract': 'Assigner un contrat',
  'settings.purchasedEnergyContracts.model.assign.title': 'Assign new contract to {amount} items',
  'settings.purchasedEnergyContracts.model.assign.success':
    'Le contrat est attribué à {amount} éléments',

  // Persona
  'settings.persona.location': 'Informations localisation de la personne',
  'settings.persona.personal': 'Informations personnelles',

  // Vehicles
  'settings.vehicles': 'Paramètres du véhicule',

  // Dashboards
  'settings.dashboards.rolesAndPermissions': 'Rôles & Permissions',
  'settings.dashboards.info': 'Info',
  'settings.dashboards.dashboards': 'Tableaux de bord',
  'settings.dashboards.dashboards0': 'Tableaux droits de Visiteur',
  'settings.dashboards.dashboards1': `Tableaux droits d'Editeur`,
  'settings.dashboards.dashboards2': 'Tableaux droits de Propriétaire',
  'settings.dashboards.convDashboards': 'Tableaux Conventionels',
  'settings.dashboards.successfullyDeleted': 'Tableau supprimé avec succès.',
  'settings.dashboards.unsuscriptionSuccessful': 'Déscription réussie.',
  'settings.dashboards.dashboardName': 'Nom',
  'settings.dashboards.icon': 'Icône',
  'settings.dashboards.creator': 'Créateur',
  'settings.dashboards.saved': 'Changements enregistrés.',
  'settings.dashboards.errorSaving': "Erreur lors de l'enregistrement du tableau",
  'settings.dashboards.confirmChangeCreator':
    'Êtes-vous sûr de vouloir changer le créateur de ce groupe de tableaux?',
  'settings.dashboards.canNotSetOwner':
    'Nouveau créateur ne peut pas être assigné. Nouveau créateur doit avoir les droits de Propriétaire.',
  'settings.dashboards.noRightsDefined': 'Veuilles assigner les droits aux abonnés.',
  'settings.dashboards.addToFavourites': 'Ajouter aux favoris',
  'settings.dashboards.masterDashboardGroup': 'Master Dashboard Group',
  'settings.dashboards.subscriber': 'Abonné',
  'settings.dashboards.rights': 'Droits',
  'settings.dashboards.selectDashboards': 'Veuillez sélectionner au moins un tableau à partager.',
  'settings.dashboards.mailAdded': "L'adresse e-mail a été ajoutée à la liste.",
  'settings.dashboards.unknownMail': "L'adresse e-mail est inconnue.",
  'settings.dashboards.visitor': 'Visiteur',
  'settings.dashboards.visitorWithShare': 'Visiteur (Permis de partager)',
  'settings.dashboards.editor': 'Editeur',
  'settings.dashboards.owner': 'Propriétaire',
  'settings.dashboards.conventional': 'Conventionnel',
  'settings.dashboards.master': 'Master',
  'settings.dashboards.pleaseSelect': 'Veuillez sélectionner',
  'settings.dashboards.addManually': "Entrez l'adresse e-mail manuellement",
  'settings.dashboards.addButton': 'Ajouter',
  'settings.dashboards.createdAt': 'Date de création',
  'settings.dashboards.updatedAt': 'Dernière mise à jour',
  'settings.dashboards.conventionalHint':
    'Ces tableaux ne seront pas mis à jour après être partagés',
  'settings.dashboards.masterHint':
    'Ces tableaux seront mis à jour automatiquement après être partagés',
  'settings.dashboards.delete': 'Supprimer',

  // Menu
  'settings.menu.title': 'Menus',
  'settings.menu.editItems': `Modifier les éléments de menu`,
  'settings.menuItems.title': 'Items',

  // My Products & Licenses
  'settings.myProducts.title': 'Mes Produits',
  'settings.licenses.title': 'Totaux',
  'settings.dataUsage.title': "L'utilisation de Données",
  'settings.dataUsage.last12Months': '12 Derniers Mois',
  'settings.dataUsage.historyOfLast': 'Utilisation de données des ',
  'settings.dataUsage.months': ' derniers mois',
  'settings.licenses.dataposts': 'dataposts',
  'settings.licenses.totalDataposts': 'Nombre de Dataposts',
  'settings.licenses.allowedDataposts': 'Dataposts max',
  'settings.licenses.totals': 'Totaux',
  'settings.licenses.numberOfUsers': "Nombre d'utilisateurs",
  'settings.licenses.numberOfCompanies': "Nombre d'entreprises",
  'settings.licenses.numberOfOrganisations': "Nombre d'organisations",
  'settings.licenses.numberOfSites': 'Nombre de Sites',
  'settings.licenses.numberOfBuildings': 'Nombre de Bâtiments',
  'settings.licenses.numberOfAssets': "Nombre d'atouts",
  'settings.licenses.numberOfPersona': 'Nombre de Personnages',
  'settings.licenses.numberOfVehicles': 'Nombre de Véhicules',
  'settings.licenses.numberOfUpstreamDownstreamActivities': "Nombre d'activités amant/aval",
  'settings.licenses.numberOfDatapoints': 'Nombre de points de données',
  'settings.licenses.numberOfCatches': 'Nombre de Catches',
  'settings.licenses.totalDatapostsPrevious': 'Dataposts M-1',
  'settings.dataUsage.customer': 'Client',
  'settings.licenses.sms': 'SMS',
  'settings.licenses.totalSms': 'Nombre de SMS',
  'settings.licenses.totalSmssPrevious': 'Sms M-1',
  'settings.licenses.allowedSms': 'SMS Max',
  'settings.licenses.sites': 'Sites',
  'settings.licenses.maxSites': 'Sites max',
  'settings.licenses.datapoints': 'Points de données',
  'settings.licenses.maxDatapoints': 'Points de données max',
  'settings.licenses.users': 'Utilisatrices',
  'settings.licenses.maxUsers': 'Utilisateurs max',
}
