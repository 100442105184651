import React from 'react'

import { SliderRailStyled } from './styled'

const SliderRail = ({ getRailProps }) => {
  return (
    <SliderRailStyled {...getRailProps()} className="SliderRail">
      <div />
      <div />
    </SliderRailStyled>
  )
}

export default SliderRail
