import { useEffect, useState } from 'react'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import { useFilters } from 'components/Form/components/ValuePicker/hooks'
import { tagCompare } from 'components/SettingsComponents/PermissionsModal/helpers'

export const useTags = ({
  tags,
  clearChecker,
  resetTags,
  onChange,
  insertTags,
  tagFilter,
  value,
}) => {
  const [tagList, setTagList] = useState([])
  const [availableTags, setAvailableTags] = useState([])
  const [selectedTags, setSelectedTags] = useState(value)

  const { onSearch, searchValue, data: filteredTags } = useFilters(
    availableTags,
    tagFilter,
    null,
    'label.text'
  )

  useEffect(() => {
    let combinedTags = tags?.length ? tags : resetTags || []
    if (insertTags) {
      combinedTags = insertTags(combinedTags)
    }
    const set = new Set(combinedTags.map(JSON.stringify))
    combinedTags = Array.from(set).map(JSON.parse).sort(tagCompare)
    const labeledTags = combinedTags.map(([key, value]) => ({
      label: { text: `${key}: ${value}` },
      key,
      value,
      color: generateTagColor(key),
    }))

    setTagList(labeledTags)
    setAvailableTags(labeledTags)
  }, [insertTags, tags, resetTags])

  useEffect(() => {
    setSelectedTags([])
  }, [clearChecker])

  const onValueUpdate = (value) => {
    setAvailableTags(
      tagList.filter((tag) => !value.some((v) => v.key.toLowerCase() === tag.key.toLowerCase()))
    )
    setSelectedTags(value)
    onChange && onChange(value)
    onSearch({ target: { value: '' } })
  }

  const onRemoveTag = (e, tag) => {
    const tags = selectedTags.filter(({ key }) => key !== tag.key)
    setSelectedTags(tags)
    onChange && onChange(tags)
    onSearch({ target: { value: '' } })
  }
  return {
    onRemoveTag,
    onValueUpdate,
    onSearch,
    searchValue,
    filteredTags,
    selectedTags,
  }
}
