import React from 'react'

import { PropTypes } from 'prop-types'

const CompactCell = ({ data }) => {
  return (
    <div className="AgGrid__combined-cell">
      <strong>{data.name}</strong>
      <span>{data.id}</span>
    </div>
  )
}

CompactCell.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
}

export default CompactCell
