import React, { useRef, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { useOnClickOutside, useSearchThrottle } from 'components/Global/hooks'

import SearchFieldStyled from './styled'

const SearchField = ({
  onSearch,
  onBlur,
  reset,
  className,
  placeholder,
  interval = 500,
  minChar = 2,
}) => {
  const [showSearchField, setShowSearchField] = useState(false)
  const element = useRef(null)
  useOnClickOutside(element, () => setShowSearchField(false))
  const [searchValue, onChange] = useSearchThrottle({
    callback: onSearch,
    reset,
    interval,
    minChar,
  })

  return (
    <SearchFieldStyled
      className={`${className} SearchField`}
      showSearchField={showSearchField}
      ref={element}
    >
      <Input
        value={searchValue}
        onBlur={onBlur}
        onChange={onChange}
        prefix={<SearchOutlined />}
        placeholder={placeholder}
        allowClear
      />
    </SearchFieldStyled>
  )
}

export default SearchField
