import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import styled from 'styled-components'

import { Value } from 'components/Form/components/Field/styled'
import { MarkerListFieldStyled } from 'components/Form/fields/MarkerListField/styled'
import { TagListFieldStyled } from 'components/Form/fields/TagListField/styled'
import IconStyled from 'components/Global/Icon/styled'

export const DatapointFieldStyled = styled.div`
  padding: 0.5rem;
  border: 1px solid
    ${({ theme, error, virtual }) =>
      error
        ? theme.color.error
        : virtual
        ? transparentize(0.5, theme.color.highlight)
        : theme.color.softStroke};
  background: ${({ theme, error, virtual }) =>
    transparentize(
      0.98,
      error ? theme.color.error : virtual ? theme.color.highlight : theme.color.softStroke
    )};
  border-radius: 0.5rem;
  margin: 0.5rem 0;

  ${MarkerListFieldStyled} {
    flex-direction: column;

    > ${Value} {
      .Tooltip {
        width: 100%;
      }
    }
  }

  ${TagListFieldStyled} {
    flex-direction: column;
  }

  .Field {
    flex-direction: column;
  }

  .DatapointField__actions {
    margin-right: 0.5rem;
    display: flex;
    background: ${({ theme }) => theme.color.highlight};
    padding: 0.3rem 0;
    border-radius: 2rem;
  }

  .DatapointField__action {
    padding: 0 0.4rem;
    font-size: 1.4rem !important;
    color: ${({ theme }) => theme.color.highlightColor};
  }

  .DatapointField__expand {
    margin-left: 0.5rem;
  }
`

export const DatapointFieldHeader = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;

  ${IconStyled} {
    &.type-fa:not(.disabled) {
      &:hover,
      &.active {
        color: ${({ theme }) => theme.color.main};
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .TagField__label {
    flex: 1;
    overflow: hidden;

    ${Value} {
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }
  }

  ${IconStyled} {
    transition: color 0.2s ease;
    font-size: 1.2rem;
    display: flex;

    &.type-fa {
      color: ${({ theme }) => theme.color.backgroundFront};
    }
  }
`

export const DatapointFieldContent = styled(motion.div)`
  overflow: hidden;
  transform-origin: top;

  .Field {
    flex-direction: column;
    text-align: left;
  }
  .Value {
    text-align: left;
    justify-content: flex-start;
  }
`

export const ClickableIdField = styled.div`
  cursor: pointer;
`

export const DetailLink = styled.a`
  color: ${({ theme }) => theme.color.action};
`
