import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { FormattedMessage, injectIntl } from 'react-intl'

import { timeUnits } from 'components/Dashboard/Widget/config/selections'
import ValuePicker from 'components/Form/components/ValuePicker/ValuePicker'
import { MultiFieldContainer } from 'components/Global/FormField/FormFieldElement/styled'
import NumberInput from 'components/Global/FormField/NumberInput'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import FormField from '../index'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import DecreaseConfiguratorStyled from './styled'

const decreaseModes = [
  {
    value: 'endDate',
    label: {
      formatted: 'widget.targetEndDate',
    },
  },
  {
    value: 'decrease',
    label: {
      formatted: 'widget.gradualDecrease',
    },
  },
  {
    value: 'increase',
    label: {
      formatted: 'widget.gradualIncrease',
    },
  },
]

const DecreaseConfigurator = ({
  value,
  modeSelector = true,
  onChange,
  dataCy = 'DecreaseConfigurator',
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()
  const [mode, setMode] = useState(
    elementInEditMode.preview.targetPercentage
      ? elementInEditMode.preview.targetPercentage < 0
        ? 'increase'
        : 'decrease'
      : 'endDate'
  )

  useEffect(() => {
    const newMode = elementInEditMode.preview.targetPercentage
      ? elementInEditMode.preview.targetPercentage < 0
        ? 'increase'
        : 'decrease'
      : 'endDate'
    if (!modeSelector && mode !== newMode) {
      setMode(newMode)
    }
  }, [elementInEditMode.preview.targetPercentage, mode, modeSelector])

  const onDatePickerChange = ({ value, dataKey }) => {
    if (value.range) {
      if (value.value[1]) {
        const newValue = {
          startTime: value.value[0].startOf('day').valueOf(),
          endTime: value.value[1].endOf('day').startOf('hour').valueOf(),
        }
        onChange(newValue, dataKey)
      }
    } else {
      onChange({ startTime: value.value?.startOf('day').valueOf() || null, endTime: null }, dataKey)
    }
  }

  const handleSelectMode = (e, { value: update }) => {
    setMode((state) => {
      if (update === 'endDate') {
        onChange(null, 'targetPercentage')
        onChange(null, 'decreaseUnit')
      } else {
        if (
          value.targetPercentage &&
          ((state === 'decrease' && update === 'increase') ||
            (state === 'increase' && update === 'decrease'))
        ) {
          onChange(-value.targetPercentage, 'targetPercentage')
        }
        onChange(null, 'targetValue')
      }
      return update
    })
  }

  return (
    <DecreaseConfiguratorStyled className="DecreaseConfigurator">
      {modeSelector && (
        <CheckboxSelector
          options={decreaseModes}
          value={mode}
          onSelect={handleSelectMode}
          dataCy={`${dataCy}-type-selector`}
        />
      )}
      {mode === 'endDate' ? (
        <>
          {modeSelector && (
            <FormField
              dataKey="targetPeriod"
              componentName="DatePicker"
              componentProps={{
                allowClear: true,
                range: true,
                showToday: false,
                showTime: false,
                format: 'YYYY-MM-DD',
              }}
              value={[
                value.targetPeriod?.startTime && dayjs(value.targetPeriod?.startTime),
                value.targetPeriod?.endTime && dayjs(value.targetPeriod?.endTime),
              ]}
              label={{
                formatted: 'widget.targetPeriod',
                style: {
                  position: 'top',
                },
              }}
              placeholder="Now"
              startTime={value.targetPeriod?.startTime}
              onChange={onDatePickerChange}
              dataCy={`${dataCy}-targetPeriod`}
            />
          )}
          <FormField
            dataKey="targetValue"
            componentName="NumberInput"
            value={value.targetValue}
            label={{
              formatted: 'widget.targetValue',
              style: {
                position: 'top',
              },
            }}
            onChange={({ value, dataKey }) => onChange(value, dataKey)}
            dataCy={`${dataCy}-targetValue`}
          />
          {modeSelector && (
            <>
              <FormField
                dataKey="percentage"
                componentName="Switch"
                value={value.percentage}
                label={{
                  formatted: 'widget.percentage',
                }}
                onChange={({ value, dataKey }) => onChange(value, dataKey)}
                dataCy={`${dataCy}-percentage`}
              />
              <FormField
                dataKey="extendEndTimeToTarget"
                componentName="Switch"
                value={value.extendEndTimeToTarget}
                label={{
                  formatted: 'widget.extendPeriodToTarget',
                }}
                onChange={({ value, dataKey }) => onChange(value, dataKey)}
                dataCy={`${dataCy}-extendEndTimeToTarget`}
              />
            </>
          )}
        </>
      ) : (
        <>
          {modeSelector && (
            <FormField
              dataKey="targetPeriod"
              componentName="DatePicker"
              componentProps={{
                allowClear: true,
                showToday: false,
                showTime: false,
                format: 'YYYY-MM-DD',
              }}
              value={value.targetPeriod?.startTime && dayjs(value.targetPeriod?.startTime)}
              label={{
                formatted: 'widget.targetStartDate',
                style: {
                  position: 'top',
                },
              }}
              placeholder="Now"
              startTime={value.targetPeriod?.startTime}
              onChange={onDatePickerChange}
              dataCy={`${dataCy}-targetPeriod`}
            />
          )}
          <FormFieldLabel>
            <FormattedMessage id={mode === 'increase' ? 'widget.increase' : 'widget.decrease'} />
          </FormFieldLabel>
          <MultiFieldContainer className="MultiFieldContainer">
            <NumberInput
              className="NumberInput"
              value={
                !value.targetPercentage
                  ? 0
                  : mode === 'increase'
                  ? -value.targetPercentage
                  : value.targetPercentage
              }
              min={0}
              onBlur={(value) => onChange(mode === 'increase' ? -value : value, 'targetPercentage')}
              dataCy={`${dataCy}-targetPercentage`}
            />
            <FormFieldLabel>{intl.formatMessage({ id: 'widget.percentagePer' })}</FormFieldLabel>
            <ValuePicker
              className="ValuePicker"
              value={elementInEditMode.preview.decreaseUnit}
              options={timeUnits}
              onChange={(value) => onChange(value, 'decreaseUnit')}
              selectionKeys={{
                label: 'label',
                value: 'value',
              }}
              placeholder={intl.formatMessage({
                id: mode === 'increase' ? 'widget.increaseUnit' : 'widget.decreaseUnit',
              })}
              search={false}
              dataCy={`${dataCy}-decreaseUnit`}
              disabled={!modeSelector}
            />
          </MultiFieldContainer>
        </>
      )}
    </DecreaseConfiguratorStyled>
  )
}

export default injectIntl(DecreaseConfigurator)
