import styled, { css } from 'styled-components'

import { FieldMessage, Value } from 'components/Form/components/Field/styled'
import TextInputStyled from 'components/Form/components/TextInput/styled'
import { TextFieldStyled } from 'components/Form/fields/TextField/styled'

export const TagFieldStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.color.softStroke};
  border-radius: 0.5rem;
  margin: 0.5rem 0;

  ${TextFieldStyled} {
    padding: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      > * {
        opacity: 0.5;
      }
    `}
  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.color.error};
    `}
  .Field {
    &.TagField__label {
      font-weight: 600;
      width: 40%;
      min-width: 40%;

      ${Value} {
        color: ${({ theme }) => theme.color.mainText};
        text-align: left;
      }
    }

    &.TagField__value {
      flex: 1;
      height: 2rem;
      overflow: hidden;

      ${TextInputStyled} {
        height: 100%;
      }

      ${Value} {
        height: 100%;
      }

      ${FieldMessage} {
        display: none;
      }
    }

    ${Value} {
      display: flex;
      align-items: center;
    }
  }

  > ${FieldMessage} {
    position: absolute;
    background: ${({ theme }) => theme.color.backgroundFront};
    top: -0.5rem;
    left: 1rem;
    line-height: 1;
    padding: 0 0.3rem;
  }

  .TooltipContent {
    position: absolute;
    bottom: 100%;
    min-height: auto;
  }

  button {
    &:not(.ant-btn-primary) {
      &:not(:focus) {
        color: ${({ theme }) => theme.color.softText};
      }
    }
  }

  span.ant-tag {
    &:not(.ant-tag-has-color) {
      color: ${({ theme }) => theme.color.softText};
    }
  }

  span.TagField__type {
    position: absolute;
    font-size: 0.7rem;
    letter-spacing: 1px;
    background: ${({ theme }) => theme.color.backgroundFront};
    top: -0.3rem;
    right: 1rem;
    line-height: 1;
    padding: 0 0.3rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.softText};
  }
`
