import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Cascader as AntCascader, Divider } from 'antd'
import { injectIntl } from 'react-intl'

import Button from 'components/Global/Button'

import { ButtonContainer } from './styled'

const Cascader = ({
  intl,
  showButtons = false,
  prefix = true,
  onCancel,
  onConfirm,
  cancelButtonProps = {},
  confirmButtonProps = {},
  ...props
}) => {
  const dropdownRender = (menus) => {
    return (
      <div>
        {menus}
        <Divider style={{ margin: 0 }} />
        <ButtonContainer>
          <Button
            variant="smallButton"
            label={intl.formatMessage({ id: 'cancel' })}
            onClick={onCancel}
            {...cancelButtonProps}
          />
          <Button
            variant="smallButton"
            isPrimary
            label={intl.formatMessage({ id: 'confirm' })}
            onClick={onConfirm}
            {...confirmButtonProps}
          />
        </ButtonContainer>
      </div>
    )
  }

  return (
    <AntCascader
      dropdownRender={showButtons ? dropdownRender : undefined}
      prefix={prefix && <SearchOutlined />}
      {...props}
    />
  )
}

Cascader.propTypes = {}

Cascader.defaultProps = {}

export default injectIntl(Cascader)
