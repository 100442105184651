import React from 'react'

import Progress from 'components/Form/components/Progress'

import { ProgressFieldStyled } from './styled'

const ProgressField = ({
  value,
  comparator,
  strokeColor,
  showValue,
  showPercentage,
  size = 'small',
  name,
  label,
  validation,
  viewRender,
  editRender,
  disabled,
  rows,
  ...fieldProps
}) => {
  return (
    <ProgressFieldStyled {...fieldProps} label={label} size={size}>
      {({ watch }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        return (
          <Progress
            size={size}
            value={fieldValue}
            comparator={comparator}
            strokeColor={strokeColor}
            showValue={showValue}
            showPercentage={showPercentage}
          />
        )
      }}
    </ProgressFieldStyled>
  )
}

export default ProgressField
