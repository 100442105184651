import React from 'react'

import { injectIntl } from 'react-intl'

import Icon from '../../Icon'
import { DashboardPickerButtonStyled } from './styled'

const DashboardPickerButton = ({
  label,
  icon,
  item,
  singleDatapoint,
  isActive,
  isSelected,
  isChildSelected,
  onButtonClick,
}) => {
  return (
    <DashboardPickerButtonStyled
      className={`DashboardPickerButton ${
        isSelected ? 'selected' : isChildSelected ? 'child-selected' : isActive ? 'active' : ''
      }`}
    >
      <Icon icon={icon} />
      {label && <span className="Button__label">{label}</span>}
      <Icon icon="fas fa-check" onClick={() => onButtonClick(item, 'single')} />
      {!singleDatapoint && (
        <Icon icon="fas fa-list-tree" onClick={() => onButtonClick(item, 'multi')} />
      )}
    </DashboardPickerButtonStyled>
  )
}

export default injectIntl(DashboardPickerButton)
