import { css } from 'styled-components'

export const smallButton = css`
  font-size: 0.9em;
  color: ${({ theme }) => theme.color.mainText};
  background: ${({ theme }) => theme.color.softStroke};
  border: 1px solid ${({ theme }) => theme.color.softText};
  border-radius: 1rem;
  padding: 0 0.5rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  padding: 0 0.5rem;
  &:hover {
    background: ${({ theme }) => theme.color.softerStroke};
    border: 1px solid ${({ theme }) => theme.color.mainText};
  }
`
