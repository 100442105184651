import React from 'react'

import { AgGridColumn } from '@ag-grid-community/react'
import { Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { injectIntl } from 'react-intl'

import { useQueryData } from 'services/data'

import Table from 'components/Dashboard/components/Table/Table'

const Version = ({ value }) => <Tag>v{value}</Tag>

const DateTooltip = ({ value, valueFormatted }) => {
  return <Tooltip title={dayjs(value).format('LLL')}>{valueFormatted}</Tooltip>
}

const dateFormatter = ({ value }) => dayjs(value).fromNow()

export const About = ({ intl }) => {
  const { data, loading } = useQueryData({ service: 'module_versions', method: 'GET' })

  return (
    <Table
      value={data?.versions}
      frameworkComponents={{ Version, DateTooltip }}
      loading={loading}
      height="60vh"
    >
      <AgGridColumn
        field="type"
        headerName={intl.formatMessage({ id: 'application' })}
        valueFormatter={({ value }) => {
          const str = value.replace('_', ' ')
          return str.charAt(0).toUpperCase() + str.slice(1)
        }}
        sortable
        flex={2}
      />
      <AgGridColumn
        field="version"
        headerName={intl.formatMessage({ id: 'version' })}
        cellRenderer="Version"
        flex={2}
      />
      <AgGridColumn
        field="@timestamp"
        headerName={intl.formatMessage({ id: 'deployedAt' })}
        valueFormatter={dateFormatter}
        sortable
        flex={3}
        cellRenderer="DateTooltip"
      />
    </Table>
  )
}

export default injectIntl(About)
