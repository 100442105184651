import React, { useMemo } from 'react'

import { PropTypes } from 'prop-types'
import { Route } from 'react-router-dom'

import { useSelectableOptions } from '../../hooks'

const SelectableRoute = ({ children, name, ...props }) => {
  const options = useSelectableOptions()

  const path = useMemo(() => {
    const option = options.find((option) => option.name === name)
    if (!option) {
      throw new Error(`No selectable route option found with name: "${name}"`)
    }

    return option.path
  }, [options, name])

  return (
    <Route path={path} {...props}>
      {children}
    </Route>
  )
}

SelectableRoute.propTypes = {
  name: PropTypes.string.isRequired,
}

export default SelectableRoute
