import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import RowsStyled from './styled'

const Rows = ({
  dataKey = 'rows',
  subPath,
  selectedIndex,
  buttons = true,
  onChange,
  onClick,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const rows = get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []

  const handleRowClick = (e, { id, name, index }) => {
    e.stopPropagation()
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: index,
          dataKey: dataKey,
        },
      })
      onClick(e, { id, name, index })
    }
  }

  const onRemoveRow = (e, { index: colIndex }) => {
    e.stopPropagation()
    const newRows = rows.filter((group, index) => index !== colIndex)
    onChange(newRows, dataKey)
  }

  const onAddRow = () => {
    const newRows = rows.concat({
      headerName: intl.formatMessage({ id: 'widget.row' }),
    })
    onChange(newRows, dataKey)
    const newRowIndex = newRows.length - 1
    action({
      type: 'ON_DATAPOINT_SELECT',
      payload: {
        value: newRowIndex,
        dataKey: dataKey,
      },
    })
    onClick(null, { ...newRows[newRowIndex], index: newRowIndex })
  }

  return (
    <RowsStyled className="DatapointGroups">
      {rows.map((column, index) => (
        <Button
          label={
            column.name
              ? column.name.startsWith('intl')
                ? intl.formatMessage({ id: column.name.replace('intl.', '') })
                : column.name
              : `Row ${index + 1}`
          }
          variant="tag"
          onIconClick={(e) => onRemoveRow(e, { ...column, index })}
          icon={onRemoveRow && 'fas fa-xmark'}
          isActive={selectedIndex === index}
          onClick={(e) => handleRowClick(e, { ...column, index })}
        />
      ))}
      {buttons && (
        <div className="Datapoints__buttons">
          <Button
            className="SmallButton"
            label={intl.formatMessage({
              id: 'widget.addRow',
            })}
            onClick={onAddRow}
            variant="smallButton"
          />
        </div>
      )}
    </RowsStyled>
  )
}

export default injectIntl(Rows)
