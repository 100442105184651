import React, { useState } from 'react'

import Button from 'components/Global/Button'
import Upload from 'components/Global/FormField/Upload'
import Icon from 'components/Global/Icon'

import { useIconsQuery } from '../../../LayoutComponents/Menu/MenuLeft/useIconsQuery'
import IconPickerStyled, { IconListStyled } from './styled'

const IconPicker = ({ value, onChange, companyWide = true }) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const { data: icons, loading, refetch: refetchIcons } = useIconsQuery()

  const onToggleOpen = (payload) => {
    return setIsOpenState((state) => (typeof payload !== 'undefined' ? !!payload : !state))
  }
  const onIconClick = (value, e) => {
    e && e.stopPropagation()
    onChange(value)
    setIsOpenState((state) => !state)
  }

  return (
    <IconPickerStyled
      className="IconPicker"
      button={{
        icon: value,
        children: value && (
          <Icon
            className="close-button"
            icon="fas fa-xmark"
            variant="clearIcon"
            onClick={(e) => onIconClick(null, e)}
          />
        ),
      }}
      variant="IconPicker"
      isOpen={isOpenState}
      onToggleOpen={onToggleOpen}
    >
      <IconListStyled>
        {value && (
          <Icon icon="fas fa-xmark" variant="clearIcon" onClick={(e) => onIconClick(null, e)} />
        )}
        <Upload
          onChange={(value) => {
            refetchIcons()
            onIconClick(value, null)
          }}
          icon="fa-upload"
          placeholderIcon="fas fa-xmark"
          loadingText={false}
          companyWide={companyWide}
        />
        {!loading &&
          icons.map((icon) => (
            <Button
              key={icon}
              icon={icon}
              onClick={(e) => onIconClick(icon, e)}
              isActive={icon === value}
            />
          ))}
      </IconListStyled>
    </IconPickerStyled>
  )
}

IconPicker.propTypes = {}

IconPicker.defaultProps = {}

export default IconPicker
