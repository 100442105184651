import React from 'react'

import dayjs from 'dayjs'
import { injectIntl } from 'react-intl'

import OrganisationReportingDateRange from '../../../Form/components/OrganisationReportDateRange'
import { FormFieldLabel } from '../styled'

const ReportingYearSelector = ({
  value,
  dataKey,
  organisationId,
  dataCy = 'ReportingYearSelector',
  onChange,
  onGranularityUpdate,
  onPresetUpdate,
  intl,
}) => {
  const onReportingYearChange = (value) => {
    const newValue = { startTime: value?.[0].format(), endTime: value?.[1].format() }
    onChange(newValue, dataKey)
    onPresetUpdate(null)
    onGranularityUpdate(newValue)
  }

  return (
    <>
      <FormFieldLabel>
        {intl.formatMessage({ id: 'graphComponent.presetControl.customPeriod' })}
      </FormFieldLabel>
      <OrganisationReportingDateRange
        organisationId={organisationId}
        allowCustomRange
        onChange={onReportingYearChange}
        enableDefaultValue={false}
        defaultValue={[
          value?.[dataKey]?.startTime ? dayjs(value[dataKey].startTime) : dayjs(),
          value?.[dataKey]?.endTime ? dayjs(value[dataKey].endTime) : dayjs(),
        ]}
        presets={false}
        dataCy={dataCy}
      />
    </>
  )
}

export default injectIntl(ReportingYearSelector)
