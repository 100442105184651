import React, { useMemo, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import ReactDOM from 'react-dom'
import { FormattedMessage, injectIntl } from 'react-intl'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import Button from '../../Button'
import { usePortal } from '../../hooks'
import { FormFieldLabel } from '../styled'
import Upload from '../Upload'
import { fuzzyMatch } from '../utils/functions'
import { IconListButtons, IconListDrawer, IconListStyled } from './styled'

const IconListPortal = ({ icons, onIconClick, onUpload, value, companyWide, intl }) => {
  const target = usePortal('bottom-drawer')
  const { action } = useDashboard()
  const [search, setSearch] = useState('')

  const filteredIcons = useMemo(() => icons.filter((icon) => fuzzyMatch(icon, search, 'high')), [
    icons,
    search,
  ])

  const onSearch = (e) => {
    setSearch(e.target.value)
  }

  const onExit = () => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        dataKey: 'isOpen',
        value: false,
      },
    })
  }

  return ReactDOM.createPortal(
    <IconListDrawer>
      <FormFieldLabel margin="1rem">
        <FormattedMessage id="widget.icons" />
      </FormFieldLabel>
      <Input onChange={onSearch} prefix={<SearchOutlined />} />
      <IconListStyled className="IconList">
        <Upload
          onChange={(value) => {
            onUpload()
            onIconClick(null, value)
          }}
          icon="fas fa-upload"
          placeholderIcon="fas fa-xmark"
          imageStyle="small"
          loadingText={false}
          companyWide={companyWide}
        />
        {filteredIcons.map((icon) => (
          <Button
            key={icon}
            icon={icon}
            onClick={(e) => onIconClick(e, icon)}
            isActive={icon === value}
            dataCy={`IconList-${icon}`}
          />
        ))}
      </IconListStyled>
      <IconListButtons>
        <Button
          label={intl.formatMessage({ id: 'widget.back' })}
          variant="mainButton"
          onClick={onExit}
          dataCy="IconList-back"
        />
        <Button
          label={intl.formatMessage({
            id: 'widget.ok',
          })}
          variant="mainButton"
          isPrimary
          onClick={onExit}
          dataCy="IconList-ok"
        />
      </IconListButtons>
    </IconListDrawer>,
    target
  )
}

export default injectIntl(IconListPortal)
