import React, { useCallback, useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { useActivityCategories } from 'services/entities'
import { scope3SubCategories, useScopeDefinition } from 'services/sustainability/ghg'

import { SearchableTree } from 'components/Global/Tree'

import { cat3Config } from '../../../Global/FormField/GhgDataSelector/config'
import EntityTreeStyled from './styled'

const UpstreamDownstreamEntityTypeTree = ({ onChange, onTreeOpenClick, openItems, intl }) => {
  const { data } = useActivityCategories()
  const { data: scopeDefinition } = useScopeDefinition()

  const getSubCategories = useCallback(
    (entityDetails, categoryId, disabled) => {
      if (categoryId === '3') {
        return scope3SubCategories.map((cat) => ({
          categoryId: cat,
          id: cat,
          treeId: cat,
          name: intl.formatMessage({ id: `sustainability.ghg.scope3.${cat}` }),
          tags: scopeDefinition.find(({ id }) => id === cat3Config[cat]?.scopeId)?.tags,
          disabled,
        }))
      }
      return entityDetails.map(({ entityType }) => ({
        ...entityType,
        categoryId,
        treeId: categoryId,
        children: [],
        disabled,
      }))
    },
    [intl, scopeDefinition]
  )

  const { tree } = useMemo(() => {
    if (data?.activityCategories) {
      return data.activityCategories.reduce(
        (output, { entityDetails, name, direction, id, enabled, ...category }, index) => {
          if (direction.title === 'Upstream') {
            if (!output.upstreamCategories.name) {
              output.upstreamCategories = {
                ...output.upstreamCategories,
                name: direction.title,
                ...direction,
                treeId: `${direction.__typename}:${id}`,
              }
            }

            const subCategories = getSubCategories(entityDetails, id, !enabled)
            output.upstreamCategories.children.push({
              ...category,
              id,
              treeId: `${category.__typename}:${id}`,
              categoryId: id,
              name,
              children: subCategories,
              disabled: !enabled,
            })

            output.upstreamCategories.entityTypeIds.push(
              ...entityDetails.map(({ entityType }) => entityType.id)
            )
          } else {
            if (!output.downstreamCategories.name) {
              output.downstreamCategories = {
                ...output.downstreamCategories,
                name: direction.title,
                ...direction,
                treeId: `${direction.__typename}:${id}`,
              }
            }

            const subCategories = getSubCategories(entityDetails, id, !enabled)
            output.downstreamCategories.children.push({
              ...category,
              id,
              treeId: `${category.__typename}.${id}`,
              categoryId: id,
              name,
              children: subCategories,
              disabled: !enabled,
            })

            output.downstreamCategories.entityTypeIds.push(
              ...entityDetails.map(({ entityType }) => entityType.id)
            )
          }

          if (data.activityCategories.length === index + 1) {
            output.tree = [output.upstreamCategories, output.downstreamCategories]
          }

          return output
        },
        {
          tree: [],
          upstreamCategories: { children: [], entityTypeIds: [] },
          downstreamCategories: { children: [], entityTypeIds: [] },
        }
      )
    }
    return { tree: [] }
  }, [data?.activityCategories, getSubCategories])

  return (
    <EntityTreeStyled className="EntityTree">
      <SearchableTree
        value={tree}
        level={0}
        elementHeight={30}
        show
        onButtonClick={onChange}
        onTreeOpenClick={onTreeOpenClick}
        openItems={openItems}
        trackOpenState
        idKey="treeId"
      />
    </EntityTreeStyled>
  )
}

export default injectIntl(UpstreamDownstreamEntityTypeTree)
