import styled from 'styled-components'

import Selector from 'components/Global/FormField/Selector'
import Loader from 'components/Global/Loader'

export const OptionPickerSelector = styled(Selector)``

const OptionPickerStyled = styled(Selector)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const OptionPickerValue = styled(Selector)`
  display: flex;
  flex-wrap: wrap;
  .Button {
    > .Icon {
      order: 1;
      margin-left: 0.5rem;
    }
  }
`

export const OptionPickerLoader = styled(Loader)`
  color: ${({ theme }) => theme.color.softText};
  text-transform: uppercase;
  .Icon {
    color: ${({ theme }) => theme.color.softerText};
    font-size: 2rem;
  }
  label {
    font-size: 0.7rem;
  }
  .Spinner {
    order: 1;
    margin-top: 0.5rem;
  }
`

export default OptionPickerStyled
