import React, { useMemo } from 'react'

import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { useFieldArray, useFormContext } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import DatapointField from 'components/Form/fields/DatapointField'

import { DatapointListFieldStyled } from './styled'

const DatapointListField = ({
  value,
  name,
  filter,
  datapointFilter,
  format = 'PPpp',
  dependencies,
  onOpenDetails,
  intl,
  ...fieldProps
}) => {
  const { fields } = useFieldArray({
    name,
  })
  const { loading, watch, getValues } = useFormContext()
  const isAllOpen = watch('ui.datapoints.toggleExpandAll')

  const list = useMemo(
    () => {
      let listData
      const filterFn = ({ item }) => {
        if (!filter) {
          return true
        }
        return filter(item, { watch, getValues })
      }
      listData = value === undefined && name === undefined ? value : fields
      listData = listData.map((item, index) => ({ item, index })).filter(filterFn)
      return sortBy(listData, 'item.name').map(({ index, item }) => (
        <DatapointField
          key={item.id}
          name={`${name}[${index}]`}
          filter={datapointFilter}
          isAllOpen={isAllOpen}
          context={fieldProps.context}
          action={fieldProps.action}
          onOpenDetails={onOpenDetails}
        />
      ))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, name, fields, filter, datapointFilter]
  )

  if (!list || list.length === 0) {
    return (
      <span>
        {intl
          ? intl.formatMessage({
              id: 'componentCaptions.noDatapoints',
            })
          : 'No datapoints'}
      </span>
    )
  }

  if (loading) {
    return <Skeleton width="200px" />
  }

  return <DatapointListFieldStyled {...fieldProps}>{list}</DatapointListFieldStyled>
}

DatapointListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      required: PropTypes.bool,
    })
  ),
}

export default DatapointListField
