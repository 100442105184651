import dayjs from 'dayjs'

import { DATETIME_NAME_FORMAT } from 'util/constants'
import round from 'util/round'

function DEFAULT_TIMESTAMP_FORMATTER(value) {
  return dayjs(value).format(DATETIME_NAME_FORMAT)
}

export const chartOptions = (
  { type, stacking, hideUnit, showPercentage, enableExport, min, max, theme },
  localizeNumber
) => {
  return {
    ...(type === 'solidgauge' && {
      pane: {
        size: '100%',
        center: ['50%', '55%'],
        startAngle: -115,
        endAngle: 115,
        background: [
          {
            innerRadius: '85%',
            outerRadius: '95%',
            shape: 'arc',
            borderColor: 'transparent',
            backgroundColor: theme.color.softerText,
          },
        ],
      },
    }),
    ...((type === 'area' || type === 'column' || type === 'bar') && {
      xAxis: [
        {
          type: 'datetime',
          tickColor: null,
          lineColor: theme.color.softStroke,
          gridLineWidth: 0,
          lineWidth: 1,
          tickmarkPlacement: 'on',
          showEmpty: false,
          labels: {
            step: 1,
            y: 25,
            style: { fontSize: '8px', color: theme.color.softText },
          },
          className: 'highcarts-axis-labels',
          index: 0,
          isX: true,
        },
      ],
      tooltip: {
        backgroundColor: '',
        borderWidth: 0,
        borderRadius: 0,
        shadow: false,
        shape: 'square',
        borderColor: 'transparent',
        className: 'Cart__tooltip',
        styledMode: true,
        useHTML: true,
        min: 0,
        max: 1000,
        crosshairs: {
          color: 'rgba(0, 0, 0, 0.03)',
        },
        shared: true,
        formatter: function () {
          const points = this.points
          if (points) {
            return points.reduce((s, point) => {
              const yAxis = point.series.yAxis
              const unit = yAxis.axisTitle?.textStr
              const value = round(point.y, 2)
              const seriesName = point.series.name
              return `<p class="tooltip__date">${s}</p><p class="tooltip__value"><span style="background:${
                point.color
              }" class="tooltip__color"></span><span
              class="name">${seriesName}</span><span class="value">${value} <span class="unit">${
                hideUnit ? '' : unit || ''
              }</span></span></p>`
            }, `<span>${DEFAULT_TIMESTAMP_FORMATTER(this.x)}</span>`)
          }
          const point = this.point

          if (point) {
            return `<p class="tooltip__value">
            <span style="background:${point.color};" class="tooltip__color"></span>
            <span class="name">${point.name}</span>
            <span class="value">
              ${localizeNumber(showPercentage ? point.y : point.percentage)}
              <span class="unit">${showPercentage ? point.unit || '' : '%'}</span>
            </span>
          </p>`
          }
          return ''
        },
      },
    }),
    yAxis: {
      ...(type === 'solidgauge' && {
        min: min || 0,
        max: max || 100,
        tickPositions: [min, max || 0],
        labels: {
          formatter: function () {
            return `${localizeNumber(this.value) || 0} ${this.unit || ''}`
          },
        },
        //stops: colors,
      }),
      ...((type === 'area' || type === 'column' || type === 'bar') && {
        gridLineColor: theme.color.softerText,
        lineColor: null,
        gridLineWidth: 1,
        lineWidth: 1,
        allowDecimals: true,
        maxPadding: 0.05,
        minPadding: 0.05,
        showEmpty: false,
        endOnTick: true,
        startOnTick: true,
        labels: {
          align: 'right',
          step: 1,
          style: { fontSize: '8px', color: theme.color.softText },
        },
        className: 'highcarts-axis-labels',
      }),
    },
    title: {
      text: undefined,
    },
    time: {
      useUTC: false,
    },
    chart: {
      type,
      alignTicks: false,
      backgroundColor: 'transparent',
      zoomType: 'x',
      animation: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      [type]: {
        stacking: stacking ? 'normal' : null,
        borderWidth: 0,
        groupPadding: 0.1,
        grouping: true,
        shadow: false,
        minPointLength: 1,
        ...(type === 'solidgauge' && {
          innerRadius: '78%',
          rounded: true,
          linecap: 'round',
          dataLabels: {
            borderWidth: 0,
            useHTML: true,
            allowOverlap: true,
          },
        }),
      },
      series: {
        boostThreshold: 0,
        marker: {
          enabled: false,
        },
        visible: true,
      },
    },
    legend: {
      align: 'right',
      itemStyle: {
        fontSize: '10px',
        fontWeight: 100,
        color: theme.color.mainText,
        alignColumns: false,
        itemWidth: 350,
        itemMarginTop: 2,
        itemMarginBottom: 2,
      },
      squareSymbol: true,
    },
    exporting: {
      enabled: enableExport,
      buttons: {
        contextButton: {
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'downloadCSV',
            'downloadXLS',
            'viewData',
          ],
        },
      },
    },
  }
}
