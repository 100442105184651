export default {
  301: 'Error on datapoint {datapointName}: No mobile combustion conversion factor found for vehicle type {vehicleTypeName} and fuel type {fuelTypeName}',
  302: 'Error on datapoint {datapointName}: No active mobile combustion conversion factor found for vehicle type {vehicleTypeName} and fuel type {fuelTypeName} on date {datetime}',
  303: 'Error on datapoint {datapointName}: No conversion found for {fromUnitName} to {toUnitName}',
  304: 'Error on datapoint {datapointName}: No purchased energy conversion factor found for country {countryName} and energy type {energyTypeName}',
  305: "Error on datapoint {datapointName}: Meter {entityId} doesn't have a contract",
  306: 'Error on datapoint {datapointName}: No active energy contract found for meter {entityId} on date {datetime}',
  307: 'Error on datapoint {datapointName}: No stationary combustion conversion factor found for country {countryName} and fuel type {fuelTypeName}',
  308: 'Error on datapoint {datapointName}: No active conversion factor found for fuel type {fuelTypeName} and country {countryName} on date {dateTime}',
  309: 'Error on datapoint {datapointName}: Not every kwH consumption entry of vehicle {vehicleId} has an energy contract provided',
  310: 'Error on datapoint {datapointName}: No active conversion factor found for energy type {energyTypeName} and country {countryName} on date {dateTime}',
  311: 'Error on datapoint {datapointName}: No "typeGas" value found on datapoint {entityId}',
  312: 'Error on datapoint {datapointName}: No gas definition found for typeGas id {gasId} on datapoint {entityId}',
  313: 'Error on datapoint {datapointName}: No conversionfactor found for entity {entityName} with vehicleType: {vehicleTypeName}, vehicle fueltype: {vehicleFuelTypeName} and transportLoadType: {transportLoadTypeName}',
  314: 'Error on datapoint {datapointName}: No conversionfactor found for entity {entityName} with travelMethod: {travelMethodName}, vehicle fueltype: {vehicleFuelTypeName}',
  315: 'Error on datapoint {datapointName}: Emission Absolute datapoint could not be found on entity {entityName}',
  316: 'Error on datapoint {datapointName}: Emission Biogenic Co2 datapoint could not be found on entity {entityName}',
  401: 'Error on datapoint {datapointName}: No organisation provided',
  402: 'Error on datapoint {datapointName}: Start date {start} is later or equal as end date {end}',
  403: 'Error on datapoint {datapointName}: No scope definition found for scope {scopeId}',
  404: 'Error on datapoint {datapointName}: Entity with id {entityId} does not exist',
  405: 'Error on datapoint {datapointName}: contract  {contractName} does not exist but was used for vehicle {entityName}',
  406: 'Error on datapoint {datapointName}: Emissionfactor {emissionfactor} is not available',
  407: 'Error on datapoint {datapointName}: Emissionfactors {emissionfactors} are not available',
  500: 'Error on datapoint {datapointName}: Internal error',
}
