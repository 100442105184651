import { sdgLabels } from 'components/Dashboard/Widget/config/selections'

export const config = {
  displayProps: {},
  editProps: {
    name: 'widget.sdgWidget',
    category: 'widget.sustainability',
    keywords: ['other', 'sdg', 'sustainability'],
    img: '/resources/images/widgets/sdg.png',
    info: {
      'fr-FR':
        '<h2>SDG</h2>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        "<p>Le widget SDG vous permet de choisir un objectif et d'afficher des informations clés à son sujet.</p>\n" +
        "<li><strong>Label</strong> L'un des 17 SDG</li>\n" +
        "<li><strong>Lien</strong> Définir un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le widget</li>\n" +
        '<li><strong>Masquer le libellé</strong> Afficher ou non un libellé supplémentaire</li>\n' +
        '</ul>',
      'en-US':
        '<h2>SDG</h2>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<p>The SDG widget lets you choose a goal and display key  information about it.</p>\n' +
        "<li><strong>Label</strong> One of the 17 SDG's</li>\n" +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the widget</li>\n' +
        '<li><strong>Hide label</strong> Display an extra label or not</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>SDG</h2>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<p>Met de SDG-widget kun je een doel kiezen en er belangrijke informatie over weergeven.</p>\n' +
        "<li><strong>Label</strong> Een van de 17 SDG's</li>\n" +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de widget wordt geklikt</li>\n' +
        '<li><strong>Label verbergen</strong> Een extra label weergeven of niet</li>\n' +
        '</ul>',
    },
    default: {
      type: 'Sdg',
      sdg: {
        icon: '/resources/images/sdg/sdg-01.png',
        label: { formatted: 'widget.sdg1' },
      },
      hideLabel: true,
    },
    preview: {
      icon: 'fas fa-leaf',
      sdg: {
        icon: '/resources/images/sdg/sdg-01.png',
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'ValuePicker',
        label: { formatted: 'widget.label' },
        dataKey: 'sdg',
        componentProps: {
          options: sdgLabels,
          search: false,
          selectionKeys: {
            value: 'value',
            label: 'label',
          },
          valueKeys: ['icon', 'label', 'value', 'color'],
        },
      },
      {
        category: 'widget.general',
        componentName: 'LinkPicker',
        label: { formatted: 'widget.link' },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.hideLabel' },
        dataKey: 'hideLabel',
        col: 'col2 first',
      },
    ],
  },
}

export default config
