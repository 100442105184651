import React, { useMemo } from 'react'

import get from 'lodash/get'
import { object, string } from 'prop-types'
import { injectIntl } from 'react-intl'

import MicroWidgetEdgeConfigurator from 'components/Dashboard/Widget/MicroWidgetConfigurator/MicroWidgetEdgeConfigurator'
import MicroWidgetEdges from 'components/Dashboard/Widget/MicroWidgetConfigurator/MicroWidgetEdges'
import TableIconPicker from 'components/Form/components/IconPicker/IconPicker'
import OptionPicker from 'components/Form/components/OptionPicker'
import SelectionText from 'components/Form/components/SelectionText/SelectionText'
import ValuePicker from 'components/Form/components/ValuePicker'
import { AntSwitch as Switch } from 'components/Global/AntDesign'
import Button from 'components/Global/Button'
import DateTime from 'components/Global/DateTime'
import CaptionEditor from 'components/Global/FormField/CaptionEditor'
import ClassificationPicker from 'components/Global/FormField/ClassificationPicker'
import ColorPicker from 'components/Global/FormField/ColorPicker'
import ColumnPicker from 'components/Global/FormField/ColumnPicker'
import CustomerPicker from 'components/Global/FormField/CustomerPicker'
import DatapointValueTypePicker from 'components/Global/FormField/DatapointValueTypePicker'
import DatePicker from 'components/Global/FormField/DatePicker'
import DateRangeSelector from 'components/Global/FormField/DateRangeSelector'
import DecreaseConfigurator from 'components/Global/FormField/DecreaseConfigurator'
import { CalculationMethod } from 'components/Global/FormField/DefaultStartTimeSelector'
import EmissionFactorSelector from 'components/Global/FormField/EmissionFactorSelector'
import EntitiesPicker from 'components/Global/FormField/EntitiesPicker'
import EntityCascader from 'components/Global/FormField/EntityCascader'
import EntityFilePicker from 'components/Global/FormField/EntityFilePicker'
import EntityTagPicker from 'components/Global/FormField/EntityTagPicker'
import EntityValueTypePicker from 'components/Global/FormField/EntityValueTypePicker'
import GranularitySelector from 'components/Global/FormField/GranularitySelector'
import IconPicker from 'components/Global/FormField/IconPicker'
import LinkPicker from 'components/Global/FormField/LinkPicker'
import PeriodPicker from 'components/Global/FormField/PeriodPicker'
import Select from 'components/Global/FormField/Select'
import UnitPicker from 'components/Global/FormField/UnitPicker'
import ValueTypeSelector from 'components/Global/FormField/ValueTypeSelector'
import XRangeLineConfigurator from 'components/Global/FormField/XRangeLineConfigurator'
import Icon from 'components/Global/Icon'
import Table from 'components/Global/Table'
import Text from 'components/Global/Text'
import { AgGridStatusCell } from 'components/Layout/List'

import MicroWidgetConfigurator from '../../../Dashboard/Widget/MicroWidgetConfigurator'
import CaptionTooltip from '../CaptionTooltip'
import ColorRangeSlider, {
  GhgMinMaxColorRangeSlider,
  HeatMapMinMaxColorRangeSlider,
  MinMaxColorRangeSlider,
} from '../ColorRangeSlider'
import Columns from '../Columns'
import Comparator from '../Comparator'
import ComparisonPointConfigurator from '../ComparisonPointConfigurator'
import CustomLogic from '../CustomLogic'
import DashboardLink from '../DashboardLink'
import InformationDetailConfigurator from '../DatapointConfigurator/InformationDetailConfigurator'
import DatapointGroups from '../DatapointGroups'
import { Datapoints } from '../Datapoints'
import DateTimeFormatPicker from '../DateTimeFormatPicker'
import DefaultStartTimeSelector from '../DefaultStartTimeSelector'
import { Entities } from '../Entities'
import EquipmentPresetDatapointSelector from '../EquipmentPresetDatapointSelector'
import EquipmentSelector from '../EquipmentSelector'
import FixedValues from '../FixedValues'
import InformationLines from '../InformationLines'
import Input from '../Input'
import LocationSearch from '../LocationSearch/LocationSearch'
import MapEntitySelector from '../MapEntitySelector'
import MapZoomConfigurator from '../MapZoomConfigurator'
import NavigationTypeSelector from '../NavigationTypeSelector'
import NumberInput from '../NumberInput'
import NumberRangeInput from '../NumberRangeInput'
import Progress from '../Progress'
import RangeSlider from '../RangeSlider'
import RawDataConfigurator from '../RawDataConfigurator'
import Rows from '../Rows'
import TextArea from '../TextArea'
import TimeOffset from '../TimeOffset'
import Upload from '../Upload'
import FormFieldElementStyled from './styled'

const FormFieldElement = ({
  disabled,
  value,
  dataKey,
  path,
  subPath,
  defaultValue,
  instance,
  variant,
  label,
  selectOptions,
  componentName = 'Text',
  componentProps,
  onPressEnter,
  onChange,
  onOpenChange,
  intl,
  dataCy: incomingDataCy = 'FormFieldElement',
}) => {
  const { className } = componentProps
  const dataCy = `${incomingDataCy}-${componentName}${dataKey ? `-${dataKey}` : ''}`
  const component = useMemo(() => {
    return {
      Switch,
      Select,
      DatePicker,
      Input,
      NumberInput,
      IconPicker,
      Icon,
      Status: AgGridStatusCell,
      ValuePicker,
      LinkPicker,
      ColorPicker,
      DateRangeSelector,
      PeriodPicker,
      GranularitySelector,
      Button,
      Table,
      CaptionEditor,
      Text,
      SelectionText,
      Datapoints,
      Entities,
      DatapointGroups,
      RangeSlider,
      ColorRangeSlider,
      NumberRangeInput,
      DefaultStartTimeSelector,
      Comparator,
      MicroWidgetConfigurator,
      Upload,
      EquipmentSelector,
      EquipmentPresetDatapointSelector,
      RawDataConfigurator,
      ComparisonPointConfigurator,
      MapEntitySelector,
      MapZoomConfigurator,
      CustomLogic,
      MicroWidgetEdgeConfigurator,
      MicroWidgetEdges,
      DateTimeFormatPicker,
      ValueTypeSelector,
      UnitPicker,
      EntitiesPicker,
      DatapointValueTypePicker,
      EntityValueTypePicker,
      Progress,
      Columns,
      InformationLines,
      Rows,
      ColumnPicker,
      EntityFilePicker,
      TimeOffset,
      Color: Button,
      TableIconPicker,
      OptionPicker,
      DateTime,
      EmissionFactorSelector,
      XRangeLineConfigurator,
      CustomerPicker,
      ClassificationPicker,
      EntityCascader,
      DecreaseConfigurator,
      TextArea,
      CalculationMethod,
      MinMaxColorRangeSlider,
      HeatMapMinMaxColorRangeSlider,
      GhgMinMaxColorRangeSlider,
      InformationDetailConfigurator,
      NavigationTypeSelector,
      CaptionTooltip,
      FixedValues,
      EntityTagPicker,
      LocationSearch,
      DashboardLink,
    }[componentName]
    // eslint-disable-next-line
  }, [componentName, Table])
  const fieldElementProps = useMemo(() => {
    switch (componentName) {
      case 'Switch':
        return {
          ...componentProps,
          onChange: (value, e) => {
            e.stopPropagation()
            onChange(value)
          },
          checked: value,
          defaultChecked: defaultValue,
          value,
          size: componentProps.size || 'small',
          dataCy,
        }
      case 'Input':
      case 'TextArea':
        return {
          ...componentProps,
          value: value || defaultValue,
          onBlur: (e) => onChange(e.target.value),
          dataCy,
        }
      case 'NumberInput':
        return {
          ...componentProps,
          value: value || defaultValue,
          onBlur: (value) => onChange(value),
          dataCy,
        }
      case 'NumberRangeInput':
        return {
          ...componentProps,
          value: value || defaultValue,
          onBlur: (value) => onChange(value),
          dataCy,
        }
      case 'Text':
        return {
          text: componentProps.viewRender
            ? componentProps.viewRender(value, instance)
            : componentProps.text && componentProps.text.formatted
            ? intl.formatMessage({ id: componentProps.text.formatted })
            : componentProps.text || value,
          dataCy,
        }
      case 'Icon':
        return {
          ...componentProps,
          onClick: () => onChange(value),
          icon: componentProps.viewRender
            ? componentProps.viewRender(value, instance)
            : componentProps.icon || value || defaultValue,
          dataCy,
        }
      case 'CaptionTooltip':
        return {
          ...componentProps,
          onClick: () =>
            componentProps.onChange
              ? componentProps.onChange(value, { path, subPath })
              : onChange(value),
          caption:
            typeof componentProps.caption === 'function'
              ? componentProps.caption(instance)
              : {
                  ...componentProps.caption,
                  ...(componentProps.selectionKeys
                    ? { ...value, text: get(instance, componentProps.selectionKeys.label) }
                    : { text: value }),
                },
          dataCy,
        }
      case 'Color':
        return {
          ...componentProps,
          color: value || defaultValue,
          variant: 'ColorPicker',
          dataCy,
        }
      case 'Button':
        return {
          ...componentProps,
          onClick: () => onChange(componentProps),
          value,
          label:
            componentProps.label &&
            (componentProps.label.formatted
              ? intl.formatMessage({ id: componentProps.label.formatted })
              : componentProps.label.dataKey
              ? get(value || defaultValue, componentProps.label.dataKey)
              : componentProps.label.text),
          dataCy,
        }
      case 'Datapoints':
        return {
          ...componentProps,
          subPath,
          label,
          onChange,
          onClick: (e, selectedItem) => {
            return componentProps.section ? onChange({ ...componentProps, selectedItem }) : null
          },
          dataCy,
        }
      case 'Entities':
        return {
          ...componentProps,
          subPath,
          label,
          onChange,
          onClick: (e, selectedItem) =>
            componentProps.section ? onChange({ ...componentProps, selectedItem }) : null,
          dataCy,
        }
      case 'DatapointGroups':
        return {
          ...componentProps,
          subPath,
          //label,
          onChange,
          onClick: (e, { type = 'section', ...selectedItem }) => {
            const section = {
              section: componentProps.section,
              groups: componentProps.groupSection,
              linked: componentProps.linkedSection,
            }[type]
            return section
              ? onChange({
                  ...componentProps,
                  section:
                    type === 'section'
                      ? componentProps.section
                      : {
                          ...section,
                          componentProps: {
                            ...section.componentProps,
                            section: componentProps.section,
                          },
                        },
                  dataKey: section.name,
                  selectedItem,
                })
              : null
          },
          dataCy,
        }
      case 'Columns':
        return {
          ...componentProps,
          subPath,
          //label,
          onChange,
          onClick: (e, selectedItem) =>
            componentProps.section
              ? onChange({
                  ...componentProps,
                  dataKey: componentProps.section.name,
                  selectedItem,
                })
              : null,
          dataCy,
        }
      case 'InformationLines':
        return {
          ...componentProps,
          subPath,
          //label,
          onChange,
          onClick: (e, selectedItem) =>
            componentProps.section
              ? onChange({
                  ...componentProps,
                  dataKey: componentProps.section.name,
                  selectedItem,
                })
              : null,
          dataCy,
        }
      case 'Rows':
        return {
          ...componentProps,
          subPath,
          //label,
          onChange,
          onClick: (e, selectedItem) =>
            componentProps.section
              ? onChange({
                  ...componentProps,
                  dataKey: componentProps.section.name,
                  selectedItem,
                })
              : null,
          dataCy,
        }
      case 'MapEntitySelector':
        return {
          ...componentProps,
          label,
          value,
          onChange,
          onClick: (e, selectedItem) => {
            return onChange({ ...componentProps, selectedItem })
          },
          dataCy,
        }
      case 'Progress':
        return {
          ...componentProps,
          value,
          color: componentProps.colorRender
            ? componentProps.colorRender(instance)
            : componentProps.color,
          dataCy,
        }
      case 'EquipmentSelector':
        return {
          ...componentProps,
          label,
          value,
          onChange,
          onClick: (e, selectedItem) => {
            return onChange({ ...componentProps, selectedItem })
          },
          dataCy,
        }
      case 'CalculationMethod':
        return {
          ...componentProps,
          label,
          value: value || instance,
          onChange,
          dataCy,
        }
      case 'EquipmentPresetDatapointSelector':
        return {
          ...componentProps,
          label,
          value,
          onChange,
          dataCy,
        }
      case 'MicroWidgetEdges':
        return {
          ...componentProps,
          label,
          value,
          onChange,
          onClick: (e, selectedItem) => {
            return onChange({ ...componentProps, selectedItem })
          },
          dataCy,
        }
      case 'Comparator':
        return {
          ...componentProps,
          subPath,
          value,
          onChange,
          onClick: () => onChange(componentProps),
          dataCy,
        }
      case 'Status':
        return {
          ...componentProps,
          status: value,
          dataCy,
        }
      case 'ColorRangeSlider':
        return {
          ...componentProps,
          value,
          tickRange:
            componentProps.tickRange &&
            (Array.isArray(componentProps.tickRange)
              ? [
                  typeof componentProps.tickRange[0] === 'number'
                    ? componentProps.tickRange[0]
                    : get(
                        {
                          instance,
                          value,
                        },
                        componentProps.tickRange[0]
                      ),
                  typeof componentProps.tickRange[1] === 'number'
                    ? componentProps.tickRange[1]
                    : get(
                        {
                          instance,
                          value,
                        },
                        componentProps.tickRange[0]
                      ),
                ]
              : get({ instance, value }, componentProps.tickRange)),
          onChange,
          dataCy,
        }
      case 'MinMaxColorRangeSlider':
      case 'HeatMapMinMaxColorRangeSlider':
      case 'GhgMinMaxColorRangeSlider':
        return {
          ...componentProps,
          value,
          tickRange:
            componentProps.tickRange &&
            (Array.isArray(componentProps.tickRange)
              ? [
                  typeof componentProps.tickRange[0] === 'number'
                    ? componentProps.tickRange[0]
                    : get(
                        {
                          instance,
                          value,
                        },
                        componentProps.tickRange[0]
                      ),
                  typeof componentProps.tickRange[1] === 'number'
                    ? componentProps.tickRange[1]
                    : get(
                        {
                          instance,
                          value,
                        },
                        componentProps.tickRange[0]
                      ),
                ]
              : get({ instance, value }, componentProps.tickRange)),
          onChange,
          dataCy,
        }
      case 'ValuePicker':
      case 'DatapointValueTypePicker':
        return {
          ...componentProps,
          subPath,
          value,
          onChange: (value, option) => onChange(value, null, option),
          dataCy,
        }
      default:
        return {
          ...componentProps,
          subPath,
          value,
          onChange,
          dataCy,
        }
    }
  }, [
    componentName,
    componentProps,
    value,
    onChange,
    defaultValue,
    instance,
    intl,
    label,
    subPath,
    path,
    dataCy,
  ])

  return (
    <FormFieldElementStyled
      {...fieldElementProps}
      className={`FormFieldElement ${componentName} ${className || ''}`}
      as={component || 'div'}
      disabled={disabled || componentProps.readOnly}
      variant={fieldElementProps.variant || variant}
      componentName={componentName}
      defaultValue={value || defaultValue}
      selectOptions={selectOptions}
      onPressEnter={onPressEnter}
      onOpenChange={onOpenChange}
      data-cy={dataCy}
    />
  )
}

FormFieldElement.propTypes = {
  componentName: string,
  componentProps: object,
}

FormFieldElement.defaultProps = {}

export default injectIntl(FormFieldElement)
