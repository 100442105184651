import { transparentize } from 'polished'
import { css } from 'styled-components'

export const DashboardMenu = css`
  transition: top 0.3s ease, bottom 0.3s ease, left 0.3s ease, right 0.3s ease;
  font-size: 0.9rem;
  background-color: ${({ theme }) => transparentize(0.1, theme.color.backgroundFront)};
  border: 1px solid ${({ theme }) => theme.color.softStroke};
  box-shadow: ${({ theme }) => theme.shadowElevation2(true)};
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
  min-height: unset;
  width: fit-content;
  min-width: unset;

  .Icon {
    color: ${({ theme }) => theme.color.main};
    width: 1rem;
    &.close-button {
      display: ${({ theme }) => theme.screenSize.screen.current !== 's' && 'none'};
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
`
