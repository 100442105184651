import React from 'react'

import Button from '../../../../Global/Button'
import CaptionTooltip from '../../../../Global/FormField/CaptionTooltip'
import { MenuItemButtonStyled } from './styled'

const MenuItemButton = ({ caption, ...props }) => {
  return (
    <MenuItemButtonStyled className="MenuItemButton">
      <CaptionTooltip caption={caption} timeout={800} variant="DashboardMenu">
        <Button {...props} />
      </CaptionTooltip>
    </MenuItemButtonStyled>
  )
}

export default MenuItemButton
