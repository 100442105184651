import React, { useMemo } from 'react'

import { transparentize } from 'polished'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  getDatapointCalculatedValue,
  getDatapointsCalculatedValue,
  getDatapointsOffsetsCalculatedValues,
} from 'util/datapointCalculationFunctions'
import { useDatapointsSparklinePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/Sparkline/Ui'

import { useWidgetDatapoints } from '../../../Dashboard/utils/assets/hooks'
import { getSparkLineHistory } from '../functions'

const ChartCard = ({
  dataProps = {},
  datapoints = [],
  method = 'sum',
  icon,
  url,
  defaultStartTime,
  defaultGranularity = 'h',
  defaultGranularityValue = 1,
  title,
  color,
  preview = { chart: { lines: [] } },
  intl,
  customLogic,
  conversionUnit,
  decimals = 2,
  newSocketValue,
  defaultCalculationMethod,
  datapointsLinked,
  locationData,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const { isPreviewMode, isEditMode } = dataProps
  const theme = useTheme()

  const {
    datapoints: widgetDatapoints,
    comparators,
    minComparators,
    maxComparators,
  } = useWidgetDatapoints({
    datapoints,
    datapointsLinked,
    locationData,
  })

  const allDatapoints = useMemo(
    () => [...widgetDatapoints, ...comparators, ...minComparators, ...maxComparators],
    [widgetDatapoints, comparators, minComparators, maxComparators]
  )

  const datapointsPayload = useDatapointsSparklinePayload({
    datapoints: allDatapoints,
    defaultStartTime,
    defaultGranularity,
    defaultGranularityValue,
    conversionUnit,
    calculationMethod: defaultCalculationMethod,
    locationData,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: { datapoints: datapointsPayload, widgetType: 'kpiBackgroundTrend' },
    deps: [
      datapoints,
      datapointsLinked,
      defaultStartTime,
      defaultGranularity,
      defaultGranularityValue,
      conversionUnit,
      defaultCalculationMethod,
      locationData,
    ],
    skip: !allDatapoints.length,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data?.data?.datapoints

  const { chart, value, errors, color: widgetColor } = useMemo(() => {
    const errors = getDatapointErrors(validData)

    if (!validData) {
      return {
        chart: {
          ...preview.chart,
          lines:
            error || !isPreviewMode
              ? []
              : [
                  {
                    ...preview.chart.lines[0],
                    colors: color
                      ? {
                          area: transparentize(0.9, color),
                          line: color,
                        }
                      : {
                          area: transparentize(0.9, theme.color.main),
                          line: theme.color.main,
                        },
                  },
                ],
        },
        value: error || !isPreviewMode ? '--' : 0,
        color,
        errors,
      }
    }
    const lineColor = (locationData && allDatapoints[0]?.color) || color
    let value, history

    if (defaultGranularity === 'month') {
      const {
        history: calculatedHistory,
        ...calculatedValues
      } = getDatapointsOffsetsCalculatedValues({ data: validData, decimals })
      value = calculatedValues[method || 'sum']
      history = calculatedHistory
    } else {
      if (validData.length === 1) {
        const { history: dataHistory, ...data } = validData[0]
        value = getDatapointCalculatedValue(data, { decimals, customLogic })
        history = getSparkLineHistory(validData)
      } else {
        value = getDatapointsCalculatedValue(validData, { decimals, customLogic, method })
        history = getSparkLineHistory(validData, method)
      }
    }

    value = typeof value === 'number' ? localizeNumber(value) : '--'
    return {
      value,
      chart: {
        lines: [
          {
            values: history,
            colors: {
              area: transparentize(0.9, lineColor || theme.color.main),
              line: lineColor || theme.color.main,
            },
          },
        ],
      },
      errors,
      color: lineColor,
    }
  }, [
    validData,
    color,
    customLogic,
    defaultGranularity,
    decimals,
    method,
    preview.chart,
    theme.color.main,
    error,
    localizeNumber,
    isPreviewMode,
    locationData,
    allDatapoints,
  ])

  return (
    <Ui
      {...props}
      chart={chart}
      preview={preview}
      value={value}
      unit={
        validData &&
        validData[0] &&
        (validData[0].conversionFactor?.endUnit.name || validData[0].unit)
      }
      title={locationData ? `${allDatapoints[0]?.name} | ${title}` : title}
      icon={icon}
      color={widgetColor}
      url={url}
      loading={{
        loading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(ChartCard)
