import React from 'react'

import { Empty } from 'antd'
import set from 'lodash/fp/set'

import TableStyled, { TableBody, TableRow } from './styled'

const Table = ({
  columns,
  value = [],
  buttons,
  defaultRowValue,
  readOnly,
  onChange,
  onArrayUpdate,
  onDelete,
  fixedFileId,
  disableAdd = false,
}) => {
  const handleChange = ({ rowIndex, dataKey, value: updateValue }) => {
    return onChange(set(`[${rowIndex}].${dataKey}`, updateValue, value))
  }

  const clearObject = (object = {}) => {
    return Object.keys(object).reduce((object, key = '0') => {
      object[key] = null
      return object
    }, {})
  }

  const getDefaultRow = (rowIndex) => {
    const defaultRow =
      typeof defaultRowValue === 'function' ? defaultRowValue({ value }) : defaultRowValue

    return defaultRow
      ? { ...defaultRow, value: defaultRow.value || `new-${value.length}` }
      : clearObject(value[rowIndex])
  }

  const getColumnConfig = ({ componentName, componentProps, ...column }) => {
    let component = componentName
    if (readOnly) {
      component =
        {
          ValuePicker: 'SelectionText',
          ColorPicker: 'Color',
          TableIconPicker: 'Icon',
          DatePicker: 'DateTime',
        }[componentName] || componentName
    }

    return {
      ...column,
      componentName: component,
      componentProps: { ...componentProps, readOnly },
    }
  }

  const handleRowActionClick = (e, { name, rowIndex }) => {
    if (onArrayUpdate && !(name === 'delete' && onDelete)) {
      return onArrayUpdate({ name, rowIndex, data: value[rowIndex] })
    }
    switch (name) {
      case 'add':
        const addedItem = getDefaultRow(rowIndex)
        return onChange(
          rowIndex > 0
            ? [...value.slice(0, rowIndex + 1), addedItem, ...value.slice(rowIndex + 1)]
            : [addedItem, ...value]
        )
      case 'delete':
        if (onDelete) {
          onDelete(fixedFileId, value[rowIndex].id)
        }
        if (onArrayUpdate) {
          onArrayUpdate({ name, rowIndex, data: value[rowIndex] })
        }
        return onChange([...value.slice(0, rowIndex), ...value.slice(rowIndex + 1)])
      default:
    }
  }

  const { header, tableColumns } = columns.reduce(
    (tableConfig, column) => {
      if (column.label?.formatted) {
        tableConfig.header.formattedLabels = tableConfig.header.formattedLabels || true
      } else if (column.label) {
        tableConfig.header[column.dataKey] = column.label
      }
      tableConfig.tableColumns.push(getColumnConfig(column))
      return tableConfig
    },
    { header: {}, tableColumns: [] }
  )

  return (
    <TableStyled className="Table">
      {header && (
        <TableRow
          fields={columns.map(({ componentName, label, ...col }) => ({
            ...col,
            ...(header.formattedLabels && { label }),
            hideValue: header.formattedLabels,
          }))}
          value={!header.formattedLabels && header}
          variant="Table"
          className="table-head"
          buttons={
            (!readOnly || buttons) &&
            !disableAdd && [
              {
                name: 'add',
                icon: 'lnr lnr-plus-circle',
              },
            ]
          }
          buttonsAlwaysVisible
          onButtonClick={(e, payload) =>
            handleRowActionClick(e, { ...payload, rowIndex: value.length })
          }
        />
      )}
      <TableBody className="TableBody">
        {!value || value.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          value.map((row, index) => (
            <TableRow
              key={row.id || row.name || `row-${index}`}
              fields={tableColumns}
              value={row}
              rowIndex={index}
              path={`root[${index}]`}
              rowsTotal={value.length}
              hideLabels
              variant="Table"
              buttons={
                buttons ||
                (!readOnly && [
                  ...(onArrayUpdate ? [{ name: 'edit', icon: 'lnr lnr-pencil' }] : []),
                  ...(header ? [] : [{ name: 'add', icon: 'lnr lnr-plus-circle' }]),
                  {
                    name: 'delete',
                    icon: 'lnr lnr-trash',
                  },
                ])
              }
              onChange={(payload) =>
                handleChange({ ...payload, rowIndex: index, rowsTotal: value.length })
              }
              onButtonClick={(e, payload) =>
                handleRowActionClick(e, { ...payload, rowIndex: index, rowsTotal: value.length })
              }
            />
          ))
        )}
      </TableBody>
    </TableStyled>
  )
}

export default Table
