import { Menu } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

export const AntMenu = styled(Menu)`
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-item-active {
    background-color: ${({ theme }) => transparentize(0.8, theme.color.main)} !important;
  }
`
