export default {
  'widget.insufficientRights': "Vous n'avez pas les droits suffisants pour consulter ce widget",
  'widget.noDataForDataPoint': 'Aucune donnée trouvée pour le (s) point (s) de données',
  'widget.noDatapointsSelected': 'Aucun point(s) de données sélectionné(s)',
  'widget.noInternetConnection': 'Pas de connexion Internet',
  'widget.configureYourWidget': 'Configurez votre widget',
  'widget.selectYourWidget': 'Sélectionnez votre widget',
  'widget.selectDatapoint': 'Sélectionnez un point de données',
  'widget.selectDatapoints': 'Sélectionnez des points de données',
  'widget.selectedDatapoints': 'Points de données sélectionnés',
  'widget.selectedDatapoint': 'Point de données sélectionné',
  'widget.selectYourDatapoints': 'Sélectionnez vos points de données',
  'widget.selectDatapointsForThisWidget': 'Sélectionnez vos points de données pour ce widget',
  'widget.label': 'Étiquette',
  'widget.icon': 'Icône',
  'widget.icons': 'Icônes',
  'widget.size': 'Taille',
  'widget.link': 'Lien',
  'widget.dashboard': 'Tableau de bord',
  'widget.section': 'Section',
  'widget.url': 'Url',
  'widget.color': 'Couleur',
  'widget.colors': 'Couleurs',
  'widget.colorsPercentage': 'Couleurs (%)',
  'widget.method': 'Méthode',
  'widget.calculationMethod': 'Méthode de calcul',
  'widget.calculationMethodMin': 'Méthode de calcul min',
  'widget.calculationMethodMax': 'Méthode de calcul max',
  'widget.difference': 'Différence',
  'widget.sum': 'Somme',
  'widget.mean': 'Moyenne',
  'widget.min': 'Min',
  'widget.max': 'Max',
  'widget.chart': 'Graphique',
  'widget.datapoint': 'Point de données',
  'widget.lines': 'Lignes',
  'widget.datapoints': 'Points de données',
  'widget.getDataPointsForEntity': "Obtenez des points de données pour l'entité:",
  'widget.search': 'Chercher...',
  'widget.entities': 'Entités',
  'widget.selectedEntity': 'Entité sélectionnée',
  'widget.tagsAndMarkers': 'Tags & markers',
  'widget.noData': 'Pas de données',
  'widget.ok': 'Ok',
  'widget.back': 'Retour',
  'widget.next': 'Suivant',
  'widget.showUnit': "Afficher l'unité",
  'widget.hideValue': 'Masquer la valeur',
  'widget.caption': 'Légende',
  'widget.captions': 'Légendes',
  'widget.okValue': 'Valeur correcte',
  'widget.warning': 'Avertissement',
  'widget.error': 'Erreur',
  'widget.inProgress': 'En cours',
  'widget.value': 'Value',
  'widget.type': 'Type',
  'widget.inactive': 'Inactif',
  'widget.yearOverview': "Aperçu de l'année",
  'widget.kpiWidget': 'KPI',
  'widget.navigation': 'Navigation',
  'widget.lastValue': 'Dernière valeur',
  'widget.lastValueDateTime': 'Heure de la date de la dernière valeur',
  'widget.expectedDateTime': 'Date et heure prévues',
  'widget.timeOverdue': 'Temps de retard',
  'widget.consumption': 'Consommation',
  'widget.gauge': 'Jauge',
  'widget.absoluteGauge': 'Jauge absolue',
  'widget.relativeGauge': 'Jauge relative',
  'widget.deviation': 'Déviation max. en %',
  'widget.circleGauge': 'Jauge de cercle',
  'widget.comparisonPieChart': 'Diagramme circulaire',
  'widget.referenceValue': 'Valeur de référence',
  'widget.reference': 'Référence',
  'widget.unit': 'Unité',
  'widget.timeUnit': 'Unité de temps',
  'widget.time': 'Temps',
  'widget.defaultStartTime': 'Heure de début par défaut',
  'widget.seconds': 'Secondes',
  'widget.minutes': 'Minutes',
  'widget.hours': 'Heurs',
  'widget.day': 'Jour',
  'widget.days': 'Jours',
  'widget.week': 'Semaine',
  'widget.weeks': 'Semaines',
  'widget.month': 'Mois',
  'widget.months': 'Mois',
  'widget.year': 'Année',
  'widget.years': 'Années',
  'widget.granularity': 'Granularité',
  'widget.showTotals': 'Afficher les totaux',
  'widget.hideRawData': 'Données brutes disponibles',
  'widget.rawData': 'Données brutes',
  'widget.showRawDataByDefault': 'Données brutes activé par défaut',
  'widget.datapointTotals': 'Totaux des points de données',
  'widget.widgetConfiguration': 'Configuration du widget',
  'widget.comparisonPoint': 'Point de comparaison',
  'widget.percentage': 'Pourcentage',
  'widget.range': 'Gamme',
  'widget.minMaxValue': 'Valeur min/max',
  'widget.1day': '1 jour',
  'widget.cancel': 'Annuler',
  'widget.save': 'Enregistrer',
  'widget.saveWidget': 'Enregistrer le widget',
  'widget.deltaValue': 'Valeur delta',
  'widget.data': 'Données',
  'widget.opacity': 'Opacité',
  'widget.none': 'Aucun',
  'widget.fixedValue': 'Valeur fixe',
  'widget.fixedValues': 'Valeurs fixes',
  'widget.clearAllDatapoints': 'Effacer tous les points de données',
  'widget.clearDatapoint': 'Effacer la points de donnée',
  'widget.clear': 'Effacer',
  'widget.noLink': 'Pas de lien',
  'widget.placeholder': 'Espace réservé',
  'widget.emptySpace': 'Espace vide',
  'widget.noConfigurationOptions': 'Aucune option de configuration',
  'widget.showPercentage': 'Afficher le pourcentage',
  'widget.showInvertedValue': 'Afficher la valeur résiduelle',
  'widget.heatmap': 'Carte de chaleur',
  'widget.decimals': 'Décimales',
  'widget.status': 'Statut',
  'widget.showDescription': 'Montrer la description',
  'widget.trendChartWidget': 'Trend Chart Widget',
  'widget.info': 'Infos',
  'widget.useSteps': 'Affichage par étapes',
  'widget.invertValues': 'Inverser les valeurs',
  'widget.preset': 'Valeur prédéfinie',
  'widget.presetLabel': 'Étiquette prédéfinie',
  'widget.efficiencyGauge': "Jauge d'efficacité",
  'widget.grid': 'Table',
  'widget.ghgGrid': 'GHG - Table',
  'widget.enableExport': "Activer l'exportation",
  'widget.calendar': 'Calendrier',
  'widget.connectivity': 'Connectivité',
  'widget.booleanCalendar': 'Afficher valeurs comme activé/désactivé',
  'widget.default': 'Défaut',
  'widget.boolean': 'Activé/désactivé',
  'widget.expandable': 'Extensible',
  'widget.defaultOpen': 'Par défaut ouvert',
  'widget.timeSelection': "Sélection de l'heure",
  'widget.discardChanges': 'Annuler les modifications non enregistrées avant de partir?',
  'widget.yes': 'Oui',
  'widget.no': 'Non',
  'widget.createSection': 'Créer une section',
  'widget.editSection': 'Éditer cette section',
  'widget.deleteSection': 'Supprimer cette section',
  'widget.deleteSectionConfirm': 'Êtes-vous sûr de vouloir supprimer cette section?',
  'widget.layouts': 'Mises en page',
  'widget.widgets': 'Widgets',
  'widget.selectALayout': 'Sélectionnez une mise en page',
  'widget.selectAWidget': 'Sélectionnez un widget',
  'widget.selectLayout': 'Sélectionnez cette mise en page',
  'widget.selectWidget': 'Sélectionnez ce widget',
  'widget.hideHeader': "Masquer l'en-tête",
  'widget.noSections': 'Aucune section configurée',
  'widget.floorMap': 'Plan',
  'widget.microWidget': 'Micro widget',
  'widget.plusMinus': 'Plus / Moins',
  'widget.onOff': 'Allumer / Éteindre',
  'widget.mode': 'Mode',
  'widget.controlButtons': 'Boutons de contrôle',
  'widget.incrementValue': "Valeur d'incrément",
  'widget.iconOn': 'Icône activée',
  'widget.iconOff': 'Icône désactivée',
  'widget.colorOn': 'Couleur activée',
  'widget.colorOff': 'Couleur désactivée',
  'widget.control': 'Contrôle',
  'widget.writeDatapoint': 'Point de données écrire',
  'widget.addWidget': 'Ajouter un widget',
  'widget.offset': 'Décalage',
  'widget.historicalComparisonChartWidget': 'Comparaison Historique Trend Widget',
  'widget.previousPeriod': 'Semaine passée',
  'widget.currentPeriod': 'Semaine actuelle',
  'widget.navigationLabel': 'Étiquette de navigation',
  'widget.addNavigationLabel': 'Ajouter une étiquette de navigation',
  'widget.offsets': 'Décalages',
  'widget.timeRange': 'Intervalle de temps',
  'widget.timeRangeDatapoints': 'Intervalle de temps pour point(s) de données principal(s)',
  'widget.timeRangeComparisonDatapoints':
    'Intervalle de temps pour point(s) de données de comparaison',
  'widget.style': 'Style',
  'widget.customMarker': 'Marqueur réglable',
  'widget.addCustomMarker': 'Ajouter un marqueur réglable',
  'widget.showSquealInfo': "Afficher les informations d'alarme",
  'widget.geocoordinatesClickOnMap':
    'Géocoordonnées (cliquez sur la carte pour définir une position)',
  'widget.mapCenterClickOnMap':
    'Centre de la carte (cliquez sur la carte pour définir une position)',
  'widget.zoom': 'Zoom',
  'widget.autoZoom': 'Zoom automatique',
  'widget.map': 'Carte',
  'widget.addEntities': 'Ajouter des Sites',
  'widget.addTraceableDevices': 'Ajouter des traceurs',
  'widget.comparisonPeriod': 'Période de comparaison',
  'widget.customLogic': 'Logique personnalisée',
  'widget.equipments': 'Équipements',
  'widget.equipmentType': "Type d'équipement",
  'widget.selectedEquipments': 'Équipements sélectionnés',
  'widget.equipmentsForType': 'Équipements pour le type sélectionné',
  'widget.equipmentLabel': "Étiquette d'équipement",
  'widget.matrix': 'Matrice',
  'widget.matrixDetail': 'Matrice détail',
  'widget.addLayout': 'Ajouter une mise en page',
  'widget.showComparator': 'Montrer valeur de référence',
  'widget.areYouSure': 'Êtes-vous sûr?',
  'widget.unit.tonCO2e': 'Tonne CO2e',
  'widget.circle': 'Cercle',
  'widget.connectedDatapoints': 'Points de données connectés',
  'widget.inheritColor': 'Hériter la couleur',
  'widget.highlightOnHover': 'Mettre en surbrillance au survol',
  'widget.enlargeLabelOnHover': "Agrandir l'étiquette au survol",
  'widget.showDate': 'Afficher la date',
  'widget.dateFormat': 'Format de date',
  'widget.timeFormat': "Format de l'heure",
  'widget.clock': 'Horloge',
  'widget.divider': 'Diviseur',
  'widget.showDivider': 'Afficher le diviseur',
  'widget.backgroundColor': 'Couleur de fond',
  'widget.fontColor': 'Couleur du texte',
  'widget.differenceArrow': 'Flèche de différence',
  'widget.differencePercentage': 'Pourcentage de différence',
  'widget.showDifferenceArrow': 'Afficher la flèche de différence',
  'widget.showDifferencePercentage': 'Afficher le pourcentage de différence',
  'widget.arrowUpColor': 'Couleur de la flèche vers le haut',
  'widget.arrowDownColor': 'Couleur de la flèche vers le bas',
  'widget.transparentZone': 'Zone transparente',
  'widget.offlineSince': 'déconnecté depuis',
  'widget.isOnline': 'est connecté',
  'widget.sdg1': 'No poverty',
  'widget.sdg2': 'Zero hunger',
  'widget.sdg3': 'Good health and well-being',
  'widget.sdg4': 'Quality education',
  'widget.sdg5': 'Gender equality',
  'widget.sdg6': 'Clean water and sanitation',
  'widget.sdg7': 'Affordable and clean energy',
  'widget.sdg8': 'Decent work and economic growth',
  'widget.sdg9': 'Industry, innovation and infrastructure',
  'widget.sdg10': 'Reduced inequalities',
  'widget.sdg11': 'Sustainable cities and communities',
  'widget.sdg12': 'Responsible consumption and production',
  'widget.sdg13': 'Climate action',
  'widget.sdg14': 'Life below water',
  'widget.sdg15': 'Life on land',
  'widget.sdg16': 'Peace, justice and strong institutions',
  'widget.sdg17': 'Partnership for the goals',
  'widget.hideLabel': "Masquer l'étiquette",
  'widget.sdgWidget': 'Widget SDG',
  'widget.maxWidgetResizeError':
    'La taille limite de ce widget a été dépassée. Déverrouillez un widget dans cette ligne et réessayez.',
  'widget.lockedWidgetResizeError':
    'Les widgets verrouillés ne peuvent pas être redimensionnés. Déverrouillez votre widget et réessayez.',
  'widget.lockedWidgetAddError':
    "Cette ligne n'a plus d'espace pour ajouter un widget. Déverrouillez un widget dans cette ligne et réessayez.",
  'widget.copySection': 'Copier dans le presse-papiers',
  'widget.pasteSection': 'Coller du presse-papiers',
  'widget.charts': 'Graphiques',
  'widget.gaugesIndicators': 'Jauges & Indicateurs',
  'widget.grids': 'Tables',
  'widget.ghgGrids': 'GHG - Tables',
  'widget.controls': 'Régulations',
  'widget.others': 'Autres',
  'widget.general': 'Général',
  'widget.interval': 'Intervalle',
  'widget.eventLog': 'Journal des événements',
  'widget.advanced': 'Avancée',
  'widget.sustainability': 'Durabilité',
  'widget.mainDatapoint': 'Point(s) de données principal',
  'widget.otherDatapoint': 'Autre point(s) de données',
  'widget.absoluteValue': 'Valeur absolue',
  'widget.differenceValue': 'Valeur de différence',
  'widget.group': 'Groupe',
  'widget.groups': 'Groupes',
  'widget.addGroup': 'Ajouter un groupe',
  'widget.selectedGroup': 'Groupe sélectionné',
  'widget.unGroupedDatapoints': 'Points de données non groupés',
  'widget.selectedDatapointConfiguration': 'Configuration du point de données sélectionné',
  'widget.selectedDataConfiguration': 'Configuration de données sélectionné',
  'widget.last': 'Dernier',
  'widget.classicWidgets': 'Widgets classiques',
  'widget.sustainabilityWidgets': 'Widgets de durabilité',
  'widget.ghgCharts': 'Graphiques - GHG',
  'widget.scope': 'Scope',
  'widget.emissionFactors': 'Détails des émissions',
  'widget.emissionFactor': 'Détail des émissions',
  'widget.selectData': 'Sélectionnez les données',
  'widget.clearData': 'Effacer les données',
  'widget.ghgGaugesIndicators': 'GHG - Jauges & Indicateurs',
  'widget.countries': 'Pays',
  'widget.columns': 'Colonnes',
  'widget.column': 'Colonne',
  'widget.addColumn': 'Ajouter une colonne',
  'widget.selectedColumnConfiguration': 'Configuration de colonne sélectionnée',
  'widget.selectedLineConfiguration': 'Configuration de ligne sélectionnée',
  'widget.selectedRowConfiguration': 'Configuration de ligne sélectionnée',
  'widget.timeRangeValue': 'Valeur de la plage de temps',
  'widget.site': 'Location',
  'widget.building': 'Bâtiment',
  'widget.floor': 'Étage',
  'widget.room': 'Pièce',
  'widget.equipment': 'Équipement',
  'widget.name': 'Nom',
  'widget.width': 'Largeur',
  'widget.row': 'Ligne',
  'widget.rows': 'Lignes',
  'widget.addRow': 'Ajouter une ligne',
  'widget.comparisonValue': 'Valeur de comparaison',
  'widget.amount': 'Quantité',
  'widget.selectEntity': 'Sélectionnez une entité',
  'widget.clearEntity': "Effacer l'entité",
  'widget.file': 'Ficher',
  'widget.files': 'Fichers',
  'widget.image': 'Image',
  'widget.information': 'Information',
  'widget.informationLine': "Ligne d'information",
  'widget.line': 'Ligne',
  'widget.addLine': 'Ajouter une ligne',
  'widget.lineType': 'Lijntype',
  'widget.during': 'Pendant',
  'widget.duration': 'Durée',
  'widget.informationLines': "Lignes d'information",
  'widget.border': 'Bordure',
  'widget.donut': 'Donut',
  'widget.showLegend': 'Afficher la légende',
  'widget.stack': 'Empiler',
  'widget.bar': 'Bar',
  'widget.ascending': 'Ascendant',
  'widget.descending': 'Descendant',
  'widget.sortOrder': 'Ordre de tri',
  'widget.lineChart': 'Graphique en ligne',
  'widget.barChart': 'Diagramme à bandes',
  'widget.selectSite': 'Sélectionnez un site',
  'widget.selectTraceableDevice': 'Sélectionnez un appareil traçable',
  'widget.consumptionExport': 'Exportation de la consommation',
  'widget.organisation': 'Organisation',
  'widget.totals': 'Totaux',
  'widget.historicalBarChart': 'Graphique à barres historique',
  'widget.xrange': 'X Range',
  'widget.customer': 'Client',
  'widget.dataEntry': 'Saisie des données',
  'widget.dataSource': 'La source de données',
  'widget.confirmYourEntry': 'Confirmez votre saisie',
  'widget.areYouSureSmallerThanPrevious':
    'Voulez-vous saisir une valeur inférieure à la précédente?',
  'widget.areYouSureNegativeValue': 'Voulez-vous saisir une valeur négative?',
  'widget.relativeInput': 'Entrée relative',
  'widget.showOverdueDatapointsOnly': 'Afficher uniquement les points de données en retard',
  'widget.statusGrid': "Grille d'état",
  'widget.energySaving': "Économie d'énergie",
  'widget.meterOverview': 'Aperçu du compteur',
  'widget.relativeToggleCaption':
    'Avec "Relatif : actif", les valeurs sont saisies en tant que différence par rapport à la valeur précédente. Avec "Relatif : inactif", les valeurs sont saisies comme telles.',
  'widget.energyClassification': 'Classification énergétique',
  'widget.area': 'Zone',
  'widget.performancePeriod': 'Performance {period}',
  'widget.varianceToPeriod': 'Écart à {period}',
  'widget.averageAllBuildings': 'Moyenne (tous les bâtiments)',
  'widget.forecast': 'Estimé',
  'widget.targetEndDate': 'Date de fin cible',
  'widget.gradualDecrease': 'Diminution progressive',
  'widget.gradualIncrease': 'Augmentation progressive',
  'widget.target': 'Cible',
  'widget.targetPeriod': 'Période cible',
  'widget.targetValue': 'Valeur cible',
  'widget.targetStartDate': 'Date de début cible',
  'widget.decrease': 'Diminution',
  'widget.increase': 'Augmentation',
  'widget.percentagePer': '% par',
  'widget.decreaseUnit': 'Diminution unité',
  'widget.increaseUnit': 'Augmentation unité',
  'widget.trendLine': 'Ligne de tendance',
  'widget.extendPeriodToTarget': "Prolonger la période à l'heure cible",
  'widget.hour': 'Heure',
  'widget.apply': 'Appliquer',
  'widget.configurationError': 'Erreur de configuration du widget',
  'widget.tooltip': 'Info-bulle',
  'widget.tooltipText': "Texte d'info-bulle",
  'widget.centeredText': 'Texte centré',
  'widget.valuesAreExpressedIn': 'Les valeurs sont exprimées en',
  'widget.groupCalculationMethod': 'Méthode de calcul du groupe',
  'widget.copyToClipboard': 'Copier dans le presse-papiers',
  'widget.pasteFromClipboard': 'Coller du presse-papiers',
  'widget.copySettings': 'Copier les paramètres',
  'widget.pasteSettings': 'Coller les paramètres',
  'widget.absolute': 'Absolu',
  'widget.operator': 'Opérateur',
  'widget.successfullyEntered': 'entré(e) avec succès',
  'widget.updateInProgress': 'mise à jour en cours',
  'widget.successfullyUpdatedTo': 'mis à jour avec succès vers',
  'widget.successfullyDeleted': 'Successfully deleted',
  'widget.detail': 'Détail',
  'widget.addSite': 'Ajouter un site',
  'widget.addBuilding': 'Ajouter un bâtiment',
  'widget.addFloor': 'Ajouter un étage',
  'widget.addRoom': 'Ajouter une pièce',
  'widget.addEquipment': 'Ajouter un équipement',
  'widget.equip': 'Équipement',
  'widget.id': 'ID',
  'widget.remark': 'Remarque',
  'widget.varia': 'Varia',
  'widget.layout': 'Disposition',
  'widget.portrait': 'Portrait',
  'widget.landscape': 'Paysage',
  'widget.fields': 'Champs',
  'widget.addSingleProperty': 'Ajouter une seule propriété',
  'widget.addEntityDetails': "Ajouter les détails de l'entité",
  'widget.country': 'Pays',
  'widget.degreeDayReference': 'Référence degré-jour',
  'widget.singleLink': 'Lien unique',
  'widget.multipleLinks': 'Liens multiples',
  'widget.navigationType': 'Type de navigation',
  'widget.dashboards': 'Tableaux de bord',
  'widget.sections': 'Sections',
  'widget.masterDetail': 'Maître/Détail',
  'widget.showDetails': 'Afficher les détails',
  'widget.floatingDetails': 'Détails flottants',
  'widget.floatingList': 'Liste flottante',
  'widget.floatingGrid': 'Grille flottante',
  'widget.fixedList': 'Liste fixe',
  'widget.fixedGrid': 'Grille fixe',
  'widget.list': 'Liste',
  'widget.details': 'Détails',
  'widget.floating': 'Flottant',
  'widget.fixed': 'Fixé',
  'widget.entityValue': "Valeur d'entité",
  'widget.defaultExpanded': 'Développé par défaut',
  'widget.resolution': 'Resolution',
  'widget.comparatorUnit': 'Unité du comparateur',
  'widget.selectedDatapointTag': 'Balise point de données sélectionné',
  'widget.otherDatapointTag': 'Balise autre point de données',
  'widget.entityTag': "Balise d'entité",
  'widget.formula': 'Formule',
  'widget.formulaPreview': 'Aperçu de la formule',
  'widget.formulaEditor': 'Éditeur de formule',
  'widget.intervalCalculationMethod': "Méthode de calcul d'intervalle",
  'widget.tagValue': 'Valeur de balise',
  'widget.searchCity': 'Chercher une ville',
  'widget.yAxisMin': 'Axe Y min',
  'widget.yAxisMax': 'Axe Y max',
  'widget.linkedWidget': 'Widget lié',
  'widget.linkedDashboard': 'Tableau de bord lié',
  'widget.dashboardLink': 'Lien tableau de bord',
  'widget.addWidgetDatapoints': 'Ajouter des points de données de widget',
  'widget.linkedDatapoints': 'Points de données liés',
  'widget.groupDatapoints': 'Regrouper les points de données',
  'widget.linkedPointValue': 'Valeur du point cliqué',
  'widget.addClickedPointValue': 'Ajouter la valeur du point cliqué',
  'widget.splitPage': 'Affichage sur page divisée',
  'widget.close': 'Fermer',
  'widget.extraDatapoints': 'Points de données supplémentaires',
  'widget.addWidgetExtraDatapoints': 'Ajouter des points de données supplémentaires de widget',
  'widget.addWidgetComparators': 'Ajouter les comparateurs du widget',
  'widget.addWidgetMinComparators': 'Ajouter les comparateurs min du widget',
  'widget.addWidgetMaxComparators': 'Ajouter les comparateurs max du widget',
  'widget.linkedLinkDatapoints': 'Points de données supplémentaires liés',
  'widget.linkedComparators': 'Comparateurs liés',
  'widget.linkedMinComparators': 'Comparateurs min liés',
  'widget.linkedMaxComparators': 'Comparateurs max liés',
  'widget.formulaResult': 'Résultat de la formule',
  'widget.reload': 'Recharger',
}
