import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import { useCurrentCustomer } from 'services/store'

const QUERY_ALL_LOCKED_REPORTING_YEARS = gql`
  query ReportingYearsDatePickerLockedYears($companyId: ID!, $locked: Boolean) {
    allReportingYears(companyId: $companyId, locked: $locked) {
      year
      organisationId
      startDate
      endDate
    }
  }
`

export const useAllLockedReportinYears = () => {
  const customer = useCurrentCustomer()
  const { data, ...rest } = useQuery(QUERY_ALL_LOCKED_REPORTING_YEARS, {
    variables: { companyId: customer?.id, locked: true },
    skip: !customer?.id,
    fetchPolicy: 'network-only',
  })

  const lockedRanges = useMemo(
    () =>
      data?.allReportingYears?.map((lock) => {
        return {
          start: dayjs(lock.startDate),
          end: dayjs(lock.endDate),
        }
      }),
    [data]
  )

  return { data: lockedRanges, ...rest }
}
