import React, { useMemo } from 'react'

import { useOrganisations } from 'services/organisations'

import SelectField from '../../SelectField'

const OrganisationSelectField = ({
  onOptionsLoaded,
  isCore,
  options,
  showLegalEntity = false,
  ...props
}) => {
  const { data } = useOrganisations({ isCore })

  const orgOptions = useMemo(() => {
    const result = data?.map(({ id, name, legalEntity }) => ({
      value: id,
      label: showLegalEntity
        ? `${name}${legalEntity ? ` | ${legalEntity.abbreviation}` : ''}`
        : name,
    }))

    if (result && typeof options === 'function') {
      return options(result)
    }

    return result
  }, [data, options, showLegalEntity])

  return <SelectField {...props} options={orgOptions} />
}

export default OrganisationSelectField
