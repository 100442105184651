import { motion } from 'framer-motion'
import styled from 'styled-components'

import { WidgetEditor } from './styled.WidgetEditor'

const variants = {
  WidgetEditor,
}

const ButtonListStyled = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ variant }) => variants[variant]}
`
export default ButtonListStyled
