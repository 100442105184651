import React, { useMemo } from 'react'

import { useEntitiesByTypeIds } from 'services/entities'

import SelectField from '../../SelectField'

const EntitySelectField = ({ typeIds, siteId, ...props }) => {
  const { data } = useEntitiesByTypeIds(typeIds, { siteId })
  const options = useMemo(() => data?.map(({ id, name }) => ({ value: id, label: name })), [data])

  return <SelectField {...props} options={options} />
}

export default EntitySelectField
