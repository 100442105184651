import React, { useMemo } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { useFilters } from '../../Form/components/ValuePicker/hooks'
import { filterTree, flattenTree, getAllOpenItems } from '../EditableTree/utilities'
import { SearchableTreeStyled } from './styled'
import Tree from './Tree'

const SearchableTree = ({
  value,
  isOpen,
  selectionKeys = { label: 'title' },
  placeholder = 'Search To Select',
  idKey,
  ...props
}) => {
  const flattenedData = useMemo(() => (value ? flattenTree(value, idKey) : []), [value, idKey])
  const { onSearch, searchValue, data } = useFilters(flattenedData, null, null, selectionKeys.label)

  const { tree } = useMemo(() => filterTree(flattenedData, data, idKey), [
    data,
    flattenedData,
    idKey,
  ])

  const openItems = searchValue && getAllOpenItems({ children: tree.items }, 'root')

  return (
    <SearchableTreeStyled className="SearchableTree">
      <Input onChange={onSearch} placeholder={placeholder} prefix={<SearchOutlined />} allowClear />
      <Tree
        {...props}
        value={tree.items}
        isOpen={isOpen}
        selectionKeys={selectionKeys}
        openItems={openItems}
      />
    </SearchableTreeStyled>
  )
}

export default SearchableTree
