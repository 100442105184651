import React, { useState } from 'react'

import { AgGridReact } from '@ag-grid-community/react'

import Pagination from 'components/Dashboard/components/Table/Pagination'
import SearchField from 'components/Global/FormField/SearchField/SearchField'
import Loader from 'components/Global/Loader'
import { AgGridMaterial } from 'components/Layout/List/scenes/Table/styled'

const Table = ({
  node,
  value = [],
  onChange,
  onPaginate,
  children,
  loading,
  defaultColDef,
  headerHeight,
  height = '400px',
  gridRef,
  searchEnabled = false,
  ...props
}) => {
  const [searchText, setSearchText] = useState(null)
  const handleOnSearch = ({ value }) => {
    setSearchText(value)
  }

  return (
    <AgGridMaterial
      className="ag-theme-material"
      size="md"
      height={height}
      headerHeight={headerHeight}
    >
      <div className="AgGridMaterial__tools">
        {searchEnabled && <SearchField onSearch={handleOnSearch} />}
        {onPaginate && <Pagination onChange={onPaginate} />}
      </div>

      <AgGridReact
        ref={gridRef}
        rowData={value}
        quickFilterText={searchText}
        defaultColDef={{
          resizable: true,
          cellRenderer: 'info',
          flex: 1,
          filter: true,
          ...defaultColDef,
        }}
        rowHeight={40}
        animateRows={true}
        {...props}
      >
        {children}
      </AgGridReact>
      {loading && <Loader spinner={{ type: 'lineScale', size: 20 }} loading />}
    </AgGridMaterial>
  )
}

export default Table
