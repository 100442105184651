const treeifyHiearchyList = ({
  list,
  idKey = 'id',
  depthType,
  disabledTypes = [],
  disabledFunction,
  labelFunction,
  childrenKey = 'children',
  parentKey = 'parent',
  transformKeys = true,
}) => {
  const lookup = list.reduce((lookup, obj) => {
    lookup[obj[idKey]] = transformKeys
      ? {
          value: obj.id,
          label: labelFunction ? labelFunction(obj) : obj.name,
          type: obj.type,
          disabled: disabledFunction ? disabledFunction(obj) : disabledTypes.includes(obj.type),
        }
      : { ...obj }
    return lookup
  }, {})

  return list.reduce((treeList, obj) => {
    const parentId = obj.hierarchy ? obj.hierarchy[obj.hierarchy.length - 2] : obj[parentKey]
    if (!depthType || obj.type !== depthType) {
      if (parentId && lookup[parentId] !== undefined) {
        lookup[parentId][childrenKey] = lookup[parentId][childrenKey]
          ? lookup[parentId][childrenKey].concat(lookup[obj.id])
          : [lookup[obj.id]]
        lookup[parentId].disabled = false
      } else {
        treeList.push(lookup[obj.id])
      }
    }
    return treeList
  }, [])
}

export default treeifyHiearchyList
