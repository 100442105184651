import { Tag } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

const { CheckableTag } = Tag

const CheckableTagStyled = styled(CheckableTag)`
  margin: 0.5rem 0.5rem 0 0;
  font-size: 1rem;

  &.unchecked {
    color: ${({ theme, color }) => color || theme.color.main};
    border-color: ${({ theme, color }) => color || theme.color.main};

    &:hover {
      color: ${({ theme, color }) => color || theme.color.main};
    }
    &:active,
    &:focus {
      background-color: ${({ theme }) => transparentize(0.9, theme.color.main)};
    }
  }

  &.checked {
    background-color: ${({ theme, color }) => color || theme.color.main};
  }
`
export default CheckableTagStyled
