import React from 'react'

import { Tooltip } from 'antd'
import { injectIntl } from 'react-intl'

import { MenuActionButtonStyled } from 'components/Layout/MenuBar/components/MenuPanel/styled'

import Icon from '../../../Global/Icon'

const EditDashboardsButton = ({ intl }) => {
  return (
    <Tooltip title={intl.formatMessage({ id: 'menu.dashboards.edit' })}>
      <MenuActionButtonStyled className="ButtonStyled" to="/dashboard/management">
        <Icon icon={`far fa-gear`} />
      </MenuActionButtonStyled>
    </Tooltip>
  )
}

export default injectIntl(EditDashboardsButton)
