import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Label, Value } from 'components/Form/components/Field/styled'
import { RatioBarFieldStyled } from 'components/Form/fields/RatioBarField/styled'

export const EnergyCompositionFieldStyled = styled(Field)`
  width: 100%;

  > ${Value} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-radio-group {
    margin: 0 0 1rem auto;
  }

  ${RatioBarFieldStyled} {
    justify-content: flex-start;

    ${Label} {
      max-width: 66.5%;
      margin-left: 1.1rem;
      margin-right: 0;
      font-weight: 600;
    }
    ${Value} {
      margin: 0 1.2rem 0 auto;
    }
  }
`
