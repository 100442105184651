import React from 'react'

import { Input } from 'antd'
import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import { useCountryList } from 'services/companies'
import { coreConfig } from 'services/store'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'

import { LocationSearchFieldStyled } from './styled'

const { Search } = Input

const LocationSearchField = ({
  value,
  defaultValue = '',
  name,
  label,
  placeholder,
  validation,
  onBlur,
  viewRender,
  editRender,
  showRequired,
  disabled,
  rows,
  onChange,
  getDependencies,
  ...fieldProps
}) => {
  const { data: countryList } = useCountryList(false)
  const coreconfig = coreConfig()

  const handleOnSearch = (data, controllerOnChange) => {
    const request = require('request')
    const options = {
      method: 'GET',
      url: `${coreconfig.url}openweather/geo?q=${data}`,
      //url: `https://api.openweathermap.org:443/geo/1.0/direct?q=${data}&appid=3c07d5d80ae7a91bdf95f0a34d43f116`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + coreconfig.token,
      },
    }
    let countryId = null
    let obj = null
    request(options, function (error, response) {
      if (response.body !== '[]') {
        const resp = JSON.parse(response.body)
        if (resp.error) {
          getDependencies({ geoCoord: ``, geoCountry: '', tz: '' })
          return
        }
        obj = resp.geo[0]
        const timezone = resp.timezone
        if (obj?.name && obj?.lat && obj?.lon) {
          if (obj?.country) {
            countryId = countryList?.countries?.filter(
              (country) => country.alpha2 === obj.country
            )[0]?.id
          }
          controllerOnChange(`${obj?.name}`)
          getDependencies({
            geoCoord: `${obj.lat}, ${obj.lon}`,
            geoCountry: countryId || '',
            tz: timezone || '',
          })
        } else {
          getDependencies({ geoCoord: `` })
        }
      }
    })
  }

  return (
    <LocationSearchFieldStyled
      {...fieldProps}
      label={label}
      required={showRequired && validation?.required}
    >
      {({ errors, isEditMode, watch, loading }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        const error = get(errors, name)

        if (loading) {
          return <Skeleton width="200px" />
        }

        if (isEditMode) {
          const field = name ? (
            <Controller
              name={name}
              rules={validation}
              defaultValue={defaultValue}
              render={({ onChange: controllerOnChange, value: controllerValue }) => {
                const handleOnChange = (val) => {
                  controllerOnChange(val)
                  onChange && onChange(val)
                }

                return (
                  <Search
                    onChange={handleOnChange}
                    value={controllerValue}
                    placeholder={placeholder}
                    error={error}
                    onBlur={onBlur}
                    disabled={disabled}
                    rows={rows}
                    onSearch={(data) => handleOnSearch(data, controllerOnChange)}
                    style={{ height: '25px' }}
                  />
                )
              }}
            />
          ) : (
            <div>
              <Search
                onChange={onChange}
                value={value}
                error={error}
                onBlur={onBlur}
                disabled={disabled}
                rows={rows}
                onSearch={handleOnSearch}
                style={{ height: '30px' }}
              />
            </div>
          )

          const message = <FieldMessage message={error} label={label} name={name} />

          return editRender ? editRender({ field, message }) : [field, message]
        }
        return viewRender ? viewRender(fieldValue) : <span>{fieldValue}</span>
      }}
    </LocationSearchFieldStyled>
  )
}

export default LocationSearchField
