import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

export const DatapointListFieldStyled = styled(Field)`
  padding: 0.5rem;

  ${Value} {
    width: 100%;
  }
  > ${Value} {
    > .Field {
      padding: 0;
    }
  }
`
