import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useDatapointHover } from 'components/Global/FormField/Datapoints/functions'
import GhgDataSelectorContainer from 'components/Global/FormField/GhgDataSelector/GhgDataSelectorContainer'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { capitalizeString } from '../../../Dashboard/functions'
import { DatapointDetails } from '../Datapoints'
import DatapointSelector from '../DatapointSelector'
import DatapointGroupList from './DatapointGroupList'
import DatapointList from './DatapointList'
import EquipmentDatapoints from './EquipmentDatapoints'
import LinkedData from './LinkedData'
import DatapointGroupsStyled from './styled'

const selectors = {
  DatapointSelector,
  GhgDataSelector: GhgDataSelectorContainer,
}

const datapointsLabel = {
  DatapointSelector: 'widget.datapoints',
  GhgDataSelector: 'widget.data',
}

const selectDatapointsLabel = {
  DatapointSelector: 'widget.selectDatapoints',
  GhgDataSelector: 'widget.selectData',
}

const clearDatapointsLabel = {
  DatapointSelector: 'widget.clearAllDatapoints',
  GhgDataSelector: 'widget.clearData',
}

const DatapointGroups = ({
  dataKey = 'datapoints',
  bottomDrawer = 'DatapointSelector',
  subPath,
  singleDatapoint,
  fixedUnit,
  buttons = true,
  canReselect,
  onChange,
  onClick,
  intl,
  linkedMode,
  label,
}) => {
  const {
    state: { editModeContext, elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
    action,
  } = useDashboard()

  const { hoveredElement, detail, onDatapointHover } = useDatapointHover()

  const datapoints =
    get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []
  const selectedDatapoints = (datapoints === 'main'
    ? get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}datapoints`) || []
    : datapoints || []
  ).map((dp, index) => ({ ...dp, itemIndex: index }))

  const datapointGroups =
    get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}Groups`) || []

  const handleDatapointClick = (e, { id, name, itemIndex, type, typeIndex }) => {
    e.stopPropagation()
    if (onClick) {
      action({
        type: 'ON_DATAPOINT_SELECT',
        payload: {
          value: type ? typeIndex : itemIndex,
          dataKey: type ? `${dataKey}${capitalizeString(type)}` : dataKey,
        },
      })
      onClick(e, { id, name, index: itemIndex, typeIndex: type && typeIndex, type })
    }
  }

  const onRemoveDatapoint = (e, { typeIndex, itemIndex, ...value }) => {
    e.stopPropagation()
    const isGroupDatapointRemove = typeof typeIndex === 'number'
    if (isGroupDatapointRemove) {
      const newGroupDatapoints = datapointGroups[typeIndex].datapoints.filter(
        (item) => item !== value.id
      )
      return onChange(
        datapointGroups.map((group, index) =>
          index === typeIndex
            ? {
                ...group,
                datapoints: newGroupDatapoints,
              }
            : group
        ),
        `${dataKey}Groups`
      )
    }
    const newDatapointArray = selectedDatapoints.filter((dp, index) => itemIndex !== index)

    onChange(newDatapointArray, dataKey)
  }

  const onRemoveAllDatapoints = () => {
    onChange([], `${dataKey}Groups`)
    onChange([], dataKey)
  }

  const onOpenBottomDrawer = () => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          key: `${bottomDrawer}-${dataKey}-groups`,
          isOpen: true,
          width: 'full',
          component: selectors[bottomDrawer],
          componentProps: {
            dataKey,
            subPath,
            singleDatapoint,
            fixedUnit,
            groupDataKey: `${dataKey}Groups`,
            canReselect,
            intl,
          },
        },
      },
    })
  }

  const datapointDetail =
    detail?.__typename === 'Datapoint' ? detail : { name: detail?.name, namedReferences: {} }

  const groups = datapointGroups.reduce(
    (groups, group) => {
      const groupDatapoints = group[dataKey]?.reduce((groupDatapoints, datapoint) => {
        const datapointIndex = groups.remainingDatapoints.findIndex(
          (dp) => dp?.id === datapoint && dp.groupId === group.id
        )

        if (datapointIndex !== -1) {
          const datapointData = groups.remainingDatapoints[datapointIndex]

          groups.remainingDatapoints = groups.remainingDatapoints.filter(
            (item, index) => index !== datapointIndex
          )
          groupDatapoints.push(datapointData)
        }
        return groupDatapoints
      }, [])
      groups.groups.push({ ...group, datapoints: groupDatapoints })
      return groups
    },
    { groups: [], remainingDatapoints: [...selectedDatapoints] }
  )

  return editModeContext === 'Matrix' && dataKey === 'datapoints' ? (
    <EquipmentDatapoints
      equipments={elementInEditMode.preview.equipments}
      matrixDetailGraph={elementInEditMode.matrixDetailGraph}
      equipmentTemplate={elementInEditMode.preview.equipmentTemplate}
      detailTemplate={elementInEditMode.preview.detailTemplate}
      onChange={onChange}
    />
  ) : (
    <DatapointGroupsStyled className="DatapointGroups">
      <DatapointDetails hoveredElement={hoveredElement} detail={datapointDetail} />
      <DatapointGroupList
        label={label}
        groups={groups.groups}
        datapoints={datapoints}
        datapointGroups={datapointGroups}
        dataKey={dataKey}
        selectedDatapointMap={elementInEditMode?.selectedDatapointMap}
        selectedDatapoints={selectedDatapoints}
        onDatapointClick={handleDatapointClick}
        onDatapointHover={onDatapointHover}
        onRemoveDatapoint={onRemoveDatapoint}
        onClick={onClick}
        onChange={onChange}
        action={action}
        buttons={buttons}
      />
      <DatapointList
        label={datapointsLabel[bottomDrawer]}
        datapoints={groups.remainingDatapoints}
        selectedDatapoints={selectedDatapoints}
        selectedDatapointMap={elementInEditMode?.selectedDatapointMap}
        onDatapointHover={onDatapointHover}
        onRemoveDatapoint={onRemoveDatapoint}
        onDatapointClick={handleDatapointClick}
        onRemoveAllDatapoints={onRemoveAllDatapoints}
        onOpenBottomDrawer={onOpenBottomDrawer}
        selectDatapointsLabel={
          singleDatapoint ? 'widget.selectDatapoint' : selectDatapointsLabel[bottomDrawer]
        }
        clearDatapointsLabel={
          singleDatapoint ? 'widget.clearDatapoint' : clearDatapointsLabel[bottomDrawer]
        }
      />
      {linkedMode && (
        <LinkedData
          label="widget.linkedWidget"
          elementInEditMode={elementInEditMode?.preview}
          selectedDatapointMap={elementInEditMode?.selectedDatapointMap}
          dataKey={dataKey}
          subPath={subPath}
          onChange={onChange}
          onClick={onClick}
          action={action}
          onDatapointClick={handleDatapointClick}
        />
      )}
    </DatapointGroupsStyled>
  )
}

export default injectIntl(DatapointGroups)
