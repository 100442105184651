import { css } from 'styled-components'

export const Navigation = css`
  transition: box-shadow 0.2s ease, color 0.2s ease, border-color 0.2s ease, background 0.2s ease;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  .Icon {
    margin: 0 0.5rem;
  }
  ${({ isActive }) =>
    isActive
      ? css`
          background: ${({ theme }) => theme.color.main};
          border: 1px solid ${({ theme }) => theme.color.main};
          color: ${({ theme }) => theme.color.white};
          &:hover {
            box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
            background: ${({ theme }) => theme.color.white};
            color: ${({ theme }) => theme.color.main};
          }
        `
      : css`
          border: 1px solid ${({ theme }) => theme.color.main};
          color: ${({ theme }) => theme.color.main};
          &:hover {
            box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
            border-color: ${({ theme }) => theme.color.main};
          }
        `}
`
