import React, { useCallback } from 'react'

import { Popconfirm, Switch as AntSwtich } from 'antd'
import { PropTypes } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Error } from './styled'

const Switch = ({
  confirm,
  confirmText,
  okText,
  cancelText,
  onChange,
  checked,
  disabled,
  error,
  ...props
}) => {
  const handleConfirm = useCallback(() => {
    onChange(!checked)
  }, [checked, onChange])

  const renderError = error ? <Error>{error}</Error> : null

  if (!confirm || disabled) {
    return (
      <div>
        <AntSwtich checked={checked} disabled={disabled} onChange={onChange} {...props} />
        {renderError}
      </div>
    )
  }

  return (
    <div>
      <Popconfirm
        title={confirmText || <FormattedMessage id="confirmMessage" />}
        onConfirm={handleConfirm}
        okText={okText || <FormattedMessage id="confirm" />}
        cancelText={<FormattedMessage id="cancel" />}
        placement="bottomRight"
      >
        <AntSwtich checked={checked} {...props} />
      </Popconfirm>
      {renderError}
    </div>
  )
}

Switch.propTypes = {
  confirm: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  confirmText: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onChange: PropTypes.func,
}

Switch.defaultProps = {
  confirm: false,
}

export default Switch
