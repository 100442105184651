import React from 'react'

import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'

import { useDatapointHover } from 'components/Global/FormField/Datapoints/functions'
import { DatapointDetails } from 'components/Global/FormField/Datapoints/index'

import { UNIT_QUERY } from '../../../../services/units'
import Button from '../../Button'
import DatapointSelector from '../DatapointSelector'
import EntitySelector from '../EntitySelector'
import GhgDataSelectorContainer from '../GhgDataSelector/GhgDataSelectorContainer'
import ValueTypeSelector from '../ValueTypeSelector'
import { DatapointListStyled } from './styled'

const selectors = {
  DatapointSelector,
  GhgDataSelector: GhgDataSelectorContainer,
  EntitySelector,
}

const selectDatapointsLabel = {
  DatapointSelector: 'widget.selectDatapoints',
  GhgDataSelector: 'widget.selectData',
  EntitySelector: 'widget.selectData',
}

const clearDatapointsLabel = {
  DatapointSelector: 'widget.clearAllDatapoints',
  GhgDataSelector: 'widget.clearData',
  EntitySelector: 'widget.clearData',
}
const DatapointList = ({
  datapoints,
  bottomDrawer = 'DatapointSelector',
  dataKey,
  groupIndex,
  selectedItemIndex,
  selectedDatapointMap,
  type,
  subPath,
  fixedUnit,
  method,
  hideMethod,
  tags,
  canReselect,
  tagFilter,
  singleDatapoint,
  navigationLabelOption,
  customMarkerOption,
  onChange,
  onDatapointClick,
  onRemoveDatapoint,
  action,
  buttons = true,
  elementInEditMode,
  intl,
}) => {
  const handleRemoveDatapoint = (e, value) => {
    e.stopPropagation()
    if (onRemoveDatapoint) {
      return onRemoveDatapoint(e, value)
    }
    const newDatapointArray = selectedDatapoints.filter((dp, index) => index !== value.itemIndex)
    onChange(newDatapointArray, dataKey)
  }
  const onRemoveAllDatapoints = () => {
    onChange([], dataKey)
  }

  const onAddNavigationLabel = () => {
    const datapoints = selectedDatapoints.concat({
      id: uniqid(),
      label: intl.formatMessage({ id: 'widget.navigationLabel' }),
      type: 'NavigationLabel',
    })
    onChange(datapoints, dataKey)
  }
  const onAddCustomMarker = () => {
    const datapoints = selectedDatapoints.concat({
      id: uniqid(),
      label: intl.formatMessage({ id: 'widget.customMarker' }),
      geoCoord: '50.8503,4.3517',
      icon: 'fas fa-map-marker-alt',
    })
    onChange(datapoints, dataKey)
  }

  const onBottomDrawerSave = () => {
    onChange([], 'entities')
  }

  const onOpenBottomDrawer = () => {
    return action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: true,
          width: 'full',
          component: selectors[bottomDrawer],
          key: `${bottomDrawer}-${dataKey}`,
          componentProps: {
            dataKey,
            subPath,
            singleDatapoint,
            fixedUnit,
            intl,
            tags,
            canReselect,
            tagFilter,
            onSave: onBottomDrawerSave,
          },
        },
      },
    })
  }
  const { hoveredElement, detail, onDatapointHover } = useDatapointHover({ delay: 5000 })

  const selectedDatapoints =
    datapoints ||
    (
      get(elementInEditMode, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []
    ).map((dp, index) => ({ ...dp, itemIndex: index }))

  const datapointsUnit = selectedDatapoints.length ? selectedDatapoints[0].unit : null
  const { data: unitDetails } = useQuery(UNIT_QUERY, {
    variables: {
      name: datapointsUnit,
    },
    skip: !datapointsUnit,
  })

  if (selectedDatapoints === 'main') {
    return null
  }

  const selectedDatapointMapIndex = selectedDatapointMap?.[dataKey]

  return (
    <DatapointListStyled className="DatapointList">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      {selectedDatapoints.map((datapoint) => {
        const { id, groupId, itemIndex, name, label = 'unknown', color } = datapoint || {}
        return (
          <div
            key={`${id}${groupId || ''}-${itemIndex}`}
            onMouseEnter={(e) => onDatapointHover(e, { id, name })}
            onMouseLeave={(e) => onDatapointHover(e, null)}
          >
            <Button
              label={name || label}
              variant="tag"
              onIconClick={(e) => {
                onDatapointHover(e, null)
                handleRemoveDatapoint(e, { id, name, typeIndex: groupIndex, type, itemIndex })
              }}
              icon={/*onRemoveDatapoint && */ 'fas fa-xmark'}
              color={color}
              isActive={(selectedItemIndex ?? selectedDatapointMapIndex) === itemIndex}
              onClick={(e) =>
                onDatapointClick(e, { id, name, itemIndex, type, typeIndex: groupIndex })
              }
            />
          </div>
        )
      })}
      {buttons && selectedDatapoints !== 'main' && (
        <div className="Datapoints__buttons">
          <Button
            className="SmallButton"
            label={intl.formatMessage({
              id: singleDatapoint ? 'widget.selectDatapoint' : selectDatapointsLabel[bottomDrawer],
            })}
            onClick={onOpenBottomDrawer}
            variant="smallButton"
          />
          {selectedDatapoints.length > 0 && (
            <Button
              label={intl.formatMessage({
                id: singleDatapoint ? 'widget.clearDatapoint' : clearDatapointsLabel[bottomDrawer],
              })}
              onClick={onRemoveAllDatapoints}
              variant="smallButton"
            />
          )}
          {navigationLabelOption && (
            <Button
              label={intl.formatMessage({ id: 'widget.addNavigationLabel' })}
              onClick={onAddNavigationLabel}
              variant="smallButton"
            />
          )}
          {customMarkerOption && (
            <Button
              label={intl.formatMessage({ id: 'widget.addCustomMarker' })}
              onClick={onAddCustomMarker}
              variant="smallButton"
            />
          )}
        </div>
      )}
      {!hideMethod && selectedDatapoints.length > 1 && (
        <ValueTypeSelector
          value={get(elementInEditMode, `${subPath ? `${subPath}.` : ''}${method || 'method'}`)}
          componentName="ValuePicker"
          label={{ formatted: 'widget.method' }}
          dataKey={method}
          datapointsDataKey={dataKey}
          subPath={subPath}
          onChange={({ value }) => onChange(value, method)}
          difference={unitDetails?.unit.difference}
        />
      )}
    </DatapointListStyled>
  )
}

export default injectIntl(DatapointList)
