import { buttonSizes } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.file',
    category: 'widget.others',
    keywords: ['indicator', 'status', 'kpi'],
    img: '/resources/images/widgets/file.png',
    info: {
      'fr-FR':
        '<h2>Fichier</h2>\n' +
        '<p>Le widget de fichier affiche toutes les valeurs et fichiers possibles pour certaines entités et points de données.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Afficher le séparateur</strong> Ajouter une ligne horizontale sous l'en-tête</li>\n" +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de l'en-tête</li>\n" +
        "<li><strong>Couleur de la police</strong> Couleur de la police de l'en-tête</li>\n" +
        '<li><strong>Taille</strong> Sélectionnez la taille du widget</li>\n' +
        '</ul>\n' +
        '<h4>Données</h4>\n' +
        '<ul>\n' +
        "<li><strong>Entités</strong> Sélectionnez l'entité pour la ligne</li>\n" +
        '<li><strong>Image</strong>Sélectionnez les images à afficher</li>\n' +
        '<li><strong>Fichier</strong>Sélectionnez le(s) fichier(s) à afficher</li>\n' +
        '</ul>',
      'en-US':
        '<h2>File</h2>\n' +
        '<p>The file widget displays all possible values and files for certain entities and datapoints.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Add a horizontal line under the header</li>\n' +
        "<li><strong>Background Color</strong> Header's background color</li>\n" +
        "<li><strong>Font Color</strong> Header's font color</li>\n" +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '</ul>\n' +
        '<h4>Data</h4>\n' +
        '<ul>\n' +
        '<li><strong>Entities</strong> Select entity for line</li>\n' +
        '<li><strong>Image</strong>Select image(s) to display</li>\n' +
        '<li><strong>File</strong>Select file(s) to display</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Bestand</h2>\n' +
        '<p>De bestandswidget toont alle mogelijke waarden en bestanden voor bepaalde entiteiten en datapunten.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Widgets titel</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Voeg een horizontale lijn toe onder de kop</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur kop</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van kop</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '</ul>\n' +
        '<h4>Gegevens</h4>\n' +
        '<ul>\n' +
        '<li><strong>Entiteiten</strong> Selecteer entiteit voor regel</li>\n' +
        '<li><strong>Afbeelding</strong>Selecteer afbeelding(en) om weer te geven</li>\n' +
        '<li><strong>Bestand</strong>Selecteer bestand(en) om weer te geven</li>\n' +
        '</ul>',
    },
    default: {
      type: 'File',
      title: 'widget.file',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      url: null,
      datapoints: [],
      method: 'sum',
      showUnit: true,
      hideValue: false,
      captions: {},
    },
    preview: {
      value: 20,
      unit: '°C',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      showUnit: true,
      hideValue: false,
      showDescription: false,
      showDiffArrow: true,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDivider' },
        col: 'col2',
        dataKey: 'line',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.data',
        componentName: 'Entities',
        label: { formatted: 'widget.entities' },
        componentProps: {
          dataKey: 'entities',
          singleDatapoint: true,
        },
      },
      {
        category: 'widget.data',
        componentName: 'EntityFilePicker',
        componentProps: {},
        dataKey: 'files',
        dependencies: [
          {
            dependentOn: 'widget.entities',
            dependencyType: 'hasValue',
            dependencyResolver: 'show',
          },
        ],
      },
    ],
  },
}

export default config
