import React from 'react'

import { Input } from 'antd'
import { get } from 'lodash'
import { Controller } from 'react-hook-form'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import TextInput from 'components/Form/components/TextInput'
import { PasswordFieldStyled, TextFieldStyled } from 'components/Form/fields/TextField/styled'

const TextField = ({
  value,
  defaultValue,
  name,
  label,
  placeholder,
  validation,
  onBlur,
  viewRender,
  editRender,
  showRequired,
  disabled,
  rows,
  onChange,
  align = 'right',
  ...fieldProps
}) => {
  if (fieldProps.type !== 'password')
    return (
      <TextFieldStyled
        {...fieldProps}
        align={align}
        label={label}
        required={showRequired && validation?.required}
      >
        {({ errors, isEditMode, watch, loading }) => {
          const fieldValue = value === undefined && name !== undefined ? watch(name) : value
          const error = get(errors, name)

          if (loading) {
            return <Skeleton width="200px" />
          }

          if (isEditMode) {
            const field = name ? (
              <Controller
                name={name}
                rules={validation}
                defaultValue={defaultValue}
                render={({ onChange: controllerOnChange, value: controllerValue }) => {
                  const handleOnChange = (val) => {
                    controllerOnChange(val)
                    onChange && onChange(val)
                  }

                  const handleOnBlur = (val) => {
                    controllerOnChange(val)
                    onChange && onChange(val)
                    onBlur && onBlur(val)
                  }

                  return (
                    <TextInput
                      onChange={handleOnChange}
                      value={controllerValue}
                      placeholder={placeholder}
                      error={error}
                      onBlur={handleOnBlur}
                      disabled={disabled}
                      rows={rows}
                    />
                  )
                }}
              />
            ) : (
              <div>
                <TextInput
                  onChange={onChange}
                  value={value}
                  error={error}
                  onBlur={onBlur}
                  disabled={disabled}
                  rows={rows}
                />
              </div>
            )

            const message = <FieldMessage message={error} label={label} name={name} />

            return editRender ? editRender({ field, message }) : [field, message]
          }
          return viewRender ? viewRender(fieldValue) : <span>{fieldValue}</span>
        }}
      </TextFieldStyled>
    )
  else
    return (
      <PasswordFieldStyled
        {...fieldProps}
        label={label}
        required={showRequired && validation?.required}
      >
        {({ errors, isEditMode, watch, loading }) => {
          const fieldValue = value === undefined && name !== undefined ? watch(name) : value
          const error = get(errors, name)

          if (loading) {
            return <Skeleton width="200px" />
          }

          if (isEditMode) {
            const field = name ? (
              <Controller
                name={name}
                rules={validation}
                defaultValue={defaultValue}
                render={({ onChange: controllerOnChange, value: controllerValue }) => {
                  const handleOnChange = (val) => {
                    controllerOnChange(val)
                    onChange && onChange(val)
                  }

                  return (
                    <Input.Password
                      onChange={handleOnChange}
                      value={controllerValue}
                      placeholder="input password"
                      style={{ textOverflow: 'unset !important' }}
                    />
                  )
                }}
              />
            ) : (
              <div>
                <TextInput
                  onChange={onChange}
                  value={value}
                  error={error}
                  onBlur={onBlur}
                  disabled={disabled}
                  rows={rows}
                />
              </div>
            )

            const message = <FieldMessage message={error} label={label} name={name} />

            return editRender ? editRender({ field, message }) : [field, message]
          }
          return viewRender ? viewRender(fieldValue) : <span>{'********'}</span>
        }}
      </PasswordFieldStyled>
    )
}

export default TextField
