import React from 'react'

import { Controller } from 'react-hook-form'

import fileUrl from 'util/fileUrl'

import Field from 'components/Form/components/Field'
import Icon, { FileIcon } from 'components/Global/Icon'

import { FileFieldButtons, FileFieldStyled } from './styled'

const FileField = ({ value, name, width, ...fieldProps }) => {
  return (
    <Field {...fieldProps} name={name}>
      {({ control, watch, isEditMode }) => {
        const fieldValue = value || watch(name)
        return (
          <Controller
            control={control}
            name={`${name}.deleted`}
            render={({ onChange, value: controllerValue }) => {
              const handleToggleDelete = () => {
                onChange(!controllerValue)
              }
              if (isEditMode) {
                return (
                  <FileFieldStyled
                    as="span"
                    width={width}
                    deleted={fieldValue.deleted}
                    onClick={handleToggleDelete}
                  >
                    <FileIcon mimeType={fieldValue.file.mimetype} />
                    <span>{fieldValue.file.name}</span>
                    <FileFieldButtons>
                      <Icon icon={fieldValue.deleted ? 'lnr lnr-undo' : 'lnr lnr-trash'} />
                    </FileFieldButtons>
                  </FileFieldStyled>
                )
              }

              return (
                <FileFieldStyled
                  key={fieldValue.file.id}
                  href={fileUrl(fieldValue.file.key)}
                  download
                  target="_blank"
                >
                  <FileIcon mimeType={fieldValue.file.mimetype} />
                  <span>{fieldValue.file.name}</span>
                </FileFieldStyled>
              )
            }}
          />
        )
      }}
    </Field>
  )
}

export default FileField
