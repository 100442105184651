import { darken, transparentize } from 'polished'
import { css } from 'styled-components'

export const mainButton = css`
  font-size: 1rem;
  color: ${({ theme, isPrimary, disabled }) =>
    isPrimary && !disabled ? theme.color.white : theme.color.softText};
  background: ${({ theme, isPrimary, disabled }) =>
    !disabled && isPrimary ? theme.color.action : theme.color.softerStroke};
  border: 1px solid
    ${({ theme, isPrimary, disabled }) =>
      !disabled && (isPrimary ? theme.color.action : theme.color.softText)};
  border-radius: 0.5rem;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  max-width: fit-content;
  box-shadow: ${({ theme, isPrimary, disabled }) =>
    isPrimary && !disabled && theme.shadowElevation3(theme.darkMode)};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  &:hover {
    background: ${({ theme, isPrimary, disabled }) =>
      !disabled &&
      (isPrimary ? transparentize(0.9, theme.color.action) : theme.color.softerStroke)};
    border: 1px solid
      ${({ theme, disabled }) => (disabled ? theme.color.mainText : theme.color.action)};
    color: ${({ theme, isPrimary, disabled }) =>
      !disabled && (isPrimary ? theme.color.action : theme.color.mainText)};
  }
  &:focus {
    outline: 5px auto
      ${({ theme, isPrimary, disabled }) =>
        darken(0.2, !disabled && isPrimary ? theme.color.action : theme.color.softerStroke)};
  }
  &:last-of-type {
    margin-right: 0;
  }
  span {
    white-space: nowrap;
  }
`
