import React from 'react'

import { ContentFlex, ContentScrollContainer } from 'components/Layout/Content'

import { ContentBody, ContentHeaderStyled, ContentWrapper, IconContainer, Title } from './styled'

const ContentPanel = ({
  headerContent,
  title,
  buttons,
  icon,
  fixedContent,
  className,
  children,
  topOffset,
}) => {
  return (
    <ContentScrollContainer topOffset={topOffset}>
      <ContentWrapper className={className}>
        <ContentHeaderStyled>
          {(title || icon) && (
            <ContentFlex padding={false}>
              {icon && <IconContainer>{icon}</IconContainer>}
              {title && <Title>{title}</Title>}
            </ContentFlex>
          )}
          {headerContent}
          {buttons && <ContentFlex padding={false}>{buttons}</ContentFlex>}
        </ContentHeaderStyled>
        <ContentBody>{children}</ContentBody>
        {fixedContent}
      </ContentWrapper>
    </ContentScrollContainer>
  )
}

export default ContentPanel
