import React from 'react'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import Button from 'components/Global/Button'

const DatapointSelectorButtons = ({ intl, onCancel, onSave }) => {
  const { action } = useDashboard()
  const handleCancel = () => {
    //setSelectedEntity(null)
    if (action) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: false,
          dataKey: 'isOpen',
        },
      })
    }
    onCancel && onCancel()
  }

  const handleSave = () => {
    //setSelectedEntity(null)
    if (action) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: {
            isOpen: false,
            width: 'full',
          },
        },
      })
    }
    onSave && onSave()
  }

  return (
    <div className="DatapointSelector__buttons">
      <Button
        label={intl.formatMessage({ id: 'widget.cancel' })}
        variant="mainButton"
        onClick={handleCancel}
      />
      <Button
        label={intl.formatMessage({ id: 'widget.ok' })}
        variant="mainButton"
        isPrimary
        onClick={handleSave}
      />
    </div>
  )
}

export default DatapointSelectorButtons
