import React from 'react'

const ActionConfirmationValue = ({ value, unit }) => (
  <span className="value">
    <strong>{value}</strong>
    {unit}
  </span>
)

export default ActionConfirmationValue
