import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import DatapointTags from 'components/Dashboard/components/Tags/DatapointTags'
import OptionPicker from 'components/Form/components/OptionPicker'
import ValuePicker from 'components/Form/components/ValuePicker/ValuePicker'
import Component from 'components/Global/Component/styled'
import { DatapointDetails } from 'components/Global/FormField/Datapoints'
import { useDatapointHover } from 'components/Global/FormField/Datapoints/functions'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import Icon from '../../Icon'

const EntityFilter = ({
  datapoints,
  filteredDatapoints,
  selectedDatapoints = [],
  dataSources,
  dataSource,
  searchValue,
  onSearch,
  onSelectDataSource,
  onSelectEntity,
  onSelectTags,
  onValueUpdate,
  optionRenderer,
  tagFilter,
  preview,
  selectedEntity,
  loading,
  intl,
  canReselect,
  fixedUnit,
  fromEntityTypes = ['equip', 'point'],
  entityType = 'datapoint',
  numberValuesOnly = false,
}) => {
  const { hoveredElement, detail, onDatapointHover } = useDatapointHover()

  const handleSelectAll = () => {
    const { values, added } = filteredDatapoints.reduce(
      ({ values, added }, { tags, ...datapoint }) => {
        if (canReselect || !selectedDatapoints.some(({ id }) => id === datapoint.id)) {
          values.push(datapoint)
          added.push(datapoint)
        }
        return { values, added }
      },
      { values: [...selectedDatapoints], added: [] }
    )
    onValueUpdate(values, added)
  }

  const preventSelectAll =
    fixedUnit &&
    filteredDatapoints.length &&
    filteredDatapoints.some(({ unit }) => unit !== filteredDatapoints[0].unit)

  return (
    <div className="DatapointSelector__editor">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      <div>
        <FormFieldLabel>{intl.formatMessage({ id: 'widget.entities' })}</FormFieldLabel>
        {dataSources && (
          <ValuePicker
            options={dataSources}
            placeholder={intl.formatMessage({ id: 'widget.dataSource' })}
            value={dataSource?.id}
            selectionKeys={{ label: 'name', value: 'id' }}
            onChange={onSelectDataSource}
          />
        )}
        <Component
          as={dataSource?.treeComponent}
          onChange={onSelectEntity}
          onSelectScope={onSelectDataSource}
          selectedOrganisation={preview?.organisationId}
          selectedEntity={selectedEntity}
          dataSource={dataSource?.id}
        />
      </div>
      <div>
        <FormFieldLabel>{intl.formatMessage({ id: 'widget.tagsAndMarkers' })}</FormFieldLabel>
        <DatapointTags
          fromEntityTypes={fromEntityTypes}
          node={selectedEntity}
          onChange={onSelectTags}
          datapoints={datapoints}
          clearChecker={selectedEntity?.key}
          tagFilter={tagFilter}
          numberValuesOnly={numberValuesOnly}
        />
      </div>
      <div>
        <FormFieldLabel>
          {intl.formatMessage({ id: `widget.${entityType}` })} ({datapoints?.length})
        </FormFieldLabel>
        <div className="DatapointSelector__search">
          <Input
            onChange={onSearch}
            value={searchValue}
            prefix={<SearchOutlined />}
            placeholder="Search to Select"
          />
          {!preventSelectAll && <Icon icon="fas fa-check-double" onClick={handleSelectAll} />}
        </div>
        <OptionPicker
          options={filteredDatapoints}
          value={selectedDatapoints}
          selectionKeys={{ label: 'name', value: 'id' }}
          isMultiSelect
          variant="Tags"
          onHover={onDatapointHover}
          loading={loading}
          returnAsArray
          renderFunction={optionRenderer}
          onChange={onValueUpdate}
          canReselect={canReselect}
        />
      </div>
    </div>
  )
}

export default EntityFilter
