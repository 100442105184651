export default {
  'widget.insufficientRights': 'Je hebt onvoldoende rechten om deze widget te raadplegen',
  'widget.noDataForDataPoint': 'Geen data gevonden voor datapunt(en)',
  'widget.noDatapointsSelected': 'Geen datapunt(en) geselecteerd',
  'widget.noInternetConnection': 'Geen internet verbinding',
  'widget.configureYourWidget': 'Configureer je widget',
  'widget.selectYourWidget': 'Kies je widget',
  'widget.selectDatapoint': 'Kies een nieuw datapunt',
  'widget.selectDatapoints': 'Kies nieuwe datapunten',
  'widget.selectedDatapoint': 'Geselecteerd datapunt',
  'widget.selectedDatapoints': 'Geselecteerde datapunten',
  'widget.selectYourDatapoints': 'Kies je datapunten',
  'widget.selectDatapointsForThisWidget': 'Kies je datapunten voor deze widget',
  'widget.label': 'Label',
  'widget.icon': 'Pictogram',
  'widget.icons': 'Pictogrammen',
  'widget.size': 'Maat',
  'widget.link': 'Link',
  'widget.dashboard': 'Dashboard',
  'widget.section': 'Sectie',
  'widget.url': 'Url',
  'widget.color': 'Kleur',
  'widget.colors': 'Kleuren',
  'widget.colorsPercentage': 'Kleuren (%)',
  'widget.method': 'Methode',
  'widget.calculationMethod': 'Rekenmethode',
  'widget.calculationMethodMin': 'Rekenmethode min',
  'widget.calculationMethodMax': 'Rekenmethode max',
  'widget.difference': 'Verschil',
  'widget.sum': 'Som',
  'widget.mean': 'Gemiddelde',
  'widget.min': 'Min',
  'widget.max': 'Max',
  'widget.chart': 'Grafiek',
  'widget.datapoint': 'Datapunt',
  'widget.lines': 'Lijnen',
  'widget.datapoints': 'Datapunten',
  'widget.getDataPointsForEntity': 'Zoek datapunten op voor entiteit:',
  'widget.search': 'Zoeken...',
  'widget.entities': 'Entiteiten',
  'widget.selectedEntity': 'Geselecteerde entiteit',
  'widget.tagsAndMarkers': 'Tags & markers',
  'widget.noData': 'Geen gegevens',
  'widget.ok': 'Ok',
  'widget.back': 'Terug',
  'widget.next': 'Volgende',
  'widget.showUnit': 'Toon eenheid',
  'widget.hideValue': 'Verberg waarde',
  'widget.caption': 'Bijschrift',
  'widget.captions': 'Bijschriften',
  'widget.okValue': 'OK waarde',
  'widget.warning': 'Waarschuwing',
  'widget.error': 'Fout',
  'widget.inProgress': 'Bezig',
  'widget.value': 'Waarde',
  'widget.type': 'Type',
  'widget.inactive': 'Inactief',
  'widget.yearOverview': 'Jaaroverzicht',
  'widget.kpiWidget': 'KPI',
  'widget.navigation': 'Navigatie',
  'widget.lastValue': 'Laatste waarde',
  'widget.lastValueDateTime': 'Laatste waarde Datum Tijd',
  'widget.expectedDateTime': 'Verwachte Datum Tijd',
  'widget.timeOverdue': 'Tijd te laat',
  'widget.consumption': 'Verbruik',
  'widget.gauge': 'Meter',
  'widget.absoluteGauge': 'Absolute Meter',
  'widget.relativeGauge': 'Relatieve Meter',
  'widget.deviation': 'Max afwijking in %',
  'widget.circleGauge': 'Cirkelmeter',
  'widget.comparisonPieChart': 'Pie Chart',
  'widget.referenceValue': 'Referentiewaarde',
  'widget.reference': 'Referentie',
  'widget.unit': 'Eenheid',
  'widget.timeUnit': 'Tijdseenheid',
  'widget.time': 'Tijd',
  'widget.defaultStartTime': 'Standaard starttijd',
  'widget.seconds': 'Seconden',
  'widget.minutes': 'Minuten',
  'widget.hours': 'Uur',
  'widget.day': 'Dag',
  'widget.days': 'Dagen',
  'widget.week': 'Week',
  'widget.weeks': 'Weken',
  'widget.month': 'Maand',
  'widget.months': 'Maanden',
  'widget.year': 'Jaar',
  'widget.years': 'Jaar',
  'widget.granularity': 'Granulariteit',
  'widget.showTotals': 'Totalen weergeven',
  'widget.hideRawData': 'Onbewerkte gegevens beschikbaar',
  'widget.rawData': 'Onbewerkte gegevens',
  'widget.showRawDataByDefault': 'Onbewerkte gegevens standaard tonen',
  'widget.datapointTotals': 'Datapunt totalen',
  'widget.widgetConfiguration': 'Datapunt totalen',
  'widget.comparisonPoint': 'Vergelijkingspunt',
  'widget.percentage': 'Percentage',
  'widget.range': 'Bereik',
  'widget.minMaxValue': 'Min/max waarde',
  'widget.1day': '1 dag',
  'widget.cancel': 'Annuleren',
  'widget.save': 'Opslaan',
  'widget.saveWidget': 'Bewaar widget',
  'widget.deltaValue': 'Delta waarde',
  'widget.data': 'Gegevens',
  'widget.opacity': 'Transparantie',
  'widget.none': 'Geen',
  'widget.fixedValue': 'Vaste waarde',
  'widget.fixedValues': 'Vaste waarden',
  'widget.clearAllDatapoints': 'Wis alle datapunten',
  'widget.clearDatapoint': 'Wis datapunt',
  'widget.clear': 'Wissen',
  'widget.noLink': 'Geen link',
  'widget.placeholder': 'Placeholder',
  'widget.emptySpace': 'Lege ruimte',
  'widget.noConfigurationOptions': 'Geen configuratie-opties',
  'widget.showPercentage': 'Percentage weergeven',
  'widget.showInvertedValue': 'Restwaarde weergeven',
  'widget.heatmap': 'Heatmap',
  'widget.decimals': 'Decimalen',
  'widget.status': 'Status',
  'widget.showDescription': 'Beschrijving weergeven',
  'widget.trendChartWidget': 'Trend Chart Widget',
  'widget.info': 'Info',
  'widget.useSteps': 'In stappen weergeven',
  'widget.invertValues': 'Waarden omkeren',
  'widget.preset': 'Preset',
  'widget.presetLabel': 'Preset label',
  'widget.efficiencyGauge': 'Efficiëntie meter',
  'widget.grid': 'Tabel',
  'widget.ghgGrid': 'GHG - Tabel',
  'widget.enableExport': 'Exporteren inschakelen',
  'widget.calendar': 'Kalender',
  'widget.connectivity': 'Connectiviteit',
  'widget.default': 'Standaard',
  'widget.boolean': 'Actief/non-actief',
  'widget.expandable': 'Openklapbaar',
  'widget.defaultOpen': 'Standaard open',
  'widget.timeSelection': 'Tijdselectie',
  'widget.discardChanges': 'Niet-opgeslagen wijzigingen annuleren voor je vertrek?',
  'widget.yes': 'Ja',
  'widget.no': 'Neen',
  'widget.createSection': 'Sectie aanmaken',
  'widget.editSection': 'Sectie wijzigen',
  'widget.deleteSection': 'Sectie verwijderen',
  'widget.deleteSectionConfirm': 'Weet je zeker dat je deze sectie wil verwijderen?',
  'widget.layouts': 'Layouts',
  'widget.widgets': 'Widgets',
  'widget.selectALayout': 'Kies een layout',
  'widget.selectAWidget': 'Kies een widget',
  'widget.selectLayout': 'Kies layout',
  'widget.selectWidget': 'Kies widget',
  'widget.hideHeader': 'Verberg hoofding',
  'widget.noSections': 'Geen secties geconfigureerd',
  'widget.floorMap': 'Plattegrond',
  'widget.microWidget': 'Micro-widget',
  'widget.plusMinus': 'Plus / Min',
  'widget.onOff': 'Aan / Uit',
  'widget.controlButtons': 'Bedieningsknoppen',
  'widget.iconOn': 'Pictogram aan',
  'widget.iconOff': 'Pictogram uit',
  'widget.colorOn': 'Kleur aan',
  'widget.colorOff': 'Kleur uit',
  'widget.control': 'Bediening',
  'widget.writeDatapoint': 'Schrijfdatapunt',
  'widget.addWidget': 'Voeg een widget toe',
  'widget.offset': 'Offset',
  'widget.historicalComparisonChartWidget': 'Historische Vergelijking Trend Widget',
  'widget.previousPeriod': 'Vorige week',
  'widget.currentPeriod': 'Huidige week',
  'widget.navigationLabel': 'Navigatielabel',
  'widget.addNavigationLabel': 'Navigatielabel toevoegen',
  'widget.offsets': 'Offsets',
  'widget.timeRange': 'Tijdsbereik',
  'widget.timeRangeDatapoints': 'Tijdsbereik voor hoofddatapunt(en)',
  'widget.timeRangeComparisonDatapoints': 'Tijdsbereik voor vergelijkingsdatapunt(en)',
  'widget.style': 'Stijl',
  'widget.customMarker': 'Instelbare marker',
  'widget.addCustomMarker': 'Instelbare marker toevoegen',
  'widget.showSquealInfo': 'Alarm info tonen',
  'widget.mapCenterClickOnMap': 'Kaart middelpunt (klik op de kaart om een positie in te stellen)',
  'widget.geocoordinatesClickOnMap':
    'Geocoördinaten (klik op de kaart om een positie in te stellen)',
  'widget.zoom': 'Zoom',
  'widget.autoZoom': 'Automatische zoom',
  'widget.map': 'Kaart',
  'widget.addEntities': 'Sites toevoegen',
  'widget.addTraceableDevices': 'Trackers toevoegen',
  'widget.comparisonPeriod': 'Vergelijkingsperiode',
  'widget.customLogic': 'Aangepaste logica',
  'widget.equipments': 'Toestellen',
  'widget.equipmentType': 'Type toestel',
  'widget.selectedEquipments': 'Geselecteerde toestellen',
  'widget.equipmentsForType': 'Toestellen voor geselecteerde type',
  'widget.equipmentLabel': 'Toestel label',
  'widget.matrix': 'Matrix',
  'widget.matrixDetail': 'Matrix detail',
  'widget.addLayout': 'Lay-out toevoegen',
  'widget.showComparator': 'Toon referentiewaarde',
  'widget.areYouSure': 'Weet je het zeker?',
  'widget.unit.tonCO2e': 'Ton CO2e',
  'widget.circle': 'Cirkel',
  'widget.connectedDatapoints': 'Verbonden datapunten',
  'widget.inheritColor': 'Kleur overnemen',
  'widget.highlightOnHover': 'Markeer bij muisaanwijzer',
  'widget.enlargeLabelOnHover': 'Vergroot label bij muisaanwijzer',
  'widget.showDate': 'Datum weergeven',
  'widget.dateFormat': 'Datumnotatie',
  'widget.timeFormat': 'Tijdnotatie',
  'widget.clock': 'Klok',
  'widget.divider': 'Scheidingslijn',
  'widget.showDivider': 'Toon scheidingslijn',
  'widget.backgroundColor': 'Achtergrondkleur',
  'widget.fontColor': 'Tekstkleur',
  'widget.differenceArrow': 'Pijl verschil',
  'widget.differencePercentage': 'Verschil percentage',
  'widget.showDifferenceArrow': 'Toon pijl verschil',
  'widget.showDifferencePercentage': 'Toon verschil percentage',
  'widget.arrowUpColor': 'Pijl naar boven kleur',
  'widget.arrowDownColor': 'Pijl naar beneden kleur',
  'widget.transparentZone': 'Transparente zone',
  'widget.mode': 'Mode',
  'widget.incrementValue': 'Toename waarde',
  'widget.offlineSince': 'offline sinds',
  'widget.isOnline': 'is online',
  'widget.sdg1': 'No poverty',
  'widget.sdg2': 'Zero hunger',
  'widget.sdg3': 'Good health and well-being',
  'widget.sdg4': 'Quality education',
  'widget.sdg5': 'Gender equality',
  'widget.sdg6': 'Clean water and sanitation',
  'widget.sdg7': 'Affordable and clean energy',
  'widget.sdg8': 'Decent work and economic growth',
  'widget.sdg9': 'Industry, innovation and infrastructure',
  'widget.sdg10': 'Reduced inequalities',
  'widget.sdg11': 'Sustainable cities and communities',
  'widget.sdg12': 'Responsible consumption and production',
  'widget.sdg13': 'Climate action',
  'widget.sdg14': 'Life below water',
  'widget.sdg15': 'Life on land',
  'widget.sdg16': 'Peace, justice and strong institutions',
  'widget.sdg17': 'Partnership for the goals',
  'widget.hideLabel': 'Verberg label',
  'widget.sdgWidget': 'SDG widget',
  'widget.maxWidgetResizeError':
    'De maximale grootte voor deze widget is overschreden. Ontgrendel een widget in deze rij en probeer opnieuw.',
  'widget.lockedWidgetResizeError':
    'Vergrendelde widgets kunnen niet worden vergroot of verkleind. Ontgrendel je widget en probeer opnieuw.',
  'widget.lockedWidgetAddError':
    'Deze rij heeft geen ruimte meer om een widget toe te voegen. Ontgrendel een widget in deze rij en probeer opnieuw.',
  'widget.copySection': 'Kopieer sectie naar klembord',
  'widget.pasteSection': 'Sectie van klembord plakken',
  'widget.charts': 'Grafieken',
  'widget.gaugesIndicators': 'Meters & Indicatoren',
  'widget.grids': 'Tabellen',
  'widget.ghgGrids': 'GHG - Tabellen',
  'widget.controls': 'Bediening',
  'widget.others': 'Andere',
  'widget.general': 'Algemeen',
  'widget.eventLog': 'Logboek',
  'widget.interval': 'Interval',
  'widget.advanced': 'Geavanceerd',
  'widget.sustainability': 'Duurzaamheid',
  'widget.mainDatapoint': 'Hoofddatapunt(en)',
  'widget.otherDatapoint': 'Ander(e) datapunt(en)',
  'widget.absoluteValue': 'Absolute waarde',
  'widget.differenceValue': 'Verschilwaarde',
  'widget.group': 'Groep',
  'widget.groups': 'Groepen',
  'widget.addGroup': 'Groep toevoegen',
  'widget.selectedGroup': 'Geselecteerde groep',
  'widget.unGroupedDatapoints': 'Niet-gegroepeerde datapunten',
  'widget.selectedDatapointConfiguration': 'Geselecteerde datapuntconfiguratie',
  'widget.selectedDataConfiguration': 'Geselecteerde data configuratie',
  'widget.last': 'Laatste',
  'widget.classicWidgets': 'Klassieke widgets',
  'widget.sustainabilityWidgets': 'Duurzaamheidswidgets',
  'widget.ghgCharts': 'GHG - grafieken',
  'widget.scope': 'Scope',
  'widget.emissionFactors': 'Emissiedetails',
  'widget.emissionFactor': 'Emissiedetail',
  'widget.selectData': 'Selecteer gegevens',
  'widget.clearData': 'Verwijder gegevens',
  'widget.ghgGaugesIndicators': 'GHG - Meters & Indicatoren',
  'widget.countries': 'Landen',
  'widget.columns': 'Kolommen',
  'widget.column': 'Kolom',
  'widget.addColumn': 'Kolom toevoegen',
  'widget.selectedColumnConfiguration': 'Geselecteerde kolomconfiguratie',
  'widget.selectedLineConfiguration': 'Geselecteerde lijnconfiguratie',
  'widget.selectedRowConfiguration': 'Geselecteerde rijconfiguratie',
  'widget.timeRangeValue': 'Tijdbereikwaarde',
  'widget.site': 'Site',
  'widget.building': 'Gebouw',
  'widget.floor': 'Verdieping',
  'widget.room': 'Kamer',
  'widget.equipment': 'Apparatuur',
  'widget.name': 'Naam',
  'widget.width': 'Breedte',
  'widget.row': 'Rij',
  'widget.rows': 'Rijen',
  'widget.addRow': 'Rij toevoegen',
  'widget.comparisonValue': 'Vergelijkingswaarde',
  'widget.amount': 'Aantal',
  'widget.selectEntity': 'Selecteer entiteit',
  'widget.clearEntity': 'Entiteit wissen',
  'widget.file': 'Bestand',
  'widget.files': 'Bestanden',
  'widget.image': 'Afbeelding',
  'widget.information': 'Informatie',
  'widget.informationLine': 'Informatielijn',
  'widget.line': 'Lijn',
  'widget.addLine': 'Lijn toevoegen',
  'widget.lineType': 'Lijn type',
  'widget.during': 'Gedurende',
  'widget.duration': 'Duur',
  'widget.informationLines': 'Informatielijnen',
  'widget.border': 'Rand',
  'widget.donut': 'Donut',
  'widget.showLegend': 'Legende weergeven',
  'widget.stack': 'Stapelen',
  'widget.bar': 'Staaf',
  'widget.ascending': 'Oplopend',
  'widget.descending': 'Aflopend',
  'widget.sortOrder': 'Sorteervolgorde',
  'widget.lineChart': 'Lijngrafiek',
  'widget.barChart': 'Staafdiagram',
  'widget.selectSite': 'Selecteer een site',
  'widget.selectTraceableDevice': 'Selecteer een traceerbaar apparaat',
  'widget.consumptionExport': 'Consumptie export',
  'widget.organisation': 'Organisatie',
  'widget.totals': 'Totalen',
  'widget.historicalBarChart': 'Historisch staafdiagram',
  'widget.xrange': 'X Range',
  'widget.customer': 'Klant',
  'widget.dataEntry': 'Gegevensinvoer',
  'widget.dataSource': 'Databron',
  'widget.confirmYourEntry': 'Bevestig je invoer',
  'widget.areYouSureSmallerThanPrevious':
    'Weet je zeker dat je een waarde wilt invoeren die kleiner is dan de vorige?',
  'widget.areYouSureNegativeValue': 'Weet je zeker dat je een negatieve waarde wilt invoeren?',
  'widget.relativeInput': 'Relatieve invoer',
  'widget.showOverdueDatapointsOnly': 'Alleen achterstallige datapunten weergeven',
  'widget.statusGrid': 'Statusraster',
  'widget.energySaving': 'Energiebesparing',
  'widget.meterOverview': 'Meteroverzicht',
  'widget.relativeToggleCaption':
    'Bij "Relatief: aan" worden waarden als verschil ten opzichte van de vorige waarde ingevoerd. Bij "Relatief: uit" worden worden waarden als dusdanig ingevoerd.',
  'widget.energyClassification': 'Energieclassificatie',
  'widget.area': 'Gebied',
  'widget.performancePeriod': 'Prestatie {period}',
  'widget.varianceToPeriod': 'Vergelijking met {period}',
  'widget.averageAllBuildings': 'Gemiddeld (alle gebouwen)',
  'widget.forecast': 'Voorspelling',
  'widget.targetEndDate': 'Doel per einddatum',
  'widget.target': 'Doel',
  'widget.gradualDecrease': 'Geleidelijke afname',
  'widget.gradualIncrease': 'Geleidelijke toename',
  'widget.targetPeriod': 'Doelperiode',
  'widget.targetValue': 'Streefwaarde',
  'widget.targetStartDate': 'Streefwaarde datum',
  'widget.decrease': 'Afname',
  'widget.increase': 'Toename',
  'widget.percentagePer': '% per',
  'widget.decreaseUnit': 'Afnameeenheid',
  'widget.increaseUnit': 'Toenameeenheid',
  'widget.trendLine': 'Trendlijn',
  'widget.extendPeriodToTarget': 'Verleng periode tot doel tijd',
  'widget.hour': 'Uur',
  'widget.apply': 'Toepassen',
  'widget.configurationError': 'Widgetconfiguratiefout',
  'widget.tooltip': 'Tooltip',
  'widget.tooltipText': 'Tooltip tekst',
  'widget.centeredText': 'Gecentreerde tekst',
  'widget.valuesAreExpressedIn': 'De waarden worden uitgedrukt in',
  'widget.groupCalculationMethod': 'Groepsberekeningsmethode',
  'widget.copyToClipboard': 'Kopiëer naar klembord',
  'widget.pasteFromClipboard': 'Plak van klembord',
  'widget.copySettings': 'Instellingen kopiëren',
  'widget.pasteSettings': 'Instellingen plakken',
  'widget.absolute': 'Absoluut',
  'widget.paused': 'Gepauzeerd',
  'widget.liveData': 'Live',
  'widget.operator': 'Operator',
  'widget.successfullyEntered': 'succesvol ingevoerd',
  'widget.updateInProgress': 'update wordt uitgevoerd',
  'widget.successfullyUpdatedTo': 'succesvol geüpdatet naar',
  'widget.successfullyDeleted': 'succesvol verwijderd',
  'widget.detail': 'Detail',
  'widget.addSite': 'Site toevoegen',
  'widget.addBuilding': 'Gebouw toevoegen',
  'widget.addFloor': 'Verdieping toevoegen',
  'widget.addRoom': 'Kamer toevoegen',
  'widget.addEquipment': 'Apparaat toevoegen',
  'widget.equip': 'Apparaat',
  'widget.id': 'ID',
  'widget.remark': 'Opmerking',
  'widget.varia': 'Varia',
  'widget.layout': 'Lay-out',
  'widget.portrait': 'Portret',
  'widget.landscape': 'Landschap',
  'widget.fields': 'Velden',
  'widget.addSingleProperty': 'Enkele eigenschap toevoegen',
  'widget.addEntityDetails': 'Entiteitsdetails toevoegen',
  'widget.country': 'Land',
  'widget.degreeDayReference': 'Referentie graaddag',
  'widget.singleLink': 'Enkele link',
  'widget.multipleLinks': 'Meerdere linken',
  'widget.navigationType': 'Navigatie type',
  'widget.dashboards': 'Dashboards',
  'widget.sections': 'Secties',
  'widget.masterDetail': 'Meester/detail',
  'widget.showDetails': 'Toon details',
  'widget.floatingDetails': 'Vlottende details',
  'widget.floatingList': 'Vlottende tabel',
  'widget.floatingGrid': 'Vlottend rooster',
  'widget.fixedList': 'Vaste tabel',
  'widget.fixedGrid': 'Vast rooster',
  'widget.list': 'Lijst',
  'widget.details': 'Details',
  'widget.floating': 'Vlottend',
  'widget.fixed': 'Vast',
  'widget.entityValue': 'Entiteitswaarde',
  'widget.defaultExpanded': 'Standaard opengeklapt',
  'widget.resolution': 'Resolutie',
  'widget.comparatorUnit': 'Vergelijkingseenheid',
  'widget.selectedDatapointTag': 'Geselecteerd datapunt tag',
  'widget.otherDatapointTag': 'Ander datapunt tag',
  'widget.entityTag': 'Entiteit tag',
  'widget.formula': 'Formule',
  'widget.formulaPreview': 'Voorbeeld van formule',
  'widget.formulaEditor': 'Formule-editor',
  'widget.intervalCalculationMethod': 'Intervalberekeningsmethode',
  'widget.tagValue': 'Tag-waarde',
  'widget.searchCity': 'Stad zoeken',
  'widget.yAxisMin': 'Y-as min',
  'widget.yAxisMax': 'Y-as max',
  'widget.linkedWidget': 'Gekoppelde widget',
  'widget.linkedDashboard': 'Gekoppeld dashboard',
  'widget.dashboardLink': 'Dashboard koppeling',
  'widget.addWidgetDatapoints': 'Voeg widgetdatapunten toe',
  'widget.linkedDatapoints': 'Gekoppelde datapunten',
  'widget.groupDatapoints': 'Groepeer datapunten',
  'widget.linkedPointValue': 'Geklikte puntwaarde',
  'widget.addClickedPointValue': 'Voeg aangeklikte puntwaarde toe',
  'widget.splitPage': 'Weergeven op gesplitste pagina',
  'widget.close': 'Sluiten',
  'widget.extraDatapoints': 'Extra datapunten',
  'widget.addWidgetExtraDatapoints': 'Voeg extra widgetdatapunten toe',
  'widget.addWidgetComparators': 'Voeg widget comparators toe',
  'widget.addWidgetMinComparators': 'Voeg widget min comparators toe',
  'widget.addWidgetMaxComparators': 'Voeg widget max comparators toe',
  'widget.linkedLinkDatapoints': 'Gekoppelde extra datapunten',
  'widget.linkedComparators': 'Gekoppelde comparators',
  'widget.linkedMinComparators': 'Gekoppelde min comparators',
  'widget.linkedMaxComparators': 'Gekoppelde max comparators',
  'widget.formulaResult': 'Formule resultaat',
  'widget.reload': 'Herladen',
}
