import React, { useCallback, useMemo } from 'react'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { range } from 'lodash'

import { useOrganisationReportingYear } from 'services/organisations'

import DateField from '../..'

dayjs.extend(duration)
const ReportingYearDateField = ({ organisationId, max, min, ...props }) => {
  const { createDateRange } = useOrganisationReportingYear(organisationId)

  const dateRange = useMemo(() => {
    const year = dayjs().year()
    const [start, end] = createDateRange(year)

    return [min > start ? min : start, max < end ? max : end]
  }, [createDateRange, min, max])

  const disabledDate = useCallback(
    (date) => {
      return date && (date < dateRange[0] || date > dateRange[1])
    },
    [dateRange]
  )

  const getDisabledNumbers = (selected, begin, end, max, unit) => {
    const diffBegin = dayjs.duration(selected.diff(begin))
    const diffEnd = dayjs.duration(end.diff(selected))

    if (diffBegin.as(unit) < max) {
      return range(0, begin.get(unit))
    }

    if (diffEnd.as(unit) < max) {
      return range(end.get(unit), max)
    }

    return []
  }

  const disabledTime = useCallback(
    (selected) => {
      const end = dayjs()
      const begin = dateRange[0]

      const hours = getDisabledNumbers(selected, begin, end, 24, 'hours')
      const minutes = getDisabledNumbers(selected, begin, end, 60, 'minutes')
      const seconds = getDisabledNumbers(selected, begin, end, 60, 'seconds')

      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds,
      }
    },
    [dateRange]
  )

  return <DateField disabledDate={disabledDate} disabledTime={disabledTime} {...props} />
}

export default ReportingYearDateField
