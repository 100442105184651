import React, { useEffect } from 'react'

import { GraphSection } from 'components/Dashboard/components/BulletGraph/styled'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import { ClassificationElementStyled } from './styled'

const ClassificationElement = ({
  value,
  size = 'l',
  padding,
  onChange,
  dataCy = 'ClassificationElement',
}) => {
  const min = value
    ? value.energyClassificationDetails?.[1]
      ? value.energyClassificationDetails[0].maxValue -
        value.energyClassificationDetails[1].maxValue
      : value.energyClassificationDetails[0].maxValue - 100
    : 0
  const diff = value
    ? value.energyClassificationDetails[value.energyClassificationDetails.length - 1].maxValue -
      value.energyClassificationDetails[value.energyClassificationDetails.length - 2].maxValue
    : 0
  const max = value
    ? value.energyClassificationDetails[value.energyClassificationDetails.length - 1].maxValue +
      diff
    : 0
  const values = value
    ? [
        ...value?.energyClassificationDetails.map(({ maxValue, ...item }) => ({
          ...item,
          value: maxValue,
        })),
        {
          id: 'max',
          color: value.aboveTresholdColor,
          label: value.aboveTresholdLabel,
          value: max,
        },
      ]
    : []
  const range = max - min
  const {
    state: {
      bottomDrawer: { isOpen },
    },
  } = useDashboard()

  useEffect(() => {
    //if (!isOpen) setIsOpenState(false)
  }, [isOpen])

  return (
    <ClassificationElementStyled
      className="ClassificationElement"
      size={size}
      padding={padding}
      onClick={onChange}
    >
      <span className="ClassificationElement__label">{value?.name}</span>
      <div className="ClassificationElement__values">
        {values.map(({ id, color, label, value }, index) => {
          const slice = Math.abs(
            index === 0 ? value - min : value - (values[index - 1]?.value || 0)
          )
          const percentage = (slice / range) * 100
          return (
            <GraphSection className="GraphSection" key={id} color={color} percentage={percentage}>
              <span className="GraphSection__label">{label}</span>
              {size !== 's' && <span className="GraphSection__value">{value}</span>}
            </GraphSection>
          )
        })}
      </div>
    </ClassificationElementStyled>
  )
}

ClassificationElement.propTypes = {}

ClassificationElement.defaultProps = {}

export default ClassificationElement
