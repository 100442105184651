import styled from 'styled-components'

const CustomerSelectorStyled = styled.div`
  h3 {
    white-space: nowrap;
    font-size: 1rem;
    color: ${({ theme }) => theme.color.softText};
    margin-bottom: 0;
  }
  .CustomerSelector__value {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.strongText};
  }
  .DropdownArrow {
    &:after {
      border-color: ${({ theme }) => theme.color.strongText} !important;
    }
  }
`
export default CustomerSelectorStyled
