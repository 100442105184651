import aggrid from '@ag-grid-community/core/dist/styles/ag-grid.css'
import material from '@ag-grid-community/core/dist/styles/ag-theme-material.css'
import { darken, transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { PaginationStyled } from 'components/Dashboard/components/Table/styled'
import SearchFieldStyled from 'components/Global/FormField/SearchField/styled'
import { ListHeader } from 'components/Global/List'

const getTreeIndentStyles = (indent, levels = 20) => {
  let styles = ''

  for (let i = 1; i < levels; i++) {
    styles = `${styles}
      .ag-ltr .ag-toolpanel-indent-${i} {
        padding-left: ${indent * i}px;
      }

      .ag-rtl .ag-toolpanel-indent-${i} {
        padding-right: ${indent * i}px;
      }

      .ag-ltr .ag-row-group-indent-${i} {
        padding-left: ${indent * 2 * i}px;
      }

      .ag-rtl .ag-row-group-indent-${i} {
        padding-right: ${indent * 2 * i}px;
      }
    `
  }
  return styles
}

export const AgGridMaterial = styled.div`
  ${aggrid}
  ${material}
  ${({ size = 'lg' }) =>
    ({
      lg: css``,
      md: css`
        font-weight: 100;

        .ag-cell,
        .ag-full-width-row .ag-cell-wrapper.ag-row-group {
          line-height: 40px !important;
        }

        .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
          height: 40px !important;
        }
      `,
      sm: css``,
    }[size])}

  min-height: ${({ tableHeight }) => tableHeight};

  ${({ verticalHeaders }) =>
    verticalHeaders &&
    css`
      .ag-header-row-floating-filter {
        top: 0 !important;
      }

      .ag-cell-label-container {
        height: 100%;
      }

      .ag-header-cell-label {
        height: 100%;
        padding: 0 !important;
        align-items: flex-end;
      }

      .ag-header-cell-label .ag-header-cell-text {
        width: 30px;
        transform: rotate(-90deg);
        margin-top: 5px;
        display: inline-block;
        flex: 1;
        transform-origin: left top;
        overflow: visible;
      }

      .ag-header-cell-menu-button {
        margin: auto auto 0.5rem;
      }
    `}
  height: ${({ height }) => height};
  flex: 1;
  display: flex;
  flex-direction: column;

  .AgGridMaterial__tools {
    display: flex;
    background: ${({ theme }) => theme.color.backgroundHighlight};
    padding: 1rem;
  }

  > div:not(.AgGridMaterial__tools) {
    width: ${({ width }) => width || '100%'};
    min-width: ${({ width }) => width || '100%'};
    flex: 1;
  }

  ${SearchFieldStyled} {
    padding: 1rem 1rem 0;
    width: 40%;
  }

  ${PaginationStyled} {
    margin: auto 0 auto auto;
  }

  .ag-row {
    cursor: pointer;

    ${ListHeader} {
      border-left: 1px solid ${({ theme }) => theme.color.softStroke};
      font-size: 1.4rem;
      padding-top: 1.4rem;
    }

    &.ag-row-footer {
      font-weight: 600;
    }

    .ag-cell {
      align-items: center;
      display: flex;

      & > .ag-react-container {
        height: auto;
      }

      &.text-center {
        justify-content: center;
        .ag-react-container {
          display: flex;
          justify-content: center;
        }
      }
      &.text-right {
        justify-content: flex-end;
        .ag-react-container {
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
          .AgGridStatusCell {
            .AgGridStatusCell__container {
              flex-direction: row-reverse;
              label {
                margin: 0 0.2rem 0 0.5rem;
              }
            }
          }
        }
      }

      &:before {
        transition: all 0.2s ease;
      }

      &.new-parent {
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          bottom: 5px;
          left: 0;
          right: 0;
          background: ${({ theme }) => transparentize(0.9, theme.color.main)};
        }
      }

      &.new-sibling-before {
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          height: 5px;
          bottom: 0;
          left: 0;
          right: 0;
          background: ${({ theme }) => transparentize(0.8, theme.color.main)};
        }
      }

      &.new-sibling-after {
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          height: 5px;
          top: 0;
          left: 0;
          right: 0;
          background: ${({ theme }) => transparentize(0.8, theme.color.main)};
        }
      }

      &.forbidden {
        &:before {
          content: '';
          position: absolute;
          background: ${({ theme }) => transparentize(0.5, theme.color.backgroundFront)};
          z-index: 100;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      &.ag-cell-inline-editing {
        box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
        border-radius: 0.5rem;
        height: auto;
        padding: 0;
        input {
          padding: 0 0.5rem;
          border-color: ${({ theme }) => theme.color.main};
        }
      }
    }

    /* $_remove_{ListHeader} {
    border-left: 1px solid ${({ theme }) => theme.color.softStroke};
    font-size: 1.4rem;
    padding-top: 1.4rem;
  } */

    .ag-theme-material {
      height: 100%;
      border-left: 1px solid ${({ theme }) => theme.color.softStroke};
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: #23234f !important;
  }

  .ag-row-group {
    > .ag-cell-wrapper {
      padding-left: 1.5rem;
    }
    > .ag-group-value {
      flex-grow: unset;
    }

    .ag-icon {
      color: ${({ theme }) => theme.color.strongText};
    }
  }

  .ag-drag-handle {
    .ag-icon {
      color: ${({ theme }) => theme.color.strongText};
    }
  }
  .ag-react-container {
    height: 100%;

    .AgGrid__combined-cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      line-height: 1.4;

      span {
        color: ${({ theme }) => theme.color.mainText};
        font-weight: 100;
      }
    }
  }

  .ag-set-filter-item > div,
  .ag-set-filter-item > span {
    white-space: pre-wrap;
  }

  &.ag-theme-material {
    ${getTreeIndentStyles(10, 20)}
    font-family: ${({ theme }) => theme.font.primary};

    .ag-overlay-loading-wrapper {
      background: none;
    }

    .ag-root-wrapper {
      background: transparent;
    }

    .ag-header {
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1rem;
      font-weight: 500;
      background: none;
      height: ${({ headerHeight = 32 }) => headerHeight}px !important;
      min-height: ${({ headerHeight = 32 }) => headerHeight}px !important;
    }

    .ag-header-row:not(.ag-header-row-floating-filter):not(.ag-header-row-column-filter) {
      height: ${({ headerHeight = 32 }) => headerHeight}px !important;
      min-height: ${({ headerHeight = 32 }) => headerHeight}px !important;
    }

    /*.ag-header,
    .ag-header-viewport,
    .ag-header-row:not(.ag-header-row-floating-filter) {
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1rem;
      font-weight: 500;
      background: none;
    }*/

    .ag-center-cols-viewport {
      overflow-x: hidden;
    }

    .ag-header-row-floating-filter,
    .ag-header-row-column-filter {
      height: 32px !important;
      min-height: 32px !important;
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1rem;
      font-weight: 500;
      background: none;
    }

    .ag-header {
      border-color: ${({ theme }) => theme.color.softerStroke};
    }

    .ag-header-icon,
    .ag-header-label-icon,
    .ag-sort-order {
      color: ${({ theme }) => theme.color.softText};
    }

    .ag-header-cell:not(.ag-floating-filter) {
      transition: border 0.2s ease;
      background: ${({ theme }) => theme.color.backgroundHighlight};
      border-bottom: 2px solid transparent;

      &:hover {
        background: ${({ theme }) => theme.color.backgroundHighlight} !important;
        border-bottom: 2px solid ${({ theme }) => theme.color.main};
      }

      .ag-header-cell-text {
        color: ${({ theme }) => theme.color.softText};
      }
      .ag-icon {
        color: ${({ theme }) => theme.color.strongText};
      }

      &.text-center {
        display: flex;
        align-items: center;
        justify-content: center;

        .ag-header-cell-label {
          justify-content: center;
        }
      }

      &.text-right {
        justify-content: flex-end;
        .ag-header-cell-label {
          justify-content: flex-end;
        }
        .ag-cell-label-container {
          overflow: visible;
        }
        .ag-header-icon {
          margin-right: -1.5rem;
          margin-left: 0.3rem;
        }
      }
    }

    .ag-header-cell.ag-floating-filter {
      background: none !important;

      .ag-floating-filter-body {
        display: none;
      }

      .ag-floating-filter-button {
        margin-left: auto;
      }
    }

    .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover {
      background: ${({ theme }) => theme.color.backgroundHighlight};
    }

    .ag-tab-header {
      background: ${({ theme }) => theme.color.backgroundHighlight};

      .ag-tab.ag-tab-selected {
        border-bottom: 1px solid ${({ theme }) => theme.color.main};
      }
    }

    .ag-menu {
      color: ${({ theme }) => theme.color.mainText};
      background: ${({ theme }) => theme.color.backgroundFront};
    }

    .ag-menu-header {
      background: ${({ theme }) => theme.color.backgroundHighlight};
    }

    .ag-row {
      background-color: transparent;
      border-color: ${({ theme }) => theme.color.softStroke};
      color: ${({ theme }) => theme.color.mainText};

      &.virtual {
        background: ${({ theme }) => transparentize(0.97, theme.color.highlight)};
        border-color: ${({ theme }) => transparentize(0.5, theme.color.highlight)};

        &.ag-row-hover {
          background-color: ${({ theme }) => transparentize(0.9, theme.color.highlight)};
        }

        &.ag-row-selected {
          background-color: ${({ theme }) => transparentize(0.8, theme.color.highlight)};
        }
      }
      &.active {
        background-color: ${({ theme }) => darken(0.03, theme.color.backgroundHighlight)};
      }
    }

    .ag-row-hover {
      background-color: ${({ theme }) => theme.color.backgroundHighlight};

      .Icon.IconCell__action {
        opacity: 1;
        padding: 0 0.5rem;
      }
    }

    .ag-row-selected {
      background-color: ${({ theme }) => darken(0.01, theme.color.backgroundHighlight)};
    }

    .ag-icon-checkbox-checked {
      color: ${({ theme }) => theme.color.main};
    }

    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
    .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle,
    .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
    .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.color.main};
    }
  }
`
