import React, { createContext, useContext, useEffect, useState } from 'react'

export const CookieBannerContext = createContext(null)

export const useCookieBanner = () => useContext(CookieBannerContext)

const CookieBannerProvider = ({ children }) => {
  const [cookieSettings, setCookieSettings] = useState({
    allowCookies: false
  })

  useEffect(() => {
    const cookies = JSON.parse(localStorage.getItem('cookieSettings')) || {
      allowCookies: false
    }
    setCookieSettings(cookies)
  }, [])

  const onSetCookies = (payload) => {
    const newCookieSettings = {
      ...cookieSettings,
      ...payload
    }
    localStorage.setItem('cookieSettings', JSON.stringify(newCookieSettings))
    setCookieSettings(newCookieSettings)
  }
  return (
    <CookieBannerContext.Provider value={{ cookieSettings, onSetCookies }}>
      {children}
    </CookieBannerContext.Provider>
  )
}

export default CookieBannerProvider
