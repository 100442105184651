import React from 'react'

import gfm from 'remark-gfm'

import { MarkdownContainer } from './styled'

const Markdown = ({ children }) => {
  return <MarkdownContainer remarkPlugins={[gfm]}>{children}</MarkdownContainer>
}

export default Markdown
