import React from 'react'

import PropTypes from 'prop-types'

import { AntSelect } from '../../AntDesign'
import SelectStyled from './styled'

const { Option } = AntSelect
const Select = ({
  disabled,
  value,
  placeholder,
  defaultValue,
  selectOptions,
  onChange,
  onSearch,
  onFocus,
  onBlur,
  dataCy,
}) => {
  return (
    <SelectStyled className="Select" data-cy={dataCy}>
      <AntSelect
        disabled={disabled}
        showSearch
        style={{ width: 200 }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        defaultValue={defaultValue}
        value={value}
      >
        {selectOptions.map((option, index) => (
          <Option key={`option${index}`} value={option}>
            {option}
          </Option>
        ))}
      </AntSelect>
    </SelectStyled>
  )
}

Select.propTypes = {
  selectOptions: PropTypes.array,
}

Select.defaultProps = {
  selectOptions: [],
}

export default Select
