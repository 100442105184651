import dayjs from 'dayjs'

export const convertNotifications = (notifications, global) => {
  return notifications.map(({ guid, name, type, inProgress, url, duration, ...notification }) => ({
    ...notification,
    duration,
    icon: {
      report: 'fas fa-chart-line',
      etl: 'fas fa-up-from-bracket',
      info: 'fas fa-info',
      error: 'fas fa-xmark',
    }[type],
    type: { 1: 'warning', 0: 'success', 2: 'info' }[inProgress] || type,
    startTime: notification['@timestamp'],
    inProgress: inProgress === 1,
    id: guid,
    ...(type === 'info' || typeof inProgress === 'undefined'
      ? { label: name }
      : {
          duration: duration || 10,
          formattedLabel: {
            id: `${type}${inProgress}`,
            details: {
              name,
              duration: duration || 0,
            },
          },
        }),
    ...(url && {
      duration: duration || 10,
      buttons: [
        { url, label: 'download' },
        {
          isPrimary: true,
          label: 'view',
          onClick: () =>
            global.ui.action({
              type: 'SET_MODAL',
              payload: {
                modalComponent: 'OfficeViewer',
                title: 'sustainability.module.gri.report.title',
                url,
              },
            }),
        },
      ],
    }),
  }))
}

export const checkForNewMessages = (notifications, currentState, deleted) => {
  return notifications.filter(({ id, inProgress, duration, startTime }) => {
    if (deleted.includes(id)) {
      return false
    }
    return (
      dayjs(startTime).isAfter(dayjs().subtract(30 - (duration || 0), 's')) &&
      !currentState.some(
        (notification) => notification.id === id && notification.inProgress === inProgress
      )
    )
  })
}
