import styled from 'styled-components'

import { Connectivity } from './styled.Connectivity'
import { SeverityCounts } from './styled.SeverityCounts'
import { Widget } from './styled.Widget'

const variants = {
  Widget,
  SeverityCounts,
  Connectivity,
}

const TextExtendedStyled = styled.p`
  ${({ variant }) => variants[variant]}
  ${({ type }) => variants[type]}
`
export default TextExtendedStyled
