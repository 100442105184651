import { buttonSizes } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.meterOverview',
    category: 'widget.gaugesIndicators',
    keywords: ['indicator', 'meter', 'overview'],
    img: '/resources/images/widgets/meteroverview.png',
    info: {
      'fr-FR':
        '<h2>Aperçu des compteurs</h2>\n' +
        '<p>Le widget Aperçu des compteurs affiche les connexions hiérarchiques entre les compteurs</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données (LINK)</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Meter Overview</h2>\n' +
        '<p>The Meter Overview widget shows the hierarchical connections between meters</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Data</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints (LINK)</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Meteroverzicht</h2>\n' +
        '<p>De widget Meteroverzicht toont de hiërarchische verbindingen tussen meters</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevens</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten (LINK)</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>',
    },
    default: {
      type: 'MeterOverview',
      title: 'widget.meterOverview',
      icon: 'fas fa-info',
      color: variables.color.color.aviary.main,
      fontColor: '#FFFFFF',
      rootMeters: [],
    },
    preview: {
      value: 20,
      unit: '°C',
      icon: 'fas fa-sitemap',
      color: variables.color.color.aviary.main,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.data',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints' },
        componentProps: {
          dataKey: 'datapoints',
          bottomDrawer: 'EntitySelector',
          hideMethod: true,
          tags: {
            meter: '1',
          },
        },
      },
    ],
  },
}

export default config
