import styled from 'styled-components'

const FixedValuesStyled = styled.div`
  .TableBody {
    overflow: visible;
    max-height: none;
  }
  .CaptionTooltip {
    cursor: pointer;
  }
`

export default FixedValuesStyled
