import React from 'react'

import { Form } from 'antd'
import { injectIntl } from 'react-intl'

import { AntInput } from '../AntDesign'

const PasswordField = ({
  label,
  name,
  intl,
  customRules = [],
  type,
  autoComplete = true,
  skipRequired = false,
}) => {
  const rules = [...customRules]

  if (type === 'password') {
    rules.push(
      {
        min: 8,
        message: intl.formatMessage({ id: 'form.password.minimum' }),
      },
      {
        max: 50,
        message: intl.formatMessage({ id: 'form.password.maximum' }),
      },
      {
        validator: (_, value) => {
          if (!value || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(intl.formatMessage({ id: 'form.password.regex' }))
        },
      }
    )
  }

  if (type === 'confirmPassword') {
    rules.push(({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject(intl.formatMessage({ id: 'form.password.match' }))
      },
    }))
  }

  rules.push({
    required: !skipRequired,
    whitespace: true,
    message: `${label} ${intl.formatMessage({ id: 'form.input.required' })}`,
  })

  return (
    <Form.Item
      dependencies={type === 'confirmPassword' ? ['password'] : []}
      name={name}
      label={label}
      rules={rules}
    >
      <AntInput type="password" autoComplete={autoComplete || 'new-password'} />
    </Form.Item>
  )
}

export default injectIntl(PasswordField)
