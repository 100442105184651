import React, { useMemo } from 'react'

import { transparentize } from 'polished'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  getDatapointCalculatedValue,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useLocaleNumber } from 'util/numbers'
import { useGhgData } from 'services/entities'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/Sparkline/Ui'

import { getGranularity } from '../../../Dashboard/utils/common/helpers'
import { getSparkLineHistory } from '../functions'

const GhgSparkline = ({
  dataProps,
  datapoints,
  method = 'sum',
  icon,
  url,
  defaultStartTime,
  title,
  color,
  emissionFactor,
  preview,
  intl,
  customLogic,
  conversionUnit,
  decimals = 2,
  newSocketValue,
  returnUnitId,
  defaultCalculationMethod,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const { isPreviewMode, isEditMode } = dataProps
  const theme = useTheme()

  const { data, error, refetch, loading } = useGhgData({
    datapoints,
    emissionFactor,
    returnUnitId,
    granularity: getGranularity({
      defaultStartTime: defaultStartTime || { preset: 'past7Days' },
      type: 'ghg',
    }),
    timeRange: defaultStartTime,
    defaultTimeRange: { preset: 'past7Days' },
    options: {
      poll: true,
    },
    calculationMethod: defaultCalculationMethod,
    widgetType: 'ghgSparkline',
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data

  const { chart, value, errors, returnUnit } = useMemo(() => {
    if (!validData) {
      return {
        chart: {
          ...preview.chart,
          lines:
            error || !isPreviewMode
              ? []
              : [
                  {
                    ...preview.chart.lines[0],
                    colors: color
                      ? {
                          area: transparentize(0.9, color),
                          line: color,
                        }
                      : {
                          area: transparentize(0.9, theme.color.main),
                          line: theme.color.main,
                        },
                  },
                ],
        },
        value: error || !isPreviewMode ? '--' : 0,
        errors: [],
      }
    }
    const errors = getDatapointErrors(validData)
    let value, history
    const { emissions, returnUnit } = validData[0]
    if (!emissions[0]) {
      value = '--'
      history = []
    } else if (validData.length === 1) {
      value = getDatapointCalculatedValue(
        { ...emissions[0], calculationMethod: defaultCalculationMethod },
        { decimals, customLogic }
      )
      history = getSparkLineHistory(emissions)
    } else {
      const emissionData = validData.reduce(
        (emissionData, { emissions }) =>
          emissions[0]?.history.length ? emissionData.concat(emissions[0]) : emissionData,
        []
      )
      value = getDatapointsCalculatedValue(emissionData, {
        decimals,
        customLogic,
        method,
        calculationMethod: defaultCalculationMethod,
      })
      history = getSparkLineHistory(emissionData, method)
    }

    value = typeof value === 'number' ? localizeNumber(value) : '--'
    return {
      value,
      chart: {
        lines: [
          {
            values: history,
            colors: {
              area: transparentize(0.9, color || theme.color.main),
              line: color || theme.color.main,
            },
          },
        ],
      },
      errors,
      returnUnit,
    }
  }, [
    validData,
    color,
    customLogic,
    decimals,
    method,
    preview.chart,
    theme.color.main,
    localizeNumber,
    defaultCalculationMethod,
    error,
    isPreviewMode,
  ])

  return (
    <Ui
      {...props}
      chart={chart}
      preview={preview}
      value={value}
      unit={returnUnit?.name || 'kgCO2e'}
      title={title}
      icon={icon}
      color={color}
      url={url}
      loading={{
        loading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(GhgSparkline)
