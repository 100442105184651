import React from 'react'

import { injectIntl } from 'react-intl'

import Button from '../../Button'
import { FormFieldLabel } from '../styled'

const DatapointList = ({
  datapoints,
  dataKey,
  label,
  intl,
  selectedDatapointMap,
  selectDatapointsLabel,
  clearDatapointsLabel,
  selectedDatapoints,
  onDatapointHover,
  onRemoveDatapoint,
  onDatapointClick,
  onOpenBottomDrawer,
  onRemoveAllDatapoints,
  groupIndex,
}) => {
  return (
    <>
      <FormFieldLabel as="h4">{intl.formatMessage({ id: label })}</FormFieldLabel>
      {datapoints.map(({ id, name, itemIndex, color }) => (
        <div
          onMouseEnter={(e) => onDatapointHover(e, { id, name, color })}
          onMouseLeave={(e) => onDatapointHover(e, null)}
          key={`${id}-${itemIndex}`}
        >
          <Button
            key={id}
            label={name}
            variant="tag"
            onIconClick={(e) =>
              onRemoveDatapoint(e, { id, name, itemIndex, typeIndex: groupIndex })
            }
            icon="fas fa-xmark"
            color={color}
            isActive={selectedDatapointMap[dataKey] === itemIndex}
            onClick={(e) => onDatapointClick(e, { id, name, itemIndex })}
          />
        </div>
      ))}
      <div className="Datapoints__buttons">
        <Button
          className="SmallButton"
          label={intl.formatMessage({ id: selectDatapointsLabel })}
          onClick={onOpenBottomDrawer}
          variant="smallButton"
        />
        {selectedDatapoints.length > 0 && (
          <Button
            label={intl.formatMessage({ id: clearDatapointsLabel })}
            onClick={onRemoveAllDatapoints}
            variant="smallButton"
          />
        )}
      </div>
    </>
  )
}

export default injectIntl(DatapointList)
