import React from 'react'

import FormField from '../../../Global/FormField'
import { buttonSizes } from '../config/selections'

export const NavigationLabelConfigurator = ({ value = {}, onChange }) => {
  return (
    <div className="NavigationLabelConfigurator">
      <FormField
        value={value.label}
        dataKey="label"
        label={{ formatted: 'widget.label' }}
        componentName="Input"
        onChange={onChange}
      />
      <FormField
        value={value.icon}
        dataKey="icon"
        col="col2"
        label={{ formatted: 'widget.icon' }}
        componentName="IconPicker"
        componentProps={{
          bottomDrawer: true,
        }}
        onChange={onChange}
      />
      <FormField
        value={value.color}
        dataKey="color"
        col="col2"
        label={{ formatted: 'widget.color' }}
        componentName="ColorPicker"
        onChange={onChange}
      />
      <FormField
        value={value.size}
        dataKey="size"
        label={{ formatted: 'widget.size' }}
        componentName="OptionPicker"
        componentProps={{
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        }}
        onChange={onChange}
      />
      <FormField
        value={value}
        label={{ formatted: 'widget.link' }}
        componentName="LinkPicker"
        onChange={onChange}
      />
    </div>
  )
}
