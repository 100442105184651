import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useLocation } from 'react-router'
import { useTheme } from 'styled-components'

import { useDashboards } from 'services/dashboards'

import { useOnClickOutside } from '../../Global/hooks'
import DashboardsMenu from './components/DashboardsMenu'
import MainMenu from './components/MainMenu'
import MenuPanel from './components/MenuPanel'
import SettingsMenu from './components/SettingsMenu'
import SustainabilityMenu from './components/SustainabilityMenu'
import { getDashboardMenu } from './functions'
import MenuBarStyled from './styled'

const MenuBar = ({ menu }) => {
  const [isPanelOpen, setPanelOpen] = useState(false)
  const [panelStatus, setPanelStatus] = useState('')
  const timeout = useRef()
  const element = useRef()

  const location = useLocation()
  const [activeMenu, setActiveMenu] = useState(location.pathname.split('/')[1])

  const theme = useTheme()

  const onMobileView = theme.screenSize.screen.current === 's'

  const { data: dbDashboards, refetch } = useDashboards()

  const { favourites, dashboards } = useMemo(() => getDashboardMenu(dbDashboards, location), [
    dbDashboards,
    location,
  ])

  useEffect(() => {
    setPanelOpen(false)
  }, [location.pathname, setPanelOpen])

  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  useOnClickOutside(element, () => setPanelOpen(false))

  const handlePanelOpen = useCallback((value) => {
    if (typeof value === 'boolean') {
      return setPanelOpen(value)
    }
    setPanelOpen((state) => {
      return !state
    })
    setPanelStatus('opening')
    timeout.current = setTimeout(() => setPanelStatus(''), 1000)
  }, [])

  const defaultMenu = [
    {
      name: 'dashboards ',
      id: 'dashboard',
      icon: 'lnr lnr-chart-bars',
      subMenu: onMobileView ? favourites : undefined,
      togglePanel: true,
      isActive: activeMenu === 'dashboard',
    },
    {
      name: 'sustainability ',
      id: 'sustainability',
      icon: 'lnr lnr-leaf',
      togglePanel: true,
      subMenu: onMobileView ? undefined : favourites,
      isActive: activeMenu === 'sustainability',
      hideOnMobile: true,
    },
    {
      name: 'settings',
      id: 'settings',
      icon: 'lnr lnr-cog',
      togglePanel: true,
      isActive: activeMenu === 'settings',
      hideOnMobile: true,
    },
  ]

  const setSelectedKeys = useCallback(() => {
    if (location.pathname.match(/settings.*/)) {
      return ['settings']
    }

    return [location.pathname.split('/dashboard/')[1]]
  }, [location.pathname])

  const onMenuItemClick = useCallback(
    (e, payload) => {
      if (payload.id) {
        setActiveMenu(payload.id)
      }

      if (!payload.togglePanel) {
        handlePanelOpen(false)
        return
      }

      if (isPanelOpen) {
        if (activeMenu === payload.id) {
          handlePanelOpen(false)
        }
      } else {
        handlePanelOpen()
      }
    },
    [isPanelOpen, handlePanelOpen, activeMenu]
  )

  const onDashboardClick = useCallback(() => {
    handlePanelOpen(false)
  }, [handlePanelOpen])

  return (
    <MenuBarStyled className="MenuBar" status={panelStatus} ref={element}>
      <div className="MenuBar__wrapper">
        <MainMenu
          menu={menu || defaultMenu}
          activeMenu={activeMenu}
          onMenuItemClick={onMenuItemClick}
          screen={theme.screenSize.screen.current}
        />
      </div>
      <MenuPanel
        name={activeMenu}
        width="20vw"
        togglePanelWidth={handlePanelOpen}
        isActive={isPanelOpen}
        screen={theme.screenSize.screen.current}
        hideOnMobile
      >
        {activeMenu === 'dashboard' && (
          <DashboardsMenu
            dashboards={dashboards}
            setSelectedKeys={setSelectedKeys}
            onItemClick={onDashboardClick}
            onClose={refetch}
          />
        )}
        {activeMenu === 'settings' && (
          <SettingsMenu setSelectedKeys={setSelectedKeys} baseUrl="/settings" />
        )}
        {activeMenu === 'sustainability' && (
          <SustainabilityMenu setSelectedKeys={setSelectedKeys} baseUrl="/sustainability" />
        )}
      </MenuPanel>
    </MenuBarStyled>
  )
}

export default memo(MenuBar)
