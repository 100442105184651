import React from 'react'

import { Radio } from 'antd'
import get from 'lodash/get'
import { Controller } from 'react-hook-form'

import Field from 'components/Form/components/Field'
import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'

const RadioGroupField = ({
  name,
  value,
  label,
  options,
  defaultValue,
  onChange,
  showRequired,
  viewRender,
  editRender,
  validation,
  optionType,
  buttonStyle,
  size,
  ...fieldProps
}) => {
  return (
    <Field {...fieldProps} label={label} required={showRequired && validation?.required}>
      {({ errors, isEditMode, watch, loading }) => {
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value

        const error = get(errors, name)

        if (loading) {
          return <Skeleton width="200px" />
        }

        if (isEditMode) {
          const field = (
            <Controller
              defaultValue={defaultValue}
              name={name}
              rules={validation}
              render={({ onChange: controllerOnChange, value: controllerValue }) => {
                const handleOnChange = (event) => {
                  const value = event.target.value
                  controllerOnChange(value)
                  onChange && onChange(value)
                }

                const elementProps = {
                  onChange: handleOnChange,
                  value: controllerValue,
                  options,
                  optionType,
                  buttonStyle,
                  size,
                }

                return <Radio.Group {...elementProps} />
              }}
            />
          )

          const message = <FieldMessage message={error} label={label} />

          return editRender ? editRender({ field, message }) : [field, message]
        }

        const renderValue =
          options?.find((option) => option.value === fieldValue)?.label ?? fieldValue

        return viewRender ? viewRender(fieldValue) : <span>{renderValue}</span>
      }}
    </Field>
  )
}

export default RadioGroupField
