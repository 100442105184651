import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import get from 'lodash/get'

import { DATAPOINT_DETAILS_QUERY } from 'services/datapoints'
import { useLazyNonAssetEntities } from 'services/entities'
import {
  useMobileCombustionVehiclesForOrganisation,
  useOrganisations,
  usePurchasedEnergyMetersForOrganisation,
  useRefrigerantEquipmentsForOrganisation,
  useStationaryCombustionMetersForOrganisation,
} from 'services/organisations'
import { useCurrentCustomer } from 'services/store'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import { useFilters } from 'components/Form/components/ValuePicker/hooks'
import { useDatapointHover } from 'components/Global/FormField/Datapoints/functions'
import {
  getDatapointsForCountryUpdate,
  getDatapointsForNonCountryUpdate,
  getEntityDatapointsForTags,
  getEntityVirtualDatapoint,
  getFilteredDatapointsForSelectedTags,
  getQueryDatapoint,
  getQueryPayload,
  getTaggedDatapointForEntity,
  getTaggedDatapointsForCountry,
} from 'components/Global/FormField/GhgDataSelector/functions'

const getOrganisationObject = (organisationId, organisations = []) => {
  return organisations.find(({ id }) => id === organisationId)
}

const formatTags = (tags = []) => {
  return tags ? tags.reduce((tags, tag) => [...tags, ...Object.entries(tag)], []) : []
}

export const useGhgData = ({
  dataKey,
  groupDataKey,
  singleDatapoint,
  fixedUnit,
  subPath,
  groupId,
}) => {
  const customer = useCurrentCustomer()
  const { data: organisationData } = useOrganisations({ isCore: true })

  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()
  const [
    getStationaryCombustionMeters,
    { data: stationaryCombustionMeters },
  ] = useStationaryCombustionMetersForOrganisation()
  const [
    getPurchasedEnergyMeters,
    { data: purchasedEnergyDatapoints },
  ] = usePurchasedEnergyMetersForOrganisation()

  const [
    getMobileCombustionVehicles,
    { data: mobileCombustionVehicles },
  ] = useMobileCombustionVehiclesForOrganisation()

  const [
    getRefrigerantEquipments,
    { data: refrigerantEquipments },
  ] = useRefrigerantEquipmentsForOrganisation()

  const [getNonAssetEntities, { data: nonAssetEntities }] = useLazyNonAssetEntities()

  const [getDatapointDetails, { data }] = useLazyQuery(DATAPOINT_DETAILS_QUERY)

  const [selectedScope, setSelectedScope] = useState(null)
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [datapoints, setDatapoints] = useState([])
  const [datapointsUnit, setDatapointsUnit] = useState(null)
  const [resetTags, setResetTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const queryName = useRef()
  const cat3 = useRef()

  const organisationId =
    elementInEditMode.preview.organisationId && parseInt(elementInEditMode.preview.organisationId)

  const { onSearch, searchValue, data: filteredDatapoints } = useFilters(
    datapoints,
    null,
    {},
    'name'
  )

  const query = useMemo(
    () => ({
      getStationaryCombustionMeters,
      getPurchasedEnergyMeters,
      getMobileCombustionVehicles,
      getRefrigerantEquipments,
      getNonAssetEntities,
    }),
    [
      getStationaryCombustionMeters,
      getPurchasedEnergyMeters,
      getMobileCombustionVehicles,
      getRefrigerantEquipments,
      getNonAssetEntities,
    ]
  )

  const datapointsData = useMemo(() => {
    const data = {
      getStationaryCombustionMeters:
        stationaryCombustionMeters?.stationaryCombustionMetersForOrganisation,
      getPurchasedEnergyMeters: purchasedEnergyDatapoints?.purchasedEnergyMetersForOrganisation,
      getMobileCombustionVehicles:
        mobileCombustionVehicles?.mobileCombustionVehiclesForOrganisation,
      getNonAssetEntities: nonAssetEntities,
      getRefrigerantEquipments: refrigerantEquipments?.refrigerantEquipmentsForOrganisation,
    }[queryName.current]

    if (data) {
      return [...new Map(data.map((v) => [v.id, v])).values()]
    }
    return []
  }, [
    stationaryCombustionMeters,
    purchasedEnergyDatapoints,
    mobileCombustionVehicles,
    nonAssetEntities,
    refrigerantEquipments,
  ])

  const { selectedGroups, selectedDatapoints } = useMemo(() => {
    const selectedGroups =
      get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}Groups`) || []
    return {
      selectedGroups,
      selectedDatapoints: [
        ...selectedGroups.filter(({ id }) => id?.includes('country')),
        ...get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`),
      ],
    }
  }, [elementInEditMode.preview, subPath, dataKey])

  const onSelectTags = useCallback(
    (value, entity) => {
      const filteredDatapoints = entity.sites
        ? []
        : getEntityDatapointsForTags({
            entity,
            datapoints: datapointsData,
            tags: value,
            scope: selectedScope,
            organisationId,
          })

      setDatapoints([
        ...getFilteredDatapointsForSelectedTags({
          datapoints: datapointsData,
          selectedTags: value,
          scope: selectedScope,
          entity,
          organisationId,
          cat3: cat3.current,
        }),
        ...(value?.length
          ? [
              entity.sites
                ? getTaggedDatapointsForCountry({
                    tags: value,
                    entity,
                    scope: selectedScope,
                    organisationId,
                  })
                : getTaggedDatapointForEntity({
                    tags: value,
                    entity,
                    scope: selectedScope,
                    organisationId,
                    customer,
                  }),
            ]
          : [getEntityVirtualDatapoint(selectedScope, entity, customer, cat3)]),
        ...filteredDatapoints,
      ])
      setSelectedTags(value)
    },
    [customer, datapointsData, organisationId, selectedScope]
  )

  const onSelectEntity = useCallback(
    (entity, entityCat3, preventQuery) => {
      if (!entity) {
        return setSelectedEntity(entity)
      }
      cat3.current = entityCat3
      queryName.current = entityCat3?.query || selectedScope?.query
      const queryPayload =
        !preventQuery && getQueryPayload(entityCat3?.scopeId || selectedScope?.id, entity)
      const virtualDatapoint = getEntityVirtualDatapoint(
        selectedScope,
        entity,
        customer,
        entityCat3
      )
      if (virtualDatapoint) {
        setDatapoints((state) =>
          state.some(({ id }) => id === virtualDatapoint.id) ? state : [virtualDatapoint]
        )
      }

      if (queryPayload) {
        query[queryName.current]({
          variables: {
            ...queryPayload,
            organisationId,
          },
        })
      }
      if (selectedTags.length) {
        onSelectTags(selectedTags, entity)
      }
      setSelectedEntity(entity)
      if (entity.tags) {
        setResetTags(formatTags(entity.tags))
      }
    },
    [customer, onSelectTags, organisationId, query, selectedScope, selectedTags]
  )

  useEffect(() => {
    setSelectedEntity(null)
  }, [customer])

  useEffect(() => {
    if (organisationId && selectedScope && organisationData && customer) {
      onSelectEntity(getOrganisationObject(organisationId.toString(), organisationData), null, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope, organisationId])

  useEffect(() => {
    setDatapointsUnit(data?.datapoints[0] ? data.datapoints[0].rawUnit : null)
  }, [data])

  useEffect(() => {
    if (fixedUnit && selectedDatapoints.length) {
      if (selectedDatapoints[0].unit) {
        return setDatapointsUnit(selectedDatapoints[0].unit)
      }
      return getDatapointDetails({
        variables: {
          datapoints: [
            {
              id: selectedDatapoints[0].id,
            },
          ],
        },
      })
    }
    setDatapointsUnit(null)
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatapoints])

  useEffect(() => {
    if (datapointsData) {
      setDatapoints((state) => {
        return datapointsData.reduce(
          (datapoints, datapoint) => {
            if (!state.some(({ id }) => id === datapoint.id)) {
              datapoints.push(
                getQueryDatapoint({
                  datapoint,
                  scope: selectedScope,
                  entity: selectedEntity,
                  organisationId,
                  cat3: cat3.current,
                })
              )
            }
            return datapoints
          },
          [...state]
        )
      })
    }
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datapointsData, datapointsUnit])

  const onSelectScope = (scope) => {
    if (scope) {
      queryName.current = scope.query
      setSelectedScope(scope)
      setResetTags(scope.tags ? formatTags(scope.tags) : [])
      setDatapoints([])
      setSelectedTags([])
    }
  }

  const onSelectDatapoints = useCallback(
    (values, addedItems, addedItem) => {
      const isCountryUpdate = selectedEntity?.sites
      const { datapointsToSave, groups, added } = isCountryUpdate
        ? getDatapointsForCountryUpdate({
            values,
            selectedScope,
            organisationId,
            dataKey,
            selectedDatapoints,
            groupId,
            addedItem,
          })
        : getDatapointsForNonCountryUpdate({
            values,
            selectedScope,
            organisationId,
            selectedDatapoints,
            groupId,
            addedItem,
          })

      if (isCountryUpdate) {
        action({
          type: 'ON_PREVIEW_UPDATE',
          payload: {
            graph: { dataKey: `${dataKey}Groups`, subPath },
            value: groups.reduce((groups, group) => {
              groups = groups.some(({ id }) => id === group.id)
                ? groups.filter(({ id }) => id === group.id)
                : groups.concat(group)
              return groups
            }, selectedGroups),
          },
        })
      }

      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: { dataKey, subPath },
          value:
            singleDatapoint && selectedDatapoints
              ? datapointsToSave[0]
                ? [datapointsToSave[0]]
                : []
              : datapointsToSave,
          meta: {
            groupDataKey: groupDataKey,
            removed: isCountryUpdate
              ? []
              : selectedDatapoints.filter(({ id }) => !datapointsToSave.some((dp) => dp.id === id)),
            added: isCountryUpdate ? [] : added,
          },
        },
      })
    },
    [
      action,
      dataKey,
      groupDataKey,
      organisationId,
      selectedDatapoints,
      selectedEntity?.sites,
      selectedGroups,
      selectedScope,
      singleDatapoint,
      subPath,
      groupId,
    ]
  )

  const onSelectOrganisation = (value) => {
    if (selectedScope.id === '0') {
      onSelectEntity(getOrganisationObject(value, organisationData), selectedScope, customer)
    }
    action({
      type: 'ON_PREVIEW_UPDATE',
      payload: {
        graph: { dataKey: 'organisationId', subPath },
        value,
      },
    })
  }

  const onDrawerCancel = () => {
    setSelectedEntity(null)
    action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: false,
        dataKey: 'isOpen',
      },
    })
  }

  const onDrawerSave = () => {
    setSelectedEntity(null)
    action({
      type: 'ON_BOTTOM_DRAWER_UPDATE',
      payload: {
        value: {
          isOpen: false,
          width: 'full',
        },
      },
    })
  }

  const { hoveredElement, detail, onDatapointHover } = useDatapointHover()
  const allDatapointsSelected = selectedDatapoints.length === filteredDatapoints.length

  return {
    selectedScope,
    filteredDatapoints,
    onSelectScope,
    onSelectOrganisation,
    onSelectTags,
    onSelectDatapoints,
    onSelectEntity,
    hoveredElement,
    detail,
    onDatapointHover,
    allDatapointsSelected,
    onDrawerSave,
    onDrawerCancel,
    organisationData,
    selectedEntity,
    elementInEditMode,
    selectedDatapoints,
    onSearch,
    searchValue,
    resetTags,
    organisationId: elementInEditMode.preview.organisationId,
  }
}
