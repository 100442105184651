import Highcharts from 'highcharts'

import { generateSvgFromIconName } from 'util/fontAwesomeFunctions'
import round from 'util/round'

export const chartOptions = ({ trackColor, color, series = [] }) => ({
  title: undefined,
  chart: {
    backgroundColor: 'transparent',
  },
  pane: {
    startAngle: 0,
    endAngle: 360,
    size: '100%',
    background: [
      {
        innerRadius: '78%',
        outerRadius: '90%',
        backgroundColor: trackColor || Highcharts.color(color).setOpacity(0.1).get(),
        borderWidth: 0,
      },
    ],
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    lineWidth: 0,
    min: 0,
    tickPositions: [],
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        borderWidth: 0,
        useHTML: true,
        allowOverlap: true,
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: true,
    },
  },
  credits: {
    enabled: false,
  },
  series,
})

export const getSerie = (
  { color, value, showInvertedValues, showPercentage, percentage, unit, max, caption, icon },
  localizeNumber
) => {
  const svgTag = generateSvgFromIconName(icon)

  return {
    type: 'solidgauge',
    data: [
      {
        color: color,
        radius: '90%',
        innerRadius: '78%',
        y: value,
      },
    ],
    tooltip: {
      valueSuffix: unit,
    },
    dataLabels: {
      enabled: true,
      verticalAlign: 'middle',
      borderWidth: 0,
      backgroundColor: 'none',
      shadow: false,
      style: {},
      formatter: function () {
        const value = localizeNumber(showInvertedValues ? max - this.y : this.y)
        return `
          <div class="data-labels">
            <div class="data-label-container">
              ${
                caption.length
                  ? `
                  <div class="data-label" style="text-align:center;">
                    <span class="value">${value}</span>
                    <span class="unit">${unit}</span>
                    ${icon && !showPercentage && svgTag !== '' ? `${svgTag}` : ''}
                  </div>
                  <span class="CircleGauge__caption-text">${caption}</span>
                `
                  : `
                  <div class="data-label" style="text-align:center;">
                    <span class="value">${value}</span>
                    <span class="unit">${unit}</span>
                    ${icon && !showPercentage && svgTag !== '' ? `${svgTag}` : ''}
                   </div>
                `
              }
            </div>
            ${
              showPercentage
                ? `
              <div class="data-label-container">
                ${
                  caption.length
                    ? `<span class="CircleGauge__caption-text">${caption} %</span>`
                    : ''
                }
                <div class="data-label" style="text-align:center;"><span class="value">${
                  localizeNumber(round(percentage), 0) || 0
                }</span> %${icon && svgTag !== '' ? ` ${svgTag}` : ''}
                </h5>
              </div>
            `
                : ''
            }
          </div>
        `
      },
    },
  }
}
