import React from 'react'

import WidgetErrorStyled from './styled'

const WidgetError = ({ error, children }) => {
  if (!error) {
    return null
  }
  const icon = {
    error: 'fas fa-circle-xmark',
    warning: 'fas fa-triangle-exclamation',
    info: 'fas fa-circle-info',
  }
  return (
    <WidgetErrorStyled
      className="WidgetError"
      label={error.message || (typeof error === 'string' && error)}
      icon={icon[error.level || 'error']}
      type={error.level || 'error'}
      loading
      spinner={false}
    >
      {children}
    </WidgetErrorStyled>
  )
}

export default WidgetError
