import React, { useState } from 'react'

import ReceiverContent from 'components/Form/components/DndMapper/components/ReceiverContent'
import { ReceiverStyled } from 'components/Form/components/DndMapper/components/styled'

const Receiver = ({ caption, valuesToMatchTo, handleRemove, handleAdd }) => {
  // eslint-disable-next-line no-unused-vars
  const [currentValue, setCurrentValue] = useState([{ key: 1 }])
  const [currentField, setCurrentField] = useState('')
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggedOver(true)
  }

  const onDragLeave = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggedOver(false)
  }

  const handleDrop = (source) => {
    source.preventDefault()
    if (currentField !== '') {
      handleAdd(currentField, valuesToMatchTo[0].dataIndex)
    }
    setCurrentValue([{ key: 1, [valuesToMatchTo[0].dataIndex]: source.dataTransfer.getData('id') }])
    setCurrentField(source.dataTransfer.getData('id'))
    handleRemove(
      source.dataTransfer.getData('id'),
      valuesToMatchTo[0].dataIndex,
      valuesToMatchTo[0].type
    )
  }

  return (
    <ReceiverStyled
      title={caption}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onClick={() => {
        if (currentField !== '') {
          handleAdd(currentField, valuesToMatchTo[0].dataIndex)
          setCurrentValue([{ key: 1 }])
          setCurrentField('')
        }
      }}
      onDrop={(source) => handleDrop(source)}
    >
      <ReceiverContent
        label={valuesToMatchTo && valuesToMatchTo[0].title}
        value={currentField}
        isDraggedOver={isDraggedOver}
        isMapped={!!currentField}
      />
    </ReceiverStyled>
  )
}

export default Receiver
