import { dashStyles, defaultStartTimeUnits } from 'components/Dashboard/Widget/config/selections'

import { configFieldConvertor } from '../../../Dashboard/utils/common/helpers'
import { config as graphCard } from './../GraphCard/config'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.historicalComparisonChartWidget',
    category: 'widget.charts',
    keywords: ['chart', 'trend', 'historical'],
    img: '/resources/images/widgets/historicalcomparisongraph.png',
    info: {
      'en-US':
        '<h2>Historical Comparison Trend Widget</h2>\n' +
        '<p>The Historical Comparison Trend widget displays datapoint values on a time trend and adds historical trends for the same datapoint and time span.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '<li><strong>Opacity</strong> Determine the opacity of the trend charts</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groups</strong> Add or select an existing group of datapoints</li>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Datapoint Totals</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint Totals</strong> Select widget datapoints fot the totals and for each datapoint its label and whether or not to calculate the delta value</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Granularity</strong> Select the granularity of the datapoint values</li>\n' +
        '</ul>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Fixed Values</strong> Create a fixed value to display on the widget, with unit and color</li>\n' +
        '</ul>\n' +
        '<h3>Group Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Selected Group</strong> This section displays all existing groups and allows to select new datapoints for it</li>\n' +
        '<li><strong>Ungrouped Datapoints</strong> This section displays all selected, but ungrouped datapoints. They can be added to the selected group by clicking on them.</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the group</li>\n' +
        '<li><strong>Color</strong> Select a color for the group</li>\n' +
        '<li><strong>Style</strong> Select a line style for the group</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method for the group</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Color</strong> Select a color for the datapoint</li>\n' +
        '<li><strong>Use Steps</strong> Display the trend in a step wise manner</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Offsets</strong> Add one or more historical trend lines for the selected datapoint by selecting a value, unit, linestyle and color</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Historische vergelijkingstrendwidget</h2>\n' +
        '<p>De widget Historische vergelijkingstrend geeft gegevenspuntwaarden weer op basis van een tijdtrend en voegt historische trends toe voor hetzelfde gegevenspunt en dezelfde tijdspanne.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '<li><strong>Dekking</strong> Bepaal de dekking van de trendgrafieken</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groepen</strong> Een bestaande groep datapunten toevoegen of selecteren</li>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunttotalen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunttotalen</strong> Selecteer widgetgegevenspunten voor de totalen en voor elk gegevenspunt het bijbehorende label en of de deltawaarde al dan niet moet worden berekend</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Granulariteit</strong> Selecteer de granulariteit van de gegevenspuntwaarden</li>\n' +
        '</ul>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Vaste waarden</strong> Maak een vaste waarde om op de widget weer te geven, met eenheid en kleur</li>\n' +
        '</ul>\n' +
        '<h3>Groepsconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Geselecteerde groep</strong> In deze sectie worden alle bestaande groepen weergegeven en kunt u er nieuwe datapunten voor selecteren</li>\n' +
        '<li><strong>Niet-gegroepeerde gegevenspunten</strong> In deze sectie worden alle geselecteerde, maar niet-gegroepeerde gegevenspunten weergegeven. Ze kunnen aan de geselecteerde groep worden toegevoegd door erop te klikken.</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor de groep</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor de groep</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor de groep</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode voor de groep</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor het datapunt</li>\n' +
        '<li><strong>Gebruik stappen</strong> Geef de trend stapsgewijs weer</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Offsets</strong> Voeg een of meer historische trendlijnen toe voor het geselecteerde datapunt door een waarde, eenheid, lijnstijl en kleur te selecteren</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Widget de tendance de comparaison historique</h2>\n' +
        '<p>Le widget Tendance de comparaison historique affiche les valeurs des points de données sur une tendance temporelle et ajoute des tendances historiques pour le même point de données et la même période.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        "<li><strong>Opacité</strong> Déterminer l'opacité des graphiques de tendance</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groupes</strong> Ajoutez ou sélectionnez un groupe existant de points de données</li>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Totaux des points de données</h4>\n' +
        '<ul>\n' +
        "<li><strong>Totaux des points de données</strong> Sélectionnez les points de données du widget pour les totaux et pour chaque point de données son étiquette et s'il faut ou non calculer la valeur delta</li>\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Granularité</strong> Sélectionnez la granularité des valeurs des points de données</li>\n' +
        '</ul>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Valeurs fixes</strong> Créez une valeur fixe à afficher sur le widget, avec unité et couleur</li>\n' +
        '</ul>\n' +
        '<h3>Configuration de groupe</h3>\n' +
        '<ul>\n' +
        '<li><strong>Groupe sélectionné</strong> Cette section affiche tous les groupes existants et permet de sélectionner de nouveaux points de données</li>\n' +
        '<li><strong>Points de données non groupés</strong> Cette section affiche tous les points de données sélectionnés, mais non groupés. Ils peuvent être ajoutés au groupe sélectionné en cliquant dessus.</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le groupe</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le groupe</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le groupe</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul pour le groupe</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le point de données</li>\n' +
        '<li><strong>Utiliser les étapes</strong> Afficher la tendance par étapes</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Décalages</strong> Ajoutez une ou plusieurs lignes de tendance historiques pour le point de données sélectionné en sélectionnant une valeur, une unité, un style de ligne et une couleur</li>\n' +
        '</ul>',
    },
    default: {
      ...graphCard.editProps.default,
      type: 'HistoricalComparisonGraph',
      title: 'widget.historicalComparisonChartWidget',
    },
    unUsedDatapointProperties: ['rangeId'],
    preview: {
      icon: 'fas fa-chart-area',
      chart: {
        series: [
          {
            color: '#e80a0a',
            data: [
              [0, 1],
              [1, 7],
              [2, 3],
              [3, 10],
              [4, 1],
              [5, 2],
            ],
            type: 'area',
            name: 'Previous period',
          },
          {
            color: '#2A66C9',
            data: [
              [0, 2],
              [1, 4],
              [2, 12],
              [3, 5],
              [4, 2],
              [5, 1],
            ],
            type: 'area',
            name: 'Current period',
          },
        ],
      },
    },
    fields: configFieldConvertor(graphCard.editProps.fields, [
      {
        selector: (field) =>
          field.dataKey === 'showTotals' || field.category === 'widget.datapointTotals',
        resolver: () => 'exclude',
      },
      {
        selector: (field) => field.componentName === 'DatapointGroups',
        resolver: (field) => ({
          ...field,
          componentProps: {
            ...field.componentProps,
            section: {
              ...field.componentProps.section,
              componentProps: {
                ...field.componentProps.section.componentProps,
                fields: configFieldConvertor(field.componentProps.section.componentProps.fields, [
                  {
                    selector: (field) => field.dataKey === 'useSteps',
                    resolver: (field) => [
                      field,
                      {
                        category: 'widget.interval',
                        componentName: 'CalculationMethod',
                        dataKey: '',
                        componentProps: {
                          dataKey: 'calculationMethod',
                          addRangeMethod: true,
                        },
                      },
                      {
                        category: 'widget.interval',
                        componentName: 'Table',
                        label: { formatted: 'widget.offsets' },
                        dataKey: 'offsets',
                        componentProps: {
                          columns: [
                            {
                              label: { formatted: 'widget.value' },
                              dataKey: 'value',
                              componentName: 'NumberInput',
                              width: 10,
                            },
                            {
                              label: { formatted: 'widget.unit' },
                              dataKey: 'unit',
                              componentName: 'ValuePicker',
                              componentProps: {
                                options: defaultStartTimeUnits,
                                selectionKeys: {
                                  value: 'value',
                                  label: 'label',
                                },
                                search: false,
                              },
                              width: 10,
                            },
                            {
                              label: { formatted: 'widget.method' },
                              dataKey: '',
                              componentName: 'CalculationMethod',
                              componentProps: {
                                hideLabel: true,
                                component: 'ValuePicker',
                                addRangeMethod: true,
                              },
                              width: 10,
                            },
                            {
                              label: { formatted: 'widget.style' },
                              dataKey: 'dashStyle',
                              componentName: 'ValuePicker',
                              componentProps: {
                                options: dashStyles,
                                selectionKeys: {
                                  value: 'value',
                                  label: 'label',
                                },
                                search: false,
                              },
                              width: 10,
                            },
                            {
                              label: { formatted: 'widget.color' },
                              dataKey: 'color',
                              componentName: 'ColorPicker',
                              width: 10,
                            },
                          ],
                          defaultRowValue: {
                            unit: 'month',
                            value: 1,
                            dashStyle: 'Solid',
                            color: variables.color.color.aviary.main,
                          },
                        },
                      },
                    ],
                  },
                  {
                    selector: (field) => field.componentName === 'CalculationMethod',
                    resolver: () => 'exclude',
                  },
                ]),
              },
            },
            groupSection: {
              ...field.componentProps.groupSection,
              componentProps: {
                ...field.componentProps.groupSection.componentProps,
                fields: configFieldConvertor(
                  field.componentProps.groupSection.componentProps.fields,
                  [
                    {
                      selector: (field) => field.dataKey === 'name',
                      resolver: (field) => ({
                        ...field,
                        dataKey: 'caption',
                      }),
                    },
                    {
                      selector: (field) => field.dataKey === 'method',
                      resolver: (field) => [
                        field,
                        {
                          category: 'widget.interval',
                          componentName: 'CalculationMethod',
                          dataKey: '',
                          componentProps: {
                            dataKey: 'calculationMethod',
                            addRangeMethod: true,
                          },
                        },
                        {
                          category: 'widget.interval',
                          componentName: 'Table',
                          label: { formatted: 'widget.offsets' },
                          dataKey: 'offsets',
                          componentProps: {
                            columns: [
                              {
                                label: { formatted: 'widget.value' },
                                dataKey: 'value',
                                componentName: 'NumberInput',
                                width: 10,
                              },
                              {
                                label: { formatted: 'widget.unit' },
                                dataKey: 'unit',
                                componentName: 'ValuePicker',
                                componentProps: {
                                  options: defaultStartTimeUnits,
                                  selectionKeys: {
                                    value: 'value',
                                    label: 'label',
                                  },
                                  search: false,
                                },
                                width: 10,
                              },
                              {
                                label: { formatted: 'widget.method' },
                                dataKey: '',
                                componentName: 'CalculationMethod',
                                componentProps: {
                                  difference: false,
                                  hideLabel: true,
                                  component: 'ValuePicker',
                                  parentDataKey: 'datapointsGroups',
                                  addRangeMethod: true,
                                },
                                width: 10,
                              },
                              {
                                label: { formatted: 'widget.style' },
                                dataKey: 'dashStyle',
                                componentName: 'ValuePicker',
                                componentProps: {
                                  options: dashStyles,
                                  selectionKeys: {
                                    value: 'value',
                                    label: 'label',
                                  },
                                  search: false,
                                },
                                width: 10,
                              },
                              {
                                label: { formatted: 'widget.color' },
                                dataKey: 'color',
                                componentName: 'ColorPicker',
                                width: 10,
                              },
                            ],
                            defaultRowValue: {
                              unit: 'month',
                              value: 1,
                              dashStyle: 'Solid',
                              color: variables.color.color.aviary.main,
                            },
                          },
                        },
                      ],
                    },
                    {
                      selector: (field) => field.componentName === 'CalculationMethod',
                      resolver: () => 'exclude',
                    },
                  ]
                ),
              },
            },
          },
        }),
      },
    ]),
  },
}

export default config
