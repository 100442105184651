import React, { useMemo } from 'react'

import Highcharts from 'highcharts'
import HCMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { merge } from 'lodash'
import { useTheme } from 'styled-components'

import {
  getComparatorValue,
  getDatapointsCalculatedValue,
  getOverUsePercentageString,
} from 'util/datapointCalculationFunctions'
import { useComparatorsPayload, useDatapointsForTimeRangePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks'
import { getDatapointErrors } from 'components/Widgets/functions'
import Ui from 'components/Widgets/Gauge/Ui'

import { useWidgetDatapoints } from '../../../../Dashboard/utils/assets/hooks'
import { chartOptions } from '../chartOptions'

HCMore(Highcharts)
SolidGauge(Highcharts)

const Gauge = ({
  datapoints = [],
  value,
  conversionUnit,
  minComparators,
  maxComparators,
  decimals = 2,
  colors,
  method = 'sum',
  minComparatorsMethod,
  maxComparatorsMethod,
  difference,
  timeRange,
  comparisonPeriod,
  overUse = false,
  showDelta = false,
  linkProps,
  dataProps = {},
  preview = { gauge: { series: [] } },
  newSocketValue,
  customLogic,
  defaultCalculationMethod,
  minComparatorsCalculationMethod,
  maxComparatorsCalculationMethod,
  comparatorUnit,
  dashboardLink,
  widgetTitle,
  showWidgetDetail,
  datapointsLinked,
  locationData,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps
  const {
    datapoints: widgetDatapoints,
    comparators: widgetComparators,
    maxComparators: widgetMaxComparators,
    minComparators: widgetMinComparators,
  } = useWidgetDatapoints({
    datapoints,
    minComparators,
    maxComparators,
    datapointsLinked,
    locationData,
  })
  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints: widgetDatapoints,
    timeRange,
    groupConversionUnit: conversionUnit,
    locationData,
  })

  const allMaxComparators = useMemo(() => [...widgetComparators, ...widgetMaxComparators], [
    widgetComparators,
    widgetMaxComparators,
  ])

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'gauge',
    },
    deps: [datapoints, locationData],
    skip: widgetDatapoints.length === 0,
  })

  const {
    values: minComparatorValues,
    comparatorDatapoints: minComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints: widgetDatapoints,
    comparators: widgetMinComparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
    locationData,
  })

  const { data: minComparatorsData, loading: minComparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: minComparatorDatapoints,
      widgetType: 'gaugeMinComparator',
    },
    deps: [minComparators, locationData],
    skip: minComparatorDatapoints.length === 0,
  })

  const {
    values: maxComparatorValues,
    comparatorDatapoints: maxComparatorDatapoints,
  } = useComparatorsPayload({
    datapoints: widgetDatapoints,
    comparators: allMaxComparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
    locationData,
  })

  const { data: maxComparatorsData, loading: maxComparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: maxComparatorDatapoints,
      widgetType: 'gaugeMaxComparator',
    },
    deps: [maxComparators, locationData],
    skip: maxComparatorDatapoints.length === 0,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const roundedMinimum = getComparatorValue({
    comparators: widgetMinComparators,
    comparatorValues: minComparatorValues,
    comparatorsData: minComparatorsData,
    method: minComparatorsMethod,
    decimals,
    calculationMethod: minComparatorsCalculationMethod,
    defaultValue: 0,
  })

  const roundedMaximum = getComparatorValue({
    comparators: allMaxComparators,
    comparatorValues: maxComparatorValues,
    comparatorsData: maxComparatorsData,
    method: maxComparatorsMethod,
    decimals,
    calculationMethod: maxComparatorsCalculationMethod,
    defaultValue: 100,
  })

  const validData = data?.data?.datapoints
  const overusePercentage = getOverUsePercentageString(
    validData,
    maxComparatorsData?.data?.datapoints[0],
    overUse
  )

  const { series, datapointChartProps, errors } = useMemo(() => {
    const errors = getDatapointErrors(validData)
    if (!validData?.length) {
      return isPreviewMode
        ? { series: error ? [] : preview.gauge.series, datapointChartProps: {}, errors }
        : { datapointChartProps: {}, errors }
    }
    const value = getDatapointsCalculatedValue(validData, {
      method,
      customLogic,
      decimals,
      calculationMethod: defaultCalculationMethod,
    })
    const displayUnit = validData[0].conversionFactor?.endUnit.name || validData[0].unit
    const series = [
      {
        type: 'solidgauge',
        data: [value],
        tooltip: {
          valueSuffix: displayUnit,
        },
        dataLabels: {
          y: overusePercentage.length ? 22 : 10,
          formatter: function () {
            return `<div class="data-labels" style="text-align:center;">
          ${overusePercentage}<br/>
          <span class="value">${localizeNumber(this.y) || 0}</span>
          <span class="unit">${displayUnit}</span>
         </div>`
          },
        },
      },
    ]
    const datapointChartProps = {
      yAxis: {
        min: roundedMinimum || 0,
        max: roundedMaximum || 100,
        tickPositions: [roundedMinimum, roundedMaximum || 0],
        labels: {
          formatter: function () {
            return `${localizeNumber(this.value) || 0} ${comparatorUnit || displayUnit}`
          },
        },
        stops: colors,
      },
    }
    return { series, datapointChartProps, errors }
  }, [
    validData,
    roundedMinimum,
    roundedMaximum,
    overusePercentage,
    colors,
    customLogic,
    decimals,
    preview.gauge.series,
    isPreviewMode,
    method,
    error,
    localizeNumber,
    defaultCalculationMethod,
    comparatorUnit,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })

  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <Ui
      {...props}
      className="SolidGauge"
      options={options}
      loading={{
        loading: loading || minComparatorsLoading || maxComparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default Gauge
