import React, { useEffect, useState } from 'react'

import CharacterCount from 'components/Global/FormField/TextArea/CharacterCount'

import TextAreaStyled from './styled'

const TextArea = ({ value, onChange, onBlur, maxLength, mode, rows = 5 }) => {
  const [inputValue, setTextAreaValue] = useState(value || '')

  useEffect(() => {
    setTextAreaValue(value || '')
  }, [value])

  const _onChange = (e) => {
    setTextAreaValue(e.target.value)
    onChange && onChange(e)
  }
  const _onBlur = (e) => {
    setTextAreaValue(e.target.value)
    onBlur && onBlur(e)
  }

  return (
    <TextAreaStyled className="TextArea">
      <textarea value={inputValue} onBlur={_onBlur} onChange={_onChange} rows={rows} />
      <CharacterCount value={inputValue} max={maxLength} mode={mode} />
    </TextAreaStyled>
  )
}

export default TextArea
