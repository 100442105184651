import { message } from 'antd'
import set from 'lodash/fp/set'

import {
  calculateParentHeights,
  getColumnTotal,
  getCurrentArrayValue,
  getGraphDataKey,
  getRowItemIndex,
  getUpdatedDataValue,
  reCalculateLayout,
} from './functions'

export const onWidgetAdd = (
  action,
  { data, layout, editModeContext, elementInEditMode, placeholder },
  dispatch
) => {
  const {
    payload: {
      target,
      source,
      duplicate,
      graph: { path, itemIndex, rowIndex, rowItemIndex, columns },
      container,
      intl,
    },
  } = action

  const currentLayout =
    editModeContext === 'Matrix'
      ? { sections: [elementInEditMode.preview.detailTemplate] }
      : data.layout

  if (target === 'first') {
    const value = set(
      getGraphDataKey(action.payload.graph.path, 'elements'),
      [placeholder],
      currentLayout
    )
    if (source === 'Matrix') {
      const path = action.payload.graph.path.replace('root.elements[0].', '')
      const equipments = elementInEditMode.preview.equipments.map((element) =>
        set(`detail.${path}.elements`, [{ datapoints: [] }], element)
      )

      return dispatch({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          rootUpdate: true,
          value: {
            ...elementInEditMode.preview,
            equipments,
            detailTemplate: { ...value.sections[0], h: 400 },
          },
          graph: {
            dataKey: null,
          },
        },
      })
    }
    return dispatch({
      type: 'UPDATE_UI',
      payload: {
        value,
      },
    })
  }

  const columnTotal = getColumnTotal(columns, layout)
  const { array, dataKey } = getCurrentArrayValue(currentLayout, path)
  const newItemIndex =
    target === 'col' ? itemIndex + 1 : getRowItemIndex(array, rowIndex, rowItemIndex, columnTotal)

  const newItem = duplicate
    ? {
        ...array[itemIndex],
        w: target === 'col' ? array[itemIndex].w : columnTotal,
      }
    : {
        ...placeholder,
        w: target === 'col' ? array[itemIndex].w : columnTotal,
        h: target === 'col' && array[itemIndex].h,
      }
  const { layout: newLayout, rowDiff } = reCalculateLayout({
    array: [...array.slice(0, newItemIndex), newItem, ...array.slice(newItemIndex)],
    selectedItem: {
      itemIndex: itemIndex,
      rowIndex: target === 'col' ? rowIndex : rowIndex + 1,
      rowItemIndex,
    },
    columns: columnTotal,
    direction: target,
    operation: 'add',
    containerHeight: container.clientHeight,
    intl,
  })

  let _value = getUpdatedDataValue(currentLayout, dataKey, newLayout, 'edit')

  if (target === 'row') {
    _value = calculateParentHeights(path, _value, rowDiff)
  }

  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value: _value,
      layoutUpdate: {
        operation: 'add',
        path,
        itemIndex,
      },
    },
  })
}
export const onWidgetDelete = (
  action,
  { data, layout, editModeContext, elementInEditMode },
  dispatch
) => {
  const {
    payload: {
      graph: { path, itemIndex, rowIndex, rowItemIndex, columns },
      container,
    },
  } = action
  const columnTotal = getColumnTotal(columns, layout)
  const currentLayout =
    editModeContext === 'Matrix'
      ? { sections: [elementInEditMode.preview.detailTemplate] }
      : data.layout

  const { array, dataKey } = getCurrentArrayValue(currentLayout, path)

  const { layout: newLayout } = reCalculateLayout({
    array,
    selectedItem: { itemIndex, rowIndex, rowItemIndex },
    columns: columnTotal,
    direction: array[itemIndex].w === columnTotal ? 'row' : 'col',
    operation: 'delete',
  })

  let _value = getUpdatedDataValue(currentLayout, dataKey, newLayout, 'delete', itemIndex)

  if (array[itemIndex].w === columnTotal && array[itemIndex].h < 100) {
    _value = calculateParentHeights(
      path,
      _value,
      -(container.clientHeight / 100) * array[itemIndex].h
    )
  }

  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value: _value,
      layoutUpdate: {
        operation: 'delete',
        path,
        itemIndex,
      },
    },
  })
}

export const onLayoutUpdate = (
  action,
  { data, layout, editModeContext, elementInEditMode },
  dispatch
) => {
  const {
    payload: {
      graph: { path, itemIndex, columns, rowIndex, rowItemIndex },
      direction,
      container,
      value,
      intl,
    },
  } = action
  const columnTotal = getColumnTotal(columns, layout)
  const colWidth = container.clientWidth / columnTotal
  const columnRatio = Math.round(value.width / colWidth)
  const rowHeight = Math.round(
    (value.height / (container.clientHeight + (value.height - value.prevHeight))) * 100
  )

  const currentLayout =
    editModeContext === 'Matrix'
      ? { sections: [elementInEditMode.preview.detailTemplate] }
      : data.layout

  const { array, dataKey } = getCurrentArrayValue(currentLayout, path)
  if (array[itemIndex].isLocked) {
    return message.error(intl.formatMessage({ id: 'widget.lockedWidgetResizeError' }))
  }
  const { layout: newArray, rowDiff } = reCalculateLayout({
    array,
    selectedItem: {
      itemIndex,
      rowIndex,
      rowItemIndex,
      w: columnRatio,
      h: rowHeight,
    },
    columns: columnTotal,
    direction: { horizontal: 'col', vertical: 'row', all: 'all' }[direction],
    operation: 'edit',
    itemHeight: value.height,
    itemPrevHeight: value.prevHeight,
    containerHeight: container.clientHeight,
    intl,
  })

  let _value = getUpdatedDataValue(currentLayout, dataKey, newArray, 'edit')

  if (direction === 'vertical' || direction === 'all') {
    _value = calculateParentHeights(path, _value, rowDiff)
  }

  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value: _value,
    },
  })
}

export const onValueUpdate = (action, { data, elementInEditMode }, dispatch) => {
  const previewValue = getUpdatedDataValue(
    elementInEditMode.preview,
    action.payload.graph.dataKey,
    action.payload.value,
    'edit'
  )

  dispatch({
    type: 'ON_VALUE_UPDATE_UI',
    payload: {
      value: previewValue,
    },
  })
}

export const onWidgetSave = (
  action,
  { data, elementInEditMode, editModeContext },
  dispatch,
  updateDashboard
) => {
  const value = getUpdatedDataValue(
    data.layout,
    getGraphDataKey(elementInEditMode.graph.path),
    elementInEditMode.preview,
    'edit'
  )
  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value,
      rootUpdate: editModeContext === 'Matrix',
    },
  })
  updateDashboard({
    variables: {
      id: parseInt(data.id),
      data: {
        layout: JSON.stringify(value),
      },
    },
  })
}

export const onRestoreSection = (action, { data, history }, dispatch, updateDashboard) => {
  const restoreValue = getUpdatedDataValue(
    data.layout,
    getGraphDataKey(action.payload.graph.path),
    history[action.payload.graph.path],
    'edit'
  )
  const value = {
    ...restoreValue,
    sections: restoreValue.sections.filter((section) => !!section),
  }
  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value,
    },
  })
  updateDashboard({
    variables: {
      id: parseInt(data.id),
      data: {
        layout: JSON.stringify(value),
      },
    },
  })
}

export const onDeleteSection = (action, { data, history }, dispatch, updateDashboard) => {
  const value = {
    ...data.layout,
    sections: [
      ...data.layout.sections.slice(0, action.payload.sectionIndex),
      ...data.layout.sections.slice(action.payload.sectionIndex + 1),
    ],
  }
  dispatch({
    type: 'UPDATE_UI',
    payload: {
      value: value,
    },
  })
  updateDashboard({
    variables: {
      id: parseInt(data.id),
      data: {
        layout: JSON.stringify(value),
      },
    },
  })
}
