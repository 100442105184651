import React from 'react'

import NumberInputStyled from './styled'

const NumberInput = ({ value, onChange, onBlur }) => {
  const handleChange = (value) => {
    onChange && onChange(value)
  }
  const handleBlur = (e) => {
    onBlur && onBlur(e.target.value)
  }

  return (
    <NumberInputStyled
      className="NumberInput"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

export default NumberInput
