import { css } from 'styled-components'

export const NavigationWidget = css`
  transition: opacity 0.2s ease, transform 0.2s ease, opacity 0.2s ease, background 0.2s ease;
  margin: 0.2rem 0.5rem 0.3rem 0;
  max-width: 100%;
  width: fit-content;
  white-space: nowrap;
  padding: 0 0.5rem 0 0 !important;
  height: 100%;

  > .Icon {
    display: flex;
    align-items: center;
    padding: 0 0 0 0.2rem;
    margin-right: 0.5rem;
    &.type-fa {
      color: ${({ theme }) => theme.color.backgroundFront};
      background: ${({ theme, color }) => color || theme.color.main};
      border-radius: 0.3rem;
      width: 1.8rem;
      min-width: 1.8rem;
      height: 1.8rem;

      svg {
        width: 60%;
        height: 60%;
      }
    }
    &.type-img {
      padding: 0.2rem !important;
    }
  }
  &:hover {
    transform: scale(1.05);
    opacity: 1;
    .Button__label {
      opacity: 1;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ color, theme }) => color || theme.color.softText};
      color: rgba(255, 255, 255, 0.9) !important;
      &:hover {
        color: ${({ color, theme }) => color || theme.color.softText} !important;
      }
    `}
  .Button__label {
    transition: opacity 0.2s ease;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    order: 1;
    opacity: 0.8;
  }
`
