import dayjs from 'dayjs'
import { transparentize } from 'polished'
import uniqid from 'uniqid'

import { CALCULATIONS, HIGHCHARTS_DASH_STYLES } from '../../../../util/constants'
import { getGranularityForTimeRange } from '../../components/TimePicker/functions'
import {
  calculateMinMax,
  getConstantsMinMax,
  getConstantValues,
  getConversionHistory,
  getDefaultColorByIndex,
  getGroupProperties,
  getTargetHistory,
  getZones,
  historyToHighchartsSeries,
  rangesToHighchartsSeries,
  translateHistoryLabel,
} from '../common/helpers'

const getDatapointTargetHistory = (targetData, targetDetails, forecast) => {
  const datapointForecast = forecast?.[0] && forecast
  return targetData && datapointForecast
    ? getTargetHistory({
        ...targetDetails,
        trendStart: datapointForecast[0].time,
        targetStartValue: datapointForecast[0].value || 0,
        targetEndValue: datapointForecast[1]?.value || 0,
        targetPercentage: null,
        offset: false,
      })
    : []
}

const getDataForDatapoint = ({ datapointsData, datapoint, group, method, rangeId }) => {
  const check = (dp) => {
    if (dp.tag) {
      return false
    }
    if (dp.id !== datapoint) {
      return false
    }
    if (rangeId && dp.rangeId !== rangeId) {
      return false
    }
    if (method && dp.calculationMethod !== method) {
      return false
    }
    if (group) {
      return dp.groupId === group.id
    }
    return !dp.groupId
  }
  return datapointsData.find((dp) => check(dp)) || {}
}

const getTargetForDatapoint = ({ targetData, targetDetails, datapoint }) => {
  const { id, caption, name, targetPercentage, targetValue, color } = datapoint
  const { unit, rawUnit, history } = getDataForDatapoint({
    datapointsData: targetData.datapoints,
    datapoint: id,
  })
  const targetStartValue = history?.[0]?.value || 0
  const targetHistory = getTargetHistory({
    targetStartValue,
    ...targetDetails,
    targetPercentage: targetPercentage || targetDetails.targetPercentage,
    targetEndValue: targetValue || targetDetails.targetEndValue,
    source: 'target',
  })

  return {
    caption: `${caption || name} - Target`,
    id: `${id}-target`,
    dashStyle: 'Dash',
    opacity: 0,
    color: color && transparentize(0.5, color),
    lineWidth: 1,
    history: targetHistory,
    unit,
    rawUnit,
  }
}

const getForecastZones = (forecast) => {
  return forecast?.[0]?.time
    ? {
        zoneAxis: 'x',
        zones: [
          {
            value: forecast[0].time,
          },
          { dashStyle: 'dot' },
        ],
      }
    : { zones: [] }
}

const getConstantZones = (datapointConstants, constants, color, data) => {
  const datapointConstantsWithValues = getConstantValues(datapointConstants, data)
  return getZones(
    [
      ...(datapointConstantsWithValues
        ? datapointConstantsWithValues.map((c) => ({ ...c, apply: true }))
        : []),
      ...constants.filter(
        ({ type, value }) =>
          typeof value === 'number' &&
          !datapointConstantsWithValues?.some((c) => c.type === type && c.value === value)
      ),
    ],
    color
  )
}

const addDatapointDataToGroupData = ({
  datapointData,
  groupData,
  targetData,
  targetDetails,
  rangeId,
}) => {
  const { rawUnit, unitObject, conversionFactor, kind, history, forecast } = datapointData

  groupData.history.push([
    ...history,
    ...getDatapointTargetHistory(targetData, targetDetails, forecast),
  ])
  groupData.rawUnit = groupData.rawUnit || rawUnit
  groupData.kind = groupData.kind || kind
  groupData.unitObject = groupData.unitObject || unitObject
  groupData.conversionFactor = groupData.conversionFactor || conversionFactor
  if (rangeId && !groupData.type) {
    groupData.type = 'line'
  }
}

const addDatapointRangeToGroupData = ({
  datapointData,
  datapointsData,
  group,
  groupRangeData,
  rangeId,
}) => {
  const { id, rawUnit, unitObject, conversionFactor, kind } = datapointData
  const minDatapointData = getDataForDatapoint({
    datapointsData,
    datapoint: id,
    group,
    method: 'min',
    rangeId,
  })
  const maxDatapointData = getDataForDatapoint({
    datapointsData,
    datapoint: id,
    group,
    method: 'max',
    rangeId,
  })

  const { history: minHistory } = minDatapointData
  const { history: maxHistory } = maxDatapointData
  groupRangeData.ranges.push(getRanges(minHistory, maxHistory))
  groupRangeData.rawUnit = groupRangeData.rawUnit || rawUnit
  groupRangeData.kind = groupRangeData.kind || kind
  groupRangeData.unitObject = groupRangeData.unitObject || unitObject
  groupRangeData.conversionFactor = groupRangeData.conversionFactor || conversionFactor
}

const getOffsetsForDatapoint = ({
  data,
  offsets = [],
  history = [],
  compareHistory = [],
  datapointsData,
  startTime,
  intl,
}) => {
  return offsets.reduce((output, { value, unit, defaultCalculationMethod, ...offset }, index) => {
    const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
    const offsetData = {
      ...data,
      ...offset,
      offset: startTime - offsetStartTime,
    }
    if (compareHistory[index]) {
      const extendedData = {
        ...offsetData,
        history: [
          compareHistory[index].map(({ value }, index) => ({ value, time: history[index]?.time })),
        ],
        caption: `${data.caption || data.name} - ${value} ${translateHistoryLabel(
          value,
          unit,
          intl.locale
        )}`,
        ...(data.rangeId && { type: 'line' }),
        id: `${offsetData.id}-offset-${index}`,
      }
      output.push({ ...extendedData, history: extendedData.history[0] })
    }
    if (data.rangeId && defaultCalculationMethod === 'range') {
      const { minCompareHistory, maxCompareHistory } =
        datapointsData && data.rangeId
          ? getCompareRangeData({ datapoint: data, datapointsData })
          : {}
      const extendedData = {
        ...offsetData,
        ranges: getRanges(minCompareHistory[index], maxCompareHistory[index]),
        type: 'arearange',
        lineWidth: 0,
        caption: `${data.caption || data.name} - ${intl.formatMessage({
          id: 'widget.range',
        })} - ${value} ${translateHistoryLabel(value, unit, intl.locale)}`,
      }
      output.push(extendedData)
    }
    return output
  }, [])
}

const getOffsetsForGroupDatapoint = ({
  group,
  datapoint,
  offsets = [],
  history = [],
  compareHistory = [],
  datapointsData,
  startTime,
  intl,
}) => {
  return offsets.reduce((output, { value, unit, defaultCalculationMethod, ...offset }, index) => {
    const offsetStartTime = dayjs(startTime).subtract(value, unit).valueOf()
    const offsetData = {
      ...group,
      ...offset,
      offset: startTime - offsetStartTime,
      id: `${group.id}-offset-${index}`,
    }
    if (compareHistory[index]) {
      const compare = compareHistory[index].map(({ value }, index) => ({
        value,
        time: history[index]?.time,
      }))
      output[offsetStartTime] = output[offsetStartTime]
        ? output[offsetStartTime].history.push(compare)
        : {
            ...offsetData,
            ...(datapoint.rangeId && defaultCalculationMethod === 'range' && { type: 'line' }),
            history: [compare],
            caption: `${offsetData.caption || offsetData.name} - ${value} ${translateHistoryLabel(
              value,
              unit,
              intl.locale
            )}`,
          }
    }
    if (datapoint.rangeId && defaultCalculationMethod === 'range') {
      const { minCompareHistory, maxCompareHistory } = datapointsData
        ? getCompareRangeData({ datapoint, datapointsData, group, rangeId: datapoint.rangeId })
        : {}

      const rangeGroupKey = `${offsetStartTime}range`
      output[rangeGroupKey] = output[rangeGroupKey]
        ? output[rangeGroupKey].ranges.push(
            getRanges(minCompareHistory[index], maxCompareHistory[index])
          )
        : {
            ...offsetData,
            type: 'arearange',
            ranges: [getRanges(minCompareHistory[index], maxCompareHistory[index])],
          }
    }
    return output
  }, {})
}

const addDatapointCompareDataToGroupCompareData = ({
  groupCompareData,
  group,
  datapointsData,
  datapoint,
  startTime,
  intl,
}) => {
  const { rawUnit, unitObject, conversionFactor, kind, history, compareHistory } = datapoint
  return {
    ...groupCompareData,
    ...getOffsetsForGroupDatapoint({
      datapointsData,
      datapoint,
      group: {
        ...group,
        rawUnit,
        kind,
        unitObject,
        conversionFactor,
      },
      offsets: group.offsets,
      history,
      compareHistory,
      startTime,
      intl,
    }),
  }
}

const addDatapointTargetDataToGroupTargetData = ({
  targetData,
  targetDetails,
  datapoint,
  datapointData,
  group,
  groupTargetData,
}) => {
  const { rawUnit, unitObject, conversionFactor, kind } = datapointData
  const { history } = targetData.datapoints.find((data) => data.id === datapoint) || {}
  const targetStartValue = history[0]?.value || 0
  const targetHistory = getTargetHistory({
    targetStartValue,
    ...targetDetails,
    targetPercentage: group.targetPercentage || targetDetails.targetPercentage,
    targetEndValue: group.targetValue || targetDetails.targetEndValue || 0,
  })

  groupTargetData.history = groupTargetData.history
    ? [targetHistory, ...groupTargetData.history]
    : [targetHistory]
  groupTargetData.rawUnit = groupTargetData.rawUnit || rawUnit
  groupTargetData.kind = groupTargetData.kind || kind
  groupTargetData.unitObject = groupTargetData.unitObject || unitObject
  groupTargetData.conversionFactor = groupTargetData.conversionFactor || conversionFactor
  groupTargetData.dashStyle = groupTargetData.dashStyle || 'Dash'
  groupTargetData.lineWidth = groupTargetData.lineWidth || 1
  groupTargetData.name = groupTargetData.name || `${group.name} - Target`
  groupTargetData.color = groupTargetData.color || transparentize(0.5, group.color)
}

const mergeDatapointData = ({ datapoint, datapointData, targetData, targetDetails, index }) => {
  const {
    color,
    offsets,
    id,
    rangeId,
    groupId,
    caption,
    name,
    targetPercentage,
    targetValue,
    ...dp
  } = datapoint
  const { history, compareHistory, forecast, ...rest } = datapointData

  return {
    caption,
    ...dp,
    ...rest,
    ...(rangeId && { type: 'line' }),
    name,
    color: getDefaultColorByIndex(color, index),
    history: [...history, ...getDatapointTargetHistory(targetData, targetDetails, forecast)],
    ...(targetData && getForecastZones(forecast)),
  }
}

const getRanges = (minHistory, maxHistory) =>
  minHistory.map((data, index) => [data, maxHistory[index]])

const getRangeDatapointData = ({ datapoint, datapointsData, intl }) => {
  const {
    offsets,
    id,
    rangeId,
    groupId,
    caption,
    name,
    targetPercentage,
    targetValue,
    ...dp
  } = datapoint
  const minDatapointData = getDataForDatapoint({ datapointsData, datapoint: id, method: 'min' })
  const maxDatapointData = getDataForDatapoint({ datapointsData, datapoint: id, method: 'max' })

  const { history: minHistory, forecast, ...rest } = minDatapointData
  const { history: maxHistory } = maxDatapointData

  const data = {
    caption,
    ...dp,
    ...rest,
    type: 'arearange',
    lineWidth: 0,
  }
  return [
    {
      ...data,
      name: `${name} - ${intl.formatMessage({ id: 'widget.range' })}`,
      ranges: getRanges(minHistory, maxHistory),
    },
  ]
}

const getCompareRangeData = ({ datapoint, datapointsData, group, rangeId }) => {
  const { id } = datapoint
  const minDatapointData = getDataForDatapoint({
    datapointsData,
    datapoint: id,
    method: 'min',
    group,
    rangeId,
  })
  const maxDatapointData = getDataForDatapoint({
    datapointsData,
    datapoint: id,
    method: 'max',
    group,
    rangeId,
  })

  const { compareHistory: minCompareHistory } = minDatapointData
  const { compareHistory: maxCompareHistory } = maxDatapointData

  return {
    minCompareHistory,
    maxCompareHistory,
  }
}

const getGroupCalculatedHistory = (group) => {
  if (!group.history) {
    return
  }
  const getValueArray = (index) =>
    group.history.reduce(
      (group, h) => (h[index]?.value ? group.concat(h[index]?.value) : group),
      []
    )
  return {
    history: group.history[0].map((history, index) => ({
      ...history,
      value: CALCULATIONS[group.method || 'sum'](getValueArray(index), 0),
    })),
  }
}

const getGroupCalculatedRanges = (group) => {
  if (!group.ranges) {
    return
  }
  const getValueArray = (index, type = 'min') => {
    const valueIndex = {
      min: 0,
      max: 1,
    }[type]
    return group.ranges.reduce(
      (group, range) =>
        range[index][valueIndex]?.value ? group.concat(range[index][valueIndex]?.value) : group,
      []
    )
  }
  return {
    ranges: group.ranges[0].map((history, index) => [
      {
        ...history,
        value: CALCULATIONS[group.method || 'sum'](getValueArray(index, 'min'), 0),
      },
      {
        ...history,
        value: CALCULATIONS[group.method || 'sum'](getValueArray(index, 'max'), 0),
      },
    ]),
  }
}

export const mergeHistoryData = ({
  datapointsData = [],
  datapoints = [],
  targetData,
  targetDetails,
  groups,
  startTime,
  intl,
}) => {
  if (!datapointsData.length) {
    return []
  }
  return datapoints.reduce((merged, dp, index) => {
    const {
      offsets,
      id,
      groupId,
      caption,
      name,
      targetPercentage,
      targetValue,
      tag,
      ...datapoint
    } = dp
    if (!tag && (!groups || !groupId)) {
      const { rangeId, ...dataForDatapoint } = getDataForDatapoint({
        datapointsData,
        datapoint: id,
      })
      const { history, compareHistory, forecast, ...datapointData } = dataForDatapoint

      if (history) {
        merged.push(
          mergeDatapointData({
            datapoint: { ...dp, rangeId },
            datapointData: dataForDatapoint,
            targetData,
            targetDetails,
            index,
          })
        )

        if (rangeId) {
          merged.push(
            ...getRangeDatapointData({
              datapoint: dp,
              datapointsData,
              startTime,
              intl,
            })
          )
        }

        if (offsets?.length > 0) {
          merged.push(
            ...getOffsetsForDatapoint({
              data: {
                ...datapoint,
                ...datapointData,
                rangeId,
                name,
                caption,
              },
              offsets,
              history,
              compareHistory,
              datapointsData,
              startTime,
              intl,
            })
          )
        }
      }
      if (targetData) {
        merged.push(getTargetForDatapoint({ targetData, targetDetails, datapoint: dp }))
      }
    }

    return merged
  }, [])
}

export const createDatapointGroups = ({
  datapoints,
  datapointsData,
  datapointsGroups,
  targetData,
  targetDetails,
  startTime,
  intl,
}) => {
  return datapointsGroups.reduce(
    (groups, group, index) => {
      const groupColor = getDefaultColorByIndex(group.color, index, 9)
      const {
        groupData,
        groupCompareData,
        groupTargetData,
        groupRangeData,
      } = group.datapoints.reduce(
        (data, datapoint) => {
          const { rangeId, ...datapointData } = getDataForDatapoint({
            datapointsData,
            datapoint,
            group,
          })

          if (datapointData.history) {
            addDatapointDataToGroupData({
              datapointData,
              groupData: data.groupData,
              targetData,
              targetDetails,
              rangeId,
            })
            if (rangeId) {
              addDatapointRangeToGroupData({
                datapointData,
                datapointsData,
                groupRangeData: data.groupRangeData,
                group,
                rangeId,
              })
            }
            groups.groupedDatapoints.push(datapoint)
          }

          if (group.offsets) {
            data.groupCompareData = addDatapointCompareDataToGroupCompareData({
              groupCompareData: data.groupCompareData,
              group,
              datapointsData,
              datapoint: { ...datapointData, rangeId },
              startTime,
              intl,
            })
          }

          if (targetData) {
            const { zones, zoneAxis } = getForecastZones(datapointData.forecast)
            data.groupData.zoneAxis = zoneAxis
            data.groupData.zones = zones
            addDatapointTargetDataToGroupTargetData({
              targetData,
              targetDetails,
              datapoint,
              datapointData,
              group,
              groupTargetData: data.groupTargetData,
            })
          }

          return data
        },
        {
          groupData: {
            history: [],
            datapoints: group.datapoints,
            rawUnit: null,
            kind: null,
            color: groupColor,
          },
          groupCompareData: {},
          groupTargetData: {},
          groupRangeData: {
            type: 'arearange',
            lineWidth: 0,
            name: `${group.name} - ${intl.formatMessage({ id: 'widget.range' })}`,
            ranges: [],
            color: groupColor,
            method: group.method,
          },
        }
      )
      if (groupData.history.length) {
        groups.groups.push({ ...group, ...groupData })
      }

      groups.groups = [
        ...groups.groups,
        ...(groupRangeData.ranges.length ? [groupRangeData] : []),
        ...Object.values(groupCompareData),
        ...(groupTargetData.history ? [groupTargetData] : []),
      ]

      if (index === datapointsGroups.length - 1) {
        groups.remainingDatapoints = mergeHistoryData({
          datapointsData,
          datapoints,
          targetData,
          targetDetails,
          groups: groups.groupedDatapoints,
          startTime,
          intl,
        })
      }
      return groups
    },
    { groups: [], groupedDatapoints: [], remainingDatapoints: [] }
  )
}

export const getGroupsCalculatedData = (groups) => {
  return groups.map((group) => ({
    ...group,
    ...getGroupCalculatedHistory(group),
    ...getGroupCalculatedRanges(group),
  }))
}

export const getHighChartSeries = ({
  data,
  datapointData,
  constants,
  zones,
  showInLegend,
  chartStyle,
  yAxisUnits,
  graphOpacity = 0.1,
  stacking,
}) => {
  return datapointData.reduce(
    (output, datapoint, index) => {
      const {
        id,
        datapoints,
        useSteps,
        line,
        lineWidth,
        caption,
        color,
        opacity,
        dashStyle,
        offset,
        unitObject,
        conversionFactor,
        customLogic,
        rawUnit,
        history,
        ranges,
        kind,
        name,
        constants: datapointConstants,
        zones: dpZones,
        zoneAxis,
        type,
      } = datapoint

      let series

      if (ranges) {
        const { series: rangeSeries } = rangesToHighchartsSeries(
          {
            ...unitObject,
            ...conversionFactor,
            customLogic,
          },
          ranges
        )
        series = rangeSeries
      } else {
        const processedHistory = getConversionHistory(
          {
            ...unitObject,
            ...conversionFactor,
            customLogic,
          },
          history
        )
        const { series: highCartSeries } = historyToHighchartsSeries(processedHistory)
        series = highCartSeries
      }

      const unit = conversionFactor?.endUnit?.formatted || rawUnit
      const chartType = type || (line ? 'line' : chartStyle)

      const datapointZones = datapointConstants?.length
        ? getConstantZones(datapointConstants, constants, color, data)
        : dpZones || zones
      output.series.push({
        id,
        datapoints,
        difference: unitObject?.difference,
        name: caption || name,
        showInLegend,
        data: series,
        type: chartType,
        color,
        offset,
        yAxis: yAxisUnits?.indexOf(unit),
        unit,
        zIndex: line ? 1 : 0,
        step: kind === 'bool' || useSteps ? 'left' : undefined,
        fillOpacity: chartType === 'area' ? opacity ?? graphOpacity : undefined,
        ...(lineWidth && { lineWidth }),
        dashStyle: HIGHCHARTS_DASH_STYLES.includes(dashStyle) ? dashStyle : 'Solid',
        ...(datapointZones?.length > 0 && {
          zones: datapointZones.map((zone) => ({
            ...zone,
            color: zone.color === 'color' ? color : zone.color,
          })),
          zoneAxis,
        }),
      })

      if (index === datapointData.length - 1 && constants.length > 0) {
        const { minValue, maxValue } = calculateMinMax(output.series, stacking)
        const { yAxisMin, yAxisMax } = getConstantsMinMax(constants)
        output.minValue = yAxisMin ?? (minValue > 0 ? 0 : minValue)
        output.maxValue = yAxisMax ?? maxValue
      }
      return output
    },
    { series: [] }
  )
}

export const getDatapointsPayload = ({
  datapoints,
  datapointsGroups,
  startTime,
  endTime,
  influxFunction,
  multiplier,
  defaultStartTime,
  granularityUnit,
  granularityValue,
  extendPeriod,
  calculationMethod = 'mean',
}) => {
  const groupProperties = getGroupProperties(datapointsGroups)
  const { defaultGranularity, defaultGranularityValue } = getGranularityForTimeRange(
    defaultStartTime,
    false,
    calculationMethod
  )

  const dpGranularity = granularityUnit || defaultGranularity
  const momentUnit = dpGranularity === 'MS' ? 'month' : dpGranularity
  const start = startTime ? startTime.startOf(momentUnit).valueOf() : undefined

  const end = endTime
    ? endTime.endOf(momentUnit || dpGranularity).valueOf()
    : extendPeriod && defaultStartTime.preset
    ? dayjs(start).add(1, defaultStartTime.preset.replace('this', '').toLowerCase()).valueOf()
    : undefined

  return datapoints.reduce(
    (datapoints, { id, groupId, conversionUnit, defaultCalculationMethod }) => {
      const method =
        groupId && groupProperties[id]
          ? groupProperties[id].defaultCalculationMethod || calculationMethod
          : defaultCalculationMethod || calculationMethod

      const rangeId = groupId ? groupProperties[id].rangeId : method === 'range' && uniqid()

      const datapoint = {
        id,
        returnUnitId: groupProperties[id]?.conversionUnit || conversionUnit,
        influxFunction,
        multiplier,
        granularity:
          method === 'raw'
            ? undefined
            : `${granularityValue || defaultGranularityValue}${dpGranularity}`,
        startTime: start,
        endTime: end,
        calculationMethod: method === 'range' ? 'mean' : method,
        groupId,
        ...(rangeId && { rangeId }),
      }

      datapoints.push(datapoint)

      if (method === 'range') {
        datapoints.push(
          { ...datapoint, calculationMethod: 'min' },
          { ...datapoint, calculationMethod: 'max' }
        )
      }

      return datapoints
    },
    []
  )
}

export const getYAxisUnits = (datapoints) =>
  datapoints.reduce(
    (map, datapoint) => ({
      ...map,
      [datapoint.rawUnit]:
        datapoint.conversionFactor?.endUnit?.name || map[datapoint.rawUnit] || datapoint.unit,
    }),
    {}
  )
