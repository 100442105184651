import {
  configFieldConvertor,
  ghgTrendFieldConvertors,
} from '../../../Dashboard/utils/common/helpers'
import { config as trendLine } from './../TrendLine/config'

export const config = {
  ...trendLine,
  editProps: {
    ...trendLine.editProps,
    category: 'widget.ghgCharts',
    keywords: [...trendLine.editProps.keywords, 'ghg'],
    default: {
      ...trendLine.editProps.default,
      dataType: 'ghg',
      type: 'GhgTrendLine',
    },
    fields: configFieldConvertor(trendLine.editProps.fields, ghgTrendFieldConvertors),
  },
}

export default config
