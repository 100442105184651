import React, { useEffect, useState } from 'react'

import { Image } from 'antd'
import { AnimatePresence } from 'framer-motion'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { VIRTUAL_ENTITY_TAG_NAME } from 'util/constants'

import Field from 'components/Form/components/Field'
import { FieldMessage } from 'components/Form/components/Field/styled'
import SelectField from 'components/Form/fields/SelectField'
import SwitchField from 'components/Form/fields/SwitchField'
import TextField from 'components/Form/fields/TextField'
import Icon from 'components/Global/Icon'

import RuleValueListField from './RuleValueListField'
import { DatapointFieldContent, DatapointFieldHeader, RuleFieldStyled } from './styled'

const RuleField = ({
  value,
  name,
  filter,
  action,
  onOpenDetails,
  intl,
  allowEdit,
  flowOptions,
  ...fieldProps
}) => {
  const theme = useTheme()
  const { watch, isEditMode, errors, getValues } = useFormContext()
  const isAllOpen = watch('ui.rules.toggleExpandAll')
  const [isOpen, setIsOpen] = useState(isAllOpen)
  const myMarkers = getValues(`${name}.markers`)
  const virtual = myMarkers?.some((marker) => marker.name === VIRTUAL_ENTITY_TAG_NAME)

  useEffect(() => {
    setIsOpen(isAllOpen)
  }, [isAllOpen])

  const fieldErrors = get(errors, name)

  const rule = watch(name)

  const enabled = rule?.isActive
  const required = rule?.required

  const open = isEditMode ? isOpen || enabled : isOpen
  const img = `https://static.iot.canary.expert/rules/${intl.formatMessage({
    id: `settings.rules.${rule.name}.fileName`,
  })}`

  return (
    <Field {...fieldProps}>
      <RuleFieldStyled
        disabled={!enabled}
        error={open ? false : fieldErrors !== undefined}
        virtual={virtual}
      >
        <DatapointFieldHeader
          onClick={() => setIsOpen((state) => !state)}
          className={`${open ? 'active' : 'inactive'}`}
        >
          <SwitchField
            name={`${name}.isActive`}
            size="small"
            disabled={required}
            disableClick={!isEditMode}
            readOnly={!allowEdit}
          />

          <TextField
            label={intl.formatMessage({ id: `settings.rules.${rule.name}.name` })}
            className="TagField__label"
            readOnly
          />
          <Icon
            icon={open ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'}
            className="DatapointField__expand"
            style={
              enabled && isEditMode ? { cursor: 'not-allowed', color: theme.color.softerText } : {}
            }
          />
        </DatapointFieldHeader>
        <AnimatePresence>
          {open && (
            <DatapointFieldContent
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              exit={{ scaleY: 0 }}
            >
              <div className="DatapointFieldContent__description">
                <p>{intl.formatMessage({ id: `settings.rules.${rule.name}.description` })}</p>
                <Image
                  alt="description"
                  className="image"
                  src={img}
                  style={{ height: '100%' }}
                  fallback="/resources/images/default-image.gif"
                  preview={false}
                />
              </div>
              <RuleValueListField
                name={`${name}.fields`}
                parentIsEnabled={enabled}
                allowEdit={allowEdit}
              />
              <SelectField
                name={`${name}.flowId`}
                label="Flow"
                options={flowOptions}
                allowClear={true}
              />
            </DatapointFieldContent>
          )}
        </AnimatePresence>

        {fieldErrors?.value && (
          <FieldMessage>
            {intl.formatMessage({ id: `form.field.error.${fieldErrors.value.type}` })}
          </FieldMessage>
        )}
      </RuleFieldStyled>
    </Field>
  )
}

export default injectIntl(RuleField)
