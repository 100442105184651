import React, { useCallback, useEffect, useState } from 'react'

import { arrayOf, object } from 'prop-types'

import Button from '../Button'
import { getAllOpenItems } from '../EditableTree/utilities'
import TreeStyled from './styled'
import TreeItem from './TreeItem'

const defaultTreeItemComponentProps = (item) => {
  const { iconString, title, name, disabled } = item
  return {
    icon: iconString,
    label: title || name,
    disabled,
    variant: 'treeItem',
  }
}

const Tree = ({
  value,
  isOpen,
  selectedKeys,
  level = 0,
  elementHeight = 30,
  variant,
  screenSize,
  onButtonClick,
  onTreeOpenClick,
  path = 'root',
  trackOpenState,
  initialOpen,
  treeItemComponent = Button,
  treeItemComponentProps = defaultTreeItemComponentProps,
  openItems,
  openIcon = 'arrow',
  sortKey = 'order',
}) => {
  const [globalOpenItems, setGlobalOpenItems] = useState(openItems)
  useEffect(() => {
    if (trackOpenState && level === 0) {
      const rootOpenItems = initialOpen
        ? getAllOpenItems({ children: value }, path)
        : openItems || []
      setGlobalOpenItems(rootOpenItems)
    }
  }, [trackOpenState, level, openItems, initialOpen, value, path])

  const handleTreeOpenClick = useCallback(
    (item, path) => {
      let openItems
      if (trackOpenState && level === 0) {
        setGlobalOpenItems((state) => {
          openItems = state.includes(path)
            ? state.filter((item) => item !== path)
            : [...state, path]
          if (onTreeOpenClick) {
            onTreeOpenClick(item, path, openItems)
          }
          return openItems
        })
        return
      }
      if (onTreeOpenClick) {
        return onTreeOpenClick(item, path)
      }
    },
    [trackOpenState, level, onTreeOpenClick]
  )

  const sortByOrder = (a, b) => {
    if (!a?.[sortKey]) {
      return 1
    }
    if (a[sortKey] < b[sortKey]) {
      return -1
    }
    if (a[sortKey] > b[sortKey]) {
      return 1
    }
    return 0
  }

  const tree = value?.[0]?.[sortKey] ? [...value].sort(sortByOrder) : value || []

  const getIsOpen = (index) => {
    if (level === 0 || isOpen) {
      return true
    }
    const treeOpenItems = openItems || globalOpenItems || []
    return treeOpenItems.includes(`${path}.children[${index}]`)
  }

  return (
    <TreeStyled className="Tree" level={level} variant={variant}>
      {tree.map((treeItem, index) => (
        <TreeItem
          key={treeItem.id}
          treeItem={treeItem}
          selectedKeys={selectedKeys}
          level={level}
          variant={variant}
          screenSize={screenSize}
          isOpen={getIsOpen(index)}
          elementHeight={elementHeight}
          treeItemComponent={treeItemComponent}
          treeItemComponentProps={treeItemComponentProps}
          onButtonClick={onButtonClick}
          onTreeOpenClick={handleTreeOpenClick}
          openIcon={openIcon}
          path={`${path}.children[${index}]`}
          openItems={openItems || globalOpenItems}
        />
      ))}
    </TreeStyled>
  )
}

Tree.propTypes = {
  value: arrayOf(object),
}

Tree.defaultProps = {}

export default Tree
