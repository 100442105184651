import React from 'react'

import { injectIntl } from 'react-intl'

import { useVehicleTypesForCompany } from 'services/nonAssetEntityTypes'
import { useOrganisationForms } from 'services/organisations'

import { SearchableTree } from 'components/Global/Tree'

import EntityTreeStyled from './styled'

const MobileCombustionTree = ({ onChange }) => {
  const { data: departments } = useOrganisationForms({ type: 'Department' })
  const { data: vehicleTypes } = useVehicleTypesForCompany()

  const vehicleTypeList = vehicleTypes
    ? vehicleTypes.map((v) => ({ ...v, name: v.description }))
    : []

  return (
    <EntityTreeStyled className="EntityTree">
      <SearchableTree
        value={[
          { name: 'Departments', children: departments || [] },
          { name: 'Vehicle types', children: vehicleTypeList },
        ]}
        level={0}
        elementHeight={30}
        show
        onButtonClick={onChange}
      />
    </EntityTreeStyled>
  )
}

export default injectIntl(MobileCombustionTree)
