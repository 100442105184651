import styled, { css } from 'styled-components'

import { Title } from 'components/Global/Caption/styled'
import { WidgetCaption } from 'components/Global/Caption/styled.WidgetCaption'
import Icon from 'components/Global/Icon/styled'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

export const gauge = css`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  ${({ isPreviewMode }) =>
    isPreviewMode &&
    css`
      width: 70%;
    `}
  .Gauge__container {
    flex: 1;
    overflow: hidden;
    > div {
      height: 100%;
    }
  }
`

export const axisLabels = css`
  .highcharts-axis-labels {
    transform: translateY(20px);
    text {
      fill: ${({ theme }) => theme.color.mainText} !important;
      font-size: 12px !important;
    }
  }
`

export const dataLabels = css`
  .data-labels {
    position: absolute;
    bottom: 3%;
    width: 180px;
    left: -90px;
    font-family: ${({ theme }) => theme.font.primary};
    font-weight: 600;
    color: ${({ theme }) => theme.color.strongText} !important;
    .value {
      font-size: 1.5rem;
    }
  }
`

const GaugeStyled = styled.div`
  ${gauge}
  ${axisLabels}
  ${dataLabels}

  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .Chart {
    flex: 1;
    overflow: hidden;
    > .RemountOnResize {
      height: 100%;
    }
  }

  &.SolidGauge,
  &.Consumption {
    .highcharts-axis-labels {
      transform: translateY(7px);
    }
  }

  ${WidgetCaption} {
    width: 100%;
    justify-content: center;
    z-index: 10;
    padding: 0;
    ${Title} {
      font-weight: 100;
    }
  }
  ${WidgetLoader} {
    ${Icon} {
      line-height: 3;
    }
  }
`
export default GaugeStyled
