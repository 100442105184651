import styled, { css } from 'styled-components'

import { OpenCloseButton } from 'components/Global/Button/OpenCloseButton'

const MainPanelStyled = styled.div`
  position: relative;
  background-image: ${({ theme }) => theme.darkMode && theme.color.backgroundBack};
  background: ${({ theme }) => !theme.darkMode && theme.color.backgroundBack};
  min-height: 100vh;

  > .ant-row,
  > .ant-card {
    height: calc(100vh - 52px);
  }

  ${({ theme }) =>
    theme.screenSize.screen.current === 's'
      ? css``
      : css`
          overflow: hidden;
          padding-top: 4rem;
          margin-left: 100px;

          ${OpenCloseButton} {
            position: fixed;
            bottom: 2.5rem;
            left: 7rem;
            margin-left: 2rem;
            z-index: 300;
          }
        `};
`
export default MainPanelStyled
