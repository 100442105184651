import { Radio } from 'antd'
import { lighten } from 'polished'
import styled from 'styled-components'

export const AntRadio = styled(Radio)`
  .ant-radio {
    margin-bottom: auto;
    margin-top: 0.4rem;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${({ theme }) => theme.color.main};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.color.main};
  }

  .ant-radio-checked::after {
    border: 1px solid ${({ theme }) => theme.color.main};
  }

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.color.main};
  }
`

export const AntRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper:hover {
    color: ${({ theme }) => theme.color.main};
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${({ theme }) => lighten(0.1, theme.color.main)};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: ${({ theme }) => theme.color.main};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: ${({ theme }) => theme.color.main};
    color: ${({ theme }) => theme.color.main};
  }

  .ant-radio-button-wrapper:focus-within {
    -webkit-box-shadow: 0 0 3px 1px ${({ theme }) => lighten(0.5, theme.color.main)};
    box-shadow: 0 0 3px 1px ${({ theme }) => lighten(0.5, theme.color.main)};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${({ theme }) => theme.color.main};
  }
`
