import styled from 'styled-components'

export const DropdownItem = styled.div`
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  @include transition-slow;
  strong.text-uppercase {
    text-transform: uppercase;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow();
  }

  :global {
    .anticon {
      font-size: rem(16) !important;
    }
  }
`

export const Option = styled.span`
  span[role='img'] {
    margin-right: 0.6rem;
  }
`
