import React, { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'

import TextInputStyled from './styled'

const TextInput = ({ error, onChange, value, placeholder, onBlur, disabled, rows }) => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFunc = useCallback(
    debounce((val) => {
      onChange(val)
    }, 200),
    [onChange]
  )

  const handleOnChange = useCallback(
    (e) => {
      setInternalValue(e.target.value)
      if (onChange) {
        debounceFunc(e.target.value)
      }
    },
    [setInternalValue, debounceFunc, onChange]
  )

  const elementProps = {
    onChange: handleOnChange,
    onBlur,
    value: internalValue,
    disabled,
    placeholder,
  }
  return (
    <TextInputStyled className="TextInput" error={!!error} rows={rows} disabled={disabled}>
      {rows ? <textarea {...elementProps} rows={rows} /> : <input {...elementProps} />}
      {/*{!disabled && internalValue}*/}
    </TextInputStyled>
  )
}

export default TextInput
