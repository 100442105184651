import React from 'react'

import { Input } from 'antd'

const { TextArea: TextAreaInput } = Input

const TextArea = ({ register, ...props }) => {
  return <TextAreaInput {...props} ref={register} autoSize={{ minRows: 3 }} />
}

export default TextArea
