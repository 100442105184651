export const getNewSlideState = ({ activeSlide }, value, slides = []) => {
  let newValue = value
  if (typeof value === 'string') {
    newValue = value === 'prev' ? activeSlide - 1 : activeSlide + 1
  }
  const newState = newValue <= 0 ? 0 : newValue === slides.length ? slides.length - 1 : newValue
  return {
    prevSlide: activeSlide,
    activeSlide: newState,
    reverse: newState < activeSlide
  }
}
