import React, { useMemo } from 'react'

import { merge } from 'lodash'
import { injectIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  calculatePlotBands,
  getComparatorValue,
  getDatapointsCalculatedValue,
} from 'util/datapointCalculationFunctions'
import { useComparatorsPayload, useDatapointsForTimeRangePayload } from 'util/hooks'
import { useLocaleNumber } from 'util/numbers'
import round from 'util/round'
import { useQueryData } from 'services/data'

import { useWidgetDataRefetch } from 'components/Dashboard/Widget/hooks.js'
import { getDatapointErrors } from 'components/Widgets/functions'
import { chartOptions, getSerie } from 'components/Widgets/Gauge/ClassicGauge/chartOptions'
import { SolidGauge } from 'components/Widgets/Gauge/ClassicGauge/styled'

const RelativeGauge = ({
  dataProps = {},
  decimals = 2,
  datapoints = [],
  method = 'sum',
  comparatorsMethod,
  comparators,
  difference,
  timeRange,
  comparisonPeriod,
  conversionUnit,
  intl,
  prop,
  showDelta = false,
  showComparator = false,
  customLogic,
  deviation = 50,
  preview = { gauge: { series: [] } },
  defaultCalculationMethod,
  comparatorsCalculationMethod,
  colors = [
    [0, '#44bc66'],
    [0.33, '#fed33a'],
    [0.66, '#f88c1d'],
    [1, '#f14e3e'],
  ],
  newSocketValue,
  comparatorUnit,
  ...props
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  const { isPreviewMode, isEditMode } = dataProps

  const datapointsPayload = useDatapointsForTimeRangePayload({
    datapoints,
    timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data, error, loading, refetch } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointsPayload,
      widgetType: 'relativeGauge',
    },
    deps: [datapointsPayload],
    skip: datapoints.length === 0,
  })

  const { values, comparatorDatapoints } = useComparatorsPayload({
    datapoints,
    comparators,
    timeRange: comparisonPeriod || timeRange,
    groupConversionUnit: conversionUnit,
  })

  const { data: comparatorsData, loading: comparatorsLoading } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: comparatorDatapoints,
      widgetType: 'relativeGaugeComparator',
    },
    deps: [comparatorDatapoints],
    skip: !comparatorDatapoints?.length,
  })

  useWidgetDataRefetch(refetch, newSocketValue, loading, isPreviewMode, isEditMode)

  const validData = data?.data?.datapoints

  const { series, datapointChartProps, errors } = useMemo(() => {
    const errors = getDatapointErrors(datapoints)
    if (!validData) {
      return isPreviewMode
        ? { series: error ? [] : preview.gauge.series, datapointChartProps: {}, errors }
        : { datapointChartProps: {}, errors }
    }
    const value = getDatapointsCalculatedValue(validData, {
      method,
      customLogic,
      decimals,
      calculationMethod: defaultCalculationMethod,
    })
    const comparator = getComparatorValue({
      comparators,
      comparatorValues: values,
      comparatorsData,
      method: comparatorsMethod,
      decimals,
      calculationMethod: comparatorsCalculationMethod,
      defaultValue: value,
    })

    const displayUnit = validData[0]
      ? validData[0].conversionFactor?.endUnit.name || validData[0].unit
      : ''
    const minimum = round(comparator - (Math.abs(comparator) / 100) * deviation, decimals)
    const maximum = round(comparator + (Math.abs(comparator) / 100) * deviation, decimals)
    const deviationPercentage = round(((value - comparator) / Math.abs(comparator)) * 100, decimals)
    const percentageLabel =
      deviationPercentage < 0 ? ` (${deviationPercentage}%)` : ` (+${deviationPercentage}%)`

    const series = [
      getSerie({ value, unit: displayUnit + percentageLabel, theme }, false, localizeNumber),
    ]

    if (showComparator) {
      series.push(
        getSerie(
          {
            value: comparator,
            unit: `${displayUnit} - ${intl.formatMessage({ id: 'widget.reference' })}`,
            theme,
          },
          true,
          localizeNumber
        )
      )
    }

    const datapointChartProps = {
      yAxis: [
        {
          min: minimum,
          max: maximum,
          tickPositions: [minimum, maximum],
          labels: {
            formatter: function () {
              return this.isFirst
                ? `${localizeNumber(this.value)} ${comparatorUnit || displayUnit} (-${deviation}%)`
                : `${localizeNumber(this.value)} ${comparatorUnit || displayUnit} (+${deviation}%)`
            },
          },
          plotBands: calculatePlotBands(colors, minimum, maximum).map(({ from, to, color }) => ({
            from,
            to,
            color,
            outerRadius: '100%',
            innerRadius: '78%',
          })),
        },
      ],
    }
    return { series, datapointChartProps, errors }
  }, [
    theme,
    colors,
    customLogic,
    decimals,
    deviation,
    isPreviewMode,
    method,
    preview.gauge.series,
    showComparator,
    validData,
    comparatorsData,
    datapoints,
    error,
    intl,
    localizeNumber,
    comparators,
    comparatorsCalculationMethod,
    comparatorsMethod,
    defaultCalculationMethod,
    values,
    comparatorUnit,
  ])

  const globalChartOptions = chartOptions({
    theme,
    series,
  })
  const options = merge({}, globalChartOptions, datapointChartProps)

  return (
    <SolidGauge
      {...props}
      className="RelativeGauge"
      options={options}
      loading={{
        loading: loading || comparatorsLoading,
        icon: preview.icon,
      }}
      inlineErrors={errors}
      error={error}
    />
  )
}

export default injectIntl(RelativeGauge)
