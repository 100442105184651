import { transparentize } from 'polished'
import styled from 'styled-components'

import Icon from '../Icon/styled'
import Loader from './index'

export const WidgetLoader = styled(Loader)`
  background: ${({ theme, error }) =>
    transparentize(error ? 0.1 : 0.2, theme.color.backgroundFront)};
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 100;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.softText};
  padding: 0 5%;

  ${Icon} {
    width: 10% !important;
    padding: 0 !important;
    height: auto;
    max-height: 40%;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.softStroke};
    animation: loaderPulse 2s infinite;

    svg {
      min-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }

  label {
    overflow: hidden;
    margin-top: 1%;
  }

  @keyframes loaderPulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }

    60% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }
`
