import styled from 'styled-components'

const InformationLinesStyled = styled.div`
  overflow: auto;

  > h4 {
    margin-bottom: 0.5rem;
  }

  .Datapoints__buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .smallButton {
    margin: 0.5rem 0.5rem 0 0;
  }
`

export const DatapointGroupStyled = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;

  &:nth-child(2) {
    margin-top: 0;
  }

  > .Icon {
    position: relative;
    cursor: pointer;
    margin: 0.5rem 0.5rem 0.5rem 0;
    &:after {
      content: '';
      position: absolute;
      width: 0.5rem;
      height: 1px;
      top: 50%;
      left: 100%;
      background: ${({ theme }) => theme.color.softText};
    }
  }
  .AccordeonContent {
    width: 100%;
    padding-left: 2.5rem;
    border-bottom: none;
    border-bottom: none !important;
  }

  .Button {
    max-width: 360px;
  }

  .DatapointGroup__datapoints {
    position: relative;
    padding: 0.5rem 1rem;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% - 8px);
      left: 0;
      top: -13px;
      background: ${({ theme }) => theme.color.softText};
    }

    > div {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1px;
        left: -1rem;
        top: 50%;
        background: ${({ theme }) => theme.color.softText};
      }
    }
  }
`
export default InformationLinesStyled
