import styled from 'styled-components'

const DefaultStartTimeSelectorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    width: 100%;
  }
  .DateRangeSelector {
    margin-bottom: 0 !important;
  }
  .OrganisationReportingDateRange {
    flex-direction: column;
    .ant-radio-button-wrapper {
      height: auto;
      line-height: unset;
      margin-bottom: 0.5rem;
    }
    .RangePicker {
      margin-left: 0;
    }
  }
  .Button {
    flex-direction: row-reverse;
    font-size: 1em;
    line-height: 1.7;
    color: ${({ theme, isPrimary }) => (isPrimary ? theme.color.white : theme.color.softText)};
    border: 1px solid ${({ theme }) => theme.color.softStroke};
    border-radius: 2rem;
    padding: 0.1rem 0.8rem;
    transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;

    &:hover,
    &.active {
      border-color: ${({ theme, disabled }) => !disabled && theme.color.main};

      .Icon path {
        fill: ${({ theme, disabled }) => !disabled && theme.color.main};
      }
    }
  }

  .Tooltip {
    > .Button {
      span {
        white-space: nowrap;
        font-weight: 100;
        margin-right: 0.5em;
      }

      path {
        fill: ${({ theme }) => theme.color.softText};
      }
    }
  }

  .NumberInput {
    max-width: 5rem;
    min-width: 5rem;
  }

  .MultiFieldContainer {
    label {
      margin: 0;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .col2 {
    width: 50%;
  }
`
export default DefaultStartTimeSelectorStyled
