import React, { useCallback, useMemo, useState } from 'react'

import { Button as AntButton, Divider } from 'antd'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import treeifyHiearchyList from 'util/treeifyHiearchyList'
import {
  useEntityTreeDataForCompany,
  usePurchasedEnergyMetersForEntity,
  useStationaryCombustionMetersForEntity,
} from 'services/entities'
import {
  usePurchasedEnergyMetersForOrganisation,
  useRefrigerantEquipmentsForOrganisation,
  useStationaryCombustionMetersForOrganisation,
} from 'services/organisations'

import { useFieldMetaState } from 'components/Form/components/Field/hooks'
import {
  DropdownRender,
  GhgDatapointCascaderFieldStyled,
} from 'components/Form/fields/CascaderField/variants/GhgDatapointCascaderField/styled'
import Button from 'components/Global/Button'

import { getQueryPayload } from './functions'

/* IMPORTANT: when using this field, the form must have the shouldUnregister={false} prop */

const GhgDatapointCascaderField = ({
  name,
  onChange,
  scopeId,
  organisationId,
  extraFilterTags,
  extraNotFilterTags,
  refrigerantType,
  ...props
}) => {
  const { setValue } = useFormContext() || {}
  const [valuePath, setValuePath] = useFieldMetaState('ghgDatapointSelector', name, 'valuePath', [])
  const [selectedDatapoint, setSelectedDatapoint] = useFieldMetaState(
    'ghgDatapointSelector',
    name,
    'selectedDatapoint'
  )
  const [popupVisible, setPopupVisible] = useState('reset')
  const { data } = useEntityTreeDataForCompany()
  const [
    getStationaryCombustionMeters,
    { data: stationaryCombustionMeters },
  ] = useStationaryCombustionMetersForEntity()

  const [
    getPurchasedEnergyMeters,
    { data: purchasedEnergyMeters },
  ] = usePurchasedEnergyMetersForEntity()
  const [
    getOrganisationStationaryCombustionMeters,
    { data: organisationStationaryCombustionMeters },
  ] = useStationaryCombustionMetersForOrganisation()

  const [
    getOrganisationPurchasedEnergyMeters,
    { data: organisationPurchasedEnergyMeters },
  ] = usePurchasedEnergyMetersForOrganisation()

  const [
    getRefrigerantEquipmentsForOrganisation,
    { data: refrigerantEquipmentsForOrganisation },
  ] = useRefrigerantEquipmentsForOrganisation()

  const { query, datapoints } = useMemo(() => {
    switch (scopeId) {
      case 1:
        return {
          query: organisationId
            ? getOrganisationStationaryCombustionMeters
            : getStationaryCombustionMeters,
          datapoints: organisationId
            ? organisationStationaryCombustionMeters?.stationaryCombustionMetersForOrganisation
            : stationaryCombustionMeters?.stationaryCombustionMetersForEntity,
        }
      case 2:
        return {
          query: organisationId ? getOrganisationPurchasedEnergyMeters : getPurchasedEnergyMeters,
          datapoints: organisationId
            ? organisationPurchasedEnergyMeters?.purchasedEnergyMetersForOrganisation
            : purchasedEnergyMeters?.purchasedEnergyMetersForEntity,
        }
      case 4:
        return {
          query: getRefrigerantEquipmentsForOrganisation,
          datapoints: refrigerantEquipmentsForOrganisation?.refrigerantEquipmentsForOrganisation,
        }
      default:
    }
  }, [
    scopeId,
    organisationId,
    getOrganisationStationaryCombustionMeters,
    getStationaryCombustionMeters,
    organisationStationaryCombustionMeters,
    stationaryCombustionMeters,
    getOrganisationPurchasedEnergyMeters,
    getPurchasedEnergyMeters,
    organisationPurchasedEnergyMeters,
    purchasedEnergyMeters,
    getRefrigerantEquipmentsForOrganisation,
    refrigerantEquipmentsForOrganisation,
  ])

  const options = useMemo(
    () =>
      data
        ? treeifyHiearchyList({
            list: data,
            idKey: 'id',
            depthType: null,
            disabledTypes: ['room', 'equip'],
          })[0].children
        : [],
    [data]
  )

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  const handleDatapointSelect = useCallback(
    ({ id, equipName, datapointName }) => {
      setValue(name, id)
      setSelectedDatapoint({ id, equipName, datapointName })
      handlePopupClose()
      onChange && onChange(id)
    },
    [setValue, name, onChange, setSelectedDatapoint]
  )

  const handlePopupClose = () => {
    setPopupVisible('close')
    setTimeout(() => setPopupVisible('reset'), 500)
  }

  const displayRender = useCallback(
    (label) => {
      return `${!selectedDatapoint && label[label.length - 1] ? label[label.length - 1] : ''}${
        selectedDatapoint?.equipName || ''
      } ${selectedDatapoint?.datapointName || ''}`
    },
    [selectedDatapoint]
  )

  const dropdownRender = useCallback(
    (menus) => {
      return (
        <DropdownRender path={valuePath}>
          {menus}
          <Divider style={{ margin: 0 }} />
          <div className="DropdownRender__datapoints">
            <h4>{scopeId === 4 ? 'Select entity' : 'Select datapoint'}</h4>
            <div>
              <div className="DropdownRender__datapoints-container">
                {datapoints?.length > 0 && valuePath?.length > 0 ? (
                  datapoints.map(({ id, equipName, datapointName }) => (
                    <Button
                      key={id}
                      label={`${equipName} ${datapointName}`}
                      isActive={id === selectedDatapoint?.id}
                      variant="tag"
                      onClick={() => handleDatapointSelect({ id, equipName, datapointName })}
                    />
                  ))
                ) : (
                  <span className="DropdownRender__no-values">No datapoints</span>
                )}
              </div>
              <AntButton size="small" onClick={handlePopupClose}>
                Cancel
              </AntButton>
            </div>
          </div>
        </DropdownRender>
      )
    },
    [datapoints, selectedDatapoint, handleDatapointSelect, valuePath, scopeId]
  )
  const handleCascaderChange = (values) => {
    setPopupVisible('open')
    const queryPayload = getQueryPayload({ id: scopeId }, values, {
      extraFilterTags,
      extraNotFilterTags,
      refrigerantType,
    })

    query({
      variables: {
        ...queryPayload,
        ...(organisationId && { organisationId: parseFloat(organisationId) }),
      },
    })

    setValuePath(values)
    setValue(name, null)
  }

  const cascaderProps = {
    ...props,
    ...(popupVisible === 'close'
      ? {
          open: false,
        }
      : popupVisible === 'open' && { open: true }),
  }

  return (
    <GhgDatapointCascaderFieldStyled
      {...cascaderProps}
      name={name ? `meta.${name}` : undefined}
      onChange={handleCascaderChange}
      options={options}
      showSearch={{ filter, matchInputWidth: false }}
      displayRender={displayRender}
      dropdownRender={dropdownRender}
      changeOnSelect
      allowClear={false}
      popupPlacement="bottomLeft"
      hasValue={!!selectedDatapoint}
    />
  )
}

GhgDatapointCascaderField.propTypes = {
  refrigerantType: PropTypes.oneOf(['equipment', 'inventory']),
}

GhgDatapointCascaderField.defaultProps = {}

export default GhgDatapointCascaderField
