import React, { useEffect, useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { useHasModule } from 'services/security/modules'
import { useScopeDefinition } from 'services/sustainability/ghg'

import ValuePicker from 'components/Form/components/ValuePicker/ValuePicker'
import { scopeConfig } from 'components/Global/FormField/GhgDataSelector/config'

const ScopeSelector = ({ value, onChange, intl }) => {
  const { data: scopeDefinition } = useScopeDefinition()
  const [hasModule] = useHasModule()

  const scopes = useMemo(() => {
    const hasScope3 = hasModule('ghg.scope3')
    return scopeDefinition
      ? scopeDefinition.reduce((scopes, { id, description, ...def }, index) => {
          scopes.push({
            ...def,
            ...scopeConfig[id],
            name: description,
            id,
            disabled: hasScope3 ? false : id === '6' ? true : false,
          })
          if (index === scopeDefinition.length - 1) {
            scopes = scopes.sort((a, b) => (a.name > b.name ? 1 : -1))
          }
          return scopes
        }, [])
      : []
  }, [scopeDefinition, hasModule])

  useEffect(() => {
    if (scopes && !value) {
      onChange(scopes[0])
    }
  }, [scopes, value, onChange])
  return (
    <ValuePicker
      options={scopes}
      placeholder={intl.formatMessage({ id: 'widget.scope' })}
      value={value?.id}
      selectionKeys={{ label: 'name', value: 'id' }}
      onChange={(id, scope) => onChange(scope)}
    />
  )
}

export default injectIntl(ScopeSelector)
