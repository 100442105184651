import React from 'react'

import { number, object, oneOfType, string } from 'prop-types'
import { injectIntl } from 'react-intl'

import { useData, useDisplayValue } from 'components/Form/components/ValuePicker/hooks'

import SelectionTextStyled from './styled'

const SelectionText = ({
  value,
  query,
  options,
  selectionKeys = { value: 'value', label: 'label' },
  className,
  intl,
}) => {
  const { data } = useData(query, options)
  const displayValue = useDisplayValue(data, selectionKeys, value, intl)

  return (
    <SelectionTextStyled className={className}>
      <span dangerouslySetInnerHTML={{ __html: displayValue }} />
    </SelectionTextStyled>
  )
}

SelectionText.propTypes = {
  text: oneOfType([string, number]),
  className: string,
  styleProps: object,
}

SelectionText.defaultProps = {}

export default injectIntl(SelectionText)
