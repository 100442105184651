import React, { useEffect, useState } from 'react'

import { ChromePicker } from 'react-color'
import { withTheme } from 'styled-components'

import Button from 'components/Global/Button'

import ColorPickerStyled, { ColorElement } from './styled'

const preset = require('./../../../../theme/variables').color.preset

const ColorPicker = ({
  value,
  theme,
  clearIcon,
  onChange,
  width = 190,
  height = 400,
  dataCy = 'ColorPicker',
  disabled = false,
}) => {
  const [selectedColor, setSelectedColor] = useState(value || null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSelectedColor(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onSelectColor = (value, e) => {
    e.stopPropagation()
    const update = typeof value === 'object' ? null : value
    onChange(update)
    setSelectedColor(update)
  }
  return (
    <ColorPickerStyled
      className="ColorPicker"
      dataCy={dataCy}
      button={{ color: value, dataCy: `${dataCy}-trigger`, disabled: disabled }}
      isOpen={isOpen}
      color={selectedColor}
      clearIcon={clearIcon}
      value={value}
      variant="ColorPicker"
      width={width}
      height={height}
      onChange={onSelectColor}
      onToggleOpen={(payload) => {
        if (!disabled) {
          setIsOpen((state) => (typeof payload !== 'undefined' ? payload : !state))
        }
      }}
    >
      <ChromePicker
        onChangeComplete={(payload = {}, e) => onSelectColor(payload.hex, e)}
        color={selectedColor || theme.color.main}
        disableAlpha
      />
      <div className="ColorPicker__presets">
        <ColorElement
          value={theme.color.main}
          isSelected={theme.color.main === selectedColor}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => onSelectColor(theme.color.main, e)}
          data-cy={`${dataCy}-theme-main`}
        />
        <ColorElement
          value={theme.color.highlight}
          isSelected={theme.color.highlight === selectedColor}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => onSelectColor(theme.color.highlight, e)}
          data-cy={`${dataCy}-theme-highlight`}
        />
        <ColorElement
          value={theme.color.action}
          isSelected={theme.color.action === selectedColor}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => onSelectColor(theme.color.action, e)}
          data-cy={`${dataCy}-theme-action`}
        />
        <ColorElement
          value={theme.color.sideBar}
          isSelected={theme.color.sideBar === selectedColor}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => onSelectColor(theme.color.sideBar, e)}
          data-cy={`${dataCy}-theme-sidebar`}
        />
        {Object.entries(preset).map(([key, value]) => (
          <ColorElement
            key={key}
            value={value || theme.color.main}
            isSelected={value === selectedColor}
            whileTap={{ scale: 0.9 }}
            onClick={(e) => onSelectColor(value, e)}
            data-cy={`${dataCy}-preset-${key}`}
          />
        ))}
      </div>
      <div className="ColorPicker__button-container">
        <Button
          variant="smallButton"
          label="Ok"
          isPrimary
          onClick={() => setIsOpen(false)}
          dataCy={`${dataCy}-confirm`}
        />
      </div>
    </ColorPickerStyled>
  )
}

ColorPicker.propTypes = {}

ColorPicker.defaultProps = {}

export default withTheme(ColorPicker)
