import React from 'react'

import { AnimatePresence } from 'framer-motion'
import { injectIntl } from 'react-intl'

import Button from 'components/Global/Button'
import { useCookieBanner } from 'components/Layout/CookieBanner/CookieBannerProvider'

import CookieBannerStyled from './styled'

const CookieBanner = ({ intl }) => {
  const { cookieSettings, onSetCookies } = useCookieBanner()

  return (
    <AnimatePresence>
      {!cookieSettings.allowCookies && (
        <CookieBannerStyled
          className="CookieBanner"
          initial={{
            bottom: '-100%',
            opacity: 0,
            visibility: 'hidden',
          }}
          exit={{
            bottom: '-100%',
            opacity: 0,
            visibility: 'hidden',
          }}
          animate={{
            bottom: '0%',
            opacity: 1,
            visibility: 'visible',
            transition: {
              duration: 1,
            },
          }}
        >
          <h2>Use of cookies</h2>
          <p>
            When you visit this website or the online platforms of Canary, you automatically give
            your consent for the website to install cookies on your device, with which information
            is collected on your use of this website. You can block cookies via the settings of your
            browser, although that can have an impact on the proper functioning of this site. Canary
            uses both temporary and permanent cookies.
          </p>
          <div className="CookieBanner__buttons">
            <Button
              label={intl.formatMessage({ id: 'form.moreInfo' })}
              url="/user/login/cookies"
              variant="mainButton"
            />
            <Button
              data-testid="acceptCookies"
              label={intl.formatMessage({ id: 'form.acceptCookies' })}
              onClick={() => onSetCookies({ allowCookies: true })}
              isPrimary
              variant="mainButton"
            />
          </div>
        </CookieBannerStyled>
      )}
    </AnimatePresence>
  )
}

export default injectIntl(CookieBanner)
