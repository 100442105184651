import {
  configFieldConvertor,
  ghgTrendFieldConvertors,
} from '../../../Dashboard/utils/common/helpers'
import { config as graphCard } from './../GraphCard/config'

export const config = {
  ...graphCard,
  editProps: {
    ...graphCard.editProps,
    category: 'widget.ghgCharts',
    keywords: [...graphCard.editProps.keywords, 'ghg'],
    default: {
      ...graphCard.editProps.default,
      dataType: 'ghg',
      type: 'GhgLineChart',
    },
    fields: configFieldConvertor(graphCard.editProps.fields, ghgTrendFieldConvertors),
  },
}

export default config
