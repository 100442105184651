module.exports = {
  color: {
    preset: {
      white: '#FFF',
      softerText: '#e4e9f0',
      mainStroke: '#a8abb1',
      softText: '#8a8a8a',
      black: '#272727',
      color7: '#5537ff',
      color8: '#3e76e5',
      color9: '#438de4',
      color10: '#21b9f7',
      color11: '#1bd6b9',
      color12: '#10d680',
      color13: '#44bc66',
      color14: '#c6d336',
      color1: '#ffe31a',
      color2: '#f88c1d',
      color3: '#f14e3e',
      color4: '#ff2b2a',
      color5: '#fa075e',
      color6: '#a507fa',
    },
    light: {
      primary: '',
      white: '#FFF',
      backgroundBase: '#C6E5F7', //'#f2f4f8'
      backgroundFront: '#FFF',
      backgroundBack: '#f6f6f6',
      softerText: '#e4e9f0',
      softText: '#8a8a8a',
      mainStroke: '#a8abb1',
      mainText: '#494949',
      strongText: '#353535',
      strongerText: '#181818',
      softStroke: '#e4e6ea',
      softerStroke: '#f2f4f8',
      theme: '#fdbe10',
      mainShadow: 'rgb(180,180,180)',
      success: '#44bc66',
      error: '#f14e3e',
      warning: '#fed33a',
      info: '#3e76e5',
    },
    dark: {
      primary: '',
      white: '#FFF',
      backgroundBase: '#C6E5F7', //'#f2f4f8'
      backgroundFront: '#FFF',
      backgroundBack: '#f6f6f6',
      softerText: '#e4e9f0',
      softText: '#8a8a8a',
      mainStroke: '#a8abb1',
      mainText: '#494949',
      strongText: '#353535',
      strongerText: '#181818',
      softStroke: '#e4e6ea',
      softerStroke: '#f2f4f8',
      theme: '#fdbe10',
      mainShadow: 'rgb(180,180,180)',
      success: '#44bc66',
      error: '#f14e3e',
      warning: '#fed33a',
      info: '#3e76e5',
    },
    color: {
      aviary: {
        color10: '#606060',
        warning: '#fed33a',
        main: '#535ab4',
        sideBar: '#24224f',
        action: '#E83D73',
        soft: '#C6E5F7',
        highlight: '#ffe31a',
        critical: '#f88c1d',
        error: '#f14e3e',
        color9: '#ff2b2a',
        color8: '#fa075e',
        color11: '#a507fa',
        color12: '#5537ff',
        info: '#3e76e5',
        color6: '#438de4',
        color5: '#21b9f7',
        color4: '#1bd6b9',
        color3: '#10d680',
        success: '#44bc66',
        color2: '#c6d336',
      },
      heylen: {
        color10: '#606060',
        warning: '#fed33a',
        main: '#be0010',
        action: '#be0010',
        highlight: '#eef5ff',
        sideBar: '#be0010',
        critical: '#f88c1d',
        error: '#f14e3e',
        color9: '#ff2b2a',
        color8: '#fa075e',
        color11: '#a507fa',
        color12: '#5537ff',
        info: '#3e76e5',
        color6: '#438de4',
        color5: '#21b9f7',
        color4: '#1bd6b9',
        color3: '#10d680',
        success: '#44bc66',
        color2: '#c6d336',
      },
      heylen2: {
        color10: '#606060',
        warning: '#fed33a',
        main: '#b62e2e',
        action: '#be0010',
        sideBar: '#be0010',
        highlight: '#be0010',
        critical: '#f88c1d',
        error: '#f14e3e',
        color9: '#ff2b2a',
        color8: '#fa075e',
        color11: '#a507fa',
        color12: '#5537ff',
        info: '#3e76e5',
        color6: '#438de4',
        color5: '#21b9f7',
        color4: '#1bd6b9',
        color3: '#10d680',
        success: '#44bc66',
        color2: '#c6d336',
      },
      green: {
        color10: '#606060',
        warning: '#fed33a',
        main: '#61a67c',
        action: '#00a843',
        sideBar: '#00a843',
        highlight: '#00a843',
        critical: '#f88c1d',
        error: '#f14e3e',
        color9: '#ff2b2a',
        color8: '#fa075e',
        color11: '#a507fa',
        color12: '#5537ff',
        info: '#3e76e5',
        color6: '#438de4',
        color5: '#21b9f7',
        color4: '#1bd6b9',
        color3: '#10d680',
        success: '#44bc66',
        color2: '#c6d336',
      },
    },
  },
  font: {
    primary: 'poppins,sans-serif',
    secondary: 'poppins,sans-serif',
  },
  text: {
    xl: {
      size: 1.6,
      spacing: 0.02,
      weight: 600,
    },
    l: {
      size: 1.4,
      spacing: 0.01,
      weight: 600,
    },
    m: {
      size: 1,
      spacing: 0.02,
      weight: 300,
    },
    s: {
      size: 0.9,
      spacing: 0.02,
      weight: 300,
    },
  },
  topBar: {
    height: '4em',
  },
  sideBar: {
    width: '100px',
  },
  shadowBaseColor: 'rgb(180,180,180)',
  shadowElevation0: (darkMode = false) =>
    `0 0 0 rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.16), 0 0 0 rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.23)`,
  shadowElevation1: (darkMode = false) =>
    `0 1px 3px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.12), 0 1px 2px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.24)`,
  shadowElevation2: (darkMode = false) =>
    `0 3px 6px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.16), 0 3px 6px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.23)`,
  shadowElevation3: (darkMode = false) =>
    `0 10px 20px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.19), 0 6px 6px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.23)`,
  shadowElevation4: (darkMode = false) =>
    `0 14px 28px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.16), 0 10px 10px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.23)`,
  shadowElevation5: (darkMode = false) =>
    `0 19px 38px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},0.30), 0 15px 12px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },0.22)`,
  d3Elevation1: '0 0 3px rgba(180,180,180,0.12), 0 0 2px rgba(180,180,180,0.24)',
  d3Elevation2: '0 0 6px rgba(180,180,180,0.16), 0 0 6px rgba(180,180,180,0.23)',
  d3Elevation3: '0 0 20px rgba(180,180,180,0.19), 0 0 6px rgba(180,180,180,0.23)',
  d3Elevation4: '0 0 28px rgba(180,180,180,0.25), 0 0 10px rgba(180,180,180,0.22)',
  d3Elevation5: '0 0 38px rgba(180,180,180,0.30), 0 0 12px rgba(180,180,180,0.22)',
  panelElevationRight: (darkMode = false) =>
    `0 0 10px -5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.2), 0 0 24px 2px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },.14), 0 0 30px 5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.12)`,
  panelElevationLeft: (darkMode = false) =>
    `0 0 20px 5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.2), 0 0 24px 2px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },.14), 0 0 30px 5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.12)`,
  panelElevationLeft2: (darkMode = false) =>
    `0 0 6px 0px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.2), 0 0 10px 2px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },.14), 0 0 30px 5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.12)`,
  panelElevationBottom: (darkMode = false) =>
    `0px 0 10px -5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.2), 0px 0 24px 2px rgba(${
      darkMode ? '0, 0, 0' : '180, 180, 180'
    },.14), 0px 0 30px 5px rgba(${darkMode ? '0, 0, 0' : '180, 180, 180'},.12)`,
}
