import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { useTags } from 'components/Dashboard/components/Tags/hooks'
import OptionPicker from 'components/Form/components/OptionPicker'
import Button from 'components/Global/Button'

import TagsStyled from './styled'

const Tags = ({ tags, value = [], onChange, insertTags, resetTags, clearChecker, tagFilter }) => {
  const {
    onSearch,
    searchValue,
    selectedTags,
    filteredTags,
    onValueUpdate,
    onRemoveTag,
  } = useTags({ tags, value, onChange, insertTags, resetTags, clearChecker, tagFilter })

  return (
    <TagsStyled className="Tags">
      <Input
        onChange={onSearch}
        value={searchValue}
        prefix={<SearchOutlined />}
        placeholder="Search to Select"
      />
      <div className="Tags__selected">
        {selectedTags.map((tag) => (
          <Button
            key={tag.key}
            label={tag.label.text}
            variant="tag"
            onIconClick={(e) => onRemoveTag(e, tag)}
            icon="fas fa-xmark"
            color={tag.color}
            isActive
          />
        ))}
      </div>
      <OptionPicker
        value={selectedTags}
        options={filteredTags}
        variant="Tags"
        selectionKeys={{ label: 'label.text', value: 'label.text' }}
        isMultiSelect
        onChange={onValueUpdate}
      />
    </TagsStyled>
  )
}

export default Tags
