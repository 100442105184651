import React from 'react'

import { PropTypes } from 'prop-types'
import { useTheme } from 'styled-components'

import MenuHeader from 'components/Layout/MenuBar/components/MenuHeader'
import Scrollbar from 'components/LayoutComponents/Scrollbar/Scrollbar'

import { Menu } from './styled'

const SecondaryMenu = ({ title, items, setSelectedKeys, localisedLabels }) => {
  const theme = useTheme()

  return (
    <>
      <MenuHeader title={title} />
      <Scrollbar color={theme.color.sidebarText}>
        <Menu
          screenSize={theme.screenSize}
          searchField={{}}
          value={items}
          selectedKeys={setSelectedKeys()}
          variant="menuTree"
          className="settings-menu"
          level={0}
          localisedLabels={localisedLabels}
          defaultOpen
        />
      </Scrollbar>
    </>
  )
}

SecondaryMenu.propTypes = {
  title: PropTypes.string.isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  items: PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    url: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        url: PropTypes.string,
      })
    ),
  }),
}

SecondaryMenu.defaultProps = {}

export default SecondaryMenu
