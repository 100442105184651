import styled, { css } from 'styled-components'

const MenuBar = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 900;

  .MenuBar__wrapper {
    position: relative;
    z-index: 100;
    background: ${({ theme }) => theme.color.sideBar};
  }

  ${({ theme }) =>
    theme.screenSize.screen.current === 's'
      ? css`
          right: 0;

          .MenuBar__wrapper {
            padding: 1rem;
            display: flex;
            width: 100%;
          }
        `
      : css`
          top: 0;

          .MenuPanel.active {
            //transition-delay: ${({ status }) => (status === 'opening' ? '0s' : '0.5s')};
            left: 100px;

            > div {
              opacity: 1;
            }
          }

          .MenuBar__wrapper {
            padding: 9rem 0 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100px;
          }
        `}
`
export default MenuBar
