import React from 'react'

import mime from 'mime-types'

import Icon from './Icon'

const iconExtMap = {
  unknown: 'fas file',
  pdf: 'fas file-pdf',
  xlsx: 'fas file-excel',
  docx: 'fas file-word',
}

const FileIcon = ({ mimeType, className, ...props }) => {
  const ext = mimeType ? mime.extension(mimeType) : 'unknown'
  const icon = iconExtMap[ext] ?? iconExtMap.unknown

  return <Icon {...props} className={`Icon FileIcon file-type-${ext} ${className}`} icon={icon} />
}

export default FileIcon
