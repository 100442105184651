import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  .Button {
    margin-left: 0.8rem;
  }
`
