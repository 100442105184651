import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

export const FlowListFieldStyled = styled(Field)`
  padding: 0.5rem;

  ${Value} {
    width: 100%;
  }
  > ${Value} {
    text-align: left !important;
    > .Field {
      padding: 0;
    }
    .ant-btn {
      width: fit-content;
    }
  }
`
