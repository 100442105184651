export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.eventLog',
    category: 'widget.grids',
    keywords: ['grid', 'table'],
    img: '/resources/images/widgets/eventlog.png',
    info: {
      'fr-FR': '',
      'en-US': '',
      'nl-NL': '',
    },
    default: {
      type: 'AgEventLog',
      title: 'widget.eventLog',
      icon: 'fas fa-info',
      datapoints: [],
      decimals: 2,
    },
    preview: {
      icon: 'fas fa-table',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
    ],
  },
}

export default config
