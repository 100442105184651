import React from 'react'

import { injectIntl } from 'react-intl'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import ColumnPickerStyled from './styled'

const ColumnPicker = ({ value, onChange }) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()

  return (
    <ColumnPickerStyled
      value={value}
      className="ColumnPicker"
      options={elementInEditMode.preview?.gridColumns.map((col, index) => ({
        ...col,
        index,
        disabled: col.valueType !== 'value' && col.valueType !== 'referenceValue',
      }))}
      selectionKeys={{ label: 'headerName', value: 'index' }}
      onChange={onChange}
      isMultiSelect
      valueKey="index"
    />
  )
}

ColumnPicker.propTypes = {}

ColumnPicker.defaultProps = {
  options: [],
}

export default injectIntl(ColumnPicker)
