import React, { useCallback } from 'react'

import { FormattedMessage } from 'react-intl'

import { NoResults, ResultContainer } from './styled'

export const Results = ({
  results,
  close,
  selected,
  clickRef,
  RowComponent,
  onMouseEnter,
  selectMode,
  ...props
}) => {
  const handleMouseEnter = useCallback(
    (index, event) => {
      onMouseEnter({ index }, event)
    },
    [onMouseEnter]
  )

  return (
    <ResultContainer {...props}>
      {results?.length > 0 ? (
        results?.map((result, index) => {
          const isSelected = index === selected
          return (
            <RowComponent
              onMouseEnter={(event) => handleMouseEnter(index, event)}
              data={result}
              index={index}
              selected={index === selected}
              selectMode={selectMode}
              close={close}
              clickRef={isSelected && clickRef}
            />
          )
        })
      ) : (
        <NoResults>
          <FormattedMessage id="search.noResultFound" />
        </NoResults>
      )}
    </ResultContainer>
  )
}

export default Results
