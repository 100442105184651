import { css } from 'styled-components'

export const Widget = css`
  &.title {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.mainText};
  }
  color: ${({ theme }) => theme.color.strongText};
  margin: 0;
`
