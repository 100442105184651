import styled from 'styled-components'

const LocationSearchStyled = styled.div`
  > p {
    color: ${({ theme }) => theme.color.softText};
    margin-bottom: 0;
    opacity: 0.6;
  }
  .ant-input-affix-wrapper {
    border-radius: 0.2rem;
    border-color: ${({ theme }) => theme.color.softStroke};
  }
`
export default LocationSearchStyled
