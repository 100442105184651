export default {
  301: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion de la combustion mobile n'a été trouvé pour le type de véhicule {vehicleTypeName} et le type de carburant {vehicleFuelTypeName}.",
  302: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion de la combustion mobile active n'a été trouvé pour le type de véhicule {vehicleFuelTypeName} et le type de carburant {vehicleFuelTypeName} à la date {datetime}.",
  303: 'Erreur sur le point de données {datapointName}: Aucune conversion trouvée pour {fromUnitName} vers {toUnitName}',
  304: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion de l'énergie achetée n'a été trouvé pour le pays {countryName} et le type d'énergie {energyTypeName}.",
  305: "Erreur sur le point de données {datapointName}: Le compteur {entityId} n'a pas de contrat ",
  306: "Erreur sur le point de données {datapointName}: Aucun contrat d'énergie actif n'a été trouvé pour le compteur {entityId} à la date {datetime}.",
  307: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion pour la combustion stationnaire n'a été trouvé pour le pays {nomdupays} et le type de carburant {nomduproduit}.",
  308: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion actif n'a été trouvé pour le type de carburant {fuelTypeName} et le pays {countryName} à la date {dateTime}.",
  309: "Erreur sur le point de données {datapointName}: Un contrat énergétique n'est pas fourni pour chaque entrée de consommation de kWhdu véhicule {vehicleId}.",
  310: "Erreur sur le point de données {datapointName}: Aucun facteur de conversion actif n'a été trouvé pour le type d'énergie {nom du type d'énergie} et le pays {nom du pays} à la date {dateTime}.",
  311: 'Erreur sur le point de données {datapointName}: Aucune valeur "typeGas" trouvée sur le point de données {entityId}',
  312: 'Erreur sur le point de données {datapointName}: Aucune définition de gaz trouvée pour le typeGas id {gasId} sur le point de données {entityId}',
  313: 'Erreur sur le point de données {datapointName}: No conversionfactor found for entity {entityName} with vehicleType: {vehicleTypeName}, vehicle fueltype: {vehicleFuelTypeName} and transportLoadType: {transportLoadTypeName}',
  314: 'Erreur sur le point de données {datapointName}: No conversionfactor found for entity {entityName} with travelMethod: {travelMethodName}, vehicle fueltype: {vehicleFuelTypeName}',
  315: 'Erreur sur le point de données {datapointName}: Emission Absolute datapoint could not be found on entity {entityName}',
  316: 'Erreur sur le point de données {datapointName}: Emission Biogenic Co2 datapoint could not be found on entity {entityName}',
  401: 'Erreur sur le point de données {datapointName}: Aucune organisation fournie',
  402: 'Erreur sur le point de données {datapointName}: Startdate {start} est postérieure ou égale à enddate {end}',
  403: 'Erreur sur le point de données {datapointName}: Aucune définition de portée trouvée pour la portée {scopeId}',
  404: "Erreur sur le point de données {datapointName}: L'entité avec l'id {entityId} n'existe pas",
  405: "Erreur sur le point de données {datapointName}: Le contrat {contractNem} n'existe pas mais a été utilisé pour le véhicule {entityId}",
  406: 'Erreur sur le point de données {datapointName}: Emissionfactor {emissionfactor} is not available',
  407: 'Erreur sur le point de données {datapointName}: Emissionfactors {emissionfactors} are not available',
  500: 'Erreur sur le point de données {datapointName}: Erreur interne',
}
