import { gql } from '@apollo/client'

export const THEME_QUERY = gql`
  query theme($domain: String!) {
    theme(domain: $domain) {
      id
      hostname
      theme
    }
  }
`
