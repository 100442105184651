import styled from 'styled-components'

import Tooltip from '../../Tooltip'

const ClassificationPickerStyled = styled(Tooltip)`
  display: flex;
  position: relative;
  z-index: ${({ isOpen }) => isOpen && 10};

  > .Button {
    position: relative;
    border-radius: 3px;
    min-height: 3rem;
    border: 1px solid ${({ isOpen, theme }) => (isOpen ? theme.color.main : theme.color.softStroke)};
    width: 100%;
    padding: 0.5rem;
    .ClassificationElement {
      width: 100%;
    }
  }
`

export const ClassificationElementStyled = styled.div`
  padding: ${({ padding }) => padding && '0 .5rem'};
  cursor: pointer;

  .ClassificationElement__values {
    display: flex;
  }

  .ClassificationElement__label {
    font-size: 0.8rem;
  }

  .GraphSection {
    margin-top: 0;
    padding: 0 1rem;
    line-height: 1.4;

    &__label {
      font-size: 0.8rem;
    }
  }
`
export default ClassificationPickerStyled
