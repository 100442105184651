import React, { useState } from 'react'

import { Checkbox, Form } from 'antd'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import TooltipContent from 'components/Global/Tooltip/TooltipContent'

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'column' ? 'column' : 'row')};
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`

const RadioField = ({
  label,
  direction = 'column',
  name,
  intl,
  required,
  customRules = [],
  options,
  mode,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const rules = [...customRules]

  if (required) {
    rules.push({
      required: true,
      message: intl.formatMessage({
        id: mode === 'multiple' ? 'form.select.required' : 'form.select.requiredOne',
      }),
    })
  }

  return (
    <Form.Item name={name} label={label} rules={rules}>
      {props.tooltip ? (
        <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
          <TooltipContent variant="Datapoints" isOpen={isOpen} maxWidth="80rem">
            <div>{props.tooltip}</div>
          </TooltipContent>
          <StyledCheckboxGroup direction={direction} options={options} />
        </div>
      ) : (
        <StyledCheckboxGroup direction={direction} options={options} />
      )}
    </Form.Item>
  )
}

export default injectIntl(RadioField)
