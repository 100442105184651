import styled from 'styled-components'

const FormStyled = styled.div`
  form {
    > .ant-alert {
      margin: 1rem 2rem;
    }
  }
`
export default FormStyled
