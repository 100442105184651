import { css } from 'styled-components'

export const lineScale = css`
    display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: auto;
		div {
            width: ${({ size }) => Math.ceil(size / 6)}px;
            height: ${({ size }) => size}px;
			background: ${({ theme, color }) => (color ? theme.color[color] : theme.color.softText)};
			margin: 0 ${({ size }) => Math.ceil(size / 15)}px;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);
			border-radius: 2px;
			&:nth-child(1) {
				animation: line-scale 1s -.4s infinite cubic-bezier(.2,.68,.18,1.08)
			}
			&:nth-child(2) {
				animation: line-scale 1s -.3s infinite cubic-bezier(.2,.68,.18,1.08);
			}
			&:nth-child(3) {
				animation: line-scale 1s -.2s infinite cubic-bezier(.2,.68,.18,1.08);
			}
			&:nth-child(4) {
				animation: line-scale 1s -.1s infinite cubic-bezier(.2,.68,.18,1.08);
			}
			&:nth-child(5) {
				animation: line-scale 1s 0s infinite cubic-bezier(.2,.68,.18,1.08);
			}
		}
    
}
@keyframes line-scale {
  0% {
    transform: scaleY(1.0);
  }
  50% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(1.0);
  }
}
`
