import React from 'react'

import { SelectField } from 'components/Form'

import { useStateOptions } from './hooks'

export const DateStateSelectField = ({ options, addCurrentOption, onChange, value, ...props }) => {
  const stateOptions = useStateOptions(options, { addCurrentOption })

  const handleOnChange = (id, ...options) => {
    if (id !== value) {
      // only when a different values is selected
      onChange(id, ...options)
    }
  }

  return (
    <SelectField
      options={stateOptions}
      disabled={!stateOptions}
      onChange={handleOnChange}
      value={value}
      {...props}
    />
  )
}

export default DateStateSelectField
