import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import CascaderField from 'components/Form/fields/CascaderField/CascaderField'

export const DropdownRender = styled.div`
  ${({ path, theme }) => css`
    .ant-cascader-menu {
      transition: background 0.2s ease;

      &:nth-child(${path?.length}) {
        background: ${transparentize(0.95, theme.color.main)};
      }

      &:nth-child(5) {
        display: none;
      }
    }
  `}
  .DropdownRender__datapoints {
    background: ${({ theme }) => transparentize(0.95, theme.color.main)};
    padding: 0 1rem 0.5rem;

    div {
      display: flex;
      width: 100%;
    }

    h4 {
      color: ${({ theme }) => theme.color.mainText};
      margin: 0;
      padding: 1rem 0 0.5rem;
      font-size: 0.8rem;
      text-transform: uppercase;
    }

    .Button {
      justify-content: flex-start;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
    }

    .DropdownRender__no-values {
      padding: 0.5rem 0;
      font-size: 0.8rem;
    }
  }

  .DropdownRender__datapoints-container {
    display: flex;
    flex-wrap: wrap;
  }
`

export const GhgDatapointCascaderFieldStyled = styled(CascaderField)`
  .ant-cascader-picker-label {
    color: ${({ hasValue, theme }) => hasValue && theme.color.mainText};
  }
`
