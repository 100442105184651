import React from 'react'

import { Switch as AntSwitch } from 'antd'
import { bool, func, string } from 'prop-types'

import SwitchStyled from './styled'

const Switch = ({ label, checked, disabled, size = 'small', onClick, onChange }) => {
  return (
    <SwitchStyled className="Switch" checked={checked}>
      {label && <span className="Switch__label">{label}</span>}
      <AntSwitch
        checked={checked}
        disabled={disabled}
        size={size}
        onClick={onClick}
        onChange={onChange}
      />
    </SwitchStyled>
  )
}

Switch.propTypes = {
  label: string,
  checked: bool,
  disabled: bool,
  size: string,
  onClick: func,
  onChange: func,
}

Switch.defaultProps = {}

export default Switch
