import styled, { css } from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'

export const TextFieldStyled = styled(Field)`
  .TextInput {
    display: inline-block;
    //overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  ${({ align }) => {
    if (align === 'right') {
      return css`
        ${Value} {
          //overflow: hidden;
          text-align: right !important;
        }
      `
    }
  }}
`

export const PasswordFieldStyled = styled(Field)`
  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    min-height: 100%;
  }
  .ant-input-password {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
  }
`
