import React from 'react'

import PropTypes from 'prop-types'

import Icon from 'components/Global/Icon'

import UserBadgeStyled from './styled'

const UserBadge = ({ initials, avatar }) => {
  return avatar ? (
    <Icon
      icon={avatar}
      style={{
        margin: '0px 0px 0px 5px',
        height: '40px',
        width: '40px',
        borderRadius: '20px',
        cursor: 'pointer',
      }}
    />
  ) : (
    <UserBadgeStyled className="UserBadge">{initials}</UserBadgeStyled>
  )
}

UserBadge.propTypes = {
  prop: PropTypes.arrayOf(PropTypes.object),
}

UserBadge.defaultProps = {}

export default UserBadge
