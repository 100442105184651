import React, { Fragment } from 'react'

import Button from 'components/Global/Button'

import MainMenuStyled from './styled'

const MainMenu = ({ menu, onMenuItemClick, screen }) => {
  return (
    <MainMenuStyled className="MainMenu">
      {menu.map(
        (menuItem) =>
          !(screen === 's' && menuItem.hideOnMobile) && (
            <Fragment key={`menuItem-${menuItem.id}`}>
              <Button
                key={menuItem.name}
                label={menuItem.name}
                icon={menuItem.icon}
                url={menuItem.url}
                variant="MainMenu"
                isActive={menuItem.isActive}
                className={menuItem.name}
                onClick={(e) => onMenuItemClick(e, menuItem)}
              />

              {menuItem.subMenu && (
                <div className="MainMenu__sub">
                  {menuItem.subMenu.map((subMenuItem, index) => (
                    <Button
                      key={`${subMenuItem.name}-${index}`}
                      label={subMenuItem.name}
                      icon={subMenuItem.icon}
                      url={subMenuItem.url}
                      variant="subMenu"
                      isActive={subMenuItem.isActive}
                      onClick={(e) => onMenuItemClick(e, subMenuItem)}
                    />
                  ))}
                </div>
              )}
            </Fragment>
          )
      )}
    </MainMenuStyled>
  )
}

export default MainMenu
