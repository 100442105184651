import React from 'react'

import DropdownArrow from 'components/Global/Dropdown/DropdownArrow/styled'
import { useAccordeonContext } from 'components/LayoutComponents/Accordeon/AccordeonProvider'
import { useAccordeon } from 'components/LayoutComponents/Accordeon/utils'

import { AccordeonContent, AccordeonHeader, AccordeonStyled } from './styled'

const Accordeon = ({
  header,
  arrowSize = '.9rem',
  defaultOpen = false,
  children,
  className,
  recalculateHeight,
  dataCy = 'Accordeon',
}) => {
  const { recalculateHeight: contextRecalculateHeight } = useAccordeonContext()
  const { wrapper, height, setIsOpen, isOpen } = useAccordeon(
    defaultOpen,
    recalculateHeight,
    contextRecalculateHeight
  )

  return (
    <AccordeonStyled className={className}>
      <AccordeonHeader
        onClick={() => setIsOpen((state) => !state)}
        className={`${isOpen ? 'active' : 'inactive'}`}
        data-cy={`${dataCy}-header`}
      >
        {React.Children.count(children) > 0 && arrowSize && (
          <DropdownArrow className="DropdownArrow" size={arrowSize} />
        )}
        {header}
      </AccordeonHeader>

      <AccordeonContent
        initial={{ height: 0 }}
        animate={{ height }}
        className={`AccordeonContent ${isOpen ? 'active' : 'inactive'}`}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {children && <div ref={wrapper}>{children}</div>}
      </AccordeonContent>
    </AccordeonStyled>
  )
}

export default Accordeon
