import React, { useCallback, useState } from 'react'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { PaginationStyled } from './styled'

const Pagination = ({ onChange }) => {
  const [{ page }, setPagination] = useState({ page: 0 })
  const handlePagination = useCallback(
    (direction) => {
      setPagination((state) => {
        const newState =
          direction === 'next'
            ? {
                ...state,
                page: state.page + 1,
              }
            : state.page === 0
            ? state
            : { ...state, page: state.page - 1 }
        onChange(newState.page)
        return newState
      })
    },
    [setPagination, onChange]
  )
  return (
    <PaginationStyled className="Pagination">
      <LeftOutlined onClick={() => handlePagination('prev')} />
      <span>{page + 1}</span>
      <RightOutlined onClick={() => handlePagination('next')} />
    </PaginationStyled>
  )
}

export default Pagination
