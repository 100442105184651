import React, { useRef } from 'react'

import { HvacAuto } from 'components/CustomIcons/HvacAuto'
import { HvacComfort } from 'components/CustomIcons/HvacComfort'
import { HvacEco } from 'components/CustomIcons/HvacEco'
import { HvacFrost } from 'components/CustomIcons/HvacFrost'
import { HvacStandby } from 'components/CustomIcons/HvacStandby'
import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'

import { HvacButton, ModeControlStyled } from './styled'

const valueIcons = {
  0: <HvacAuto />,
  1: <HvacComfort />,
  2: <HvacStandby />,
  3: <HvacEco />,
  4: <HvacFrost />,
}

const ModeControl = ({
  value,
  unit,
  datapoint = {},
  writeDatapoint = {},
  confirmation,
  onConfirmationRequest,
}) => {
  const element = useRef()
  const lastValue = String(value)

  const getActive = (mode) => {
    if (lastValue === mode) {
      return 1
    }
    if (lastValue && lastValue.length > 1 && lastValue.charAt(1) === mode) {
      return 'auto'
    }
    return null
  }

  const getAutoMode = (mode) => {
    if (lastValue === mode) {
      return 1
    }
    if (lastValue && lastValue.length > 1) {
      return 1
    }
    return 0
  }

  const handleClick = (mode) => ({ currentTarget }) => {
    onConfirmationRequest({ value: mode })
    currentTarget.blur()
  }

  return (
    <ModeControlStyled className="ModeControl" ref={element}>
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        parentRef={element.current}
        id={writeDatapoint?.id || datapoint.id}
        onConfirmationRequest={onConfirmationRequest}
      >
        {valueIcons[confirmation.value] || valueIcons[0]}
      </ActionConfirmationWithAdjustableValue>
      {unit === 'hvac_modus' ? (
        <>
          <HvacButton onClick={handleClick('0')} active={getAutoMode('0')}>
            <HvacAuto />
          </HvacButton>
          <HvacButton onClick={handleClick('1')} active={getActive('1')}>
            <HvacComfort />
          </HvacButton>
          <HvacButton onClick={handleClick('2')} active={getActive('2')}>
            <HvacStandby />
          </HvacButton>
          <HvacButton onClick={handleClick('3')} active={getActive('3')}>
            <HvacEco />
          </HvacButton>
          <HvacButton onClick={handleClick('4')} active={getActive('4')}>
            <HvacFrost />
          </HvacButton>
        </>
      ) : null}
    </ModeControlStyled>
  )
}

export default ModeControl
