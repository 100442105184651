import SimpleBar from 'simplebar-react'
import styled from 'styled-components'

import MenuList from '../MenuList'

export const Menu = styled(MenuList)`
  .MenuListItem {
    /* Sub items */
    &.menu-open .MenuList .Button {
      padding: 0.2rem 0.2rem 1rem 2.5rem;
    }

    .Button__label {
      white-space: inherit !important;
    }
  }
`

export const ScrollableContainer = styled(SimpleBar)`
  height: calc(100vh - 4rem);
  .simplebar-scrollbar {
    opacity: 0.25;
  }
`
