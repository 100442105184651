import styled from 'styled-components'

import { ellipsis } from './styled.ellipsis'
import { grid } from './styled.grid'
import { lineScale } from './styled.lineScale'
import { ring } from './styled.ring'

const variants = {
  ring,
  grid,
  ellipsis,
  lineScale,
}

const SpinnerStyled = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ${({ variant }) => variants[variant]}
`
export default SpinnerStyled
