import React, { forwardRef, useRef, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { debounce } from 'throttle-debounce'

import { useOnClickOutside } from 'components/Global/hooks'

import SearchStyled from './styled'

const Search = forwardRef(({ onSearch, ...props }, ref) => {
  const [showSearch, setShowSearch] = useState(false)
  const element = useRef(null)
  useOnClickOutside(element, () => setShowSearch(false))
  const handleOnChange = debounce(300, (value) => onSearch({ value }))
  const onChange = (e) => {
    e.persist()
    handleOnChange(e.target.value)
  }
  return (
    <SearchStyled className={`Search`} showSearch={showSearch} ref={element}>
      <Input onChange={onChange} prefix={<SearchOutlined />} allowClear {...props} ref={ref} />
    </SearchStyled>
  )
})

export default Search
