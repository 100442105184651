import React from 'react'

import { DatePicker as AntDatePicker } from 'antd'
import PropTypes from 'prop-types'

import DatePickerStyled from './styled'

const DatePicker = ({
  disabled,
  value,
  placeholder,
  format,
  disabledDate,
  disabledTime,
  showToday,
  showTime,
  range,
  onChange,
  onOpenChange,
  open,
}) => {
  const _onDatePickerChange = (payload) => {
    onChange({ value: payload, range })
  }
  const onClick = (e) => {
    e.stopPropagation()
  }
  return (
    <DatePickerStyled className="DatePicker" onClick={onClick}>
      {range ? (
        <AntDatePicker.RangePicker
          onCalendarChange={_onDatePickerChange}
          disabled={disabled}
          disabledDate={disabledDate}
          showToday={showToday}
          showTime={showTime}
          value={value}
          placeholder={placeholder}
          onOpenChange={onOpenChange}
        />
      ) : (
        <AntDatePicker
          onChange={_onDatePickerChange}
          disabled={disabled}
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          showToday={showToday}
          showTime
          format={format}
          value={value}
          placeholder={placeholder}
          open={open}
          onOpenChange={onOpenChange}
        />
      )}
    </DatePickerStyled>
  )
}

DatePicker.propTypes = {
  selectOptions: PropTypes.array,
}

DatePicker.defaultProps = {
  selectOptions: [],
}

export default DatePicker
