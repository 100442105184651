import { useCallback, useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

const QUERY_ENABLED_MODULES = gql`
  query Company($companyId: Float!) {
    company(companyId: $companyId) {
      id
      modules {
        id
        key
      }
    }
  }
`

export const useHasModule = () => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ENABLED_MODULES, {
    variables: {
      companyId: customer?.id,
    },
    skip: !customer?.id,
  })

  const enabledModuleKeys = useMemo(() => data?.company?.modules?.map((m) => m.key) || [], [data])

  const hasAccess = useCallback(
    (key) => {
      const keys = typeof key === 'string' ? [key] : key
      return keys.some((key) => enabledModuleKeys.includes(key))
    },
    [enabledModuleKeys]
  )

  return [hasAccess, info]
}
