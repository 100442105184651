import { zip } from 'lodash'

import { CALCULATIONS } from '../../../util/constants'

export const getSparkLineHistory = (data, method = 'sum') => {
  let history = []
  if (!data?.[0]?.history) {
    return history
  }
  if (data.length === 1) {
    history = data[0].history.map((entry) => entry.value)
  } else {
    history = zip(...data.map((dp) => dp.history.map(({ value }) => value))).map((value) => {
      return CALCULATIONS[method](value)
    })
  }
  if (history.every((value) => value === 0)) {
    return []
  }
  const min = CALCULATIONS.min(history)
  if (min < 0) {
    return history.map((v) => v + -min)
  }
  return history
}
