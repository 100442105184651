import { message } from 'antd'

export const initialState = {
  notifications: [],
  deleted: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: action.payload.value }
    case 'ADD_NOTIFICATION':
      return { ...state, notifications: [action.payload.value, ...state.notifications] }
    case 'DELETE_NOTIFICATION':
      const deletedIds = [...state.deleted, action.payload.value]
      message.destroy(action.payload.value)
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => !deletedIds.includes(id)),
        deleted: deletedIds,
      }
    default:
      return state
  }
}

export default reducer
