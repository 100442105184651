import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'

import FormField from '../../../Global/FormField'
import CheckboxSelector from '../../../Global/FormField/Selector/styled.CheckboxSelector'
import { FormFieldLabel } from '../../../Global/FormField/styled'

const controlTypes = [
  {
    value: 'none',
    label: {
      formatted: 'widget.none',
    },
  },
  {
    value: 'increment',
    label: {
      formatted: 'widget.plusMinus',
    },
  },
  {
    value: 'toggle',
    label: {
      formatted: 'widget.onOff',
    },
  },
  {
    value: 'mode',
    label: {
      formatted: 'widget.mode',
    },
  },
]

export const DatapointLabelConfigurator = ({ value = {}, subPath = '', onChange }) => {
  const [controlType, setControlType] = useState(value.controlType || 'none')

  useEffect(() => {
    setControlType(value.controlType)
  }, [value.controlType])

  const handleSelectControlType = (e, { value }) => {
    setControlType(value)
    onChange({ value, dataKey: 'controlType' })
  }

  return (
    <div className="DatapointLabelConfigurator">
      <FormField
        value={value.label}
        dataKey="label"
        label={{ formatted: 'widget.label' }}
        componentName="Input"
        onChange={onChange}
      />
      <FormField
        value={value.color}
        dataKey="color"
        col="col2 first"
        label={{ formatted: 'widget.color' }}
        componentName="ColorPicker"
        onChange={onChange}
      />
      <FormField
        value={value.enlargeLabelOnHover}
        dataKey="enlargeLabelOnHover"
        col="col2"
        label={{ formatted: 'widget.enlargeLabelOnHover' }}
        componentName="Switch"
        onChange={onChange}
      />
      <FormFieldLabel>
        <FormattedMessage id="widget.controlButtons" />
      </FormFieldLabel>
      <CheckboxSelector
        className="CheckboxSelector"
        options={controlTypes}
        value={controlType}
        dataKey="controlType"
        onSelect={handleSelectControlType}
      />
      {controlType === 'toggle' && (
        <>
          <FormField
            value={value.iconOn}
            dataKey="iconOn"
            col="col4"
            label={{ formatted: 'widget.iconOn' }}
            componentName="IconPicker"
            componentProps={{
              bottomDrawer: true,
            }}
            onChange={onChange}
          />
          <FormField
            value={value.colorOn}
            dataKey="colorOn"
            col="col4"
            label={{ formatted: 'widget.colorOn' }}
            componentName="ColorPicker"
            onChange={onChange}
          />
          <FormField
            value={value.iconOff}
            dataKey="iconOff"
            col="col4"
            label={{ formatted: 'widget.iconOff' }}
            componentName="IconPicker"
            componentProps={{
              bottomDrawer: true,
            }}
            onChange={onChange}
          />
          <FormField
            value={value.colorOff}
            dataKey="colorOff"
            col="col4"
            className="align-right"
            label={{ formatted: 'widget.colorOff' }}
            componentName="ColorPicker"
            onChange={onChange}
          />
        </>
      )}
      {controlType === 'increment' && (
        <>
          <FormField
            value={value.range || [0, 0]}
            dataKey="range"
            col="col2 first"
            label={{ formatted: 'widget.range' }}
            componentName="NumberRangeInput"
            onChange={onChange}
          />
          <FormField
            value={value.increments}
            dataKey="increments"
            col="col2"
            label={{ formatted: 'widget.incrementValue' }}
            componentName="NumberInput"
            onChange={onChange}
          />
        </>
      )}
      {controlType === 'mode' && (
        <>
          <FormField
            value={value.buttons || []}
            dataKey="buttons"
            label={{ formatted: 'widget.buttons' }}
            componentName="CaptionEditor"
            componentProps={{
              columns: ['value', 'icon', 'color'],
            }}
            onChange={onChange}
          />
        </>
      )}
      {controlType !== 'none' && (
        <FormField
          value={
            value.writeDatapoint
              ? { writeDatapoint: [value.writeDatapoint] }
              : { writeDatapoint: [] }
          }
          label={{ formatted: 'widget.writeDatapoint' }}
          dataKey="writeDatapoint"
          subPath={subPath}
          componentName="Datapoints"
          componentProps={{
            dataKey: 'writeDatapoint',
            singleDatapoint: true,
          }}
          onChange={({ value, dataKey }) =>
            onChange({ value, dataKey: dataKey || 'writeDatapoint' })
          }
        />
      )}
    </div>
  )
}
