import { InputNumber } from 'antd'
import styled from 'styled-components'

export const InputNumberStyled = styled(InputNumber)`
  width: 100%;
  background-color: transparent;
  border-color: ${({ theme }) => theme.color.softStroke};

  .ant-input-number-handler-wrap {
    background: ${({ theme }) => theme.color.backgroundHighlight};
    border-color: ${({ theme }) => theme.color.softStroke};
  }

  .ant-input-number-handler {
    color: ${({ theme }) => theme.color.mainText};
    border-color: ${({ theme }) => theme.color.softStroke};

    path {
      fill: ${({ theme }) => theme.color.mainText};
    }
  }

  .ant-input-number-input-wrap {
    padding-right: 14px;
    color: ${({ theme }) => theme.color.mainText};
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.color.error};
  }
`

export const SuffixWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${InputNumberStyled} {
    padding-right: ${({ suffixWidth }) => suffixWidth + 10}px;
  }
`

export const Suffix = styled.span`
  position: absolute;
  right: 35px;
  z-index: 100;
`
