import React from 'react'

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Controller } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { getLocalizedFormattedDate } from 'util/timeConversionFunctions'

import FieldMessage from 'components/Form/components/Field/FieldMessage'
import Skeleton from 'components/Form/components/Skeleton/styled'
import { DateFieldStyled } from 'components/Form/fields/DateField/styled'

const DateField = ({
  value,
  defaultValue,
  name,
  label,
  format = 'PPpp',
  intl,
  control,
  showTime,
  allowClear,
  validation,
  onChange,
  disabled,
  showRequired,
  disabledDate,
  disabledTime,
  picker,
  submitValueFormatter = (date) => (date ? date.toISOString() : date),
  parse = (value) => (value ? dayjs(value) : undefined),
  showToday,
  showNow,
  ...fieldProps
}) => {
  const datePickerProps = {
    disabled,
    disabledDate,
    disabledTime,
    picker,
    allowClear,
    showToday,
    showNow,
  }

  if (name) {
  }

  return (
    <DateFieldStyled {...fieldProps} label={label} required={showRequired && validation?.required}>
      {({ isEditMode, watch, loading, errors }) => {
        const error = get(errors, name)

        if (loading) {
          return <Skeleton width="200px" />
        }

        if (isEditMode) {
          if (name) {
            return (
              <Controller
                name={name}
                defaultValue={defaultValue}
                control={control}
                rules={validation}
                render={({ onChange: fieldOnChange, value }) => {
                  return (
                    <>
                      <DatePicker
                        onChange={(date) => {
                          fieldOnChange(submitValueFormatter(date))
                          if (typeof onChange === 'function') {
                            onChange(date)
                          }
                        }}
                        value={parse(value)}
                        {...{ showTime, allowClear }}
                        {...datePickerProps}
                      />
                      <FieldMessage message={error} label={label} />
                    </>
                  )
                }}
              />
            )
          }

          return (
            <DatePicker
              value={value}
              onChange={onChange}
              disabled={disabled}
              {...datePickerProps}
            />
          )
        }

        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        const date = fieldValue && getLocalizedFormattedDate(parse(fieldValue), format, intl.locale)

        return <span>{date}</span>
      }}
    </DateFieldStyled>
  )
}

export default injectIntl(DateField)
