import styled from 'styled-components'

import { Widget } from './styled.Widget'

const variants = {
  Widget,
}

const TextStyled = styled.p`
  ${({ variant }) => variants[variant]}
  ${({ type }) => variants[type]}
`
export default TextStyled
