import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { useNonAssetEntities } from 'services/entities'
import { useVehicleTypesForCompany } from 'services/nonAssetEntityTypes'
import { useOrganisationForms } from 'services/organisations'

import {
  getNonAssetTreeByDataSource,
  getTreeConfigByDataSource,
} from 'components/Dashboard/components/NonAssetEntityTree/functions'
import { SearchableTree } from 'components/Global/Tree'

import EntityTreeStyled from './styled'

const typeIdMap = {
  vehicles: [12],
}

const NonAssetEntityTree = ({ dataSource, onChange }) => {
  const { data: entities } = useNonAssetEntities(typeIdMap[dataSource], !dataSource)
  const { data: departments } = useOrganisationForms({ type: 'Department' })
  const { data: vehicleTypes } = useVehicleTypesForCompany()

  const vehicleTypeList = useMemo(
    () => (vehicleTypes ? vehicleTypes.map((v) => ({ ...v, name: v.description })) : []),
    [vehicleTypes]
  )

  const treeConfig = useMemo(
    () =>
      getTreeConfigByDataSource({
        dataSource,
        departments,
        vehicleTypeList,
      }),
    [dataSource, departments, vehicleTypeList]
  )

  const tree = useMemo(() => getNonAssetTreeByDataSource(entities, treeConfig, dataSource), [
    entities,
    treeConfig,
    dataSource,
  ])

  return (
    <EntityTreeStyled className="EntityTree">
      <SearchableTree value={tree} level={0} elementHeight={30} show onButtonClick={onChange} />
    </EntityTreeStyled>
  )
}

export default injectIntl(NonAssetEntityTree)
