import React, { useRef } from 'react'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'

import { PlusMinusControlButton, PlusMinusControlStyled } from './styled'

const PlusMinusControl = ({
  min,
  max,
  increments = 1,
  value,
  writeDatapoint = {},
  datapoint,
  unit,
  confirmation,
  onConfirmationRequest,
}) => {
  const element = useRef()
  const currentValue = confirmation.value === null ? value : confirmation.value
  const increment = () => {
    let newValue = Number(currentValue) + Number(increments)
    newValue = max ? Math.min(newValue, max) : newValue
    onConfirmationRequest({ value: newValue })
  }

  const decrement = () => {
    let newValue = Number(currentValue) - Number(increments)
    newValue = min ? Math.max(newValue, min) : newValue
    if (newValue !== currentValue) {
      onConfirmationRequest({ value: newValue })
    }
  }

  return (
    <PlusMinusControlStyled className="PlusMinusControl" ref={element}>
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        parentRef={element.current}
        id={writeDatapoint?.id || datapoint?.id}
        unit={unit}
        onConfirmationRequest={onConfirmationRequest}
      />
      <PlusMinusControlButton
        onClick={({ target }) => {
          target.blur()
          increment()
        }}
      >
        <PlusOutlined />
      </PlusMinusControlButton>
      <PlusMinusControlButton
        onClick={({ target }) => {
          target.blur()
          decrement()
        }}
      >
        <MinusOutlined />
      </PlusMinusControlButton>
    </PlusMinusControlStyled>
  )
}

export default PlusMinusControl
