import { lighten, transparentize } from 'polished'
import { css } from 'styled-components'

const antStyling = css`
  input,
  .ant-input,
  .ant-input-number-input,
  .ant-input-number .ant-input-number-input,
  .ant-select-selection-item,
  .ant-select-disabled .ant-select-selector,
  .ant-input-disabled,
  .ant-input[disabled],
  .ant-picker,
  .ant-picker .ant-picker-input input,
  .ant-picker.ant-picker-disabled,
  .ant-picker .ant-picker-input > .input-disabled,
  .ant-picker .ant-picker-input > input[disabled],
  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select .ant-select-selector input,
  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-select-selection-search-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover,
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked,
  .ant-input-affix-wrapper,
  .ant-radio-button-wrapper,
  .ant-input-affix-wrapper input {
    color: ${({ theme }) => theme.color.mainText};
    background-color: transparent;
    border-color: ${({ theme }) => theme.color.softStroke};

    ::placeholder,
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.color.mainText};
    }
  }

  .ant-input-number-group .ant-input-number-group-addon {
    background: ${({ theme }) => theme.color.softerStroke};
  }

  .ant-select .ant-select-arrow,
  .ant-cascader-menu-item-expand-icon {
    color: ${({ theme }) => theme.color.mainText} !important;
  }

  .ant-picker {
    .ant-picker-clear {
      background: ${({ theme }) => theme.color.backgroundFront};
    }

    path {
      color: ${({ theme }) => theme.color.mainText};
    }
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: ${({ theme }) => theme.color.backgroundFront};
    }

    .ant-picker-range-arrow::before {
      background-color: ${({ theme }) => theme.color.backgroundFront};
    }

    .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background-color: ${({ theme }) => theme.color.softStroke};
    }

    .ant-picker-content th,
    .ant-picker-header,
    .ant-picker-header button,
    .ant-picker-header button:hover,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
    .ant-picker-cell {
      color: ${({ theme }) => theme.color.mainText};
    }
  }

  .ant-btn-sm,
  .ant-btn,
  .ant-btn-default:disabled,
  .ant-modal-footer button {
    background: transparent;

    :not(:disabled):hover {
      border-color: ${({ theme }) => lighten(0.1, theme.color.action)} !important;
    }

    &.ant-btn-primary:not(:disabled) {
      border-color: ${({ theme }) => theme.color.action};
      background: ${({ theme }) => theme.color.action};
      color: ${({ theme }) => theme.color.white};

      &:hover {
        background: ${({ theme }) => lighten(0.1, theme.color.action)} !important;
        border-color: ${({ theme }) => lighten(0.1, theme.color.action)} !important;
      }
    }

    &.ant-input-search-button {
      border-color: ${({ theme }) => theme.color.softStroke};
    }
  }

  .anticon {
    color: ${({ theme }) => theme.color.mainText};
  }

  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.color.backgroundFront};

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
      background-color: ${({ theme }) => transparentize(0.95, theme.color.mainText)};
    }
  }

  .ant-dropdown,
  .ant-dropdown .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: ${({ theme }) => theme.color.backgroundFront};
  }

  .ant-popover {
    .ant-popover-inner {
      background-color: ${({ theme }) => theme.color.backgroundFront};
    }

    .ant-popover-arrow {
      --antd-arrow-background-color: ${({ theme }) => theme.color.backgroundFront};
    }
  }

  .ant-picker-cell-disabled,
  .ant-btn-default:disabled,
  .ant-select-dropdown .ant-select-item-option-disabled,
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover,
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked,
  .ant-picker.ant-picker-disabled,
  .ant-select-dropdown .ant-cascader-menu-item-disabled {
    color: ${({ theme }) => theme.color.mainText};
    opacity: 0.5;
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view) {
    opacity: 0.2;
  }

  .ant-collapse .ant-collapse-content,
  .ant-card,
  .ant-card .ant-card-head,
  .ant-list,
  .ant-table-wrapper .ant-table {
    background-color: transparent;
    color: ${({ theme }) => theme.color.mainText};
    border-color: ${({ theme }) => theme.color.softStroke};
  }

  .ant-modal .ant-modal-content,
  .ant-modal .ant-modal-header {
    background-color: ${({ theme }) => theme.color.backgroundFront};
  }

  .ant-tabs-tab-active {
    background-color: ${({ theme }) => transparentize(0.9, theme.color.mainText)} !important;
    border-bottom: none !important;
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-table-wrapper {
    div.ant-table-tbody > tr.ant-table-row:hover,
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: ${({ theme }) => theme.color.main};
    }

    .ant-table-tbody > tr > td {
      border-color: ${({ theme }) => theme.color.softerStroke};
    }

    .ant-table-thead > tr > th,
    .ant-table-thead .column-user-actions {
      color: ${({ theme }) => theme.color.mainText};
      border-color: ${({ theme }) => theme.color.softStroke};
      background-color: ${({ theme }) => theme.color.backgroundHighlight} !important;
    }

    td.ant-table-column-sort {
      background: ${({ theme }) => theme.color.backgroundFront};
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: ${({ theme }) => theme.color.backgroundHighlight} !important;
    }

    .ant-table-thead th.ant-table-column-sort {
      background: ${({ theme }) => theme.color.backgroundFront};
    }

    .ant-table-thead
      > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td,
    .ant-table-tbody
      > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background-color: ${({ theme }) => transparentize(0.9, theme.color.main)};
    }

    .ant-table-thead > tr > td {
      border-color: ${({ theme }) => theme.color.softStroke};
    }

    .ant-table-tbody .ant-table-row-selected > .ant-table-cell {
      background-color: ${({ theme }) => transparentize(0.8, theme.color.main)} !important;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th {
      position: relative;
      padding: 12px 8px;
      overflow-wrap: break-word;
    }
  }

  .ant-form-item-explain {
    font-size: 0.9rem !important;
  }

  .ant-message {
    display: flex;
    flex-direction: column;
    align-items: end;
    top: 155px !important;
    right: 1rem !important;
  }
  .ant-message-notice-content {
    position: relative;
  }

  .ant-tabs-tab-active {
    background-color: transparent !important;

    .ant-tabs-tab-btn {
      text-shadow: none !important;
    }
  }

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0 0 16px;
  }

  .ant-tabs-content-top {
    margin-top: 1rem;
  }

  .ant-message {
    font-weight: 600;
  }
`

export default antStyling
