import React from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'

import { coreConfig } from 'services/store'

import Button from 'components/Global/Button'
import Icon from 'components/Global/Icon'
import { Title } from 'components/Layout/Content'
import { useNotification } from 'components/Layout/Notifications'
import NotificationProgress from 'components/Layout/Notifications/NotificationProgress'

import NotificationsStyled, {
  Notification,
  NotificationContainer,
  NotificationTooltip,
} from './styled'

const Notifications = () => {
  const theme = useTheme()

  const {
    state: { notifications },
    action,
  } = useNotification()

  const handleDelete = async (id) => {
    action({
      type: 'DELETE_NOTIFICATION',
      payload: {
        value: id,
      },
    })
    const coreconfig = coreConfig()
    try {
      await axios.delete(coreconfig.url + `notification/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + coreconfig.token,
        },
      })
    } catch (e) {}
  }

  return (
    <NotificationsStyled className="Notifications">
      <NotificationTooltip
        valueElement={<Icon icon="fas fa-bell" />}
        width={400}
        hasNotifications={!!notifications.length}
        lastNotificationType={notifications[0]?.type}
        variant="Notifications"
      >
        <NotificationContainer>
          <Title className="Title">
            <FormattedMessage
              id={notifications.length === 1 ? 'notificationsCountSingle' : 'notificationsCount'}
              values={{ amount: notifications.length }}
            />
          </Title>
          <div className="NotificationContainer__scroll">
            {notifications.map(
              ({
                id,
                formattedLabel,
                label,
                buttons,
                inProgress,
                icon,
                type = 'info',
                startTime,
              }) => {
                return (
                  <Notification key={id}>
                    <Icon icon={icon} variant="notification" color={theme.color[type]} />
                    <div className="Notification__content">
                      <h4 className="Notification__title">
                        {formattedLabel ? (
                          <FormattedMessage
                            id={formattedLabel.id}
                            values={formattedLabel.details}
                          />
                        ) : (
                          <span dangerouslySetInnerHTML={{ __html: label }} />
                        )}
                      </h4>
                      <div className="Notification__details">
                        {startTime && (
                          <span className="Notification__date">{dayjs(startTime).fromNow()}</span>
                        )}
                      </div>
                      {inProgress && <NotificationProgress />}
                    </div>
                    {buttons?.map((button) => (
                      <Button {...button} variant="smallButton" />
                    ))}
                    <Icon
                      className="Notification__delete"
                      icon="fas fa-trash"
                      onClick={() => handleDelete(id)}
                    />
                  </Notification>
                )
              }
            )}
          </div>
        </NotificationContainer>
      </NotificationTooltip>
    </NotificationsStyled>
  )
}

export default Notifications
