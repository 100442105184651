import styled, { css } from 'styled-components'

import Button from './index'

export const OpenCloseButton = styled(Button)`
  transition: box-shadow 0.3s ease;
  background: ${({ theme }) => theme.color.highlight};
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 100%;
  box-shadow: 0 10px 20px rgba(80, 80, 80, 0.19), 0 6px 6px rgba(80, 80, 80, 0.23);
  &:hover {
    box-shadow: 0 19px 38px rgba(80, 80, 80, 0.3), 0 15px 12px rgba(80, 80, 80, 0.22);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      .Button__label {
        transform: ${({ width }) => (width === 0 ? 'rotateY(0deg)' : 'rotateY(180deg)')};
        transform-origin: top center;
        &:before {
          transform: rotate(45deg) translateX(-2px) translateY(2px);
        }
      }
    `}
  .Button__label {
    transition: transform 0.3s ease;
    position: absolute;
    width: 35%;
    height: 35%;
    transform-style: preserve-3d;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-top: 1px solid ${({ theme }) => theme.color.highlightText};
      border-right: 1px solid ${({ theme }) => theme.color.highlightText};
      transform: rotate(45deg) translateX(-2px) translateY(2px);
    }
  }
`
