import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

const QUERY_ENERGY_TYPES = gql`
  query energyTypes($companyId: ID!) {
    energyTypes(companyId: $companyId) {
      id
      energySource
      standardUnit {
        id
        name
        alias
      }
      renewable
      tags
    }
  }
`

export const useEnergyTypes = () => {
  const customer = useCurrentCustomer()
  const companyId = customer?.id
  const { data, ...rest } = useQuery(QUERY_ENERGY_TYPES, {
    variables: { companyId },
    fetchPolicy: 'network-only',
  })
  return { data: data?.energyTypes, ...rest }
}

const QUERY_FUEL_TYPES = gql`
  query fuelTypes($companyId: ID!) {
    fuelTypes(companyId: $companyId) {
      id
      name
      standardUnit {
        id
        name
        alias
      }
      tags
    }
  }
`

export const useFuelTypes = () => {
  const customer = useCurrentCustomer()
  const companyId = customer?.id
  const { data, ...rest } = useQuery(QUERY_FUEL_TYPES, {
    variables: { companyId },
    fetchPolicy: 'network-only',
  })
  return { data: data?.fuelTypes, ...rest }
}
