import styled, { css } from 'styled-components'

import { clearIcon } from './styled.clearIcon'
import { notification } from './styled.notification'

const variants = {
  clearIcon,
  notification,
}

const IconStyled = styled.i`
  color: ${({ color }) => color};
  ${({ variant }) => variants[variant]}
  ${({ iconType }) =>
    iconType === 'img' &&
    css`
      width: 100%;
    `}
`
export default IconStyled
