import styled from 'styled-components'

const DashboardPickerStyled = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .Input {
    width: 100%;
  }

  .Tree {
    flex: 1;
    overflow: auto;
  }
`
export default DashboardPickerStyled
