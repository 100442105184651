export default {
  'widget.insufficientRights': 'You have insufficient rights to access this widget',
  'widget.noDataForDataPoint': 'No data found for datapoint(s)',
  'widget.noDatapointsSelected': 'No datapoint(s) selected',
  'widget.noInternetConnection': 'No internet connection',
  'widget.configureYourWidget': 'Configure your widget',
  'widget.selectYourWidget': 'Select your widget',
  'widget.selectDatapoints': 'Select datapoints',
  'widget.selectDatapoint': 'Select a datapoint',
  'widget.selectedDatapoint': 'Selected datapoint',
  'widget.selectedDatapoints': 'Selected datapoints',
  'widget.selectYourDatapoints': 'Select your datapoints',
  'widget.selectDatapointsForThisWidget': 'Select your datapoints for this widget',
  'widget.label': 'Label',
  'widget.icon': 'Icon',
  'widget.icons': 'Icons',
  'widget.size': 'Size',
  'widget.link': 'Link',
  'widget.dashboard': 'Dashboard',
  'widget.section': 'Section',
  'widget.url': 'Url',
  'widget.color': 'Color',
  'widget.colors': 'Colors',
  'widget.colorsPercentage': 'Colors (%)',
  'widget.method': 'Method',
  'widget.calculationMethod': 'Calculation method',
  'widget.calculationMethodMin': 'Calculation method min',
  'widget.calculationMethodMax': 'Calculation method max',
  'widget.difference': 'Difference',
  'widget.sum': 'Sum',
  'widget.mean': 'Mean',
  'widget.min': 'Min',
  'widget.max': 'Max',
  'widget.chart': 'Chart',
  'widget.lines': 'Lines',
  'widget.datapoints': 'Datapoints',
  'widget.datapoint': 'Datapoint',
  'widget.getDataPointsForEntity': 'Get datapoints for entity:',
  'widget.search': 'Search...',
  'widget.entities': 'Entities',
  'widget.selectedEntity': 'Selected entity',
  'widget.tagsAndMarkers': 'Tags & markers',
  'widget.noData': 'No data',
  'widget.ok': 'Ok',
  'widget.back': 'Back',
  'widget.next': 'Next',
  'widget.showUnit': 'Show unit',
  'widget.hideValue': 'Hide value',
  'widget.caption': 'Caption',
  'widget.captions': 'Captions',
  'widget.okValue': 'OK value',
  'widget.warning': 'Warning',
  'widget.error': 'Error',
  'widget.inProgress': 'In progress',
  'widget.value': 'Value',
  'widget.type': 'Type',
  'widget.inactive': 'Inactive',
  'widget.yearOverview': 'Year overview',
  'widget.kpiWidget': 'KPI',
  'widget.navigation': 'Navigation',
  'widget.lastValue': 'Last value',
  'widget.lastValueDateTime': 'Last Value Date Time',
  'widget.expectedDateTime': 'Expected Date Time',
  'widget.timeOverdue': 'Time Overdue',
  'widget.consumption': 'Consumption',
  'widget.gauge': 'Gauge',
  'widget.absoluteGauge': 'Absolute Gauge',
  'widget.relativeGauge': 'Relative Gauge',
  'widget.deviation': 'Max deviation in %',
  'widget.circleGauge': 'Circle gauge',
  'widget.comparisonPieChart': 'Pie Chart',
  'widget.referenceValue': 'Reference value',
  'widget.reference': 'Reference',
  'widget.unit': 'Unit',
  'widget.timeUnit': 'Time unit',
  'widget.time': 'Time',
  'widget.defaultStartTime': 'Default start time',
  'widget.seconds': 'Seconds',
  'widget.minutes': 'Minutes',
  'widget.hours': 'Hours',
  'widget.day': 'Day',
  'widget.days': 'Days',
  'widget.week': 'Week',
  'widget.weeks': 'Weeks',
  'widget.month': 'Month',
  'widget.months': 'Months',
  'widget.year': 'Year',
  'widget.years': 'Years',
  'widget.granularity': 'Granularity',
  'widget.showTotals': 'Show totals',
  'widget.hideRawData': 'Raw data available',
  'widget.rawData': 'Raw data',
  'widget.showRawDataByDefault': 'Raw data default on',
  'widget.datapointTotals': 'Datapoint totals',
  'widget.widgetConfiguration': 'Widget configuration',
  'widget.comparisonPoint': 'Comparison point',
  'widget.percentage': 'Percentage',
  'widget.range': 'Range',
  'widget.minMaxValue': 'Min/max value',
  'widget.cancel': 'Cancel',
  'widget.save': 'Save',
  'widget.saveWidget': 'Save widget',
  'widget.deltaValue': 'Delta value',
  'widget.data': 'Data',
  'widget.opacity': 'Opacity',
  'widget.none': 'None',
  'widget.fixedValue': 'Fixed value',
  'widget.fixedValues': 'Fixed values',
  'widget.clearAllDatapoints': 'Clear all datapoints',
  'widget.clearDatapoint': 'Clear datapoint',
  'widget.clear': 'Clear',
  'widget.noLink': 'No link',
  'widget.placeholder': 'Placeholder',
  'widget.emptySpace': 'Empty space',
  'widget.noConfigurationOptions': 'No configuration options',
  'widget.showPercentage': 'Show percentage',
  'widget.showInvertedValue': 'Show inverted value',
  'widget.heatmap': 'Heatmap',
  'widget.decimals': 'Decimals',
  'widget.status': 'Status',
  'widget.showDescription': 'Show description',
  'widget.trendChartWidget': 'Trend Chart Widget',
  'widget.info': 'Info',
  'widget.useSteps': 'Use steps',
  'widget.invertValues': 'Invert values',
  'widget.preset': 'Preset',
  'widget.presetLabel': 'Preset label',
  'widget.efficiencyGauge': 'Efficiency gauge',
  'widget.grid': 'Grid',
  'widget.enableExport': 'Enable export',
  'widget.calendar': 'Calendar',
  'widget.connectivity': 'Connectivity',
  'widget.default': 'Default',
  'widget.boolean': 'On/off',
  'widget.expandable': 'Expandable',
  'widget.defaultOpen': 'Default open',
  'widget.timeSelection': 'Time selection',
  'widget.discardChanges': 'Discard unsaved changes before leaving?',
  'widget.yes': 'Yes',
  'widget.no': 'No',
  'widget.createSection': 'Create new section',
  'widget.editSection': 'Edit this section',
  'widget.deleteSection': 'Delete this section',
  'widget.deleteSectionConfirm': 'Are you sure you want to delete this section?',
  'widget.layouts': 'Layouts',
  'widget.widgets': 'Widgets',
  'widget.selectALayout': 'Select a layout',
  'widget.selectAWidget': 'Select a widget',
  'widget.selectLayout': 'Select layout',
  'widget.selectWidget': 'Select widget',
  'widget.hideHeader': 'Hide header',
  'widget.noSections': 'No sections configured',
  'widget.floorMap': 'Floor map',
  'widget.plusMinus': 'Plus / Minus',
  'widget.onOff': 'On / Off',
  'widget.mode': 'Mode',
  'widget.microWidget': 'Micro widget',
  'widget.controlButtons': 'Control buttons',
  'widget.incrementValue': 'Increment value',
  'widget.iconOn': 'Icon on',
  'widget.iconOff': 'Icon off',
  'widget.colorOn': 'Color on',
  'widget.colorOff': 'Color off',
  'widget.control': 'Control',
  'widget.writeDatapoint': 'Write datapoint',
  'widget.addWidget': 'Add a widget',
  'widget.offset': 'Offset',
  'widget.historicalComparisonChartWidget': 'Historical Comparison Trend Widget',
  'widget.previousPeriod': 'Previous week',
  'widget.currentPeriod': 'Current week',
  'widget.navigationLabel': 'Navigation label',
  'widget.addNavigationLabel': 'Add navigation label',
  'widget.offsets': 'Offsets',
  'widget.timeRange': 'Time range',
  'widget.timeRangeDatapoints': 'Time range for main datapoint(s)',
  'widget.timeRangeComparisonDatapoints': 'Time range for comparison datapoint(s)',
  'widget.style': 'Style',
  'widget.customMarker': 'Custom marker',
  'widget.addCustomMarker': 'Add custom marker',
  'widget.showSquealInfo': 'Show squeal info',
  'widget.geocoordinatesClickOnMap': 'Geocoordinates (click on the map to set a position)',
  'widget.mapCenterClickOnMap': 'Map center (click on the map to set a position)',
  'widget.zoom': 'Zoom',
  'widget.autoZoom': 'Auto zoom',
  'widget.addEntities': 'Add sites',
  'widget.addTraceableDevices': 'Add trackers',
  'widget.map': 'Map',
  'widget.comparisonPeriod': 'Comparison period',
  'widget.customLogic': 'Custom logic',
  'widget.equipments': 'Equipments',
  'widget.equipmentType': 'Equipment type',
  'widget.selectedEquipments': 'Selected equipments',
  'widget.equipmentsForType': 'Equipments for selected type',
  'widget.equipmentLabel': 'Equipment label',
  'widget.matrix': 'Matrix',
  'widget.matrixDetail': 'Matrix detail',
  'widget.addLayout': 'Add layout',
  'widget.showComparator': 'Show reference value',
  'widget.areYouSure': 'Are you sure?',
  'widget.unit.tonCO2e': 'Ton CO2e',
  'widget.circle': 'Circle',
  'widget.connectedDatapoints': 'Connected datapoints',
  'widget.inheritColor': 'Inherit color',
  'widget.highlightOnHover': 'Highlight on hover',
  'widget.enlargeLabelOnHover': 'Enlarge label on hover',
  'widget.showDate': 'Show date',
  'widget.dateFormat': 'Date format',
  'widget.timeFormat': 'Time format',
  'widget.clock': 'Clock',
  'widget.divider': 'Divider',
  'widget.showDivider': 'Show divider',
  'widget.backgroundColor': 'Background color',
  'widget.fontColor': 'Font color',
  'widget.differenceArrow': 'Difference arrow',
  'widget.differencePercentage': 'Difference percentage',
  'widget.showDifferenceArrow': 'Show difference arrow',
  'widget.showDifferencePercentage': 'Show difference percentage',
  'widget.arrowUpColor': 'Arrow up color',
  'widget.arrowDownColor': 'Arrow down color',
  'widget.transparentZone': 'Transparent zone',
  'widget.offlineSince': 'offline since',
  'widget.isOnline': 'is online',
  'widget.sdg1': 'No poverty',
  'widget.sdg2': 'Zero hunger',
  'widget.sdg3': 'Good health and well-being',
  'widget.sdg4': 'Quality education',
  'widget.sdg5': 'Gender equality',
  'widget.sdg6': 'Clean water and sanitation',
  'widget.sdg7': 'Affordable and clean energy',
  'widget.sdg8': 'Decent work and economic growth',
  'widget.sdg9': 'Industry, innovation and infrastructure',
  'widget.sdg10': 'Reduced inequalities',
  'widget.sdg11': 'Sustainable cities and communities',
  'widget.sdg12': 'Responsible consumption and production',
  'widget.sdg13': 'Climate action',
  'widget.sdg14': 'Life below water',
  'widget.sdg15': 'Life on land',
  'widget.sdg16': 'Peace, justice and strong institutions',
  'widget.sdg17': 'Partnership for the goals',
  'widget.hideLabel': 'Hide label',
  'widget.sdgWidget': 'SDG widget',
  'widget.maxWidgetResizeError':
    'The size limit for this widget has been exceeded. Unlock a widget in this row and try again.',
  'widget.lockedWidgetResizeError':
    'Locked widgets cannot be resized. Unlock your widget and try again.',
  'widget.lockedWidgetAddError':
    'This row has no space left to add a widget. Unlock a widget in this row and try again.',
  'widget.copySection': 'Copy section to clipboard',
  'widget.pasteSection': 'Paste section from clipboard',
  'widget.charts': 'Charts',
  'widget.gaugesIndicators': 'Gauges & Indicators',
  'widget.grids': 'Grids',
  'widget.ghgGrids': 'GHG - Grids',
  'widget.controls': 'Controls',
  'widget.others': 'Others',
  'widget.general': 'General',
  'widget.interval': 'Interval',
  'widget.eventLog': 'Event log',
  'widget.advanced': 'Advanced',
  'widget.sustainability': 'Sustainability',
  'widget.mainDatapoint': 'Main datapoint(s)',
  'widget.otherDatapoint': 'Other datapoint(s)',
  'widget.absoluteValue': 'Absolute value',
  'widget.differenceValue': 'Difference value',
  'widget.group': 'Group',
  'widget.groups': 'Groups',
  'widget.addGroup': 'Add group',
  'widget.selectedGroup': 'Selected group',
  'widget.unGroupedDatapoints': 'Ungrouped datapoints',
  'widget.selectedDatapointConfiguration': 'Selected datapoint configuration',
  'widget.selectedDataConfiguration': 'Selected data configuration',
  'widget.last': 'Last',
  'widget.classicWidgets': 'Classic widgets',
  'widget.sustainabilityWidgets': 'Sustainability widgets',
  'widget.ghgCharts': 'GHG - Charts',
  'widget.scope': 'Scope',
  'widget.emissionFactors': 'Emission details',
  'widget.emissionFactor': 'Emission detail',
  'widget.selectData': 'Select data',
  'widget.clearData': 'Clear data',
  'widget.ghgGaugesIndicators': 'GHG - Gauges & Indicators',
  'widget.countries': 'Countries',
  'widget.columns': 'Columns',
  'widget.column': 'Column',
  'widget.addColumn': 'Add column',
  'widget.selectedColumnConfiguration': 'Selected column configuration',
  'widget.selectedLineConfiguration': 'Selected line configuration',
  'widget.selectedRowConfiguration': 'Selected row configuration',
  'widget.timeRangeValue': 'Time range value',
  'widget.site': 'Site',
  'widget.building': 'Building',
  'widget.floor': 'Floor',
  'widget.room': 'Room',
  'widget.equipment': 'Equipment',
  'widget.name': 'Name',
  'widget.width': 'Width',
  'widget.row': 'Row',
  'widget.rows': 'Rows',
  'widget.addRow': 'Add row',
  'widget.comparisonValue': 'Comparison value',
  'widget.amount': 'Amount',
  'widget.selectEntity': 'Select entity',
  'widget.clearEntity': 'Clear entity',
  'widget.file': 'File',
  'widget.files': 'Files',
  'widget.image': 'Image',
  'widget.information': 'Information',
  'widget.line': 'Line',
  'widget.addLine': 'Add line',
  'widget.lineType': 'Line type',
  'widget.during': 'During',
  'widget.duration': 'Duration',
  'widget.informationLines': 'Information lines',
  'widget.border': 'Border',
  'widget.donut': 'Donut',
  'widget.showLegend': 'Show legend',
  'widget.stack': 'Stack',
  'widget.bar': 'Bar',
  'widget.ascending': 'Ascending',
  'widget.descending': 'Descending',
  'widget.sortOrder': 'Sort order',
  'widget.lineChart': 'Line chart',
  'widget.barChart': 'Bar chart',
  'widget.selectSite': 'Select a site',
  'widget.selectTraceableDevice': 'Select a traceable device',
  'widget.consumptionExport': 'Consumption export',
  'widget.organisation': 'Organisation',
  'widget.totals': 'Totals',
  'widget.historicalBarChart': 'Historical Bar Chart',
  'widget.xrange': 'X Range',
  'widget.customer': 'Customer',
  'widget.dataEntry': 'Data entry',
  'widget.dataSource': 'Data source',
  'widget.confirmYourEntry': 'Confirm your entry',
  'widget.areYouSureSmallerThanPrevious':
    'Are you sure you want to enter a value smaller than the previous one?',
  'widget.areYouSureNegativeValue': 'Are you sure you want to enter a negative value?',
  'widget.relativeInput': 'Relative input',
  'widget.showOverdueDatapointsOnly': 'Show overdue datapoints only',
  'widget.statusGrid': 'Status grid',
  'widget.energySaving': 'Energy saving',
  'widget.meterOverview': 'Meter overview',
  'widget.relativeToggleCaption':
    'With "Relative: on", values are entered as a difference to the previous value. With "Relative: off", values are entered as such.',
  'widget.energyClassification': 'Energy classification',
  'widget.area': 'Area',
  'widget.performancePeriod': 'Performance {period}',
  'widget.varianceToPeriod': 'Variance to {period}',
  'widget.averageAllBuildings': 'Average (All buildings)',
  'widget.forecast': 'Forecast',
  'widget.targetEndDate': 'Target end date',
  'widget.gradualDecrease': 'Gradual decrease',
  'widget.gradualIncrease': 'Gradual increase',
  'widget.target': 'Target',
  'widget.targetPeriod': 'Target period',
  'widget.targetValue': 'Target value',
  'widget.targetStartDate': 'Target start date',
  'widget.decrease': 'Decrease',
  'widget.increase': 'Increase',
  'widget.percentagePer': '% per',
  'widget.decreaseUnit': 'Decrease unit',
  'widget.increaseUnit': 'Increase unit',
  'widget.trendLine': 'Trend line',
  'widget.extendPeriodToTarget': 'Extend period to target time',
  'widget.hour': 'Hour',
  'widget.apply': 'Apply',
  'widget.configurationError': 'Widget configuration error',
  'widget.tooltip': 'Tooltip',
  'widget.tooltipText': 'Tooltip text',
  'widget.centeredText': 'Centered text',
  'widget.valuesAreExpressedIn': 'Values are expressed in',
  'widget.groupCalculationMethod': 'Group calculation method',
  'widget.copyToClipboard': 'Copy to clipboard',
  'widget.pasteFromClipboard': 'Paste from clipboard',
  'widget.copySettings': 'Copy settings',
  'widget.pasteSettings': 'Paste settings',
  'widget.absolute': 'Absolute',
  'widget.operator': 'Operator',
  'widget.successfullyEntered': 'successfully entered',
  'widget.updateInProgress': 'update in progress',
  'widget.successfullyUpdatedTo': 'successfully updated to',
  'widget.successfullyDeleted': 'Successfully deleted',
  'widget.detail': 'Detail',
  'widget.addSite': 'Add site',
  'widget.addBuilding': 'Add building',
  'widget.addFloor': 'Add floor',
  'widget.addRoom': 'Add room',
  'widget.addEquipment': 'Add equipment',
  'widget.equip': 'Equipment',
  'widget.id': 'ID',
  'widget.remark': 'Remark',
  'widget.varia': 'Varia',
  'widget.layout': 'Layout',
  'widget.portrait': 'Portrait',
  'widget.landscape': 'Landscape',
  'widget.fields': 'Fields',
  'widget.addSingleProperty': 'Add single property',
  'widget.addEntityDetails': 'Add entity details',
  'widget.country': 'Country',
  'widget.degreeDayReference': 'Degree day reference',
  'widget.singleLink': 'Single link',
  'widget.multipleLinks': 'Multiple links',
  'widget.navigationType': 'Navigation type',
  'widget.dashboards': 'Dashboards',
  'widget.sections': 'Sections',
  'widget.masterDetail': 'Master/Detail',
  'widget.details': 'Details',
  'widget.showDetails': 'Show details',
  'widget.floatingDetails': 'Floating details',
  'widget.floatingList': 'Floating list',
  'widget.floatingGrid': 'Floating grid',
  'widget.fixedList': 'Fixed list',
  'widget.fixedGrid': 'Fixed grid',
  'widget.list': 'List',
  'widget.floating': 'Floating',
  'widget.fixed': 'Fixed',
  'widget.entityValue': 'Entity value',
  'widget.defaultExpanded': 'Default expanded',
  'widget.resolution': 'Resolution',
  'widget.comparatorUnit': 'Comparator unit',
  'widget.selectedDatapointTag': 'Selected datapoint tag',
  'widget.otherDatapointTag': 'Other datapoint tag',
  'widget.entityTag': 'Entity tag',
  'widget.formula': 'Formula',
  'widget.formulaPreview': 'Formula preview',
  'widget.formulaEditor': 'Formula editor',
  'widget.intervalCalculationMethod': 'Interval calculation method',
  'widget.tagValue': 'Tag value',
  'widget.searchCity': 'Search city',
  'widget.yAxisMin': 'Y-axis min',
  'widget.yAxisMax': 'Y-axis max',
  'widget.linkedWidget': 'Linked widget',
  'widget.linkedDashboard': 'Linked dashboard',
  'widget.dashboardLink': 'Dashboard link',
  'widget.addWidgetDatapoints': 'Add widget datapoints',
  'widget.linkedDatapoints': 'Linked datapoints',
  'widget.groupDatapoints': 'Group datapoints',
  'widget.linkedPointValue': 'Clicked point value',
  'widget.addClickedPointValue': 'Add clicked point value',
  'widget.splitPage': 'Display on split page',
  'widget.close': 'Close',
  'widget.extraDatapoints': 'Extra datapoints',
  'widget.addWidgetExtraDatapoints': 'Add widget extra datapoints',
  'widget.addWidgetComparators': 'Add widget comparators',
  'widget.addWidgetMinComparators': 'Add widget min comparators',
  'widget.addWidgetMaxComparators': 'Add widget max comparators',
  'widget.linkedLinkDatapoints': 'Linked extra datapoints',
  'widget.linkedComparators': 'Linked comparators',
  'widget.linkedMinComparators': 'Linked min comparators',
  'widget.linkedMaxComparators': 'Linked max comparators',
  'widget.formulaResult': 'Formula result',
  'widget.reload': 'Reload',
}
