import { useCallback, useEffect, useMemo, useState } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

const REQUEST_UPLOAD_QUERY = gql`
  query requestFileUpload($companyId: Int!, $companyWide: Boolean = false) {
    fileUpload(companyId: $companyId, companyWide: $companyWide) {
      url
    }
  }
`

export const useFileUpload = (companyId, options) => {
  const uploadOnSetFile = useMemo(() => options.uploadOnSetFile || false, [options])
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [uploadError, setUploadError] = useState()
  const [uploadedFile, setUploadedFile] = useState()

  const memoryUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file])

  const { onCompleted, companyWide = false } = options

  const [doUpload, { error }] = useLazyQuery(REQUEST_UPLOAD_QUERY, {
    variables: { companyId, companyWide },
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(
      async (data) => {
        const url = data?.fileUpload?.url

        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        })

        const fileData = await response.json()

        if (response.ok) {
          setUploadedFile(fileData)
        } else {
          if (fileData.error) {
            setUploadError(fileData.error)
          } else {
            throw new Error(`Could not upload file: ${fileData}`)
          }
        }

        if (typeof onCompleted === 'function') {
          onCompleted(fileData)
        }

        setLoading(false)
      },
      [file, onCompleted]
    ),
  })

  const upload = useCallback(() => {
    if (file) {
      setLoading(true)
      doUpload()
    }
  }, [doUpload, file])

  useEffect(() => {
    if (uploadOnSetFile && file) {
      upload()
    }
  }, [file, upload, uploadOnSetFile])

  return [
    setFile,
    {
      upload,
      loading,
      error: error || uploadError?.message,
      uploadError,
      memoryUrl,
      uploadedFile,
    },
  ]
}

export default useFileUpload
