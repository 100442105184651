import styled from 'styled-components'

export const Grid = styled.div`
  flex: 1;
  overflow: hidden;

  > div {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    overflow: auto;
    border-top: 2.5rem solid ${({ theme }) => theme.color.backgroundHighlight};
  }
`

export const Item = styled.div`
  flex: 1 1 20%;
  width: 20%;
  max-width: 20%;
  padding: 1em;
`
