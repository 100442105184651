import { useCallback, useMemo } from 'react'

import { useFilteredFeatures } from '../../../../services/entityFeatureTypes'

export const useIsNumberValueTag = () => {
  const { data } = useFilteredFeatures([
    { featureType: 'field', fieldType: 'numeric' },
    {
      featureType: 'tag',
      fieldType: 'numeric',
    },
  ])

  const checkObject = useMemo(() => {
    return data
      ? data.reduce((checkObject, { featureName }) => {
          checkObject[featureName] = true
          return checkObject
        }, {})
      : {}
  }, [data])

  return useCallback(
    ({ tagName }) => {
      return checkObject[tagName]
    },
    [checkObject]
  )
}
