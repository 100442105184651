import React, { useEffect, useRef } from 'react'

import { FormattedMessage, injectIntl } from 'react-intl'

import { calculationMethods, granularityUnits } from 'components/Dashboard/Widget/config/selections'

import OptionPicker from '../../../Form/components/OptionPicker'
import ValuePicker from '../../../Form/components/ValuePicker/ValuePicker'
import { MultiFieldContainer } from '../FormFieldElement/styled'
import GranularitySelector from '../GranularitySelector'
import NumberInput from '../NumberInput'
import { FormFieldLabel } from '../styled'

export const heatMapIntervals = {
  Y: ['MS', 'w'],
  MS: ['w', 'd'],
  w: ['d'],
  d: ['h'],
  h: ['m'],
  m: ['m'],
}

const HeatmapIntervalSelector = ({
  value,
  dataCy = 'HeatmapIntervalSelector',
  onBlur,
  onGranularityChange,
  intl,
  defaultStartTime,
  disabled,
}) => {
  const initialRender = useRef(false)
  const allowedHeatmapIntervals = heatMapIntervals[value.defaultGranularity] || []

  const allowedUnits = granularityUnits.filter(({ value }) =>
    allowedHeatmapIntervals.includes(value)
  )

  useEffect(() => {
    if (initialRender.current) {
      const updateHeatmapIntervals = heatMapIntervals[value.defaultGranularity]
      onBlur(updateHeatmapIntervals[0], 'heatmapGranularityUnit')
    }
    initialRender.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.defaultGranularity])

  const handleGranularityChange = (update, dataKey) => {
    onGranularityChange({ value: update, dataKey })
  }

  return (
    <>
      <FormFieldLabel>
        <FormattedMessage id={'widget.interval'} />
      </FormFieldLabel>
      <MultiFieldContainer className="MultiFieldContainer HeatmapIntervalSelector">
        <GranularitySelector
          value={value}
          defaultStartTime={defaultStartTime}
          disabled={disabled}
          heatMapIntervals
          onChange={handleGranularityChange}
        />
        <FormFieldLabel>{intl.formatMessage({ id: 'widget.resolution' })}</FormFieldLabel>
        <NumberInput
          className="NumberInput"
          value={value?.heatmapGranularityValue || 0}
          min={0}
          onBlur={(value) => onBlur(value, 'heatmapGranularityValue')}
          dataCy={`${dataCy}-heatmapGranularityValue`}
        />
        <ValuePicker
          className="ValuePicker"
          value={value?.heatmapGranularityUnit}
          options={allowedUnits}
          onChange={(value) => onBlur(value, 'heatmapGranularityUnit')}
          selectionKeys={{
            label: 'label',
            value: 'value',
          }}
          placeholder={intl.formatMessage({ id: 'widget.unit' })}
          search={false}
          dataCy={`${dataCy}-heatmapGranularityUnit`}
        />
      </MultiFieldContainer>
      {value?.defaultGranularityValue > 1 && (
        <>
          <FormFieldLabel>
            <FormattedMessage id={'widget.intervalCalculationMethod'} />
          </FormFieldLabel>
          <OptionPicker
            options={calculationMethods.filter(
              ({ value }) => value !== 'amount' && value !== 'range'
            )}
            onChange={(value) => onBlur(value, 'intervalMethod')}
            value={value.intervalMethod}
          />
        </>
      )}
    </>
  )
}

export default injectIntl(HeatmapIntervalSelector)
