import React, { useEffect, useState } from 'react'

import { DatePicker as AntDatePicker } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import {
  DateRangeSelectorButton,
  DateRangeSelectorStyled,
} from 'components/Global/FormField/DateRangeSelector/styled'

import PeriodPickerStyled from './styled'

const options = [{ name: 'week' }, { name: 'month' }, { name: 'year' }]

const PeriodPicker = ({
  disabled,
  value,
  placeholder,
  disabledDate = () => false,
  disabledTime,
  defaultPeriod,
  showToday,
  onChange,
  onOpenChange,
  open,
  intl,
  dataCy = 'PeriodPicker',
}) => {
  const [mode, setMode] = useState(defaultPeriod || 'week')
  useEffect(() => {
    if (defaultPeriod) {
      setMode(defaultPeriod)
    }
  }, [defaultPeriod])
  const _onPeriodPickerChange = (time) => {
    onChange({
      value: [time.startOf(mode).valueOf(), time.startOf(mode).add(1, mode).valueOf()],
      range: true,
    })
  }
  const onClick = (mode) => {
    setMode(mode)
  }

  const periodOptions = defaultPeriod ? [] : options
  return (
    <PeriodPickerStyled className="PeriodPicker">
      <DateRangeSelectorStyled className="DateRangeSelector">
        {periodOptions.map((button) => (
          <DateRangeSelectorButton
            {...button}
            label={intl.formatMessage({
              id: `graphComponent.presetControl.${button.name}`,
            })}
            isActive={mode === button.name}
            onClick={() => onClick(button.name)}
            dataCy={`${dataCy}-${button.name}`}
          />
        ))}
      </DateRangeSelectorStyled>
      <AntDatePicker
        onChange={_onPeriodPickerChange}
        disabled={disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        showToday={showToday}
        picker={mode}
        format={
          {
            year: 'YYYY',
            month: 'MMM YYYY',
          }[mode]
        }
        value={value}
        placeholder={placeholder}
        open={open}
        onOpenChange={onOpenChange}
      />
    </PeriodPickerStyled>
  )
}

PeriodPicker.propTypes = {
  selectOptions: PropTypes.array,
}

PeriodPicker.defaultProps = {
  selectOptions: [],
}

export default injectIntl(PeriodPicker)
