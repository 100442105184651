import { useState } from 'react'

export const useSearch = ({ onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectMode, setSelectMode] = useState(true)
  const [searchText, setSearchText] = useState('')

  return {
    props: {
      selectedIndex,
      setSelectedIndex,
      searchText,
      setSearchText,
      onClose,
      selectMode,
      setSelectMode,
    },
    text: searchText,
  }
}
