import styled from 'styled-components'

const AgGridToggleCellStyled = styled.div`
  font-size: 0.8rem;
  font-weight: 100;
  color: ${({ theme }) => theme.color.mainText};

  button {
    margin-left: 1rem;
  }
`

export default AgGridToggleCellStyled
