import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Label, Value } from 'components/Form/components/Field/styled'

export const TagMarkerSelectFieldStyled = styled(Field)`
  padding: 0.5rem 0;

  ${Label} {
    margin: 0 0 auto;
  }

  ${Value} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;

    > .Tags {
      width: 100%;
    }

    .ValuePickerValue {
      display: flex;
      flex-wrap: wrap;
    }
  }
`
