import React, { createContext, useContext, useEffect, useState } from 'react'

import { useAppState } from 'services/store'

import { parseDefaultTime } from './functions'

const TimeSelectionContext = createContext(null)

export const useTimeSelection = () => useContext(TimeSelectionContext)

export const TimeSelectionProvider = ({ children, defaultStartTime, defaultEndTime }) => {
  const [time, setTime] = useState(
    parseDefaultTime({ startTime: defaultStartTime, endTime: defaultEndTime } || ['day'])
  )
  const [presetTime, setPresetTime] = useState(null)
  const [source, setSource] = useState(null)
  const {
    appState: { globalTime },
  } = useAppState()

  useEffect(() => {
    if (globalTime.startTime) {
      setTime({
        startTime: parseDefaultTime(globalTime, 'startTime'),
        endTime: parseDefaultTime(globalTime, 'endTime'),
        preset: globalTime.preset,
      })
      setSource('globalTimeSelection')
    }
  }, [globalTime])

  const onTimeChange = (value) => {
    setTime({
      startTime: parseDefaultTime(value, 'startTime'),
      endTime: parseDefaultTime(value, 'endTime'),
      preset: value.preset,
    })
    setSource('sectionTimeSelection')
  }

  const contextValue = {
    source,
    time,
    presetTime,
    setTime: onTimeChange,
    setPresetTime,
  }

  return (
    <TimeSelectionContext.Provider value={contextValue}>{children}</TimeSelectionContext.Provider>
  )
}
