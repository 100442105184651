import React from 'react'

import { number, object, oneOfType, string } from 'prop-types'

import TextExtendedStyled from './styled'

const TextExtended = ({ text, extension, styleProps, className }) => (
  <TextExtendedStyled className={className} {...styleProps}>
    <span dangerouslySetInnerHTML={{ __html: text }} />
    {extension && <label dangerouslySetInnerHTML={{ __html: extension }} />}
  </TextExtendedStyled>
)

TextExtended.propTypes = {
  text: oneOfType([string, number]),
  extension: string,
  className: string,
  styleProps: object,
}

TextExtended.defaultProps = {}

export default TextExtended
