import { isAddedItem } from '../DatapointSelector/DatapointSelector'
import { scopeConfig } from './config'

const entityTypeMap = {
  VehicleType: 'vehicleTypeId',
  OrganisationForm: 'departmentId',
}

const tagMap = {
  VehicleType: 'vehicleType',
  OrganisationForm: 'departmentRef',
}

export const getQueryPayload = (scopeId, { hierarchy, __typename, ...entity }) => {
  if (scopeId === '1' || scopeId === '2') {
    // Stationary combustion or Purchased energy
    if (hierarchy) {
      const refKey = ['siteRef', 'buildingRef', 'floorRef', 'roomRef'][hierarchy.length - 2]
      const meterKey = ['siteMeter', 'buildingMeter', 'floorMeter', 'subMeter'][
        hierarchy.length - 2
      ]

      return {
        meterInfo: {
          meterInfo: JSON.stringify({
            [refKey]: hierarchy[hierarchy.length - 1],
            [meterKey]: '1',
          }),
        },
      }
    }
  }

  if (scopeId === '3') {
    // Mobile combustion
    const key = entityTypeMap[__typename]
    return {
      [key]: entity.id && parseInt(entity.id),
    }
  }
  if (scopeId === '4') {
    // Refrigerants
    if (hierarchy) {
      const refKey = ['siteRef', 'buildingRef', 'floorRef', 'roomRef'][hierarchy.length - 2]

      return {
        tagFilter: JSON.stringify({
          [refKey]: hierarchy[hierarchy.length - 1],
        }),
      }
    }
  }
  if (scopeId === '6') {
    switch (__typename) {
      case 'ActivityDirection':
        return {
          typeIds: entity.entityTypeIds,
        }
      case 'ActivityCategory':
        return {
          typeIds: entity.children.map(({ id }) => id),
        }
      case 'EntityType':
        return {
          typeIds: [entity.id],
        }
      default:
        return null
    }
  }
}

export const getEntityVirtualDatapoint = (
  scope,
  { __typename, sites, ...entity },
  customer,
  cat3
) => {
  const scopeId = cat3?.scopeId || scope.id
  switch (scopeId) {
    case '0':
    case '5':
      return {
        id: `${scopeId}-${entity.id}`,
        name: `${scope.name} ${entity.name} All`,
        scopeId: scopeId,
        organisationId: parseInt(entity.id),
        entityId: customer?.entity.id,
      }
    case '1':
    case '2':
    case '4':
      // Stationary combustion or Purchased energy or Refrigerants
      if (sites) {
        // Country selection
        return {
          id: `${scopeId}-${entity.id}`,
          name: `${scope.name} ${entity.name} All`,
          sites: sites.map((site) => ({
            id: `${scopeId}-${site.id}`,
            scopeId: scopeId,
            entityId: site.id,
            name: `${scope.name} ${site.name} All`,
          })),
        }
      }
      // Entity selection or Customer selection
      return {
        id: `${scopeId}-${entity.id}`,
        scopeId: scopeId,
        entityId: entity.type ? entity.id : customer?.entity.id,
        name: `${scope.name} ${entity.name} All`,
      }
    case '3':
      // Mobile combustion
      const key = tagMap[__typename]
      return {
        id: `${scopeId}-${entity.id || 'all'}`,
        scopeId: scopeId,
        entityId: customer?.entity.id,
        tags: key
          ? {
              [key]: entity.id,
            }
          : null,
        name: `${scope.name} ${entity.name} All`,
      }
    case '6':
      // Upstream Downstream
      if (__typename === 'ActivityDirection') {
        return {
          id: `direction-${scopeId}-${entity.id || 'all'}`,
          scopeId: scopeId,
          entityId: customer?.entity.id,
          activityDirectionId: entity.id,
          name: `${scope.name} ${entity.name} All`,
        }
      }
      if (__typename === 'ActivityCategory') {
        return {
          id: `category-${scopeId}-${entity.id || 'all'}`,
          scopeId: scopeId,
          entityId: customer?.entity.id,
          categoryId: entity.id,
          name: `${scope.name} ${entity.name} All`,
        }
      }
      if (__typename === 'EntityType') {
        return {
          id: `type-${scopeId}-${entity.id || 'all'}`,
          scopeId: scopeId,
          entityId: customer?.entity.id,
          entityTypeId: entity.id,
          name: `${scope.name} ${entity.name} All`,
        }
      }
      return {
        id: `${scopeId}-${entity.id || 'all'}`,
        scopeId: scopeId,
        entityId: customer?.entity.id,
        tags: __typename === 'ActivityDirection' ? { activityDirection: entity.id } : null,
        name: `${scope.name} ${cat3 ? `${cat3.name}` : ''} ${entity.name} All`,
        scope3SubCategory: cat3?.id,
      }
    default:
  }
}

export const getQueryDatapoint = ({ datapoint, scope, entity, organisationId, cat3 }) => {
  const switchScopeId = cat3?.scopeId || scope.id
  switch (switchScopeId) {
    case '3':
    case '4':
      // Mobile combustion
      //Refrigerants
      return {
        id: datapoint.id,
        entityId: datapoint.id,
        scopeId: scope.id,
        name: datapoint.equipName,
        organisationId,
        equipTags: datapoint.equipTags,
        datapointTags: datapoint.datapointTags,
        scope3SubCategory: cat3?.id,
      }
    case '6':
      // Upstream Downstream
      const { tags, ...queryDatapoint } = datapoint
      return {
        ...queryDatapoint,
        entityId: datapoint.entityId || datapoint.id,
        scopeId: scope.id,
        organisationId,
        datapointTags: datapoint.tags,
        scope3SubCategory: cat3?.id,
      }
    default:
      return {
        id: datapoint.id,
        datapointId: datapoint.id,
        scopeId: scope.id,
        name: `${datapoint.equipName} ${datapoint.datapointName}`,
        ...(entity && { entityId: entity.id }),
        ...(datapoint.datapointTags && { unit: datapoint.datapointTags.unit }),
        organisationId,
        equipTags: datapoint.equipTags,
        datapointTags: datapoint.datapointTags,
        scope3SubCategory: cat3?.id,
      }
  }
}

export const getEntityDatapointsForTags = ({ entity, datapoints = [], tags, scope }) => {
  return datapoints.reduce((filteredDatapoints, { datapointTags, ...dp }) => {
    const include =
      tags.length > 0 &&
      tags.reduce((include, { key, value }) => {
        include = include ? datapointTags?.[key] === value : include
        return include
      }, true)
    if (include) {
      filteredDatapoints.push({
        id: `${scope.id}-${dp.id}`,
        datapointId: dp.id,
        entityId: entity.id,
        scopeId: scope.id,
        name: `${scope.name} ${dp.equipName} ${dp.datapointName}`,
        unit: dp.unit,
        difference: dp.unitObject?.difference,
      })
    }
    return filteredDatapoints
  }, [])
}

export const getTaggedDatapointForEntity = ({ tags = [], entity = {}, scope, customer }) => {
  const virtualDatapoint = getEntityVirtualDatapoint(scope, entity, customer) || {}

  return {
    scopeId: scope.id,
    entityId: entity.id || customer.entity.id,
    ...tags.reduce(
      (propertiesFromTags, { key, value }) => {
        propertiesFromTags.tags[key] = value
        propertiesFromTags.name = `${propertiesFromTags.name} | ${key}: ${value}`
        propertiesFromTags.id = `${propertiesFromTags.id}-${key}-${value}`
        if (key === 'unit') {
          propertiesFromTags.unit = value
        }
        return propertiesFromTags
      },
      {
        tags: { ...entity.tags, ...virtualDatapoint.tags },
        name: `${scope.name}: ${entity.name}`,
        id: entity.id || customer.entity.id,
      }
    ),
  }
}

export const getTaggedDatapointsForCountry = ({ tags = [], entity, scope }) => {
  const { tags: tagsForCountry, nameSuffix, idSuffix } = tags.reduce(
    (propertiesFromTags, { key, value }) => {
      propertiesFromTags.tags[key] = value
      propertiesFromTags.nameSuffix = `${propertiesFromTags.nameSuffix} | ${key}: ${value}`
      propertiesFromTags.idSuffix = `${propertiesFromTags.idSuffix}-${key}-${value}`
      if (key === 'unit') {
        propertiesFromTags.unit = value
      }
      return propertiesFromTags
    },
    { tags: {}, nameSuffix: '', idSuffix: '' }
  )
  return {
    id: `${entity.id}${idSuffix}`,
    name: `${entity.name}${nameSuffix}`,
    scopeId: scope.id,
    entityId: entity.id,
    sites: entity.sites.map((site) => ({
      ...site,
      id: `${site.id}${idSuffix}`,
      name: `${site.name} ${nameSuffix}`,
      tags: tagsForCountry,
    })),
  }
}

export const getFilteredDatapointsForSelectedTags = ({
  datapoints = [],
  selectedTags,
  scope,
  entity,
  organisationId,
  cat3,
}) => {
  return datapoints
    .filter(({ tags, equipTags, datapointTags }) => {
      return selectedTags.every(
        ({ key, value }) =>
          tags?.[key] === value || equipTags?.[key] === value || datapointTags?.[key] === value
      )
    })
    .map((datapoint) => getQueryDatapoint({ datapoint, scope, entity, organisationId, cat3 }))
}

export const getDatapointsForCountryUpdate = ({
  values,
  selectedScope,
  organisationId,
  dataKey,
  selectedDatapoints,
  groupId,
  addedItem,
}) => {
  return values.reduce(
    (update, { emissionFactors, sites, datapoints, ...datapoint }) => {
      if (sites || datapoints) {
        if (sites) {
          update.datapointsToSave = update.datapointsToSave.concat(
            sites.reduce((newSites, item) => {
              const newSite = {
                ...item,
                groupId: datapoint.id,
                emissionFactors:
                  emissionFactors || scopeConfig[selectedScope?.id || '1'].defaultEmissions,
                organisationId: datapoint.organisationId || organisationId,
              }
              const added = isAddedItem({
                item: newSite,
                groupId: datapoint.id,
                addedItem,
                selectedDatapoints,
              })
              newSites.push({ ...newSite, ...(added && { groupId: datapoint.id }) })
              if (added) {
                update.added.push({ ...newSite, groupId: datapoint.id })
              }
              return newSites
            }, [])
          )
        }
        update.groups.push({
          ...datapoint,
          emissionFactors:
            emissionFactors || scopeConfig[selectedScope?.id || '1'].defaultEmissions,
          [dataKey]: datapoints || sites.map(({ id }) => id),
          organisationId: datapoint.organisationId || organisationId,
        })
      } else {
        const newItem = {
          ...datapoint,
          emissionFactors:
            emissionFactors || scopeConfig[selectedScope?.id || '1'].defaultEmissions,
          organisationId: datapoint.organisationId || organisationId,
        }
        const added = isAddedItem({
          item: newItem,
          groupId,
          addedItem,
          selectedDatapoints,
        })
        update.datapointsToSave.push({ ...newItem, ...(added && { groupId }) })
        if (added) {
          update.added.push({ ...newItem, ...(groupId && { groupId }) })
        }
      }
      return update
    },
    { datapointsToSave: [], groups: [], added: [] }
  )
}

export const getDatapointsForNonCountryUpdate = ({
  values,
  selectedScope,
  organisationId,
  selectedDatapoints,
  groupId,
  addedItem,
}) => {
  return values.reduce(
    (update, { emissionFactors, id, ...datapoint }) => {
      if (!id.includes('country')) {
        const newItem = {
          id,
          ...datapoint,
          emissionFactors:
            emissionFactors || scopeConfig[selectedScope?.id || '1'].defaultEmissions,
          organisationId: datapoint.organisationId || organisationId,
        }
        const added = isAddedItem({
          item: newItem,
          groupId,
          addedItem,
          selectedDatapoints,
        })
        update.datapointsToSave.push({ ...newItem, ...(added && { groupId }) })
        if (added) {
          update.added.push({ ...newItem, ...(groupId && { groupId }) })
        }
      }

      return update
    },
    { datapointsToSave: [], added: [] }
  )
}
