import { useMemo } from 'react'

import dayjs from 'dayjs'
import { useTheme } from 'styled-components'
import uniqid from 'uniqid'

import { useQueryData } from '../../../../services/data'
import { useLocaleNumber } from '../../../../util/numbers'
import {
  getGranularityForTimeRange,
  getStartEndTime,
  useDefaultTimeRange,
} from '../../components/TimePicker/functions'
import {
  getGranularity,
  getGroupProperties,
  getTagDatapoints,
  getWidgetDatapoints,
} from '../common/helpers'
import { getSeriesFromDatapoints } from './functions'

export const useWidgetDatapoints = ({
  datapoints,
  datapointsGroups,
  comparators,
  minComparators,
  maxComparators,
  constants,
  datapointsLinked,
  locationData,
}) => {
  const localizeNumber = useLocaleNumber()
  const theme = useTheme()
  return useMemo(
    () =>
      getWidgetDatapoints({
        datapoints,
        datapointsGroups,
        comparators,
        minComparators,
        maxComparators,
        constants,
        datapointsLinked,
        locationData,
        localizeNumber,
        theme,
      }),
    [
      datapoints,
      comparators,
      minComparators,
      maxComparators,
      datapointsGroups,
      constants,
      datapointsLinked,
      locationData,
      localizeNumber,
      theme,
    ]
  )
}

export const useDatapointsPayload = ({
  datapoints,
  datapointsGroups,
  startTime,
  endTime,
  influxFunction,
  multiplier,
  defaultStartTime,
  granularityUnit,
  granularityValue,
  extendPeriod,
  calculationMethod = 'mean',
  includeDatapointConstants = true,
  constants,
}) => {
  return useMemo(() => {
    const groupProperties = getGroupProperties(datapointsGroups)
    const { defaultGranularity, defaultGranularityValue } = getGranularityForTimeRange(
      defaultStartTime,
      false,
      calculationMethod
    )

    const { start, end } = getStartEndTime({
      startTime,
      endTime,
      granularity: granularityUnit || defaultGranularity,
      defaultStartTime,
      extendPeriod,
    })

    const tagDatapoints = getTagDatapoints(constants)

    return datapoints.reduce(
      (
        datapoints,
        { id, groupId, conversionUnit, flowLogic, flowData, defaultCalculationMethod, constants }
      ) => {
        const method =
          groupId && groupProperties[id]
            ? groupProperties[id].defaultCalculationMethod || calculationMethod
            : defaultCalculationMethod || calculationMethod

        const rangeId = groupId ? groupProperties[id]?.rangeId : method === 'range' && uniqid()

        const granularity =
          method === 'raw'
            ? undefined
            : `${granularityValue || defaultGranularityValue}${
                granularityUnit || defaultGranularity
              }`

        const datapoint = {
          id,
          returnUnitId: groupProperties[id]?.conversionUnit || conversionUnit,
          influxFunction,
          multiplier,
          granularity,
          startTime: start,
          endTime: end,
          calculationMethod: method === 'range' ? 'mean' : method,
          groupId,
          ...(rangeId && { rangeId }),
          ...(flowLogic && { flowLogic, flowData }),
        }

        datapoints.push(datapoint)

        if (method === 'range') {
          datapoints.push(
            { ...datapoint, calculationMethod: 'min' },
            { ...datapoint, calculationMethod: 'max' }
          )
        }

        if (includeDatapointConstants && constants) {
          constants.forEach(({ value }) => {
            if (value && typeof value === 'object' && value.tagName) {
              datapoints.push({ id: datapoint.id, tag: value.tagName })
            }
          })
        }

        return datapoints
      },
      [...tagDatapoints]
    )
  }, [
    datapoints,
    datapointsGroups,
    startTime,
    endTime,
    granularityUnit,
    granularityValue,
    influxFunction,
    multiplier,
    calculationMethod,
    defaultStartTime,
    extendPeriod,
    constants,
    includeDatapointConstants,
  ])
}

const getOffsetsForPayload = (offsets = [], calculationMethod) => {
  return offsets.map(({ defaultCalculationMethod, ...offset }) => {
    return {
      ...offset,
      calculationMethod:
        !defaultCalculationMethod || defaultCalculationMethod === 'range'
          ? calculationMethod
          : defaultCalculationMethod,
    }
  })
}

export const useHistoricalDatapointsPayload = ({
  datapoints,
  startTime,
  endTime,
  datapointsGroups,
  granularity,
  calculationMethod,
  preset,
  constants,
}) => {
  const timeRange = useMemo(
    () => ({
      startTime: startTime
        ? startTime.startOf('second').valueOf()
        : dayjs().subtract(7, 'days').valueOf(),
      endTime: endTime ? endTime.startOf('second').valueOf() : dayjs().valueOf(),
      preset,
    }),
    [startTime, endTime, preset]
  )
  const groupProperties = getGroupProperties(datapointsGroups)
  const dataTimeRange = useDefaultTimeRange(timeRange, { preset: 'past7Days' }, false)
  const { presetObject } = dataTimeRange
  const dataGranularity = getGranularity({
    granularity,
    calculationMethod,
    defaultStartTime: presetObject && { unit: presetObject.unit },
  })

  return useMemo(() => {
    const tagDatapoints = getTagDatapoints(constants)
    return {
      datapoints: datapoints.reduce(
        (
          datapoints,
          {
            id,
            difference,
            conversionUnit,
            defaultStartTime,
            offsets,
            defaultCalculationMethod,
            groupId,
          }
        ) => {
          const method =
            groupId && groupProperties[id]
              ? groupProperties[id].defaultCalculationMethod || calculationMethod
              : defaultCalculationMethod || calculationMethod
          const payloadMethod = method === 'range' ? 'mean' : method

          const rangeId = groupId ? groupProperties[id].rangeId : method === 'range' && uniqid()

          const payloadOffsets = groupProperties[id]?.offsets || offsets

          const datapoint = {
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
            id,
            offsets: getOffsetsForPayload(payloadOffsets, payloadMethod),
            returnUnitId: groupProperties[id]?.returnUnitId || conversionUnit,
            difference,
            defaultStartTime,
            calculationMethod: payloadMethod,
            groupId,
            ...(rangeId && { rangeId }),
            granularity: dataGranularity,
            timeRange: presetObject,
          }
          datapoints.push(datapoint)

          if (method === 'range') {
            datapoints.push(
              {
                ...datapoint,
                calculationMethod: 'min',
                offsets: getOffsetsForPayload(payloadOffsets, 'min'),
              },
              {
                ...datapoint,
                calculationMethod: 'max',
                offsets: getOffsetsForPayload(payloadOffsets, 'max'),
              }
            )
          }
          if (constants) {
            constants.forEach(({ tag }) => {
              if (tag) {
                datapoints.push({ id: datapoint.id, tag: tag.tagName })
              }
            })
          }
          return datapoints
        },
        tagDatapoints
      ),
      timeRange,
    }
  }, [
    timeRange,
    groupProperties,
    datapoints,
    dataGranularity,
    presetObject,
    calculationMethod,
    constants,
  ])
}

export const useTotals = ({
  showTotals,
  datapointTotals,
  granularity,
  startTime,
  endTime,
  widgetType = 'graphCardTotals',
}) => {
  const hasTotals = showTotals && datapointTotals?.length
  const { data: totals } = useQueryData({
    service: 'datapoints',
    payload: {
      datapoints: datapointTotals?.map(({ id }) => ({
        id: id,
        granularity: granularity,
        startTime: startTime ? startTime.startOf('second').valueOf() : undefined,
        endTime: endTime ? endTime.startOf('second').valueOf() : undefined,
      })),
      widgetType,
    },
    deps: [startTime, endTime, granularity, datapointTotals],
    skip: !hasTotals,
  })

  const { series } = getSeriesFromDatapoints({
    datapoints: datapointTotals,
    datapointsData: totals?.data?.datapoints,
    startTime: null,
    endTime: null,
  })

  return showTotals ? series || [] : []
}
