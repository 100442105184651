import React from 'react'

import { withTheme } from 'styled-components'

import BaseLayout from 'components/Layout/BaseLayout'
import LogoDropdown from 'components/Layout/LogoDropdown'
import MainPanel from 'components/Layout/MainPanel'
import MenuBar from 'components/Layout/MenuBar'
import TopBar from 'components/Layout/TopBar'
import Shortcuts from 'components/Shortcuts'

const MainLayout = ({ children, theme }) => {
  return (
    <BaseLayout>
      {theme.screenSize.screen.current !== 's' && <LogoDropdown />}
      <MenuBar />
      <MainPanel>
        <TopBar />
        <Shortcuts>{children}</Shortcuts>
      </MainPanel>
    </BaseLayout>
  )
}

export default withTheme(MainLayout)
