import styled, { css } from 'styled-components'

import Caption from 'components/Global/Caption/styled'
import FlexContainer from 'components/Global/FlexContainer'
import FlexContainerStyled from 'components/Global/FlexContainer/FlexContainerStyled'
import Icon from 'components/Global/Icon/styled'
import Tooltip from 'components/Global/Tooltip'

export const TimePickerStyled = styled(FlexContainer)`
  align-items: center;
  font-weight: 100;
  line-height: 1.2;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.TimePicker__source {
      text-transform: uppercase;
      font-size: 0.7rem;
    }
  }

  button {
    margin: 0 0.5rem;
  }
`
export const TooltipStyled = styled(Tooltip)`
  position: ${({ theme }) => theme.screenSize.screen.current !== 's' && 'relative'};
  display: flex;
  margin: 0.5rem;
  z-index: 1;

  .Button {
    flex-direction: row-reverse;
    font-size: 1em;
    line-height: 1.7;
    color: ${({ theme, isPrimary }) => (isPrimary ? theme.color.white : theme.color.softText)};
    border: 1px solid ${({ theme }) => theme.color.softStroke};
    border-radius: 2rem;
    padding: 0.1rem 0.8rem;
    transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
    max-width: fit-content;
    border-color: ${({ theme, disabled }) => !disabled && theme.color.main};

    .Icon path {
      fill: ${({ theme, disabled }) => !disabled && theme.color.main};
    }
  }

  > .Button {
    &:hover,
    &.active {
      span {
        color: ${({ theme, disabled }) => !disabled && theme.color.main};
      }
    }

    span {
      white-space: nowrap;
      font-weight: 100;
      margin-right: 0.5em;
    }

    path {
      fill: ${({ theme }) => theme.color.softText};
    }
  }
`

export const TimeSelectionStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .TimeSelection__header {
    ${({ title, icon, theme }) => {
      if (!title && !icon && !theme.screenSize.screen.current === 's') {
        return css`
          padding: 1rem 1.5rem 1rem;
        `
      }
    }}
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    padding-right: ${({ theme }) => !theme.screenSize.screen.current === 's' && '1.5rem'};
    font-weight: 100;

    > .Caption {
      margin: 0.5rem auto 0.5rem 0;
    }

    > .Icon {
      cursor: pointer;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.color.main};
      border: 1px solid ${({ theme }) => theme.color.main};
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.play {
        opacity: 0.5;
      }

      &.pause:not(:hover) {
        animation: iconPulse 1.5s ease infinite;
      }
      &:hover {
        opacity: 1;
      }
    }

    > ${FlexContainerStyled} {
      justify-self: flex-end;
    }
  }

  ${Caption} {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    ${Icon} {
      z-index: 1;
      color: ${({ theme, color }) => color || theme.color.softText};
      margin: 0 0.8rem 0 -1.3rem;
      font-size: 1.2rem;
    }
  }

  + div {
    flex: 1;
  }

  @keyframes iconPulse {
    0% {
      opacity: 0.1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`
