import { css } from 'styled-components'

export const EditableTree = css`
  transition: top 0.3s ease, bottom 0.3s ease, left 0.3s ease, right 0.3s ease;
  font-size: 0.9rem;
  font-weight: 100;
  background-color: ${({ theme }) => theme.color.backgroundFront};
  border: 1px solid ${({ theme }) => theme.color.softStroke};
  box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  min-width: 10rem;
  position: absolute;
  bottom: 100%;
  right: 100%;
  min-height: auto;
  z-index: 1000;

  span {
    white-space: nowrap;
  }

  .Icon {
    color: ${({ theme }) => theme.color.main};
    width: 1rem;
    &.close-button {
      display: ${({ theme }) => theme.screenSize.screen.current !== 's' && 'none'};
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
`
