import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { useDatapointOptions } from 'services/datapoints'

import { datapointValueTypes } from 'components/Dashboard/Widget/config/selections'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import DatapointValueTypePickerStyled from './styled'

const DatapointValueTypePicker = ({ value, onChange, types = datapointValueTypes }) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()

  const { data } = useDatapointOptions(elementInEditMode.preview.datapoints)

  const availableOptions = useMemo(
    () =>
      data
        ? data.reduce((options, datapoint) => {
            Object.entries(datapoint.tags).forEach(([tag, tagValue]) => {
              if (
                !options.some(({ value }) => value === `tags.${tag}`) &&
                ![
                  'buildingRef',
                  'customerRef',
                  'equipRef',
                  'floorRef',
                  'roomRef',
                  'siteRef',
                ].includes(tag)
              ) {
                options.push({
                  value: `tags.${tag}`,
                  label: { text: tag },
                  valueType: 'dataKey',
                  dataType: tagValue === '1' ? 'boolean' : 'string',
                })
              }
            })
            return options
          }, types)
        : types,
    [data, types]
  )

  return (
    <DatapointValueTypePickerStyled
      value={value}
      className="DatapointValueTypePicker"
      input
      search={false}
      options={availableOptions}
      selectionKeys={{ label: 'label', value: 'value' }}
      onChange={onChange}
    />
  )
}

DatapointValueTypePicker.propTypes = {}

DatapointValueTypePicker.defaultProps = {
  options: [],
}

export default injectIntl(DatapointValueTypePicker)
