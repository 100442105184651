import React, { useCallback, useMemo, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsHighMaps from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official'
import { merge } from 'lodash'
import { useTheme } from 'styled-components'

import { HIGHCHARTS_DASH_STYLES } from 'util/constants'
import { useLocaleNumber } from 'util/numbers'

import { chartOptions } from 'components/Dashboard/components/Chart/functions'
import { UniversalChartStyled } from 'components/Dashboard/components/Chart/styled'
import { parseDefaultTime } from 'components/Dashboard/components/TimePicker/functions'
import TimePicker from 'components/Dashboard/components/TimePicker/TimePicker'
import { useContainerSize } from 'components/Global/hooks'
import RemountOnResize from 'components/Global/RemountOnResize'
import Status from 'components/Widgets/other/Status/Ui'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const highCharts = {
  default: Highcharts,
  heatmap: HighchartsHighMaps,
}

const UniversalChart = ({
  constants,
  captions,
  hideUnit,
  className,
  children,
  width,
  height,
  widget,
  graphOpacity = 0.1,
  enableExport = false,
  showLegend,
  stacking,
  series,
  chartStyle,
  type = 'default',
  defaultStartTime,
  defaultEndTime,
  onTimeChange,
  remountTrigger,
  ...props
}) => {
  const theme = useTheme()
  const localizeNumber = useLocaleNumber()
  const [time, setTime] = useState(
    parseDefaultTime({ startTime: defaultStartTime, endTime: defaultEndTime } || ['day'])
  )
  const [container, containerSize] = useContainerSize(
    { width: 0, height: 0 },
    null,
    null,
    theme.screenSize.screen.current
  )

  const chart =
    {
      BarChart: chartStyle || 'column',
      GraphCard: 'area',
      LineChart: 'area',
      PieChart: 'pie',
      Gauge: 'solidgauge',
    }[widget] ||
    chartStyle ||
    'area'

  const handleTimeChange = useCallback(
    (value) => {
      const newValue = {
        startTime: parseDefaultTime(value, 'startTime'),
        endTime: parseDefaultTime(value, 'endTime'),
        preset: value.preset,
      }
      setTime(newValue)
      onTimeChange(newValue)
    },
    [onTimeChange]
  )

  const mergedOptions = useMemo(() => {
    const dataChartOptions = {
      ...props,
      series:
        series?.map(({ useSteps, dashStyle, fillOpacity, ...serie }, index) => ({
          ...serie,
          fillOpacity: chart === 'area' ? fillOpacity || graphOpacity : undefined,
          dashStyle:
            chart === 'area' && HIGHCHARTS_DASH_STYLES.includes(dashStyle) ? dashStyle : 'Solid',
          color: index === 0 ? serie.color || theme.color.main : serie.color,
        })) || [],
      legend: { enabled: showLegend },
      chart: {
        fillOpacity: graphOpacity,
        height,
        width,
      },
    }

    const globalChartOptions = chartOptions(
      {
        type: chart,
        graphOpacity,
        enableExport,
        showLegend,
        stacking,
        theme,
        series: series || [],
        captions,
        hideUnit,
      },
      localizeNumber
    )
    return merge(containerSize, dataChartOptions, globalChartOptions)
  }, [
    captions,
    chart,
    containerSize,
    enableExport,
    graphOpacity,
    height,
    hideUnit,
    localizeNumber,
    props,
    series,
    showLegend,
    stacking,
    theme,
    width,
  ])

  if (widget === 'Status') {
    return <Status {...props} />
  }

  return (
    <>
      {onTimeChange && (
        <TimePicker className="TimeSelector" onChange={handleTimeChange} value={time} />
      )}
      <UniversalChartStyled className={`UniversalChart ${className}`} ref={container} type={chart}>
        <RemountOnResize
          h={containerSize.height}
          w={containerSize.width}
          remountTrigger={remountTrigger}
        >
          {containerSize.height > 0 && containerSize.width > 0 && (
            <HighchartsReact highcharts={highCharts[type]} options={mergedOptions} />
          )}
        </RemountOnResize>
        {children}
      </UniversalChartStyled>
    </>
  )
}

export default UniversalChart
