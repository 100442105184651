import React, { useCallback, useMemo, useState } from 'react'

import get from 'lodash/get'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import Button from 'components/Global/Button'
import DatapointSelectorStyled from 'components/Global/FormField/DatapointSelector/styled'
import { FormFieldLabel } from 'components/Global/FormField/styled'

import { flattenTree } from '../../EditableTree/utilities'
import DashboardPicker from '../DashboardPicker'
import Input from '../Input'
import SectionPicker from '../SectionPicker/SectionPicker'

const LinkSelector = ({
  value,
  singleDatapoint,
  dataKey = 'links',
  path,
  subPath,
  onSave,
  onCancel,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()

  const defaultValue =
    value || get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`)
  const flattenedDefault = useMemo(
    () =>
      defaultValue
        ? singleDatapoint
          ? [defaultValue]
          : flattenTree(defaultValue, 'id').filter(({ url }) => url)
        : [],
    [defaultValue, singleDatapoint]
  )

  const [selectedDashboards, setSelectedDashboards] = useState(flattenedDefault)
  const [selected, setSelected] = useState(null)

  const onSelectLink = useCallback(
    (value, raw, type) => {
      setSelectedDashboards([
        raw,
        ...(type === 'multi'
          ? flattenTree(raw.children).reduce((added, { key, ...item }) => {
              if (!flattenedDefault.some(({ url }) => url === `/dashboard/${key}`)) {
                added.push({ ...item, key, url: `/dashboard/${key}` })
              }
              return added
            }, [])
          : []),
        ...flattenedDefault.filter(({ url }) => url !== raw?.url),
      ])
      setSelected({
        value,
        raw: { ...raw, name: raw.title, children: type === 'single' ? [] : raw.children },
      })
    },
    [flattenedDefault]
  )

  const onUpdateLink = useCallback((e) => {
    const value = e.target.value
    setSelectedDashboards((state) => [
      { url: value, icon: 'fas fa-link' },
      ...state.filter(({ url }) => {
        return url !== value
      }),
    ])
    setSelected({ value, raw: { url: value, icon: 'fas fa-link', title: value } })
  }, [])

  const handleCancel = () => {
    if (action) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: false,
          dataKey: 'isOpen',
        },
      })
    }
    onCancel && onCancel()
  }

  const handleSave = () => {
    if (action) {
      action({
        type: 'ON_BOTTOM_DRAWER_UPDATE',
        payload: {
          value: {
            isOpen: false,
            width: 'full',
          },
        },
      })
    }

    onSave && onSave(selected)
    setSelected(null)
    setSelectedDashboards([])
  }

  const currentLink = get({ root: defaultValue }, `${path}`)

  return (
    <DatapointSelectorStyled className="LinkSelector" height="40vh">
      <div className="DatapointSelector__editor">
        <div>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.dashboards' })}</FormFieldLabel>
          <DashboardPicker
            value={selectedDashboards}
            selected={selected}
            singleDatapoint={singleDatapoint}
            onChange={onSelectLink}
          />
        </div>
        <div>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.sections' })}</FormFieldLabel>
          <SectionPicker value={selectedDashboards} onChange={onSelectLink} />
        </div>
        <div>
          <FormFieldLabel>{intl.formatMessage({ id: 'widget.url' })}</FormFieldLabel>
          <Input
            onBlur={onUpdateLink}
            placeholder="https://www.google.com"
            value={currentLink?.name ? null : currentLink?.url}
          />
        </div>
      </div>
      <div className="DatapointSelector__buttons">
        <Button
          label={intl.formatMessage({ id: 'widget.cancel' })}
          variant="mainButton"
          onClick={handleCancel}
        />
        <Button
          label={intl.formatMessage({ id: 'widget.ok' })}
          variant="mainButton"
          isPrimary
          onClick={handleSave}
        />
      </div>
    </DatapointSelectorStyled>
  )
}

export default LinkSelector
