import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { FILTERED_ENTITIES_QUERY } from 'services/entities'
import { useAllFixedFileRecordsAsSelectOptions } from 'services/fixedFiles'
import { useAppState } from 'services/store'

import CountrySelectField from 'components/Form/fields/CountrySelectField'
import DateField from 'components/Form/fields/DateField'
import LocationSearchField from 'components/Form/fields/LocationSearchField'
import NumberField from 'components/Form/fields/NumberField'
import SelectField from 'components/Form/fields/SelectField'
import TextField from 'components/Form/fields/TextField'

const TagEditor = ({
  value,
  defaultValue,
  values,
  valueKey = 'value',
  name,
  parentIsEnabled,
  readOnly,
  tagName,
  intl,
  isEditMode,
  enabled,
  required,
  onChange = () => {},
  watch,
  setValue,
  ...fieldProps
}) => {
  const {
    appState: { customer },
  } = useAppState()

  const handleGetDependencies = (dependencyObject) => {
    for (const [key, value] of Object.entries(dependencyObject)) {
      const tagIndex = values.tags.findIndex((item) => item.name === key)
      if (tagIndex > -1) {
        setValue(`tags[${tagIndex}].value`, value)
      }
    }
  }

  let ffInfo
  switch (tagName) {
    case 'geoCountry':
      ffInfo = { tableId: 1, labelField: 'name', type: 'select' }
      break
    case 'refrigerantEquipmentType':
      ffInfo = { tableId: 33, labelField: 'name', type: 'select' }
      break
    case 'supplier':
      /* ffInfo = { tableId: 38, labelField: 'name', type: 'select' } */
      ffInfo = {
        tableId: 31,
        labelField: 'name',
        type: 'select',
        filter: '"valueChainRelationId"=2 OR "valueChainRelationId"=4',
      }
      break
    case 'consumer':
      /* ffInfo = { tableId: 39, labelField: 'name', type: 'select' } */
      ffInfo = {
        tableId: 31,
        labelField: 'name',
        type: 'select',
        filter: '"valueChainRelationId"=3 OR "valueChainRelationId"=4',
      }
      break
    case 'departmentRef':
      ffInfo = { tableId: 30, labelField: 'name', type: 'select', filter: '"organisationTypeId"=1' }
      break
    case 'typeGas':
      ffInfo = { tableId: 32, labelField: 'name', type: 'select' }
      break
    case 'reportingPeriod':
      ffInfo = { tableId: 53, labelField: 'name', type: 'select' }
      break
    case 'transportApplication':
      ffInfo = { tableId: 36, labelField: 'name', type: 'select' }
      break
    case 'transportType':
      ffInfo = { tableId: 56, labelField: 'name', type: 'select' }
      break
    case 'transportMode':
      ffInfo = { tableId: 61, labelField: 'name', type: 'select' }
      break
    case 'transportLoadType':
      ffInfo = { tableId: 37, labelField: 'name', type: 'select' }
      break
    case 'travelMethod':
      ffInfo = { tableId: 15, labelField: 'description', type: 'select' }
      break
    case 'activityDirection':
      ffInfo = { tableId: 54, labelField: 'name', type: 'select' }
      break
    case 'typeLogistics':
      ffInfo = { tableId: 57, labelField: 'name', type: 'select' }
      break
    case 'finishingLevel':
      ffInfo = { tableId: 55, labelField: 'name', type: 'select' }
      break
    case 'emissionInputPreference':
      ffInfo = {
        tableId: 62,
        labelField: 'name',
        type: 'select',
        excludedIds: [24, 25].includes(fieldProps.context?.entityType)
          ? ['4']
          : [26, 27].includes(fieldProps.context?.entityType)
          ? ['3']
          : null,
      }
      break
    case 'emissionInputPreferenceBiogenicCo2':
      ffInfo = {
        tableId: 62,
        labelField: 'name',
        type: 'select',
        excludedIds: [24, 25].includes(fieldProps.context?.entityType)
          ? ['4']
          : [26, 27].includes(fieldProps.context?.entityType)
          ? ['3']
          : null,
      }
      break
    case 'vehicleType':
      ffInfo = { tableId: 16, labelField: 'description', type: 'select' }
      break
    case 'vehicleFuelType':
      ffInfo = { tableId: 26, labelField: 'name', type: 'select' }
      break
    case 'siteRef':
      ffInfo = {
        tagSet: [],
        refUuid: fieldProps?.context?.hierarchy[0],
        labelField: 'name',
        onlyEntityType: 2,
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'degreeDayReference': {
      ffInfo = {
        tagSet: [],
        refUuid: 'ab6d48c9-5614-4b6c-a004-61c81c76c714',
        labelField: 'name',
        onlyEntityType: 28,
        type: 'entitiesWithTagsSelect',
      }
      break
    }
    case 'meterReference':
      ffInfo = {
        tagSet: ['equip: 1', 'meter: 1', 'elec: 1'],
        refUuid: fieldProps?.context?.hierarchy[1],
        labelField: 'name',
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'hotWaterReference':
      ffInfo = {
        tagSet: ['equip: 1', 'hvac: 1', 'plant: 1', 'hot-water: 1'],
        refUuid: fieldProps?.context?.hierarchy[1],
        labelField: 'name',
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'coldWaterReference':
      ffInfo = {
        tagSet: ['equip: 1', 'hvac: 1', 'plant: 1', 'cold-water: 1'],
        refUuid: fieldProps?.context?.hierarchy[1],
        labelField: 'name',
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'chilledWaterReference':
      ffInfo = {
        tagSet: ['equip: 1', 'hvac: 1', 'plant: 1', 'chilled-water: 1'],
        refUuid: fieldProps?.context?.hierarchy[1],
        labelField: 'name',
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'steamReference':
      ffInfo = {
        tagSet: ['equip: 1', 'hvac: 1', 'plant: 1', 'steam: 1'],
        refUuid: fieldProps?.context?.hierarchy[1],
        labelField: 'name',
        type: 'entitiesWithTagsSelect',
      }
      break
    case 'submeterOf':
      ffInfo = {
        tagSet: ['equip: 1', 'meter: 1', `siteRef: ${fieldProps?.context?.hierarchy[1]}`],
        refUuid: fieldProps?.context?.hierarchy[0],
        labelField: 'name',
        type: 'meterOptionsSelect',
      }
      break
    case 'disposalDate':
    case 'installationDate':
    case 'refDate':
      ffInfo = { type: 'date' }
      break
    default:
      ffInfo = undefined
  }

  const extraTagValue = useMemo(() => {
    const foundIndex = values.markers.findIndex(
      (marker) =>
        // ['elec', 'hot-water', 'cold-water', 'chilled-water', 'gas', 'waterMeter'].includes(marker.name) && marker.enabled
        ['elecMeter', 'gasMeter', 'thermal-meter', 'waterMeter'].includes(marker.name) &&
        marker.enabled
    )
    return foundIndex > -1 ? `${values.markers[foundIndex].name}: 1` : ''
  }, [values.markers])

  const { data: ffOptions } = useAllFixedFileRecordsAsSelectOptions(
    ffInfo?.tableId,
    ffInfo?.labelField,
    customer?.id,
    ffInfo?.filter,
    !ffInfo?.tableId
  )

  const { data: logicOptions } = useQuery(FILTERED_ENTITIES_QUERY, {
    variables: {
      tags:
        ffInfo?.tagSet && extraTagValue !== '' && tagName === 'submeterOf'
          ? [...ffInfo?.tagSet, extraTagValue]
          : ffInfo?.tagSet,
      entityId: ffInfo?.refUuid,
      onlyEntityType: ffInfo?.onlyEntityType,
      parentInfo: fieldProps.context?.entityType,
    },
    skip: !ffInfo?.tagSet || !ffInfo?.refUuid,
    fetchPolicy: 'no-cache',
  })

  const nonFFOptions = logicOptions?.filteredEntities?.map((option) => {
    return { label: option.name, value: option.id, tags: option.tags }
  })
  let meterOptions

  if (tagName === 'submeterOf') {
    const value = watch(`${name}${valueKey ? `.${valueKey}` : ''}`)
    let foundIndex = values.markers.findIndex(
      (marker) =>
        ['siteMeter', 'buildingMeter', 'floorMeter', 'subMeter'].includes(marker.name) &&
        marker.enabled
    )
    const meterOrder = foundIndex > -1 ? values.markers[foundIndex].name : 'undefined'
    /* foundIndex = values.markers.findIndex(
      (marker) =>
        ['elec', 'hot-water', 'cold-water', 'chilled-water', 'gas', 'waterMeter'].includes(
          marker.name
        ) && marker.enabled
    ) */
    foundIndex = values.markers.findIndex(
      (marker) =>
        ['elecMeter', 'gasMeter', 'thermal-meter', 'waterMeter'].includes(marker.name) &&
        marker.enabled
    )
    const meterType = foundIndex > -1 ? values.markers[foundIndex].name : ''
    if (meterType && meterOrder) {
      meterOptions = nonFFOptions?.filter((meter) => meter.tags[meterType] === '1')
      switch (meterOrder) {
        case 'siteMeter':
          meterOptions = []
          break
        case 'buildingMeter':
          meterOptions = meterOptions?.filter((meter) => meter.tags.siteMeter === '1')
          break
        case 'floorMeter':
          meterOptions = meterOptions?.filter(
            (meter) =>
              (meter.tags.siteMeter === '1' &&
                meter.tags.siteRef &&
                meter.tags.siteRef === fieldProps?.context?.hierarchy[1]) ||
              (fieldProps?.context?.hierarchy?.length > 2 &&
                meter.tags.buildingMeter === '1' &&
                meter.tags.buildingRef &&
                meter.tags.buildingRef === fieldProps?.context?.hierarchy[2]) ||
              (fieldProps?.context?.hierarchy?.length > 3 &&
                meter.tags.floorMeter === '1' &&
                meter.tags.floorRef &&
                meter.tags.floorRef === fieldProps?.context?.hierarchy[3])
          )
          break
        case 'subMeter':
          meterOptions = meterOptions?.filter(
            (meter) =>
              (meter.tags.siteMeter === '1' &&
                meter.tags.siteRef &&
                meter.tags.siteRef === fieldProps?.context?.hierarchy[1]) ||
              (fieldProps?.context?.hierarchy?.length > 2 &&
                meter.tags.buildingMeter === '1' &&
                meter.tags.buildingRef &&
                meter.tags.buildingRef === fieldProps?.context?.hierarchy[2]) ||
              (fieldProps?.context?.hierarchy?.length > 3 &&
                meter.tags.floorMeter === '1' &&
                meter.tags.floorRef &&
                meter.tags.floorRef === fieldProps?.context?.hierarchy[3]) ||
              (fieldProps?.context?.hierarchy?.length > 2 &&
                meter.tags.subMeter === '1' &&
                meter.tags.buildingRef &&
                meter.tags.buildingRef === fieldProps?.context?.hierarchy[2])
          )
          break
        case 'undefined':
          meterOptions = [] /* meterOptions?.filter(
            (meter) =>
              (meter.tags.siteMeter === '1' &&
                meter.tags.siteRef &&
                meter.tags.siteRef === fieldProps?.context?.hierarchy[1]) ||
              (fieldProps?.context?.hierarchy?.length > 2 &&
                meter.tags.buildingMeter === '1' &&
                meter.tags.buildingRef &&
                meter.tags.buildingRef === fieldProps?.context?.hierarchy[2]) ||
              (fieldProps?.context?.hierarchy?.length > 3 &&
                meter.tags.floorMeter === '1' &&
                meter.tags.floorRef &&
                meter.tags.floorRef === fieldProps?.context?.hierarchy[3]) ||
              (fieldProps?.context?.hierarchy?.length > 2 &&
                meter.tags.subMeter === '1' &&
                meter.tags.buildingRef &&
                meter.tags.buildingRef === fieldProps?.context?.hierarchy[2]) ||
              (!meter.tags.siteMeter &&
                !meter.tags.buildingMeter &&
                !meter.tags.floorMeter &&
                !meter.tags.subMeter)
          ) */
          break
        default:
          break
      }
      if (fieldProps?.context?.hierarchy[5]) {
        meterOptions = meterOptions?.filter(
          (option) => option.value !== fieldProps?.context?.hierarchy[5]
        )
      }
    } else {
      meterOptions = []
    }
    if (meterOptions?.findIndex((option) => option.value === value) === -1) {
      if (value) setValue(`${name}${valueKey ? `.${valueKey}` : ''}`, null)
    }
  }

  if (required && parentIsEnabled && !enabled) {
    setValue && setValue(`${name}.enabled`, true)
  }

  const defaultFieldValue = defaultValue?.[valueKey] || defaultValue?.defaultValue || null

  if (tagName === 'geoCountry') {
    return (
      <CountrySelectField
        name={`${name}${valueKey ? `.${valueKey}` : ''}`}
        value={`${name}${valueKey ? `.${valueKey}` : ''}`}
        label=""
        allowClear={false}
        addAllOption={false}
        validation={{ required: parentIsEnabled === false ? false : required || enabled }}
      />
    )
  }

  if (ffInfo) {
    if (ffInfo.type === 'select') {
      return (
        <SelectField
          name={`${name}${valueKey ? `.${valueKey}` : ''}`}
          value={`${name}${valueKey ? `.${valueKey}` : ''}`}
          label=""
          options={
            ffInfo?.excludedIds
              ? ffOptions?.filter((option) => !ffInfo.excludedIds.includes(option.value))
              : ffOptions
          }
          validation={{ required: parentIsEnabled === false ? false : required || enabled }}
          defaultValue={defaultFieldValue}
          readOnly={readOnly}
        />
      )
    }

    if (ffInfo.type === 'meterOptionsSelect') {
      return (
        <SelectField
          name={`${name}${valueKey ? `.${valueKey}` : ''}`}
          value={`${name}${valueKey ? `.${valueKey}` : ''}`}
          label=""
          options={meterOptions}
          validation={{ required: parentIsEnabled === false ? false : required || enabled }}
          defaultValue={defaultFieldValue}
        />
      )
    }

    if (ffInfo.type === 'entitiesWithTagsSelect') {
      return (
        <SelectField
          name={`${name}${valueKey ? `.${valueKey}` : ''}`}
          value={`${name}${valueKey ? `.${valueKey}` : ''}`}
          label=""
          options={nonFFOptions}
          validation={{ required: parentIsEnabled === false ? false : required || enabled }}
          defaultValue={defaultFieldValue}
        />
      )
    }

    if (ffInfo.type === 'meterOptionsSelect') {
      return (
        <SelectField
          name={`${name}${valueKey ? `.${valueKey}` : ''}`}
          value={`${name}${valueKey ? `.${valueKey}` : ''}`}
          label=""
          options={meterOptions}
          validation={{ required: parentIsEnabled === false ? false : required || enabled }}
          defaultValue={defaultFieldValue}
        />
      )
    }

    return <DateField name={`${name}${valueKey ? `.${valueKey}` : ''}`} showTime={false} />
  }

  if (tagName === 'location') {
    return (
      <LocationSearchField
        getDependencies={(data) => handleGetDependencies(data)}
        name={`${name}${valueKey ? `.${valueKey}` : ''}`}
        className="TagField__value"
        type={['password', 'clientSecret'].includes(tagName) ? 'password' : null}
        onClick={() => {
          if (isEditMode && !readOnly) {
            setValue && setValue(`${name}.enabled`, true)
            onChange(value)
          }
        }}
        onBlur={(e) => {
          if (isEditMode && !e.target.value.length && !required && !readOnly) {
            setValue && setValue(`${name}.enabled`, false)
            onChange(e.target.value)
          }
        }}
        validation={{ required: parentIsEnabled === false ? false : required || enabled }}
        readOnly={readOnly}
        defaultValue={defaultFieldValue}
      />
    )
  }

  if (tagName === 'area') {
    return (
      <NumberField
        name={`${name}${valueKey ? `.${valueKey}` : ''}`}
        className="TagField__value"
        type={['password', 'clientSecret'].includes(tagName) ? 'password' : null}
        onClick={() => {
          if (isEditMode && !readOnly) {
            setValue && setValue(`${name}.enabled`, true)
            onChange(value)
          }
        }}
        onBlur={(e) => {
          if (isEditMode && !e.target.value.length && !required && !readOnly) {
            setValue && setValue(`${name}.enabled`, false)
            onChange(e.target.value)
          }
        }}
        validation={{ required: parentIsEnabled === false ? false : required || enabled }}
        readOnly={readOnly}
        defaultValue={defaultFieldValue}
      />
    )
  }

  return (
    <TextField
      name={`${name}${valueKey ? `.${valueKey}` : ''}`}
      className="TagField__value"
      type={['password', 'clientSecret'].includes(tagName) ? 'password' : null}
      onClick={() => {
        if (isEditMode && !readOnly) {
          setValue && setValue(`${name}.enabled`, true)
          onChange(value)
        }
      }}
      onBlur={(e) => {
        if (isEditMode && !e.target.value.length && !required && !readOnly) {
          setValue && setValue(`${name}.enabled`, false)
          onChange(e.target.value)
        }
      }}
      validation={{ required: parentIsEnabled === false ? false : required || enabled }}
      readOnly={readOnly}
      defaultValue={defaultFieldValue}
    />
  )
}
export default TagEditor
