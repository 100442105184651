import React, { useMemo } from 'react'

import SelectField from '../../SelectField'
import { useGlobalWarmingPotentials } from './hooks'

const GlobalWarmingPotentialSelectField = (props) => {
  const { data } = useGlobalWarmingPotentials()

  const options = useMemo(
    () =>
      data?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  )

  return <SelectField options={options} {...props} />
}

export default GlobalWarmingPotentialSelectField
