import styled from 'styled-components'

const DatapointConfiguratorStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    padding-top: 0.5rem;
    color: ${({ theme }) => theme.color.mainText};
    font-size: 1.4rem;
  }
  h4 {
    color: ${({ theme }) => theme.color.mainText};
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .Slide {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    &:last-child {
      .DatapointConfigurator__datapoints {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        > .Tags,
        > .ValuePicker {
          transition: flex 0.5s ease;
          flex: 1;
          height: auto;
          overflow: hidden;
          &:hover {
            flex: 2;
          }
        }
        > .ValuePicker {
          .TooltipContent {
            flex: 1;
            overflow: auto;
            padding: 0 0.5rem;
          }
        }
        .Loader {
          padding-bottom: 1rem;
        }
      }
    }
    .Accordeon {
      margin: 0 -1rem;
      &Content > div {
        padding: 0 1rem;
        .FormField {
          width: 100%;
          &.col2 {
            width: 50%;
          }
          &.col3 {
            width: 33%;
          }
          &.col4 {
            width: 25%;
            &:nth-child(2 of .col4) {
              .TooltipContent {
                left: auto;
                right: 0;
              }
            }
          }
          &.first {
            padding-right: 0.5rem;
          }
        }
      }
    }
    .EntityTree {
      height: 100%;
    }
  }
  .DatapointConfigurator__buttons {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 2rem;
  }
  .DatapointSelect__buttons {
    display: flex;
    padding: 0.5rem 0.5rem 2rem;
    .SmallButton {
      margin: 0 0.5rem;
    }
  }
  .DatapointConfigurator__datapoints {
    padding: 0 1rem 0.5rem;
    max-height: 50%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    p {
      color: ${({ theme }) => theme.color.softText};
      font-size: 0.8rem;
    }
  }
  .DatapointList {
    overflow: visible;
  }
`

export const InformationDetailConfiguratorStyled = styled.div`
  p {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.color.softStroke};
    .InformationDetailConfigurator__label {
      font-weight: 600;
      margin: 0 0.5rem;
      flex: 1;
      text-align: left;
    }
  }
`
export default DatapointConfiguratorStyled
