import React from 'react'

import { FormattedMessage, injectIntl } from 'react-intl'

import { defaultStartTimeUnits } from 'components/Dashboard/Widget/config/selections'

import ValuePicker from '../../../Form/components/ValuePicker/ValuePicker'
import { MultiFieldContainer } from '../FormFieldElement/styled'
import NumberInput from '../NumberInput'
import { FormFieldLabel } from '../styled'

const RangeSelector = ({ value, dataKey, dataCy = 'RangeSelector', onChange, onBlur, intl }) => {
  return (
    <>
      <FormFieldLabel>
        <FormattedMessage id={'widget.offset'} />
      </FormFieldLabel>
      <MultiFieldContainer className="MultiFieldContainer">
        <NumberInput
          className="NumberInput"
          value={value?.[dataKey]?.amount || 0}
          min={0}
          onBlur={(value) => onBlur(value, 'amount', dataKey)}
          dataCy={`${dataCy}-amount`}
        />
        <ValuePicker
          className="ValuePicker"
          value={value?.[dataKey]?.unit}
          options={defaultStartTimeUnits}
          onChange={(value) => onChange(value, 'unit', dataKey)}
          selectionKeys={{
            label: 'label',
            value: 'value',
          }}
          placeholder={intl.formatMessage({ id: 'widget.offset' })}
          search={false}
          dataCy={`${dataCy}-unit`}
        />
        <FormFieldLabel>{intl.formatMessage({ id: 'widget.during' })}</FormFieldLabel>
        <NumberInput
          className="NumberInput"
          value={value?.[dataKey]?.duration || 0}
          min={0}
          onBlur={(value) => onBlur(value, 'duration', dataKey)}
          dataCy={`${dataCy}-duration`}
        />
        <ValuePicker
          className="ValuePicker"
          value={value?.[dataKey]?.durationUnit}
          options={defaultStartTimeUnits}
          onChange={(value) => onChange(value, 'durationUnit', dataKey)}
          selectionKeys={{
            label: 'label',
            value: 'value',
          }}
          placeholder={intl.formatMessage({ id: 'widget.duration' })}
          search={false}
          dataCy={`${dataCy}-durationUnit`}
        />
      </MultiFieldContainer>
    </>
  )
}

export default injectIntl(RangeSelector)
