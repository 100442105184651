import {
  calculationMethods,
  dashStyles,
  graphTypes,
  minMax,
} from 'components/Dashboard/Widget/config/selections'

import {
  configFieldConvertor,
  generalConfigFields,
  generalTrendFields,
} from '../../../Dashboard/utils/common/helpers'

const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.lineChart',
    category: 'widget.charts',
    keywords: ['chart', 'trend'],
    img: '/resources/images/widgets/graphcard.png',
    info: {
      'nl-NL':
        '<h2>Grafiek</h2>\n' +
        '<p>De grafiekwidget geeft gegevens weer uit een of meer velden gedurende een bepaalde tijdsperiode. Deze gegevenstrends kunnen op verschillende manieren worden weergegeven, waardoor een breed scala aan aanpassingen mogelijk is.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Toon totalen</strong> Toets totalen weergeven of niet</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '<li><strong>Dekking</strong> Bepaal de dekking van de trendgrafieken</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groepen</strong> Een bestaande groep datapunten toevoegen of selecteren</li>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunttotalen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunttotalen</strong> Selecteer widgetgegevenspunten voor de totalen en voor elk gegevenspunt het bijbehorende label en of de deltawaarde al dan niet moet worden berekend</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Onbewerkte gegevens beschikbaar</strong> Schakel onbewerkte gegevens uit of niet</li>\n' +
        '<li><strong>Standaard onbewerkte gegevens aan</strong> Standaard onbewerkte gegevens weergeven of niet</li>\n' +
        '<li><strong>Granulariteit</strong> Selecteer de granulariteit van de gegevenspuntwaarden</li>\n' +
        '</ul>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Vaste waarden</strong> Maak een vaste waarde om op de widget weer te geven, met eenheid en kleur</li>\n' +
        '</ul>\n' +
        '<h3>Groepsconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Geselecteerde groep</strong> In deze sectie worden alle bestaande groepen weergegeven en kunt u er nieuwe datapunten voor selecteren</li>\n' +
        '<li><strong>Niet-gegroepeerde gegevenspunten</strong> In deze sectie worden alle geselecteerde, maar niet-gegroepeerde gegevenspunten weergegeven. Ze kunnen aan de geselecteerde groep worden toegevoegd door erop te klikken.</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor de groep</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor de groep</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor de groep</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode voor de groep</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een kleur voor het datapunt</li>\n' +
        '<li><strong>Stijl</strong> Selecteer een lijnstijl voor het datapunt</li>\n' +
        '<li><strong>Gebruik stappen</strong> Geef de trend stapsgewijs weer</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Graphique</h2>\n' +
        "<p>Le widget graphique affiche les données d'un ou plusieurs champs sur une période définie. Ces tendances de données peuvent être affichées de différentes manières, permettant un large éventail de personnalisation.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '<li><strong>Afficher les totaux</strong> Afficher les totaux ou non</li>\n' +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        "<li><strong>Opacité</strong> Déterminer l'opacité des graphiques de tendance</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groupes</strong> Ajoutez ou sélectionnez un groupe existant de points de données</li>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Totaux des points de données</h4>\n' +
        '<ul>\n' +
        "<li><strong>Totaux des points de données</strong> Sélectionnez les points de données du widget pour les totaux et pour chaque point de données son étiquette et s'il faut ou non calculer la valeur delta</li>\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Données brutes disponibles</strong> Désactiver ou non le basculement des données brutes</li>\n' +
        '<li><strong>Activé par défaut des données brutes</strong> Afficher les données brutes par défaut ou non</li>\n' +
        '<li><strong>Granularité</strong> Sélectionnez la granularité des valeurs des points de données</li>\n' +
        '</ul>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Valeurs fixes</strong> Créez une valeur fixe à afficher sur le widget, avec unité et couleur</li>\n' +
        '</ul>\n' +
        '<h3>Configuration de groupe</h3>\n' +
        '<ul>\n' +
        '<li><strong>Groupe sélectionné</strong> Cette section affiche tous les groupes existants et permet de sélectionner de nouveaux points de données</li>\n' +
        '<li><strong>Points de données non groupés</strong> Cette section affiche tous les points de données sélectionnés, mais non groupés. Ils peuvent être ajoutés au groupe sélectionné en cliquant dessus.</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le groupe</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le groupe</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le groupe</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul pour le groupe</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur pour le point de données</li>\n' +
        '<li><strong>Style</strong> Sélectionnez un style de ligne pour le point de données</li>\n' +
        '<li><strong>Utiliser les étapes</strong> Afficher la tendance par étapes</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Chart</h2>\n' +
        '<p>The Chart Widget displays data from one or more fields across a set time period. These data trends can be displayed in various ways, allowing for a wide range of customization.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Show Totals</strong> Display Totals button or not</li>\n' +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '<li><strong>Opacity</strong> Determine the opacity of the trend charts</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Groups</strong> Add or select an existing group of datapoints</li>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Datapoint Totals</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint Totals</strong> Select widget datapoints fot the totals and for each datapoint its label and whether or not to calculate the delta value</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Raw Data Available</strong> Disable raw data toggle or not</li>\n' +
        '<li><strong>Raw Data Default On</strong> Show raw data by default or not</li>\n' +
        '<li><strong>Granularity</strong> Select the granularity of the datapoint values</li>\n' +
        '</ul>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Fixed Values</strong> Create a fixed value to display on the widget, with unit and color</li>\n' +
        '</ul>\n' +
        '<h3>Group Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Selected Group</strong> This section displays all existing groups and allows to select new datapoints for it</li>\n' +
        '<li><strong>Ungrouped Datapoints</strong> This section displays all selected, but ungrouped datapoints. They can be added to the selected group by clicking on them.</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the group</li>\n' +
        '<li><strong>Color</strong> Select a color for the group</li>\n' +
        '<li><strong>Style</strong> Select a line style for the group</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method for the group</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Color</strong> Select a color for the datapoint</li>\n' +
        '<li><strong>Style</strong> Select a line style for the datapoint</li>\n' +
        '<li><strong>Use Steps</strong> Display the trend in a step wise manner</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'GraphCard',
      title: 'widget.lineChart',
      icon: 'fas fa-info',
      color: null,
      showTotals: false,
      enableExport: false,
      showLegend: true,
      useSteps: false,
      masterDetail: false,
      stacking: false,
      details: 'none',
      graphOpacity: 0.1,
      dashboardLink: null,
      datapoints: [],
      datapointsGroups: [],
      datapointTotals: [],
      datapointsLinked: [],
      constants: [],
      defaultStartTime: {
        preset: 'past7Days',
      },
      defaultGranularityValue: 1,
      defaultGranularity: 'd',
      calculationMethod: 'mean',
    },
    unUsedDatapointProperties: ['rangeId', 'offsets'],
    preview: {
      icon: 'fas fa-chart-area',
      chart: {
        series: [
          {
            color: '#109afd',
            data: [
              [0, 1],
              [1, 5],
              [2, 3],
              [3, 7],
              [4, 1],
              [5, 2],
            ],
            type: 'area',
            name: 'Series 1',
          },
          {
            color: variables.color.light.theme,
            data: [
              [0, 2],
              [1, 8],
              [2, 10],
              [3, 3],
              [4, 5],
              [5, 1],
            ],
            type: 'area',
            name: 'Series 2',
          },
        ],
      },
    },
    fields: [
      ...generalConfigFields,
      ...configFieldConvertor(generalTrendFields, []),
      {
        category: 'widget.datapoints',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.useSteps' },
                  dataKey: 'useSteps',
                  col: 'col2',
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: graphTypes,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.style' },
                  dataKey: 'dashStyle',
                  componentName: 'ValuePicker',
                  componentProps: {
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                    options: dashStyles,
                  },
                },
                {
                  category: 'widget.fixedValues',
                  componentName: 'Table',
                  label: { formatted: 'widget.fixedValues' },
                  dataKey: 'constants',
                  componentProps: {
                    columns: [
                      {
                        label: { formatted: 'widget.value' },
                        dataKey: 'value',
                        componentName: 'NumberInput',
                        width: 10,
                      },
                      {
                        label: { formatted: 'widget.tagValue' },
                        dataKey: 'value',
                        componentName: 'EntityTagPicker',
                        componentProps: {
                          dataKey: 'datapoints',
                          tagsOnly: true,
                          numberValues: true,
                        },
                        width: 15,
                      },
                      {
                        componentName: 'ValuePicker',
                        componentProps: {
                          query: {
                            name: 'UNITS_QUERY',
                            dataKey: 'units',
                          },
                          selectionKeys: {
                            value: 'formatted',
                            label: 'formatted',
                          },
                        },
                        label: { formatted: 'widget.unit' },
                        dataKey: 'unit',
                        width: 10,
                      },
                      {
                        label: { formatted: 'widget.type' },
                        dataKey: 'type',
                        componentName: 'ValuePicker',
                        componentProps: {
                          selectionKeys: { label: 'label', value: 'value' },
                          options: minMax.filter(({ value }) => value !== 'fixedValue'),
                          search: false,
                        },
                        width: 10,
                      },
                      {
                        label: { formatted: 'widget.color' },
                        dataKey: 'color',
                        componentName: 'ColorPicker',
                        width: 10,
                      },
                    ],
                    defaultRowValue: {
                      value: 0,
                      color: variables.color.color.aviary.error,
                    },
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                    addRangeMethod: true,
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapoints',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
          groupSection: {
            name: 'datapointsGroups',
            componentName: 'DatapointGroupConfigurator',
            componentProps: {
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'name',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                    clearIcon: true,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.useSteps' },
                  dataKey: 'useSteps',
                  col: 'col2',
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.style' },
                  dataKey: 'dashStyle',
                  componentName: 'ValuePicker',
                  componentProps: {
                    options: dashStyles,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: graphTypes,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.groupCalculationMethod' },
                  dataKey: 'method',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: calculationMethods.filter(
                      ({ value }) => value !== 'range' && value !== 'amount'
                    ),
                  },
                },
                {
                  category: 'widget.fixedValues',
                  componentName: 'Table',
                  label: { formatted: 'widget.fixedValues' },
                  dataKey: 'constants',
                  componentProps: {
                    columns: [
                      {
                        label: { formatted: 'widget.value' },
                        dataKey: 'value',
                        componentName: 'NumberInput',
                        width: 10,
                      },
                      {
                        label: { formatted: 'widget.type' },
                        dataKey: 'type',
                        componentName: 'ValuePicker',
                        componentProps: {
                          selectionKeys: { label: 'label', value: 'value' },
                          options: minMax,
                          search: false,
                        },
                        width: 10,
                      },
                      {
                        label: { formatted: 'widget.color' },
                        dataKey: 'color',
                        componentName: 'ColorPicker',
                        width: 10,
                      },
                    ],
                    defaultRowValue: {
                      value: 0,
                      color: variables.color.color.aviary.error,
                    },
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                    addRangeMethod: true,
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapointsGroups',
                  },
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
          linkedSection: {
            name: 'datapointsLinked',
            componentName: 'LinkedDataConfigurator',
            componentProps: {
              dataKey: 'datapointsLinked',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'name',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  dataKey: 'color',
                  col: 'col2 first',
                  componentProps: {
                    width: 250,
                    clearIcon: true,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.useSteps' },
                  dataKey: 'useSteps',
                  col: 'col2',
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.style' },
                  dataKey: 'dashStyle',
                  componentName: 'ValuePicker',
                  componentProps: {
                    options: dashStyles,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                },
                {
                  category: 'widget.general',
                  label: { formatted: 'widget.type' },
                  dataKey: 'type',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: graphTypes,
                    selectionKeys: {
                      value: 'value',
                      label: 'label',
                    },
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.groupDatapoints' },
                  dataKey: 'grouped',
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.groupCalculationMethod' },
                  dataKey: 'method',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: calculationMethods.filter(
                      ({ value }) => value !== 'range' && value !== 'amount'
                    ),
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.grouped',
                      dependencyType: 'hasValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.interval',
                  componentName: 'CalculationMethod',
                  dataKey: '',
                  componentProps: {
                    dataKey: 'calculationMethod',
                    addRangeMethod: true,
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapointsGroups',
                  },
                  dependencies: [
                    {
                      dependentOn: 'selectedItem.id',
                      dependencyType: 'valueIsNot',
                      dependencyResolver: 'show',
                      dependencyValue: 'pointValue',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.datapointTotals',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapointTotals', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapointTotals',
          section: {
            name: 'datapointTotals',
            componentName: 'DatapointConfigurator',
            componentProps: {
              dataKey: 'datapointTotals',
              fields: [
                {
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
                {
                  componentName: 'Switch',
                  label: { formatted: 'widget.deltaValue' },
                  dataKey: 'difference',
                  col: 'col2 first',
                },
                {
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapointTotals',
                  },
                },
                {
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  componentProps: {},
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        dataKey: '',
        componentProps: {
          dataKey: 'defaultStartTime',
          datapointsDataKey: 'datapoints',
          range: true,
          hideGranularity: false,
          addRangeMethod: true,
        },
      },
      {
        category: 'widget.dashboardLink',
        componentName: 'DashboardLink',
        dataKey: 'dashboardLink',
        componentProps: {
          dataKey: 'dashboardLink',
        },
      },
      {
        category: 'widget.fixedValues',
        componentName: 'FixedValues',
        label: { formatted: 'widget.fixedValues' },
        dataKey: 'constants',
        componentProps: {
          dataKey: 'constants',
          columns: [],
          defaultRowValue: {
            unit: null,
            value: 0,
            color: variables.color.color.aviary.error,
          },
        },
      },
    ],
  },
}

export default config
