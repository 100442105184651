import React from 'react'

import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useFieldArray, useFormContext } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import FlowField from 'components/Form/fields/FlowField'

import { FlowListFieldStyled } from './styled'

const FlowListField = ({
  value,
  name,
  filter,
  datapointFilter,
  format = 'PPpp',
  dependencies,
  onOpenDetails,
  intl,
  allowEdit,
  createMode,
  ...fieldProps
}) => {
  const { loading, isEditMode } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: `${name}.actions`,
    shouldUnregister: true,
  })

  if (!fields || !fields?.length) {
    return (
      <>
        {(isEditMode || createMode) && (
          <Button
            name="ui.rules.toggleExpandAll"
            onClick={() => {
              append({
                id: null,
                title: 'title',
                action: {
                  type: 'mail',
                  message: 'Alert {site} {building}',
                  recipients: {
                    users: [],
                  },
                },
                threshold: 0,
              })
            }}
          >
            {intl.formatMessage({
              id: 'componentCaptions.addCondition',
            })}
          </Button>
        )}
        <span>
          {intl
            ? intl.formatMessage({
                id: 'componentCaptions.noConditions',
              })
            : 'No conditions7'}
        </span>
      </>
    )
  }

  if (loading) {
    return <Skeleton width="200px" />
  }

  return (
    <FlowListFieldStyled {...fieldProps}>
      <div className="FlowListField__list">
        {fields.map((item, index) => (
          <FlowField
            name={`${name}.actions[${index}]`}
            removeFlowAction={remove}
            key={item.id ?? `temp-${index}-${item.action.type}`}
            createMode={createMode}
            index={index}
            intl={intl}
            isLast={index === fields.length - 1}
          />
        ))}
      </div>
      {(isEditMode || createMode) && (
        <Button
          onClick={() => {
            append({
              id: null,
              title: '',
              action: {
                type: 'mail',
                message: 'Alert {site} {building}',
                recipients: {
                  users: [],
                },
              },
              threshold: 0,
            })
          }}
        >
          {intl.formatMessage({
            id: 'componentCaptions.addCondition',
          })}
        </Button>
      )}
    </FlowListFieldStyled>
  )
}

FlowListField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      required: PropTypes.bool,
    })
  ),
}

export default FlowListField
