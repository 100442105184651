import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Radio } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { useOrganisationReportingYear } from 'services/organisations'

import RangePicker from 'components/Form/components/RangePicker'

import AntDatePicker from '../../../Global/AntDesign/AntDatePicker/AntDatePicker'
import { OrganisationReportingDateRangeStyled } from './styled'

const OrganisationReportingDateRange = ({
  defaultValue,
  enableDefaultValue = true,
  onChange,
  organisationId,
  size,
  allowCustomRange = false,
  presets = true,
  format = 'YYYY-MM-DD',
  disabled,
}) => {
  const [isCustomDateRange, setIsCustomDateRange] = useState(!organisationId)
  const [customDateRange, setCustomDateRange] = useState(defaultValue)
  const [reportingYearDate, setReportingYearDate] = useState()
  const [hasSelectedYear, setHasSelectedYear] = useState(false)

  const handleChange = useCallback(
    (range) => {
      if (onChange) {
        onChange(range)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data: reportingYearData, createDateRange, loading } = useOrganisationReportingYear(
    organisationId
  )

  const getReportingYearRange = useCallback(
    (reportingYearDate) => {
      if (!reportingYearDate) {
        return
      }

      const range = createDateRange(reportingYearDate.year())

      if (!range[0]) {
        return
      }

      return range
    },
    [createDateRange]
  )

  const formatReportingYearRange = useCallback(
    (reportingYearDate, format) => {
      const range = getReportingYearRange(reportingYearDate)

      if (!range) {
        return
      }

      const [start, end] = range

      return `${start.format(format)} - ${end.format(format)}`
    },
    [getReportingYearRange]
  )

  const setDefaultReportingYear = useCallback(() => {
    const [start] = getReportingYearRange(dayjs())
    const year = start.isAfter() ? dayjs().subtract(1, 'year') : dayjs()
    setReportingYearDate(year)
    const range = getReportingYearRange(year)
    handleChange(range)
  }, [getReportingYearRange, handleChange])

  useEffect(() => {
    if (enableDefaultValue && !hasSelectedYear && reportingYearData && !isCustomDateRange) {
      setDefaultReportingYear()
    }
  }, [
    hasSelectedYear,
    reportingYearData,
    isCustomDateRange,
    setDefaultReportingYear,
    enableDefaultValue,
  ])

  useEffect(() => {
    setIsCustomDateRange(!organisationId)
  }, [organisationId])

  const reportingYearFormatted = useMemo(
    () => (reportingYearDate ? formatReportingYearRange(reportingYearDate, format) : null),
    [formatReportingYearRange, reportingYearDate, format]
  )

  const handleIsCustomRangeChange = useCallback(
    (event) => {
      setIsCustomDateRange(event.target.value)
      const range = event.target.value ? customDateRange : getReportingYearRange(reportingYearDate)
      if (range) {
        handleChange(range)
      }
    },
    [setIsCustomDateRange, getReportingYearRange, customDateRange, handleChange, reportingYearDate]
  )

  const handleReportingYearDateChange = useCallback(
    (year) => {
      setReportingYearDate(year)
      setHasSelectedYear(true)
      const range = getReportingYearRange(year)
      handleChange(range)
    },
    [setReportingYearDate, setHasSelectedYear, handleChange, getReportingYearRange]
  )

  const handleCustomDateRangeChange = useCallback(
    (value) => {
      setCustomDateRange(value)
      handleChange(value)
    },
    [handleChange]
  )

  return (
    <OrganisationReportingDateRangeStyled className="OrganisationReportingDateRange">
      {allowCustomRange && (
        <Radio.Group
          options={[
            { value: false, label: 'Reporting year', disabled: !reportingYearData },
            { value: true, label: 'Custom' },
          ]}
          value={isCustomDateRange}
          onChange={handleIsCustomRangeChange}
          optionType="button"
          buttonStyle="solid"
          size={size}
          disabled={disabled || loading}
        />
      )}
      {!isCustomDateRange || !allowCustomRange ? (
        <AntDatePicker
          key="reporting"
          value={reportingYearDate || customDateRange?.[0]}
          onChange={handleReportingYearDateChange}
          allowClear={false}
          picker="year"
          disabled={(!allowCustomRange && !organisationId) || loading || disabled}
          size={size}
          format={reportingYearFormatted}
        />
      ) : (
        <RangePicker
          key="custom"
          onChange={handleCustomDateRangeChange}
          value={customDateRange}
          size={size}
          allowClear={false}
          disabled={disabled}
          presets={presets}
        />
      )}
    </OrganisationReportingDateRangeStyled>
  )
}

OrganisationReportingDateRange.propTypes = {
  allowCustomRange: PropTypes.bool,
}

export default OrganisationReportingDateRange
