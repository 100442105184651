import React from 'react'

import { injectIntl } from 'react-intl'

import Icon from 'components/Global/Icon'
import { ContentLoaderStyled } from 'components/Layout/Content/styled'

const ContentNoData = ({ className, intl }) => {
  return (
    <ContentLoaderStyled
      className={`Loader ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      fullscreen
    >
      <span>{intl.formatMessage({ id: 'settings.noData' })}</span>
      <Icon icon="fas fa-database" />
    </ContentLoaderStyled>
  )
}

export default injectIntl(ContentNoData)
