import React from 'react'

import FormField from '../../../Global/FormField'

export const PhaseToggleConfigurator = ({ value = {}, onChange }) => {
  return (
    <div className="PhaseToggleConfigurator">
      <FormField
        value={value.iconOn}
        dataKey="iconOn"
        col="col4"
        label={{ formatted: 'widget.iconOn' }}
        componentName="IconPicker"
        componentProps={{
          bottomDrawer: true,
        }}
        onChange={onChange}
      />
      <FormField
        value={value.colorOn}
        dataKey="colorOn"
        col="col4"
        label={{ formatted: 'widget.colorOn' }}
        componentName="ColorPicker"
        onChange={onChange}
      />
      <FormField
        value={value.iconOff}
        dataKey="iconOff"
        col="col4"
        label={{ formatted: 'widget.iconOff' }}
        componentName="IconPicker"
        componentProps={{
          bottomDrawer: true,
        }}
        onChange={onChange}
      />
      <FormField
        value={value.colorOff}
        dataKey="colorOff"
        col="col4"
        label={{ formatted: 'widget.colorOff' }}
        componentName="ColorPicker"
        onChange={onChange}
      />
      <FormField
        value={
          value.writeDatapoint ? { writeDatapoint: [value.writeDatapoint] } : { writeDatapoint: [] }
        }
        label={{ formatted: 'widget.writeDatapoint' }}
        dataKey="writeDatapoint"
        //subPath={subPath}
        componentName="Datapoints"
        componentProps={{
          dataKey: 'writeDatapoint',
          singleDatapoint: true,
        }}
        onChange={({ value, dataKey }) => onChange({ value, dataKey: dataKey || 'writeDatapoint' })}
      />
    </div>
  )
}
