import React from 'react'

import { Tag } from 'antd'
import { getApplicationKeyMap } from 'react-hotkeys'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import { CanaryModal } from 'components/Global/Modal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const AboutModal = ({ intl, onClose }) => {
  const keyMap = getApplicationKeyMap()

  return (
    <CanaryModal
      title={intl.formatMessage({ id: 'shortcuts' })}
      visible
      footer={null}
      onClose={onClose}
      onCancel={onClose}
    >
      <Container>
        {Object.entries(keyMap).map(([name, keys]) => {
          return (
            <div>
              {keys.sequences.map(({ sequence }) => (
                <Tag>{sequence}</Tag>
              ))}
              {intl.formatMessage({ id: `shortcut.${name}` })}
            </div>
          )
        })}
      </Container>
    </CanaryModal>
  )
}

export default injectIntl(AboutModal)
