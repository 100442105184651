import React from 'react'

import get from 'lodash/get'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import DatapointSelectorStyled from 'components/Global/FormField/DatapointSelector/styled'

import DatapointSelectorButtons from '../DatapointSelector/DatapointSelectorButtons'
import EntityFilter from '../DatapointSelector/EntityFilter'
import { useEntitiesFilter } from './hooks'

const EntitySelector = ({
  value,
  singleDatapoint,
  dataKey = 'entities',
  groupDataKey,
  subPath,
  tags,
  meta: entityMeta,
  onlyEntityType,
  onSave,
  onCancel,
  onValueUpdate,
  intl,
}) => {
  const {
    state: { elementInEditMode = { preview: {} } },
    action,
  } = useDashboard()

  const entitiesFilter = useEntitiesFilter({
    onlyEntityType,
    tags,
  })

  const selectedEntities =
    value || get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}`) || []

  const onSelectDatapoints = (values) => {
    const datapointsToSave = values.map(({ tags, ...item }) => ({ ...entityMeta, ...item }))

    const newValue =
      singleDatapoint && selectedEntities
        ? datapointsToSave.filter(({ id }) => !selectedEntities.some((dp) => dp.id === id))
        : datapointsToSave

    const meta = {
      groupDataKey,
      removed: selectedEntities.filter(({ id }) => !datapointsToSave.some((dp) => dp.id === id)),
      added: datapointsToSave.filter(({ id }) => !selectedEntities.some((dp) => dp.id === id)),
    }
    if (action) {
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: { dataKey, subPath },
          value: newValue,
          meta,
        },
      })
    }
    onValueUpdate && onValueUpdate(newValue, meta)
  }

  return (
    <DatapointSelectorStyled className="EntitySelector" height="40vh">
      <EntityFilter
        {...entitiesFilter}
        selectedDatapoints={selectedEntities}
        onValueUpdate={onSelectDatapoints}
        intl={intl}
        fromEntityTypes={['customer', 'site', 'building', 'floor', 'room', 'equip']}
      />
      <DatapointSelectorButtons onSave={onSave} onCancel={onCancel} intl={intl} />
    </DatapointSelectorStyled>
  )
}

export default EntitySelector
