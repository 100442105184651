import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import { emissionFactors } from 'components/Dashboard/Widget/config/selections'
import OptionPicker from 'components/Form/components/OptionPicker'

import { useDashboard } from '../../../Dashboard/DashboardProvider'

const EmissionFactorSelector = ({ dataKey = 'datapoints', value, onChange, isMultiSelect }) => {
  const {
    state: { elementInEditMode = { preview: {}, selectedDatapointMap: {} } },
  } = useDashboard()

  const selectedDatapoint =
    elementInEditMode.preview[dataKey][elementInEditMode.selectedDatapointMap?.[dataKey] || 0]

  const options = useMemo(() => {
    if (selectedDatapoint) {
      switch (selectedDatapoint.scopeId) {
        case '1':
        case '2':
          return emissionFactors.filter(({ value }) =>
            ['total', 'co2', 'ch4', 'ch4Co2e', 'n2o', 'n2oCo2e', 'biogenicCo2'].includes(value)
          )
        case '4':
          return emissionFactors.filter(({ value }) => !['ar4', 'ar5'].includes(value))
        case '3':
        case '5':
        case '6':
          return emissionFactors.filter(({ value }) => ['total', 'biogenicCo2'].includes(value))
        default:
          return emissionFactors
      }
    }
  }, [selectedDatapoint])

  return (
    <OptionPicker
      value={value}
      selectionKeys={{ label: 'label', value: 'value' }}
      valueKey="value"
      options={options}
      onChange={onChange}
      isMultiSelect={isMultiSelect}
    />
  )
}

export default injectIntl(EmissionFactorSelector)
