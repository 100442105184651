export const presets = [
  {
    id: 'preset-912',
    title: 'Detail chart',
    layout: {
      new: true,
      sections: [
        {
          h: 472,
          type: 'Section',
          header: {
            hide: true,
            icon: 'fas fa-info',
            title: '1 x 1 grid',
          },
          columns: {
            l: 12,
            m: 6,
            s: 2,
          },
          elements: [
            {
              h: 100,
              w: 12,
              icon: 'fas fa-info',
              type: 'GraphCard',
              title: 'Detail chart',
              details: 'floatingGrid',
              dataType: 'assets',
              stacking: false,
              useSteps: false,
              datapoints: [],
              showLegend: true,
              showTotals: false,
              enableExport: false,
              graphOpacity: 0.1,
              masterDetail: true,
              datapointTotals: [],
              datapointsGroups: [],
              datapointsLinked: [
                {
                  id: 'datapoints',
                  name: 'Linked datapoints',
                  type: 'area',
                  color: null,
                  method: 'mean',
                  grouped: false,
                },
                {
                  id: 'linkDatapoints',
                  name: 'Linked extra datapoints',
                },
              ],
              defaultStartTime: {
                preset: 'linkedWidget',
              },
              calculationMethod: 'mean',
              defaultGranularity: 'linkedData',
              defaultGranularityValue: 1,
              defaultCalculationMethod: 'max',
            },
          ],
          dateSelection: false,
        },
      ],
    },
    owner: 117,
    readOnly: true,
    creatorMail: 'tom@canary.expert',
    masterDashboardRights: 2,
    isDataEntryDashboard: false,
    __typename: 'SimpleDashboard',
  },
  {
    id: 'preset-915',
    title: 'Decomposition Detail',
    layout: {
      new: true,
      sections: [
        {
          h: 944,
          type: 'Section',
          header: {
            hide: true,
            icon: 'fas fa-info',
            title: '1 x 1 grid',
          },
          columns: {
            l: 12,
            m: 6,
            s: 2,
          },
          elements: [
            {
              h: 50,
              w: 8,
              icon: 'fas chart-line',
              type: 'GraphCard',
              title: 'Decomposition detail',
              details: 'none',
              dataType: 'assets',
              stacking: false,
              useSteps: false,
              datapoints: [],
              showLegend: true,
              showTotals: false,
              enableExport: false,
              graphOpacity: 0.1,
              masterDetail: true,
              datapointTotals: [],
              datapointsGroups: [],
              datapointsLinked: [
                {
                  id: 'datapoints',
                  name: 'Linked datapoints',
                  type: 'area',
                  color: null,
                  method: 'mean',
                  grouped: false,
                },
                {
                  id: 'linkDatapoints',
                  name: 'Linked extra datapoints',
                },
              ],
              defaultStartTime: {
                preset: 'linkedWidget',
              },
              calculationMethod: 'mean',
              defaultGranularity: 'linkedData',
              defaultGranularityValue: 1,
              defaultCalculationMethod: 'max',
            },
            {
              h: 50,
              w: 4,
              icon: 'fas chart-pie',
              type: 'ComparisonPieChart',
              color: '#535ab4',
              title: 'Pie Chart',
              dataType: 'assets',
              constants: [],
              sortOrder: 'none',
              datapoints: [],
              showLegend: true,
              showPercentage: true,
              datapointTotals: [],
              datapointsGroups: [],
              datapointsLinked: [
                {
                  id: 'datapoints',
                  name: 'Linked datapoints',
                },
              ],
              defaultStartTime: {
                preset: 'linkedWidget',
              },
              calculationMethod: 'mean',
              defaultGranularity: 'm',
              defaultGranularityValue: 1,
            },
            {
              h: 50,
              w: 12,
              icon: 'fas chart-bar',
              type: 'BarChart',
              title: 'Staafdiagram',
              details: 'none',
              dataType: 'assets',
              stacking: true,
              useSteps: false,
              datapoints: [],
              showLegend: true,
              showTotals: false,
              enableExport: false,
              graphOpacity: 0.1,
              datapointTotals: [],
              datapointsGroups: [],
              datapointsLinked: [
                {
                  id: 'datapoints',
                  name: 'Linked datapoints',
                  type: 'column',
                  color: null,
                  method: 'mean',
                  grouped: false,
                },
                {
                  id: 'linkDatapoints',
                  name: 'Linked extra datapoints',
                  type: 'column',
                },
              ],
              defaultStartTime: {
                preset: 'linkedWidget',
              },
              calculationMethod: 'mean',
              defaultGranularity: 'linkedData',
              defaultGranularityValue: 1,
            },
          ],
          dateSelection: false,
        },
      ],
    },
    owner: 117,
    readOnly: true,
    creatorMail: 'tom@canary.expert',
    masterDashboardRights: 2,
    isDataEntryDashboard: false,
    __typename: 'SimpleDashboard',
  },
  {
    id: 'preset-916',
    title: 'Comparison Detail',
    layout: {
      new: true,
      sections: [
        {
          h: 366,
          type: 'Section',
          header: {
            hide: true,
            icon: 'fas fa-info',
            title: '2 x 2 / 2 x 1 grid',
          },
          columns: {
            l: 12,
            m: 6,
            s: 2,
          },
          elements: [
            {
              h: 100,
              w: 9,
              id: '78eabcf2-352f-498f-9cb4-b4e795a7a97d',
              type: 'Section',
              elements: [
                {
                  h: 100,
                  w: 12,
                  icon: 'fas chart-line',
                  type: 'GraphCard',
                  title: 'Line chart',
                  details: 'none',
                  dataType: 'assets',
                  stacking: false,
                  useSteps: false,
                  datapoints: [],
                  showLegend: true,
                  showTotals: false,
                  enableExport: false,
                  graphOpacity: 0.1,
                  masterDetail: false,
                  datapointTotals: [],
                  datapointsGroups: [],
                  datapointsLinked: [
                    {
                      id: 'datapoints',
                      name: 'Linked datapoints',
                      type: 'area',
                      color: null,
                      method: 'mean',
                      grouped: false,
                    },
                    {
                      id: 'linkDatapoints',
                      name: 'Linked extra datapoints',
                    },
                  ],
                  defaultStartTime: {
                    preset: 'linkedWidget',
                  },
                  calculationMethod: 'mean',
                  defaultGranularity: 'linkedData',
                  defaultGranularityValue: 1,
                  defaultCalculationMethod: 'max',
                },
              ],
            },
            {
              h: 100,
              w: 3,
              id: 'aa93fa31-dc04-40d5-9adf-b4a3ec109b6d',
              type: 'Section',
              elements: [
                {
                  h: 50.09652509652511,
                  w: 12,
                  icon: 'fas fa-info',
                  line: false,
                  type: 'Status',
                  title: 'Status',
                  method: 'sum',
                  dataKey: 'referenceValue',
                  captions: {},
                  dataType: 'assets',
                  showUnit: true,
                  fontColor: '#FFF',
                  hideValue: false,
                  timeRange: {
                    preset: 'linkedWidget',
                  },
                  datapoints: [],
                  customLogic: '',
                  arrowUpColor: '#44bc66',
                  showDiffArrow: true,
                  arrowDownColor: '#f14e3e',
                  diffPercentage: true,
                  comparisonPeriod: {
                    unit: 'day',
                    amount: 1,
                    duration: 1,
                    durationUnit: 'day',
                  },
                  datapointsLinked: [
                    {
                      id: 'pointValue',
                      name: 'Clicked point value',
                    },
                  ],
                  defaultGranularity: 'h',
                  defaultGranularityValue: 1,
                },
                {
                  h: 49.90347490347489,
                  w: 12,
                  icon: 'fas fa-info',
                  line: false,
                  type: 'Status',
                  title: 'Status',
                  method: 'sum',
                  dataKey: 'referenceValue',
                  captions: {},
                  dataType: 'assets',
                  showUnit: true,
                  fontColor: '#FFF',
                  hideValue: false,
                  timeRange: {
                    preset: 'linkedWidget',
                  },
                  datapoints: [],
                  customLogic: '',
                  arrowUpColor: '#44bc66',
                  dashboardLink: {
                    unit: 'hour',
                    amount: 1,
                    duration: 1,
                    durationUnit: 'hour',
                  },
                  showDiffArrow: true,
                  arrowDownColor: '#f14e3e',
                  diffPercentage: true,
                  comparisonPeriod: {
                    unit: 'day',
                    amount: 1,
                    duration: 1,
                    durationUnit: 'day',
                  },
                  datapointsLinked: [
                    {
                      id: 'linkDatapoints',
                      name: 'Linked extra datapoints',
                    },
                  ],
                  defaultGranularity: 'h',
                  defaultGranularityValue: 1,
                },
              ],
            },
          ],
          dateSelection: false,
        },
      ],
    },
    owner: 117,
    readOnly: true,
    creatorMail: 'tom@canary.expert',
    masterDashboardRights: 2,
    isDataEntryDashboard: false,
    __typename: 'SimpleDashboard',
  },
  {
    id: 'preset-918',
    title: 'Comparison Gauge',
    layout: {
      new: true,
      sections: [
        {
          h: 366,
          type: 'Section',
          header: {
            hide: true,
            icon: 'fas fa-info',
            title: '2 x 2 / 2 x 1 grid',
          },
          columns: {
            l: 12,
            m: 6,
            s: 2,
          },
          elements: [
            {
              h: 100,
              w: 9,
              id: 'ac45f931-09a3-404f-abf6-06b6b18803e2',
              type: 'Section',
              elements: [
                {
                  h: 100,
                  w: 12,
                  icon: 'fas chart-line',
                  type: 'GraphCard',
                  title: 'Line chart',
                  details: 'none',
                  dataType: 'assets',
                  stacking: false,
                  useSteps: false,
                  datapoints: [],
                  showLegend: true,
                  showTotals: false,
                  enableExport: false,
                  graphOpacity: 0.1,
                  masterDetail: false,
                  datapointTotals: [],
                  datapointsGroups: [],
                  datapointsLinked: [
                    {
                      id: 'datapoints',
                      name: 'Linked datapoints',
                      type: 'area',
                      color: null,
                      method: 'mean',
                      grouped: false,
                    },
                    {
                      id: 'linkDatapoints',
                      name: 'Linked extra datapoints',
                    },
                  ],
                  defaultStartTime: {
                    preset: 'linkedWidget',
                  },
                  calculationMethod: 'mean',
                  defaultGranularity: 'linkedData',
                  defaultGranularityValue: 1,
                  defaultCalculationMethod: 'max',
                },
              ],
            },
            {
              h: 100,
              w: 3,
              id: 'e2a62414-4c45-49da-a0fa-b37424d87ac0',
              type: 'Section',
              elements: [
                {
                  h: 100,
                  w: 12,
                  icon: 'fas gauge-simple-high',
                  type: 'Gauge',
                  title: 'Gauge',
                  colors: [
                    [0, '#44bc66'],
                    [0.33, '#fed33a'],
                    [0.66, '#f88c1d'],
                    [1, '#f14e3e'],
                  ],
                  method: 'sum',
                  dataType: 'assets',
                  timeRange: {
                    preset: 'linkedWidget',
                  },
                  datapoints: [],
                  customLogic: '',
                  maxComparators: [],
                  minComparators: [],
                  comparisonPeriod: {
                    unit: 'day',
                    amount: 1,
                    duration: 1,
                    durationUnit: 'day',
                  },
                  datapointsLinked: [
                    {
                      id: 'pointValue',
                      name: 'Clicked point value',
                    },
                    {
                      id: 'comparators',
                      name: 'Linked comparators',
                    },
                    {
                      id: 'maxComparators',
                      name: 'Linked max comparators',
                    },
                    {
                      id: 'minComparators',
                      name: 'Linked min comparators',
                    },
                    {
                      id: 'datapoints',
                      name: 'Linked datapoints',
                    },
                  ],
                  maxComparatorsMethod: 'mean',
                  minComparatorsMethod: 'mean',
                },
              ],
            },
          ],
          dateSelection: false,
        },
      ],
    },
    owner: 117,
    readOnly: true,
    creatorMail: 'tom@canary.expert',
    masterDashboardRights: 2,
    isDataEntryDashboard: false,
    __typename: 'SimpleDashboard',
  },
  {
    id: 'preset-917',
    title: 'Comparison Heatmap',
    layout: {
      new: true,
      sections: [
        {
          h: 400,
          type: 'Section',
          header: {
            hide: true,
            icon: 'fas fa-info',
            title: '1 x 1 grid',
          },
          columns: {
            l: 12,
            m: 6,
            s: 2,
          },
          elements: [
            {
              h: 100,
              w: 12,
              icon: 'fas fa-info',
              type: 'DatapointHeatMap',
              range: [0, 100],
              title: 'Heatmap',
              colors: [
                [0, '#44bc66'],
                [0.33, '#fed33a'],
                [0.66, '#f88c1d'],
                [1, '#f14e3e'],
              ],
              legend: true,
              offset: 0,
              dataType: 'assets',
              datapoints: [],
              difference: false,
              percentage: false,
              showTotals: false,
              comparators: [],
              customLogic: '',
              hideRawData: false,
              graphOpacity: 0.1,
              defaultRawData: false,
              datapointsLinked: [
                {
                  id: 'datapoints',
                  name: 'Linked datapoints',
                },
                {
                  id: 'comparators',
                  name: 'Linked comparators',
                },
                {
                  id: 'maxComparators',
                  name: 'Linked max comparators',
                },
                {
                  id: 'pointValue',
                  name: 'Clicked point value',
                },
              ],
              defaultStartTime: {
                preset: 'linkedWidget',
              },
              defaultGranularity: 'm',
              heatmapGranularityUnit: 'm',
              heatmapGranularityValue: 1,
            },
          ],
          dateSelection: false,
        },
      ],
    },
    owner: 117,
    readOnly: true,
    creatorMail: 'tom@canary.expert',
    masterDashboardRights: 2,
    isDataEntryDashboard: false,
    __typename: 'SimpleDashboard',
  },
]
