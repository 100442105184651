import { useMemo } from 'react'

import dayjs from 'dayjs'

export const useHeatMapStartEnd = ({ startTime, endTime, xAxisUnit, yAxisUnit }) => {
  const start = useMemo(() => dayjs(startTime).startOf(xAxisUnit).startOf(yAxisUnit), [
    startTime,
    yAxisUnit,
    xAxisUnit,
  ])
  const end = useMemo(
    () => (endTime ? dayjs(endTime).startOf(yAxisUnit) : dayjs().startOf(yAxisUnit)),
    [endTime, yAxisUnit]
  )
  return { start, end }
}

export const useHeatMapDatapointsPayload = ({
  datapoints,
  conversionUnit,
  granularity,
  endTime,
  start,
  end,
  calculationMethod,
  yAxisUnit,
}) => {
  return useMemo(() => {
    return datapoints.map(({ id }) => {
      return {
        id,
        returnUnitId: conversionUnit,
        granularity,
        startTime: start.valueOf(),
        endTime: endTime ? end.subtract(1, yAxisUnit).valueOf() : end.valueOf(),
        calculationMethod,
      }
    })
  }, [datapoints, granularity, start, end, conversionUnit, calculationMethod, yAxisUnit, endTime])
}

const getYAxisMax = (unit, value, defaultValue, granularity) => {
  if (!unit) {
    return defaultValue
  }
  const max = {
    MS: 12,
    w: granularity?.includes('Y') ? 54 : 5,
    d: granularity?.includes('w') ? 7 : 31,
    h: 24,
    m: 60,
  }[unit]
  return Math.ceil(max / (value || 1))
}

export const useHeatMapGranularity = ({
  granularity,
  heatmapGranularityUnit,
  heatmapGranularityValue,
}) => {
  return useMemo(() => {
    if (!granularity) {
      return {}
    }
    if (granularity.includes('Y')) {
      return {
        granularity: heatmapGranularityUnit
          ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
          : '1MS',
        xAxisUnit: 'year',
        yAxisUnit: heatmapGranularityUnit || 'day',
        yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 12, granularity),
        yAxisLabel: 'Months',
      }
    }
    if (granularity.includes('month') || granularity.includes('MS')) {
      return {
        granularity: heatmapGranularityUnit
          ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
          : '1d',
        xAxisUnit: 'month',
        yAxisUnit: heatmapGranularityUnit || 'day',
        yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 31),
        yAxisLabel: heatmapGranularityUnit === 'w' ? 'Weeks' : 'Days',
      }
    }
    if (granularity.includes('m')) {
      return {
        granularity: heatmapGranularityUnit
          ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
          : '1m',
        xAxisUnit: 'hour',
        yAxisUnit: heatmapGranularityUnit || 'minute',
        yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 60),
        yAxisLabel: 'Minutes',
      }
    }
    if (granularity.includes('h')) {
      const count = parseInt(granularity.replace('h', ''))
      return count > 1
        ? {
            granularity: heatmapGranularityUnit
              ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
              : '1h',
            xAxisUnit: 'day',
            yAxisUnit: heatmapGranularityUnit || 'hour',
            yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 24),
            yAxisLabel: { m: 'Minutes', h: 'Hours' }[heatmapGranularityUnit],
          }
        : {
            granularity: heatmapGranularityUnit
              ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
              : '1m',
            xAxisUnit: 'hour',
            yAxisUnit: heatmapGranularityUnit || 'minute',
            yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 60),
            yAxisLabel: { m: 'Minutes', h: 'Hours' }[heatmapGranularityUnit],
          }
    }
    if (granularity.includes('d')) {
      const count = parseInt(granularity.replace('d', ''))
      return count > 1
        ? {
            granularity: heatmapGranularityUnit
              ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
              : '1d',
            xAxisUnit: 'day',
            yAxisUnit: heatmapGranularityUnit || 'h',
            yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 31),
            yAxisLabel: { m: 'Minutes', h: 'Hours' }[heatmapGranularityUnit],
          }
        : {
            granularity: heatmapGranularityUnit
              ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
              : '1h',
            xAxisUnit: 'day',
            yAxisUnit: heatmapGranularityUnit || 'hour',
            yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 24),
            yAxisLabel: { m: 'Minutes', h: 'Hours' }[heatmapGranularityUnit],
          }
    }
    if (granularity.includes('w')) {
      return {
        granularity: heatmapGranularityUnit
          ? `${heatmapGranularityValue || 1}${heatmapGranularityUnit}`
          : '1d',
        xAxisUnit: 'week',
        yAxisUnit: heatmapGranularityUnit || 'day',
        yAxisMax: getYAxisMax(heatmapGranularityUnit, heatmapGranularityValue, 7, granularity),
        yAxisLabel: { d: 'Days', m: 'Minutes', h: 'Hours' }[heatmapGranularityUnit],
      }
    }
    return {}
  }, [granularity, heatmapGranularityUnit, heatmapGranularityValue])
}

export const useHeatMapComparatorsPayload = ({
  datapoints,
  conversionUnit,
  granularity,
  comparisonPeriod,
  start,
  end,
  yAxisUnit,
  calculationMethod,
}) => {
  return useMemo(() => {
    return datapoints
      ? datapoints.map(({ id }) => {
          return {
            id,
            returnUnitId: conversionUnit,
            granularity,
            startTime: comparisonPeriod
              ? start.subtract(comparisonPeriod.amount, comparisonPeriod.unit).valueOf()
              : start.valueOf(),
            endTime: comparisonPeriod
              ? end
                  .subtract(1, yAxisUnit)
                  .subtract(comparisonPeriod.amount, comparisonPeriod.unit)
                  .valueOf()
              : end.subtract(1, yAxisUnit).valueOf(),
            calculationMethod: calculationMethod,
          }
        })
      : []
  }, [
    datapoints,
    granularity,
    start,
    end,
    comparisonPeriod,
    conversionUnit,
    calculationMethod,
    yAxisUnit,
  ])
}
