import React, { useMemo } from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useEntityOptions } from 'services/entities'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import EntityValueTypePickerStyled from './styled'

const EntityValueTypePicker = ({
  value,
  onChange,
  subPath,
  dataKey = 'entities',
  types = [{ value: 'name', label: { formatted: 'widget.name' }, valueType: 'dataKey' }],
  tagsOnly,
}) => {
  const {
    state: { elementInEditMode = { preview: {}, matrixDetailGraph: {} } },
  } = useDashboard()

  const { data } = useEntityOptions(
    `${get(elementInEditMode.preview, `${subPath ? `${subPath}.` : ''}${dataKey}[0].id`)}`
  )

  const availableOptions = useMemo(() => {
    if (data) {
      return [
        ...types,
        ...Object.entries(data).reduce((options, [key, value]) => {
          if (!tagsOnly) {
            if (value?.__typename === 'AviaryEntity') {
              options.push({
                value: `${key}.name`,
                label: { text: key },
                valueType: 'dataKey',
              })
            } else if (['type', 'remark', 'varia', 'markers', 'tags'].includes(key)) {
              options.push({
                value: key,
                label: { text: key },
                valueType: 'dataKey',
              })
              if (key === 'markers') {
                value.forEach((v) => {
                  options.push({
                    value: `${key}.${v.name}`,
                    label: { text: v.name },
                    valueType: 'dataKey',
                  })
                })
              }
            }
          }
          if (key === 'tags') {
            value.forEach((v) => {
              options.push({
                value: `${key}.${v.name}`,
                label: { text: v.name },
                valueType: 'dataKey',
              })
            })
          }
          return options
        }, []),
      ]
    }
    return types
  }, [data, types, tagsOnly])

  const handleChange = (payload, _, meta) => {
    if (!tagsOnly) {
      onChange(meta.label?.text, 'title')
    }
    onChange(payload)
  }

  return (
    <EntityValueTypePickerStyled
      value={value}
      className="EntityValueTypePicker"
      input
      search
      options={availableOptions}
      selectionKeys={{ label: 'label', value: 'value' }}
      valueKey="value"
      onChange={handleChange}
    />
  )
}

EntityValueTypePicker.defaultProps = {
  options: [],
}

export default injectIntl(EntityValueTypePicker)
