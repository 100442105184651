import React from 'react'

import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import { useData } from 'components/Form/components/ValuePicker/hooks'

import OptionPickerStyled, { OptionPickerLoader } from './styled'

const OptionPicker = ({
  value,
  options = [],
  query = {
    name: 'DEFAULT_QUERY',
    variables: {},
  },
  selectionKeys = { value: 'value', label: 'label' },
  valueKeys,
  valueKey,
  isMultiSelect,
  returnAsArray = false,
  variant,
  onChange,
  onHover,
  className,
  loading,
  canReselect,
  renderFunction,
  intl,
}) => {
  const { data } = useData(query, options)

  const getValueObject = (value) =>
    valueKeys.reduce(
      (valueObject, key) => ({
        ...valueObject,
        [key]: value[key],
      }),
      {}
    )

  const getSingleValue = (item) => {
    if (valueKeys) {
      return getValueObject(item)
    }
    return selectionKeys && selectionKeys.value ? item[selectionKeys.value] : item
  }

  const getMultiValue = (item) => {
    const selectionKey = selectionKeys ? selectionKeys.value || selectionKeys.id : 'value'
    const currentValue = value || []
    const isSelected =
      !canReselect &&
      currentValue.some((i) => (valueKey ? i : get(i, selectionKey)) === get(item, selectionKey))
    return isSelected
      ? currentValue.filter(
          (i) => (valueKey ? i : get(i, selectionKey)) !== get(item, selectionKey)
        )
      : currentValue.concat(
          valueKeys ? getValueObject(item) : valueKey ? get(item, valueKey) : item
        )
  }

  const onSelect = (e, value) => {
    e.stopPropagation()

    onChange(
      isMultiSelect
        ? getMultiValue(value)
        : returnAsArray
        ? [getSingleValue(value)]
        : getSingleValue(value),
      null,
      value
    )
  }

  return loading ? (
    <OptionPickerLoader
      loading
      spinner={{ type: 'lineScale', size: 10 }}
      icon="fas fa-database"
      label={intl.formatMessage({ id: 'widget.noData' })}
    />
  ) : (
    <OptionPickerStyled
      className={`OptionPicker ${variant} ${className || ''}`}
      variant={variant || 'OptionPicker'}
      options={data}
      value={value}
      onSelect={onSelect}
      selectionKeys={selectionKeys}
      isMultiSelect={isMultiSelect}
      onHover={onHover}
      canReselect={canReselect}
      renderFunction={renderFunction}
    />
  )
}

export default injectIntl(OptionPicker)
