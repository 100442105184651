import React from 'react'

import { useDashboard } from 'components/Dashboard/DashboardProvider'
import { calculationMethods } from 'components/Dashboard/Widget/config/selections'
import FormField from 'components/Global/FormField/index'

import { getGranularityForTimeRange } from '../../../Dashboard/components/TimePicker/functions'

const getDatapoints = (value, datapointsDataKey, isMainDatapointRef) =>
  value?.[isMainDatapointRef ? 'datapoints' : datapointsDataKey] || []

const calculationMethodsFilter = (
  calculationMethods,
  containsNonDifferenceDatapoint,
  method,
  granularity,
  addRangeMethod
) => {
  const check = (value) => {
    if (!addRangeMethod && value === 'range') {
      return false
    }
    if (granularity === 'm' && value === 'range') {
      return false
    }
    if (value === 'amount') {
      return false
    }
    if (!containsNonDifferenceDatapoint && value !== 'mean') {
      return false
    }
    if (method && value === 'range' && method !== 'range') {
      return false
    }
    return true
  }
  return calculationMethods.filter(({ value }) => check(value))
}

const CalculationMethod = ({
  value,
  datapointsDataKey = 'datapoints',
  parentDataKey,
  onChange,
  granularity,
  hideGranularity,
  hideLabel,
  label,
  dataCy = 'CalculationMethod',
  component = 'OptionPicker',
  isMainDatapointRef,
  difference,
  addRangeMethod,
}) => {
  const {
    state: { advanced, elementInEditMode = { preview: {}, selectedDatapointMap: {} } },
  } = useDashboard()

  const { defaultGranularityValue, defaultGranularity } = getGranularityForTimeRange(
    value?.defaultStartTime,
    value?.type === 'DatapointHeatMap'
  )

  const containsNonDifferenceDatapoint =
    difference === false ||
    value?.difference === false ||
    getDatapoints(elementInEditMode.preview, datapointsDataKey, isMainDatapointRef).some(
      ({ difference }) => difference === false
    )

  const method =
    parentDataKey &&
    getDatapoints(elementInEditMode.preview, parentDataKey, isMainDatapointRef)?.[
      elementInEditMode.selectedDatapointMap[parentDataKey]
    ]?.defaultCalculationMethod

  const calculationGranularity = granularity || elementInEditMode.preview.defaultGranularity

  return advanced ? (
    <FormField
      componentName={component}
      label={!hideLabel && { formatted: label || 'widget.calculationMethod' }}
      componentProps={{
        selectionKeys: { label: 'label', value: 'value' },
        options: [
          ...calculationMethodsFilter(
            calculationMethods,
            containsNonDifferenceDatapoint,
            method,
            calculationGranularity,
            addRangeMethod
          ),
          ...(defaultGranularity === 'm' && defaultGranularityValue === 1
            ? [{ value: 'raw', label: { formatted: 'widget.rawData' } }]
            : []),
          ...(hideGranularity ? [{ value: 'last', label: { formatted: 'widget.lastValue' } }] : []),
        ],
        search: false,
      }}
      value={
        value?.[
          datapointsDataKey === 'datapoints'
            ? 'defaultCalculationMethod'
            : `${datapointsDataKey}CalculationMethod`
        ]
      }
      onChange={({ value }) =>
        onChange(
          value,
          datapointsDataKey === 'datapoints'
            ? 'defaultCalculationMethod'
            : `${datapointsDataKey}CalculationMethod`
        )
      }
      dataCy={dataCy}
    />
  ) : null
}

export default CalculationMethod
