import React from 'react'

import FlexContainerStyled from './FlexContainerStyled'

const FlexContainer = ({ styleProps, children, className }) => (
  <FlexContainerStyled className={`FlexContainer ${className}`} {...styleProps}>
    {children}
  </FlexContainerStyled>
)

FlexContainer.propTypes = {}

FlexContainer.defaultProps = {}

export default FlexContainer
