import { useEffect, useState } from 'react'

import { client } from 'services/apollo'
import { THEME_QUERY } from 'services/theme'

export const useTheme = (forceLogoutUser) => {
  const storedTheme = localStorage.getItem('theme')
  const parsedTheme = storedTheme ? JSON.parse(storedTheme) : null
  const [customTheme, setCustomTheme] = useState(parsedTheme)
  useEffect(() => {
    if (!storedTheme || window.location.pathname === '/user/login') {
      client
        .query({
          query: THEME_QUERY,
          variables: { domain: window.location.hostname },
        })
        .then((result) => {
          if (result.data.theme?.theme) {
            localStorage.setItem('theme', JSON.stringify(result.data.theme.theme))
            setCustomTheme(result.data.theme.theme)
          }
        })
        .catch((err) => {
          console.error(err)
          forceLogoutUser()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return customTheme
}
