import React, { useCallback, useMemo } from 'react'

import dayjs from 'dayjs'
import { range } from 'lodash'
import { injectIntl } from 'react-intl'

import DateField from '../..'
import { useAllLockedReportinYears } from './hook'

const disableAllTime = {
  disabledHours: () => range(0, 24),
  disabledMinutes: () => range(0, 60),
  disabledSeconds: () => range(0, 60),
}

const ReportingYearDatePickerField = ({
  organisationId,
  disabledDate,
  max,
  min,
  intl,
  ...props
}) => {
  const { data, loading, error } = useAllLockedReportinYears()

  const isDateDisabled = useCallback(
    (date) => {
      if (disabledDate && disabledDate(date)) {
        return true
      }

      if (!date || !data) return true

      if (max && date > max) {
        return true
      }

      if (min && date < min) {
        return true
      }

      return data?.some(({ start, end }) => start <= date && date <= end)
    },
    [data, disabledDate, min, max]
  )

  const showNow = useMemo(() => !isDateDisabled(dayjs()), [isDateDisabled])

  const disabledTime = useCallback(
    (date) => {
      if (!date) {
        return disableAllTime
      }

      const disable = isDateDisabled(date)

      if (disable) {
        return disableAllTime
      }
    },
    [isDateDisabled]
  )

  return (
    <DateField
      disabledDate={isDateDisabled}
      disabled={loading || error}
      disabledTime={disabledTime}
      showNow={showNow}
      infoTooltip
      infoIcon
      infoText={intl.formatMessage({ id: 'reportingYear.datepicker.info' })}
      {...props}
    />
  )
}

export default injectIntl(ReportingYearDatePickerField)
