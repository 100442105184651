import React from 'react'

import MenuListItem from './MenuListItem'
import MenuListStyled from './styled'

const MenuList = ({
  value,
  show,
  selectedKeys,
  level,
  variant,
  className,
  screenSize,
  onItemClick,
  localisedLabels,
  defaultOpen = false,
  tooltip = false,
}) => {
  const sortByOrder = (a, b) => {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
    return 0
  }

  const menu = [...value]
  menu.sort(sortByOrder)
  return (
    <MenuListStyled className={`MenuList ${className || ''}`} level={level} variant={variant}>
      {menu.map((menuItem, index) => (
        <MenuListItem
          key={menuItem.id || `item-${index}`}
          menuItem={menuItem}
          selectedKeys={selectedKeys}
          level={level}
          variant={variant}
          screenSize={screenSize}
          isOpen={level === 0 || show}
          onItemClick={onItemClick}
          defaultOpen={defaultOpen}
          localisedLabels={localisedLabels}
          tooltip={tooltip}
        />
      ))}
    </MenuListStyled>
  )
}

export default MenuList
