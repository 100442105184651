import React from 'react'

import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import FieldLabel from './FieldLabel'
import { FieldStyled, Value } from './styled'

const Field = ({
  children,
  label,
  infoTooltip = false,
  infoIcon = false,
  infoText,
  warningText,
  className,
  readOnly,
  onClick,
  required,
  fullWidth,
  ...props
}) => {
  const formContext = useFormContext()

  const isEditMode = (formContext?.isEditMode ?? true) && !readOnly
  return (
    <FieldStyled
      {...props}
      fullWidth={fullWidth}
      className={`Field ${className} ${fullWidth && 'full-width'}`}
      onClick={onClick}
    >
      {label && (
        <FieldLabel
          isEditMode={isEditMode}
          required={required}
          infoText={infoText}
          infoIcon={infoIcon}
          tooltip={infoTooltip}
          warningText={warningText}
        >
          {typeof label === 'string' ? <FormattedMessage id={label} /> : label}
        </FieldLabel>
      )}

      <Value className="Value">
        {typeof children === 'function' ? children({ ...formContext, isEditMode }) : children}
      </Value>
    </FieldStyled>
  )
}

Field.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  readfullWidthOnly: PropTypes.bool,
}

Field.defaultProps = {
  readOnly: false,
  fullWidth: false,
}

export default Field
