import uniqid from 'uniqid'

import { useNotification } from 'components/Layout/Notifications/NotificationProvider'

const generateActionPayload = (payload, type) => {
  if (typeof payload === 'string') {
    return {
      value: {
        type,
        text: payload,
      },
    }
  }
  return {
    value: {
      ...payload,
      duration: payload.duration ?? (payload.buttons && 0),
      type,
      id: payload.id || uniqid(),
    },
  }
}

export const useMessages = () => {
  const { action } = useNotification()

  return {
    info: (payload) =>
      action({
        type: 'SET_MESSAGE',
        payload: generateActionPayload(payload, 'info'),
      }),
    error: (payload) =>
      action({
        type: 'SET_MESSAGE',
        payload: generateActionPayload(payload, 'error'),
      }),
    warning: (payload) =>
      action({
        type: 'SET_MESSAGE',
        payload: generateActionPayload(payload, 'warning'),
      }),
    success: (payload) =>
      action({
        type: 'SET_MESSAGE',
        payload: generateActionPayload(payload, 'success'),
      }),
    delete: (payload) =>
      action({
        type: 'DELETE_NOTIFICATION',
        payload: {
          value: payload.id,
        },
      }),
  }
}

export const useNotifications = () => {
  const { action } = useNotification()

  return {
    info: (payload) =>
      action({
        type: 'ADD_NOTIFICATION',
        payload: generateActionPayload(payload, 'info'),
      }),
    error: (payload) =>
      action({
        type: 'ADD_NOTIFICATION',
        payload: generateActionPayload(payload, 'error'),
      }),
    warning: (payload) =>
      action({
        type: 'ADD_NOTIFICATION',
        payload: generateActionPayload(payload, 'warning'),
      }),
    success: (payload) =>
      action({
        type: 'ADD_NOTIFICATION',
        payload: generateActionPayload(payload, 'success'),
      }),
  }
}
