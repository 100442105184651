import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

const QUERY_ENERGY_CLASSIFICATIONS = gql`
  query energyClassifications($companyId: Float!) {
    energyClassifications(companyId: $companyId) {
      id
      name
    }
  }
`

export const useEnergyClassifications = (id) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ENERGY_CLASSIFICATIONS, {
    variables: { companyId: id || customer?.id },
    skip: !id && !customer?.id,
  })
  return { data: data?.energyClassifications, ...rest }
}

const QUERY_ENERGY_CLASSIFICATIONS_DETAILS = gql`
  query energyClassificationsDetails($companyId: Float!) {
    energyClassifications(companyId: $companyId) {
      id
      name
      aboveTresholdColor
      aboveTresholdLabel
      interval {
        id
        label
      }
      energyClassificationDetails {
        id
        maxValue
        label
        color
      }
      unit {
        id
        name
        difference
      }
    }
  }
`

export const useEnergyClassificationsDetails = (id) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ENERGY_CLASSIFICATIONS_DETAILS, {
    variables: { companyId: id || customer?.id },
    skip: !id && !customer?.id,
    fetchPolicy: 'no-cache',
  })
  return { data: data?.energyClassifications, ...rest }
}
