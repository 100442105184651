import React, { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { useDashboard } from 'components/Dashboard/DashboardProvider'

import { DATAPOINT_TAGS_QUERY } from '../../../../services/datapoints'
import { generateTagColor } from '../../../Dashboard/components/Tags/functions'
import NumberInput from '../../../Form/components/NumberInput'
import { DatapointDetails } from '../Datapoints'
import { useDatapointHover } from '../Datapoints/functions'
import DatapointSelectorButtons from '../DatapointSelector/DatapointSelectorButtons'
import EntityFilter from '../DatapointSelector/EntityFilter'
import { useDatapointsFilter } from '../DatapointSelector/hooks'
import DatapointSelectorStyled from '../DatapointSelector/styled'
import { useEntitiesFilter } from '../EntitySelector/hooks'
import CheckboxSelector from '../Selector/styled.CheckboxSelector'
import { FormFieldLabel } from '../styled'
import { useIsNumberValueTag } from './hooks'
import { AugmentedEntity, Tag } from './styled'

/*export const isNumberValue = (value) =>
  /!*value !== '1' && value !== '0' && *!/ value !== null && !isNaN(value * 1)*/

const valueTypes = [
  {
    value: 'value',
    label: {
      formatted: 'widget.fixedValue',
    },
  },
  {
    value: 'selectedDatapointTag',
    label: {
      formatted: 'widget.selectedDatapointTag',
    },
  },
  {
    value: 'datapointTag',
    label: {
      formatted: 'widget.otherDatapointTag',
    },
  },
  {
    value: 'entityTag',
    label: {
      formatted: 'widget.entityTag',
    },
  },
]

const FixedValueSelector = ({
  value,
  subPath,
  onSave,
  onCancel,
  onValueUpdate,
  intl,
  dataCy = 'FixedValueSelector',
  dataKey,
  datapointsDataKey = 'datapoints',
  tags,
}) => {
  const {
    state: { elementInEditMode, isDataEntryDashboard },
    action,
  } = useDashboard()

  const selectedValues =
    value || (elementInEditMode?.preview && get(elementInEditMode?.preview, `${dataKey}`)) || []

  const selectedDatapoints =
    value ||
    (elementInEditMode?.preview && get(elementInEditMode?.preview, `${datapointsDataKey}`)) ||
    []

  const selected = get(elementInEditMode?.preview, subPath)

  const [valueType, setValueType] = useState(
    typeof selected.value === 'number' || typeof selected.value === 'string'
      ? 'value'
      : selected.value.entityId
      ? 'entityTag'
      : 'datapointTag'
  )

  const { data: datapointTags } = useQuery(DATAPOINT_TAGS_QUERY, {
    variables: {
      datapoints: selectedDatapoints.map(({ id }) => ({ id })),
      skip: valueType !== 'selectedDatapointTag' || !selectedDatapoints.length,
    },
  })

  const isNumberValueTag = useIsNumberValueTag()
  const datapointFilter = useCallback(
    ({ tags }) => {
      return Object.entries(tags).some(([tagName, tagValue]) =>
        isNumberValueTag({ tagName, tagValue })
      )
    },
    [isNumberValueTag]
  )

  const datapointsFilter = useDatapointsFilter({
    datapointAssetsFilter: datapointFilter,
    datapointNonAssetsFilter: datapointFilter,
    tags,
    preview: elementInEditMode?.preview,
    isDataEntryDashboard,
  })

  const entitiesFilter = useEntitiesFilter({
    entitiesFilter: datapointFilter,
    tags,
  })

  const filter =
    valueType === 'datapointTag'
      ? datapointsFilter
      : valueType === 'entityTag'
      ? entitiesFilter
      : null

  const optionRenderer = ({ label, option }) => {
    const idKey = valueType === 'entityTag' ? 'entityId' : 'datapointId'
    const selectedEntity = selectedValues.find(({ value }) => value[idKey] === option.id)
    const tags =
      option.tags &&
      Object.entries(option.tags).reduce((tags, [tagName, tagValue]) => {
        if (isNumberValueTag({ tagValue, tagName })) {
          const color = generateTagColor(tagName)
          tags.push(
            <Tag
              color={color}
              key={tagName}
              onClick={() => handleValueUpdate({ [idKey]: option.id, tagName, tagValue, label })}
              className={`Tag ${selectedEntity?.value?.tagName === tagName ? 'selected' : ''}`}
            >
              {tagName} | <span>{tagValue}</span>
            </Tag>
          )
        }
        return tags
      }, [])
    return (
      <AugmentedEntity
        onMouseEnter={(e) => onDatapointHover(e, option)}
        onMouseLeave={(e) => onDatapointHover(e, null)}
        className={`AugmentedEntity ${selectedEntity ? 'selected' : ''}`}
      >
        <span className="AugmentedEntity__label">{label}</span>
        {tags}
      </AugmentedEntity>
    )
  }

  const handleValueUpdate = (payload) => {
    if (action) {
      action({
        type: 'ON_PREVIEW_UPDATE',
        payload: {
          graph: { dataKey: 'value', subPath },
          value: payload,
        },
      })
    }
    onValueUpdate && onValueUpdate(payload)
  }
  const { hoveredElement, detail, onDatapointHover } = useDatapointHover()

  return (
    <DatapointSelectorStyled className="DatapointSelector" height="50vh">
      <DatapointDetails hoveredElement={hoveredElement} detail={detail} />
      <div className="DatapointSelector__switch">
        <CheckboxSelector
          options={valueTypes}
          value={valueType}
          onSelect={(e, { value }) => setValueType(value)}
          dataCy={`${dataCy}-type-selector`}
        />
      </div>
      {filter && (
        <EntityFilter
          {...filter}
          optionRenderer={optionRenderer}
          intl={intl}
          entityType={valueType === 'entityTag' ? 'entities' : 'datapoints'}
          selectedDatapoints={selectedValues}
        />
      )}
      {valueType === 'selectedDatapointTag' && datapointTags?.datapoints && (
        <div className="DatapointSelector__editor">
          <div>
            <FormFieldLabel>{intl.formatMessage({ id: 'widget.datapoints' })}</FormFieldLabel>
            <div className="DatapointSelector__scroll-container">
              {datapointTags.datapoints.reduce((datapointTags, dp) => {
                if (datapointFilter(dp)) {
                  const datapoint = selectedDatapoints.find(({ id }) => id === dp.id)
                  datapointTags.push(
                    optionRenderer({
                      label: datapoint.name,
                      option: { ...datapoint, tags: dp.tags },
                    })
                  )
                }
                return datapointTags
              }, [])}
            </div>
          </div>
        </div>
      )}
      {valueType === 'value' && (
        <div className="DatapointSelector__editor">
          <div>
            <FormFieldLabel>{intl.formatMessage({ id: 'widget.fixedValue' })}</FormFieldLabel>
            <NumberInput
              value={typeof selected.value === 'number' ? selected.value : 0}
              onChange={(value) => handleValueUpdate(value)}
            />
          </div>
        </div>
      )}
      <DatapointSelectorButtons onSave={onSave} onCancel={onCancel} intl={intl} />
    </DatapointSelectorStyled>
  )
}

export default FixedValueSelector
