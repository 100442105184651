import React, { useMemo } from 'react'

import { injectIntl } from 'react-intl'

import treeifyHiearchyList from 'util/treeifyHiearchyList'
import { useEntitiesForOrganisation } from 'services/organisations'
import { useCurrentCustomer } from 'services/store'

import { SearchableTree } from 'components/Global/Tree'

import EntityTreeStyled, { Country, CountryGroup, Customer, EntityTreeLoader } from './styled'

const StationaryCombustionTree = ({ onChange, selectedOrganisation, onTreeOpenClick, intl }) => {
  const customer = useCurrentCustomer()
  const { data, loading: entitiesLoading } = useEntitiesForOrganisation(
    null,
    selectedOrganisation && parseInt(selectedOrganisation)
  )

  const options = useMemo(
    () =>
      data
        ? treeifyHiearchyList({
            list: data,
            idKey: 'id',
            depthType: 'equip',
            disabledTypes: [],
            childrenKey: 'children',
            parentKey: null,
            transformKeys: false,
          })[0]?.children || []
        : [],
    [data]
  )

  const optionsByCountry = options.reduce((countries, site) => {
    if (countries[site.countryInfo?.countryName]) {
      countries[site.countryInfo?.countryName].sites.push(site)
    } else {
      countries[site.countryInfo?.countryName || '- All -'] = {
        countryImg: site.countryInfo?.countryImgName || 'all',
        sites: [site],
      }
    }
    return countries
  }, {})

  if (entitiesLoading || !options) {
    return (
      <EntityTreeLoader
        loading
        spinner={entitiesLoading && { type: 'lineScale', size: 10 }}
        icon="fas fa-database"
        label={intl.formatMessage({ id: 'widget.noData' })}
      />
    )
  }

  return (
    <EntityTreeStyled className="EntityTree">
      <Customer className="Customer" onClick={() => onChange(customer)}>
        {customer.name}
        {Object.entries(optionsByCountry).map(([country, { sites, countryImg }]) => (
          <CountryGroup>
            <Country
              onClick={(e) => {
                e.stopPropagation()
                return onChange({ sites, name: country, id: `country${country}` })
              }}
              treeItem={{}}
              isOpen
            >
              <img
                src={`/resources/images/countryflags/${countryImg?.toLowerCase()}.png`}
                alt={countryImg}
              />
              {country}
            </Country>
            <SearchableTree
              value={sites}
              level={0}
              elementHeight={30}
              show
              onButtonClick={onChange}
              onTreeOpenClick={onTreeOpenClick}
              trackOpenState
            />
          </CountryGroup>
        ))}
      </Customer>
    </EntityTreeStyled>
  )
}

export default injectIntl(StationaryCombustionTree)
