import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { useAllFixedFileRecords } from 'services/fixedFiles'
import { useCurrentCustomer } from 'services/store'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import TagsStyled from 'components/Dashboard/components/Tags/styled'
import Skeleton from 'components/Form/components/Skeleton/styled'
import ValuePicker from 'components/Form/components/ValuePicker'
import TagEditor from 'components/Form/fields/TagField/TagEditor'
import Button from 'components/Global/Button'

import { TagMarkerSelectFieldStyled } from './styled'

const TagMarkerSelectField = ({
  value,
  name,
  filter,
  dependencies,
  featureType,
  onChange,
  uncontrolled,
  readOnly,
  disableList = [],
  ...fieldProps
}) => {
  const customer = useCurrentCustomer()
  const { data } = useAllFixedFileRecords(6, false, customer?.id, readOnly)

  const options = useMemo(
    () =>
      !readOnly && data
        ? data.records.reduce((options, { fields }) => {
            if (!featureType || fields['Feature Type'] === featureType) {
              options.push({
                ...fields,
                id: fields.id,
                name: fields.Name,
                type: featureType,
                color: generateTagColor(fields.Name),
              })
            }
            return options
          }, [])
        : [],
    [data, featureType, readOnly]
  )
  return (
    <TagMarkerSelectFieldStyled {...fieldProps}>
      {({ control, watch, loading, getValues, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }
        const values = getValues()
        if (isEditMode) {
          return (
            <Controller
              control={control}
              name={name}
              render={({ onChange: fromFieldOnChange, value: controllerValue }) => {
                const handleChange = (value) => {
                  const update = value.reduce((update, { name, value }) => {
                    update[name] = featureType === 'marker' ? '1' : value
                    return update
                  }, {})

                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }
                const renderValue =
                  value === undefined && name === undefined ? value : controllerValue
                const list = renderValue
                  ? Object.entries(renderValue).map(([key, value]) => ({
                      name: key,
                      value,
                      id: key,
                      color: generateTagColor(key),
                    }))
                  : []

                const handleRemoveItem = ({ id }) => {
                  const { [id]: removed, ...update } = controllerValue
                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }

                return (
                  <TagsStyled className="Tags">
                    <ValuePicker
                      value={list}
                      options={options}
                      search
                      input
                      variant="Tags"
                      selectionKeys={{ label: 'name', value: 'id' }}
                      isMultiSelect
                      onChange={handleChange}
                      width={380}
                      valueElement={
                        <div className="ValuePickerValue">
                          {list.map(({ id, name: tagName, value }) => (
                            <Button
                              key={id}
                              label={tagName}
                              variant="tag"
                              color={generateTagColor(tagName)}
                              icon="fas fa-xmark"
                              onIconClick={() => handleRemoveItem({ id, name: tagName })}
                              disabled={disableList.includes(tagName)}
                            >
                              {featureType === 'marker' ? null : (
                                <TagEditor
                                  name={`${name}.${tagName}`}
                                  tagName={tagName}
                                  value={value}
                                  values={values}
                                  isEditMode
                                  watch={watch}
                                  valueKey={false}
                                />
                              )}
                            </Button>
                          ))}
                        </div>
                      }
                    />
                  </TagsStyled>
                )
              }}
            />
          )
        }
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value
        return (
          <TagsStyled className="Tags">
            <div className="ValuePickerValue">
              {((fieldValue && Object.entries(fieldValue)) || []).map(([key, value]) => (
                <Button
                  key={key}
                  label={`${key}${value !== '1' ? ` | ${value}` : ''}`}
                  variant="tag"
                  color={generateTagColor(key)}
                />
              ))}
            </div>
          </TagsStyled>
        )
      }}
    </TagMarkerSelectFieldStyled>
  )
}

TagMarkerSelectField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default TagMarkerSelectField
