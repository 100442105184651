import { COLORS } from 'util/constants'

import {
  getUniqueTags,
  RESERVED_TAGS,
  tagCompare,
} from 'components/SettingsComponents/PermissionsModal/helpers'

const isUnique = (mainArray, [label, value]) => {
  return !mainArray.some(([itemLabel, itemValue]) => label === itemLabel && value === itemValue)
}

const addUniqueTags = (newTags, currentTags) => {
  return Object.entries(newTags).reduce((currentTags, item) => {
    if (isUnique(currentTags, item) && !RESERVED_TAGS.includes(item[0])) {
      currentTags.push(item)
    }
    return currentTags
  }, currentTags)
}

export const getDatapointTags = (datapoints = [], currentTags) => {
  return datapoints.reduce(
    (currentTags, dp) => {
      if (dp.datapointTags) {
        addUniqueTags(dp.datapointTags, currentTags)
      }
      if (dp.equipTags) {
        addUniqueTags(dp.equipTags, currentTags)
      }
      if (dp.tags) {
        addUniqueTags(dp.tags, currentTags)
      }
      return currentTags
    },
    [...currentTags]
  )
}

export const getNodeTags = (
  node,
  fromEntityTypes = ['customer', 'site', 'building', 'floor', 'room', 'equip', 'point'],
  numberValuesOnly
) => {
  return node?.tags ? getUniqueTags(node, fromEntityTypes, numberValuesOnly).sort(tagCompare) : []
}

export const generateTagColor = (key = '', index) => {
  let charSum = 0
  for (let i = 0; i < key.length; i++) {
    charSum += key.charCodeAt(i)
  }
  const colorIndex = Math.round(8 * Math.sin(charSum) + 8)
  return COLORS[index || colorIndex]
}
