const variables = require('theme/variables')

export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.heatmap',
    category: 'widget.charts',
    keywords: ['chart', 'trend', 'heatmap'],
    img: '/resources/images/widgets/heatmap.png',
    info: {
      'fr-FR':
        '<h2>Heatmap</h2>\n' +
        '<p>Le widget Heatmap est couramment utilisé pour visualiser les points chauds dans les ensembles de données et pour afficher des modèles ou des corrélations. En raison de leur nature compacte, ils sont souvent utilisés avec de grands ensembles de données.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        "<li><strong>Activer l'exportation</strong> Afficher ou non le menu d'exportation</li>\n" +
        '<li><strong>Afficher la légende</strong> Afficher ou non une légende sous le graphique</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Point de données</strong> Sélectionnez un point de données de widget</li>\n' +
        '<li><strong>Point de comparaison</strong> Sélectionnez un point de données de comparaison de widget</li>\n' +
        '<li><strong>Valeur Min/Max</strong> Définir la valeur minimale et maximale</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<ul>\n' +
        '<li><strong>Données brutes disponibles</strong> Désactiver ou non le basculement des données brutes</li>\n' +
        '<li><strong>Activé par défaut des données brutes</strong> Afficher les données brutes par défaut ou non</li>\n' +
        '<li><strong>Granularité</strong> Sélectionnez la granularité des valeurs des points de données</li>\n' +
        '</ul>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '<li><strong>Couleurs</strong> Définir les couleurs pour la transition entre les valeurs</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Valeurs fixes</strong> Créez une valeur fixe à afficher sur le widget, avec unité et couleur</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Heatmap</h2>\n' +
        '<p>The Heatmap widget is commonly used to visualize hot spots within data sets, and to show patterns or correlations. Due to their compact nature, they are often used with large sets of data.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Enable Export</strong> Display export menu or not</li>\n' +
        '<li><strong>Show Legend</strong> Display a legend under the chart or not</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint</strong> Select a widget datapoint</li>\n' +
        '<li><strong>Comparison Point</strong> Select a widget comparison datapoint</li>\n' +
        '<li><strong>Min/Max Value</strong> Define the minimum and maximum value</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Raw Data Available</strong> Disable raw data toggle or not</li>\n' +
        '<li><strong>Raw Data Default On</strong> Show raw data by default or not</li>\n' +
        '<li><strong>Granularity</strong> Select the granularity of the datapoint values</li>\n' +
        '</ul>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Colors</strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Fixed Values</strong> Create a fixed value to display on the widget, with unit and color</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Heatmap</h2>\n' +
        '<p>De Heatmap-widget wordt vaak gebruikt om hotspots in datasets te visualiseren en om patronen of correlaties weer te geven. Vanwege hun compacte karakter worden ze vaak gebruikt met grote datasets.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Exporteren inschakelen</strong> Exportmenu weergeven of niet</li>\n' +
        '<li><strong>Show Legend</strong> Geef een legenda onder de grafiek weer of niet</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunt</strong> Selecteer een widget-datapunt</li>\n' +
        '<li><strong>Vergelijkingspunt</strong> Selecteer een widgetvergelijkingsgegevenspunt</li>\n' +
        '<li><strong>Min/Max Waarde</strong> Definieer de minimum- en maximumwaarde</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<ul>\n' +
        '<li><strong>Onbewerkte gegevens beschikbaar</strong> Schakel onbewerkte gegevens uit of niet</li>\n' +
        '<li><strong>Standaard onbewerkte gegevens aan</strong> Standaard onbewerkte gegevens weergeven of niet</li>\n' +
        '<li><strong>Granulariteit</strong> Selecteer de granulariteit van de gegevenspuntwaarden</li>\n' +
        '</ul>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '<li><strong>Kleuren</strong> Definieer kleuren voor de overgang tussen waarden</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Vaste waarden</strong> Maak een vaste waarde om op de widget weer te geven, met eenheid en kleur</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'DatapointHeatMap',
      title: 'widget.heatmap',
      icon: 'fas fa-info',
      color: null,
      showTotals: false,
      hideRawData: false,
      defaultRawData: false,
      legend: true,
      colors: [
        [0, '#44bc66'],
        [0.33, '#fed33a'],
        [0.66, '#f88c1d'],
        [1, '#f14e3e'],
      ],
      defaultStartTime: {
        preset: 'yesterday',
      },
      graphOpacity: 0.1,
      dashboardLink: null,
      datapoints: [],
      datapointsLinked: [],
      comparators: [],
      range: [0, 100],
      offset: 0,
      customLogic: '',
      conversionUnit: null,
      percentage: false,
      difference: false,
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-table-cells',
      heatmap: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct'],
        },
        yAxis: {
          categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          title: null,
          reversed: true,
        },
        colorAxis: {
          min: 0,
          minColor: variables.color.color.aviary.warning,
          maxColor: variables.color.color.aviary.success,
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          symbolWidth: 320,
          symbolHeight: 5,
          margin: 10,
          padding: 5,
          y: 10,
          x: 25,
        },
        tooltip: false,
        credits: {
          enabled: false,
        },
        series: [
          {
            name: 'Serie 1',
            borderWidth: 1,
            data: [
              [0, 0, 10],
              [0, 1, 19],
              [0, 2, 8],
              [0, 3, 24],
              [0, 4, 67],
              [1, 0, 92],
              [1, 1, 58],
              [1, 2, 78],
              [1, 3, 117],
              [1, 4, 48],
              [2, 0, 35],
              [2, 1, 15],
              [2, 2, 123],
              [2, 3, 64],
              [2, 4, 52],
              [3, 0, 72],
              [3, 1, 132],
              [3, 2, 114],
              [3, 3, 19],
              [3, 4, 16],
              [4, 0, 38],
              [4, 1, 5],
              [4, 2, 8],
              [4, 3, 117],
              [4, 4, 115],
              [5, 0, 88],
              [5, 1, 32],
              [5, 2, 12],
              [5, 3, 6],
              [5, 4, 120],
              [6, 0, 13],
              [6, 1, 44],
              [6, 2, 88],
              [6, 3, 98],
              [6, 4, 96],
              [7, 0, 31],
              [7, 1, 1],
              [7, 2, 82],
              [7, 3, 32],
              [7, 4, 30],
              [8, 0, 85],
              [8, 1, 97],
              [8, 2, 123],
              [8, 3, 64],
              [8, 4, 84],
              [9, 0, 47],
              [9, 1, 114],
              [9, 2, 31],
              [9, 3, 48],
              [9, 4, 91],
            ],
          },
        ],
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.enableExport' },
        dataKey: 'enableExport',
        col: 'col2 first',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showLegend' },
        dataKey: 'legend',
        col: 'col2',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoint', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapoints',
          method: 'method',
          fixedUnit: true,
          canReselect: false,
          //linkedMode: true,
        },
      },
      {
        category: 'widget.datapoints',
        componentName: 'Comparator',
        label: { formatted: 'widget.comparisonPoint', tagName: 'h4' },
        componentProps: {
          dataKey: 'comparators',
          disableProperty: true,
          fixedUnit: true,
        },
      },
      {
        category: 'widget.datapoints',
        componentName: 'ComparisonPointConfigurator',
        componentProps: {
          range: true,
        },
      },
      {
        category: 'widget.datapoints',
        componentName: 'HeatMapMinMaxColorRangeSlider',
        label: { formatted: 'widget.colors' },
        dataKey: 'colors',
        componentProps: {
          tickRange: 'instance.range',
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        dataKey: '',
        componentProps: {
          dataKey: 'defaultStartTime',
          datapointsDataKey: 'datapoints',
          hideLabel: true,
          range: true,
          hideGranularity: false,
          heatMapIntervals: true,
        },
        label: { formatted: 'widget.timeRangeDatapoints', tagName: 'h4' },
      },
      {
        category: 'widget.interval',
        componentName: 'TimeOffset',
        dataKey: 'comparisonPeriod',
        componentProps: {
          datapointsDataKey: 'comparators',
        },
      },
      {
        category: 'widget.dashboardLink',
        componentName: 'DashboardLink',
        dataKey: 'dashboardLink',
        componentProps: {
          dataKey: 'dashboardLink',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'UnitPicker',
        label: { formatted: 'widget.unit' },
        dataKey: 'conversionUnit',
        componentProps: {
          dataKey: 'datapoints',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
