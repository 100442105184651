export default {
  'form.submit': 'Opslaan',
  'form.reset': 'Reset',

  'form.field.error.required': 'Dit veld is verplicht',
  'form.tag.error.required': 'Een waarde voor deze tag is verplicht',
  'form.validation.unique': 'Deze waarde is niet uniek',
  'form.validation.containsErrors': 'Los de fouten op om te kunnen opslaan',

  // Specific
  'form.email.alreadyExists': ' is already being used by someone.',
  'form.email.alreadyInvited': ' is alreeds uitgenodigd voor uw organisatie.',

  // Labels
  'form.label.name': 'Naam',
  'form.label.email': 'E-mail',
  'form.label.emails': 'E-mailadres(sen)',
  'form.label.phone': 'Telefoonnummer',
  'form.label.surname': 'Achternaam',
  'form.label.firstname': 'Voornaam',
  'form.label.roles': 'Rollen',
  'form.label.fixedPermissions': 'Vaste permissies',
  'form.label.optional': '(Optioneel)',
  'form.label.street': 'Straat',
  'form.label.streetNumber': 'N°',
  'form.label.postalCode': 'Postcode',
  'form.label.city': 'Gemeente',
  'form.label.country': 'Land',
  'form.label.vatNumber': 'BTW-nummer',
  'form.label.website': 'Website',
  'form.label.address': 'Adres',
  'form.label.administrator': 'Admin',
  'form.label.createdAt': 'Datum aangemaakt',
  'form.label.updatedAt': 'Laastste update',
  'form.label.hasDatapoints': 'Database',
  'form.label.isActive': 'Actief',
  'form.label.deactivated': 'Gedeactiveerd',
  'form.label.isDealer': 'Is dealer',
  'form.label.dealer': 'Dealer',
  'form.label.logo': 'Logo',
  'form.label.noImage': 'Geen foto',
  'form.label.yes': 'Ja',
  'form.label.no': 'Nee',
  'form.label.adminFirstName': 'Voornaam admin',
  'form.label.adminLastName': 'Familienaam admin',
  'form.label.adminMail': 'Mailadres admin',
  'form.label.mobile': 'GSM nummer',
  'form.label.avatar': 'Profielfoto',
  'form.label.customers': 'Klanten',
  'form.label.dataLimit': 'Gegevenslimiet',
  'form.label.smsLimit': 'SMS limiet',
  'form.label.energyClassification': 'Energy Classification',
  'form.label.reportingYear': 'Reporting Year',
  'form.label.legalEntity': 'Legal Entity',
  'form.label.customerCode': 'Customer Code',
  'form.label.internalId': 'Internal Ref.',
  'form.label.customerMail': 'Mailadres Bedrijf',
  'form.label.numericOnly': 'Uitsluitend numerieke tekens',
  'form.label.noZeroesAfterPlus': '[+][landcode][zonenr][ltelefoonnr]',

  // Generic
  'form.input.required': ' is een vereist veld.',
  'form.input.invalid': ' is geen geldige waarde.',
  'form.select.required': 'U moet een waarde selecteren.',
  'form.select.requiredOne': 'U moet minstens één waarde selecteren.',
  'form.password.minimum': 'Minimale wachtwoordlengte: 8 tekens',
  'form.password.maximum': 'Maximale wachtwoordlengte: 50 tekens',
  'form.password.regex': 'Wachtwoord vereist minimaal 1 hoofdletter en 1 cijfer',
  'form.password.match': 'Wachtwoorden komen niet overeen',
  'form.dropHereToUpload': 'Zet uw bestand hier neer om te uploaden',
  'form.uploading': 'Bezig met uploaden ...',
  'form.dropAfileToReplaceImage': 'Sleep een bestand om de afbeelding te vervangen',
  'form.moreInfo': 'Meer info',
  'form.acceptCookies': 'Aanvaard cookies',
  'form.iAgree': 'Ik ga akkoord',

  // Validation
  'form.validation.maxLength': 'De maximale lengte voor dit veld bedraagt ',
  'form.validation.characters': 'tekens.',

  'form.field.education.primary_education': 'PRIMARY EDUCATION',
  'form.field.education.basic_education': 'BASIC EDUCATION',
  'form.field.education.secondary_education': 'SECONDARY EDUCATION',
  'form.field.education.college_graduate': 'COLLEGE GRADUATE',
  'form.field.education.bachelor': 'BACHELOR',
  'form.field.education.master': 'MASTER',
  'form.field.education.doctor': 'DOCTOR',
}
