import React, { useState } from 'react'

import get from 'lodash/get'

import ButtonList from '../ButtonList'
import FormField from '../FormField'
import FieldGroupStyled from './styled'

const FieldGroup = ({
  fields,
  buttons,
  value,
  path,
  rowIndex,
  rowsTotal,
  hideLabels,
  buttonsAlwaysVisible,
  onChange,
  onButtonClick,
  variant,
  className,
}) => {
  const [showButtons, setShowButtons] = useState(false)

  return (
    <FieldGroupStyled
      className={`FieldGroup ${className}`}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
      variant={variant}
    >
      {fields.map(({ label, ...field }, index) => (
        <FormField
          {...field}
          key={field.id || field.name || `field-${index}`}
          value={get(value, field.dataKey)}
          path={path}
          instance={value ? { ...value, rowIndex, rowsTotal } : undefined}
          label={!hideLabels && label}
          variant={variant}
          onChange={onChange}
        />
      ))}
      {buttons && (
        <ButtonList
          buttons={buttons}
          onButtonClick={onButtonClick}
          show={buttonsAlwaysVisible || showButtons}
        />
      )}
    </FieldGroupStyled>
  )
}

export default FieldGroup
