import { transparentize } from 'polished'
import styled from 'styled-components'

import NumberInput from 'components/Form/components/NumberInput'

const NumberInputStyled = styled(NumberInput)`
  .ant-input-number-input {
    &::selection {
      background: ${({ theme }) => transparentize(0.8, theme.color.main)};
    }
  }
`
export default NumberInputStyled
