import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { injectIntl } from 'react-intl'

import { useDashboard } from '../../../Dashboard/DashboardProvider'
import OptionPicker from '../../../Form/components/OptionPicker/OptionPicker'
import { useFilters } from '../../../Form/components/ValuePicker/hooks'
import DashboardPickerStyled from './styled'

const SectionPicker = ({
  value,
  onChange,
  dataCy = 'DashboardPicker',
  selectionKeys = { label: 'title' },
  disabled,
  placeholder = 'Search to Select',
  intl,
}) => {
  const {
    state: { data },
  } = useDashboard()

  const sections = (data?.layout.sections || []).map((section, index) => ({
    url: `#section-${section.id}`,
    id: `section-${section.id}`,
    title: section.header?.title || `${intl.formatMessage({ id: 'widget.section' })} ${index + 1}`,
    icon: section.header?.icon,
    //color: section.header?.iconColor,
    isActive: value.some(({ url }) => url === `#section-${section.id}`),
  }))

  const { onSearch, searchValue, data: filteredOptions } = useFilters(
    sections,
    null,
    {},
    selectionKeys?.label,
    intl
  )
  const onSelectSection = (value, dataKey, raw) => {
    onChange(value, raw)
  }

  return (
    <DashboardPickerStyled className="DashboardPicker">
      <Input
        onChange={(e) => onSearch && onSearch(e)}
        value={searchValue}
        placeholder={placeholder}
        disabled={disabled}
        data-cy={`${dataCy || 'Tooltip'}-search`}
        prefix={<SearchOutlined />}
      />
      <OptionPicker
        options={filteredOptions}
        selectionKeys={{ label: 'title', value: 'id' }}
        variant="Tags"
        onChange={onSelectSection}
        value={value.map((v) => (typeof v === 'string' ? v.replace('#', '') : v))}
        input
        search
      />
    </DashboardPickerStyled>
  )
}

SectionPicker.propTypes = {}

SectionPicker.defaultProps = {
  options: [],
}

export default injectIntl(SectionPicker)
