import { css } from 'styled-components'

export const ColorPicker = css`
  position: relative;
  &:after {
    transition: background 0.2s ease, top 0.2s ease, bottom 0.2s ease, left 0.2s ease,
      right 0.2s ease;
    box-shadow: ${({ theme }) => theme.shadowElevation1(theme.darkMode)};
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: 3px;
    background: ${({ color }) => color};
  }
`
