export const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.connectivity',
    category: 'widget.gaugesIndicators',
    keywords: ['gauge', 'indicator', 'connectivity'],
    img: '/resources/images/widgets/connectivity.png',
    info: {
      'fr-FR':
        '<h2>Connectivité</h2>\n' +
        "<p>Le widget Connectivité fournit à la fois une vue globale et détaillée sur l'état de connexion de certaines entités.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        "<li><strong>Icône </strong>Sélectionnez l'icône à utiliser comme indicateur d'état</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Entités </strong>Sélectionnez les entités dont le statut sera affiché dans le widget</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Connectivity</h2>\n' +
        '<p>The Connectivity widget provides both an overall and a detailed view on the connection status of certain entities.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        '<li><strong>Icon </strong>Select the icon to be used as status indicator</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Entities </strong>Select the entities whose status will be displayed in the widget</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Connectiviteit</h2>\n' +
        '<p>De widget Connectiviteit biedt zowel een algemeen als een gedetailleerd overzicht van de verbindingsstatus van bepaalde entiteiten.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Icoon </strong>Selecteer het pictogram dat als statusindicator moet worden gebruikt</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Entiteiten </strong>Selecteer de entiteiten waarvan de status in de widget wordt weergegeven</li>\n' +
        '</ul>',
    },
    default: {
      type: 'Connectivity',
      title: 'widget.connectivity',
      entities: [],
    },
    preview: {
      icon: 'fas fa-network-wired',
      chart: {
        onlineLabel: [],
        offlineLabel: [],
        percentage: 100,
        suffix: 'online',
        status: 'success',
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.datapoints',
        componentName: 'EntitiesPicker',
        label: { formatted: 'widget.entities' },
        dataKey: 'entities',
        componentProps: {
          type: 'building',
        },
      },
    ],
  },
}

export default config
