import React from 'react'

import FormField from '../index'
import MapZoomConfiguratorStyled from './styled'

const MapZoomConfigurator = ({ value = {}, onChange }) => {
  return (
    <MapZoomConfiguratorStyled className="MapEntitySelector">
      <FormField
        componentName="Switch"
        label={{ formatted: 'widget.autoZoom' }}
        value={!value.zoom}
        dataKey="zoom"
        onChange={({ value }) => onChange(value ? 0 : 7, 'zoom')}
      />

      {value.zoom > 0 && (
        <>
          <FormField
            componentName="NumberInput"
            label={{ formatted: 'widget.zoom' }}
            value={value.zoom}
            dataKey="zoom"
            col="col2 first"
            onChange={({ value }) => onChange(value, 'zoom')}
          />
          <FormField
            componentName="Input"
            label={{ formatted: 'widget.mapCenterClickOnMap' }}
            value={value.center}
            dataKey="center"
            col="col2"
            onChange={({ value }) => onChange(value, 'center')}
          />
        </>
      )}
    </MapZoomConfiguratorStyled>
  )
}

export default MapZoomConfigurator
