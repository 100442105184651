import React from 'react'

import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import { ContentHeader } from 'components/Layout/Content'

import { AntButton } from '../../../Global/AntDesign'
import CancelButton from './CancelButton'
import { TitleField } from './styled'

const FormContentHeader = ({
  children,
  buttons,
  readOnly,
  onBlur,
  titleComp,
  onCancelClick,
  disableSubmit,
  closePath,
  showSaveAndClose = false,
  hideEdit = false,
  nameField = 'name',
  validation = { required: true },
  className,
  titleFieldProps = {},
}) => {
  const history = useHistory()
  const {
    isEditMode,
    setEditMode,
    formState,
    handleFormReset,
    executeFormSubmit,
    isCreateMode,
  } = useFormContext()

  const closeUrl =
    closePath || history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))

  const formButtons = (
    <>
      <CancelButton
        confirm={formState.isDirty}
        text={<FormattedMessage id="confirmDiscardChanges" />}
        onClick={() => {
          const next = () => {
            if (isCreateMode || !isEditMode) {
              handleFormReset()
              history.push({ pathname: closeUrl, search: history.location.search })
            } else {
              setEditMode(false)
              handleFormReset()
            }
          }

          if (onCancelClick) {
            onCancelClick(next)
          } else {
            next()
          }
        }}
      >
        <FormattedMessage id={isEditMode ? 'dataTable.cancel' : 'dataTable.close'} />
      </CancelButton>
      {!hideEdit && !isEditMode && (
        <AntButton
          key="edit"
          id="edit"
          type="primary"
          color="action"
          onClick={() => setEditMode(true)}
        >
          <FormattedMessage id="dataTable.edit" />
        </AntButton>
      )}
      {isEditMode && (
        <>
          <AntButton
            key="save"
            id="save"
            type="primary"
            color="action"
            disabled={
              isEditMode && (disableSubmit !== undefined ? disableSubmit : !formState.isDirty)
            }
            onClick={executeFormSubmit}
          >
            <FormattedMessage id="dataTable.save" />
          </AntButton>

          {showSaveAndClose && (
            <AntButton
              key="saveAndClose"
              id="saveAndClose"
              type="primary"
              color="action"
              disabled={disableSubmit !== undefined ? disableSubmit : !formState.isDirty}
              onClick={() => executeFormSubmit({ closeAfterSubmit: true })}
            >
              <FormattedMessage id="dataTable.saveAndClose" />
            </AntButton>
          )}
        </>
      )}
    </>
  )

  return (
    <ContentHeader
      className={className}
      titleComp={
        titleComp ?? (
          <TitleField
            name={nameField}
            readOnly={readOnly}
            validation={validation}
            onBlur={onBlur}
            {...titleFieldProps}
          />
        )
      }
      buttons={
        <>
          {buttons} {formButtons}
        </>
      }
    >
      {children}
    </ContentHeader>
  )
}

FormContentHeader.propTypes = {
  submitDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideButtons: PropTypes.bool,
  getClosePath: PropTypes.string,
}

FormContentHeader.defaultProps = {
  readOnly: false,
}

export default FormContentHeader
