import styled from 'styled-components'

const MapEntitySelectorStyled = styled.div`
  .ValuePicker {
    flex-direction: row;
    flex-wrap: wrap;
    > .ValuePicker__value {
      display: none;
    }
  }
  .ValuePickerValue {
    max-height: 30vh;
    overflow: auto;
  }
  .MapEntitySelector__button-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .smallButton {
    margin: 0.5rem 0.5rem 0.5rem 0;
    .Button__label {
      white-space: nowrap;
    }
  }
`
export default MapEntitySelectorStyled
