import React from 'react'

import { TickStyled } from './styled'

const Tick = ({ tick, count, format = (d) => d }) => {
  return (
    <TickStyled className="Tick" tick={tick} count={count}>
      <div />
      <div>{format(tick.value)}</div>
    </TickStyled>
  )
}

export default Tick
