import styled, { css } from 'styled-components'

import Icon from 'components/Global/Icon'

export const ResultContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;

  & > * {
    padding: 0.8rem 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.color.softerStroke};
    }
  }
`

export const NoResults = styled.div`
  text-align: center;
`

export const ResultItem = styled.div`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  align-items: center;

  ${({ selected, selectMode }) => {
    if (selectMode) {
      if (selected) {
        return css`
          color: ${({ theme }) => theme.color.highlightText} !important;
          background-color: ${({ theme }) => theme.color.highlight} !important;
        `
      }
    } else {
      return css`
        &:hover {
          color: ${({ theme }) => theme.color.highlightText} !important;
          background-color: ${({ theme }) => theme.color.highlight} !important;
        }
      `
    }
  }}
`

export const ResultIcon = styled(Icon)`
  font-size: 20px;
  color: ${({ theme }) => theme.color.softText};
`

export const Title = styled.div``

export const ResultContent = styled.div`
  width: 100%;
`
