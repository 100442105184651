import React from 'react'

import { PropTypes } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { AntButton } from '../../../../Global/AntDesign'

const CloseButton = ({ to, transId = 'dataTable.close', onClick }) => {
  return (
    <AntButton color="action" onClick={() => onClick && onClick()}>
      <Link to={to}>
        <FormattedMessage id={transId} />
      </Link>
    </AntButton>
  )
}

CloseButton.propTypes = {
  to: PropTypes.string.isRequired,
  transId: PropTypes.string,
}

export default CloseButton
