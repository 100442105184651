import React, { useState } from 'react'

import Icon from './Icon'

const StatusIcon = ({ className, icon, hoveredIcon, activeIcon, isActive, ...props }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Icon
      {...props}
      className={`StatusIcon Icon ${className}`}
      icon={isHovered ? hoveredIcon : isActive ? activeIcon : icon}
      onMouseEnter={() => hoveredIcon && setIsHovered(true)}
      onMouseLeave={() => hoveredIcon && setIsHovered(false)}
    />
  )
}

export default StatusIcon
