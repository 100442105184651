import { useCallback, useState } from 'react'

export const useActionConfirmation = () => {
  const [confirmation, setConfirmation] = useState({
    isActive: false,
    value: null,
    isConfirmed: false,
  })
  const onConfirmationRequest = useCallback(({ value, isConfirmed, isCanceled }) => {
    setConfirmation({
      value,
      isActive: !(isConfirmed || isCanceled),
      isConfirmed: !!isConfirmed,
      isCanceled,
    })
  }, [])
  return [confirmation, onConfirmationRequest]
}
