import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useFormContext } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { FieldGroupTitle } from 'components/Form/components/FieldGroup/styled'
import FileClassificationField from 'components/Form/fields/FileClassificationField/FileClassificationField'
import TextAreaField from 'components/Form/fields/TextAreaField/TextAreaField'
import Icon from 'components/Global/Icon'

import {
  UploadAndRemarksFieldContent,
  UploadAndRemarksFieldHeader,
  UploadAndRemarksFieldStyled,
} from './styled'

const UploadAndRemarksField = ({ value, name, filter, intl, ...fieldProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [height, setHeight] = useState(false)

  const wrapper = useRef()

  const { isEditMode } = useFormContext()

  useEffect(() => {
    setHeight(isOpen ? wrapper.current?.clientHeight : 0)
    // eslint-disable-next-line
  }, [isOpen, wrapper.current, isEditMode])

  const handleHeaderClick = useCallback(() => setIsOpen((state) => !state), [setIsOpen])

  return (
    <UploadAndRemarksFieldStyled {...fieldProps}>
      <UploadAndRemarksFieldHeader
        onClick={handleHeaderClick}
        className={`${isOpen ? 'active' : 'inactive'}`}
      >
        <FieldGroupTitle>{intl.formatMessage({ id: 'settings.filesRemarks' })}</FieldGroupTitle>
        <Icon icon={isOpen ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'} />
      </UploadAndRemarksFieldHeader>

      <UploadAndRemarksFieldContent initial={{ height: 0 }} animate={{ height }} large>
        <div ref={wrapper}>
          <FileClassificationField name="files" />
          <TextAreaField name="remark" label={intl.formatMessage({ id: 'remarks' })} />
        </div>
      </UploadAndRemarksFieldContent>
    </UploadAndRemarksFieldStyled>
  )
}

export default injectIntl(UploadAndRemarksField)
