export const appName = 'Canary'

/** @deprecated Implement REACT_APP_FILE_URL env variable */
const getFileEndpoint = (env) => {
  switch (env) {
    case 'prod':
      return 'https://api.iot.canary.expert'
    case 'demo':
      return 'https://api.demo.canary.expert'
    case 'demo_dev':
      return 'https://api.iot.canary.expert' // devdemo
    case 'staging':
      return 'https://api.stg.canary.expert'
    default:
    case 'dev':
      return 'https://api.dev.canary.expert'
    case 'local':
      return 'https://api.iot.canary.expert' // 'http://localhost:3000'
  }
}

export const fileEndpoint =
  process.env.REACT_APP_FILE_URL ?? getFileEndpoint(process.env.REACT_APP_ENV)

// ID's of all categories that we use at the moment
export const ghgScope3EnabledCategories = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12]
