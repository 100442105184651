import React from 'react'

export const HvacAuto = () => (
  <svg width="30px" height="25px" viewBox="-25 25 479.52 399.6">
    <defs />
    <g id="g3760" transform="matrix(0.9365625 0 0 0.78046875 0 0)">
      <g id="g4933">
        <path
          id="path3756"
          transform="translate(28.208302, 126.23796)"
          d="M332.516 308.254L332.516 166.809L349.772 181.795L368.64 160.068L184.32 0L0 160.068L18.8683 181.794L36.1246 166.808L36.1246 308.254M64.9001 308.254L64.9001 141.819L184.32 38.1125L303.74 141.819L303.74 308.254"
        />
        <text
          id="text4949"
          style={{
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '48',
            fontStretch: 'normal',
            fontWeight: '700',
            letterSpacing: '0',
            wordSpacing: '0'
          }}
        >
          <tspan>
            <tspan
              x="146.70679"
              y="411.49597"
              style={{
                strokeWidth: '1.20000005',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: '4',
                fontSize: '176'
              }}
            >
              A
            </tspan>
          </tspan>
        </text>
      </g>
      <g id="g4926" />
    </g>
    <g id="g3762" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3764" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3766" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3768" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3770" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3772" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3774" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3776" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3778" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3780" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3782" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3784" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3786" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3788" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
    <g id="g3790" transform="matrix(0.9365625 0 0 0.78046875 0 0)" />
  </svg>
)
